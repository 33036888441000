import { useSelector } from "react-redux";
import { flatten } from "lodash";
import axios from "axios";
import GetCookie from "../../Common/Functions/GetCookie";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import { AppState } from "../../../Reducers/Reducers";

type Callback = (products: { id: number, provider: number, product_type: number }[]) => Promise<boolean>;

export function useMarkBookingForOfflineProducts(): Callback {
    const carsCart = useSelector((state: AppState) => {
        return state.cars_search.cart;
    });
    const accommodationsCart = useSelector((state: AppState) => {
        return state.accommodation.sort_cart;
    });
    const poisCart = useSelector((state: AppState) => {
        return state.poi.cart;
    });
    const flightsCart = useSelector((state: AppState) => {
        return state.flight.cart;
    });
    const transfersCart = useSelector((state: AppState) => {
        return state.transfers.cart;
    });
    const assistancesCart = useSelector((state: AppState) => state.cart.assistance_cart);

    return async (products) => {
        const providerIds = products.map((product) => {
            return product.provider;
        });

        let promises = products.map((product) => {
            return makeRequest({
                id: product.id,
                productType: product.product_type,
                bookable: true
            });
        });
        const bookableOfflineProductsIds = products.map((product) => {
            return product.id;
        });
        promises = promises.concat(
            carsCart?.filter((product) => {
                return product.is_custom &&
                    !bookableOfflineProductsIds.includes(product.id) &&
                    product.is_provider_quotation_displayed &&
                    (
                        providerIds.includes(product.provider) ||
                        products.length === 0
                    );
            }).map((product) => {
                return makeRequest({
                    id: product.id,
                    productType: product.product_type,
                    bookable: false
                });
            }) ?? []
        ).concat(
            flatten(
                accommodationsCart?.map((product) => {
                    return product.rooms;
                })
            ).filter((product) => {
                return product.is_custom &&
                    !bookableOfflineProductsIds.includes(product.id) &&
                    product.is_provider_quotation_displayed &&
                    (
                        providerIds.includes(product.provider) ||
                        products.length === 0
                    );
            }).map((product) => {
                return makeRequest({
                    id: product.id,
                    productType: product.product_type,
                    bookable: false
                });
            }) ?? []
        ).concat(
            poisCart?.filter((product) => {
                return product.is_custom &&
                    !bookableOfflineProductsIds.includes(product.id) &&
                    product.is_provider_quotation_displayed &&
                    (
                        providerIds.includes(product.provider) ||
                        products.length === 0
                    );
            }).map((product) => {
                return makeRequest({
                    id: product.id,
                    productType: product.product_type,
                    bookable: false
                });
            }) ?? []
        ).concat(
            flightsCart?.filter((product) => {
                return product.is_custom &&
                    !bookableOfflineProductsIds.includes(product.id) &&
                    product.is_provider_quotation_displayed &&
                    (
                        providerIds.includes(product.provider) ||
                        products.length === 0
                    );
            }).map((product) => {
                return makeRequest({
                    id: product.id,
                    productType: product.product_type,
                    bookable: false
                });
            }) ?? []
        ).concat(
            transfersCart?.filter((product) => {
                return product.is_custom &&
                    !bookableOfflineProductsIds.includes(product.id) &&
                    product.is_provider_quotation_displayed &&
                    (
                        providerIds.includes(product.provider) ||
                        products.length === 0
                    );
            }).map((product) => {
                return makeRequest({
                    id: product.id,
                    productType: product.product_type,
                    bookable: false
                });
            }) ?? []
        ).concat(
            assistancesCart?.filter((product) => {
                return product.is_custom &&
                    !bookableOfflineProductsIds.includes(product.id) &&
                    product.is_provider_quotation_displayed &&
                    (
                        providerIds.includes(product.provider) ||
                        products.length === 0
                    );
            }).map((product) => {
                return makeRequest({
                    id: product.id,
                    productType: product.product_type,
                    bookable: false
                });
            }) ?? []
        );

        const responses = await Promise.allSettled(promises);
        return responses.every((response) => {
            return response.status === 'fulfilled';
        });
    };
}

type RequestOptions = {
    id: number,
    productType: number,
    bookable: boolean
}

async function makeRequest(options: RequestOptions): Promise<{ id: number } | null> {
    const { pass_check, headers } = CheckBeforeRequest();

    if (pass_check) {
        if (options.productType === 0) {
            const response = await axios({
                method: 'PATCH',
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/accommodation/${options.id}/`,
                data: {
                    is_provider_quotation_displayed: options.bookable
                }
            });
            return response.data;
        }
        const response = await axios({
            method: 'PATCH',
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/custom-products/${options.id}/`,
            data: {
                is_provider_quotation_displayed: options.bookable
            }
        });
        return response.data;
    }

    return null;
}
