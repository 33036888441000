import React, { useContext, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Chip,
    Collapse,
    IconButton,
    Stack,
    TableCell,
    TableRow,
    Typography
} from "@mui/material";
import {
    BuildCircle,
    CloudCircle,
    CloudOff,
    ErrorOutline,
    ExploreOutlined,
    MoreVert,
    ReportProblem,
    Visibility
} from "@mui/icons-material";
import { green } from "@mui/material/colors";
import { makeStyles } from "@material-ui/core";
import { flatten, isNumber } from "lodash";
import LightTooltip from "../Menu/MaterialTripList/utils/tooltip/lightTooltip";
import { CartConstructionProductsTableItemStatus } from "./CartConstructionProductsTableItemStatus";
import { CartConstructionProductsTableItem } from "./CartConstructionProductsTableItem";
import { CartConstructionProductsTableItemMenu } from "./CartConstructionProductsTableItemMenu";
import { CartConstructionProductsTableItemMargin } from "./CartConstructionProductsTableItemMargin";
import {
    CartConstructionProductsTableItemProviderQuotationStatus
} from "./CartConstructionProductsTableItemProviderQuotationStatus";
import {
    CartPackageItemChangeProviderQuotationStatusButtons
} from "./CartPackageItemChangeProviderQuotationStatusButtons";
import { CartPoiIcon } from "./CartPoiIcon";
import { CartProductCardProviderLogo } from "./CartProductCardProviderLogo";
import { findProductItineraryError } from "./utils/findProductItineraryError";
import { useTripDays } from "./utils/tripDays";
import { useGetPrice } from "./utils/getPrice";
import { useCartProducts } from "../Itinerary/network/cartProducts";
import { useProductDays } from "./utils/productDays";
import { useManualProductAlerts } from "./utils/manualProductAlerts";
import { useCartProductUpdate } from "./network/cartProductUpdate";
import { useShowError } from "../Utils/showError";
import { transformStepInputsToGroups } from "../Itinerary/utils/transformStepInputsToGroups";
import { findProductAssociatedItineraryStep } from "./utils/findProductAssociatedItineraryStep";
import { checkIfOnRoad } from "./utils/checkIfOnRoad";
import { sortItinerary } from "../Itinerary/utils/sortItinerary";
import GetCookie from "../Common/Functions/GetCookie";
import GetPricesId from "../Cart/Functions/Margin/GetPricesId";
import { AppState } from "../../Reducers/Reducers";
import { isProductPackaged } from "./utils/isProductPackaged";
import { ProviderContext } from "./utils/providerContext";

type Props = {
    item: ReturnType<typeof useCartProducts>['pois'][number]
}

export function CartConstructionProductsTablePoiItem(props: Props): JSX.Element {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const language = useSelector((state: AppState) => state.header.tmp_language);
    const locale = useSelector((state: AppState) => state.user.locales?.find((item) => {
        return item.language_code === i18n.language;
    })?.id ?? 1);
    const providers = useSelector((state: AppState) => state.trip.providers);
    const manual_providers = useSelector((state: AppState) => state.trip.manual_providers);
    const trip = useSelector((state: AppState) => state.trip.data_trip);
    const itinerary = useSelector((state: AppState) => state.itinerary.itinerary_list);
    const see_new_price = useSelector((state: AppState) => state.cart.see_new_price);
    const user = useSelector((state: AppState) => state.user.user);
    const [openMenu, setOpenMenu] = useState(false);
    const provider = useMemo(() => {
        return manual_providers.find((item) => {
            return item.provider.id === props.item.poi.provider;
        }) ?? providers.find((item) => {
            return item.provider.id === props.item.poi.provider;
        });
    }, [providers, manual_providers, props.item]);
    const { found, iti_error } = useMemo(() => {
        if (props.item.type === 'normal') {
            return findProductItineraryError(
                'poi',
                {
                    product: props.item.poi,
                    itinerary,
                    stackInfos: trip?.stack_info ?? null
                }
            );
        }
        return findProductItineraryError(
            'manual',
            {
                product: props.item.poi,
                itinerary,
                stackInfos: trip?.stack_info ?? null
            }
        );
    }, [
        itinerary,
        props.item,
        trip
    ]);
    const isOnRoad = useMemo(() => {
        const groups = transformStepInputsToGroups(itinerary);
        const searchableItinerary = isProductPackaged({
            product: props.item.poi,
            stackInfos: trip?.stack_info ?? null
        }) ?
            flatten(
                groups.filter((item) => {
                    return item[0] &&
                        (
                            isNumber(item[0].circuit) ||
                            isNumber(item[0].iti_type)
                        ) &&
                        item[0].circuit === props.item.poi.from_circuit;
                })
            ) :
            itinerary;
        const steps = searchableItinerary.filter((item) => {
            return item.step_type === 'STEP';
        }).sort(sortItinerary);
        const associatedStep = findProductAssociatedItineraryStep({
            type: 'start',
            itinerary: steps,
            product: props.item.poi,
            granularity: 'seconds'
        });

        if (associatedStep) {
            return checkIfOnRoad({
                type: 'poi',
                itinerary: steps,
                step: associatedStep,
                product: props.item.poi
            });
        }

        return false;
    }, [props.item.poi, itinerary]);
    const menuButtonRef = useRef<HTMLButtonElement>(null);
    const showError = useShowError();
    const update = useCartProductUpdate({
        onError(error) {
            console.error(error);
            showError(error);
        }
    });
    const tripDays = useTripDays();
    const getDays = useProductDays();
    const getPrice = useGetPrice();
    const manualProductAlerts = useManualProductAlerts(
        props.item.type === 'manual' ?
            props.item.poi :
            null
    );
    const startDate = window.moment.utc(props.item.poi.start_date);
    const endDate = window.moment.utc(props.item.poi.end_date);
    const price = getPrice(props.item.poi.prices);
    const days = getDays(props.item.poi.start_date, props.item.poi.end_date);
    const providerContext = useContext(ProviderContext);
    const variantName = props.item.poi.variant &&
        !isNumber(props.item.poi.variant) ?
        props.item.poi.variant.localization?.find((item) => {
            return item.locale === locale;
        })?.title ?? props.item.poi.variant.name :
        props.item.poi.rate_name ?? null;

    const handleSeeNewPrice = () => {
        if (props.item.type === 'normal') {
            dispatch({
                type: 'CART_SEE_NEW_PRICE_MODAL',
                payload: {
                    see_new_price: !see_new_price,
                    data: see_new_price ? null : {
                        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/custom-products/${props.item.poi.id}/`,
                        id: props.item.poi.id,
                        old_price: props.item.poi.prices[GetPricesId(props.item.poi.prices, user?.client, user, true)],
                        new_price: props.item.poi.provider_wanted_price,
                        product: props.item.poi,
                        custom_dispatch: 'POI_EDIT_CART_BY_ID'
                    }
                }
            });
        }
    };

    const onDeleteTravelerModificationAlert = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();
        const version = parseInt(GetCookie('trip_id_version') ?? '-1');
        update(
            version,
            {
                id: props.item.poi.id,
                type: props.item.type === 'normal' ?
                    'poi' :
                    'manual',
                isCustom: props.item.poi.is_custom
            },
            { traveler_modification: null }
        );
    };

    const alerts = [
        props.item.poi.booking_status && props.item.poi.booking_status.status_booking === 'UNAVAILABLE' &&
        <Alert className={classes.errorAlert} classes={{ icon: classes.alertIcon }} variant={'filled'} severity={'error'}>
            {t('cart-material.booking-unavailable')} : {props.item.poi.booking_status.item_reference}
        </Alert>,
        props.item.type === 'normal' &&
        props.item.poi.booking_status &&
        (props.item.poi.booking_status.status_booking === 'ERROR' || props.item.poi.booking_status.status_booking === 'UNAVAILABLE') &&
        props.item.poi.booking_errors &&
        props.item.poi.booking_errors.length > 0 &&
        <Alert className={classes.errorAlert} classes={{ icon: classes.alertIcon }} variant={'filled'} severity={'error'}>
            <AlertTitle>{t('cart-material.error')} :</AlertTitle>
            {
                props.item.poi.booking_errors.map((error) => (
                    <Typography key={`booking-error-${error.created_date}`}>
                        {window.moment.utc(error.created_date).format('DD/MM/YYYY')} : {error.detail}
                    </Typography>
                ))
            }
        </Alert>,
        iti_error &&
        <Alert variant="filled" severity="error" sx={{ backgroundColor: '#E74432' }}>
            {t('cart-material.itinerary-error-1')}{' '}
            {
                iti_error.destination?.data?.localization.find((item) => {
                    return item.locale === locale;
                })?.name ??
                iti_error.destination?.data?.name ??
                iti_error.destination?.data?.international_name
            } {t('global.from')}{' '}
            {window.moment.utc(iti_error.start_date).format('DD/MM/YYYY HH:mm')}{' '}
            {t('global.to')} {window.moment.utc(iti_error.end_date).format('DD/MM/YYYY HH:mm')}.{' '}
            {t('cart-material.itinerary-error-2')}.
        </Alert>,
        !found &&
        <Alert variant="filled" severity="error" sx={{ backgroundColor: '#E74432' }}>
            {t('cart-material.no-destination-found')}
        </Alert>,
        user?.client_full?.type !== 2 &&
        props.item.poi.booking_status &&
        props.item.poi.booking_status.details &&
        props.item.poi.booking_status.details !== '' &&
        <Alert variant={'filled'} severity={'info'}>
            <AlertTitle>{t('cart-material.provider-note')} :</AlertTitle>
            {props.item.poi.booking_status.details}
        </Alert>,
        props.item.type === 'normal' &&
        user?.client_full?.type !== 2 &&
        props.item.poi.provider_wanted_price &&
        <Alert variant={'filled'} severity={'error'} action={
            <Button color={"inherit"} size={'small'} onClick={handleSeeNewPrice}>{t('global.see')}</Button>
        }>
            <AlertTitle>{t('cart-material.provider-wanted-price')}</AlertTitle>
        </Alert>
    ].filter((item): item is Exclude<typeof item, false> => !!item).concat(manualProductAlerts);

    return (
        <>
            <CartConstructionProductsTableItem
                {
                ...(
                    props.item.type === 'normal' ?
                        { type: 'poi', item: props.item.poi } :
                        { type: 'manual', item: props.item.poi }
                )
                }
            >
                <TableCell sx={{ verticalAlign: 'middle', paddingRight: 0 }}>
                    {
                        alerts.length > 0 &&
                        <LightTooltip
                            title={alerts}
                            onClick={(event) => event.stopPropagation()}
                            placement="top"
                            arrow
                        >
                            <ReportProblem color="warning" />
                        </LightTooltip>
                    }
                    {
                        isOnRoad &&
                        <LightTooltip
                            title={
                                <Alert variant="filled" severity="info">
                                    {
                                        t(
                                            'cart-material.cart-construction-on-road',
                                            {
                                                from: (
                                                    isOnRoad[0].destination?.data?.localization.find((item) => {
                                                        return item.locale === locale;
                                                    })?.name ??
                                                    isOnRoad[0].destination?.data?.name ??
                                                    isOnRoad[0].destination?.data?.international_name
                                                )?.split(',')[0],
                                                to: (
                                                    isOnRoad[1].destination?.data?.localization.find((item) => {
                                                        return item.locale === locale;
                                                    })?.name ??
                                                    isOnRoad[1].destination?.data?.name ??
                                                    isOnRoad[1].destination?.data?.international_name
                                                )?.split(',')[0]
                                            }
                                        )
                                    }
                                </Alert>
                            }
                            arrow
                        >
                            <ExploreOutlined sx={{ color: '#FF8100' }} />
                        </LightTooltip>
                    }
                </TableCell>
                <TableCell>
                    <div className="ellipsis">
                        <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                            flexWrap="nowrap"
                        >
                            <LightTooltip
                                title={
                                    props.item.poi.poi_type ?
                                        t('global.poi') :
                                        t('cart-material.cart-construction-activity')
                                }
                                placement="top"
                                arrow
                            >
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={1}
                                    flexWrap="nowrap"
                                >
                                    {
                                        props.item.poi.poi_type ?
                                            <CartPoiIcon fontSize="1.25rem" /> :
                                            <Visibility />
                                    }
                                    <Typography
                                        variant="body2"
                                        fontWeight="bold"
                                        sx={{ whiteSpace: 'nowrap' }}
                                    >
                                        {
                                            props.item.poi.poi_type ?
                                                t('cart-material.cart-construction-poi') :
                                                t('cart-material.cart-construction-activity')
                                        }
                                    </Typography>
                                </Stack>
                            </LightTooltip>
                            {
                                !isProductPackaged({
                                    product: props.item.poi,
                                    stackInfos: trip?.stack_info ?? null,
                                    connected: true
                                }) &&
                                (
                                    user?.client_full?.type !== 2 ||
                                    props.item.poi.creator?.client?.type === user.client_full.type
                                ) &&
                                <>
                                    {
                                        props.item.type === 'normal' &&
                                        !props.item.poi.is_custom &&
                                        <Chip
                                            label={t('cart-material.cart-construction-flux')}
                                            size="small"
                                            sx={{
                                                backgroundColor: '#2ACAEA',
                                                color: '#fff'
                                            }}
                                            icon={<CloudCircle color="inherit" />}
                                        />
                                    }
                                    {
                                        props.item.type === 'normal' &&
                                        props.item.poi.is_custom &&
                                        <Chip
                                            label={t('cart-material.cart-construction-offline')}
                                            size="small"
                                            sx={{
                                                backgroundColor: '#6A329F',
                                                color: '#fff'
                                            }}
                                            icon={<CloudOff color="inherit" />}
                                        />
                                    }
                                    {
                                        props.item.type === 'manual' &&
                                        <Chip
                                            label={t('accommodation.manual')}
                                            size="small"
                                            sx={{
                                                backgroundColor: '#6A329F',
                                                color: '#fff'
                                            }}
                                            icon={<BuildCircle color="inherit" />}
                                        />
                                    }
                                </>
                            }
                        </Stack>
                        <Typography
                            variant="caption"
                            component="div"
                            sx={{
                                position: 'absolute',
                                marginTop: 1,
                                opacity: 0.7
                            }}
                        >
                            <LightTooltip
                                title={
                                    (!days[0] || !tripDays.includes(days[0])) ?
                                        t(
                                            'cart-material.cart-construction-incorrect-product-days',
                                            { day: tripDays.join(', ') }
                                        ) :
                                        ''
                                }
                                arrow
                            >
                                <Box
                                    sx={
                                        (!days[0] || !tripDays.includes(days[0])) ?
                                            {
                                                backgroundColor: '#ff0000',
                                                color: '#fff'
                                            } :
                                            undefined
                                    }
                                    component="span"
                                >
                                    {
                                        days[0] !== days[days.length - 1] ?
                                            t(
                                                'cart-material.cart-construction-day-from-to',
                                                { from: days[0], to: days[days.length - 1] }
                                            ) :
                                            t(
                                                'cart-material.cart-construction-day',
                                                { day: days[0] }
                                            )
                                    }
                                </Box>
                            </LightTooltip>
                            {' '}-{' '}
                            {
                                (startDate.isValid() || endDate.isValid()) &&
                                t(
                                    'cart-material.cart-construction-products-table-date',
                                    {
                                        from: startDate.isValid() ? startDate.format('L') : null,
                                        to: endDate.isValid() ? endDate.format('L') : null
                                    }
                                )
                            }
                            <Stack
                                display="inline-flex"
                                direction="row"
                                alignItems="center"
                                spacing={1}
                                sx={{ marginLeft: 1.5 }}
                            >
                                <CartConstructionProductsTableItemProviderQuotationStatus
                                    item={props.item.poi}
                                />
                            </Stack>
                            {
                                props.item.poi.traveler_modification &&
                                <Chip
                                    color="warning"
                                    label={
                                        props.item.poi.traveler_modification === 'SET_IN_TRIP' ?
                                            t('cart-material.add-to-cart-traveler') :
                                            t('cart-material.add-to-option-traveler')
                                    }
                                    size="small"
                                    icon={<ErrorOutline color="inherit" />}
                                    onDelete={onDeleteTravelerModificationAlert}
                                />
                            }
                        </Typography>
                    </div>
                </TableCell>
                <LightTooltip
                    title={
                        (
                            props.item.poi.custom_product?.place.address &&
                            !props.item.poi.custom_product?.place.address.includes('null') &&
                            props.item.poi.custom_product?.place.address
                        ) ??
                        props.item.poi.start_destination?.data?.localization.find((item) => {
                            return item.locale === locale;
                        })?.name ??
                        props.item.poi.start_destination?.data?.international_name
                    }
                    placement="top"
                    arrow
                >
                    <TableCell>
                        <div className="ellipsis">
                            {
                                (
                                    props.item.poi.custom_product?.place.address &&
                                    !props.item.poi.custom_product?.place.address.includes('null') &&
                                    props.item.poi.custom_product?.place.address
                                ) ??
                                props.item.poi.start_destination?.data?.localization.find((item) => {
                                    return item.locale === locale;
                                })?.name ??
                                props.item.poi.start_destination?.data?.international_name
                            }
                        </div>
                    </TableCell>
                </LightTooltip>
                <LightTooltip
                    title={
                        <>
                            {
                                (() => {
                                    const poiLocalization = props.item.poi.localization?.find((item) => {
                                        return item.locale === locale;
                                    });
                                    if (poiLocalization) {
                                        return poiLocalization.name;
                                    }
                                    if (props.item.type === 'normal') {
                                        const localization = props.item.poi.custom_product?.localization?.find((item) => {
                                            return item.locale === locale;
                                        });
                                        if (localization) {
                                            return (localization?.title.length ?? 0) > 0 ?
                                                localization?.title :
                                                props.item.poi.custom_product?.title;
                                        }
                                        return props.item.poi.custom_product?.title;
                                    }
                                    return props.item.poi.name;
                                })()
                            }
                            {
                                variantName &&
                                ` (${variantName})`
                            }
                        </>
                    }
                    placement="top"
                    arrow
                >
                    <TableCell>
                        <div className="ellipsis">
                            {
                                (() => {
                                    const poiLocalization = props.item.poi.localization?.find((item) => {
                                        return item.locale === locale;
                                    });
                                    if (poiLocalization) {
                                        return poiLocalization.name;
                                    }
                                    if (props.item.type === 'normal') {
                                        const localization = props.item.poi.custom_product?.localization?.find((item) => {
                                            return item.locale === locale;
                                        });
                                        if (localization) {
                                            return (localization?.title.length ?? 0) > 0 ?
                                                localization?.title :
                                                props.item.poi.custom_product?.title;
                                        }
                                        return props.item.poi.custom_product?.title;
                                    }
                                    return props.item.poi.name;
                                })()
                            }
                            {
                                variantName &&
                                ` (${variantName})`
                            }
                        </div>
                    </TableCell>
                </LightTooltip>
                <TableCell>
                    <Box sx={{ textAlign: 'center' }}>
                        {
                            !props.item.poi.poi_type &&
                            !isProductPackaged({
                                product: props.item.poi,
                                stackInfos: trip?.stack_info ?? null
                            }) &&
                            <CartProductCardProviderLogo
                                logoUrl={
                                    provider?.provider.logo?.url ??
                                    provider?.provider.logo?.thumbnail_big ??
                                    provider?.provider.logo?.thumbnail_medium ??
                                    provider?.provider.logo?.thumbnail_little ??
                                    null
                                }
                                name={provider?.provider.name ?? null}
                                width={48}
                                height={32}
                            />
                        }
                    </Box>
                </TableCell>
                <LightTooltip
                    title={
                        props.item.poi.booking_status?.item_reference ?
                            t(
                                'cart-material.cart-construction-reference',
                                { ref: props.item.poi.booking_status.item_reference }
                            ) :
                            ''
                    }
                    placement="top"
                    arrow
                >
                    <TableCell>
                        <div className="ellipsis">
                            {
                                props.item.poi.booking_status?.item_reference &&
                                t(
                                    'cart-material.cart-construction-reference',
                                    { ref: props.item.poi.booking_status.item_reference }
                                )
                            }
                        </div>
                    </TableCell>
                </LightTooltip>
                <TableCell sx={{ maxWidth: 'none !important', textAlign: 'center' }}>
                    {
                        !isProductPackaged({
                            product: props.item.poi,
                            stackInfos: trip?.stack_info ?? null,
                            connected: true
                        }) &&
                        <>
                            {
                                props.item.type === 'normal' ?
                                    <CartConstructionProductsTableItemStatus
                                        type="poi"
                                        item={props.item.poi}
                                        bookingProcessState={props.item.poi.booking_process_state}
                                        bookingStatus={props.item.poi.booking_status}
                                        agencyNeedToBook={props.item.poi.agency_need_to_book}
                                    /> :
                                    <CartConstructionProductsTableItemStatus
                                        type="manual"
                                        item={props.item.poi}
                                        bookingProcessState={props.item.poi.booking_process_state}
                                        bookingStatus={props.item.poi.booking_status}
                                        agencyNeedToBook={props.item.poi.agency_need_to_book}
                                    />
                            }
                        </>
                    }
                </TableCell>
                <TableCell>
                    {
                        !isProductPackaged({
                            product: props.item.poi,
                            stackInfos: trip?.stack_info ?? null
                        }) ?
                            <div className="ellipsis">
                                {
                                    new Intl.NumberFormat(
                                        language,
                                        {
                                            style: 'currency',
                                            currency: price.currency?.iso_code ?? 'EUR'
                                        }
                                    ).format(price.cost)
                                }
                            </div> :
                            <CartPackageItemChangeProviderQuotationStatusButtons
                                item={{
                                    ...props.item.poi,
                                    stackNumber: props.item.poi.stack_number,
                                    providerId: props.item.poi.provider,
                                    type: props.item.type === 'manual' ?
                                        'manual' :
                                        'pois',
                                    isCustom: props.item.poi.is_custom
                                }}
                            />
                    }
                </TableCell>
                <TableCell>
                    <IconButton
                        ref={menuButtonRef}
                        size="small"
                        onClick={(event) => {
                            event.stopPropagation();
                            setOpenMenu(true);
                        }}
                    >
                        <MoreVert />
                    </IconButton>
                    {
                        props.item.type === 'normal' &&
                        <CartConstructionProductsTableItemMenu
                            type="poi"
                            open={openMenu}
                            anchorEl={menuButtonRef.current}
                            value={props.item.poi}
                            onClose={() => setOpenMenu(false)}
                        />
                    }
                    {
                        props.item.type === 'manual' &&
                        <CartConstructionProductsTableItemMenu
                            type="manual"
                            open={openMenu}
                            anchorEl={menuButtonRef.current}
                            value={props.item.poi}
                            onClose={() => setOpenMenu(false)}
                        />
                    }
                </TableCell>
            </CartConstructionProductsTableItem>
            <TableRow className="cart-construction-table-item-margin-row">
                <MarginWrapper item={props.item} />
            </TableRow>
        </>
    );
}

type MarginWrapperProps = {
    item: ReturnType<typeof useCartProducts>['pois'][number]
}

function MarginWrapper(props: MarginWrapperProps): JSX.Element {
    const trip = useSelector((state: AppState) => state.trip.data_trip);
    const seeAllProductsMargins = useSelector((state: AppState) => state.cartConstruction.seeProductsMargins);

    return (
        <TableCell
            sx={
                seeAllProductsMargins ?
                    undefined :
                    { paddingTop: 0, paddingBottom: 0 }
            }
            colSpan={20}
        >
            <Collapse
                in={
                    seeAllProductsMargins &&
                    !isProductPackaged({
                        product: props.item.poi,
                        stackInfos: trip?.stack_info ?? null
                    })
                }
                unmountOnExit
            >
                {
                    props.item.type === 'normal' &&
                    <CartConstructionProductsTableItemMargin
                        type="poi"
                        item={props.item.poi}
                    />
                }
                {
                    props.item.type === 'manual' &&
                    <CartConstructionProductsTableItemMargin
                        type="manual"
                        item={props.item.poi}
                    />
                }
            </Collapse>
        </TableCell>
    );
}

const useStyles = makeStyles(() => ({
    gridImage: {
        width: 250
    },
    gridContent: {
        padding: '0 15px'
    },
    gridPrice: {
        width: 100
    },
    image: {
        borderRadius: 16
    },
    selector: {
        margin: '16px 0 8px 0'
    },
    variant: {
        margin: '16px 0 8px 0'
    },
    updateQuotationStatus: {
        textDecoration: 'underline',
        marginBottom: 4,
        cursor: 'pointer'
    },
    addNote: {
        textDecoration: 'underline',
        marginBottom: 4,
        cursor: 'pointer'
    },
    manualCancellationRules: {
        textDecoration: 'underline',
        marginBottom: 4,
        cursor: 'pointer'
    },
    containerMargin: {
        marginBottom: 4
    },
    roomIcon: {
        float: 'left',
        marginRight: 4
    },
    inlineBlock: {
        display: 'inline-block'
    },
    cancellationInfo: {
        display: 'inline-block',
        verticalAlign: 'bottom',
        marginLeft: 4,
        cursor: 'pointer'
    },
    poiName: {
        fontSize: 20,
        fontWeight: 'bold',
        cursor: 'pointer'
    },
    pin: {
        float: 'left',
        marginRight: 4
    },
    calendar: {
        float: 'left',
        marginRight: 4
    },
    composition: {
        float: 'left',
        marginRight: 4
    },
    service: {
        float: 'left',
        marginRight: 4
    },
    contractPack: {
        color: '#E6592F',
        cursor: 'pointer',
        textDecoration: 'underline'
    },
    bookingContainer: {
        paddingLeft: 15
    },
    manualBookingHand: {
        float: 'left',
        marginRight: 8
    },
    price: {
        textAlign: 'right',
        fontSize: 32,
        fontWeight: 'bold'
    },
    red: {
        textAlign: 'right',
        color: 'red'
    },
    green: {
        textAlign: 'right',
        color: 'green'
    },
    needToBook: {
        color: '#4caf50',
        textAlign: 'right'
    },
    traverlerModification: {
        borderRadius: 16,
        padding: '1px 6px'
    },
    booking: {
        borderRadius: 16,
        fontWeight: 700,
        padding: '4px 12px',
        margin: '4px 0',
        textTransform: 'uppercase'
    },
    bookingDetail: {
        textDecoration: 'underline',
        fontSize: 12,
        textAlign: 'right',
        marginBottom: 4,
        cursor: 'pointer'
    },
    booked: {
        border: '2px solid #4caf50',
        color: '#4caf50'
    },
    pending: {
        border: '2px solid #ff9800',
        color: '#ff9800'
    },
    cached: {
        float: 'left',
        marginRight: 8,
        cursor: 'pointer'
    },
    cancelled: {
        border: '2px solid black',
        color: 'black'
    },
    error: {
        border: '2px solid #f44336',
        color: '#f44336'
    },
    deleteContainer: {
        textAlign: 'right'
    },
    delete: {
        cursor: 'pointer'
    },
    prices: {
        textAlign: 'right'
    },
    errorAlert: {
        marginTop: 8
    },
    alertIcon: {
        alignItems: 'center'
    },
    infoCustomInformation: {
        border: '1px solid #FF9800',
        color: 'white',
        background: '#FF9800',
        marginRight: 5
    },
    editCustomInformation: {
        border: '1px solid'
    },
    deleteCustomInformation: {
        cursor: 'pointer',
        marginLeft: 8
    },
    marginEdit: {
        textDecoration: 'underline',
        color: '#E6592F',
        cursor: 'pointer'
    },
    validateButton: {
        backgroundColor: '#E6592F',
        color: 'white'
    },
    poiInPropertyCollapsed: {
        float: 'left',
        cursor: 'pointer',
        marginRight: 4
    },
    poiInPropertyIconCollapsed: {
        verticalAlign: 'text-top'
    },
    pictureContainer: {
        overflow: "hidden",
        height: 200,
        //width: 300,
        position: "relative",
        cursor: "pointer"
    },
    hideMargin: {
        textDecoration: 'underline',
        cursor: 'pointer'
    },
    hideProduct: {
        backgroundColor: '#eeee',
        border: "solid 2px #E6592F",
        padding: 15,
        borderRadius: 10,
        marginBottom: 20
    },
    inQuotation: {
        border: "solid 2px #43D67D",
        padding: 15,
        borderRadius: 10,
        marginBottom: 20
    },
    isOption: {
        border: "solid 2px #eeee",
        padding: 15,
        borderRadius: 10,
        marginBottom: 20
    },
    progress: {
        color: green[500],
        position: 'absolute',
        top: "16%",
        left: "43%",
        marginLeft: -12
    },
    checkboxContainer: {
        position: 'relative',
        marginTop: '-5px'
    },
    cancelButton: {
        border: '1px solid',
        backgroundColor: 'white',
        color: '#E6592F'
    },
    cancelConditionAlert: {
        "whiteSpace": 'pre-wrap',
        "&& .MuiAlert-action": {
            minWidth: 140
        }
    },
    editTime: {
        "cursor": 'pointer',
        '&:hover': {
            textDecoration: 'underline'
        }
    }
}));
