import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ProviderContext } from "../utils/providerContext";
import { makeProductEditRequest } from "../utils/editProductRequests";
import CheckResponse from "../../Flight/FlightSelected/Functions/CheckResponse";
import { CartConstructionProductsTableItemProps } from "../CartConstructionProductsTableItem";
import { AppState } from "../../../Reducers/Reducers";

type Product = {
    type: CartConstructionProductsTableItemProps['type'],
    id: number,
    roomIds?: number[],
    isCustom: boolean
}

type Callback = (tripVersion: number, product: Product, data: unknown) => Promise<void>

type Options = Partial<{
    onTrigger: () => void,
    onSuccess: (
        item: {
            type: Product['type'],
            data: NonNullable<Awaited<ReturnType<typeof makeProductEditRequest>>>[]
        }
    ) => void,
    onError: (error: Error) => void,
    onFinally: () => void
}>

export function useCartProductUpdate(options: Options): Callback {
    const dispatch = useDispatch();
    const tripId = useSelector((state: AppState) => state.trip.trip_id);
    const trip = useSelector((state: AppState) => state.trip.data_trip);
    const providerContext = useContext(ProviderContext);

    return async (version, item, data) => {
        if (tripId || providerContext.module) {
            try {
                if (options.onTrigger) {
                    options.onTrigger();
                }
                let responses: Awaited<ReturnType<typeof makeProductEditRequest>>[] = [];

                if (
                    item.type === 'accommodation' &&
                    item.roomIds
                ) {
                    responses = await Promise.all(
                        item.roomIds?.map((id) => {
                            return makeProductEditRequest({
                                tripId: providerContext.module ? providerContext.tripId : tripId!,
                                version,
                                tripToken: providerContext.module ? providerContext.tripToken : null,
                                product: {
                                    ...item,
                                    id
                                },
                                data
                            });
                        }) ?? []
                    );
                } else {
                    responses = [
                        await makeProductEditRequest({
                            tripId: providerContext.module ? providerContext.tripId : tripId!,
                            version,
                            tripToken: providerContext.module ? providerContext.tripToken : null,
                            product: item,
                            data
                        })
                    ];
                }

                if (trip) {
                    for (const response of responses) {
                        switch (item.type) {
                            case 'flight': {
                                dispatch({
                                    type: 'FLIGHT_EDIT_CART_BY_ID',
                                    payload: CheckResponse([response], trip.end_date)[0]!
                                });
                                break;
                            }
                            case 'car': {
                                dispatch({
                                    type: 'CAR_EDIT_CART_BY_ID',
                                    payload: response
                                });
                                break;
                            }
                            case 'accommodation': {
                                dispatch({
                                    type: 'ACCOMMODATION_EDIT_CART_BY_ID',
                                    payload: response
                                });
                                break;
                            }
                            case 'transfer': {
                                dispatch({
                                    type: 'TRANSFER_EDIT_CART_BY_ID',
                                    payload: response
                                });
                                break;
                            }
                            case 'poi': {
                                dispatch({
                                    type: 'POI_EDIT_CART_BY_ID',
                                    payload: response
                                });
                                break;
                            }
                            case 'assistance': {
                                dispatch({
                                    type: 'CART_ASSISTANCE_EDIT_BY_ID',
                                    payload: response
                                });
                                break;
                            }
                            default: {
                                dispatch({
                                    type: 'CART_EDIT_MANUAL_ITEM',
                                    payload: response
                                });
                                break;
                            }
                        }
                    }
                }
                if (options.onSuccess && responses) {
                    options.onSuccess({
                        type: item.type,
                        data: responses as {}[]
                    });
                }
            } catch (error: any) {
                if (options.onError) {
                    options.onError(error);
                }
            } finally {
                if (options.onFinally) {
                    options.onFinally();
                }
            }
        }
    };
}
