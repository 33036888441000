import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useGetProductName } from "./getProductName";
import { useFilteredCartProducts } from "../../Itinerary/network/filteredCartProducts";
import { CartPackageDraggableItemProps } from "../CartPackageDraggableItem";
import { AppState } from "../../../Reducers/Reducers";

type Product = {
    key: string,
    startDate: string,
    stackNumber: number | null,
    stackInfoId: number | null,
    isStackPrice: boolean,
} & CartPackageDraggableItemProps

export function useCartSummarizedProducts(): Product[] {
    const { t } = useTranslation();
    const manualProducts = useSelector((state: AppState) => state.cart.manual_item_list);
    const filteredManualProducts = manualProducts?.filter((item) => {
        return ![2, 0, 1, 12, 6, 4].includes(item.product_type) &&
            !item.auto_product;
    }) ?? [];
    const cart = useFilteredCartProducts();
    const getProductName = useGetProductName();

    return useMemo(() => {
        return cart.accommodations.map((item): Product => {
            const from = window.moment.utc(item.accommodation.start_date).format('LL');
            const to = window.moment.utc(item.accommodation.end_date).format('LL');
            return {
                key: `accommodation-${item.type}-${item.accommodation.id}`,
                stackNumber: item.accommodation.stack_number,
                stackInfoId: item.accommodation.stack_info_id,
                isStackPrice: item.accommodation.is_stack_price,
                id: item.accommodation.id,
                type: item.type === 'normal' ?
                    'accommodations' :
                    'manual',
                productType: item.accommodation.product_type,
                isCustom: item.type === 'normal' ?
                    item.accommodation.is_custom :
                    false,
                name: getProductName(
                    item.type === 'normal' ?
                        {
                            type: 'accommodation',
                            product: item.accommodation
                        } :
                        {
                            type: 'manual',
                            product: item.accommodation
                        }
                ),
                startDate: item.accommodation.start_date,
                date: from !== to ?
                    t(
                        'cart-material.cart-construction-products-table-date-html',
                        { from, to }
                    ) :
                    from,
                roomIds: item.accommodation.rooms?.map((item) => {
                    return item.id;
                }) ?? [],
                agent_accepted: item.accommodation.agent_accepted,
                agent_refused: item.accommodation.agent_refused,
                agent_comment: item.accommodation.agent_comment,
                provider_comment: item.accommodation.provider_comment,
                provider_accepted: item.accommodation.provider_accepted,
                provider_created: item.accommodation.provider_created,
                provider_refused: item.accommodation.provider_refused,
                provider_want_modification: item.accommodation.provider_want_modification,
                providerId: item.accommodation.provider,
                counter_offer_of: item.accommodation.counter_offer_of,
                is_stack_price: item.accommodation.is_stack_price,
                stack_number: item.accommodation.stack_number,
                stack_info_id: item.accommodation.stack_info_id
            };
        }).concat(
            cart.cars.map((item): Product => {
                const from = window.moment.utc(item.car.start_date).format('LL');
                const to = window.moment.utc(item.car.end_date).format('LL');
                return {
                    key: `car-${item.type}-${item.car.id}`,
                    stackNumber: item.car.stack_number,
                    stackInfoId: item.car.stack_info_id,
                    isStackPrice: item.car.is_stack_price,
                    id: item.car.id,
                    type: item.type === 'normal' ?
                        'cars' :
                        'manual',
                    productType: item.car.product_type,
                    isCustom: item.type === 'normal' ?
                        item.car.is_custom :
                        false,
                    name: getProductName(
                        item.type === 'normal' ?
                            {
                                type: 'car',
                                product: item.car
                            } :
                            {
                                type: 'manual',
                                product: item.car
                            }
                    ),
                    startDate: item.car.start_date,
                    date: from !== to ?
                        t(
                            'cart-material.cart-construction-products-table-date-html',
                            { from, to }
                        ) :
                        from,
                    agent_accepted: item.car.agent_accepted,
                    agent_refused: item.car.agent_refused,
                    agent_comment: item.car.agent_comment,
                    provider_comment: item.car.provider_comment,
                    provider_accepted: item.car.provider_accepted,
                    provider_created: item.car.provider_created,
                    provider_refused: item.car.provider_refused,
                    provider_want_modification: item.car.provider_want_modification,
                    providerId: item.car.provider,
                    counter_offer_of: item.car.counter_offer_of,
                    is_stack_price: item.car.is_stack_price,
                    stack_number: item.car.stack_number,
                    stack_info_id: item.car.stack_info_id
                };
            })
        ).concat(
            cart.flights.map((item): Product => {
                let name = '';

                if (item.type === 'normal') {
                    const startLeg = item.flight.outbounds[0]?.legs[0];
                    const endOutbound = item.flight.outbounds[item.flight.outbounds.length - 1];
                    const endLeg = endOutbound?.legs[endOutbound.legs.length - 1];
                    name = `${startLeg?.operating_airline?.commercial_name} : ${startLeg?.origin?.airport_code ?? startLeg?.origin_station?.station_code} - ${endLeg?.destination?.airport_code ?? endLeg?.destination_station?.station_code}`;
                } else {
                    const start = item.flight.flight_segment[0]?.origin_airport?.airport_code;
                    const end = item.flight.flight_segment[item.flight.flight_segment.length - 1]?.destination_airport?.airport_code;
                    name = `${item.flight.flight_segment[0]?.airline.commercial_name} : ${start} - ${end}`;
                }

                const from = window.moment.utc(item.flight.start_date).format('LL');
                const to = window.moment.utc(item.flight.end_date).format('LL');

                return {
                    key: `flight-${item.type}-${item.flight.id}`,
                    stackNumber: item.flight.stack_number,
                    stackInfoId: item.flight.stack_info_id,
                    isStackPrice: item.flight.is_stack_price,
                    id: item.flight.id,
                    type: item.type === 'normal' ?
                        'flights' :
                        'manual-flights',
                    productType: item.flight.product_type,
                    isCustom: item.flight.is_custom,
                    name,
                    startDate: item.flight.start_date,
                    date: from !== to ?
                        t(
                            'cart-material.cart-construction-products-table-date-html',
                            { from, to }
                        ) :
                        from,
                    agent_accepted: item.flight.agent_accepted,
                    agent_refused: item.flight.agent_refused,
                    agent_comment: item.flight.agent_comment,
                    provider_comment: item.flight.provider_comment,
                    provider_accepted: item.flight.provider_accepted,
                    provider_created: item.flight.provider_created,
                    provider_refused: item.flight.provider_refused,
                    provider_want_modification: item.flight.provider_want_modification,
                    providerId: item.flight.provider,
                    counter_offer_of: item.flight.counter_offer_of,
                    is_stack_price: item.flight.is_stack_price,
                    stack_number: item.flight.stack_number,
                    stack_info_id: item.flight.stack_info_id
                };
            })
        ).concat(
            cart.pois.map((item): Product => {
                const from = window.moment.utc(item.poi.start_date).format('LL');
                const to = window.moment.utc(item.poi.end_date).format('LL');
                return {
                    key: `poi-${item.type}-${item.poi.id}`,
                    stackNumber: item.poi.stack_number,
                    stackInfoId: item.poi.stack_info_id,
                    isStackPrice: item.poi.is_stack_price,
                    id: item.poi.id,
                    //eslint-disable-next-line no-nested-ternary
                    type: item.type === 'normal' && item.poi.poi_type ?
                        'pois' :
                        item.type === 'normal' ?
                            'activities' :
                            'manual',
                    productType: item.poi.product_type,
                    isCustom: item.type === 'normal',
                    name: getProductName(
                        item.type === 'normal' ?
                            {
                                type: 'poi',
                                product: item.poi
                            } :
                            {
                                type: 'manual',
                                product: item.poi
                            }
                    ),
                    startDate: item.poi.start_date,
                    date: from !== to ?
                        t(
                            'cart-material.cart-construction-products-table-date-html',
                            { from, to }
                        ) :
                        from,
                    agent_accepted: item.poi.agent_accepted,
                    agent_refused: item.poi.agent_refused,
                    agent_comment: item.poi.agent_comment,
                    provider_comment: item.poi.provider_comment,
                    provider_accepted: item.poi.provider_accepted,
                    provider_created: item.poi.provider_created,
                    provider_refused: item.poi.provider_refused,
                    provider_want_modification: item.poi.provider_want_modification,
                    providerId: item.poi.provider,
                    counter_offer_of: item.poi.counter_offer_of,
                    is_stack_price: item.poi.is_stack_price,
                    stack_number: item.poi.stack_number,
                    stack_info_id: item.poi.stack_info_id
                };
            })
        ).concat(
            cart.transfers.map((item): Product => {
                const from = window.moment.utc(item.transfer.start_date).format('LL');
                const to = window.moment.utc(item.transfer.end_date).format('LL');
                return {
                    key: `transfer-${item.type}-${item.transfer.id}`,
                    stackNumber: item.transfer.stack_number,
                    stackInfoId: item.transfer.stack_info_id,
                    isStackPrice: item.transfer.is_stack_price,
                    id: item.transfer.id,
                    type: item.type === 'normal' ?
                        'transfers' :
                        'manual',
                    productType: item.transfer.product_type,
                    isCustom: item.transfer.is_custom,
                    name: getProductName(
                        item.type === 'normal' ?
                            {
                                type: 'transfer',
                                product: item.transfer
                            } :
                            {
                                type: 'manual',
                                product: item.transfer
                            }
                    ),
                    startDate: item.transfer.start_date,
                    date: from !== to ?
                        t(
                            'cart-material.cart-construction-products-table-date-html',
                            { from, to }
                        ) :
                        from,
                    agent_accepted: item.transfer.agent_accepted,
                    agent_refused: item.transfer.agent_refused,
                    agent_comment: item.transfer.agent_comment,
                    provider_comment: item.transfer.provider_comment,
                    provider_accepted: item.transfer.provider_accepted,
                    provider_created: item.transfer.provider_created,
                    provider_refused: item.transfer.provider_refused,
                    provider_want_modification: item.transfer.provider_want_modification,
                    providerId: item.transfer.provider,
                    counter_offer_of: item.transfer.counter_offer_of,
                    is_stack_price: item.transfer.is_stack_price,
                    stack_number: item.transfer.stack_number,
                    stack_info_id: item.transfer.stack_info_id
                };
            })
        ).concat(
            cart.assistances.map((item): Product => {
                const from = window.moment.utc(item.assistance.start_date).format('LL');
                const to = window.moment.utc(item.assistance.end_date).format('LL');
                return {
                    key: `car-${item.type}-${item.assistance.id}`,
                    stackNumber: item.assistance.stack_number,
                    stackInfoId: item.assistance.stack_info_id,
                    isStackPrice: item.assistance.is_stack_price,
                    id: item.assistance.id,
                    type: item.type === 'normal' ?
                        'assistances' :
                        'manual',
                    productType: item.assistance.product_type,
                    isCustom: item.type === 'normal' ?
                        item.assistance.is_custom :
                        false,
                    name: getProductName(
                        item.type === 'normal' ?
                            {
                                type: 'assistance',
                                product: item.assistance
                            } :
                            {
                                type: 'manual',
                                product: item.assistance
                            }
                    ),
                    startDate: item.assistance.start_date,
                    date: from !== to ?
                        t(
                            'cart-material.cart-construction-products-table-date-html',
                            { from, to }
                        ) :
                        from,
                    agent_accepted: item.assistance.agent_accepted,
                    agent_refused: item.assistance.agent_refused,
                    agent_comment: item.assistance.agent_comment,
                    provider_comment: item.assistance.provider_comment,
                    provider_accepted: item.assistance.provider_accepted,
                    provider_created: item.assistance.provider_created,
                    provider_refused: item.assistance.provider_refused,
                    provider_want_modification: item.assistance.provider_want_modification,
                    providerId: item.assistance.provider,
                    counter_offer_of: item.assistance.counter_offer_of,
                    is_stack_price: item.assistance.is_stack_price,
                    stack_number: item.assistance.stack_number,
                    stack_info_id: item.assistance.stack_info_id
                };
            })
        ).concat(
            filteredManualProducts.map((item): Product => {
                const from = window.moment.utc(item.start_date).format('LL');
                const to = window.moment.utc(item.end_date).format('LL');
                return {
                    key: `manual-product-${item.id}`,
                    stackNumber: item.stack_number,
                    stackInfoId: item.stack_info_id,
                    isStackPrice: item.is_stack_price,
                    id: item.id,
                    type: 'manual',
                    productType: item.product_type,
                    isCustom: item.is_custom,
                    name: getProductName({
                        type: 'manual',
                        product: item
                    }),
                    startDate: item.start_date,
                    date: from !== to ?
                        t(
                            'cart-material.cart-construction-products-table-date-html',
                            { from, to }
                        ) :
                        from,
                    agent_accepted: item.agent_accepted,
                    agent_refused: item.agent_refused,
                    agent_comment: item.agent_comment,
                    provider_comment: item.provider_comment,
                    provider_accepted: item.provider_accepted,
                    provider_created: item.provider_created,
                    provider_refused: item.provider_refused,
                    provider_want_modification: item.provider_want_modification,
                    providerId: item.provider,
                    counter_offer_of: item.counter_offer_of,
                    is_stack_price: item.is_stack_price,
                    stack_number: item.stack_number,
                    stack_info_id: item.stack_info_id
                };
            })
        );
    }, [
        cart,
        manualProducts,
        getProductName
    ]);
}
