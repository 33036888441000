import React from "react";
import { SvgIconProps, SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";

export type DynamicIconProps = {
    iconName: string
} & SvgIconProps

export const DynamicIcon = React.forwardRef<SVGSVGElement, DynamicIconProps>(
    (props, ref) => {
        const { iconName, ...rest } = props;

        const Component = Components[iconName];

        if (!Component) {
            return null;
        }

        return (
            <Component ref={ref} {...rest} />
        )
    }
);

const Components: {
    [name: string]: React.LazyExoticComponent<OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
        muiName: string;
    }>
} = {
    Abc: React.lazy(() => import('@mui/icons-material/Abc')),
    AbcOutlined: React.lazy(() => import('@mui/icons-material/AbcOutlined')),
    AbcRounded: React.lazy(() => import('@mui/icons-material/AbcRounded')),
    AbcSharp: React.lazy(() => import('@mui/icons-material/AbcSharp')),
    AbcTwoTone: React.lazy(() => import('@mui/icons-material/AbcTwoTone')),
    AccessAlarm: React.lazy(() => import('@mui/icons-material/AccessAlarm')),
    AccessAlarmOutlined: React.lazy(() => import('@mui/icons-material/AccessAlarmOutlined')),
    AccessAlarmRounded: React.lazy(() => import('@mui/icons-material/AccessAlarmRounded')),
    AccessAlarms: React.lazy(() => import('@mui/icons-material/AccessAlarms')),
    AccessAlarmSharp: React.lazy(() => import('@mui/icons-material/AccessAlarmSharp')),
    AccessAlarmsOutlined: React.lazy(() => import('@mui/icons-material/AccessAlarmsOutlined')),
    AccessAlarmsRounded: React.lazy(() => import('@mui/icons-material/AccessAlarmsRounded')),
    AccessAlarmsSharp: React.lazy(() => import('@mui/icons-material/AccessAlarmsSharp')),
    AccessAlarmsTwoTone: React.lazy(() => import('@mui/icons-material/AccessAlarmsTwoTone')),
    AccessAlarmTwoTone: React.lazy(() => import('@mui/icons-material/AccessAlarmTwoTone')),
    Accessibility: React.lazy(() => import('@mui/icons-material/Accessibility')),
    AccessibilityNew: React.lazy(() => import('@mui/icons-material/AccessibilityNew')),
    AccessibilityNewOutlined: React.lazy(() => import('@mui/icons-material/AccessibilityNewOutlined')),
    AccessibilityNewRounded: React.lazy(() => import('@mui/icons-material/AccessibilityNewRounded')),
    AccessibilityNewSharp: React.lazy(() => import('@mui/icons-material/AccessibilityNewSharp')),
    AccessibilityNewTwoTone: React.lazy(() => import('@mui/icons-material/AccessibilityNewTwoTone')),
    AccessibilityOutlined: React.lazy(() => import('@mui/icons-material/AccessibilityOutlined')),
    AccessibilityRounded: React.lazy(() => import('@mui/icons-material/AccessibilityRounded')),
    AccessibilitySharp: React.lazy(() => import('@mui/icons-material/AccessibilitySharp')),
    AccessibilityTwoTone: React.lazy(() => import('@mui/icons-material/AccessibilityTwoTone')),
    Accessible: React.lazy(() => import('@mui/icons-material/Accessible')),
    AccessibleForward: React.lazy(() => import('@mui/icons-material/AccessibleForward')),
    AccessibleForwardOutlined: React.lazy(() => import('@mui/icons-material/AccessibleForwardOutlined')),
    AccessibleForwardRounded: React.lazy(() => import('@mui/icons-material/AccessibleForwardRounded')),
    AccessibleForwardSharp: React.lazy(() => import('@mui/icons-material/AccessibleForwardSharp')),
    AccessibleForwardTwoTone: React.lazy(() => import('@mui/icons-material/AccessibleForwardTwoTone')),
    AccessibleOutlined: React.lazy(() => import('@mui/icons-material/AccessibleOutlined')),
    AccessibleRounded: React.lazy(() => import('@mui/icons-material/AccessibleRounded')),
    AccessibleSharp: React.lazy(() => import('@mui/icons-material/AccessibleSharp')),
    AccessibleTwoTone: React.lazy(() => import('@mui/icons-material/AccessibleTwoTone')),
    AccessTime: React.lazy(() => import('@mui/icons-material/AccessTime')),
    AccessTimeFilled: React.lazy(() => import('@mui/icons-material/AccessTimeFilled')),
    AccessTimeFilledOutlined: React.lazy(() => import('@mui/icons-material/AccessTimeFilledOutlined')),
    AccessTimeFilledRounded: React.lazy(() => import('@mui/icons-material/AccessTimeFilledRounded')),
    AccessTimeFilledSharp: React.lazy(() => import('@mui/icons-material/AccessTimeFilledSharp')),
    AccessTimeFilledTwoTone: React.lazy(() => import('@mui/icons-material/AccessTimeFilledTwoTone')),
    AccessTimeOutlined: React.lazy(() => import('@mui/icons-material/AccessTimeOutlined')),
    AccessTimeRounded: React.lazy(() => import('@mui/icons-material/AccessTimeRounded')),
    AccessTimeSharp: React.lazy(() => import('@mui/icons-material/AccessTimeSharp')),
    AccessTimeTwoTone: React.lazy(() => import('@mui/icons-material/AccessTimeTwoTone')),
    AccountBalance: React.lazy(() => import('@mui/icons-material/AccountBalance')),
    AccountBalanceOutlined: React.lazy(() => import('@mui/icons-material/AccountBalanceOutlined')),
    AccountBalanceRounded: React.lazy(() => import('@mui/icons-material/AccountBalanceRounded')),
    AccountBalanceSharp: React.lazy(() => import('@mui/icons-material/AccountBalanceSharp')),
    AccountBalanceTwoTone: React.lazy(() => import('@mui/icons-material/AccountBalanceTwoTone')),
    AccountBalanceWallet: React.lazy(() => import('@mui/icons-material/AccountBalanceWallet')),
    AccountBalanceWalletOutlined: React.lazy(() => import('@mui/icons-material/AccountBalanceWalletOutlined')),
    AccountBalanceWalletRounded: React.lazy(() => import('@mui/icons-material/AccountBalanceWalletRounded')),
    AccountBalanceWalletSharp: React.lazy(() => import('@mui/icons-material/AccountBalanceWalletSharp')),
    AccountBalanceWalletTwoTone: React.lazy(() => import('@mui/icons-material/AccountBalanceWalletTwoTone')),
    AccountBox: React.lazy(() => import('@mui/icons-material/AccountBox')),
    AccountBoxOutlined: React.lazy(() => import('@mui/icons-material/AccountBoxOutlined')),
    AccountBoxRounded: React.lazy(() => import('@mui/icons-material/AccountBoxRounded')),
    AccountBoxSharp: React.lazy(() => import('@mui/icons-material/AccountBoxSharp')),
    AccountBoxTwoTone: React.lazy(() => import('@mui/icons-material/AccountBoxTwoTone')),
    AccountCircle: React.lazy(() => import('@mui/icons-material/AccountCircle')),
    AccountCircleOutlined: React.lazy(() => import('@mui/icons-material/AccountCircleOutlined')),
    AccountCircleRounded: React.lazy(() => import('@mui/icons-material/AccountCircleRounded')),
    AccountCircleSharp: React.lazy(() => import('@mui/icons-material/AccountCircleSharp')),
    AccountCircleTwoTone: React.lazy(() => import('@mui/icons-material/AccountCircleTwoTone')),
    AccountTree: React.lazy(() => import('@mui/icons-material/AccountTree')),
    AccountTreeOutlined: React.lazy(() => import('@mui/icons-material/AccountTreeOutlined')),
    AccountTreeRounded: React.lazy(() => import('@mui/icons-material/AccountTreeRounded')),
    AccountTreeSharp: React.lazy(() => import('@mui/icons-material/AccountTreeSharp')),
    AccountTreeTwoTone: React.lazy(() => import('@mui/icons-material/AccountTreeTwoTone')),
    AcUnit: React.lazy(() => import('@mui/icons-material/AcUnit')),
    AcUnitOutlined: React.lazy(() => import('@mui/icons-material/AcUnitOutlined')),
    AcUnitRounded: React.lazy(() => import('@mui/icons-material/AcUnitRounded')),
    AcUnitSharp: React.lazy(() => import('@mui/icons-material/AcUnitSharp')),
    AcUnitTwoTone: React.lazy(() => import('@mui/icons-material/AcUnitTwoTone')),
    Adb: React.lazy(() => import('@mui/icons-material/Adb')),
    AdbOutlined: React.lazy(() => import('@mui/icons-material/AdbOutlined')),
    AdbRounded: React.lazy(() => import('@mui/icons-material/AdbRounded')),
    AdbSharp: React.lazy(() => import('@mui/icons-material/AdbSharp')),
    AdbTwoTone: React.lazy(() => import('@mui/icons-material/AdbTwoTone')),
    AddAlarm: React.lazy(() => import('@mui/icons-material/AddAlarm')),
    AddAlarmOutlined: React.lazy(() => import('@mui/icons-material/AddAlarmOutlined')),
    AddAlarmRounded: React.lazy(() => import('@mui/icons-material/AddAlarmRounded')),
    AddAlarmSharp: React.lazy(() => import('@mui/icons-material/AddAlarmSharp')),
    AddAlarmTwoTone: React.lazy(() => import('@mui/icons-material/AddAlarmTwoTone')),
    AddAlert: React.lazy(() => import('@mui/icons-material/AddAlert')),
    AddAlertOutlined: React.lazy(() => import('@mui/icons-material/AddAlertOutlined')),
    AddAlertRounded: React.lazy(() => import('@mui/icons-material/AddAlertRounded')),
    AddAlertSharp: React.lazy(() => import('@mui/icons-material/AddAlertSharp')),
    AddAlertTwoTone: React.lazy(() => import('@mui/icons-material/AddAlertTwoTone')),
    AddAPhoto: React.lazy(() => import('@mui/icons-material/AddAPhoto')),
    AddAPhotoOutlined: React.lazy(() => import('@mui/icons-material/AddAPhotoOutlined')),
    AddAPhotoRounded: React.lazy(() => import('@mui/icons-material/AddAPhotoRounded')),
    AddAPhotoSharp: React.lazy(() => import('@mui/icons-material/AddAPhotoSharp')),
    AddAPhotoTwoTone: React.lazy(() => import('@mui/icons-material/AddAPhotoTwoTone')),
    AddBox: React.lazy(() => import('@mui/icons-material/AddBox')),
    AddBoxOutlined: React.lazy(() => import('@mui/icons-material/AddBoxOutlined')),
    AddBoxRounded: React.lazy(() => import('@mui/icons-material/AddBoxRounded')),
    AddBoxSharp: React.lazy(() => import('@mui/icons-material/AddBoxSharp')),
    AddBoxTwoTone: React.lazy(() => import('@mui/icons-material/AddBoxTwoTone')),
    AddBusiness: React.lazy(() => import('@mui/icons-material/AddBusiness')),
    AddBusinessOutlined: React.lazy(() => import('@mui/icons-material/AddBusinessOutlined')),
    AddBusinessRounded: React.lazy(() => import('@mui/icons-material/AddBusinessRounded')),
    AddBusinessSharp: React.lazy(() => import('@mui/icons-material/AddBusinessSharp')),
    AddBusinessTwoTone: React.lazy(() => import('@mui/icons-material/AddBusinessTwoTone')),
    AddCard: React.lazy(() => import('@mui/icons-material/AddCard')),
    AddCardOutlined: React.lazy(() => import('@mui/icons-material/AddCardOutlined')),
    AddCardRounded: React.lazy(() => import('@mui/icons-material/AddCardRounded')),
    AddCardSharp: React.lazy(() => import('@mui/icons-material/AddCardSharp')),
    AddCardTwoTone: React.lazy(() => import('@mui/icons-material/AddCardTwoTone')),
    Addchart: React.lazy(() => import('@mui/icons-material/Addchart')),
    AddchartOutlined: React.lazy(() => import('@mui/icons-material/AddchartOutlined')),
    AddchartRounded: React.lazy(() => import('@mui/icons-material/AddchartRounded')),
    AddchartSharp: React.lazy(() => import('@mui/icons-material/AddchartSharp')),
    AddchartTwoTone: React.lazy(() => import('@mui/icons-material/AddchartTwoTone')),
    AddCircle: React.lazy(() => import('@mui/icons-material/AddCircle')),
    AddCircleOutlined: React.lazy(() => import('@mui/icons-material/AddCircleOutlined')),
    AddCircleOutline: React.lazy(() => import('@mui/icons-material/AddCircleOutline')),
    AddCircleOutlineOutlined: React.lazy(() => import('@mui/icons-material/AddCircleOutlineOutlined')),
    AddCircleOutlineRounded: React.lazy(() => import('@mui/icons-material/AddCircleOutlineRounded')),
    AddCircleOutlineSharp: React.lazy(() => import('@mui/icons-material/AddCircleOutlineSharp')),
    AddCircleOutlineTwoTone: React.lazy(() => import('@mui/icons-material/AddCircleOutlineTwoTone')),
    AddCircleRounded: React.lazy(() => import('@mui/icons-material/AddCircleRounded')),
    AddCircleSharp: React.lazy(() => import('@mui/icons-material/AddCircleSharp')),
    AddCircleTwoTone: React.lazy(() => import('@mui/icons-material/AddCircleTwoTone')),
    AddComment: React.lazy(() => import('@mui/icons-material/AddComment')),
    AddCommentOutlined: React.lazy(() => import('@mui/icons-material/AddCommentOutlined')),
    AddCommentRounded: React.lazy(() => import('@mui/icons-material/AddCommentRounded')),
    AddCommentSharp: React.lazy(() => import('@mui/icons-material/AddCommentSharp')),
    AddCommentTwoTone: React.lazy(() => import('@mui/icons-material/AddCommentTwoTone')),
    Add: React.lazy(() => import('@mui/icons-material/Add')),
    AddHome: React.lazy(() => import('@mui/icons-material/AddHome')),
    AddHomeOutlined: React.lazy(() => import('@mui/icons-material/AddHomeOutlined')),
    AddHomeRounded: React.lazy(() => import('@mui/icons-material/AddHomeRounded')),
    AddHomeSharp: React.lazy(() => import('@mui/icons-material/AddHomeSharp')),
    AddHomeTwoTone: React.lazy(() => import('@mui/icons-material/AddHomeTwoTone')),
    AddHomeWork: React.lazy(() => import('@mui/icons-material/AddHomeWork')),
    AddHomeWorkOutlined: React.lazy(() => import('@mui/icons-material/AddHomeWorkOutlined')),
    AddHomeWorkRounded: React.lazy(() => import('@mui/icons-material/AddHomeWorkRounded')),
    AddHomeWorkSharp: React.lazy(() => import('@mui/icons-material/AddHomeWorkSharp')),
    AddHomeWorkTwoTone: React.lazy(() => import('@mui/icons-material/AddHomeWorkTwoTone')),
    AddIcCall: React.lazy(() => import('@mui/icons-material/AddIcCall')),
    AddIcCallOutlined: React.lazy(() => import('@mui/icons-material/AddIcCallOutlined')),
    AddIcCallRounded: React.lazy(() => import('@mui/icons-material/AddIcCallRounded')),
    AddIcCallSharp: React.lazy(() => import('@mui/icons-material/AddIcCallSharp')),
    AddIcCallTwoTone: React.lazy(() => import('@mui/icons-material/AddIcCallTwoTone')),
    AddLink: React.lazy(() => import('@mui/icons-material/AddLink')),
    AddLinkOutlined: React.lazy(() => import('@mui/icons-material/AddLinkOutlined')),
    AddLinkRounded: React.lazy(() => import('@mui/icons-material/AddLinkRounded')),
    AddLinkSharp: React.lazy(() => import('@mui/icons-material/AddLinkSharp')),
    AddLinkTwoTone: React.lazy(() => import('@mui/icons-material/AddLinkTwoTone')),
    AddLocationAlt: React.lazy(() => import('@mui/icons-material/AddLocationAlt')),
    AddLocationAltOutlined: React.lazy(() => import('@mui/icons-material/AddLocationAltOutlined')),
    AddLocationAltRounded: React.lazy(() => import('@mui/icons-material/AddLocationAltRounded')),
    AddLocationAltSharp: React.lazy(() => import('@mui/icons-material/AddLocationAltSharp')),
    AddLocationAltTwoTone: React.lazy(() => import('@mui/icons-material/AddLocationAltTwoTone')),
    AddLocation: React.lazy(() => import('@mui/icons-material/AddLocation')),
    AddLocationOutlined: React.lazy(() => import('@mui/icons-material/AddLocationOutlined')),
    AddLocationRounded: React.lazy(() => import('@mui/icons-material/AddLocationRounded')),
    AddLocationSharp: React.lazy(() => import('@mui/icons-material/AddLocationSharp')),
    AddLocationTwoTone: React.lazy(() => import('@mui/icons-material/AddLocationTwoTone')),
    AddModerator: React.lazy(() => import('@mui/icons-material/AddModerator')),
    AddModeratorOutlined: React.lazy(() => import('@mui/icons-material/AddModeratorOutlined')),
    AddModeratorRounded: React.lazy(() => import('@mui/icons-material/AddModeratorRounded')),
    AddModeratorSharp: React.lazy(() => import('@mui/icons-material/AddModeratorSharp')),
    AddModeratorTwoTone: React.lazy(() => import('@mui/icons-material/AddModeratorTwoTone')),
    AddOutlined: React.lazy(() => import('@mui/icons-material/AddOutlined')),
    AddPhotoAlternate: React.lazy(() => import('@mui/icons-material/AddPhotoAlternate')),
    AddPhotoAlternateOutlined: React.lazy(() => import('@mui/icons-material/AddPhotoAlternateOutlined')),
    AddPhotoAlternateRounded: React.lazy(() => import('@mui/icons-material/AddPhotoAlternateRounded')),
    AddPhotoAlternateSharp: React.lazy(() => import('@mui/icons-material/AddPhotoAlternateSharp')),
    AddPhotoAlternateTwoTone: React.lazy(() => import('@mui/icons-material/AddPhotoAlternateTwoTone')),
    AddReaction: React.lazy(() => import('@mui/icons-material/AddReaction')),
    AddReactionOutlined: React.lazy(() => import('@mui/icons-material/AddReactionOutlined')),
    AddReactionRounded: React.lazy(() => import('@mui/icons-material/AddReactionRounded')),
    AddReactionSharp: React.lazy(() => import('@mui/icons-material/AddReactionSharp')),
    AddReactionTwoTone: React.lazy(() => import('@mui/icons-material/AddReactionTwoTone')),
    AddRoad: React.lazy(() => import('@mui/icons-material/AddRoad')),
    AddRoadOutlined: React.lazy(() => import('@mui/icons-material/AddRoadOutlined')),
    AddRoadRounded: React.lazy(() => import('@mui/icons-material/AddRoadRounded')),
    AddRoadSharp: React.lazy(() => import('@mui/icons-material/AddRoadSharp')),
    AddRoadTwoTone: React.lazy(() => import('@mui/icons-material/AddRoadTwoTone')),
    AddRounded: React.lazy(() => import('@mui/icons-material/AddRounded')),
    AddSharp: React.lazy(() => import('@mui/icons-material/AddSharp')),
    AddShoppingCart: React.lazy(() => import('@mui/icons-material/AddShoppingCart')),
    AddShoppingCartOutlined: React.lazy(() => import('@mui/icons-material/AddShoppingCartOutlined')),
    AddShoppingCartRounded: React.lazy(() => import('@mui/icons-material/AddShoppingCartRounded')),
    AddShoppingCartSharp: React.lazy(() => import('@mui/icons-material/AddShoppingCartSharp')),
    AddShoppingCartTwoTone: React.lazy(() => import('@mui/icons-material/AddShoppingCartTwoTone')),
    AddTask: React.lazy(() => import('@mui/icons-material/AddTask')),
    AddTaskOutlined: React.lazy(() => import('@mui/icons-material/AddTaskOutlined')),
    AddTaskRounded: React.lazy(() => import('@mui/icons-material/AddTaskRounded')),
    AddTaskSharp: React.lazy(() => import('@mui/icons-material/AddTaskSharp')),
    AddTaskTwoTone: React.lazy(() => import('@mui/icons-material/AddTaskTwoTone')),
    AddToDrive: React.lazy(() => import('@mui/icons-material/AddToDrive')),
    AddToDriveOutlined: React.lazy(() => import('@mui/icons-material/AddToDriveOutlined')),
    AddToDriveRounded: React.lazy(() => import('@mui/icons-material/AddToDriveRounded')),
    AddToDriveSharp: React.lazy(() => import('@mui/icons-material/AddToDriveSharp')),
    AddToDriveTwoTone: React.lazy(() => import('@mui/icons-material/AddToDriveTwoTone')),
    AddToHomeScreen: React.lazy(() => import('@mui/icons-material/AddToHomeScreen')),
    AddToHomeScreenOutlined: React.lazy(() => import('@mui/icons-material/AddToHomeScreenOutlined')),
    AddToHomeScreenRounded: React.lazy(() => import('@mui/icons-material/AddToHomeScreenRounded')),
    AddToHomeScreenSharp: React.lazy(() => import('@mui/icons-material/AddToHomeScreenSharp')),
    AddToHomeScreenTwoTone: React.lazy(() => import('@mui/icons-material/AddToHomeScreenTwoTone')),
    AddToPhotos: React.lazy(() => import('@mui/icons-material/AddToPhotos')),
    AddToPhotosOutlined: React.lazy(() => import('@mui/icons-material/AddToPhotosOutlined')),
    AddToPhotosRounded: React.lazy(() => import('@mui/icons-material/AddToPhotosRounded')),
    AddToPhotosSharp: React.lazy(() => import('@mui/icons-material/AddToPhotosSharp')),
    AddToPhotosTwoTone: React.lazy(() => import('@mui/icons-material/AddToPhotosTwoTone')),
    AddToQueue: React.lazy(() => import('@mui/icons-material/AddToQueue')),
    AddToQueueOutlined: React.lazy(() => import('@mui/icons-material/AddToQueueOutlined')),
    AddToQueueRounded: React.lazy(() => import('@mui/icons-material/AddToQueueRounded')),
    AddToQueueSharp: React.lazy(() => import('@mui/icons-material/AddToQueueSharp')),
    AddToQueueTwoTone: React.lazy(() => import('@mui/icons-material/AddToQueueTwoTone')),
    AddTwoTone: React.lazy(() => import('@mui/icons-material/AddTwoTone')),
    AdfScanner: React.lazy(() => import('@mui/icons-material/AdfScanner')),
    AdfScannerOutlined: React.lazy(() => import('@mui/icons-material/AdfScannerOutlined')),
    AdfScannerRounded: React.lazy(() => import('@mui/icons-material/AdfScannerRounded')),
    AdfScannerSharp: React.lazy(() => import('@mui/icons-material/AdfScannerSharp')),
    AdfScannerTwoTone: React.lazy(() => import('@mui/icons-material/AdfScannerTwoTone')),
    Adjust: React.lazy(() => import('@mui/icons-material/Adjust')),
    AdjustOutlined: React.lazy(() => import('@mui/icons-material/AdjustOutlined')),
    AdjustRounded: React.lazy(() => import('@mui/icons-material/AdjustRounded')),
    AdjustSharp: React.lazy(() => import('@mui/icons-material/AdjustSharp')),
    AdjustTwoTone: React.lazy(() => import('@mui/icons-material/AdjustTwoTone')),
    AdminPanelSettings: React.lazy(() => import('@mui/icons-material/AdminPanelSettings')),
    AdminPanelSettingsOutlined: React.lazy(() => import('@mui/icons-material/AdminPanelSettingsOutlined')),
    AdminPanelSettingsRounded: React.lazy(() => import('@mui/icons-material/AdminPanelSettingsRounded')),
    AdminPanelSettingsSharp: React.lazy(() => import('@mui/icons-material/AdminPanelSettingsSharp')),
    AdminPanelSettingsTwoTone: React.lazy(() => import('@mui/icons-material/AdminPanelSettingsTwoTone')),
    AdUnits: React.lazy(() => import('@mui/icons-material/AdUnits')),
    AdUnitsOutlined: React.lazy(() => import('@mui/icons-material/AdUnitsOutlined')),
    AdUnitsRounded: React.lazy(() => import('@mui/icons-material/AdUnitsRounded')),
    AdUnitsSharp: React.lazy(() => import('@mui/icons-material/AdUnitsSharp')),
    AdUnitsTwoTone: React.lazy(() => import('@mui/icons-material/AdUnitsTwoTone')),
    Agriculture: React.lazy(() => import('@mui/icons-material/Agriculture')),
    AgricultureOutlined: React.lazy(() => import('@mui/icons-material/AgricultureOutlined')),
    AgricultureRounded: React.lazy(() => import('@mui/icons-material/AgricultureRounded')),
    AgricultureSharp: React.lazy(() => import('@mui/icons-material/AgricultureSharp')),
    AgricultureTwoTone: React.lazy(() => import('@mui/icons-material/AgricultureTwoTone')),
    Air: React.lazy(() => import('@mui/icons-material/Air')),
    Airlines: React.lazy(() => import('@mui/icons-material/Airlines')),
    AirlineSeatFlatAngled: React.lazy(() => import('@mui/icons-material/AirlineSeatFlatAngled')),
    AirlineSeatFlatAngledOutlined: React.lazy(() => import('@mui/icons-material/AirlineSeatFlatAngledOutlined')),
    AirlineSeatFlatAngledRounded: React.lazy(() => import('@mui/icons-material/AirlineSeatFlatAngledRounded')),
    AirlineSeatFlatAngledSharp: React.lazy(() => import('@mui/icons-material/AirlineSeatFlatAngledSharp')),
    AirlineSeatFlatAngledTwoTone: React.lazy(() => import('@mui/icons-material/AirlineSeatFlatAngledTwoTone')),
    AirlineSeatFlat: React.lazy(() => import('@mui/icons-material/AirlineSeatFlat')),
    AirlineSeatFlatOutlined: React.lazy(() => import('@mui/icons-material/AirlineSeatFlatOutlined')),
    AirlineSeatFlatRounded: React.lazy(() => import('@mui/icons-material/AirlineSeatFlatRounded')),
    AirlineSeatFlatSharp: React.lazy(() => import('@mui/icons-material/AirlineSeatFlatSharp')),
    AirlineSeatFlatTwoTone: React.lazy(() => import('@mui/icons-material/AirlineSeatFlatTwoTone')),
    AirlineSeatIndividualSuite: React.lazy(() => import('@mui/icons-material/AirlineSeatIndividualSuite')),
    AirlineSeatIndividualSuiteOutlined: React.lazy(() => import('@mui/icons-material/AirlineSeatIndividualSuiteOutlined')),
    AirlineSeatIndividualSuiteRounded: React.lazy(() => import('@mui/icons-material/AirlineSeatIndividualSuiteRounded')),
    AirlineSeatIndividualSuiteSharp: React.lazy(() => import('@mui/icons-material/AirlineSeatIndividualSuiteSharp')),
    AirlineSeatIndividualSuiteTwoTone: React.lazy(() => import('@mui/icons-material/AirlineSeatIndividualSuiteTwoTone')),
    AirlineSeatLegroomExtra: React.lazy(() => import('@mui/icons-material/AirlineSeatLegroomExtra')),
    AirlineSeatLegroomExtraOutlined: React.lazy(() => import('@mui/icons-material/AirlineSeatLegroomExtraOutlined')),
    AirlineSeatLegroomExtraRounded: React.lazy(() => import('@mui/icons-material/AirlineSeatLegroomExtraRounded')),
    AirlineSeatLegroomExtraSharp: React.lazy(() => import('@mui/icons-material/AirlineSeatLegroomExtraSharp')),
    AirlineSeatLegroomExtraTwoTone: React.lazy(() => import('@mui/icons-material/AirlineSeatLegroomExtraTwoTone')),
    AirlineSeatLegroomNormal: React.lazy(() => import('@mui/icons-material/AirlineSeatLegroomNormal')),
    AirlineSeatLegroomNormalOutlined: React.lazy(() => import('@mui/icons-material/AirlineSeatLegroomNormalOutlined')),
    AirlineSeatLegroomNormalRounded: React.lazy(() => import('@mui/icons-material/AirlineSeatLegroomNormalRounded')),
    AirlineSeatLegroomNormalSharp: React.lazy(() => import('@mui/icons-material/AirlineSeatLegroomNormalSharp')),
    AirlineSeatLegroomNormalTwoTone: React.lazy(() => import('@mui/icons-material/AirlineSeatLegroomNormalTwoTone')),
    AirlineSeatLegroomReduced: React.lazy(() => import('@mui/icons-material/AirlineSeatLegroomReduced')),
    AirlineSeatLegroomReducedOutlined: React.lazy(() => import('@mui/icons-material/AirlineSeatLegroomReducedOutlined')),
    AirlineSeatLegroomReducedRounded: React.lazy(() => import('@mui/icons-material/AirlineSeatLegroomReducedRounded')),
    AirlineSeatLegroomReducedSharp: React.lazy(() => import('@mui/icons-material/AirlineSeatLegroomReducedSharp')),
    AirlineSeatLegroomReducedTwoTone: React.lazy(() => import('@mui/icons-material/AirlineSeatLegroomReducedTwoTone')),
    AirlineSeatReclineExtra: React.lazy(() => import('@mui/icons-material/AirlineSeatReclineExtra')),
    AirlineSeatReclineExtraOutlined: React.lazy(() => import('@mui/icons-material/AirlineSeatReclineExtraOutlined')),
    AirlineSeatReclineExtraRounded: React.lazy(() => import('@mui/icons-material/AirlineSeatReclineExtraRounded')),
    AirlineSeatReclineExtraSharp: React.lazy(() => import('@mui/icons-material/AirlineSeatReclineExtraSharp')),
    AirlineSeatReclineExtraTwoTone: React.lazy(() => import('@mui/icons-material/AirlineSeatReclineExtraTwoTone')),
    AirlineSeatReclineNormal: React.lazy(() => import('@mui/icons-material/AirlineSeatReclineNormal')),
    AirlineSeatReclineNormalOutlined: React.lazy(() => import('@mui/icons-material/AirlineSeatReclineNormalOutlined')),
    AirlineSeatReclineNormalRounded: React.lazy(() => import('@mui/icons-material/AirlineSeatReclineNormalRounded')),
    AirlineSeatReclineNormalSharp: React.lazy(() => import('@mui/icons-material/AirlineSeatReclineNormalSharp')),
    AirlineSeatReclineNormalTwoTone: React.lazy(() => import('@mui/icons-material/AirlineSeatReclineNormalTwoTone')),
    AirlinesOutlined: React.lazy(() => import('@mui/icons-material/AirlinesOutlined')),
    AirlinesRounded: React.lazy(() => import('@mui/icons-material/AirlinesRounded')),
    AirlinesSharp: React.lazy(() => import('@mui/icons-material/AirlinesSharp')),
    AirlineStops: React.lazy(() => import('@mui/icons-material/AirlineStops')),
    AirlineStopsOutlined: React.lazy(() => import('@mui/icons-material/AirlineStopsOutlined')),
    AirlineStopsRounded: React.lazy(() => import('@mui/icons-material/AirlineStopsRounded')),
    AirlineStopsSharp: React.lazy(() => import('@mui/icons-material/AirlineStopsSharp')),
    AirlineStopsTwoTone: React.lazy(() => import('@mui/icons-material/AirlineStopsTwoTone')),
    AirlinesTwoTone: React.lazy(() => import('@mui/icons-material/AirlinesTwoTone')),
    AirOutlined: React.lazy(() => import('@mui/icons-material/AirOutlined')),
    AirplanemodeActive: React.lazy(() => import('@mui/icons-material/AirplanemodeActive')),
    AirplanemodeActiveOutlined: React.lazy(() => import('@mui/icons-material/AirplanemodeActiveOutlined')),
    AirplanemodeActiveRounded: React.lazy(() => import('@mui/icons-material/AirplanemodeActiveRounded')),
    AirplanemodeActiveSharp: React.lazy(() => import('@mui/icons-material/AirplanemodeActiveSharp')),
    AirplanemodeActiveTwoTone: React.lazy(() => import('@mui/icons-material/AirplanemodeActiveTwoTone')),
    AirplanemodeInactive: React.lazy(() => import('@mui/icons-material/AirplanemodeInactive')),
    AirplanemodeInactiveOutlined: React.lazy(() => import('@mui/icons-material/AirplanemodeInactiveOutlined')),
    AirplanemodeInactiveRounded: React.lazy(() => import('@mui/icons-material/AirplanemodeInactiveRounded')),
    AirplanemodeInactiveSharp: React.lazy(() => import('@mui/icons-material/AirplanemodeInactiveSharp')),
    AirplanemodeInactiveTwoTone: React.lazy(() => import('@mui/icons-material/AirplanemodeInactiveTwoTone')),
    AirplaneTicket: React.lazy(() => import('@mui/icons-material/AirplaneTicket')),
    AirplaneTicketOutlined: React.lazy(() => import('@mui/icons-material/AirplaneTicketOutlined')),
    AirplaneTicketRounded: React.lazy(() => import('@mui/icons-material/AirplaneTicketRounded')),
    AirplaneTicketSharp: React.lazy(() => import('@mui/icons-material/AirplaneTicketSharp')),
    AirplaneTicketTwoTone: React.lazy(() => import('@mui/icons-material/AirplaneTicketTwoTone')),
    Airplay: React.lazy(() => import('@mui/icons-material/Airplay')),
    AirplayOutlined: React.lazy(() => import('@mui/icons-material/AirplayOutlined')),
    AirplayRounded: React.lazy(() => import('@mui/icons-material/AirplayRounded')),
    AirplaySharp: React.lazy(() => import('@mui/icons-material/AirplaySharp')),
    AirplayTwoTone: React.lazy(() => import('@mui/icons-material/AirplayTwoTone')),
    AirportShuttle: React.lazy(() => import('@mui/icons-material/AirportShuttle')),
    AirportShuttleOutlined: React.lazy(() => import('@mui/icons-material/AirportShuttleOutlined')),
    AirportShuttleRounded: React.lazy(() => import('@mui/icons-material/AirportShuttleRounded')),
    AirportShuttleSharp: React.lazy(() => import('@mui/icons-material/AirportShuttleSharp')),
    AirportShuttleTwoTone: React.lazy(() => import('@mui/icons-material/AirportShuttleTwoTone')),
    AirRounded: React.lazy(() => import('@mui/icons-material/AirRounded')),
    AirSharp: React.lazy(() => import('@mui/icons-material/AirSharp')),
    AirTwoTone: React.lazy(() => import('@mui/icons-material/AirTwoTone')),
    AlarmAdd: React.lazy(() => import('@mui/icons-material/AlarmAdd')),
    AlarmAddOutlined: React.lazy(() => import('@mui/icons-material/AlarmAddOutlined')),
    AlarmAddRounded: React.lazy(() => import('@mui/icons-material/AlarmAddRounded')),
    AlarmAddSharp: React.lazy(() => import('@mui/icons-material/AlarmAddSharp')),
    AlarmAddTwoTone: React.lazy(() => import('@mui/icons-material/AlarmAddTwoTone')),
    Alarm: React.lazy(() => import('@mui/icons-material/Alarm')),
    AlarmOff: React.lazy(() => import('@mui/icons-material/AlarmOff')),
    AlarmOffOutlined: React.lazy(() => import('@mui/icons-material/AlarmOffOutlined')),
    AlarmOffRounded: React.lazy(() => import('@mui/icons-material/AlarmOffRounded')),
    AlarmOffSharp: React.lazy(() => import('@mui/icons-material/AlarmOffSharp')),
    AlarmOffTwoTone: React.lazy(() => import('@mui/icons-material/AlarmOffTwoTone')),
    AlarmOn: React.lazy(() => import('@mui/icons-material/AlarmOn')),
    AlarmOnOutlined: React.lazy(() => import('@mui/icons-material/AlarmOnOutlined')),
    AlarmOnRounded: React.lazy(() => import('@mui/icons-material/AlarmOnRounded')),
    AlarmOnSharp: React.lazy(() => import('@mui/icons-material/AlarmOnSharp')),
    AlarmOnTwoTone: React.lazy(() => import('@mui/icons-material/AlarmOnTwoTone')),
    AlarmOutlined: React.lazy(() => import('@mui/icons-material/AlarmOutlined')),
    AlarmRounded: React.lazy(() => import('@mui/icons-material/AlarmRounded')),
    AlarmSharp: React.lazy(() => import('@mui/icons-material/AlarmSharp')),
    AlarmTwoTone: React.lazy(() => import('@mui/icons-material/AlarmTwoTone')),
    Album: React.lazy(() => import('@mui/icons-material/Album')),
    AlbumOutlined: React.lazy(() => import('@mui/icons-material/AlbumOutlined')),
    AlbumRounded: React.lazy(() => import('@mui/icons-material/AlbumRounded')),
    AlbumSharp: React.lazy(() => import('@mui/icons-material/AlbumSharp')),
    AlbumTwoTone: React.lazy(() => import('@mui/icons-material/AlbumTwoTone')),
    AlignHorizontalCenter: React.lazy(() => import('@mui/icons-material/AlignHorizontalCenter')),
    AlignHorizontalCenterOutlined: React.lazy(() => import('@mui/icons-material/AlignHorizontalCenterOutlined')),
    AlignHorizontalCenterRounded: React.lazy(() => import('@mui/icons-material/AlignHorizontalCenterRounded')),
    AlignHorizontalCenterSharp: React.lazy(() => import('@mui/icons-material/AlignHorizontalCenterSharp')),
    AlignHorizontalCenterTwoTone: React.lazy(() => import('@mui/icons-material/AlignHorizontalCenterTwoTone')),
    AlignHorizontalLeft: React.lazy(() => import('@mui/icons-material/AlignHorizontalLeft')),
    AlignHorizontalLeftOutlined: React.lazy(() => import('@mui/icons-material/AlignHorizontalLeftOutlined')),
    AlignHorizontalLeftRounded: React.lazy(() => import('@mui/icons-material/AlignHorizontalLeftRounded')),
    AlignHorizontalLeftSharp: React.lazy(() => import('@mui/icons-material/AlignHorizontalLeftSharp')),
    AlignHorizontalLeftTwoTone: React.lazy(() => import('@mui/icons-material/AlignHorizontalLeftTwoTone')),
    AlignHorizontalRight: React.lazy(() => import('@mui/icons-material/AlignHorizontalRight')),
    AlignHorizontalRightOutlined: React.lazy(() => import('@mui/icons-material/AlignHorizontalRightOutlined')),
    AlignHorizontalRightRounded: React.lazy(() => import('@mui/icons-material/AlignHorizontalRightRounded')),
    AlignHorizontalRightSharp: React.lazy(() => import('@mui/icons-material/AlignHorizontalRightSharp')),
    AlignHorizontalRightTwoTone: React.lazy(() => import('@mui/icons-material/AlignHorizontalRightTwoTone')),
    AlignVerticalBottom: React.lazy(() => import('@mui/icons-material/AlignVerticalBottom')),
    AlignVerticalBottomOutlined: React.lazy(() => import('@mui/icons-material/AlignVerticalBottomOutlined')),
    AlignVerticalBottomRounded: React.lazy(() => import('@mui/icons-material/AlignVerticalBottomRounded')),
    AlignVerticalBottomSharp: React.lazy(() => import('@mui/icons-material/AlignVerticalBottomSharp')),
    AlignVerticalBottomTwoTone: React.lazy(() => import('@mui/icons-material/AlignVerticalBottomTwoTone')),
    AlignVerticalCenter: React.lazy(() => import('@mui/icons-material/AlignVerticalCenter')),
    AlignVerticalCenterOutlined: React.lazy(() => import('@mui/icons-material/AlignVerticalCenterOutlined')),
    AlignVerticalCenterRounded: React.lazy(() => import('@mui/icons-material/AlignVerticalCenterRounded')),
    AlignVerticalCenterSharp: React.lazy(() => import('@mui/icons-material/AlignVerticalCenterSharp')),
    AlignVerticalCenterTwoTone: React.lazy(() => import('@mui/icons-material/AlignVerticalCenterTwoTone')),
    AlignVerticalTop: React.lazy(() => import('@mui/icons-material/AlignVerticalTop')),
    AlignVerticalTopOutlined: React.lazy(() => import('@mui/icons-material/AlignVerticalTopOutlined')),
    AlignVerticalTopRounded: React.lazy(() => import('@mui/icons-material/AlignVerticalTopRounded')),
    AlignVerticalTopSharp: React.lazy(() => import('@mui/icons-material/AlignVerticalTopSharp')),
    AlignVerticalTopTwoTone: React.lazy(() => import('@mui/icons-material/AlignVerticalTopTwoTone')),
    AllInbox: React.lazy(() => import('@mui/icons-material/AllInbox')),
    AllInboxOutlined: React.lazy(() => import('@mui/icons-material/AllInboxOutlined')),
    AllInboxRounded: React.lazy(() => import('@mui/icons-material/AllInboxRounded')),
    AllInboxSharp: React.lazy(() => import('@mui/icons-material/AllInboxSharp')),
    AllInboxTwoTone: React.lazy(() => import('@mui/icons-material/AllInboxTwoTone')),
    AllInclusive: React.lazy(() => import('@mui/icons-material/AllInclusive')),
    AllInclusiveOutlined: React.lazy(() => import('@mui/icons-material/AllInclusiveOutlined')),
    AllInclusiveRounded: React.lazy(() => import('@mui/icons-material/AllInclusiveRounded')),
    AllInclusiveSharp: React.lazy(() => import('@mui/icons-material/AllInclusiveSharp')),
    AllInclusiveTwoTone: React.lazy(() => import('@mui/icons-material/AllInclusiveTwoTone')),
    AllOut: React.lazy(() => import('@mui/icons-material/AllOut')),
    AllOutOutlined: React.lazy(() => import('@mui/icons-material/AllOutOutlined')),
    AllOutRounded: React.lazy(() => import('@mui/icons-material/AllOutRounded')),
    AllOutSharp: React.lazy(() => import('@mui/icons-material/AllOutSharp')),
    AllOutTwoTone: React.lazy(() => import('@mui/icons-material/AllOutTwoTone')),
    AlternateEmail: React.lazy(() => import('@mui/icons-material/AlternateEmail')),
    AlternateEmailOutlined: React.lazy(() => import('@mui/icons-material/AlternateEmailOutlined')),
    AlternateEmailRounded: React.lazy(() => import('@mui/icons-material/AlternateEmailRounded')),
    AlternateEmailSharp: React.lazy(() => import('@mui/icons-material/AlternateEmailSharp')),
    AlternateEmailTwoTone: React.lazy(() => import('@mui/icons-material/AlternateEmailTwoTone')),
    AltRoute: React.lazy(() => import('@mui/icons-material/AltRoute')),
    AltRouteOutlined: React.lazy(() => import('@mui/icons-material/AltRouteOutlined')),
    AltRouteRounded: React.lazy(() => import('@mui/icons-material/AltRouteRounded')),
    AltRouteSharp: React.lazy(() => import('@mui/icons-material/AltRouteSharp')),
    AltRouteTwoTone: React.lazy(() => import('@mui/icons-material/AltRouteTwoTone')),
    Analytics: React.lazy(() => import('@mui/icons-material/Analytics')),
    AnalyticsOutlined: React.lazy(() => import('@mui/icons-material/AnalyticsOutlined')),
    AnalyticsRounded: React.lazy(() => import('@mui/icons-material/AnalyticsRounded')),
    AnalyticsSharp: React.lazy(() => import('@mui/icons-material/AnalyticsSharp')),
    AnalyticsTwoTone: React.lazy(() => import('@mui/icons-material/AnalyticsTwoTone')),
    Anchor: React.lazy(() => import('@mui/icons-material/Anchor')),
    AnchorOutlined: React.lazy(() => import('@mui/icons-material/AnchorOutlined')),
    AnchorRounded: React.lazy(() => import('@mui/icons-material/AnchorRounded')),
    AnchorSharp: React.lazy(() => import('@mui/icons-material/AnchorSharp')),
    AnchorTwoTone: React.lazy(() => import('@mui/icons-material/AnchorTwoTone')),
    Android: React.lazy(() => import('@mui/icons-material/Android')),
    AndroidOutlined: React.lazy(() => import('@mui/icons-material/AndroidOutlined')),
    AndroidRounded: React.lazy(() => import('@mui/icons-material/AndroidRounded')),
    AndroidSharp: React.lazy(() => import('@mui/icons-material/AndroidSharp')),
    AndroidTwoTone: React.lazy(() => import('@mui/icons-material/AndroidTwoTone')),
    Animation: React.lazy(() => import('@mui/icons-material/Animation')),
    AnimationOutlined: React.lazy(() => import('@mui/icons-material/AnimationOutlined')),
    AnimationRounded: React.lazy(() => import('@mui/icons-material/AnimationRounded')),
    AnimationSharp: React.lazy(() => import('@mui/icons-material/AnimationSharp')),
    AnimationTwoTone: React.lazy(() => import('@mui/icons-material/AnimationTwoTone')),
    Announcement: React.lazy(() => import('@mui/icons-material/Announcement')),
    AnnouncementOutlined: React.lazy(() => import('@mui/icons-material/AnnouncementOutlined')),
    AnnouncementRounded: React.lazy(() => import('@mui/icons-material/AnnouncementRounded')),
    AnnouncementSharp: React.lazy(() => import('@mui/icons-material/AnnouncementSharp')),
    AnnouncementTwoTone: React.lazy(() => import('@mui/icons-material/AnnouncementTwoTone')),
    Aod: React.lazy(() => import('@mui/icons-material/Aod')),
    AodOutlined: React.lazy(() => import('@mui/icons-material/AodOutlined')),
    AodRounded: React.lazy(() => import('@mui/icons-material/AodRounded')),
    AodSharp: React.lazy(() => import('@mui/icons-material/AodSharp')),
    AodTwoTone: React.lazy(() => import('@mui/icons-material/AodTwoTone')),
    Apartment: React.lazy(() => import('@mui/icons-material/Apartment')),
    ApartmentOutlined: React.lazy(() => import('@mui/icons-material/ApartmentOutlined')),
    ApartmentRounded: React.lazy(() => import('@mui/icons-material/ApartmentRounded')),
    ApartmentSharp: React.lazy(() => import('@mui/icons-material/ApartmentSharp')),
    ApartmentTwoTone: React.lazy(() => import('@mui/icons-material/ApartmentTwoTone')),
    Api: React.lazy(() => import('@mui/icons-material/Api')),
    ApiOutlined: React.lazy(() => import('@mui/icons-material/ApiOutlined')),
    ApiRounded: React.lazy(() => import('@mui/icons-material/ApiRounded')),
    ApiSharp: React.lazy(() => import('@mui/icons-material/ApiSharp')),
    ApiTwoTone: React.lazy(() => import('@mui/icons-material/ApiTwoTone')),
    AppBlocking: React.lazy(() => import('@mui/icons-material/AppBlocking')),
    AppBlockingOutlined: React.lazy(() => import('@mui/icons-material/AppBlockingOutlined')),
    AppBlockingRounded: React.lazy(() => import('@mui/icons-material/AppBlockingRounded')),
    AppBlockingSharp: React.lazy(() => import('@mui/icons-material/AppBlockingSharp')),
    AppBlockingTwoTone: React.lazy(() => import('@mui/icons-material/AppBlockingTwoTone')),
    Apple: React.lazy(() => import('@mui/icons-material/Apple')),
    AppRegistration: React.lazy(() => import('@mui/icons-material/AppRegistration')),
    AppRegistrationOutlined: React.lazy(() => import('@mui/icons-material/AppRegistrationOutlined')),
    AppRegistrationRounded: React.lazy(() => import('@mui/icons-material/AppRegistrationRounded')),
    AppRegistrationSharp: React.lazy(() => import('@mui/icons-material/AppRegistrationSharp')),
    AppRegistrationTwoTone: React.lazy(() => import('@mui/icons-material/AppRegistrationTwoTone')),
    Approval: React.lazy(() => import('@mui/icons-material/Approval')),
    ApprovalOutlined: React.lazy(() => import('@mui/icons-material/ApprovalOutlined')),
    ApprovalRounded: React.lazy(() => import('@mui/icons-material/ApprovalRounded')),
    ApprovalSharp: React.lazy(() => import('@mui/icons-material/ApprovalSharp')),
    ApprovalTwoTone: React.lazy(() => import('@mui/icons-material/ApprovalTwoTone')),
    Apps: React.lazy(() => import('@mui/icons-material/Apps')),
    AppSettingsAlt: React.lazy(() => import('@mui/icons-material/AppSettingsAlt')),
    AppSettingsAltOutlined: React.lazy(() => import('@mui/icons-material/AppSettingsAltOutlined')),
    AppSettingsAltRounded: React.lazy(() => import('@mui/icons-material/AppSettingsAltRounded')),
    AppSettingsAltSharp: React.lazy(() => import('@mui/icons-material/AppSettingsAltSharp')),
    AppSettingsAltTwoTone: React.lazy(() => import('@mui/icons-material/AppSettingsAltTwoTone')),
    AppShortcut: React.lazy(() => import('@mui/icons-material/AppShortcut')),
    AppShortcutOutlined: React.lazy(() => import('@mui/icons-material/AppShortcutOutlined')),
    AppShortcutRounded: React.lazy(() => import('@mui/icons-material/AppShortcutRounded')),
    AppShortcutSharp: React.lazy(() => import('@mui/icons-material/AppShortcutSharp')),
    AppShortcutTwoTone: React.lazy(() => import('@mui/icons-material/AppShortcutTwoTone')),
    AppsOutage: React.lazy(() => import('@mui/icons-material/AppsOutage')),
    AppsOutageOutlined: React.lazy(() => import('@mui/icons-material/AppsOutageOutlined')),
    AppsOutageRounded: React.lazy(() => import('@mui/icons-material/AppsOutageRounded')),
    AppsOutageSharp: React.lazy(() => import('@mui/icons-material/AppsOutageSharp')),
    AppsOutageTwoTone: React.lazy(() => import('@mui/icons-material/AppsOutageTwoTone')),
    AppsOutlined: React.lazy(() => import('@mui/icons-material/AppsOutlined')),
    AppsRounded: React.lazy(() => import('@mui/icons-material/AppsRounded')),
    AppsSharp: React.lazy(() => import('@mui/icons-material/AppsSharp')),
    AppsTwoTone: React.lazy(() => import('@mui/icons-material/AppsTwoTone')),
    Architecture: React.lazy(() => import('@mui/icons-material/Architecture')),
    ArchitectureOutlined: React.lazy(() => import('@mui/icons-material/ArchitectureOutlined')),
    ArchitectureRounded: React.lazy(() => import('@mui/icons-material/ArchitectureRounded')),
    ArchitectureSharp: React.lazy(() => import('@mui/icons-material/ArchitectureSharp')),
    ArchitectureTwoTone: React.lazy(() => import('@mui/icons-material/ArchitectureTwoTone')),
    Archive: React.lazy(() => import('@mui/icons-material/Archive')),
    ArchiveOutlined: React.lazy(() => import('@mui/icons-material/ArchiveOutlined')),
    ArchiveRounded: React.lazy(() => import('@mui/icons-material/ArchiveRounded')),
    ArchiveSharp: React.lazy(() => import('@mui/icons-material/ArchiveSharp')),
    ArchiveTwoTone: React.lazy(() => import('@mui/icons-material/ArchiveTwoTone')),
    ArrowBack: React.lazy(() => import('@mui/icons-material/ArrowBack')),
    ArrowBackIos: React.lazy(() => import('@mui/icons-material/ArrowBackIos')),
    ArrowBackIosNew: React.lazy(() => import('@mui/icons-material/ArrowBackIosNew')),
    ArrowBackIosNewOutlined: React.lazy(() => import('@mui/icons-material/ArrowBackIosNewOutlined')),
    ArrowBackIosNewRounded: React.lazy(() => import('@mui/icons-material/ArrowBackIosNewRounded')),
    ArrowBackIosNewSharp: React.lazy(() => import('@mui/icons-material/ArrowBackIosNewSharp')),
    ArrowBackIosNewTwoTone: React.lazy(() => import('@mui/icons-material/ArrowBackIosNewTwoTone')),
    ArrowBackIosOutlined: React.lazy(() => import('@mui/icons-material/ArrowBackIosOutlined')),
    ArrowBackIosRounded: React.lazy(() => import('@mui/icons-material/ArrowBackIosRounded')),
    ArrowBackIosSharp: React.lazy(() => import('@mui/icons-material/ArrowBackIosSharp')),
    ArrowBackIosTwoTone: React.lazy(() => import('@mui/icons-material/ArrowBackIosTwoTone')),
    ArrowBackOutlined: React.lazy(() => import('@mui/icons-material/ArrowBackOutlined')),
    ArrowBackRounded: React.lazy(() => import('@mui/icons-material/ArrowBackRounded')),
    ArrowBackSharp: React.lazy(() => import('@mui/icons-material/ArrowBackSharp')),
    ArrowBackTwoTone: React.lazy(() => import('@mui/icons-material/ArrowBackTwoTone')),
    ArrowCircleDown: React.lazy(() => import('@mui/icons-material/ArrowCircleDown')),
    ArrowCircleDownOutlined: React.lazy(() => import('@mui/icons-material/ArrowCircleDownOutlined')),
    ArrowCircleDownRounded: React.lazy(() => import('@mui/icons-material/ArrowCircleDownRounded')),
    ArrowCircleDownSharp: React.lazy(() => import('@mui/icons-material/ArrowCircleDownSharp')),
    ArrowCircleDownTwoTone: React.lazy(() => import('@mui/icons-material/ArrowCircleDownTwoTone')),
    ArrowCircleLeft: React.lazy(() => import('@mui/icons-material/ArrowCircleLeft')),
    ArrowCircleLeftOutlined: React.lazy(() => import('@mui/icons-material/ArrowCircleLeftOutlined')),
    ArrowCircleLeftRounded: React.lazy(() => import('@mui/icons-material/ArrowCircleLeftRounded')),
    ArrowCircleLeftSharp: React.lazy(() => import('@mui/icons-material/ArrowCircleLeftSharp')),
    ArrowCircleLeftTwoTone: React.lazy(() => import('@mui/icons-material/ArrowCircleLeftTwoTone')),
    ArrowCircleRight: React.lazy(() => import('@mui/icons-material/ArrowCircleRight')),
    ArrowCircleRightOutlined: React.lazy(() => import('@mui/icons-material/ArrowCircleRightOutlined')),
    ArrowCircleRightRounded: React.lazy(() => import('@mui/icons-material/ArrowCircleRightRounded')),
    ArrowCircleRightSharp: React.lazy(() => import('@mui/icons-material/ArrowCircleRightSharp')),
    ArrowCircleRightTwoTone: React.lazy(() => import('@mui/icons-material/ArrowCircleRightTwoTone')),
    ArrowCircleUp: React.lazy(() => import('@mui/icons-material/ArrowCircleUp')),
    ArrowCircleUpOutlined: React.lazy(() => import('@mui/icons-material/ArrowCircleUpOutlined')),
    ArrowCircleUpRounded: React.lazy(() => import('@mui/icons-material/ArrowCircleUpRounded')),
    ArrowCircleUpSharp: React.lazy(() => import('@mui/icons-material/ArrowCircleUpSharp')),
    ArrowCircleUpTwoTone: React.lazy(() => import('@mui/icons-material/ArrowCircleUpTwoTone')),
    ArrowDownward: React.lazy(() => import('@mui/icons-material/ArrowDownward')),
    ArrowDownwardOutlined: React.lazy(() => import('@mui/icons-material/ArrowDownwardOutlined')),
    ArrowDownwardRounded: React.lazy(() => import('@mui/icons-material/ArrowDownwardRounded')),
    ArrowDownwardSharp: React.lazy(() => import('@mui/icons-material/ArrowDownwardSharp')),
    ArrowDownwardTwoTone: React.lazy(() => import('@mui/icons-material/ArrowDownwardTwoTone')),
    ArrowDropDownCircle: React.lazy(() => import('@mui/icons-material/ArrowDropDownCircle')),
    ArrowDropDownCircleOutlined: React.lazy(() => import('@mui/icons-material/ArrowDropDownCircleOutlined')),
    ArrowDropDownCircleRounded: React.lazy(() => import('@mui/icons-material/ArrowDropDownCircleRounded')),
    ArrowDropDownCircleSharp: React.lazy(() => import('@mui/icons-material/ArrowDropDownCircleSharp')),
    ArrowDropDownCircleTwoTone: React.lazy(() => import('@mui/icons-material/ArrowDropDownCircleTwoTone')),
    ArrowDropDown: React.lazy(() => import('@mui/icons-material/ArrowDropDown')),
    ArrowDropDownOutlined: React.lazy(() => import('@mui/icons-material/ArrowDropDownOutlined')),
    ArrowDropDownRounded: React.lazy(() => import('@mui/icons-material/ArrowDropDownRounded')),
    ArrowDropDownSharp: React.lazy(() => import('@mui/icons-material/ArrowDropDownSharp')),
    ArrowDropDownTwoTone: React.lazy(() => import('@mui/icons-material/ArrowDropDownTwoTone')),
    ArrowDropUp: React.lazy(() => import('@mui/icons-material/ArrowDropUp')),
    ArrowDropUpOutlined: React.lazy(() => import('@mui/icons-material/ArrowDropUpOutlined')),
    ArrowDropUpRounded: React.lazy(() => import('@mui/icons-material/ArrowDropUpRounded')),
    ArrowDropUpSharp: React.lazy(() => import('@mui/icons-material/ArrowDropUpSharp')),
    ArrowDropUpTwoTone: React.lazy(() => import('@mui/icons-material/ArrowDropUpTwoTone')),
    ArrowForward: React.lazy(() => import('@mui/icons-material/ArrowForward')),
    ArrowForwardIos: React.lazy(() => import('@mui/icons-material/ArrowForwardIos')),
    ArrowForwardIosOutlined: React.lazy(() => import('@mui/icons-material/ArrowForwardIosOutlined')),
    ArrowForwardIosRounded: React.lazy(() => import('@mui/icons-material/ArrowForwardIosRounded')),
    ArrowForwardIosSharp: React.lazy(() => import('@mui/icons-material/ArrowForwardIosSharp')),
    ArrowForwardIosTwoTone: React.lazy(() => import('@mui/icons-material/ArrowForwardIosTwoTone')),
    ArrowForwardOutlined: React.lazy(() => import('@mui/icons-material/ArrowForwardOutlined')),
    ArrowForwardRounded: React.lazy(() => import('@mui/icons-material/ArrowForwardRounded')),
    ArrowForwardSharp: React.lazy(() => import('@mui/icons-material/ArrowForwardSharp')),
    ArrowForwardTwoTone: React.lazy(() => import('@mui/icons-material/ArrowForwardTwoTone')),
    ArrowLeft: React.lazy(() => import('@mui/icons-material/ArrowLeft')),
    ArrowLeftOutlined: React.lazy(() => import('@mui/icons-material/ArrowLeftOutlined')),
    ArrowLeftRounded: React.lazy(() => import('@mui/icons-material/ArrowLeftRounded')),
    ArrowLeftSharp: React.lazy(() => import('@mui/icons-material/ArrowLeftSharp')),
    ArrowLeftTwoTone: React.lazy(() => import('@mui/icons-material/ArrowLeftTwoTone')),
    ArrowOutward: React.lazy(() => import('@mui/icons-material/ArrowOutward')),
    ArrowOutwardOutlined: React.lazy(() => import('@mui/icons-material/ArrowOutwardOutlined')),
    ArrowOutwardRounded: React.lazy(() => import('@mui/icons-material/ArrowOutwardRounded')),
    ArrowOutwardSharp: React.lazy(() => import('@mui/icons-material/ArrowOutwardSharp')),
    ArrowOutwardTwoTone: React.lazy(() => import('@mui/icons-material/ArrowOutwardTwoTone')),
    ArrowRightAlt: React.lazy(() => import('@mui/icons-material/ArrowRightAlt')),
    ArrowRightAltOutlined: React.lazy(() => import('@mui/icons-material/ArrowRightAltOutlined')),
    ArrowRightAltRounded: React.lazy(() => import('@mui/icons-material/ArrowRightAltRounded')),
    ArrowRightAltSharp: React.lazy(() => import('@mui/icons-material/ArrowRightAltSharp')),
    ArrowRightAltTwoTone: React.lazy(() => import('@mui/icons-material/ArrowRightAltTwoTone')),
    ArrowRight: React.lazy(() => import('@mui/icons-material/ArrowRight')),
    ArrowRightOutlined: React.lazy(() => import('@mui/icons-material/ArrowRightOutlined')),
    ArrowRightRounded: React.lazy(() => import('@mui/icons-material/ArrowRightRounded')),
    ArrowRightSharp: React.lazy(() => import('@mui/icons-material/ArrowRightSharp')),
    ArrowRightTwoTone: React.lazy(() => import('@mui/icons-material/ArrowRightTwoTone')),
    ArrowUpward: React.lazy(() => import('@mui/icons-material/ArrowUpward')),
    ArrowUpwardOutlined: React.lazy(() => import('@mui/icons-material/ArrowUpwardOutlined')),
    ArrowUpwardRounded: React.lazy(() => import('@mui/icons-material/ArrowUpwardRounded')),
    ArrowUpwardSharp: React.lazy(() => import('@mui/icons-material/ArrowUpwardSharp')),
    ArrowUpwardTwoTone: React.lazy(() => import('@mui/icons-material/ArrowUpwardTwoTone')),
    Article: React.lazy(() => import('@mui/icons-material/Article')),
    ArticleOutlined: React.lazy(() => import('@mui/icons-material/ArticleOutlined')),
    ArticleRounded: React.lazy(() => import('@mui/icons-material/ArticleRounded')),
    ArticleSharp: React.lazy(() => import('@mui/icons-material/ArticleSharp')),
    ArticleTwoTone: React.lazy(() => import('@mui/icons-material/ArticleTwoTone')),
    ArtTrack: React.lazy(() => import('@mui/icons-material/ArtTrack')),
    ArtTrackOutlined: React.lazy(() => import('@mui/icons-material/ArtTrackOutlined')),
    ArtTrackRounded: React.lazy(() => import('@mui/icons-material/ArtTrackRounded')),
    ArtTrackSharp: React.lazy(() => import('@mui/icons-material/ArtTrackSharp')),
    ArtTrackTwoTone: React.lazy(() => import('@mui/icons-material/ArtTrackTwoTone')),
    AspectRatio: React.lazy(() => import('@mui/icons-material/AspectRatio')),
    AspectRatioOutlined: React.lazy(() => import('@mui/icons-material/AspectRatioOutlined')),
    AspectRatioRounded: React.lazy(() => import('@mui/icons-material/AspectRatioRounded')),
    AspectRatioSharp: React.lazy(() => import('@mui/icons-material/AspectRatioSharp')),
    AspectRatioTwoTone: React.lazy(() => import('@mui/icons-material/AspectRatioTwoTone')),
    Assessment: React.lazy(() => import('@mui/icons-material/Assessment')),
    AssessmentOutlined: React.lazy(() => import('@mui/icons-material/AssessmentOutlined')),
    AssessmentRounded: React.lazy(() => import('@mui/icons-material/AssessmentRounded')),
    AssessmentSharp: React.lazy(() => import('@mui/icons-material/AssessmentSharp')),
    AssessmentTwoTone: React.lazy(() => import('@mui/icons-material/AssessmentTwoTone')),
    Assignment: React.lazy(() => import('@mui/icons-material/Assignment')),
    AssignmentInd: React.lazy(() => import('@mui/icons-material/AssignmentInd')),
    AssignmentIndOutlined: React.lazy(() => import('@mui/icons-material/AssignmentIndOutlined')),
    AssignmentIndRounded: React.lazy(() => import('@mui/icons-material/AssignmentIndRounded')),
    AssignmentIndSharp: React.lazy(() => import('@mui/icons-material/AssignmentIndSharp')),
    AssignmentIndTwoTone: React.lazy(() => import('@mui/icons-material/AssignmentIndTwoTone')),
    AssignmentLate: React.lazy(() => import('@mui/icons-material/AssignmentLate')),
    AssignmentLateOutlined: React.lazy(() => import('@mui/icons-material/AssignmentLateOutlined')),
    AssignmentLateRounded: React.lazy(() => import('@mui/icons-material/AssignmentLateRounded')),
    AssignmentLateSharp: React.lazy(() => import('@mui/icons-material/AssignmentLateSharp')),
    AssignmentLateTwoTone: React.lazy(() => import('@mui/icons-material/AssignmentLateTwoTone')),
    AssignmentOutlined: React.lazy(() => import('@mui/icons-material/AssignmentOutlined')),
    AssignmentReturn: React.lazy(() => import('@mui/icons-material/AssignmentReturn')),
    AssignmentReturned: React.lazy(() => import('@mui/icons-material/AssignmentReturned')),
    AssignmentReturnedOutlined: React.lazy(() => import('@mui/icons-material/AssignmentReturnedOutlined')),
    AssignmentReturnedRounded: React.lazy(() => import('@mui/icons-material/AssignmentReturnedRounded')),
    AssignmentReturnedSharp: React.lazy(() => import('@mui/icons-material/AssignmentReturnedSharp')),
    AssignmentReturnedTwoTone: React.lazy(() => import('@mui/icons-material/AssignmentReturnedTwoTone')),
    AssignmentReturnOutlined: React.lazy(() => import('@mui/icons-material/AssignmentReturnOutlined')),
    AssignmentReturnRounded: React.lazy(() => import('@mui/icons-material/AssignmentReturnRounded')),
    AssignmentReturnSharp: React.lazy(() => import('@mui/icons-material/AssignmentReturnSharp')),
    AssignmentReturnTwoTone: React.lazy(() => import('@mui/icons-material/AssignmentReturnTwoTone')),
    AssignmentRounded: React.lazy(() => import('@mui/icons-material/AssignmentRounded')),
    AssignmentSharp: React.lazy(() => import('@mui/icons-material/AssignmentSharp')),
    AssignmentTurnedIn: React.lazy(() => import('@mui/icons-material/AssignmentTurnedIn')),
    AssignmentTurnedInOutlined: React.lazy(() => import('@mui/icons-material/AssignmentTurnedInOutlined')),
    AssignmentTurnedInRounded: React.lazy(() => import('@mui/icons-material/AssignmentTurnedInRounded')),
    AssignmentTurnedInSharp: React.lazy(() => import('@mui/icons-material/AssignmentTurnedInSharp')),
    AssignmentTurnedInTwoTone: React.lazy(() => import('@mui/icons-material/AssignmentTurnedInTwoTone')),
    AssignmentTwoTone: React.lazy(() => import('@mui/icons-material/AssignmentTwoTone')),
    AssistantDirection: React.lazy(() => import('@mui/icons-material/AssistantDirection')),
    AssistantDirectionOutlined: React.lazy(() => import('@mui/icons-material/AssistantDirectionOutlined')),
    AssistantDirectionRounded: React.lazy(() => import('@mui/icons-material/AssistantDirectionRounded')),
    AssistantDirectionSharp: React.lazy(() => import('@mui/icons-material/AssistantDirectionSharp')),
    AssistantDirectionTwoTone: React.lazy(() => import('@mui/icons-material/AssistantDirectionTwoTone')),
    Assistant: React.lazy(() => import('@mui/icons-material/Assistant')),
    AssistantOutlined: React.lazy(() => import('@mui/icons-material/AssistantOutlined')),
    AssistantPhoto: React.lazy(() => import('@mui/icons-material/AssistantPhoto')),
    AssistantPhotoOutlined: React.lazy(() => import('@mui/icons-material/AssistantPhotoOutlined')),
    AssistantPhotoRounded: React.lazy(() => import('@mui/icons-material/AssistantPhotoRounded')),
    AssistantPhotoSharp: React.lazy(() => import('@mui/icons-material/AssistantPhotoSharp')),
    AssistantPhotoTwoTone: React.lazy(() => import('@mui/icons-material/AssistantPhotoTwoTone')),
    AssistantRounded: React.lazy(() => import('@mui/icons-material/AssistantRounded')),
    AssistantSharp: React.lazy(() => import('@mui/icons-material/AssistantSharp')),
    AssistantTwoTone: React.lazy(() => import('@mui/icons-material/AssistantTwoTone')),
    AssistWalker: React.lazy(() => import('@mui/icons-material/AssistWalker')),
    AssistWalkerOutlined: React.lazy(() => import('@mui/icons-material/AssistWalkerOutlined')),
    AssistWalkerRounded: React.lazy(() => import('@mui/icons-material/AssistWalkerRounded')),
    AssistWalkerSharp: React.lazy(() => import('@mui/icons-material/AssistWalkerSharp')),
    AssistWalkerTwoTone: React.lazy(() => import('@mui/icons-material/AssistWalkerTwoTone')),
    AssuredWorkload: React.lazy(() => import('@mui/icons-material/AssuredWorkload')),
    AssuredWorkloadOutlined: React.lazy(() => import('@mui/icons-material/AssuredWorkloadOutlined')),
    AssuredWorkloadRounded: React.lazy(() => import('@mui/icons-material/AssuredWorkloadRounded')),
    AssuredWorkloadSharp: React.lazy(() => import('@mui/icons-material/AssuredWorkloadSharp')),
    AssuredWorkloadTwoTone: React.lazy(() => import('@mui/icons-material/AssuredWorkloadTwoTone')),
    Atm: React.lazy(() => import('@mui/icons-material/Atm')),
    AtmOutlined: React.lazy(() => import('@mui/icons-material/AtmOutlined')),
    AtmRounded: React.lazy(() => import('@mui/icons-material/AtmRounded')),
    AtmSharp: React.lazy(() => import('@mui/icons-material/AtmSharp')),
    AtmTwoTone: React.lazy(() => import('@mui/icons-material/AtmTwoTone')),
    AttachEmail: React.lazy(() => import('@mui/icons-material/AttachEmail')),
    AttachEmailOutlined: React.lazy(() => import('@mui/icons-material/AttachEmailOutlined')),
    AttachEmailRounded: React.lazy(() => import('@mui/icons-material/AttachEmailRounded')),
    AttachEmailSharp: React.lazy(() => import('@mui/icons-material/AttachEmailSharp')),
    AttachEmailTwoTone: React.lazy(() => import('@mui/icons-material/AttachEmailTwoTone')),
    AttachFile: React.lazy(() => import('@mui/icons-material/AttachFile')),
    AttachFileOutlined: React.lazy(() => import('@mui/icons-material/AttachFileOutlined')),
    AttachFileRounded: React.lazy(() => import('@mui/icons-material/AttachFileRounded')),
    AttachFileSharp: React.lazy(() => import('@mui/icons-material/AttachFileSharp')),
    AttachFileTwoTone: React.lazy(() => import('@mui/icons-material/AttachFileTwoTone')),
    Attachment: React.lazy(() => import('@mui/icons-material/Attachment')),
    AttachmentOutlined: React.lazy(() => import('@mui/icons-material/AttachmentOutlined')),
    AttachmentRounded: React.lazy(() => import('@mui/icons-material/AttachmentRounded')),
    AttachmentSharp: React.lazy(() => import('@mui/icons-material/AttachmentSharp')),
    AttachmentTwoTone: React.lazy(() => import('@mui/icons-material/AttachmentTwoTone')),
    AttachMoney: React.lazy(() => import('@mui/icons-material/AttachMoney')),
    AttachMoneyOutlined: React.lazy(() => import('@mui/icons-material/AttachMoneyOutlined')),
    AttachMoneyRounded: React.lazy(() => import('@mui/icons-material/AttachMoneyRounded')),
    AttachMoneySharp: React.lazy(() => import('@mui/icons-material/AttachMoneySharp')),
    AttachMoneyTwoTone: React.lazy(() => import('@mui/icons-material/AttachMoneyTwoTone')),
    Attractions: React.lazy(() => import('@mui/icons-material/Attractions')),
    AttractionsOutlined: React.lazy(() => import('@mui/icons-material/AttractionsOutlined')),
    AttractionsRounded: React.lazy(() => import('@mui/icons-material/AttractionsRounded')),
    AttractionsSharp: React.lazy(() => import('@mui/icons-material/AttractionsSharp')),
    AttractionsTwoTone: React.lazy(() => import('@mui/icons-material/AttractionsTwoTone')),
    Attribution: React.lazy(() => import('@mui/icons-material/Attribution')),
    AttributionOutlined: React.lazy(() => import('@mui/icons-material/AttributionOutlined')),
    AttributionRounded: React.lazy(() => import('@mui/icons-material/AttributionRounded')),
    AttributionSharp: React.lazy(() => import('@mui/icons-material/AttributionSharp')),
    AttributionTwoTone: React.lazy(() => import('@mui/icons-material/AttributionTwoTone')),
    AudioFile: React.lazy(() => import('@mui/icons-material/AudioFile')),
    AudioFileOutlined: React.lazy(() => import('@mui/icons-material/AudioFileOutlined')),
    AudioFileRounded: React.lazy(() => import('@mui/icons-material/AudioFileRounded')),
    AudioFileSharp: React.lazy(() => import('@mui/icons-material/AudioFileSharp')),
    AudioFileTwoTone: React.lazy(() => import('@mui/icons-material/AudioFileTwoTone')),
    Audiotrack: React.lazy(() => import('@mui/icons-material/Audiotrack')),
    AudiotrackOutlined: React.lazy(() => import('@mui/icons-material/AudiotrackOutlined')),
    AudiotrackRounded: React.lazy(() => import('@mui/icons-material/AudiotrackRounded')),
    AudiotrackSharp: React.lazy(() => import('@mui/icons-material/AudiotrackSharp')),
    AudiotrackTwoTone: React.lazy(() => import('@mui/icons-material/AudiotrackTwoTone')),
    AutoAwesome: React.lazy(() => import('@mui/icons-material/AutoAwesome')),
    AutoAwesomeMosaic: React.lazy(() => import('@mui/icons-material/AutoAwesomeMosaic')),
    AutoAwesomeMosaicOutlined: React.lazy(() => import('@mui/icons-material/AutoAwesomeMosaicOutlined')),
    AutoAwesomeMosaicRounded: React.lazy(() => import('@mui/icons-material/AutoAwesomeMosaicRounded')),
    AutoAwesomeMosaicSharp: React.lazy(() => import('@mui/icons-material/AutoAwesomeMosaicSharp')),
    AutoAwesomeMosaicTwoTone: React.lazy(() => import('@mui/icons-material/AutoAwesomeMosaicTwoTone')),
    AutoAwesomeMotion: React.lazy(() => import('@mui/icons-material/AutoAwesomeMotion')),
    AutoAwesomeMotionOutlined: React.lazy(() => import('@mui/icons-material/AutoAwesomeMotionOutlined')),
    AutoAwesomeMotionRounded: React.lazy(() => import('@mui/icons-material/AutoAwesomeMotionRounded')),
    AutoAwesomeMotionSharp: React.lazy(() => import('@mui/icons-material/AutoAwesomeMotionSharp')),
    AutoAwesomeMotionTwoTone: React.lazy(() => import('@mui/icons-material/AutoAwesomeMotionTwoTone')),
    AutoAwesomeOutlined: React.lazy(() => import('@mui/icons-material/AutoAwesomeOutlined')),
    AutoAwesomeRounded: React.lazy(() => import('@mui/icons-material/AutoAwesomeRounded')),
    AutoAwesomeSharp: React.lazy(() => import('@mui/icons-material/AutoAwesomeSharp')),
    AutoAwesomeTwoTone: React.lazy(() => import('@mui/icons-material/AutoAwesomeTwoTone')),
    AutoDelete: React.lazy(() => import('@mui/icons-material/AutoDelete')),
    AutoDeleteOutlined: React.lazy(() => import('@mui/icons-material/AutoDeleteOutlined')),
    AutoDeleteRounded: React.lazy(() => import('@mui/icons-material/AutoDeleteRounded')),
    AutoDeleteSharp: React.lazy(() => import('@mui/icons-material/AutoDeleteSharp')),
    AutoDeleteTwoTone: React.lazy(() => import('@mui/icons-material/AutoDeleteTwoTone')),
    AutoFixHigh: React.lazy(() => import('@mui/icons-material/AutoFixHigh')),
    AutoFixHighOutlined: React.lazy(() => import('@mui/icons-material/AutoFixHighOutlined')),
    AutoFixHighRounded: React.lazy(() => import('@mui/icons-material/AutoFixHighRounded')),
    AutoFixHighSharp: React.lazy(() => import('@mui/icons-material/AutoFixHighSharp')),
    AutoFixHighTwoTone: React.lazy(() => import('@mui/icons-material/AutoFixHighTwoTone')),
    AutoFixNormal: React.lazy(() => import('@mui/icons-material/AutoFixNormal')),
    AutoFixNormalOutlined: React.lazy(() => import('@mui/icons-material/AutoFixNormalOutlined')),
    AutoFixNormalRounded: React.lazy(() => import('@mui/icons-material/AutoFixNormalRounded')),
    AutoFixNormalSharp: React.lazy(() => import('@mui/icons-material/AutoFixNormalSharp')),
    AutoFixNormalTwoTone: React.lazy(() => import('@mui/icons-material/AutoFixNormalTwoTone')),
    AutoFixOff: React.lazy(() => import('@mui/icons-material/AutoFixOff')),
    AutoFixOffOutlined: React.lazy(() => import('@mui/icons-material/AutoFixOffOutlined')),
    AutoFixOffRounded: React.lazy(() => import('@mui/icons-material/AutoFixOffRounded')),
    AutoFixOffSharp: React.lazy(() => import('@mui/icons-material/AutoFixOffSharp')),
    AutoFixOffTwoTone: React.lazy(() => import('@mui/icons-material/AutoFixOffTwoTone')),
    AutofpsSelect: React.lazy(() => import('@mui/icons-material/AutofpsSelect')),
    AutofpsSelectOutlined: React.lazy(() => import('@mui/icons-material/AutofpsSelectOutlined')),
    AutofpsSelectRounded: React.lazy(() => import('@mui/icons-material/AutofpsSelectRounded')),
    AutofpsSelectSharp: React.lazy(() => import('@mui/icons-material/AutofpsSelectSharp')),
    AutofpsSelectTwoTone: React.lazy(() => import('@mui/icons-material/AutofpsSelectTwoTone')),
    AutoGraph: React.lazy(() => import('@mui/icons-material/AutoGraph')),
    AutoGraphOutlined: React.lazy(() => import('@mui/icons-material/AutoGraphOutlined')),
    AutoGraphRounded: React.lazy(() => import('@mui/icons-material/AutoGraphRounded')),
    AutoGraphSharp: React.lazy(() => import('@mui/icons-material/AutoGraphSharp')),
    AutoGraphTwoTone: React.lazy(() => import('@mui/icons-material/AutoGraphTwoTone')),
    AutoMode: React.lazy(() => import('@mui/icons-material/AutoMode')),
    AutoModeOutlined: React.lazy(() => import('@mui/icons-material/AutoModeOutlined')),
    AutoModeRounded: React.lazy(() => import('@mui/icons-material/AutoModeRounded')),
    AutoModeSharp: React.lazy(() => import('@mui/icons-material/AutoModeSharp')),
    AutoModeTwoTone: React.lazy(() => import('@mui/icons-material/AutoModeTwoTone')),
    Autorenew: React.lazy(() => import('@mui/icons-material/Autorenew')),
    AutorenewOutlined: React.lazy(() => import('@mui/icons-material/AutorenewOutlined')),
    AutorenewRounded: React.lazy(() => import('@mui/icons-material/AutorenewRounded')),
    AutorenewSharp: React.lazy(() => import('@mui/icons-material/AutorenewSharp')),
    AutorenewTwoTone: React.lazy(() => import('@mui/icons-material/AutorenewTwoTone')),
    AutoStories: React.lazy(() => import('@mui/icons-material/AutoStories')),
    AutoStoriesOutlined: React.lazy(() => import('@mui/icons-material/AutoStoriesOutlined')),
    AutoStoriesRounded: React.lazy(() => import('@mui/icons-material/AutoStoriesRounded')),
    AutoStoriesSharp: React.lazy(() => import('@mui/icons-material/AutoStoriesSharp')),
    AutoStoriesTwoTone: React.lazy(() => import('@mui/icons-material/AutoStoriesTwoTone')),
    AvTimer: React.lazy(() => import('@mui/icons-material/AvTimer')),
    AvTimerOutlined: React.lazy(() => import('@mui/icons-material/AvTimerOutlined')),
    AvTimerRounded: React.lazy(() => import('@mui/icons-material/AvTimerRounded')),
    AvTimerSharp: React.lazy(() => import('@mui/icons-material/AvTimerSharp')),
    AvTimerTwoTone: React.lazy(() => import('@mui/icons-material/AvTimerTwoTone')),
    BabyChangingStation: React.lazy(() => import('@mui/icons-material/BabyChangingStation')),
    BabyChangingStationOutlined: React.lazy(() => import('@mui/icons-material/BabyChangingStationOutlined')),
    BabyChangingStationRounded: React.lazy(() => import('@mui/icons-material/BabyChangingStationRounded')),
    BabyChangingStationSharp: React.lazy(() => import('@mui/icons-material/BabyChangingStationSharp')),
    BabyChangingStationTwoTone: React.lazy(() => import('@mui/icons-material/BabyChangingStationTwoTone')),
    Backpack: React.lazy(() => import('@mui/icons-material/Backpack')),
    BackpackOutlined: React.lazy(() => import('@mui/icons-material/BackpackOutlined')),
    BackpackRounded: React.lazy(() => import('@mui/icons-material/BackpackRounded')),
    BackpackSharp: React.lazy(() => import('@mui/icons-material/BackpackSharp')),
    BackpackTwoTone: React.lazy(() => import('@mui/icons-material/BackpackTwoTone')),
    Backspace: React.lazy(() => import('@mui/icons-material/Backspace')),
    BackspaceOutlined: React.lazy(() => import('@mui/icons-material/BackspaceOutlined')),
    BackspaceRounded: React.lazy(() => import('@mui/icons-material/BackspaceRounded')),
    BackspaceSharp: React.lazy(() => import('@mui/icons-material/BackspaceSharp')),
    BackspaceTwoTone: React.lazy(() => import('@mui/icons-material/BackspaceTwoTone')),
    Backup: React.lazy(() => import('@mui/icons-material/Backup')),
    BackupOutlined: React.lazy(() => import('@mui/icons-material/BackupOutlined')),
    BackupRounded: React.lazy(() => import('@mui/icons-material/BackupRounded')),
    BackupSharp: React.lazy(() => import('@mui/icons-material/BackupSharp')),
    BackupTable: React.lazy(() => import('@mui/icons-material/BackupTable')),
    BackupTableOutlined: React.lazy(() => import('@mui/icons-material/BackupTableOutlined')),
    BackupTableRounded: React.lazy(() => import('@mui/icons-material/BackupTableRounded')),
    BackupTableSharp: React.lazy(() => import('@mui/icons-material/BackupTableSharp')),
    BackupTableTwoTone: React.lazy(() => import('@mui/icons-material/BackupTableTwoTone')),
    BackupTwoTone: React.lazy(() => import('@mui/icons-material/BackupTwoTone')),
    Badge: React.lazy(() => import('@mui/icons-material/Badge')),
    BadgeOutlined: React.lazy(() => import('@mui/icons-material/BadgeOutlined')),
    BadgeRounded: React.lazy(() => import('@mui/icons-material/BadgeRounded')),
    BadgeSharp: React.lazy(() => import('@mui/icons-material/BadgeSharp')),
    BadgeTwoTone: React.lazy(() => import('@mui/icons-material/BadgeTwoTone')),
    BakeryDining: React.lazy(() => import('@mui/icons-material/BakeryDining')),
    BakeryDiningOutlined: React.lazy(() => import('@mui/icons-material/BakeryDiningOutlined')),
    BakeryDiningRounded: React.lazy(() => import('@mui/icons-material/BakeryDiningRounded')),
    BakeryDiningSharp: React.lazy(() => import('@mui/icons-material/BakeryDiningSharp')),
    BakeryDiningTwoTone: React.lazy(() => import('@mui/icons-material/BakeryDiningTwoTone')),
    Balance: React.lazy(() => import('@mui/icons-material/Balance')),
    BalanceOutlined: React.lazy(() => import('@mui/icons-material/BalanceOutlined')),
    BalanceRounded: React.lazy(() => import('@mui/icons-material/BalanceRounded')),
    BalanceSharp: React.lazy(() => import('@mui/icons-material/BalanceSharp')),
    BalanceTwoTone: React.lazy(() => import('@mui/icons-material/BalanceTwoTone')),
    Balcony: React.lazy(() => import('@mui/icons-material/Balcony')),
    BalconyOutlined: React.lazy(() => import('@mui/icons-material/BalconyOutlined')),
    BalconyRounded: React.lazy(() => import('@mui/icons-material/BalconyRounded')),
    BalconySharp: React.lazy(() => import('@mui/icons-material/BalconySharp')),
    BalconyTwoTone: React.lazy(() => import('@mui/icons-material/BalconyTwoTone')),
    Ballot: React.lazy(() => import('@mui/icons-material/Ballot')),
    BallotOutlined: React.lazy(() => import('@mui/icons-material/BallotOutlined')),
    BallotRounded: React.lazy(() => import('@mui/icons-material/BallotRounded')),
    BallotSharp: React.lazy(() => import('@mui/icons-material/BallotSharp')),
    BallotTwoTone: React.lazy(() => import('@mui/icons-material/BallotTwoTone')),
    BarChart: React.lazy(() => import('@mui/icons-material/BarChart')),
    BarChartOutlined: React.lazy(() => import('@mui/icons-material/BarChartOutlined')),
    BarChartRounded: React.lazy(() => import('@mui/icons-material/BarChartRounded')),
    BarChartSharp: React.lazy(() => import('@mui/icons-material/BarChartSharp')),
    BarChartTwoTone: React.lazy(() => import('@mui/icons-material/BarChartTwoTone')),
    BatchPrediction: React.lazy(() => import('@mui/icons-material/BatchPrediction')),
    BatchPredictionOutlined: React.lazy(() => import('@mui/icons-material/BatchPredictionOutlined')),
    BatchPredictionRounded: React.lazy(() => import('@mui/icons-material/BatchPredictionRounded')),
    BatchPredictionSharp: React.lazy(() => import('@mui/icons-material/BatchPredictionSharp')),
    BatchPredictionTwoTone: React.lazy(() => import('@mui/icons-material/BatchPredictionTwoTone')),
    Bathroom: React.lazy(() => import('@mui/icons-material/Bathroom')),
    BathroomOutlined: React.lazy(() => import('@mui/icons-material/BathroomOutlined')),
    BathroomRounded: React.lazy(() => import('@mui/icons-material/BathroomRounded')),
    BathroomSharp: React.lazy(() => import('@mui/icons-material/BathroomSharp')),
    BathroomTwoTone: React.lazy(() => import('@mui/icons-material/BathroomTwoTone')),
    Bathtub: React.lazy(() => import('@mui/icons-material/Bathtub')),
    BathtubOutlined: React.lazy(() => import('@mui/icons-material/BathtubOutlined')),
    BathtubRounded: React.lazy(() => import('@mui/icons-material/BathtubRounded')),
    BathtubSharp: React.lazy(() => import('@mui/icons-material/BathtubSharp')),
    BathtubTwoTone: React.lazy(() => import('@mui/icons-material/BathtubTwoTone')),
    Battery0Bar: React.lazy(() => import('@mui/icons-material/Battery0Bar')),
    Battery0BarOutlined: React.lazy(() => import('@mui/icons-material/Battery0BarOutlined')),
    Battery0BarRounded: React.lazy(() => import('@mui/icons-material/Battery0BarRounded')),
    Battery0BarSharp: React.lazy(() => import('@mui/icons-material/Battery0BarSharp')),
    Battery0BarTwoTone: React.lazy(() => import('@mui/icons-material/Battery0BarTwoTone')),
    Battery1Bar: React.lazy(() => import('@mui/icons-material/Battery1Bar')),
    Battery1BarOutlined: React.lazy(() => import('@mui/icons-material/Battery1BarOutlined')),
    Battery1BarRounded: React.lazy(() => import('@mui/icons-material/Battery1BarRounded')),
    Battery1BarSharp: React.lazy(() => import('@mui/icons-material/Battery1BarSharp')),
    Battery1BarTwoTone: React.lazy(() => import('@mui/icons-material/Battery1BarTwoTone')),
    Battery20: React.lazy(() => import('@mui/icons-material/Battery20')),
    Battery20Outlined: React.lazy(() => import('@mui/icons-material/Battery20Outlined')),
    Battery20Rounded: React.lazy(() => import('@mui/icons-material/Battery20Rounded')),
    Battery20Sharp: React.lazy(() => import('@mui/icons-material/Battery20Sharp')),
    Battery20TwoTone: React.lazy(() => import('@mui/icons-material/Battery20TwoTone')),
    Battery2Bar: React.lazy(() => import('@mui/icons-material/Battery2Bar')),
    Battery2BarOutlined: React.lazy(() => import('@mui/icons-material/Battery2BarOutlined')),
    Battery2BarRounded: React.lazy(() => import('@mui/icons-material/Battery2BarRounded')),
    Battery2BarSharp: React.lazy(() => import('@mui/icons-material/Battery2BarSharp')),
    Battery2BarTwoTone: React.lazy(() => import('@mui/icons-material/Battery2BarTwoTone')),
    Battery30: React.lazy(() => import('@mui/icons-material/Battery30')),
    Battery30Outlined: React.lazy(() => import('@mui/icons-material/Battery30Outlined')),
    Battery30Rounded: React.lazy(() => import('@mui/icons-material/Battery30Rounded')),
    Battery30Sharp: React.lazy(() => import('@mui/icons-material/Battery30Sharp')),
    Battery30TwoTone: React.lazy(() => import('@mui/icons-material/Battery30TwoTone')),
    Battery3Bar: React.lazy(() => import('@mui/icons-material/Battery3Bar')),
    Battery3BarOutlined: React.lazy(() => import('@mui/icons-material/Battery3BarOutlined')),
    Battery3BarRounded: React.lazy(() => import('@mui/icons-material/Battery3BarRounded')),
    Battery3BarSharp: React.lazy(() => import('@mui/icons-material/Battery3BarSharp')),
    Battery3BarTwoTone: React.lazy(() => import('@mui/icons-material/Battery3BarTwoTone')),
    Battery4Bar: React.lazy(() => import('@mui/icons-material/Battery4Bar')),
    Battery4BarOutlined: React.lazy(() => import('@mui/icons-material/Battery4BarOutlined')),
    Battery4BarRounded: React.lazy(() => import('@mui/icons-material/Battery4BarRounded')),
    Battery4BarSharp: React.lazy(() => import('@mui/icons-material/Battery4BarSharp')),
    Battery4BarTwoTone: React.lazy(() => import('@mui/icons-material/Battery4BarTwoTone')),
    Battery50: React.lazy(() => import('@mui/icons-material/Battery50')),
    Battery50Outlined: React.lazy(() => import('@mui/icons-material/Battery50Outlined')),
    Battery50Rounded: React.lazy(() => import('@mui/icons-material/Battery50Rounded')),
    Battery50Sharp: React.lazy(() => import('@mui/icons-material/Battery50Sharp')),
    Battery50TwoTone: React.lazy(() => import('@mui/icons-material/Battery50TwoTone')),
    Battery5Bar: React.lazy(() => import('@mui/icons-material/Battery5Bar')),
    Battery5BarOutlined: React.lazy(() => import('@mui/icons-material/Battery5BarOutlined')),
    Battery5BarRounded: React.lazy(() => import('@mui/icons-material/Battery5BarRounded')),
    Battery5BarSharp: React.lazy(() => import('@mui/icons-material/Battery5BarSharp')),
    Battery5BarTwoTone: React.lazy(() => import('@mui/icons-material/Battery5BarTwoTone')),
    Battery60: React.lazy(() => import('@mui/icons-material/Battery60')),
    Battery60Outlined: React.lazy(() => import('@mui/icons-material/Battery60Outlined')),
    Battery60Rounded: React.lazy(() => import('@mui/icons-material/Battery60Rounded')),
    Battery60Sharp: React.lazy(() => import('@mui/icons-material/Battery60Sharp')),
    Battery60TwoTone: React.lazy(() => import('@mui/icons-material/Battery60TwoTone')),
    Battery6Bar: React.lazy(() => import('@mui/icons-material/Battery6Bar')),
    Battery6BarOutlined: React.lazy(() => import('@mui/icons-material/Battery6BarOutlined')),
    Battery6BarRounded: React.lazy(() => import('@mui/icons-material/Battery6BarRounded')),
    Battery6BarSharp: React.lazy(() => import('@mui/icons-material/Battery6BarSharp')),
    Battery6BarTwoTone: React.lazy(() => import('@mui/icons-material/Battery6BarTwoTone')),
    Battery80: React.lazy(() => import('@mui/icons-material/Battery80')),
    Battery80Outlined: React.lazy(() => import('@mui/icons-material/Battery80Outlined')),
    Battery80Rounded: React.lazy(() => import('@mui/icons-material/Battery80Rounded')),
    Battery80Sharp: React.lazy(() => import('@mui/icons-material/Battery80Sharp')),
    Battery80TwoTone: React.lazy(() => import('@mui/icons-material/Battery80TwoTone')),
    Battery90: React.lazy(() => import('@mui/icons-material/Battery90')),
    Battery90Outlined: React.lazy(() => import('@mui/icons-material/Battery90Outlined')),
    Battery90Rounded: React.lazy(() => import('@mui/icons-material/Battery90Rounded')),
    Battery90Sharp: React.lazy(() => import('@mui/icons-material/Battery90Sharp')),
    Battery90TwoTone: React.lazy(() => import('@mui/icons-material/Battery90TwoTone')),
    BatteryAlert: React.lazy(() => import('@mui/icons-material/BatteryAlert')),
    BatteryAlertOutlined: React.lazy(() => import('@mui/icons-material/BatteryAlertOutlined')),
    BatteryAlertRounded: React.lazy(() => import('@mui/icons-material/BatteryAlertRounded')),
    BatteryAlertSharp: React.lazy(() => import('@mui/icons-material/BatteryAlertSharp')),
    BatteryAlertTwoTone: React.lazy(() => import('@mui/icons-material/BatteryAlertTwoTone')),
    BatteryCharging20: React.lazy(() => import('@mui/icons-material/BatteryCharging20')),
    BatteryCharging20Outlined: React.lazy(() => import('@mui/icons-material/BatteryCharging20Outlined')),
    BatteryCharging20Rounded: React.lazy(() => import('@mui/icons-material/BatteryCharging20Rounded')),
    BatteryCharging20Sharp: React.lazy(() => import('@mui/icons-material/BatteryCharging20Sharp')),
    BatteryCharging20TwoTone: React.lazy(() => import('@mui/icons-material/BatteryCharging20TwoTone')),
    BatteryCharging30: React.lazy(() => import('@mui/icons-material/BatteryCharging30')),
    BatteryCharging30Outlined: React.lazy(() => import('@mui/icons-material/BatteryCharging30Outlined')),
    BatteryCharging30Rounded: React.lazy(() => import('@mui/icons-material/BatteryCharging30Rounded')),
    BatteryCharging30Sharp: React.lazy(() => import('@mui/icons-material/BatteryCharging30Sharp')),
    BatteryCharging30TwoTone: React.lazy(() => import('@mui/icons-material/BatteryCharging30TwoTone')),
    BatteryCharging50: React.lazy(() => import('@mui/icons-material/BatteryCharging50')),
    BatteryCharging50Outlined: React.lazy(() => import('@mui/icons-material/BatteryCharging50Outlined')),
    BatteryCharging50Rounded: React.lazy(() => import('@mui/icons-material/BatteryCharging50Rounded')),
    BatteryCharging50Sharp: React.lazy(() => import('@mui/icons-material/BatteryCharging50Sharp')),
    BatteryCharging50TwoTone: React.lazy(() => import('@mui/icons-material/BatteryCharging50TwoTone')),
    BatteryCharging60: React.lazy(() => import('@mui/icons-material/BatteryCharging60')),
    BatteryCharging60Outlined: React.lazy(() => import('@mui/icons-material/BatteryCharging60Outlined')),
    BatteryCharging60Rounded: React.lazy(() => import('@mui/icons-material/BatteryCharging60Rounded')),
    BatteryCharging60Sharp: React.lazy(() => import('@mui/icons-material/BatteryCharging60Sharp')),
    BatteryCharging60TwoTone: React.lazy(() => import('@mui/icons-material/BatteryCharging60TwoTone')),
    BatteryCharging80: React.lazy(() => import('@mui/icons-material/BatteryCharging80')),
    BatteryCharging80Outlined: React.lazy(() => import('@mui/icons-material/BatteryCharging80Outlined')),
    BatteryCharging80Rounded: React.lazy(() => import('@mui/icons-material/BatteryCharging80Rounded')),
    BatteryCharging80Sharp: React.lazy(() => import('@mui/icons-material/BatteryCharging80Sharp')),
    BatteryCharging80TwoTone: React.lazy(() => import('@mui/icons-material/BatteryCharging80TwoTone')),
    BatteryCharging90: React.lazy(() => import('@mui/icons-material/BatteryCharging90')),
    BatteryCharging90Outlined: React.lazy(() => import('@mui/icons-material/BatteryCharging90Outlined')),
    BatteryCharging90Rounded: React.lazy(() => import('@mui/icons-material/BatteryCharging90Rounded')),
    BatteryCharging90Sharp: React.lazy(() => import('@mui/icons-material/BatteryCharging90Sharp')),
    BatteryCharging90TwoTone: React.lazy(() => import('@mui/icons-material/BatteryCharging90TwoTone')),
    BatteryChargingFull: React.lazy(() => import('@mui/icons-material/BatteryChargingFull')),
    BatteryChargingFullOutlined: React.lazy(() => import('@mui/icons-material/BatteryChargingFullOutlined')),
    BatteryChargingFullRounded: React.lazy(() => import('@mui/icons-material/BatteryChargingFullRounded')),
    BatteryChargingFullSharp: React.lazy(() => import('@mui/icons-material/BatteryChargingFullSharp')),
    BatteryChargingFullTwoTone: React.lazy(() => import('@mui/icons-material/BatteryChargingFullTwoTone')),
    BatteryFull: React.lazy(() => import('@mui/icons-material/BatteryFull')),
    BatteryFullOutlined: React.lazy(() => import('@mui/icons-material/BatteryFullOutlined')),
    BatteryFullRounded: React.lazy(() => import('@mui/icons-material/BatteryFullRounded')),
    BatteryFullSharp: React.lazy(() => import('@mui/icons-material/BatteryFullSharp')),
    BatteryFullTwoTone: React.lazy(() => import('@mui/icons-material/BatteryFullTwoTone')),
    BatterySaver: React.lazy(() => import('@mui/icons-material/BatterySaver')),
    BatterySaverOutlined: React.lazy(() => import('@mui/icons-material/BatterySaverOutlined')),
    BatterySaverRounded: React.lazy(() => import('@mui/icons-material/BatterySaverRounded')),
    BatterySaverSharp: React.lazy(() => import('@mui/icons-material/BatterySaverSharp')),
    BatterySaverTwoTone: React.lazy(() => import('@mui/icons-material/BatterySaverTwoTone')),
    BatteryStd: React.lazy(() => import('@mui/icons-material/BatteryStd')),
    BatteryStdOutlined: React.lazy(() => import('@mui/icons-material/BatteryStdOutlined')),
    BatteryStdRounded: React.lazy(() => import('@mui/icons-material/BatteryStdRounded')),
    BatteryStdSharp: React.lazy(() => import('@mui/icons-material/BatteryStdSharp')),
    BatteryStdTwoTone: React.lazy(() => import('@mui/icons-material/BatteryStdTwoTone')),
    BatteryUnknown: React.lazy(() => import('@mui/icons-material/BatteryUnknown')),
    BatteryUnknownOutlined: React.lazy(() => import('@mui/icons-material/BatteryUnknownOutlined')),
    BatteryUnknownRounded: React.lazy(() => import('@mui/icons-material/BatteryUnknownRounded')),
    BatteryUnknownSharp: React.lazy(() => import('@mui/icons-material/BatteryUnknownSharp')),
    BatteryUnknownTwoTone: React.lazy(() => import('@mui/icons-material/BatteryUnknownTwoTone')),
    BeachAccess: React.lazy(() => import('@mui/icons-material/BeachAccess')),
    BeachAccessOutlined: React.lazy(() => import('@mui/icons-material/BeachAccessOutlined')),
    BeachAccessRounded: React.lazy(() => import('@mui/icons-material/BeachAccessRounded')),
    BeachAccessSharp: React.lazy(() => import('@mui/icons-material/BeachAccessSharp')),
    BeachAccessTwoTone: React.lazy(() => import('@mui/icons-material/BeachAccessTwoTone')),
    Bed: React.lazy(() => import('@mui/icons-material/Bed')),
    BedOutlined: React.lazy(() => import('@mui/icons-material/BedOutlined')),
    BedroomBaby: React.lazy(() => import('@mui/icons-material/BedroomBaby')),
    BedroomBabyOutlined: React.lazy(() => import('@mui/icons-material/BedroomBabyOutlined')),
    BedroomBabyRounded: React.lazy(() => import('@mui/icons-material/BedroomBabyRounded')),
    BedroomBabySharp: React.lazy(() => import('@mui/icons-material/BedroomBabySharp')),
    BedroomBabyTwoTone: React.lazy(() => import('@mui/icons-material/BedroomBabyTwoTone')),
    BedroomChild: React.lazy(() => import('@mui/icons-material/BedroomChild')),
    BedroomChildOutlined: React.lazy(() => import('@mui/icons-material/BedroomChildOutlined')),
    BedroomChildRounded: React.lazy(() => import('@mui/icons-material/BedroomChildRounded')),
    BedroomChildSharp: React.lazy(() => import('@mui/icons-material/BedroomChildSharp')),
    BedroomChildTwoTone: React.lazy(() => import('@mui/icons-material/BedroomChildTwoTone')),
    BedroomParent: React.lazy(() => import('@mui/icons-material/BedroomParent')),
    BedroomParentOutlined: React.lazy(() => import('@mui/icons-material/BedroomParentOutlined')),
    BedroomParentRounded: React.lazy(() => import('@mui/icons-material/BedroomParentRounded')),
    BedroomParentSharp: React.lazy(() => import('@mui/icons-material/BedroomParentSharp')),
    BedroomParentTwoTone: React.lazy(() => import('@mui/icons-material/BedroomParentTwoTone')),
    BedRounded: React.lazy(() => import('@mui/icons-material/BedRounded')),
    BedSharp: React.lazy(() => import('@mui/icons-material/BedSharp')),
    Bedtime: React.lazy(() => import('@mui/icons-material/Bedtime')),
    BedtimeOff: React.lazy(() => import('@mui/icons-material/BedtimeOff')),
    BedtimeOffOutlined: React.lazy(() => import('@mui/icons-material/BedtimeOffOutlined')),
    BedtimeOffRounded: React.lazy(() => import('@mui/icons-material/BedtimeOffRounded')),
    BedtimeOffSharp: React.lazy(() => import('@mui/icons-material/BedtimeOffSharp')),
    BedtimeOffTwoTone: React.lazy(() => import('@mui/icons-material/BedtimeOffTwoTone')),
    BedtimeOutlined: React.lazy(() => import('@mui/icons-material/BedtimeOutlined')),
    BedtimeRounded: React.lazy(() => import('@mui/icons-material/BedtimeRounded')),
    BedtimeSharp: React.lazy(() => import('@mui/icons-material/BedtimeSharp')),
    BedtimeTwoTone: React.lazy(() => import('@mui/icons-material/BedtimeTwoTone')),
    BedTwoTone: React.lazy(() => import('@mui/icons-material/BedTwoTone')),
    Beenhere: React.lazy(() => import('@mui/icons-material/Beenhere')),
    BeenhereOutlined: React.lazy(() => import('@mui/icons-material/BeenhereOutlined')),
    BeenhereRounded: React.lazy(() => import('@mui/icons-material/BeenhereRounded')),
    BeenhereSharp: React.lazy(() => import('@mui/icons-material/BeenhereSharp')),
    BeenhereTwoTone: React.lazy(() => import('@mui/icons-material/BeenhereTwoTone')),
    Bento: React.lazy(() => import('@mui/icons-material/Bento')),
    BentoOutlined: React.lazy(() => import('@mui/icons-material/BentoOutlined')),
    BentoRounded: React.lazy(() => import('@mui/icons-material/BentoRounded')),
    BentoSharp: React.lazy(() => import('@mui/icons-material/BentoSharp')),
    BentoTwoTone: React.lazy(() => import('@mui/icons-material/BentoTwoTone')),
    BikeScooter: React.lazy(() => import('@mui/icons-material/BikeScooter')),
    BikeScooterOutlined: React.lazy(() => import('@mui/icons-material/BikeScooterOutlined')),
    BikeScooterRounded: React.lazy(() => import('@mui/icons-material/BikeScooterRounded')),
    BikeScooterSharp: React.lazy(() => import('@mui/icons-material/BikeScooterSharp')),
    BikeScooterTwoTone: React.lazy(() => import('@mui/icons-material/BikeScooterTwoTone')),
    Biotech: React.lazy(() => import('@mui/icons-material/Biotech')),
    BiotechOutlined: React.lazy(() => import('@mui/icons-material/BiotechOutlined')),
    BiotechRounded: React.lazy(() => import('@mui/icons-material/BiotechRounded')),
    BiotechSharp: React.lazy(() => import('@mui/icons-material/BiotechSharp')),
    BiotechTwoTone: React.lazy(() => import('@mui/icons-material/BiotechTwoTone')),
    Blender: React.lazy(() => import('@mui/icons-material/Blender')),
    BlenderOutlined: React.lazy(() => import('@mui/icons-material/BlenderOutlined')),
    BlenderRounded: React.lazy(() => import('@mui/icons-material/BlenderRounded')),
    BlenderSharp: React.lazy(() => import('@mui/icons-material/BlenderSharp')),
    BlenderTwoTone: React.lazy(() => import('@mui/icons-material/BlenderTwoTone')),
    Blind: React.lazy(() => import('@mui/icons-material/Blind')),
    BlindOutlined: React.lazy(() => import('@mui/icons-material/BlindOutlined')),
    BlindRounded: React.lazy(() => import('@mui/icons-material/BlindRounded')),
    BlindsClosed: React.lazy(() => import('@mui/icons-material/BlindsClosed')),
    BlindsClosedOutlined: React.lazy(() => import('@mui/icons-material/BlindsClosedOutlined')),
    BlindsClosedRounded: React.lazy(() => import('@mui/icons-material/BlindsClosedRounded')),
    BlindsClosedSharp: React.lazy(() => import('@mui/icons-material/BlindsClosedSharp')),
    BlindsClosedTwoTone: React.lazy(() => import('@mui/icons-material/BlindsClosedTwoTone')),
    Blinds: React.lazy(() => import('@mui/icons-material/Blinds')),
    BlindSharp: React.lazy(() => import('@mui/icons-material/BlindSharp')),
    BlindsOutlined: React.lazy(() => import('@mui/icons-material/BlindsOutlined')),
    BlindsRounded: React.lazy(() => import('@mui/icons-material/BlindsRounded')),
    BlindsSharp: React.lazy(() => import('@mui/icons-material/BlindsSharp')),
    BlindsTwoTone: React.lazy(() => import('@mui/icons-material/BlindsTwoTone')),
    BlindTwoTone: React.lazy(() => import('@mui/icons-material/BlindTwoTone')),
    Block: React.lazy(() => import('@mui/icons-material/Block')),
    BlockOutlined: React.lazy(() => import('@mui/icons-material/BlockOutlined')),
    BlockRounded: React.lazy(() => import('@mui/icons-material/BlockRounded')),
    BlockSharp: React.lazy(() => import('@mui/icons-material/BlockSharp')),
    BlockTwoTone: React.lazy(() => import('@mui/icons-material/BlockTwoTone')),
    Bloodtype: React.lazy(() => import('@mui/icons-material/Bloodtype')),
    BloodtypeOutlined: React.lazy(() => import('@mui/icons-material/BloodtypeOutlined')),
    BloodtypeRounded: React.lazy(() => import('@mui/icons-material/BloodtypeRounded')),
    BloodtypeSharp: React.lazy(() => import('@mui/icons-material/BloodtypeSharp')),
    BloodtypeTwoTone: React.lazy(() => import('@mui/icons-material/BloodtypeTwoTone')),
    BluetoothAudio: React.lazy(() => import('@mui/icons-material/BluetoothAudio')),
    BluetoothAudioOutlined: React.lazy(() => import('@mui/icons-material/BluetoothAudioOutlined')),
    BluetoothAudioRounded: React.lazy(() => import('@mui/icons-material/BluetoothAudioRounded')),
    BluetoothAudioSharp: React.lazy(() => import('@mui/icons-material/BluetoothAudioSharp')),
    BluetoothAudioTwoTone: React.lazy(() => import('@mui/icons-material/BluetoothAudioTwoTone')),
    BluetoothConnected: React.lazy(() => import('@mui/icons-material/BluetoothConnected')),
    BluetoothConnectedOutlined: React.lazy(() => import('@mui/icons-material/BluetoothConnectedOutlined')),
    BluetoothConnectedRounded: React.lazy(() => import('@mui/icons-material/BluetoothConnectedRounded')),
    BluetoothConnectedSharp: React.lazy(() => import('@mui/icons-material/BluetoothConnectedSharp')),
    BluetoothConnectedTwoTone: React.lazy(() => import('@mui/icons-material/BluetoothConnectedTwoTone')),
    BluetoothDisabled: React.lazy(() => import('@mui/icons-material/BluetoothDisabled')),
    BluetoothDisabledOutlined: React.lazy(() => import('@mui/icons-material/BluetoothDisabledOutlined')),
    BluetoothDisabledRounded: React.lazy(() => import('@mui/icons-material/BluetoothDisabledRounded')),
    BluetoothDisabledSharp: React.lazy(() => import('@mui/icons-material/BluetoothDisabledSharp')),
    BluetoothDisabledTwoTone: React.lazy(() => import('@mui/icons-material/BluetoothDisabledTwoTone')),
    BluetoothDrive: React.lazy(() => import('@mui/icons-material/BluetoothDrive')),
    BluetoothDriveOutlined: React.lazy(() => import('@mui/icons-material/BluetoothDriveOutlined')),
    BluetoothDriveRounded: React.lazy(() => import('@mui/icons-material/BluetoothDriveRounded')),
    BluetoothDriveSharp: React.lazy(() => import('@mui/icons-material/BluetoothDriveSharp')),
    BluetoothDriveTwoTone: React.lazy(() => import('@mui/icons-material/BluetoothDriveTwoTone')),
    Bluetooth: React.lazy(() => import('@mui/icons-material/Bluetooth')),
    BluetoothOutlined: React.lazy(() => import('@mui/icons-material/BluetoothOutlined')),
    BluetoothRounded: React.lazy(() => import('@mui/icons-material/BluetoothRounded')),
    BluetoothSearching: React.lazy(() => import('@mui/icons-material/BluetoothSearching')),
    BluetoothSearchingOutlined: React.lazy(() => import('@mui/icons-material/BluetoothSearchingOutlined')),
    BluetoothSearchingRounded: React.lazy(() => import('@mui/icons-material/BluetoothSearchingRounded')),
    BluetoothSearchingSharp: React.lazy(() => import('@mui/icons-material/BluetoothSearchingSharp')),
    BluetoothSearchingTwoTone: React.lazy(() => import('@mui/icons-material/BluetoothSearchingTwoTone')),
    BluetoothSharp: React.lazy(() => import('@mui/icons-material/BluetoothSharp')),
    BluetoothTwoTone: React.lazy(() => import('@mui/icons-material/BluetoothTwoTone')),
    BlurCircular: React.lazy(() => import('@mui/icons-material/BlurCircular')),
    BlurCircularOutlined: React.lazy(() => import('@mui/icons-material/BlurCircularOutlined')),
    BlurCircularRounded: React.lazy(() => import('@mui/icons-material/BlurCircularRounded')),
    BlurCircularSharp: React.lazy(() => import('@mui/icons-material/BlurCircularSharp')),
    BlurCircularTwoTone: React.lazy(() => import('@mui/icons-material/BlurCircularTwoTone')),
    BlurLinear: React.lazy(() => import('@mui/icons-material/BlurLinear')),
    BlurLinearOutlined: React.lazy(() => import('@mui/icons-material/BlurLinearOutlined')),
    BlurLinearRounded: React.lazy(() => import('@mui/icons-material/BlurLinearRounded')),
    BlurLinearSharp: React.lazy(() => import('@mui/icons-material/BlurLinearSharp')),
    BlurLinearTwoTone: React.lazy(() => import('@mui/icons-material/BlurLinearTwoTone')),
    BlurOff: React.lazy(() => import('@mui/icons-material/BlurOff')),
    BlurOffOutlined: React.lazy(() => import('@mui/icons-material/BlurOffOutlined')),
    BlurOffRounded: React.lazy(() => import('@mui/icons-material/BlurOffRounded')),
    BlurOffSharp: React.lazy(() => import('@mui/icons-material/BlurOffSharp')),
    BlurOffTwoTone: React.lazy(() => import('@mui/icons-material/BlurOffTwoTone')),
    BlurOn: React.lazy(() => import('@mui/icons-material/BlurOn')),
    BlurOnOutlined: React.lazy(() => import('@mui/icons-material/BlurOnOutlined')),
    BlurOnRounded: React.lazy(() => import('@mui/icons-material/BlurOnRounded')),
    BlurOnSharp: React.lazy(() => import('@mui/icons-material/BlurOnSharp')),
    BlurOnTwoTone: React.lazy(() => import('@mui/icons-material/BlurOnTwoTone')),
    Bolt: React.lazy(() => import('@mui/icons-material/Bolt')),
    BoltOutlined: React.lazy(() => import('@mui/icons-material/BoltOutlined')),
    BoltRounded: React.lazy(() => import('@mui/icons-material/BoltRounded')),
    BoltSharp: React.lazy(() => import('@mui/icons-material/BoltSharp')),
    BoltTwoTone: React.lazy(() => import('@mui/icons-material/BoltTwoTone')),
    Book: React.lazy(() => import('@mui/icons-material/Book')),
    BookmarkAdd: React.lazy(() => import('@mui/icons-material/BookmarkAdd')),
    BookmarkAdded: React.lazy(() => import('@mui/icons-material/BookmarkAdded')),
    BookmarkAddedOutlined: React.lazy(() => import('@mui/icons-material/BookmarkAddedOutlined')),
    BookmarkAddedRounded: React.lazy(() => import('@mui/icons-material/BookmarkAddedRounded')),
    BookmarkAddedSharp: React.lazy(() => import('@mui/icons-material/BookmarkAddedSharp')),
    BookmarkAddedTwoTone: React.lazy(() => import('@mui/icons-material/BookmarkAddedTwoTone')),
    BookmarkAddOutlined: React.lazy(() => import('@mui/icons-material/BookmarkAddOutlined')),
    BookmarkAddRounded: React.lazy(() => import('@mui/icons-material/BookmarkAddRounded')),
    BookmarkAddSharp: React.lazy(() => import('@mui/icons-material/BookmarkAddSharp')),
    BookmarkAddTwoTone: React.lazy(() => import('@mui/icons-material/BookmarkAddTwoTone')),
    BookmarkBorder: React.lazy(() => import('@mui/icons-material/BookmarkBorder')),
    BookmarkBorderOutlined: React.lazy(() => import('@mui/icons-material/BookmarkBorderOutlined')),
    BookmarkBorderRounded: React.lazy(() => import('@mui/icons-material/BookmarkBorderRounded')),
    BookmarkBorderSharp: React.lazy(() => import('@mui/icons-material/BookmarkBorderSharp')),
    BookmarkBorderTwoTone: React.lazy(() => import('@mui/icons-material/BookmarkBorderTwoTone')),
    Bookmark: React.lazy(() => import('@mui/icons-material/Bookmark')),
    BookmarkOutlined: React.lazy(() => import('@mui/icons-material/BookmarkOutlined')),
    BookmarkRemove: React.lazy(() => import('@mui/icons-material/BookmarkRemove')),
    BookmarkRemoveOutlined: React.lazy(() => import('@mui/icons-material/BookmarkRemoveOutlined')),
    BookmarkRemoveRounded: React.lazy(() => import('@mui/icons-material/BookmarkRemoveRounded')),
    BookmarkRemoveSharp: React.lazy(() => import('@mui/icons-material/BookmarkRemoveSharp')),
    BookmarkRemoveTwoTone: React.lazy(() => import('@mui/icons-material/BookmarkRemoveTwoTone')),
    BookmarkRounded: React.lazy(() => import('@mui/icons-material/BookmarkRounded')),
    Bookmarks: React.lazy(() => import('@mui/icons-material/Bookmarks')),
    BookmarkSharp: React.lazy(() => import('@mui/icons-material/BookmarkSharp')),
    BookmarksOutlined: React.lazy(() => import('@mui/icons-material/BookmarksOutlined')),
    BookmarksRounded: React.lazy(() => import('@mui/icons-material/BookmarksRounded')),
    BookmarksSharp: React.lazy(() => import('@mui/icons-material/BookmarksSharp')),
    BookmarksTwoTone: React.lazy(() => import('@mui/icons-material/BookmarksTwoTone')),
    BookmarkTwoTone: React.lazy(() => import('@mui/icons-material/BookmarkTwoTone')),
    BookOnline: React.lazy(() => import('@mui/icons-material/BookOnline')),
    BookOnlineOutlined: React.lazy(() => import('@mui/icons-material/BookOnlineOutlined')),
    BookOnlineRounded: React.lazy(() => import('@mui/icons-material/BookOnlineRounded')),
    BookOnlineSharp: React.lazy(() => import('@mui/icons-material/BookOnlineSharp')),
    BookOnlineTwoTone: React.lazy(() => import('@mui/icons-material/BookOnlineTwoTone')),
    BookOutlined: React.lazy(() => import('@mui/icons-material/BookOutlined')),
    BookRounded: React.lazy(() => import('@mui/icons-material/BookRounded')),
    BookSharp: React.lazy(() => import('@mui/icons-material/BookSharp')),
    BookTwoTone: React.lazy(() => import('@mui/icons-material/BookTwoTone')),
    BorderAll: React.lazy(() => import('@mui/icons-material/BorderAll')),
    BorderAllOutlined: React.lazy(() => import('@mui/icons-material/BorderAllOutlined')),
    BorderAllRounded: React.lazy(() => import('@mui/icons-material/BorderAllRounded')),
    BorderAllSharp: React.lazy(() => import('@mui/icons-material/BorderAllSharp')),
    BorderAllTwoTone: React.lazy(() => import('@mui/icons-material/BorderAllTwoTone')),
    BorderBottom: React.lazy(() => import('@mui/icons-material/BorderBottom')),
    BorderBottomOutlined: React.lazy(() => import('@mui/icons-material/BorderBottomOutlined')),
    BorderBottomRounded: React.lazy(() => import('@mui/icons-material/BorderBottomRounded')),
    BorderBottomSharp: React.lazy(() => import('@mui/icons-material/BorderBottomSharp')),
    BorderBottomTwoTone: React.lazy(() => import('@mui/icons-material/BorderBottomTwoTone')),
    BorderClear: React.lazy(() => import('@mui/icons-material/BorderClear')),
    BorderClearOutlined: React.lazy(() => import('@mui/icons-material/BorderClearOutlined')),
    BorderClearRounded: React.lazy(() => import('@mui/icons-material/BorderClearRounded')),
    BorderClearSharp: React.lazy(() => import('@mui/icons-material/BorderClearSharp')),
    BorderClearTwoTone: React.lazy(() => import('@mui/icons-material/BorderClearTwoTone')),
    BorderColor: React.lazy(() => import('@mui/icons-material/BorderColor')),
    BorderColorOutlined: React.lazy(() => import('@mui/icons-material/BorderColorOutlined')),
    BorderColorRounded: React.lazy(() => import('@mui/icons-material/BorderColorRounded')),
    BorderColorSharp: React.lazy(() => import('@mui/icons-material/BorderColorSharp')),
    BorderColorTwoTone: React.lazy(() => import('@mui/icons-material/BorderColorTwoTone')),
    BorderHorizontal: React.lazy(() => import('@mui/icons-material/BorderHorizontal')),
    BorderHorizontalOutlined: React.lazy(() => import('@mui/icons-material/BorderHorizontalOutlined')),
    BorderHorizontalRounded: React.lazy(() => import('@mui/icons-material/BorderHorizontalRounded')),
    BorderHorizontalSharp: React.lazy(() => import('@mui/icons-material/BorderHorizontalSharp')),
    BorderHorizontalTwoTone: React.lazy(() => import('@mui/icons-material/BorderHorizontalTwoTone')),
    BorderInner: React.lazy(() => import('@mui/icons-material/BorderInner')),
    BorderInnerOutlined: React.lazy(() => import('@mui/icons-material/BorderInnerOutlined')),
    BorderInnerRounded: React.lazy(() => import('@mui/icons-material/BorderInnerRounded')),
    BorderInnerSharp: React.lazy(() => import('@mui/icons-material/BorderInnerSharp')),
    BorderInnerTwoTone: React.lazy(() => import('@mui/icons-material/BorderInnerTwoTone')),
    BorderLeft: React.lazy(() => import('@mui/icons-material/BorderLeft')),
    BorderLeftOutlined: React.lazy(() => import('@mui/icons-material/BorderLeftOutlined')),
    BorderLeftRounded: React.lazy(() => import('@mui/icons-material/BorderLeftRounded')),
    BorderLeftSharp: React.lazy(() => import('@mui/icons-material/BorderLeftSharp')),
    BorderLeftTwoTone: React.lazy(() => import('@mui/icons-material/BorderLeftTwoTone')),
    BorderOuter: React.lazy(() => import('@mui/icons-material/BorderOuter')),
    BorderOuterOutlined: React.lazy(() => import('@mui/icons-material/BorderOuterOutlined')),
    BorderOuterRounded: React.lazy(() => import('@mui/icons-material/BorderOuterRounded')),
    BorderOuterSharp: React.lazy(() => import('@mui/icons-material/BorderOuterSharp')),
    BorderOuterTwoTone: React.lazy(() => import('@mui/icons-material/BorderOuterTwoTone')),
    BorderRight: React.lazy(() => import('@mui/icons-material/BorderRight')),
    BorderRightOutlined: React.lazy(() => import('@mui/icons-material/BorderRightOutlined')),
    BorderRightRounded: React.lazy(() => import('@mui/icons-material/BorderRightRounded')),
    BorderRightSharp: React.lazy(() => import('@mui/icons-material/BorderRightSharp')),
    BorderRightTwoTone: React.lazy(() => import('@mui/icons-material/BorderRightTwoTone')),
    BorderStyle: React.lazy(() => import('@mui/icons-material/BorderStyle')),
    BorderStyleOutlined: React.lazy(() => import('@mui/icons-material/BorderStyleOutlined')),
    BorderStyleRounded: React.lazy(() => import('@mui/icons-material/BorderStyleRounded')),
    BorderStyleSharp: React.lazy(() => import('@mui/icons-material/BorderStyleSharp')),
    BorderStyleTwoTone: React.lazy(() => import('@mui/icons-material/BorderStyleTwoTone')),
    BorderTop: React.lazy(() => import('@mui/icons-material/BorderTop')),
    BorderTopOutlined: React.lazy(() => import('@mui/icons-material/BorderTopOutlined')),
    BorderTopRounded: React.lazy(() => import('@mui/icons-material/BorderTopRounded')),
    BorderTopSharp: React.lazy(() => import('@mui/icons-material/BorderTopSharp')),
    BorderTopTwoTone: React.lazy(() => import('@mui/icons-material/BorderTopTwoTone')),
    BorderVertical: React.lazy(() => import('@mui/icons-material/BorderVertical')),
    BorderVerticalOutlined: React.lazy(() => import('@mui/icons-material/BorderVerticalOutlined')),
    BorderVerticalRounded: React.lazy(() => import('@mui/icons-material/BorderVerticalRounded')),
    BorderVerticalSharp: React.lazy(() => import('@mui/icons-material/BorderVerticalSharp')),
    BorderVerticalTwoTone: React.lazy(() => import('@mui/icons-material/BorderVerticalTwoTone')),
    Boy: React.lazy(() => import('@mui/icons-material/Boy')),
    BoyOutlined: React.lazy(() => import('@mui/icons-material/BoyOutlined')),
    BoyRounded: React.lazy(() => import('@mui/icons-material/BoyRounded')),
    BoySharp: React.lazy(() => import('@mui/icons-material/BoySharp')),
    BoyTwoTone: React.lazy(() => import('@mui/icons-material/BoyTwoTone')),
    BrandingWatermark: React.lazy(() => import('@mui/icons-material/BrandingWatermark')),
    BrandingWatermarkOutlined: React.lazy(() => import('@mui/icons-material/BrandingWatermarkOutlined')),
    BrandingWatermarkRounded: React.lazy(() => import('@mui/icons-material/BrandingWatermarkRounded')),
    BrandingWatermarkSharp: React.lazy(() => import('@mui/icons-material/BrandingWatermarkSharp')),
    BrandingWatermarkTwoTone: React.lazy(() => import('@mui/icons-material/BrandingWatermarkTwoTone')),
    BreakfastDining: React.lazy(() => import('@mui/icons-material/BreakfastDining')),
    BreakfastDiningOutlined: React.lazy(() => import('@mui/icons-material/BreakfastDiningOutlined')),
    BreakfastDiningRounded: React.lazy(() => import('@mui/icons-material/BreakfastDiningRounded')),
    BreakfastDiningSharp: React.lazy(() => import('@mui/icons-material/BreakfastDiningSharp')),
    BreakfastDiningTwoTone: React.lazy(() => import('@mui/icons-material/BreakfastDiningTwoTone')),
    Brightness1: React.lazy(() => import('@mui/icons-material/Brightness1')),
    Brightness1Outlined: React.lazy(() => import('@mui/icons-material/Brightness1Outlined')),
    Brightness1Rounded: React.lazy(() => import('@mui/icons-material/Brightness1Rounded')),
    Brightness1Sharp: React.lazy(() => import('@mui/icons-material/Brightness1Sharp')),
    Brightness1TwoTone: React.lazy(() => import('@mui/icons-material/Brightness1TwoTone')),
    Brightness2: React.lazy(() => import('@mui/icons-material/Brightness2')),
    Brightness2Outlined: React.lazy(() => import('@mui/icons-material/Brightness2Outlined')),
    Brightness2Rounded: React.lazy(() => import('@mui/icons-material/Brightness2Rounded')),
    Brightness2Sharp: React.lazy(() => import('@mui/icons-material/Brightness2Sharp')),
    Brightness2TwoTone: React.lazy(() => import('@mui/icons-material/Brightness2TwoTone')),
    Brightness3: React.lazy(() => import('@mui/icons-material/Brightness3')),
    Brightness3Outlined: React.lazy(() => import('@mui/icons-material/Brightness3Outlined')),
    Brightness3Rounded: React.lazy(() => import('@mui/icons-material/Brightness3Rounded')),
    Brightness3Sharp: React.lazy(() => import('@mui/icons-material/Brightness3Sharp')),
    Brightness3TwoTone: React.lazy(() => import('@mui/icons-material/Brightness3TwoTone')),
    Brightness4: React.lazy(() => import('@mui/icons-material/Brightness4')),
    Brightness4Outlined: React.lazy(() => import('@mui/icons-material/Brightness4Outlined')),
    Brightness4Rounded: React.lazy(() => import('@mui/icons-material/Brightness4Rounded')),
    Brightness4Sharp: React.lazy(() => import('@mui/icons-material/Brightness4Sharp')),
    Brightness4TwoTone: React.lazy(() => import('@mui/icons-material/Brightness4TwoTone')),
    Brightness5: React.lazy(() => import('@mui/icons-material/Brightness5')),
    Brightness5Outlined: React.lazy(() => import('@mui/icons-material/Brightness5Outlined')),
    Brightness5Rounded: React.lazy(() => import('@mui/icons-material/Brightness5Rounded')),
    Brightness5Sharp: React.lazy(() => import('@mui/icons-material/Brightness5Sharp')),
    Brightness5TwoTone: React.lazy(() => import('@mui/icons-material/Brightness5TwoTone')),
    Brightness6: React.lazy(() => import('@mui/icons-material/Brightness6')),
    Brightness6Outlined: React.lazy(() => import('@mui/icons-material/Brightness6Outlined')),
    Brightness6Rounded: React.lazy(() => import('@mui/icons-material/Brightness6Rounded')),
    Brightness6Sharp: React.lazy(() => import('@mui/icons-material/Brightness6Sharp')),
    Brightness6TwoTone: React.lazy(() => import('@mui/icons-material/Brightness6TwoTone')),
    Brightness7: React.lazy(() => import('@mui/icons-material/Brightness7')),
    Brightness7Outlined: React.lazy(() => import('@mui/icons-material/Brightness7Outlined')),
    Brightness7Rounded: React.lazy(() => import('@mui/icons-material/Brightness7Rounded')),
    Brightness7Sharp: React.lazy(() => import('@mui/icons-material/Brightness7Sharp')),
    Brightness7TwoTone: React.lazy(() => import('@mui/icons-material/Brightness7TwoTone')),
    BrightnessAuto: React.lazy(() => import('@mui/icons-material/BrightnessAuto')),
    BrightnessAutoOutlined: React.lazy(() => import('@mui/icons-material/BrightnessAutoOutlined')),
    BrightnessAutoRounded: React.lazy(() => import('@mui/icons-material/BrightnessAutoRounded')),
    BrightnessAutoSharp: React.lazy(() => import('@mui/icons-material/BrightnessAutoSharp')),
    BrightnessAutoTwoTone: React.lazy(() => import('@mui/icons-material/BrightnessAutoTwoTone')),
    BrightnessHigh: React.lazy(() => import('@mui/icons-material/BrightnessHigh')),
    BrightnessHighOutlined: React.lazy(() => import('@mui/icons-material/BrightnessHighOutlined')),
    BrightnessHighRounded: React.lazy(() => import('@mui/icons-material/BrightnessHighRounded')),
    BrightnessHighSharp: React.lazy(() => import('@mui/icons-material/BrightnessHighSharp')),
    BrightnessHighTwoTone: React.lazy(() => import('@mui/icons-material/BrightnessHighTwoTone')),
    BrightnessLow: React.lazy(() => import('@mui/icons-material/BrightnessLow')),
    BrightnessLowOutlined: React.lazy(() => import('@mui/icons-material/BrightnessLowOutlined')),
    BrightnessLowRounded: React.lazy(() => import('@mui/icons-material/BrightnessLowRounded')),
    BrightnessLowSharp: React.lazy(() => import('@mui/icons-material/BrightnessLowSharp')),
    BrightnessLowTwoTone: React.lazy(() => import('@mui/icons-material/BrightnessLowTwoTone')),
    BrightnessMedium: React.lazy(() => import('@mui/icons-material/BrightnessMedium')),
    BrightnessMediumOutlined: React.lazy(() => import('@mui/icons-material/BrightnessMediumOutlined')),
    BrightnessMediumRounded: React.lazy(() => import('@mui/icons-material/BrightnessMediumRounded')),
    BrightnessMediumSharp: React.lazy(() => import('@mui/icons-material/BrightnessMediumSharp')),
    BrightnessMediumTwoTone: React.lazy(() => import('@mui/icons-material/BrightnessMediumTwoTone')),
    BroadcastOnHome: React.lazy(() => import('@mui/icons-material/BroadcastOnHome')),
    BroadcastOnHomeOutlined: React.lazy(() => import('@mui/icons-material/BroadcastOnHomeOutlined')),
    BroadcastOnHomeRounded: React.lazy(() => import('@mui/icons-material/BroadcastOnHomeRounded')),
    BroadcastOnHomeSharp: React.lazy(() => import('@mui/icons-material/BroadcastOnHomeSharp')),
    BroadcastOnHomeTwoTone: React.lazy(() => import('@mui/icons-material/BroadcastOnHomeTwoTone')),
    BroadcastOnPersonal: React.lazy(() => import('@mui/icons-material/BroadcastOnPersonal')),
    BroadcastOnPersonalOutlined: React.lazy(() => import('@mui/icons-material/BroadcastOnPersonalOutlined')),
    BroadcastOnPersonalRounded: React.lazy(() => import('@mui/icons-material/BroadcastOnPersonalRounded')),
    BroadcastOnPersonalSharp: React.lazy(() => import('@mui/icons-material/BroadcastOnPersonalSharp')),
    BroadcastOnPersonalTwoTone: React.lazy(() => import('@mui/icons-material/BroadcastOnPersonalTwoTone')),
    BrokenImage: React.lazy(() => import('@mui/icons-material/BrokenImage')),
    BrokenImageOutlined: React.lazy(() => import('@mui/icons-material/BrokenImageOutlined')),
    BrokenImageRounded: React.lazy(() => import('@mui/icons-material/BrokenImageRounded')),
    BrokenImageSharp: React.lazy(() => import('@mui/icons-material/BrokenImageSharp')),
    BrokenImageTwoTone: React.lazy(() => import('@mui/icons-material/BrokenImageTwoTone')),
    BrowseGallery: React.lazy(() => import('@mui/icons-material/BrowseGallery')),
    BrowseGalleryOutlined: React.lazy(() => import('@mui/icons-material/BrowseGalleryOutlined')),
    BrowseGalleryRounded: React.lazy(() => import('@mui/icons-material/BrowseGalleryRounded')),
    BrowseGallerySharp: React.lazy(() => import('@mui/icons-material/BrowseGallerySharp')),
    BrowseGalleryTwoTone: React.lazy(() => import('@mui/icons-material/BrowseGalleryTwoTone')),
    BrowserNotSupported: React.lazy(() => import('@mui/icons-material/BrowserNotSupported')),
    BrowserNotSupportedOutlined: React.lazy(() => import('@mui/icons-material/BrowserNotSupportedOutlined')),
    BrowserNotSupportedRounded: React.lazy(() => import('@mui/icons-material/BrowserNotSupportedRounded')),
    BrowserNotSupportedSharp: React.lazy(() => import('@mui/icons-material/BrowserNotSupportedSharp')),
    BrowserNotSupportedTwoTone: React.lazy(() => import('@mui/icons-material/BrowserNotSupportedTwoTone')),
    BrowserUpdated: React.lazy(() => import('@mui/icons-material/BrowserUpdated')),
    BrowserUpdatedOutlined: React.lazy(() => import('@mui/icons-material/BrowserUpdatedOutlined')),
    BrowserUpdatedRounded: React.lazy(() => import('@mui/icons-material/BrowserUpdatedRounded')),
    BrowserUpdatedSharp: React.lazy(() => import('@mui/icons-material/BrowserUpdatedSharp')),
    BrowserUpdatedTwoTone: React.lazy(() => import('@mui/icons-material/BrowserUpdatedTwoTone')),
    BrunchDining: React.lazy(() => import('@mui/icons-material/BrunchDining')),
    BrunchDiningOutlined: React.lazy(() => import('@mui/icons-material/BrunchDiningOutlined')),
    BrunchDiningRounded: React.lazy(() => import('@mui/icons-material/BrunchDiningRounded')),
    BrunchDiningSharp: React.lazy(() => import('@mui/icons-material/BrunchDiningSharp')),
    BrunchDiningTwoTone: React.lazy(() => import('@mui/icons-material/BrunchDiningTwoTone')),
    Brush: React.lazy(() => import('@mui/icons-material/Brush')),
    BrushOutlined: React.lazy(() => import('@mui/icons-material/BrushOutlined')),
    BrushRounded: React.lazy(() => import('@mui/icons-material/BrushRounded')),
    BrushSharp: React.lazy(() => import('@mui/icons-material/BrushSharp')),
    BrushTwoTone: React.lazy(() => import('@mui/icons-material/BrushTwoTone')),
    BubbleChart: React.lazy(() => import('@mui/icons-material/BubbleChart')),
    BubbleChartOutlined: React.lazy(() => import('@mui/icons-material/BubbleChartOutlined')),
    BubbleChartRounded: React.lazy(() => import('@mui/icons-material/BubbleChartRounded')),
    BubbleChartSharp: React.lazy(() => import('@mui/icons-material/BubbleChartSharp')),
    BubbleChartTwoTone: React.lazy(() => import('@mui/icons-material/BubbleChartTwoTone')),
    BugReport: React.lazy(() => import('@mui/icons-material/BugReport')),
    BugReportOutlined: React.lazy(() => import('@mui/icons-material/BugReportOutlined')),
    BugReportRounded: React.lazy(() => import('@mui/icons-material/BugReportRounded')),
    BugReportSharp: React.lazy(() => import('@mui/icons-material/BugReportSharp')),
    BugReportTwoTone: React.lazy(() => import('@mui/icons-material/BugReportTwoTone')),
    BuildCircle: React.lazy(() => import('@mui/icons-material/BuildCircle')),
    BuildCircleOutlined: React.lazy(() => import('@mui/icons-material/BuildCircleOutlined')),
    BuildCircleRounded: React.lazy(() => import('@mui/icons-material/BuildCircleRounded')),
    BuildCircleSharp: React.lazy(() => import('@mui/icons-material/BuildCircleSharp')),
    BuildCircleTwoTone: React.lazy(() => import('@mui/icons-material/BuildCircleTwoTone')),
    Build: React.lazy(() => import('@mui/icons-material/Build')),
    BuildOutlined: React.lazy(() => import('@mui/icons-material/BuildOutlined')),
    BuildRounded: React.lazy(() => import('@mui/icons-material/BuildRounded')),
    BuildSharp: React.lazy(() => import('@mui/icons-material/BuildSharp')),
    BuildTwoTone: React.lazy(() => import('@mui/icons-material/BuildTwoTone')),
    Bungalow: React.lazy(() => import('@mui/icons-material/Bungalow')),
    BungalowOutlined: React.lazy(() => import('@mui/icons-material/BungalowOutlined')),
    BungalowRounded: React.lazy(() => import('@mui/icons-material/BungalowRounded')),
    BungalowSharp: React.lazy(() => import('@mui/icons-material/BungalowSharp')),
    BungalowTwoTone: React.lazy(() => import('@mui/icons-material/BungalowTwoTone')),
    BurstMode: React.lazy(() => import('@mui/icons-material/BurstMode')),
    BurstModeOutlined: React.lazy(() => import('@mui/icons-material/BurstModeOutlined')),
    BurstModeRounded: React.lazy(() => import('@mui/icons-material/BurstModeRounded')),
    BurstModeSharp: React.lazy(() => import('@mui/icons-material/BurstModeSharp')),
    BurstModeTwoTone: React.lazy(() => import('@mui/icons-material/BurstModeTwoTone')),
    BusAlert: React.lazy(() => import('@mui/icons-material/BusAlert')),
    BusAlertOutlined: React.lazy(() => import('@mui/icons-material/BusAlertOutlined')),
    BusAlertRounded: React.lazy(() => import('@mui/icons-material/BusAlertRounded')),
    BusAlertSharp: React.lazy(() => import('@mui/icons-material/BusAlertSharp')),
    BusAlertTwoTone: React.lazy(() => import('@mui/icons-material/BusAlertTwoTone')),
    BusinessCenter: React.lazy(() => import('@mui/icons-material/BusinessCenter')),
    BusinessCenterOutlined: React.lazy(() => import('@mui/icons-material/BusinessCenterOutlined')),
    BusinessCenterRounded: React.lazy(() => import('@mui/icons-material/BusinessCenterRounded')),
    BusinessCenterSharp: React.lazy(() => import('@mui/icons-material/BusinessCenterSharp')),
    BusinessCenterTwoTone: React.lazy(() => import('@mui/icons-material/BusinessCenterTwoTone')),
    Business: React.lazy(() => import('@mui/icons-material/Business')),
    BusinessOutlined: React.lazy(() => import('@mui/icons-material/BusinessOutlined')),
    BusinessRounded: React.lazy(() => import('@mui/icons-material/BusinessRounded')),
    BusinessSharp: React.lazy(() => import('@mui/icons-material/BusinessSharp')),
    BusinessTwoTone: React.lazy(() => import('@mui/icons-material/BusinessTwoTone')),
    Cabin: React.lazy(() => import('@mui/icons-material/Cabin')),
    CabinOutlined: React.lazy(() => import('@mui/icons-material/CabinOutlined')),
    CabinRounded: React.lazy(() => import('@mui/icons-material/CabinRounded')),
    CabinSharp: React.lazy(() => import('@mui/icons-material/CabinSharp')),
    CabinTwoTone: React.lazy(() => import('@mui/icons-material/CabinTwoTone')),
    Cable: React.lazy(() => import('@mui/icons-material/Cable')),
    CableOutlined: React.lazy(() => import('@mui/icons-material/CableOutlined')),
    CableRounded: React.lazy(() => import('@mui/icons-material/CableRounded')),
    CableSharp: React.lazy(() => import('@mui/icons-material/CableSharp')),
    CableTwoTone: React.lazy(() => import('@mui/icons-material/CableTwoTone')),
    Cached: React.lazy(() => import('@mui/icons-material/Cached')),
    CachedOutlined: React.lazy(() => import('@mui/icons-material/CachedOutlined')),
    CachedRounded: React.lazy(() => import('@mui/icons-material/CachedRounded')),
    CachedSharp: React.lazy(() => import('@mui/icons-material/CachedSharp')),
    CachedTwoTone: React.lazy(() => import('@mui/icons-material/CachedTwoTone')),
    Cake: React.lazy(() => import('@mui/icons-material/Cake')),
    CakeOutlined: React.lazy(() => import('@mui/icons-material/CakeOutlined')),
    CakeRounded: React.lazy(() => import('@mui/icons-material/CakeRounded')),
    CakeSharp: React.lazy(() => import('@mui/icons-material/CakeSharp')),
    CakeTwoTone: React.lazy(() => import('@mui/icons-material/CakeTwoTone')),
    Calculate: React.lazy(() => import('@mui/icons-material/Calculate')),
    CalculateOutlined: React.lazy(() => import('@mui/icons-material/CalculateOutlined')),
    CalculateRounded: React.lazy(() => import('@mui/icons-material/CalculateRounded')),
    CalculateSharp: React.lazy(() => import('@mui/icons-material/CalculateSharp')),
    CalculateTwoTone: React.lazy(() => import('@mui/icons-material/CalculateTwoTone')),
    CalendarMonth: React.lazy(() => import('@mui/icons-material/CalendarMonth')),
    CalendarMonthOutlined: React.lazy(() => import('@mui/icons-material/CalendarMonthOutlined')),
    CalendarMonthRounded: React.lazy(() => import('@mui/icons-material/CalendarMonthRounded')),
    CalendarMonthSharp: React.lazy(() => import('@mui/icons-material/CalendarMonthSharp')),
    CalendarMonthTwoTone: React.lazy(() => import('@mui/icons-material/CalendarMonthTwoTone')),
    CalendarToday: React.lazy(() => import('@mui/icons-material/CalendarToday')),
    CalendarTodayOutlined: React.lazy(() => import('@mui/icons-material/CalendarTodayOutlined')),
    CalendarTodayRounded: React.lazy(() => import('@mui/icons-material/CalendarTodayRounded')),
    CalendarTodaySharp: React.lazy(() => import('@mui/icons-material/CalendarTodaySharp')),
    CalendarTodayTwoTone: React.lazy(() => import('@mui/icons-material/CalendarTodayTwoTone')),
    CalendarViewDay: React.lazy(() => import('@mui/icons-material/CalendarViewDay')),
    CalendarViewDayOutlined: React.lazy(() => import('@mui/icons-material/CalendarViewDayOutlined')),
    CalendarViewDayRounded: React.lazy(() => import('@mui/icons-material/CalendarViewDayRounded')),
    CalendarViewDaySharp: React.lazy(() => import('@mui/icons-material/CalendarViewDaySharp')),
    CalendarViewDayTwoTone: React.lazy(() => import('@mui/icons-material/CalendarViewDayTwoTone')),
    CalendarViewMonth: React.lazy(() => import('@mui/icons-material/CalendarViewMonth')),
    CalendarViewMonthOutlined: React.lazy(() => import('@mui/icons-material/CalendarViewMonthOutlined')),
    CalendarViewMonthRounded: React.lazy(() => import('@mui/icons-material/CalendarViewMonthRounded')),
    CalendarViewMonthSharp: React.lazy(() => import('@mui/icons-material/CalendarViewMonthSharp')),
    CalendarViewMonthTwoTone: React.lazy(() => import('@mui/icons-material/CalendarViewMonthTwoTone')),
    CalendarViewWeek: React.lazy(() => import('@mui/icons-material/CalendarViewWeek')),
    CalendarViewWeekOutlined: React.lazy(() => import('@mui/icons-material/CalendarViewWeekOutlined')),
    CalendarViewWeekRounded: React.lazy(() => import('@mui/icons-material/CalendarViewWeekRounded')),
    CalendarViewWeekSharp: React.lazy(() => import('@mui/icons-material/CalendarViewWeekSharp')),
    CalendarViewWeekTwoTone: React.lazy(() => import('@mui/icons-material/CalendarViewWeekTwoTone')),
    Call: React.lazy(() => import('@mui/icons-material/Call')),
    CallEnd: React.lazy(() => import('@mui/icons-material/CallEnd')),
    CallEndOutlined: React.lazy(() => import('@mui/icons-material/CallEndOutlined')),
    CallEndRounded: React.lazy(() => import('@mui/icons-material/CallEndRounded')),
    CallEndSharp: React.lazy(() => import('@mui/icons-material/CallEndSharp')),
    CallEndTwoTone: React.lazy(() => import('@mui/icons-material/CallEndTwoTone')),
    CallMade: React.lazy(() => import('@mui/icons-material/CallMade')),
    CallMadeOutlined: React.lazy(() => import('@mui/icons-material/CallMadeOutlined')),
    CallMadeRounded: React.lazy(() => import('@mui/icons-material/CallMadeRounded')),
    CallMadeSharp: React.lazy(() => import('@mui/icons-material/CallMadeSharp')),
    CallMadeTwoTone: React.lazy(() => import('@mui/icons-material/CallMadeTwoTone')),
    CallMerge: React.lazy(() => import('@mui/icons-material/CallMerge')),
    CallMergeOutlined: React.lazy(() => import('@mui/icons-material/CallMergeOutlined')),
    CallMergeRounded: React.lazy(() => import('@mui/icons-material/CallMergeRounded')),
    CallMergeSharp: React.lazy(() => import('@mui/icons-material/CallMergeSharp')),
    CallMergeTwoTone: React.lazy(() => import('@mui/icons-material/CallMergeTwoTone')),
    CallMissed: React.lazy(() => import('@mui/icons-material/CallMissed')),
    CallMissedOutgoing: React.lazy(() => import('@mui/icons-material/CallMissedOutgoing')),
    CallMissedOutgoingOutlined: React.lazy(() => import('@mui/icons-material/CallMissedOutgoingOutlined')),
    CallMissedOutgoingRounded: React.lazy(() => import('@mui/icons-material/CallMissedOutgoingRounded')),
    CallMissedOutgoingSharp: React.lazy(() => import('@mui/icons-material/CallMissedOutgoingSharp')),
    CallMissedOutgoingTwoTone: React.lazy(() => import('@mui/icons-material/CallMissedOutgoingTwoTone')),
    CallMissedOutlined: React.lazy(() => import('@mui/icons-material/CallMissedOutlined')),
    CallMissedRounded: React.lazy(() => import('@mui/icons-material/CallMissedRounded')),
    CallMissedSharp: React.lazy(() => import('@mui/icons-material/CallMissedSharp')),
    CallMissedTwoTone: React.lazy(() => import('@mui/icons-material/CallMissedTwoTone')),
    CallOutlined: React.lazy(() => import('@mui/icons-material/CallOutlined')),
    CallReceived: React.lazy(() => import('@mui/icons-material/CallReceived')),
    CallReceivedOutlined: React.lazy(() => import('@mui/icons-material/CallReceivedOutlined')),
    CallReceivedRounded: React.lazy(() => import('@mui/icons-material/CallReceivedRounded')),
    CallReceivedSharp: React.lazy(() => import('@mui/icons-material/CallReceivedSharp')),
    CallReceivedTwoTone: React.lazy(() => import('@mui/icons-material/CallReceivedTwoTone')),
    CallRounded: React.lazy(() => import('@mui/icons-material/CallRounded')),
    CallSharp: React.lazy(() => import('@mui/icons-material/CallSharp')),
    CallSplit: React.lazy(() => import('@mui/icons-material/CallSplit')),
    CallSplitOutlined: React.lazy(() => import('@mui/icons-material/CallSplitOutlined')),
    CallSplitRounded: React.lazy(() => import('@mui/icons-material/CallSplitRounded')),
    CallSplitSharp: React.lazy(() => import('@mui/icons-material/CallSplitSharp')),
    CallSplitTwoTone: React.lazy(() => import('@mui/icons-material/CallSplitTwoTone')),
    CallToAction: React.lazy(() => import('@mui/icons-material/CallToAction')),
    CallToActionOutlined: React.lazy(() => import('@mui/icons-material/CallToActionOutlined')),
    CallToActionRounded: React.lazy(() => import('@mui/icons-material/CallToActionRounded')),
    CallToActionSharp: React.lazy(() => import('@mui/icons-material/CallToActionSharp')),
    CallToActionTwoTone: React.lazy(() => import('@mui/icons-material/CallToActionTwoTone')),
    CallTwoTone: React.lazy(() => import('@mui/icons-material/CallTwoTone')),
    CameraAlt: React.lazy(() => import('@mui/icons-material/CameraAlt')),
    CameraAltOutlined: React.lazy(() => import('@mui/icons-material/CameraAltOutlined')),
    CameraAltRounded: React.lazy(() => import('@mui/icons-material/CameraAltRounded')),
    CameraAltSharp: React.lazy(() => import('@mui/icons-material/CameraAltSharp')),
    CameraAltTwoTone: React.lazy(() => import('@mui/icons-material/CameraAltTwoTone')),
    Camera: React.lazy(() => import('@mui/icons-material/Camera')),
    CameraEnhance: React.lazy(() => import('@mui/icons-material/CameraEnhance')),
    CameraEnhanceOutlined: React.lazy(() => import('@mui/icons-material/CameraEnhanceOutlined')),
    CameraEnhanceRounded: React.lazy(() => import('@mui/icons-material/CameraEnhanceRounded')),
    CameraEnhanceSharp: React.lazy(() => import('@mui/icons-material/CameraEnhanceSharp')),
    CameraEnhanceTwoTone: React.lazy(() => import('@mui/icons-material/CameraEnhanceTwoTone')),
    CameraFront: React.lazy(() => import('@mui/icons-material/CameraFront')),
    CameraFrontOutlined: React.lazy(() => import('@mui/icons-material/CameraFrontOutlined')),
    CameraFrontRounded: React.lazy(() => import('@mui/icons-material/CameraFrontRounded')),
    CameraFrontSharp: React.lazy(() => import('@mui/icons-material/CameraFrontSharp')),
    CameraFrontTwoTone: React.lazy(() => import('@mui/icons-material/CameraFrontTwoTone')),
    CameraIndoor: React.lazy(() => import('@mui/icons-material/CameraIndoor')),
    CameraIndoorOutlined: React.lazy(() => import('@mui/icons-material/CameraIndoorOutlined')),
    CameraIndoorRounded: React.lazy(() => import('@mui/icons-material/CameraIndoorRounded')),
    CameraIndoorSharp: React.lazy(() => import('@mui/icons-material/CameraIndoorSharp')),
    CameraIndoorTwoTone: React.lazy(() => import('@mui/icons-material/CameraIndoorTwoTone')),
    CameraOutdoor: React.lazy(() => import('@mui/icons-material/CameraOutdoor')),
    CameraOutdoorOutlined: React.lazy(() => import('@mui/icons-material/CameraOutdoorOutlined')),
    CameraOutdoorRounded: React.lazy(() => import('@mui/icons-material/CameraOutdoorRounded')),
    CameraOutdoorSharp: React.lazy(() => import('@mui/icons-material/CameraOutdoorSharp')),
    CameraOutdoorTwoTone: React.lazy(() => import('@mui/icons-material/CameraOutdoorTwoTone')),
    CameraOutlined: React.lazy(() => import('@mui/icons-material/CameraOutlined')),
    CameraRear: React.lazy(() => import('@mui/icons-material/CameraRear')),
    CameraRearOutlined: React.lazy(() => import('@mui/icons-material/CameraRearOutlined')),
    CameraRearRounded: React.lazy(() => import('@mui/icons-material/CameraRearRounded')),
    CameraRearSharp: React.lazy(() => import('@mui/icons-material/CameraRearSharp')),
    CameraRearTwoTone: React.lazy(() => import('@mui/icons-material/CameraRearTwoTone')),
    CameraRoll: React.lazy(() => import('@mui/icons-material/CameraRoll')),
    CameraRollOutlined: React.lazy(() => import('@mui/icons-material/CameraRollOutlined')),
    CameraRollRounded: React.lazy(() => import('@mui/icons-material/CameraRollRounded')),
    CameraRollSharp: React.lazy(() => import('@mui/icons-material/CameraRollSharp')),
    CameraRollTwoTone: React.lazy(() => import('@mui/icons-material/CameraRollTwoTone')),
    CameraRounded: React.lazy(() => import('@mui/icons-material/CameraRounded')),
    CameraSharp: React.lazy(() => import('@mui/icons-material/CameraSharp')),
    Cameraswitch: React.lazy(() => import('@mui/icons-material/Cameraswitch')),
    CameraswitchOutlined: React.lazy(() => import('@mui/icons-material/CameraswitchOutlined')),
    CameraswitchRounded: React.lazy(() => import('@mui/icons-material/CameraswitchRounded')),
    CameraswitchSharp: React.lazy(() => import('@mui/icons-material/CameraswitchSharp')),
    CameraswitchTwoTone: React.lazy(() => import('@mui/icons-material/CameraswitchTwoTone')),
    CameraTwoTone: React.lazy(() => import('@mui/icons-material/CameraTwoTone')),
    Campaign: React.lazy(() => import('@mui/icons-material/Campaign')),
    CampaignOutlined: React.lazy(() => import('@mui/icons-material/CampaignOutlined')),
    CampaignRounded: React.lazy(() => import('@mui/icons-material/CampaignRounded')),
    CampaignSharp: React.lazy(() => import('@mui/icons-material/CampaignSharp')),
    CampaignTwoTone: React.lazy(() => import('@mui/icons-material/CampaignTwoTone')),
    Cancel: React.lazy(() => import('@mui/icons-material/Cancel')),
    CancelOutlined: React.lazy(() => import('@mui/icons-material/CancelOutlined')),
    CancelPresentation: React.lazy(() => import('@mui/icons-material/CancelPresentation')),
    CancelPresentationOutlined: React.lazy(() => import('@mui/icons-material/CancelPresentationOutlined')),
    CancelPresentationRounded: React.lazy(() => import('@mui/icons-material/CancelPresentationRounded')),
    CancelPresentationSharp: React.lazy(() => import('@mui/icons-material/CancelPresentationSharp')),
    CancelPresentationTwoTone: React.lazy(() => import('@mui/icons-material/CancelPresentationTwoTone')),
    CancelRounded: React.lazy(() => import('@mui/icons-material/CancelRounded')),
    CancelScheduleSend: React.lazy(() => import('@mui/icons-material/CancelScheduleSend')),
    CancelScheduleSendOutlined: React.lazy(() => import('@mui/icons-material/CancelScheduleSendOutlined')),
    CancelScheduleSendRounded: React.lazy(() => import('@mui/icons-material/CancelScheduleSendRounded')),
    CancelScheduleSendSharp: React.lazy(() => import('@mui/icons-material/CancelScheduleSendSharp')),
    CancelScheduleSendTwoTone: React.lazy(() => import('@mui/icons-material/CancelScheduleSendTwoTone')),
    CancelSharp: React.lazy(() => import('@mui/icons-material/CancelSharp')),
    CancelTwoTone: React.lazy(() => import('@mui/icons-material/CancelTwoTone')),
    CandlestickChart: React.lazy(() => import('@mui/icons-material/CandlestickChart')),
    CandlestickChartOutlined: React.lazy(() => import('@mui/icons-material/CandlestickChartOutlined')),
    CandlestickChartRounded: React.lazy(() => import('@mui/icons-material/CandlestickChartRounded')),
    CandlestickChartSharp: React.lazy(() => import('@mui/icons-material/CandlestickChartSharp')),
    CandlestickChartTwoTone: React.lazy(() => import('@mui/icons-material/CandlestickChartTwoTone')),
    CarCrash: React.lazy(() => import('@mui/icons-material/CarCrash')),
    CarCrashOutlined: React.lazy(() => import('@mui/icons-material/CarCrashOutlined')),
    CarCrashRounded: React.lazy(() => import('@mui/icons-material/CarCrashRounded')),
    CarCrashSharp: React.lazy(() => import('@mui/icons-material/CarCrashSharp')),
    CarCrashTwoTone: React.lazy(() => import('@mui/icons-material/CarCrashTwoTone')),
    CardGiftcard: React.lazy(() => import('@mui/icons-material/CardGiftcard')),
    CardGiftcardOutlined: React.lazy(() => import('@mui/icons-material/CardGiftcardOutlined')),
    CardGiftcardRounded: React.lazy(() => import('@mui/icons-material/CardGiftcardRounded')),
    CardGiftcardSharp: React.lazy(() => import('@mui/icons-material/CardGiftcardSharp')),
    CardGiftcardTwoTone: React.lazy(() => import('@mui/icons-material/CardGiftcardTwoTone')),
    CardMembership: React.lazy(() => import('@mui/icons-material/CardMembership')),
    CardMembershipOutlined: React.lazy(() => import('@mui/icons-material/CardMembershipOutlined')),
    CardMembershipRounded: React.lazy(() => import('@mui/icons-material/CardMembershipRounded')),
    CardMembershipSharp: React.lazy(() => import('@mui/icons-material/CardMembershipSharp')),
    CardMembershipTwoTone: React.lazy(() => import('@mui/icons-material/CardMembershipTwoTone')),
    CardTravel: React.lazy(() => import('@mui/icons-material/CardTravel')),
    CardTravelOutlined: React.lazy(() => import('@mui/icons-material/CardTravelOutlined')),
    CardTravelRounded: React.lazy(() => import('@mui/icons-material/CardTravelRounded')),
    CardTravelSharp: React.lazy(() => import('@mui/icons-material/CardTravelSharp')),
    CardTravelTwoTone: React.lazy(() => import('@mui/icons-material/CardTravelTwoTone')),
    Carpenter: React.lazy(() => import('@mui/icons-material/Carpenter')),
    CarpenterOutlined: React.lazy(() => import('@mui/icons-material/CarpenterOutlined')),
    CarpenterRounded: React.lazy(() => import('@mui/icons-material/CarpenterRounded')),
    CarpenterSharp: React.lazy(() => import('@mui/icons-material/CarpenterSharp')),
    CarpenterTwoTone: React.lazy(() => import('@mui/icons-material/CarpenterTwoTone')),
    CarRental: React.lazy(() => import('@mui/icons-material/CarRental')),
    CarRentalOutlined: React.lazy(() => import('@mui/icons-material/CarRentalOutlined')),
    CarRentalRounded: React.lazy(() => import('@mui/icons-material/CarRentalRounded')),
    CarRentalSharp: React.lazy(() => import('@mui/icons-material/CarRentalSharp')),
    CarRentalTwoTone: React.lazy(() => import('@mui/icons-material/CarRentalTwoTone')),
    CarRepair: React.lazy(() => import('@mui/icons-material/CarRepair')),
    CarRepairOutlined: React.lazy(() => import('@mui/icons-material/CarRepairOutlined')),
    CarRepairRounded: React.lazy(() => import('@mui/icons-material/CarRepairRounded')),
    CarRepairSharp: React.lazy(() => import('@mui/icons-material/CarRepairSharp')),
    CarRepairTwoTone: React.lazy(() => import('@mui/icons-material/CarRepairTwoTone')),
    Cases: React.lazy(() => import('@mui/icons-material/Cases')),
    CasesOutlined: React.lazy(() => import('@mui/icons-material/CasesOutlined')),
    CasesRounded: React.lazy(() => import('@mui/icons-material/CasesRounded')),
    CasesSharp: React.lazy(() => import('@mui/icons-material/CasesSharp')),
    CasesTwoTone: React.lazy(() => import('@mui/icons-material/CasesTwoTone')),
    Casino: React.lazy(() => import('@mui/icons-material/Casino')),
    CasinoOutlined: React.lazy(() => import('@mui/icons-material/CasinoOutlined')),
    CasinoRounded: React.lazy(() => import('@mui/icons-material/CasinoRounded')),
    CasinoSharp: React.lazy(() => import('@mui/icons-material/CasinoSharp')),
    CasinoTwoTone: React.lazy(() => import('@mui/icons-material/CasinoTwoTone')),
    CastConnected: React.lazy(() => import('@mui/icons-material/CastConnected')),
    CastConnectedOutlined: React.lazy(() => import('@mui/icons-material/CastConnectedOutlined')),
    CastConnectedRounded: React.lazy(() => import('@mui/icons-material/CastConnectedRounded')),
    CastConnectedSharp: React.lazy(() => import('@mui/icons-material/CastConnectedSharp')),
    CastConnectedTwoTone: React.lazy(() => import('@mui/icons-material/CastConnectedTwoTone')),
    Cast: React.lazy(() => import('@mui/icons-material/Cast')),
    CastForEducation: React.lazy(() => import('@mui/icons-material/CastForEducation')),
    CastForEducationOutlined: React.lazy(() => import('@mui/icons-material/CastForEducationOutlined')),
    CastForEducationRounded: React.lazy(() => import('@mui/icons-material/CastForEducationRounded')),
    CastForEducationSharp: React.lazy(() => import('@mui/icons-material/CastForEducationSharp')),
    CastForEducationTwoTone: React.lazy(() => import('@mui/icons-material/CastForEducationTwoTone')),
    Castle: React.lazy(() => import('@mui/icons-material/Castle')),
    CastleOutlined: React.lazy(() => import('@mui/icons-material/CastleOutlined')),
    CastleRounded: React.lazy(() => import('@mui/icons-material/CastleRounded')),
    CastleSharp: React.lazy(() => import('@mui/icons-material/CastleSharp')),
    CastleTwoTone: React.lazy(() => import('@mui/icons-material/CastleTwoTone')),
    CastOutlined: React.lazy(() => import('@mui/icons-material/CastOutlined')),
    CastRounded: React.lazy(() => import('@mui/icons-material/CastRounded')),
    CastSharp: React.lazy(() => import('@mui/icons-material/CastSharp')),
    CastTwoTone: React.lazy(() => import('@mui/icons-material/CastTwoTone')),
    CatchingPokemon: React.lazy(() => import('@mui/icons-material/CatchingPokemon')),
    CatchingPokemonOutlined: React.lazy(() => import('@mui/icons-material/CatchingPokemonOutlined')),
    CatchingPokemonRounded: React.lazy(() => import('@mui/icons-material/CatchingPokemonRounded')),
    CatchingPokemonSharp: React.lazy(() => import('@mui/icons-material/CatchingPokemonSharp')),
    CatchingPokemonTwoTone: React.lazy(() => import('@mui/icons-material/CatchingPokemonTwoTone')),
    Category: React.lazy(() => import('@mui/icons-material/Category')),
    CategoryOutlined: React.lazy(() => import('@mui/icons-material/CategoryOutlined')),
    CategoryRounded: React.lazy(() => import('@mui/icons-material/CategoryRounded')),
    CategorySharp: React.lazy(() => import('@mui/icons-material/CategorySharp')),
    CategoryTwoTone: React.lazy(() => import('@mui/icons-material/CategoryTwoTone')),
    Celebration: React.lazy(() => import('@mui/icons-material/Celebration')),
    CelebrationOutlined: React.lazy(() => import('@mui/icons-material/CelebrationOutlined')),
    CelebrationRounded: React.lazy(() => import('@mui/icons-material/CelebrationRounded')),
    CelebrationSharp: React.lazy(() => import('@mui/icons-material/CelebrationSharp')),
    CelebrationTwoTone: React.lazy(() => import('@mui/icons-material/CelebrationTwoTone')),
    CellTower: React.lazy(() => import('@mui/icons-material/CellTower')),
    CellTowerOutlined: React.lazy(() => import('@mui/icons-material/CellTowerOutlined')),
    CellTowerRounded: React.lazy(() => import('@mui/icons-material/CellTowerRounded')),
    CellTowerSharp: React.lazy(() => import('@mui/icons-material/CellTowerSharp')),
    CellTowerTwoTone: React.lazy(() => import('@mui/icons-material/CellTowerTwoTone')),
    CellWifi: React.lazy(() => import('@mui/icons-material/CellWifi')),
    CellWifiOutlined: React.lazy(() => import('@mui/icons-material/CellWifiOutlined')),
    CellWifiRounded: React.lazy(() => import('@mui/icons-material/CellWifiRounded')),
    CellWifiSharp: React.lazy(() => import('@mui/icons-material/CellWifiSharp')),
    CellWifiTwoTone: React.lazy(() => import('@mui/icons-material/CellWifiTwoTone')),
    CenterFocusStrong: React.lazy(() => import('@mui/icons-material/CenterFocusStrong')),
    CenterFocusStrongOutlined: React.lazy(() => import('@mui/icons-material/CenterFocusStrongOutlined')),
    CenterFocusStrongRounded: React.lazy(() => import('@mui/icons-material/CenterFocusStrongRounded')),
    CenterFocusStrongSharp: React.lazy(() => import('@mui/icons-material/CenterFocusStrongSharp')),
    CenterFocusStrongTwoTone: React.lazy(() => import('@mui/icons-material/CenterFocusStrongTwoTone')),
    CenterFocusWeak: React.lazy(() => import('@mui/icons-material/CenterFocusWeak')),
    CenterFocusWeakOutlined: React.lazy(() => import('@mui/icons-material/CenterFocusWeakOutlined')),
    CenterFocusWeakRounded: React.lazy(() => import('@mui/icons-material/CenterFocusWeakRounded')),
    CenterFocusWeakSharp: React.lazy(() => import('@mui/icons-material/CenterFocusWeakSharp')),
    CenterFocusWeakTwoTone: React.lazy(() => import('@mui/icons-material/CenterFocusWeakTwoTone')),
    ChairAlt: React.lazy(() => import('@mui/icons-material/ChairAlt')),
    ChairAltOutlined: React.lazy(() => import('@mui/icons-material/ChairAltOutlined')),
    ChairAltRounded: React.lazy(() => import('@mui/icons-material/ChairAltRounded')),
    ChairAltSharp: React.lazy(() => import('@mui/icons-material/ChairAltSharp')),
    ChairAltTwoTone: React.lazy(() => import('@mui/icons-material/ChairAltTwoTone')),
    Chair: React.lazy(() => import('@mui/icons-material/Chair')),
    ChairOutlined: React.lazy(() => import('@mui/icons-material/ChairOutlined')),
    ChairRounded: React.lazy(() => import('@mui/icons-material/ChairRounded')),
    ChairSharp: React.lazy(() => import('@mui/icons-material/ChairSharp')),
    ChairTwoTone: React.lazy(() => import('@mui/icons-material/ChairTwoTone')),
    Chalet: React.lazy(() => import('@mui/icons-material/Chalet')),
    ChaletOutlined: React.lazy(() => import('@mui/icons-material/ChaletOutlined')),
    ChaletRounded: React.lazy(() => import('@mui/icons-material/ChaletRounded')),
    ChaletSharp: React.lazy(() => import('@mui/icons-material/ChaletSharp')),
    ChaletTwoTone: React.lazy(() => import('@mui/icons-material/ChaletTwoTone')),
    ChangeCircle: React.lazy(() => import('@mui/icons-material/ChangeCircle')),
    ChangeCircleOutlined: React.lazy(() => import('@mui/icons-material/ChangeCircleOutlined')),
    ChangeCircleRounded: React.lazy(() => import('@mui/icons-material/ChangeCircleRounded')),
    ChangeCircleSharp: React.lazy(() => import('@mui/icons-material/ChangeCircleSharp')),
    ChangeCircleTwoTone: React.lazy(() => import('@mui/icons-material/ChangeCircleTwoTone')),
    ChangeHistory: React.lazy(() => import('@mui/icons-material/ChangeHistory')),
    ChangeHistoryOutlined: React.lazy(() => import('@mui/icons-material/ChangeHistoryOutlined')),
    ChangeHistoryRounded: React.lazy(() => import('@mui/icons-material/ChangeHistoryRounded')),
    ChangeHistorySharp: React.lazy(() => import('@mui/icons-material/ChangeHistorySharp')),
    ChangeHistoryTwoTone: React.lazy(() => import('@mui/icons-material/ChangeHistoryTwoTone')),
    ChargingStation: React.lazy(() => import('@mui/icons-material/ChargingStation')),
    ChargingStationOutlined: React.lazy(() => import('@mui/icons-material/ChargingStationOutlined')),
    ChargingStationRounded: React.lazy(() => import('@mui/icons-material/ChargingStationRounded')),
    ChargingStationSharp: React.lazy(() => import('@mui/icons-material/ChargingStationSharp')),
    ChargingStationTwoTone: React.lazy(() => import('@mui/icons-material/ChargingStationTwoTone')),
    ChatBubble: React.lazy(() => import('@mui/icons-material/ChatBubble')),
    ChatBubbleOutlined: React.lazy(() => import('@mui/icons-material/ChatBubbleOutlined')),
    ChatBubbleOutline: React.lazy(() => import('@mui/icons-material/ChatBubbleOutline')),
    ChatBubbleOutlineOutlined: React.lazy(() => import('@mui/icons-material/ChatBubbleOutlineOutlined')),
    ChatBubbleOutlineRounded: React.lazy(() => import('@mui/icons-material/ChatBubbleOutlineRounded')),
    ChatBubbleOutlineSharp: React.lazy(() => import('@mui/icons-material/ChatBubbleOutlineSharp')),
    ChatBubbleOutlineTwoTone: React.lazy(() => import('@mui/icons-material/ChatBubbleOutlineTwoTone')),
    ChatBubbleRounded: React.lazy(() => import('@mui/icons-material/ChatBubbleRounded')),
    ChatBubbleSharp: React.lazy(() => import('@mui/icons-material/ChatBubbleSharp')),
    ChatBubbleTwoTone: React.lazy(() => import('@mui/icons-material/ChatBubbleTwoTone')),
    Chat: React.lazy(() => import('@mui/icons-material/Chat')),
    ChatOutlined: React.lazy(() => import('@mui/icons-material/ChatOutlined')),
    ChatRounded: React.lazy(() => import('@mui/icons-material/ChatRounded')),
    ChatSharp: React.lazy(() => import('@mui/icons-material/ChatSharp')),
    ChatTwoTone: React.lazy(() => import('@mui/icons-material/ChatTwoTone')),
    CheckBox: React.lazy(() => import('@mui/icons-material/CheckBox')),
    CheckBoxOutlineBlank: React.lazy(() => import('@mui/icons-material/CheckBoxOutlineBlank')),
    CheckBoxOutlineBlankOutlined: React.lazy(() => import('@mui/icons-material/CheckBoxOutlineBlankOutlined')),
    CheckBoxOutlineBlankRounded: React.lazy(() => import('@mui/icons-material/CheckBoxOutlineBlankRounded')),
    CheckBoxOutlineBlankSharp: React.lazy(() => import('@mui/icons-material/CheckBoxOutlineBlankSharp')),
    CheckBoxOutlineBlankTwoTone: React.lazy(() => import('@mui/icons-material/CheckBoxOutlineBlankTwoTone')),
    CheckBoxOutlined: React.lazy(() => import('@mui/icons-material/CheckBoxOutlined')),
    CheckBoxRounded: React.lazy(() => import('@mui/icons-material/CheckBoxRounded')),
    CheckBoxSharp: React.lazy(() => import('@mui/icons-material/CheckBoxSharp')),
    CheckBoxTwoTone: React.lazy(() => import('@mui/icons-material/CheckBoxTwoTone')),
    CheckCircle: React.lazy(() => import('@mui/icons-material/CheckCircle')),
    CheckCircleOutlined: React.lazy(() => import('@mui/icons-material/CheckCircleOutlined')),
    CheckCircleOutline: React.lazy(() => import('@mui/icons-material/CheckCircleOutline')),
    CheckCircleOutlineOutlined: React.lazy(() => import('@mui/icons-material/CheckCircleOutlineOutlined')),
    CheckCircleOutlineRounded: React.lazy(() => import('@mui/icons-material/CheckCircleOutlineRounded')),
    CheckCircleOutlineSharp: React.lazy(() => import('@mui/icons-material/CheckCircleOutlineSharp')),
    CheckCircleOutlineTwoTone: React.lazy(() => import('@mui/icons-material/CheckCircleOutlineTwoTone')),
    CheckCircleRounded: React.lazy(() => import('@mui/icons-material/CheckCircleRounded')),
    CheckCircleSharp: React.lazy(() => import('@mui/icons-material/CheckCircleSharp')),
    CheckCircleTwoTone: React.lazy(() => import('@mui/icons-material/CheckCircleTwoTone')),
    Check: React.lazy(() => import('@mui/icons-material/Check')),
    CheckOutlined: React.lazy(() => import('@mui/icons-material/CheckOutlined')),
    Checkroom: React.lazy(() => import('@mui/icons-material/Checkroom')),
    CheckroomOutlined: React.lazy(() => import('@mui/icons-material/CheckroomOutlined')),
    CheckroomRounded: React.lazy(() => import('@mui/icons-material/CheckroomRounded')),
    CheckroomSharp: React.lazy(() => import('@mui/icons-material/CheckroomSharp')),
    CheckroomTwoTone: React.lazy(() => import('@mui/icons-material/CheckroomTwoTone')),
    CheckRounded: React.lazy(() => import('@mui/icons-material/CheckRounded')),
    CheckSharp: React.lazy(() => import('@mui/icons-material/CheckSharp')),
    CheckTwoTone: React.lazy(() => import('@mui/icons-material/CheckTwoTone')),
    ChevronLeft: React.lazy(() => import('@mui/icons-material/ChevronLeft')),
    ChevronLeftOutlined: React.lazy(() => import('@mui/icons-material/ChevronLeftOutlined')),
    ChevronLeftRounded: React.lazy(() => import('@mui/icons-material/ChevronLeftRounded')),
    ChevronLeftSharp: React.lazy(() => import('@mui/icons-material/ChevronLeftSharp')),
    ChevronLeftTwoTone: React.lazy(() => import('@mui/icons-material/ChevronLeftTwoTone')),
    ChevronRight: React.lazy(() => import('@mui/icons-material/ChevronRight')),
    ChevronRightOutlined: React.lazy(() => import('@mui/icons-material/ChevronRightOutlined')),
    ChevronRightRounded: React.lazy(() => import('@mui/icons-material/ChevronRightRounded')),
    ChevronRightSharp: React.lazy(() => import('@mui/icons-material/ChevronRightSharp')),
    ChevronRightTwoTone: React.lazy(() => import('@mui/icons-material/ChevronRightTwoTone')),
    ChildCare: React.lazy(() => import('@mui/icons-material/ChildCare')),
    ChildCareOutlined: React.lazy(() => import('@mui/icons-material/ChildCareOutlined')),
    ChildCareRounded: React.lazy(() => import('@mui/icons-material/ChildCareRounded')),
    ChildCareSharp: React.lazy(() => import('@mui/icons-material/ChildCareSharp')),
    ChildCareTwoTone: React.lazy(() => import('@mui/icons-material/ChildCareTwoTone')),
    ChildFriendly: React.lazy(() => import('@mui/icons-material/ChildFriendly')),
    ChildFriendlyOutlined: React.lazy(() => import('@mui/icons-material/ChildFriendlyOutlined')),
    ChildFriendlyRounded: React.lazy(() => import('@mui/icons-material/ChildFriendlyRounded')),
    ChildFriendlySharp: React.lazy(() => import('@mui/icons-material/ChildFriendlySharp')),
    ChildFriendlyTwoTone: React.lazy(() => import('@mui/icons-material/ChildFriendlyTwoTone')),
    ChromeReaderMode: React.lazy(() => import('@mui/icons-material/ChromeReaderMode')),
    ChromeReaderModeOutlined: React.lazy(() => import('@mui/icons-material/ChromeReaderModeOutlined')),
    ChromeReaderModeRounded: React.lazy(() => import('@mui/icons-material/ChromeReaderModeRounded')),
    ChromeReaderModeSharp: React.lazy(() => import('@mui/icons-material/ChromeReaderModeSharp')),
    ChromeReaderModeTwoTone: React.lazy(() => import('@mui/icons-material/ChromeReaderModeTwoTone')),
    Church: React.lazy(() => import('@mui/icons-material/Church')),
    ChurchOutlined: React.lazy(() => import('@mui/icons-material/ChurchOutlined')),
    ChurchRounded: React.lazy(() => import('@mui/icons-material/ChurchRounded')),
    ChurchSharp: React.lazy(() => import('@mui/icons-material/ChurchSharp')),
    ChurchTwoTone: React.lazy(() => import('@mui/icons-material/ChurchTwoTone')),
    Circle: React.lazy(() => import('@mui/icons-material/Circle')),
    CircleNotifications: React.lazy(() => import('@mui/icons-material/CircleNotifications')),
    CircleNotificationsOutlined: React.lazy(() => import('@mui/icons-material/CircleNotificationsOutlined')),
    CircleNotificationsRounded: React.lazy(() => import('@mui/icons-material/CircleNotificationsRounded')),
    CircleNotificationsSharp: React.lazy(() => import('@mui/icons-material/CircleNotificationsSharp')),
    CircleNotificationsTwoTone: React.lazy(() => import('@mui/icons-material/CircleNotificationsTwoTone')),
    CircleOutlined: React.lazy(() => import('@mui/icons-material/CircleOutlined')),
    CircleRounded: React.lazy(() => import('@mui/icons-material/CircleRounded')),
    CircleSharp: React.lazy(() => import('@mui/icons-material/CircleSharp')),
    CircleTwoTone: React.lazy(() => import('@mui/icons-material/CircleTwoTone')),
    Class: React.lazy(() => import('@mui/icons-material/Class')),
    ClassOutlined: React.lazy(() => import('@mui/icons-material/ClassOutlined')),
    ClassRounded: React.lazy(() => import('@mui/icons-material/ClassRounded')),
    ClassSharp: React.lazy(() => import('@mui/icons-material/ClassSharp')),
    ClassTwoTone: React.lazy(() => import('@mui/icons-material/ClassTwoTone')),
    CleanHands: React.lazy(() => import('@mui/icons-material/CleanHands')),
    CleanHandsOutlined: React.lazy(() => import('@mui/icons-material/CleanHandsOutlined')),
    CleanHandsRounded: React.lazy(() => import('@mui/icons-material/CleanHandsRounded')),
    CleanHandsSharp: React.lazy(() => import('@mui/icons-material/CleanHandsSharp')),
    CleanHandsTwoTone: React.lazy(() => import('@mui/icons-material/CleanHandsTwoTone')),
    CleaningServices: React.lazy(() => import('@mui/icons-material/CleaningServices')),
    CleaningServicesOutlined: React.lazy(() => import('@mui/icons-material/CleaningServicesOutlined')),
    CleaningServicesRounded: React.lazy(() => import('@mui/icons-material/CleaningServicesRounded')),
    CleaningServicesSharp: React.lazy(() => import('@mui/icons-material/CleaningServicesSharp')),
    CleaningServicesTwoTone: React.lazy(() => import('@mui/icons-material/CleaningServicesTwoTone')),
    ClearAll: React.lazy(() => import('@mui/icons-material/ClearAll')),
    ClearAllOutlined: React.lazy(() => import('@mui/icons-material/ClearAllOutlined')),
    ClearAllRounded: React.lazy(() => import('@mui/icons-material/ClearAllRounded')),
    ClearAllSharp: React.lazy(() => import('@mui/icons-material/ClearAllSharp')),
    ClearAllTwoTone: React.lazy(() => import('@mui/icons-material/ClearAllTwoTone')),
    Clear: React.lazy(() => import('@mui/icons-material/Clear')),
    ClearOutlined: React.lazy(() => import('@mui/icons-material/ClearOutlined')),
    ClearRounded: React.lazy(() => import('@mui/icons-material/ClearRounded')),
    ClearSharp: React.lazy(() => import('@mui/icons-material/ClearSharp')),
    ClearTwoTone: React.lazy(() => import('@mui/icons-material/ClearTwoTone')),
    ClosedCaptionDisabled: React.lazy(() => import('@mui/icons-material/ClosedCaptionDisabled')),
    ClosedCaptionDisabledOutlined: React.lazy(() => import('@mui/icons-material/ClosedCaptionDisabledOutlined')),
    ClosedCaptionDisabledRounded: React.lazy(() => import('@mui/icons-material/ClosedCaptionDisabledRounded')),
    ClosedCaptionDisabledSharp: React.lazy(() => import('@mui/icons-material/ClosedCaptionDisabledSharp')),
    ClosedCaptionDisabledTwoTone: React.lazy(() => import('@mui/icons-material/ClosedCaptionDisabledTwoTone')),
    ClosedCaption: React.lazy(() => import('@mui/icons-material/ClosedCaption')),
    ClosedCaptionOff: React.lazy(() => import('@mui/icons-material/ClosedCaptionOff')),
    ClosedCaptionOffOutlined: React.lazy(() => import('@mui/icons-material/ClosedCaptionOffOutlined')),
    ClosedCaptionOffRounded: React.lazy(() => import('@mui/icons-material/ClosedCaptionOffRounded')),
    ClosedCaptionOffSharp: React.lazy(() => import('@mui/icons-material/ClosedCaptionOffSharp')),
    ClosedCaptionOffTwoTone: React.lazy(() => import('@mui/icons-material/ClosedCaptionOffTwoTone')),
    ClosedCaptionOutlined: React.lazy(() => import('@mui/icons-material/ClosedCaptionOutlined')),
    ClosedCaptionRounded: React.lazy(() => import('@mui/icons-material/ClosedCaptionRounded')),
    ClosedCaptionSharp: React.lazy(() => import('@mui/icons-material/ClosedCaptionSharp')),
    ClosedCaptionTwoTone: React.lazy(() => import('@mui/icons-material/ClosedCaptionTwoTone')),
    Close: React.lazy(() => import('@mui/icons-material/Close')),
    CloseFullscreen: React.lazy(() => import('@mui/icons-material/CloseFullscreen')),
    CloseFullscreenOutlined: React.lazy(() => import('@mui/icons-material/CloseFullscreenOutlined')),
    CloseFullscreenRounded: React.lazy(() => import('@mui/icons-material/CloseFullscreenRounded')),
    CloseFullscreenSharp: React.lazy(() => import('@mui/icons-material/CloseFullscreenSharp')),
    CloseFullscreenTwoTone: React.lazy(() => import('@mui/icons-material/CloseFullscreenTwoTone')),
    CloseOutlined: React.lazy(() => import('@mui/icons-material/CloseOutlined')),
    CloseRounded: React.lazy(() => import('@mui/icons-material/CloseRounded')),
    CloseSharp: React.lazy(() => import('@mui/icons-material/CloseSharp')),
    CloseTwoTone: React.lazy(() => import('@mui/icons-material/CloseTwoTone')),
    CloudCircle: React.lazy(() => import('@mui/icons-material/CloudCircle')),
    CloudCircleOutlined: React.lazy(() => import('@mui/icons-material/CloudCircleOutlined')),
    CloudCircleRounded: React.lazy(() => import('@mui/icons-material/CloudCircleRounded')),
    CloudCircleSharp: React.lazy(() => import('@mui/icons-material/CloudCircleSharp')),
    CloudCircleTwoTone: React.lazy(() => import('@mui/icons-material/CloudCircleTwoTone')),
    CloudDone: React.lazy(() => import('@mui/icons-material/CloudDone')),
    CloudDoneOutlined: React.lazy(() => import('@mui/icons-material/CloudDoneOutlined')),
    CloudDoneRounded: React.lazy(() => import('@mui/icons-material/CloudDoneRounded')),
    CloudDoneSharp: React.lazy(() => import('@mui/icons-material/CloudDoneSharp')),
    CloudDoneTwoTone: React.lazy(() => import('@mui/icons-material/CloudDoneTwoTone')),
    CloudDownload: React.lazy(() => import('@mui/icons-material/CloudDownload')),
    CloudDownloadOutlined: React.lazy(() => import('@mui/icons-material/CloudDownloadOutlined')),
    CloudDownloadRounded: React.lazy(() => import('@mui/icons-material/CloudDownloadRounded')),
    CloudDownloadSharp: React.lazy(() => import('@mui/icons-material/CloudDownloadSharp')),
    CloudDownloadTwoTone: React.lazy(() => import('@mui/icons-material/CloudDownloadTwoTone')),
    Cloud: React.lazy(() => import('@mui/icons-material/Cloud')),
    CloudOff: React.lazy(() => import('@mui/icons-material/CloudOff')),
    CloudOffOutlined: React.lazy(() => import('@mui/icons-material/CloudOffOutlined')),
    CloudOffRounded: React.lazy(() => import('@mui/icons-material/CloudOffRounded')),
    CloudOffSharp: React.lazy(() => import('@mui/icons-material/CloudOffSharp')),
    CloudOffTwoTone: React.lazy(() => import('@mui/icons-material/CloudOffTwoTone')),
    CloudOutlined: React.lazy(() => import('@mui/icons-material/CloudOutlined')),
    CloudQueue: React.lazy(() => import('@mui/icons-material/CloudQueue')),
    CloudQueueOutlined: React.lazy(() => import('@mui/icons-material/CloudQueueOutlined')),
    CloudQueueRounded: React.lazy(() => import('@mui/icons-material/CloudQueueRounded')),
    CloudQueueSharp: React.lazy(() => import('@mui/icons-material/CloudQueueSharp')),
    CloudQueueTwoTone: React.lazy(() => import('@mui/icons-material/CloudQueueTwoTone')),
    CloudRounded: React.lazy(() => import('@mui/icons-material/CloudRounded')),
    CloudSharp: React.lazy(() => import('@mui/icons-material/CloudSharp')),
    CloudSync: React.lazy(() => import('@mui/icons-material/CloudSync')),
    CloudSyncOutlined: React.lazy(() => import('@mui/icons-material/CloudSyncOutlined')),
    CloudSyncRounded: React.lazy(() => import('@mui/icons-material/CloudSyncRounded')),
    CloudSyncSharp: React.lazy(() => import('@mui/icons-material/CloudSyncSharp')),
    CloudSyncTwoTone: React.lazy(() => import('@mui/icons-material/CloudSyncTwoTone')),
    CloudTwoTone: React.lazy(() => import('@mui/icons-material/CloudTwoTone')),
    CloudUpload: React.lazy(() => import('@mui/icons-material/CloudUpload')),
    CloudUploadOutlined: React.lazy(() => import('@mui/icons-material/CloudUploadOutlined')),
    CloudUploadRounded: React.lazy(() => import('@mui/icons-material/CloudUploadRounded')),
    CloudUploadSharp: React.lazy(() => import('@mui/icons-material/CloudUploadSharp')),
    CloudUploadTwoTone: React.lazy(() => import('@mui/icons-material/CloudUploadTwoTone')),
    Co2: React.lazy(() => import('@mui/icons-material/Co2')),
    Co2Outlined: React.lazy(() => import('@mui/icons-material/Co2Outlined')),
    Co2Rounded: React.lazy(() => import('@mui/icons-material/Co2Rounded')),
    Co2Sharp: React.lazy(() => import('@mui/icons-material/Co2Sharp')),
    Co2TwoTone: React.lazy(() => import('@mui/icons-material/Co2TwoTone')),
    Code: React.lazy(() => import('@mui/icons-material/Code')),
    CodeOff: React.lazy(() => import('@mui/icons-material/CodeOff')),
    CodeOffOutlined: React.lazy(() => import('@mui/icons-material/CodeOffOutlined')),
    CodeOffRounded: React.lazy(() => import('@mui/icons-material/CodeOffRounded')),
    CodeOffSharp: React.lazy(() => import('@mui/icons-material/CodeOffSharp')),
    CodeOffTwoTone: React.lazy(() => import('@mui/icons-material/CodeOffTwoTone')),
    CodeOutlined: React.lazy(() => import('@mui/icons-material/CodeOutlined')),
    CodeRounded: React.lazy(() => import('@mui/icons-material/CodeRounded')),
    CodeSharp: React.lazy(() => import('@mui/icons-material/CodeSharp')),
    CodeTwoTone: React.lazy(() => import('@mui/icons-material/CodeTwoTone')),
    Coffee: React.lazy(() => import('@mui/icons-material/Coffee')),
    CoffeeMaker: React.lazy(() => import('@mui/icons-material/CoffeeMaker')),
    CoffeeMakerOutlined: React.lazy(() => import('@mui/icons-material/CoffeeMakerOutlined')),
    CoffeeMakerRounded: React.lazy(() => import('@mui/icons-material/CoffeeMakerRounded')),
    CoffeeMakerSharp: React.lazy(() => import('@mui/icons-material/CoffeeMakerSharp')),
    CoffeeMakerTwoTone: React.lazy(() => import('@mui/icons-material/CoffeeMakerTwoTone')),
    CoffeeOutlined: React.lazy(() => import('@mui/icons-material/CoffeeOutlined')),
    CoffeeRounded: React.lazy(() => import('@mui/icons-material/CoffeeRounded')),
    CoffeeSharp: React.lazy(() => import('@mui/icons-material/CoffeeSharp')),
    CoffeeTwoTone: React.lazy(() => import('@mui/icons-material/CoffeeTwoTone')),
    CollectionsBookmark: React.lazy(() => import('@mui/icons-material/CollectionsBookmark')),
    CollectionsBookmarkOutlined: React.lazy(() => import('@mui/icons-material/CollectionsBookmarkOutlined')),
    CollectionsBookmarkRounded: React.lazy(() => import('@mui/icons-material/CollectionsBookmarkRounded')),
    CollectionsBookmarkSharp: React.lazy(() => import('@mui/icons-material/CollectionsBookmarkSharp')),
    CollectionsBookmarkTwoTone: React.lazy(() => import('@mui/icons-material/CollectionsBookmarkTwoTone')),
    Collections: React.lazy(() => import('@mui/icons-material/Collections')),
    CollectionsOutlined: React.lazy(() => import('@mui/icons-material/CollectionsOutlined')),
    CollectionsRounded: React.lazy(() => import('@mui/icons-material/CollectionsRounded')),
    CollectionsSharp: React.lazy(() => import('@mui/icons-material/CollectionsSharp')),
    CollectionsTwoTone: React.lazy(() => import('@mui/icons-material/CollectionsTwoTone')),
    Colorize: React.lazy(() => import('@mui/icons-material/Colorize')),
    ColorizeOutlined: React.lazy(() => import('@mui/icons-material/ColorizeOutlined')),
    ColorizeRounded: React.lazy(() => import('@mui/icons-material/ColorizeRounded')),
    ColorizeSharp: React.lazy(() => import('@mui/icons-material/ColorizeSharp')),
    ColorizeTwoTone: React.lazy(() => import('@mui/icons-material/ColorizeTwoTone')),
    ColorLens: React.lazy(() => import('@mui/icons-material/ColorLens')),
    ColorLensOutlined: React.lazy(() => import('@mui/icons-material/ColorLensOutlined')),
    ColorLensRounded: React.lazy(() => import('@mui/icons-material/ColorLensRounded')),
    ColorLensSharp: React.lazy(() => import('@mui/icons-material/ColorLensSharp')),
    ColorLensTwoTone: React.lazy(() => import('@mui/icons-material/ColorLensTwoTone')),
    CommentBank: React.lazy(() => import('@mui/icons-material/CommentBank')),
    CommentBankOutlined: React.lazy(() => import('@mui/icons-material/CommentBankOutlined')),
    CommentBankRounded: React.lazy(() => import('@mui/icons-material/CommentBankRounded')),
    CommentBankSharp: React.lazy(() => import('@mui/icons-material/CommentBankSharp')),
    CommentBankTwoTone: React.lazy(() => import('@mui/icons-material/CommentBankTwoTone')),
    Comment: React.lazy(() => import('@mui/icons-material/Comment')),
    CommentOutlined: React.lazy(() => import('@mui/icons-material/CommentOutlined')),
    CommentRounded: React.lazy(() => import('@mui/icons-material/CommentRounded')),
    CommentsDisabled: React.lazy(() => import('@mui/icons-material/CommentsDisabled')),
    CommentsDisabledOutlined: React.lazy(() => import('@mui/icons-material/CommentsDisabledOutlined')),
    CommentsDisabledRounded: React.lazy(() => import('@mui/icons-material/CommentsDisabledRounded')),
    CommentsDisabledSharp: React.lazy(() => import('@mui/icons-material/CommentsDisabledSharp')),
    CommentsDisabledTwoTone: React.lazy(() => import('@mui/icons-material/CommentsDisabledTwoTone')),
    CommentSharp: React.lazy(() => import('@mui/icons-material/CommentSharp')),
    CommentTwoTone: React.lazy(() => import('@mui/icons-material/CommentTwoTone')),
    Commit: React.lazy(() => import('@mui/icons-material/Commit')),
    CommitOutlined: React.lazy(() => import('@mui/icons-material/CommitOutlined')),
    CommitRounded: React.lazy(() => import('@mui/icons-material/CommitRounded')),
    CommitSharp: React.lazy(() => import('@mui/icons-material/CommitSharp')),
    CommitTwoTone: React.lazy(() => import('@mui/icons-material/CommitTwoTone')),
    Commute: React.lazy(() => import('@mui/icons-material/Commute')),
    CommuteOutlined: React.lazy(() => import('@mui/icons-material/CommuteOutlined')),
    CommuteRounded: React.lazy(() => import('@mui/icons-material/CommuteRounded')),
    CommuteSharp: React.lazy(() => import('@mui/icons-material/CommuteSharp')),
    CommuteTwoTone: React.lazy(() => import('@mui/icons-material/CommuteTwoTone')),
    CompareArrows: React.lazy(() => import('@mui/icons-material/CompareArrows')),
    CompareArrowsOutlined: React.lazy(() => import('@mui/icons-material/CompareArrowsOutlined')),
    CompareArrowsRounded: React.lazy(() => import('@mui/icons-material/CompareArrowsRounded')),
    CompareArrowsSharp: React.lazy(() => import('@mui/icons-material/CompareArrowsSharp')),
    CompareArrowsTwoTone: React.lazy(() => import('@mui/icons-material/CompareArrowsTwoTone')),
    Compare: React.lazy(() => import('@mui/icons-material/Compare')),
    CompareOutlined: React.lazy(() => import('@mui/icons-material/CompareOutlined')),
    CompareRounded: React.lazy(() => import('@mui/icons-material/CompareRounded')),
    CompareSharp: React.lazy(() => import('@mui/icons-material/CompareSharp')),
    CompareTwoTone: React.lazy(() => import('@mui/icons-material/CompareTwoTone')),
    CompassCalibration: React.lazy(() => import('@mui/icons-material/CompassCalibration')),
    CompassCalibrationOutlined: React.lazy(() => import('@mui/icons-material/CompassCalibrationOutlined')),
    CompassCalibrationRounded: React.lazy(() => import('@mui/icons-material/CompassCalibrationRounded')),
    CompassCalibrationSharp: React.lazy(() => import('@mui/icons-material/CompassCalibrationSharp')),
    CompassCalibrationTwoTone: React.lazy(() => import('@mui/icons-material/CompassCalibrationTwoTone')),
    Compress: React.lazy(() => import('@mui/icons-material/Compress')),
    CompressOutlined: React.lazy(() => import('@mui/icons-material/CompressOutlined')),
    CompressRounded: React.lazy(() => import('@mui/icons-material/CompressRounded')),
    CompressSharp: React.lazy(() => import('@mui/icons-material/CompressSharp')),
    CompressTwoTone: React.lazy(() => import('@mui/icons-material/CompressTwoTone')),
    Computer: React.lazy(() => import('@mui/icons-material/Computer')),
    ComputerOutlined: React.lazy(() => import('@mui/icons-material/ComputerOutlined')),
    ComputerRounded: React.lazy(() => import('@mui/icons-material/ComputerRounded')),
    ComputerSharp: React.lazy(() => import('@mui/icons-material/ComputerSharp')),
    ComputerTwoTone: React.lazy(() => import('@mui/icons-material/ComputerTwoTone')),
    ConfirmationNumber: React.lazy(() => import('@mui/icons-material/ConfirmationNumber')),
    ConfirmationNumberOutlined: React.lazy(() => import('@mui/icons-material/ConfirmationNumberOutlined')),
    ConfirmationNumberRounded: React.lazy(() => import('@mui/icons-material/ConfirmationNumberRounded')),
    ConfirmationNumberSharp: React.lazy(() => import('@mui/icons-material/ConfirmationNumberSharp')),
    ConfirmationNumberTwoTone: React.lazy(() => import('@mui/icons-material/ConfirmationNumberTwoTone')),
    ConnectedTv: React.lazy(() => import('@mui/icons-material/ConnectedTv')),
    ConnectedTvOutlined: React.lazy(() => import('@mui/icons-material/ConnectedTvOutlined')),
    ConnectedTvRounded: React.lazy(() => import('@mui/icons-material/ConnectedTvRounded')),
    ConnectedTvSharp: React.lazy(() => import('@mui/icons-material/ConnectedTvSharp')),
    ConnectedTvTwoTone: React.lazy(() => import('@mui/icons-material/ConnectedTvTwoTone')),
    ConnectingAirports: React.lazy(() => import('@mui/icons-material/ConnectingAirports')),
    ConnectingAirportsOutlined: React.lazy(() => import('@mui/icons-material/ConnectingAirportsOutlined')),
    ConnectingAirportsRounded: React.lazy(() => import('@mui/icons-material/ConnectingAirportsRounded')),
    ConnectingAirportsSharp: React.lazy(() => import('@mui/icons-material/ConnectingAirportsSharp')),
    ConnectingAirportsTwoTone: React.lazy(() => import('@mui/icons-material/ConnectingAirportsTwoTone')),
    ConnectWithoutContact: React.lazy(() => import('@mui/icons-material/ConnectWithoutContact')),
    ConnectWithoutContactOutlined: React.lazy(() => import('@mui/icons-material/ConnectWithoutContactOutlined')),
    ConnectWithoutContactRounded: React.lazy(() => import('@mui/icons-material/ConnectWithoutContactRounded')),
    ConnectWithoutContactSharp: React.lazy(() => import('@mui/icons-material/ConnectWithoutContactSharp')),
    ConnectWithoutContactTwoTone: React.lazy(() => import('@mui/icons-material/ConnectWithoutContactTwoTone')),
    Construction: React.lazy(() => import('@mui/icons-material/Construction')),
    ConstructionOutlined: React.lazy(() => import('@mui/icons-material/ConstructionOutlined')),
    ConstructionRounded: React.lazy(() => import('@mui/icons-material/ConstructionRounded')),
    ConstructionSharp: React.lazy(() => import('@mui/icons-material/ConstructionSharp')),
    ConstructionTwoTone: React.lazy(() => import('@mui/icons-material/ConstructionTwoTone')),
    ContactEmergency: React.lazy(() => import('@mui/icons-material/ContactEmergency')),
    ContactEmergencyOutlined: React.lazy(() => import('@mui/icons-material/ContactEmergencyOutlined')),
    ContactEmergencyRounded: React.lazy(() => import('@mui/icons-material/ContactEmergencyRounded')),
    ContactEmergencySharp: React.lazy(() => import('@mui/icons-material/ContactEmergencySharp')),
    ContactEmergencyTwoTone: React.lazy(() => import('@mui/icons-material/ContactEmergencyTwoTone')),
    Contactless: React.lazy(() => import('@mui/icons-material/Contactless')),
    ContactlessOutlined: React.lazy(() => import('@mui/icons-material/ContactlessOutlined')),
    ContactlessRounded: React.lazy(() => import('@mui/icons-material/ContactlessRounded')),
    ContactlessSharp: React.lazy(() => import('@mui/icons-material/ContactlessSharp')),
    ContactlessTwoTone: React.lazy(() => import('@mui/icons-material/ContactlessTwoTone')),
    ContactMail: React.lazy(() => import('@mui/icons-material/ContactMail')),
    ContactMailOutlined: React.lazy(() => import('@mui/icons-material/ContactMailOutlined')),
    ContactMailRounded: React.lazy(() => import('@mui/icons-material/ContactMailRounded')),
    ContactMailSharp: React.lazy(() => import('@mui/icons-material/ContactMailSharp')),
    ContactMailTwoTone: React.lazy(() => import('@mui/icons-material/ContactMailTwoTone')),
    ContactPage: React.lazy(() => import('@mui/icons-material/ContactPage')),
    ContactPageOutlined: React.lazy(() => import('@mui/icons-material/ContactPageOutlined')),
    ContactPageRounded: React.lazy(() => import('@mui/icons-material/ContactPageRounded')),
    ContactPageSharp: React.lazy(() => import('@mui/icons-material/ContactPageSharp')),
    ContactPageTwoTone: React.lazy(() => import('@mui/icons-material/ContactPageTwoTone')),
    ContactPhone: React.lazy(() => import('@mui/icons-material/ContactPhone')),
    ContactPhoneOutlined: React.lazy(() => import('@mui/icons-material/ContactPhoneOutlined')),
    ContactPhoneRounded: React.lazy(() => import('@mui/icons-material/ContactPhoneRounded')),
    ContactPhoneSharp: React.lazy(() => import('@mui/icons-material/ContactPhoneSharp')),
    ContactPhoneTwoTone: React.lazy(() => import('@mui/icons-material/ContactPhoneTwoTone')),
    Contacts: React.lazy(() => import('@mui/icons-material/Contacts')),
    ContactsOutlined: React.lazy(() => import('@mui/icons-material/ContactsOutlined')),
    ContactsRounded: React.lazy(() => import('@mui/icons-material/ContactsRounded')),
    ContactsSharp: React.lazy(() => import('@mui/icons-material/ContactsSharp')),
    ContactsTwoTone: React.lazy(() => import('@mui/icons-material/ContactsTwoTone')),
    ContactSupport: React.lazy(() => import('@mui/icons-material/ContactSupport')),
    ContactSupportOutlined: React.lazy(() => import('@mui/icons-material/ContactSupportOutlined')),
    ContactSupportRounded: React.lazy(() => import('@mui/icons-material/ContactSupportRounded')),
    ContactSupportSharp: React.lazy(() => import('@mui/icons-material/ContactSupportSharp')),
    ContactSupportTwoTone: React.lazy(() => import('@mui/icons-material/ContactSupportTwoTone')),
    ContentCopy: React.lazy(() => import('@mui/icons-material/ContentCopy')),
    ContentCopyOutlined: React.lazy(() => import('@mui/icons-material/ContentCopyOutlined')),
    ContentCopyRounded: React.lazy(() => import('@mui/icons-material/ContentCopyRounded')),
    ContentCopySharp: React.lazy(() => import('@mui/icons-material/ContentCopySharp')),
    ContentCopyTwoTone: React.lazy(() => import('@mui/icons-material/ContentCopyTwoTone')),
    ContentCut: React.lazy(() => import('@mui/icons-material/ContentCut')),
    ContentCutOutlined: React.lazy(() => import('@mui/icons-material/ContentCutOutlined')),
    ContentCutRounded: React.lazy(() => import('@mui/icons-material/ContentCutRounded')),
    ContentCutSharp: React.lazy(() => import('@mui/icons-material/ContentCutSharp')),
    ContentCutTwoTone: React.lazy(() => import('@mui/icons-material/ContentCutTwoTone')),
    ContentPaste: React.lazy(() => import('@mui/icons-material/ContentPaste')),
    ContentPasteGo: React.lazy(() => import('@mui/icons-material/ContentPasteGo')),
    ContentPasteGoOutlined: React.lazy(() => import('@mui/icons-material/ContentPasteGoOutlined')),
    ContentPasteGoRounded: React.lazy(() => import('@mui/icons-material/ContentPasteGoRounded')),
    ContentPasteGoSharp: React.lazy(() => import('@mui/icons-material/ContentPasteGoSharp')),
    ContentPasteGoTwoTone: React.lazy(() => import('@mui/icons-material/ContentPasteGoTwoTone')),
    ContentPasteOff: React.lazy(() => import('@mui/icons-material/ContentPasteOff')),
    ContentPasteOffOutlined: React.lazy(() => import('@mui/icons-material/ContentPasteOffOutlined')),
    ContentPasteOffRounded: React.lazy(() => import('@mui/icons-material/ContentPasteOffRounded')),
    ContentPasteOffSharp: React.lazy(() => import('@mui/icons-material/ContentPasteOffSharp')),
    ContentPasteOffTwoTone: React.lazy(() => import('@mui/icons-material/ContentPasteOffTwoTone')),
    ContentPasteOutlined: React.lazy(() => import('@mui/icons-material/ContentPasteOutlined')),
    ContentPasteRounded: React.lazy(() => import('@mui/icons-material/ContentPasteRounded')),
    ContentPasteSearch: React.lazy(() => import('@mui/icons-material/ContentPasteSearch')),
    ContentPasteSearchOutlined: React.lazy(() => import('@mui/icons-material/ContentPasteSearchOutlined')),
    ContentPasteSearchRounded: React.lazy(() => import('@mui/icons-material/ContentPasteSearchRounded')),
    ContentPasteSearchSharp: React.lazy(() => import('@mui/icons-material/ContentPasteSearchSharp')),
    ContentPasteSearchTwoTone: React.lazy(() => import('@mui/icons-material/ContentPasteSearchTwoTone')),
    ContentPasteSharp: React.lazy(() => import('@mui/icons-material/ContentPasteSharp')),
    ContentPasteTwoTone: React.lazy(() => import('@mui/icons-material/ContentPasteTwoTone')),
    Contrast: React.lazy(() => import('@mui/icons-material/Contrast')),
    ContrastOutlined: React.lazy(() => import('@mui/icons-material/ContrastOutlined')),
    ContrastRounded: React.lazy(() => import('@mui/icons-material/ContrastRounded')),
    ContrastSharp: React.lazy(() => import('@mui/icons-material/ContrastSharp')),
    ContrastTwoTone: React.lazy(() => import('@mui/icons-material/ContrastTwoTone')),
    ControlCamera: React.lazy(() => import('@mui/icons-material/ControlCamera')),
    ControlCameraOutlined: React.lazy(() => import('@mui/icons-material/ControlCameraOutlined')),
    ControlCameraRounded: React.lazy(() => import('@mui/icons-material/ControlCameraRounded')),
    ControlCameraSharp: React.lazy(() => import('@mui/icons-material/ControlCameraSharp')),
    ControlCameraTwoTone: React.lazy(() => import('@mui/icons-material/ControlCameraTwoTone')),
    ControlPoint: React.lazy(() => import('@mui/icons-material/ControlPoint')),
    ControlPointDuplicate: React.lazy(() => import('@mui/icons-material/ControlPointDuplicate')),
    ControlPointDuplicateOutlined: React.lazy(() => import('@mui/icons-material/ControlPointDuplicateOutlined')),
    ControlPointDuplicateRounded: React.lazy(() => import('@mui/icons-material/ControlPointDuplicateRounded')),
    ControlPointDuplicateSharp: React.lazy(() => import('@mui/icons-material/ControlPointDuplicateSharp')),
    ControlPointDuplicateTwoTone: React.lazy(() => import('@mui/icons-material/ControlPointDuplicateTwoTone')),
    ControlPointOutlined: React.lazy(() => import('@mui/icons-material/ControlPointOutlined')),
    ControlPointRounded: React.lazy(() => import('@mui/icons-material/ControlPointRounded')),
    ControlPointSharp: React.lazy(() => import('@mui/icons-material/ControlPointSharp')),
    ControlPointTwoTone: React.lazy(() => import('@mui/icons-material/ControlPointTwoTone')),
    Cookie: React.lazy(() => import('@mui/icons-material/Cookie')),
    CookieOutlined: React.lazy(() => import('@mui/icons-material/CookieOutlined')),
    CookieRounded: React.lazy(() => import('@mui/icons-material/CookieRounded')),
    CookieSharp: React.lazy(() => import('@mui/icons-material/CookieSharp')),
    CookieTwoTone: React.lazy(() => import('@mui/icons-material/CookieTwoTone')),
    CoPresent: React.lazy(() => import('@mui/icons-material/CoPresent')),
    CoPresentOutlined: React.lazy(() => import('@mui/icons-material/CoPresentOutlined')),
    CoPresentRounded: React.lazy(() => import('@mui/icons-material/CoPresentRounded')),
    CoPresentSharp: React.lazy(() => import('@mui/icons-material/CoPresentSharp')),
    CoPresentTwoTone: React.lazy(() => import('@mui/icons-material/CoPresentTwoTone')),
    CopyAll: React.lazy(() => import('@mui/icons-material/CopyAll')),
    CopyAllOutlined: React.lazy(() => import('@mui/icons-material/CopyAllOutlined')),
    CopyAllRounded: React.lazy(() => import('@mui/icons-material/CopyAllRounded')),
    CopyAllSharp: React.lazy(() => import('@mui/icons-material/CopyAllSharp')),
    CopyAllTwoTone: React.lazy(() => import('@mui/icons-material/CopyAllTwoTone')),
    Copyright: React.lazy(() => import('@mui/icons-material/Copyright')),
    CopyrightOutlined: React.lazy(() => import('@mui/icons-material/CopyrightOutlined')),
    CopyrightRounded: React.lazy(() => import('@mui/icons-material/CopyrightRounded')),
    CopyrightSharp: React.lazy(() => import('@mui/icons-material/CopyrightSharp')),
    CopyrightTwoTone: React.lazy(() => import('@mui/icons-material/CopyrightTwoTone')),
    Coronavirus: React.lazy(() => import('@mui/icons-material/Coronavirus')),
    CoronavirusOutlined: React.lazy(() => import('@mui/icons-material/CoronavirusOutlined')),
    CoronavirusRounded: React.lazy(() => import('@mui/icons-material/CoronavirusRounded')),
    CoronavirusSharp: React.lazy(() => import('@mui/icons-material/CoronavirusSharp')),
    CoronavirusTwoTone: React.lazy(() => import('@mui/icons-material/CoronavirusTwoTone')),
    CorporateFare: React.lazy(() => import('@mui/icons-material/CorporateFare')),
    CorporateFareOutlined: React.lazy(() => import('@mui/icons-material/CorporateFareOutlined')),
    CorporateFareRounded: React.lazy(() => import('@mui/icons-material/CorporateFareRounded')),
    CorporateFareSharp: React.lazy(() => import('@mui/icons-material/CorporateFareSharp')),
    CorporateFareTwoTone: React.lazy(() => import('@mui/icons-material/CorporateFareTwoTone')),
    Cottage: React.lazy(() => import('@mui/icons-material/Cottage')),
    CottageOutlined: React.lazy(() => import('@mui/icons-material/CottageOutlined')),
    CottageRounded: React.lazy(() => import('@mui/icons-material/CottageRounded')),
    CottageSharp: React.lazy(() => import('@mui/icons-material/CottageSharp')),
    CottageTwoTone: React.lazy(() => import('@mui/icons-material/CottageTwoTone')),
    Countertops: React.lazy(() => import('@mui/icons-material/Countertops')),
    CountertopsOutlined: React.lazy(() => import('@mui/icons-material/CountertopsOutlined')),
    CountertopsRounded: React.lazy(() => import('@mui/icons-material/CountertopsRounded')),
    CountertopsSharp: React.lazy(() => import('@mui/icons-material/CountertopsSharp')),
    CountertopsTwoTone: React.lazy(() => import('@mui/icons-material/CountertopsTwoTone')),
    Create: React.lazy(() => import('@mui/icons-material/Create')),
    CreateNewFolder: React.lazy(() => import('@mui/icons-material/CreateNewFolder')),
    CreateNewFolderOutlined: React.lazy(() => import('@mui/icons-material/CreateNewFolderOutlined')),
    CreateNewFolderRounded: React.lazy(() => import('@mui/icons-material/CreateNewFolderRounded')),
    CreateNewFolderSharp: React.lazy(() => import('@mui/icons-material/CreateNewFolderSharp')),
    CreateNewFolderTwoTone: React.lazy(() => import('@mui/icons-material/CreateNewFolderTwoTone')),
    CreateOutlined: React.lazy(() => import('@mui/icons-material/CreateOutlined')),
    CreateRounded: React.lazy(() => import('@mui/icons-material/CreateRounded')),
    CreateSharp: React.lazy(() => import('@mui/icons-material/CreateSharp')),
    CreateTwoTone: React.lazy(() => import('@mui/icons-material/CreateTwoTone')),
    CreditCard: React.lazy(() => import('@mui/icons-material/CreditCard')),
    CreditCardOff: React.lazy(() => import('@mui/icons-material/CreditCardOff')),
    CreditCardOffOutlined: React.lazy(() => import('@mui/icons-material/CreditCardOffOutlined')),
    CreditCardOffRounded: React.lazy(() => import('@mui/icons-material/CreditCardOffRounded')),
    CreditCardOffSharp: React.lazy(() => import('@mui/icons-material/CreditCardOffSharp')),
    CreditCardOffTwoTone: React.lazy(() => import('@mui/icons-material/CreditCardOffTwoTone')),
    CreditCardOutlined: React.lazy(() => import('@mui/icons-material/CreditCardOutlined')),
    CreditCardRounded: React.lazy(() => import('@mui/icons-material/CreditCardRounded')),
    CreditCardSharp: React.lazy(() => import('@mui/icons-material/CreditCardSharp')),
    CreditCardTwoTone: React.lazy(() => import('@mui/icons-material/CreditCardTwoTone')),
    CreditScore: React.lazy(() => import('@mui/icons-material/CreditScore')),
    CreditScoreOutlined: React.lazy(() => import('@mui/icons-material/CreditScoreOutlined')),
    CreditScoreRounded: React.lazy(() => import('@mui/icons-material/CreditScoreRounded')),
    CreditScoreSharp: React.lazy(() => import('@mui/icons-material/CreditScoreSharp')),
    CreditScoreTwoTone: React.lazy(() => import('@mui/icons-material/CreditScoreTwoTone')),
    Crib: React.lazy(() => import('@mui/icons-material/Crib')),
    CribOutlined: React.lazy(() => import('@mui/icons-material/CribOutlined')),
    CribRounded: React.lazy(() => import('@mui/icons-material/CribRounded')),
    CribSharp: React.lazy(() => import('@mui/icons-material/CribSharp')),
    CribTwoTone: React.lazy(() => import('@mui/icons-material/CribTwoTone')),
    CrisisAlert: React.lazy(() => import('@mui/icons-material/CrisisAlert')),
    CrisisAlertOutlined: React.lazy(() => import('@mui/icons-material/CrisisAlertOutlined')),
    CrisisAlertRounded: React.lazy(() => import('@mui/icons-material/CrisisAlertRounded')),
    CrisisAlertSharp: React.lazy(() => import('@mui/icons-material/CrisisAlertSharp')),
    CrisisAlertTwoTone: React.lazy(() => import('@mui/icons-material/CrisisAlertTwoTone')),
    Crop169: React.lazy(() => import('@mui/icons-material/Crop169')),
    Crop169Outlined: React.lazy(() => import('@mui/icons-material/Crop169Outlined')),
    Crop169Rounded: React.lazy(() => import('@mui/icons-material/Crop169Rounded')),
    Crop169Sharp: React.lazy(() => import('@mui/icons-material/Crop169Sharp')),
    Crop169TwoTone: React.lazy(() => import('@mui/icons-material/Crop169TwoTone')),
    Crop32: React.lazy(() => import('@mui/icons-material/Crop32')),
    Crop32Outlined: React.lazy(() => import('@mui/icons-material/Crop32Outlined')),
    Crop32Rounded: React.lazy(() => import('@mui/icons-material/Crop32Rounded')),
    Crop32Sharp: React.lazy(() => import('@mui/icons-material/Crop32Sharp')),
    Crop32TwoTone: React.lazy(() => import('@mui/icons-material/Crop32TwoTone')),
    Crop54: React.lazy(() => import('@mui/icons-material/Crop54')),
    Crop54Outlined: React.lazy(() => import('@mui/icons-material/Crop54Outlined')),
    Crop54Rounded: React.lazy(() => import('@mui/icons-material/Crop54Rounded')),
    Crop54Sharp: React.lazy(() => import('@mui/icons-material/Crop54Sharp')),
    Crop54TwoTone: React.lazy(() => import('@mui/icons-material/Crop54TwoTone')),
    Crop75: React.lazy(() => import('@mui/icons-material/Crop75')),
    Crop75Outlined: React.lazy(() => import('@mui/icons-material/Crop75Outlined')),
    Crop75Rounded: React.lazy(() => import('@mui/icons-material/Crop75Rounded')),
    Crop75Sharp: React.lazy(() => import('@mui/icons-material/Crop75Sharp')),
    Crop75TwoTone: React.lazy(() => import('@mui/icons-material/Crop75TwoTone')),
    CropDin: React.lazy(() => import('@mui/icons-material/CropDin')),
    CropDinOutlined: React.lazy(() => import('@mui/icons-material/CropDinOutlined')),
    CropDinRounded: React.lazy(() => import('@mui/icons-material/CropDinRounded')),
    CropDinSharp: React.lazy(() => import('@mui/icons-material/CropDinSharp')),
    CropDinTwoTone: React.lazy(() => import('@mui/icons-material/CropDinTwoTone')),
    Crop: React.lazy(() => import('@mui/icons-material/Crop')),
    CropFree: React.lazy(() => import('@mui/icons-material/CropFree')),
    CropFreeOutlined: React.lazy(() => import('@mui/icons-material/CropFreeOutlined')),
    CropFreeRounded: React.lazy(() => import('@mui/icons-material/CropFreeRounded')),
    CropFreeSharp: React.lazy(() => import('@mui/icons-material/CropFreeSharp')),
    CropFreeTwoTone: React.lazy(() => import('@mui/icons-material/CropFreeTwoTone')),
    CropLandscape: React.lazy(() => import('@mui/icons-material/CropLandscape')),
    CropLandscapeOutlined: React.lazy(() => import('@mui/icons-material/CropLandscapeOutlined')),
    CropLandscapeRounded: React.lazy(() => import('@mui/icons-material/CropLandscapeRounded')),
    CropLandscapeSharp: React.lazy(() => import('@mui/icons-material/CropLandscapeSharp')),
    CropLandscapeTwoTone: React.lazy(() => import('@mui/icons-material/CropLandscapeTwoTone')),
    CropOriginal: React.lazy(() => import('@mui/icons-material/CropOriginal')),
    CropOriginalOutlined: React.lazy(() => import('@mui/icons-material/CropOriginalOutlined')),
    CropOriginalRounded: React.lazy(() => import('@mui/icons-material/CropOriginalRounded')),
    CropOriginalSharp: React.lazy(() => import('@mui/icons-material/CropOriginalSharp')),
    CropOriginalTwoTone: React.lazy(() => import('@mui/icons-material/CropOriginalTwoTone')),
    CropOutlined: React.lazy(() => import('@mui/icons-material/CropOutlined')),
    CropPortrait: React.lazy(() => import('@mui/icons-material/CropPortrait')),
    CropPortraitOutlined: React.lazy(() => import('@mui/icons-material/CropPortraitOutlined')),
    CropPortraitRounded: React.lazy(() => import('@mui/icons-material/CropPortraitRounded')),
    CropPortraitSharp: React.lazy(() => import('@mui/icons-material/CropPortraitSharp')),
    CropPortraitTwoTone: React.lazy(() => import('@mui/icons-material/CropPortraitTwoTone')),
    CropRotate: React.lazy(() => import('@mui/icons-material/CropRotate')),
    CropRotateOutlined: React.lazy(() => import('@mui/icons-material/CropRotateOutlined')),
    CropRotateRounded: React.lazy(() => import('@mui/icons-material/CropRotateRounded')),
    CropRotateSharp: React.lazy(() => import('@mui/icons-material/CropRotateSharp')),
    CropRotateTwoTone: React.lazy(() => import('@mui/icons-material/CropRotateTwoTone')),
    CropRounded: React.lazy(() => import('@mui/icons-material/CropRounded')),
    CropSharp: React.lazy(() => import('@mui/icons-material/CropSharp')),
    CropSquare: React.lazy(() => import('@mui/icons-material/CropSquare')),
    CropSquareOutlined: React.lazy(() => import('@mui/icons-material/CropSquareOutlined')),
    CropSquareRounded: React.lazy(() => import('@mui/icons-material/CropSquareRounded')),
    CropSquareSharp: React.lazy(() => import('@mui/icons-material/CropSquareSharp')),
    CropSquareTwoTone: React.lazy(() => import('@mui/icons-material/CropSquareTwoTone')),
    CropTwoTone: React.lazy(() => import('@mui/icons-material/CropTwoTone')),
    Css: React.lazy(() => import('@mui/icons-material/Css')),
    CssOutlined: React.lazy(() => import('@mui/icons-material/CssOutlined')),
    CssRounded: React.lazy(() => import('@mui/icons-material/CssRounded')),
    CssSharp: React.lazy(() => import('@mui/icons-material/CssSharp')),
    CssTwoTone: React.lazy(() => import('@mui/icons-material/CssTwoTone')),
    CurrencyBitcoin: React.lazy(() => import('@mui/icons-material/CurrencyBitcoin')),
    CurrencyBitcoinOutlined: React.lazy(() => import('@mui/icons-material/CurrencyBitcoinOutlined')),
    CurrencyBitcoinRounded: React.lazy(() => import('@mui/icons-material/CurrencyBitcoinRounded')),
    CurrencyBitcoinSharp: React.lazy(() => import('@mui/icons-material/CurrencyBitcoinSharp')),
    CurrencyBitcoinTwoTone: React.lazy(() => import('@mui/icons-material/CurrencyBitcoinTwoTone')),
    CurrencyExchange: React.lazy(() => import('@mui/icons-material/CurrencyExchange')),
    CurrencyExchangeOutlined: React.lazy(() => import('@mui/icons-material/CurrencyExchangeOutlined')),
    CurrencyExchangeRounded: React.lazy(() => import('@mui/icons-material/CurrencyExchangeRounded')),
    CurrencyExchangeSharp: React.lazy(() => import('@mui/icons-material/CurrencyExchangeSharp')),
    CurrencyExchangeTwoTone: React.lazy(() => import('@mui/icons-material/CurrencyExchangeTwoTone')),
    CurrencyFranc: React.lazy(() => import('@mui/icons-material/CurrencyFranc')),
    CurrencyFrancOutlined: React.lazy(() => import('@mui/icons-material/CurrencyFrancOutlined')),
    CurrencyFrancRounded: React.lazy(() => import('@mui/icons-material/CurrencyFrancRounded')),
    CurrencyFrancSharp: React.lazy(() => import('@mui/icons-material/CurrencyFrancSharp')),
    CurrencyFrancTwoTone: React.lazy(() => import('@mui/icons-material/CurrencyFrancTwoTone')),
    CurrencyLira: React.lazy(() => import('@mui/icons-material/CurrencyLira')),
    CurrencyLiraOutlined: React.lazy(() => import('@mui/icons-material/CurrencyLiraOutlined')),
    CurrencyLiraRounded: React.lazy(() => import('@mui/icons-material/CurrencyLiraRounded')),
    CurrencyLiraSharp: React.lazy(() => import('@mui/icons-material/CurrencyLiraSharp')),
    CurrencyLiraTwoTone: React.lazy(() => import('@mui/icons-material/CurrencyLiraTwoTone')),
    CurrencyPound: React.lazy(() => import('@mui/icons-material/CurrencyPound')),
    CurrencyPoundOutlined: React.lazy(() => import('@mui/icons-material/CurrencyPoundOutlined')),
    CurrencyPoundRounded: React.lazy(() => import('@mui/icons-material/CurrencyPoundRounded')),
    CurrencyPoundSharp: React.lazy(() => import('@mui/icons-material/CurrencyPoundSharp')),
    CurrencyPoundTwoTone: React.lazy(() => import('@mui/icons-material/CurrencyPoundTwoTone')),
    CurrencyRuble: React.lazy(() => import('@mui/icons-material/CurrencyRuble')),
    CurrencyRubleOutlined: React.lazy(() => import('@mui/icons-material/CurrencyRubleOutlined')),
    CurrencyRubleRounded: React.lazy(() => import('@mui/icons-material/CurrencyRubleRounded')),
    CurrencyRubleSharp: React.lazy(() => import('@mui/icons-material/CurrencyRubleSharp')),
    CurrencyRubleTwoTone: React.lazy(() => import('@mui/icons-material/CurrencyRubleTwoTone')),
    CurrencyRupee: React.lazy(() => import('@mui/icons-material/CurrencyRupee')),
    CurrencyRupeeOutlined: React.lazy(() => import('@mui/icons-material/CurrencyRupeeOutlined')),
    CurrencyRupeeRounded: React.lazy(() => import('@mui/icons-material/CurrencyRupeeRounded')),
    CurrencyRupeeSharp: React.lazy(() => import('@mui/icons-material/CurrencyRupeeSharp')),
    CurrencyRupeeTwoTone: React.lazy(() => import('@mui/icons-material/CurrencyRupeeTwoTone')),
    CurrencyYen: React.lazy(() => import('@mui/icons-material/CurrencyYen')),
    CurrencyYenOutlined: React.lazy(() => import('@mui/icons-material/CurrencyYenOutlined')),
    CurrencyYenRounded: React.lazy(() => import('@mui/icons-material/CurrencyYenRounded')),
    CurrencyYenSharp: React.lazy(() => import('@mui/icons-material/CurrencyYenSharp')),
    CurrencyYenTwoTone: React.lazy(() => import('@mui/icons-material/CurrencyYenTwoTone')),
    CurrencyYuan: React.lazy(() => import('@mui/icons-material/CurrencyYuan')),
    CurrencyYuanOutlined: React.lazy(() => import('@mui/icons-material/CurrencyYuanOutlined')),
    CurrencyYuanRounded: React.lazy(() => import('@mui/icons-material/CurrencyYuanRounded')),
    CurrencyYuanSharp: React.lazy(() => import('@mui/icons-material/CurrencyYuanSharp')),
    CurrencyYuanTwoTone: React.lazy(() => import('@mui/icons-material/CurrencyYuanTwoTone')),
    CurtainsClosed: React.lazy(() => import('@mui/icons-material/CurtainsClosed')),
    CurtainsClosedOutlined: React.lazy(() => import('@mui/icons-material/CurtainsClosedOutlined')),
    CurtainsClosedRounded: React.lazy(() => import('@mui/icons-material/CurtainsClosedRounded')),
    CurtainsClosedSharp: React.lazy(() => import('@mui/icons-material/CurtainsClosedSharp')),
    CurtainsClosedTwoTone: React.lazy(() => import('@mui/icons-material/CurtainsClosedTwoTone')),
    Curtains: React.lazy(() => import('@mui/icons-material/Curtains')),
    CurtainsOutlined: React.lazy(() => import('@mui/icons-material/CurtainsOutlined')),
    CurtainsRounded: React.lazy(() => import('@mui/icons-material/CurtainsRounded')),
    CurtainsSharp: React.lazy(() => import('@mui/icons-material/CurtainsSharp')),
    CurtainsTwoTone: React.lazy(() => import('@mui/icons-material/CurtainsTwoTone')),
    Cyclone: React.lazy(() => import('@mui/icons-material/Cyclone')),
    CycloneOutlined: React.lazy(() => import('@mui/icons-material/CycloneOutlined')),
    CycloneRounded: React.lazy(() => import('@mui/icons-material/CycloneRounded')),
    CycloneSharp: React.lazy(() => import('@mui/icons-material/CycloneSharp')),
    CycloneTwoTone: React.lazy(() => import('@mui/icons-material/CycloneTwoTone')),
    Dangerous: React.lazy(() => import('@mui/icons-material/Dangerous')),
    DangerousOutlined: React.lazy(() => import('@mui/icons-material/DangerousOutlined')),
    DangerousRounded: React.lazy(() => import('@mui/icons-material/DangerousRounded')),
    DangerousSharp: React.lazy(() => import('@mui/icons-material/DangerousSharp')),
    DangerousTwoTone: React.lazy(() => import('@mui/icons-material/DangerousTwoTone')),
    DarkMode: React.lazy(() => import('@mui/icons-material/DarkMode')),
    DarkModeOutlined: React.lazy(() => import('@mui/icons-material/DarkModeOutlined')),
    DarkModeRounded: React.lazy(() => import('@mui/icons-material/DarkModeRounded')),
    DarkModeSharp: React.lazy(() => import('@mui/icons-material/DarkModeSharp')),
    DarkModeTwoTone: React.lazy(() => import('@mui/icons-material/DarkModeTwoTone')),
    DashboardCustomize: React.lazy(() => import('@mui/icons-material/DashboardCustomize')),
    DashboardCustomizeOutlined: React.lazy(() => import('@mui/icons-material/DashboardCustomizeOutlined')),
    DashboardCustomizeRounded: React.lazy(() => import('@mui/icons-material/DashboardCustomizeRounded')),
    DashboardCustomizeSharp: React.lazy(() => import('@mui/icons-material/DashboardCustomizeSharp')),
    DashboardCustomizeTwoTone: React.lazy(() => import('@mui/icons-material/DashboardCustomizeTwoTone')),
    Dashboard: React.lazy(() => import('@mui/icons-material/Dashboard')),
    DashboardOutlined: React.lazy(() => import('@mui/icons-material/DashboardOutlined')),
    DashboardRounded: React.lazy(() => import('@mui/icons-material/DashboardRounded')),
    DashboardSharp: React.lazy(() => import('@mui/icons-material/DashboardSharp')),
    DashboardTwoTone: React.lazy(() => import('@mui/icons-material/DashboardTwoTone')),
    DataArray: React.lazy(() => import('@mui/icons-material/DataArray')),
    DataArrayOutlined: React.lazy(() => import('@mui/icons-material/DataArrayOutlined')),
    DataArrayRounded: React.lazy(() => import('@mui/icons-material/DataArrayRounded')),
    DataArraySharp: React.lazy(() => import('@mui/icons-material/DataArraySharp')),
    DataArrayTwoTone: React.lazy(() => import('@mui/icons-material/DataArrayTwoTone')),
    DataObject: React.lazy(() => import('@mui/icons-material/DataObject')),
    DataObjectOutlined: React.lazy(() => import('@mui/icons-material/DataObjectOutlined')),
    DataObjectRounded: React.lazy(() => import('@mui/icons-material/DataObjectRounded')),
    DataObjectSharp: React.lazy(() => import('@mui/icons-material/DataObjectSharp')),
    DataObjectTwoTone: React.lazy(() => import('@mui/icons-material/DataObjectTwoTone')),
    DataSaverOff: React.lazy(() => import('@mui/icons-material/DataSaverOff')),
    DataSaverOffOutlined: React.lazy(() => import('@mui/icons-material/DataSaverOffOutlined')),
    DataSaverOffRounded: React.lazy(() => import('@mui/icons-material/DataSaverOffRounded')),
    DataSaverOffSharp: React.lazy(() => import('@mui/icons-material/DataSaverOffSharp')),
    DataSaverOffTwoTone: React.lazy(() => import('@mui/icons-material/DataSaverOffTwoTone')),
    DataSaverOn: React.lazy(() => import('@mui/icons-material/DataSaverOn')),
    DataSaverOnOutlined: React.lazy(() => import('@mui/icons-material/DataSaverOnOutlined')),
    DataSaverOnRounded: React.lazy(() => import('@mui/icons-material/DataSaverOnRounded')),
    DataSaverOnSharp: React.lazy(() => import('@mui/icons-material/DataSaverOnSharp')),
    DataSaverOnTwoTone: React.lazy(() => import('@mui/icons-material/DataSaverOnTwoTone')),
    Dataset: React.lazy(() => import('@mui/icons-material/Dataset')),
    DatasetLinked: React.lazy(() => import('@mui/icons-material/DatasetLinked')),
    DatasetLinkedOutlined: React.lazy(() => import('@mui/icons-material/DatasetLinkedOutlined')),
    DatasetLinkedRounded: React.lazy(() => import('@mui/icons-material/DatasetLinkedRounded')),
    DatasetLinkedSharp: React.lazy(() => import('@mui/icons-material/DatasetLinkedSharp')),
    DatasetLinkedTwoTone: React.lazy(() => import('@mui/icons-material/DatasetLinkedTwoTone')),
    DatasetOutlined: React.lazy(() => import('@mui/icons-material/DatasetOutlined')),
    DatasetRounded: React.lazy(() => import('@mui/icons-material/DatasetRounded')),
    DatasetSharp: React.lazy(() => import('@mui/icons-material/DatasetSharp')),
    DatasetTwoTone: React.lazy(() => import('@mui/icons-material/DatasetTwoTone')),
    DataThresholding: React.lazy(() => import('@mui/icons-material/DataThresholding')),
    DataThresholdingOutlined: React.lazy(() => import('@mui/icons-material/DataThresholdingOutlined')),
    DataThresholdingRounded: React.lazy(() => import('@mui/icons-material/DataThresholdingRounded')),
    DataThresholdingSharp: React.lazy(() => import('@mui/icons-material/DataThresholdingSharp')),
    DataThresholdingTwoTone: React.lazy(() => import('@mui/icons-material/DataThresholdingTwoTone')),
    DataUsage: React.lazy(() => import('@mui/icons-material/DataUsage')),
    DataUsageOutlined: React.lazy(() => import('@mui/icons-material/DataUsageOutlined')),
    DataUsageRounded: React.lazy(() => import('@mui/icons-material/DataUsageRounded')),
    DataUsageSharp: React.lazy(() => import('@mui/icons-material/DataUsageSharp')),
    DataUsageTwoTone: React.lazy(() => import('@mui/icons-material/DataUsageTwoTone')),
    DateRange: React.lazy(() => import('@mui/icons-material/DateRange')),
    DateRangeOutlined: React.lazy(() => import('@mui/icons-material/DateRangeOutlined')),
    DateRangeRounded: React.lazy(() => import('@mui/icons-material/DateRangeRounded')),
    DateRangeSharp: React.lazy(() => import('@mui/icons-material/DateRangeSharp')),
    DateRangeTwoTone: React.lazy(() => import('@mui/icons-material/DateRangeTwoTone')),
    Deblur: React.lazy(() => import('@mui/icons-material/Deblur')),
    DeblurOutlined: React.lazy(() => import('@mui/icons-material/DeblurOutlined')),
    DeblurRounded: React.lazy(() => import('@mui/icons-material/DeblurRounded')),
    DeblurSharp: React.lazy(() => import('@mui/icons-material/DeblurSharp')),
    DeblurTwoTone: React.lazy(() => import('@mui/icons-material/DeblurTwoTone')),
    Deck: React.lazy(() => import('@mui/icons-material/Deck')),
    DeckOutlined: React.lazy(() => import('@mui/icons-material/DeckOutlined')),
    DeckRounded: React.lazy(() => import('@mui/icons-material/DeckRounded')),
    DeckSharp: React.lazy(() => import('@mui/icons-material/DeckSharp')),
    DeckTwoTone: React.lazy(() => import('@mui/icons-material/DeckTwoTone')),
    Dehaze: React.lazy(() => import('@mui/icons-material/Dehaze')),
    DehazeOutlined: React.lazy(() => import('@mui/icons-material/DehazeOutlined')),
    DehazeRounded: React.lazy(() => import('@mui/icons-material/DehazeRounded')),
    DehazeSharp: React.lazy(() => import('@mui/icons-material/DehazeSharp')),
    DehazeTwoTone: React.lazy(() => import('@mui/icons-material/DehazeTwoTone')),
    Delete: React.lazy(() => import('@mui/icons-material/Delete')),
    DeleteForever: React.lazy(() => import('@mui/icons-material/DeleteForever')),
    DeleteForeverOutlined: React.lazy(() => import('@mui/icons-material/DeleteForeverOutlined')),
    DeleteForeverRounded: React.lazy(() => import('@mui/icons-material/DeleteForeverRounded')),
    DeleteForeverSharp: React.lazy(() => import('@mui/icons-material/DeleteForeverSharp')),
    DeleteForeverTwoTone: React.lazy(() => import('@mui/icons-material/DeleteForeverTwoTone')),
    DeleteOutlined: React.lazy(() => import('@mui/icons-material/DeleteOutlined')),
    DeleteOutline: React.lazy(() => import('@mui/icons-material/DeleteOutline')),
    DeleteOutlineOutlined: React.lazy(() => import('@mui/icons-material/DeleteOutlineOutlined')),
    DeleteOutlineRounded: React.lazy(() => import('@mui/icons-material/DeleteOutlineRounded')),
    DeleteOutlineSharp: React.lazy(() => import('@mui/icons-material/DeleteOutlineSharp')),
    DeleteOutlineTwoTone: React.lazy(() => import('@mui/icons-material/DeleteOutlineTwoTone')),
    DeleteRounded: React.lazy(() => import('@mui/icons-material/DeleteRounded')),
    DeleteSharp: React.lazy(() => import('@mui/icons-material/DeleteSharp')),
    DeleteSweep: React.lazy(() => import('@mui/icons-material/DeleteSweep')),
    DeleteSweepOutlined: React.lazy(() => import('@mui/icons-material/DeleteSweepOutlined')),
    DeleteSweepRounded: React.lazy(() => import('@mui/icons-material/DeleteSweepRounded')),
    DeleteSweepSharp: React.lazy(() => import('@mui/icons-material/DeleteSweepSharp')),
    DeleteSweepTwoTone: React.lazy(() => import('@mui/icons-material/DeleteSweepTwoTone')),
    DeleteTwoTone: React.lazy(() => import('@mui/icons-material/DeleteTwoTone')),
    DeliveryDining: React.lazy(() => import('@mui/icons-material/DeliveryDining')),
    DeliveryDiningOutlined: React.lazy(() => import('@mui/icons-material/DeliveryDiningOutlined')),
    DeliveryDiningRounded: React.lazy(() => import('@mui/icons-material/DeliveryDiningRounded')),
    DeliveryDiningSharp: React.lazy(() => import('@mui/icons-material/DeliveryDiningSharp')),
    DeliveryDiningTwoTone: React.lazy(() => import('@mui/icons-material/DeliveryDiningTwoTone')),
    DensityLarge: React.lazy(() => import('@mui/icons-material/DensityLarge')),
    DensityLargeOutlined: React.lazy(() => import('@mui/icons-material/DensityLargeOutlined')),
    DensityLargeRounded: React.lazy(() => import('@mui/icons-material/DensityLargeRounded')),
    DensityLargeSharp: React.lazy(() => import('@mui/icons-material/DensityLargeSharp')),
    DensityLargeTwoTone: React.lazy(() => import('@mui/icons-material/DensityLargeTwoTone')),
    DensityMedium: React.lazy(() => import('@mui/icons-material/DensityMedium')),
    DensityMediumOutlined: React.lazy(() => import('@mui/icons-material/DensityMediumOutlined')),
    DensityMediumRounded: React.lazy(() => import('@mui/icons-material/DensityMediumRounded')),
    DensityMediumSharp: React.lazy(() => import('@mui/icons-material/DensityMediumSharp')),
    DensityMediumTwoTone: React.lazy(() => import('@mui/icons-material/DensityMediumTwoTone')),
    DensitySmall: React.lazy(() => import('@mui/icons-material/DensitySmall')),
    DensitySmallOutlined: React.lazy(() => import('@mui/icons-material/DensitySmallOutlined')),
    DensitySmallRounded: React.lazy(() => import('@mui/icons-material/DensitySmallRounded')),
    DensitySmallSharp: React.lazy(() => import('@mui/icons-material/DensitySmallSharp')),
    DensitySmallTwoTone: React.lazy(() => import('@mui/icons-material/DensitySmallTwoTone')),
    DepartureBoard: React.lazy(() => import('@mui/icons-material/DepartureBoard')),
    DepartureBoardOutlined: React.lazy(() => import('@mui/icons-material/DepartureBoardOutlined')),
    DepartureBoardRounded: React.lazy(() => import('@mui/icons-material/DepartureBoardRounded')),
    DepartureBoardSharp: React.lazy(() => import('@mui/icons-material/DepartureBoardSharp')),
    DepartureBoardTwoTone: React.lazy(() => import('@mui/icons-material/DepartureBoardTwoTone')),
    Description: React.lazy(() => import('@mui/icons-material/Description')),
    DescriptionOutlined: React.lazy(() => import('@mui/icons-material/DescriptionOutlined')),
    DescriptionRounded: React.lazy(() => import('@mui/icons-material/DescriptionRounded')),
    DescriptionSharp: React.lazy(() => import('@mui/icons-material/DescriptionSharp')),
    DescriptionTwoTone: React.lazy(() => import('@mui/icons-material/DescriptionTwoTone')),
    Deselect: React.lazy(() => import('@mui/icons-material/Deselect')),
    DeselectOutlined: React.lazy(() => import('@mui/icons-material/DeselectOutlined')),
    DeselectRounded: React.lazy(() => import('@mui/icons-material/DeselectRounded')),
    DeselectSharp: React.lazy(() => import('@mui/icons-material/DeselectSharp')),
    DeselectTwoTone: React.lazy(() => import('@mui/icons-material/DeselectTwoTone')),
    DesignServices: React.lazy(() => import('@mui/icons-material/DesignServices')),
    DesignServicesOutlined: React.lazy(() => import('@mui/icons-material/DesignServicesOutlined')),
    DesignServicesRounded: React.lazy(() => import('@mui/icons-material/DesignServicesRounded')),
    DesignServicesSharp: React.lazy(() => import('@mui/icons-material/DesignServicesSharp')),
    DesignServicesTwoTone: React.lazy(() => import('@mui/icons-material/DesignServicesTwoTone')),
    Desk: React.lazy(() => import('@mui/icons-material/Desk')),
    DeskOutlined: React.lazy(() => import('@mui/icons-material/DeskOutlined')),
    DeskRounded: React.lazy(() => import('@mui/icons-material/DeskRounded')),
    DeskSharp: React.lazy(() => import('@mui/icons-material/DeskSharp')),
    DesktopAccessDisabled: React.lazy(() => import('@mui/icons-material/DesktopAccessDisabled')),
    DesktopAccessDisabledOutlined: React.lazy(() => import('@mui/icons-material/DesktopAccessDisabledOutlined')),
    DesktopAccessDisabledRounded: React.lazy(() => import('@mui/icons-material/DesktopAccessDisabledRounded')),
    DesktopAccessDisabledSharp: React.lazy(() => import('@mui/icons-material/DesktopAccessDisabledSharp')),
    DesktopAccessDisabledTwoTone: React.lazy(() => import('@mui/icons-material/DesktopAccessDisabledTwoTone')),
    DesktopMac: React.lazy(() => import('@mui/icons-material/DesktopMac')),
    DesktopMacOutlined: React.lazy(() => import('@mui/icons-material/DesktopMacOutlined')),
    DesktopMacRounded: React.lazy(() => import('@mui/icons-material/DesktopMacRounded')),
    DesktopMacSharp: React.lazy(() => import('@mui/icons-material/DesktopMacSharp')),
    DesktopMacTwoTone: React.lazy(() => import('@mui/icons-material/DesktopMacTwoTone')),
    DesktopWindows: React.lazy(() => import('@mui/icons-material/DesktopWindows')),
    DesktopWindowsOutlined: React.lazy(() => import('@mui/icons-material/DesktopWindowsOutlined')),
    DesktopWindowsRounded: React.lazy(() => import('@mui/icons-material/DesktopWindowsRounded')),
    DesktopWindowsSharp: React.lazy(() => import('@mui/icons-material/DesktopWindowsSharp')),
    DesktopWindowsTwoTone: React.lazy(() => import('@mui/icons-material/DesktopWindowsTwoTone')),
    DeskTwoTone: React.lazy(() => import('@mui/icons-material/DeskTwoTone')),
    Details: React.lazy(() => import('@mui/icons-material/Details')),
    DetailsOutlined: React.lazy(() => import('@mui/icons-material/DetailsOutlined')),
    DetailsRounded: React.lazy(() => import('@mui/icons-material/DetailsRounded')),
    DetailsSharp: React.lazy(() => import('@mui/icons-material/DetailsSharp')),
    DetailsTwoTone: React.lazy(() => import('@mui/icons-material/DetailsTwoTone')),
    DeveloperBoard: React.lazy(() => import('@mui/icons-material/DeveloperBoard')),
    DeveloperBoardOff: React.lazy(() => import('@mui/icons-material/DeveloperBoardOff')),
    DeveloperBoardOffOutlined: React.lazy(() => import('@mui/icons-material/DeveloperBoardOffOutlined')),
    DeveloperBoardOffRounded: React.lazy(() => import('@mui/icons-material/DeveloperBoardOffRounded')),
    DeveloperBoardOffSharp: React.lazy(() => import('@mui/icons-material/DeveloperBoardOffSharp')),
    DeveloperBoardOffTwoTone: React.lazy(() => import('@mui/icons-material/DeveloperBoardOffTwoTone')),
    DeveloperBoardOutlined: React.lazy(() => import('@mui/icons-material/DeveloperBoardOutlined')),
    DeveloperBoardRounded: React.lazy(() => import('@mui/icons-material/DeveloperBoardRounded')),
    DeveloperBoardSharp: React.lazy(() => import('@mui/icons-material/DeveloperBoardSharp')),
    DeveloperBoardTwoTone: React.lazy(() => import('@mui/icons-material/DeveloperBoardTwoTone')),
    DeveloperMode: React.lazy(() => import('@mui/icons-material/DeveloperMode')),
    DeveloperModeOutlined: React.lazy(() => import('@mui/icons-material/DeveloperModeOutlined')),
    DeveloperModeRounded: React.lazy(() => import('@mui/icons-material/DeveloperModeRounded')),
    DeveloperModeSharp: React.lazy(() => import('@mui/icons-material/DeveloperModeSharp')),
    DeveloperModeTwoTone: React.lazy(() => import('@mui/icons-material/DeveloperModeTwoTone')),
    DeviceHub: React.lazy(() => import('@mui/icons-material/DeviceHub')),
    DeviceHubOutlined: React.lazy(() => import('@mui/icons-material/DeviceHubOutlined')),
    DeviceHubRounded: React.lazy(() => import('@mui/icons-material/DeviceHubRounded')),
    DeviceHubSharp: React.lazy(() => import('@mui/icons-material/DeviceHubSharp')),
    DeviceHubTwoTone: React.lazy(() => import('@mui/icons-material/DeviceHubTwoTone')),
    Devices: React.lazy(() => import('@mui/icons-material/Devices')),
    DevicesFold: React.lazy(() => import('@mui/icons-material/DevicesFold')),
    DevicesFoldOutlined: React.lazy(() => import('@mui/icons-material/DevicesFoldOutlined')),
    DevicesFoldRounded: React.lazy(() => import('@mui/icons-material/DevicesFoldRounded')),
    DevicesFoldSharp: React.lazy(() => import('@mui/icons-material/DevicesFoldSharp')),
    DevicesFoldTwoTone: React.lazy(() => import('@mui/icons-material/DevicesFoldTwoTone')),
    DevicesOther: React.lazy(() => import('@mui/icons-material/DevicesOther')),
    DevicesOtherOutlined: React.lazy(() => import('@mui/icons-material/DevicesOtherOutlined')),
    DevicesOtherRounded: React.lazy(() => import('@mui/icons-material/DevicesOtherRounded')),
    DevicesOtherSharp: React.lazy(() => import('@mui/icons-material/DevicesOtherSharp')),
    DevicesOtherTwoTone: React.lazy(() => import('@mui/icons-material/DevicesOtherTwoTone')),
    DevicesOutlined: React.lazy(() => import('@mui/icons-material/DevicesOutlined')),
    DevicesRounded: React.lazy(() => import('@mui/icons-material/DevicesRounded')),
    DevicesSharp: React.lazy(() => import('@mui/icons-material/DevicesSharp')),
    DevicesTwoTone: React.lazy(() => import('@mui/icons-material/DevicesTwoTone')),
    DeviceThermostat: React.lazy(() => import('@mui/icons-material/DeviceThermostat')),
    DeviceThermostatOutlined: React.lazy(() => import('@mui/icons-material/DeviceThermostatOutlined')),
    DeviceThermostatRounded: React.lazy(() => import('@mui/icons-material/DeviceThermostatRounded')),
    DeviceThermostatSharp: React.lazy(() => import('@mui/icons-material/DeviceThermostatSharp')),
    DeviceThermostatTwoTone: React.lazy(() => import('@mui/icons-material/DeviceThermostatTwoTone')),
    DeviceUnknown: React.lazy(() => import('@mui/icons-material/DeviceUnknown')),
    DeviceUnknownOutlined: React.lazy(() => import('@mui/icons-material/DeviceUnknownOutlined')),
    DeviceUnknownRounded: React.lazy(() => import('@mui/icons-material/DeviceUnknownRounded')),
    DeviceUnknownSharp: React.lazy(() => import('@mui/icons-material/DeviceUnknownSharp')),
    DeviceUnknownTwoTone: React.lazy(() => import('@mui/icons-material/DeviceUnknownTwoTone')),
    DialerSip: React.lazy(() => import('@mui/icons-material/DialerSip')),
    DialerSipOutlined: React.lazy(() => import('@mui/icons-material/DialerSipOutlined')),
    DialerSipRounded: React.lazy(() => import('@mui/icons-material/DialerSipRounded')),
    DialerSipSharp: React.lazy(() => import('@mui/icons-material/DialerSipSharp')),
    DialerSipTwoTone: React.lazy(() => import('@mui/icons-material/DialerSipTwoTone')),
    Dialpad: React.lazy(() => import('@mui/icons-material/Dialpad')),
    DialpadOutlined: React.lazy(() => import('@mui/icons-material/DialpadOutlined')),
    DialpadRounded: React.lazy(() => import('@mui/icons-material/DialpadRounded')),
    DialpadSharp: React.lazy(() => import('@mui/icons-material/DialpadSharp')),
    DialpadTwoTone: React.lazy(() => import('@mui/icons-material/DialpadTwoTone')),
    Diamond: React.lazy(() => import('@mui/icons-material/Diamond')),
    DiamondOutlined: React.lazy(() => import('@mui/icons-material/DiamondOutlined')),
    DiamondRounded: React.lazy(() => import('@mui/icons-material/DiamondRounded')),
    DiamondSharp: React.lazy(() => import('@mui/icons-material/DiamondSharp')),
    DiamondTwoTone: React.lazy(() => import('@mui/icons-material/DiamondTwoTone')),
    Difference: React.lazy(() => import('@mui/icons-material/Difference')),
    DifferenceOutlined: React.lazy(() => import('@mui/icons-material/DifferenceOutlined')),
    DifferenceRounded: React.lazy(() => import('@mui/icons-material/DifferenceRounded')),
    DifferenceSharp: React.lazy(() => import('@mui/icons-material/DifferenceSharp')),
    DifferenceTwoTone: React.lazy(() => import('@mui/icons-material/DifferenceTwoTone')),
    Dining: React.lazy(() => import('@mui/icons-material/Dining')),
    DiningOutlined: React.lazy(() => import('@mui/icons-material/DiningOutlined')),
    DiningRounded: React.lazy(() => import('@mui/icons-material/DiningRounded')),
    DiningSharp: React.lazy(() => import('@mui/icons-material/DiningSharp')),
    DiningTwoTone: React.lazy(() => import('@mui/icons-material/DiningTwoTone')),
    DinnerDining: React.lazy(() => import('@mui/icons-material/DinnerDining')),
    DinnerDiningOutlined: React.lazy(() => import('@mui/icons-material/DinnerDiningOutlined')),
    DinnerDiningRounded: React.lazy(() => import('@mui/icons-material/DinnerDiningRounded')),
    DinnerDiningSharp: React.lazy(() => import('@mui/icons-material/DinnerDiningSharp')),
    DinnerDiningTwoTone: React.lazy(() => import('@mui/icons-material/DinnerDiningTwoTone')),
    DirectionsBike: React.lazy(() => import('@mui/icons-material/DirectionsBike')),
    DirectionsBikeOutlined: React.lazy(() => import('@mui/icons-material/DirectionsBikeOutlined')),
    DirectionsBikeRounded: React.lazy(() => import('@mui/icons-material/DirectionsBikeRounded')),
    DirectionsBikeSharp: React.lazy(() => import('@mui/icons-material/DirectionsBikeSharp')),
    DirectionsBikeTwoTone: React.lazy(() => import('@mui/icons-material/DirectionsBikeTwoTone')),
    DirectionsBoat: React.lazy(() => import('@mui/icons-material/DirectionsBoat')),
    DirectionsBoatFilled: React.lazy(() => import('@mui/icons-material/DirectionsBoatFilled')),
    DirectionsBoatFilledOutlined: React.lazy(() => import('@mui/icons-material/DirectionsBoatFilledOutlined')),
    DirectionsBoatFilledRounded: React.lazy(() => import('@mui/icons-material/DirectionsBoatFilledRounded')),
    DirectionsBoatFilledSharp: React.lazy(() => import('@mui/icons-material/DirectionsBoatFilledSharp')),
    DirectionsBoatFilledTwoTone: React.lazy(() => import('@mui/icons-material/DirectionsBoatFilledTwoTone')),
    DirectionsBoatOutlined: React.lazy(() => import('@mui/icons-material/DirectionsBoatOutlined')),
    DirectionsBoatRounded: React.lazy(() => import('@mui/icons-material/DirectionsBoatRounded')),
    DirectionsBoatSharp: React.lazy(() => import('@mui/icons-material/DirectionsBoatSharp')),
    DirectionsBoatTwoTone: React.lazy(() => import('@mui/icons-material/DirectionsBoatTwoTone')),
    DirectionsBus: React.lazy(() => import('@mui/icons-material/DirectionsBus')),
    DirectionsBusFilled: React.lazy(() => import('@mui/icons-material/DirectionsBusFilled')),
    DirectionsBusFilledOutlined: React.lazy(() => import('@mui/icons-material/DirectionsBusFilledOutlined')),
    DirectionsBusFilledRounded: React.lazy(() => import('@mui/icons-material/DirectionsBusFilledRounded')),
    DirectionsBusFilledSharp: React.lazy(() => import('@mui/icons-material/DirectionsBusFilledSharp')),
    DirectionsBusFilledTwoTone: React.lazy(() => import('@mui/icons-material/DirectionsBusFilledTwoTone')),
    DirectionsBusOutlined: React.lazy(() => import('@mui/icons-material/DirectionsBusOutlined')),
    DirectionsBusRounded: React.lazy(() => import('@mui/icons-material/DirectionsBusRounded')),
    DirectionsBusSharp: React.lazy(() => import('@mui/icons-material/DirectionsBusSharp')),
    DirectionsBusTwoTone: React.lazy(() => import('@mui/icons-material/DirectionsBusTwoTone')),
    DirectionsCar: React.lazy(() => import('@mui/icons-material/DirectionsCar')),
    DirectionsCarFilled: React.lazy(() => import('@mui/icons-material/DirectionsCarFilled')),
    DirectionsCarFilledOutlined: React.lazy(() => import('@mui/icons-material/DirectionsCarFilledOutlined')),
    DirectionsCarFilledRounded: React.lazy(() => import('@mui/icons-material/DirectionsCarFilledRounded')),
    DirectionsCarFilledSharp: React.lazy(() => import('@mui/icons-material/DirectionsCarFilledSharp')),
    DirectionsCarFilledTwoTone: React.lazy(() => import('@mui/icons-material/DirectionsCarFilledTwoTone')),
    DirectionsCarOutlined: React.lazy(() => import('@mui/icons-material/DirectionsCarOutlined')),
    DirectionsCarRounded: React.lazy(() => import('@mui/icons-material/DirectionsCarRounded')),
    DirectionsCarSharp: React.lazy(() => import('@mui/icons-material/DirectionsCarSharp')),
    DirectionsCarTwoTone: React.lazy(() => import('@mui/icons-material/DirectionsCarTwoTone')),
    Directions: React.lazy(() => import('@mui/icons-material/Directions')),
    DirectionsOff: React.lazy(() => import('@mui/icons-material/DirectionsOff')),
    DirectionsOffOutlined: React.lazy(() => import('@mui/icons-material/DirectionsOffOutlined')),
    DirectionsOffRounded: React.lazy(() => import('@mui/icons-material/DirectionsOffRounded')),
    DirectionsOffSharp: React.lazy(() => import('@mui/icons-material/DirectionsOffSharp')),
    DirectionsOffTwoTone: React.lazy(() => import('@mui/icons-material/DirectionsOffTwoTone')),
    DirectionsOutlined: React.lazy(() => import('@mui/icons-material/DirectionsOutlined')),
    DirectionsRailway: React.lazy(() => import('@mui/icons-material/DirectionsRailway')),
    DirectionsRailwayFilled: React.lazy(() => import('@mui/icons-material/DirectionsRailwayFilled')),
    DirectionsRailwayFilledOutlined: React.lazy(() => import('@mui/icons-material/DirectionsRailwayFilledOutlined')),
    DirectionsRailwayFilledRounded: React.lazy(() => import('@mui/icons-material/DirectionsRailwayFilledRounded')),
    DirectionsRailwayFilledSharp: React.lazy(() => import('@mui/icons-material/DirectionsRailwayFilledSharp')),
    DirectionsRailwayFilledTwoTone: React.lazy(() => import('@mui/icons-material/DirectionsRailwayFilledTwoTone')),
    DirectionsRailwayOutlined: React.lazy(() => import('@mui/icons-material/DirectionsRailwayOutlined')),
    DirectionsRailwayRounded: React.lazy(() => import('@mui/icons-material/DirectionsRailwayRounded')),
    DirectionsRailwaySharp: React.lazy(() => import('@mui/icons-material/DirectionsRailwaySharp')),
    DirectionsRailwayTwoTone: React.lazy(() => import('@mui/icons-material/DirectionsRailwayTwoTone')),
    DirectionsRounded: React.lazy(() => import('@mui/icons-material/DirectionsRounded')),
    DirectionsRun: React.lazy(() => import('@mui/icons-material/DirectionsRun')),
    DirectionsRunOutlined: React.lazy(() => import('@mui/icons-material/DirectionsRunOutlined')),
    DirectionsRunRounded: React.lazy(() => import('@mui/icons-material/DirectionsRunRounded')),
    DirectionsRunSharp: React.lazy(() => import('@mui/icons-material/DirectionsRunSharp')),
    DirectionsRunTwoTone: React.lazy(() => import('@mui/icons-material/DirectionsRunTwoTone')),
    DirectionsSharp: React.lazy(() => import('@mui/icons-material/DirectionsSharp')),
    DirectionsSubway: React.lazy(() => import('@mui/icons-material/DirectionsSubway')),
    DirectionsSubwayFilled: React.lazy(() => import('@mui/icons-material/DirectionsSubwayFilled')),
    DirectionsSubwayFilledOutlined: React.lazy(() => import('@mui/icons-material/DirectionsSubwayFilledOutlined')),
    DirectionsSubwayFilledRounded: React.lazy(() => import('@mui/icons-material/DirectionsSubwayFilledRounded')),
    DirectionsSubwayFilledSharp: React.lazy(() => import('@mui/icons-material/DirectionsSubwayFilledSharp')),
    DirectionsSubwayFilledTwoTone: React.lazy(() => import('@mui/icons-material/DirectionsSubwayFilledTwoTone')),
    DirectionsSubwayOutlined: React.lazy(() => import('@mui/icons-material/DirectionsSubwayOutlined')),
    DirectionsSubwayRounded: React.lazy(() => import('@mui/icons-material/DirectionsSubwayRounded')),
    DirectionsSubwaySharp: React.lazy(() => import('@mui/icons-material/DirectionsSubwaySharp')),
    DirectionsSubwayTwoTone: React.lazy(() => import('@mui/icons-material/DirectionsSubwayTwoTone')),
    DirectionsTransit: React.lazy(() => import('@mui/icons-material/DirectionsTransit')),
    DirectionsTransitFilled: React.lazy(() => import('@mui/icons-material/DirectionsTransitFilled')),
    DirectionsTransitFilledOutlined: React.lazy(() => import('@mui/icons-material/DirectionsTransitFilledOutlined')),
    DirectionsTransitFilledRounded: React.lazy(() => import('@mui/icons-material/DirectionsTransitFilledRounded')),
    DirectionsTransitFilledSharp: React.lazy(() => import('@mui/icons-material/DirectionsTransitFilledSharp')),
    DirectionsTransitFilledTwoTone: React.lazy(() => import('@mui/icons-material/DirectionsTransitFilledTwoTone')),
    DirectionsTransitOutlined: React.lazy(() => import('@mui/icons-material/DirectionsTransitOutlined')),
    DirectionsTransitRounded: React.lazy(() => import('@mui/icons-material/DirectionsTransitRounded')),
    DirectionsTransitSharp: React.lazy(() => import('@mui/icons-material/DirectionsTransitSharp')),
    DirectionsTransitTwoTone: React.lazy(() => import('@mui/icons-material/DirectionsTransitTwoTone')),
    DirectionsTwoTone: React.lazy(() => import('@mui/icons-material/DirectionsTwoTone')),
    DirectionsWalk: React.lazy(() => import('@mui/icons-material/DirectionsWalk')),
    DirectionsWalkOutlined: React.lazy(() => import('@mui/icons-material/DirectionsWalkOutlined')),
    DirectionsWalkRounded: React.lazy(() => import('@mui/icons-material/DirectionsWalkRounded')),
    DirectionsWalkSharp: React.lazy(() => import('@mui/icons-material/DirectionsWalkSharp')),
    DirectionsWalkTwoTone: React.lazy(() => import('@mui/icons-material/DirectionsWalkTwoTone')),
    DirtyLens: React.lazy(() => import('@mui/icons-material/DirtyLens')),
    DirtyLensOutlined: React.lazy(() => import('@mui/icons-material/DirtyLensOutlined')),
    DirtyLensRounded: React.lazy(() => import('@mui/icons-material/DirtyLensRounded')),
    DirtyLensSharp: React.lazy(() => import('@mui/icons-material/DirtyLensSharp')),
    DirtyLensTwoTone: React.lazy(() => import('@mui/icons-material/DirtyLensTwoTone')),
    DisabledByDefault: React.lazy(() => import('@mui/icons-material/DisabledByDefault')),
    DisabledByDefaultOutlined: React.lazy(() => import('@mui/icons-material/DisabledByDefaultOutlined')),
    DisabledByDefaultRounded: React.lazy(() => import('@mui/icons-material/DisabledByDefaultRounded')),
    DisabledByDefaultSharp: React.lazy(() => import('@mui/icons-material/DisabledByDefaultSharp')),
    DisabledByDefaultTwoTone: React.lazy(() => import('@mui/icons-material/DisabledByDefaultTwoTone')),
    DiscFull: React.lazy(() => import('@mui/icons-material/DiscFull')),
    DiscFullOutlined: React.lazy(() => import('@mui/icons-material/DiscFullOutlined')),
    DiscFullRounded: React.lazy(() => import('@mui/icons-material/DiscFullRounded')),
    DiscFullSharp: React.lazy(() => import('@mui/icons-material/DiscFullSharp')),
    DiscFullTwoTone: React.lazy(() => import('@mui/icons-material/DiscFullTwoTone')),
    Discount: React.lazy(() => import('@mui/icons-material/Discount')),
    DiscountOutlined: React.lazy(() => import('@mui/icons-material/DiscountOutlined')),
    DiscountRounded: React.lazy(() => import('@mui/icons-material/DiscountRounded')),
    DiscountSharp: React.lazy(() => import('@mui/icons-material/DiscountSharp')),
    DiscountTwoTone: React.lazy(() => import('@mui/icons-material/DiscountTwoTone')),
    DisplaySettings: React.lazy(() => import('@mui/icons-material/DisplaySettings')),
    DisplaySettingsOutlined: React.lazy(() => import('@mui/icons-material/DisplaySettingsOutlined')),
    DisplaySettingsRounded: React.lazy(() => import('@mui/icons-material/DisplaySettingsRounded')),
    DisplaySettingsSharp: React.lazy(() => import('@mui/icons-material/DisplaySettingsSharp')),
    DisplaySettingsTwoTone: React.lazy(() => import('@mui/icons-material/DisplaySettingsTwoTone')),
    Diversity1: React.lazy(() => import('@mui/icons-material/Diversity1')),
    Diversity1Outlined: React.lazy(() => import('@mui/icons-material/Diversity1Outlined')),
    Diversity1Rounded: React.lazy(() => import('@mui/icons-material/Diversity1Rounded')),
    Diversity1Sharp: React.lazy(() => import('@mui/icons-material/Diversity1Sharp')),
    Diversity1TwoTone: React.lazy(() => import('@mui/icons-material/Diversity1TwoTone')),
    Diversity2: React.lazy(() => import('@mui/icons-material/Diversity2')),
    Diversity2Outlined: React.lazy(() => import('@mui/icons-material/Diversity2Outlined')),
    Diversity2Rounded: React.lazy(() => import('@mui/icons-material/Diversity2Rounded')),
    Diversity2Sharp: React.lazy(() => import('@mui/icons-material/Diversity2Sharp')),
    Diversity2TwoTone: React.lazy(() => import('@mui/icons-material/Diversity2TwoTone')),
    Diversity3: React.lazy(() => import('@mui/icons-material/Diversity3')),
    Diversity3Outlined: React.lazy(() => import('@mui/icons-material/Diversity3Outlined')),
    Diversity3Rounded: React.lazy(() => import('@mui/icons-material/Diversity3Rounded')),
    Diversity3Sharp: React.lazy(() => import('@mui/icons-material/Diversity3Sharp')),
    Diversity3TwoTone: React.lazy(() => import('@mui/icons-material/Diversity3TwoTone')),
    Dns: React.lazy(() => import('@mui/icons-material/Dns')),
    DnsOutlined: React.lazy(() => import('@mui/icons-material/DnsOutlined')),
    DnsRounded: React.lazy(() => import('@mui/icons-material/DnsRounded')),
    DnsSharp: React.lazy(() => import('@mui/icons-material/DnsSharp')),
    DnsTwoTone: React.lazy(() => import('@mui/icons-material/DnsTwoTone')),
    Dock: React.lazy(() => import('@mui/icons-material/Dock')),
    DockOutlined: React.lazy(() => import('@mui/icons-material/DockOutlined')),
    DockRounded: React.lazy(() => import('@mui/icons-material/DockRounded')),
    DockSharp: React.lazy(() => import('@mui/icons-material/DockSharp')),
    DockTwoTone: React.lazy(() => import('@mui/icons-material/DockTwoTone')),
    DocumentScanner: React.lazy(() => import('@mui/icons-material/DocumentScanner')),
    DocumentScannerOutlined: React.lazy(() => import('@mui/icons-material/DocumentScannerOutlined')),
    DocumentScannerRounded: React.lazy(() => import('@mui/icons-material/DocumentScannerRounded')),
    DocumentScannerSharp: React.lazy(() => import('@mui/icons-material/DocumentScannerSharp')),
    DocumentScannerTwoTone: React.lazy(() => import('@mui/icons-material/DocumentScannerTwoTone')),
    DoDisturbAlt: React.lazy(() => import('@mui/icons-material/DoDisturbAlt')),
    DoDisturbAltOutlined: React.lazy(() => import('@mui/icons-material/DoDisturbAltOutlined')),
    DoDisturbAltRounded: React.lazy(() => import('@mui/icons-material/DoDisturbAltRounded')),
    DoDisturbAltSharp: React.lazy(() => import('@mui/icons-material/DoDisturbAltSharp')),
    DoDisturbAltTwoTone: React.lazy(() => import('@mui/icons-material/DoDisturbAltTwoTone')),
    DoDisturb: React.lazy(() => import('@mui/icons-material/DoDisturb')),
    DoDisturbOff: React.lazy(() => import('@mui/icons-material/DoDisturbOff')),
    DoDisturbOffOutlined: React.lazy(() => import('@mui/icons-material/DoDisturbOffOutlined')),
    DoDisturbOffRounded: React.lazy(() => import('@mui/icons-material/DoDisturbOffRounded')),
    DoDisturbOffSharp: React.lazy(() => import('@mui/icons-material/DoDisturbOffSharp')),
    DoDisturbOffTwoTone: React.lazy(() => import('@mui/icons-material/DoDisturbOffTwoTone')),
    DoDisturbOn: React.lazy(() => import('@mui/icons-material/DoDisturbOn')),
    DoDisturbOnOutlined: React.lazy(() => import('@mui/icons-material/DoDisturbOnOutlined')),
    DoDisturbOnRounded: React.lazy(() => import('@mui/icons-material/DoDisturbOnRounded')),
    DoDisturbOnSharp: React.lazy(() => import('@mui/icons-material/DoDisturbOnSharp')),
    DoDisturbOnTwoTone: React.lazy(() => import('@mui/icons-material/DoDisturbOnTwoTone')),
    DoDisturbOutlined: React.lazy(() => import('@mui/icons-material/DoDisturbOutlined')),
    DoDisturbRounded: React.lazy(() => import('@mui/icons-material/DoDisturbRounded')),
    DoDisturbSharp: React.lazy(() => import('@mui/icons-material/DoDisturbSharp')),
    DoDisturbTwoTone: React.lazy(() => import('@mui/icons-material/DoDisturbTwoTone')),
    DomainAdd: React.lazy(() => import('@mui/icons-material/DomainAdd')),
    DomainAddOutlined: React.lazy(() => import('@mui/icons-material/DomainAddOutlined')),
    DomainAddRounded: React.lazy(() => import('@mui/icons-material/DomainAddRounded')),
    DomainAddSharp: React.lazy(() => import('@mui/icons-material/DomainAddSharp')),
    DomainAddTwoTone: React.lazy(() => import('@mui/icons-material/DomainAddTwoTone')),
    DomainDisabled: React.lazy(() => import('@mui/icons-material/DomainDisabled')),
    DomainDisabledOutlined: React.lazy(() => import('@mui/icons-material/DomainDisabledOutlined')),
    DomainDisabledRounded: React.lazy(() => import('@mui/icons-material/DomainDisabledRounded')),
    DomainDisabledSharp: React.lazy(() => import('@mui/icons-material/DomainDisabledSharp')),
    DomainDisabledTwoTone: React.lazy(() => import('@mui/icons-material/DomainDisabledTwoTone')),
    Domain: React.lazy(() => import('@mui/icons-material/Domain')),
    DomainOutlined: React.lazy(() => import('@mui/icons-material/DomainOutlined')),
    DomainRounded: React.lazy(() => import('@mui/icons-material/DomainRounded')),
    DomainSharp: React.lazy(() => import('@mui/icons-material/DomainSharp')),
    DomainTwoTone: React.lazy(() => import('@mui/icons-material/DomainTwoTone')),
    DomainVerification: React.lazy(() => import('@mui/icons-material/DomainVerification')),
    DomainVerificationOutlined: React.lazy(() => import('@mui/icons-material/DomainVerificationOutlined')),
    DomainVerificationRounded: React.lazy(() => import('@mui/icons-material/DomainVerificationRounded')),
    DomainVerificationSharp: React.lazy(() => import('@mui/icons-material/DomainVerificationSharp')),
    DomainVerificationTwoTone: React.lazy(() => import('@mui/icons-material/DomainVerificationTwoTone')),
    DoneAll: React.lazy(() => import('@mui/icons-material/DoneAll')),
    DoneAllOutlined: React.lazy(() => import('@mui/icons-material/DoneAllOutlined')),
    DoneAllRounded: React.lazy(() => import('@mui/icons-material/DoneAllRounded')),
    DoneAllSharp: React.lazy(() => import('@mui/icons-material/DoneAllSharp')),
    DoneAllTwoTone: React.lazy(() => import('@mui/icons-material/DoneAllTwoTone')),
    Done: React.lazy(() => import('@mui/icons-material/Done')),
    DoneOutlined: React.lazy(() => import('@mui/icons-material/DoneOutlined')),
    DoneOutline: React.lazy(() => import('@mui/icons-material/DoneOutline')),
    DoneOutlineOutlined: React.lazy(() => import('@mui/icons-material/DoneOutlineOutlined')),
    DoneOutlineRounded: React.lazy(() => import('@mui/icons-material/DoneOutlineRounded')),
    DoneOutlineSharp: React.lazy(() => import('@mui/icons-material/DoneOutlineSharp')),
    DoneOutlineTwoTone: React.lazy(() => import('@mui/icons-material/DoneOutlineTwoTone')),
    DoneRounded: React.lazy(() => import('@mui/icons-material/DoneRounded')),
    DoneSharp: React.lazy(() => import('@mui/icons-material/DoneSharp')),
    DoneTwoTone: React.lazy(() => import('@mui/icons-material/DoneTwoTone')),
    DoNotDisturbAlt: React.lazy(() => import('@mui/icons-material/DoNotDisturbAlt')),
    DoNotDisturbAltOutlined: React.lazy(() => import('@mui/icons-material/DoNotDisturbAltOutlined')),
    DoNotDisturbAltRounded: React.lazy(() => import('@mui/icons-material/DoNotDisturbAltRounded')),
    DoNotDisturbAltSharp: React.lazy(() => import('@mui/icons-material/DoNotDisturbAltSharp')),
    DoNotDisturbAltTwoTone: React.lazy(() => import('@mui/icons-material/DoNotDisturbAltTwoTone')),
    DoNotDisturb: React.lazy(() => import('@mui/icons-material/DoNotDisturb')),
    DoNotDisturbOff: React.lazy(() => import('@mui/icons-material/DoNotDisturbOff')),
    DoNotDisturbOffOutlined: React.lazy(() => import('@mui/icons-material/DoNotDisturbOffOutlined')),
    DoNotDisturbOffRounded: React.lazy(() => import('@mui/icons-material/DoNotDisturbOffRounded')),
    DoNotDisturbOffSharp: React.lazy(() => import('@mui/icons-material/DoNotDisturbOffSharp')),
    DoNotDisturbOffTwoTone: React.lazy(() => import('@mui/icons-material/DoNotDisturbOffTwoTone')),
    DoNotDisturbOn: React.lazy(() => import('@mui/icons-material/DoNotDisturbOn')),
    DoNotDisturbOnOutlined: React.lazy(() => import('@mui/icons-material/DoNotDisturbOnOutlined')),
    DoNotDisturbOnRounded: React.lazy(() => import('@mui/icons-material/DoNotDisturbOnRounded')),
    DoNotDisturbOnSharp: React.lazy(() => import('@mui/icons-material/DoNotDisturbOnSharp')),
    DoNotDisturbOnTotalSilence: React.lazy(() => import('@mui/icons-material/DoNotDisturbOnTotalSilence')),
    DoNotDisturbOnTotalSilenceOutlined: React.lazy(() => import('@mui/icons-material/DoNotDisturbOnTotalSilenceOutlined')),
    DoNotDisturbOnTotalSilenceRounded: React.lazy(() => import('@mui/icons-material/DoNotDisturbOnTotalSilenceRounded')),
    DoNotDisturbOnTotalSilenceSharp: React.lazy(() => import('@mui/icons-material/DoNotDisturbOnTotalSilenceSharp')),
    DoNotDisturbOnTotalSilenceTwoTone: React.lazy(() => import('@mui/icons-material/DoNotDisturbOnTotalSilenceTwoTone')),
    DoNotDisturbOnTwoTone: React.lazy(() => import('@mui/icons-material/DoNotDisturbOnTwoTone')),
    DoNotDisturbOutlined: React.lazy(() => import('@mui/icons-material/DoNotDisturbOutlined')),
    DoNotDisturbRounded: React.lazy(() => import('@mui/icons-material/DoNotDisturbRounded')),
    DoNotDisturbSharp: React.lazy(() => import('@mui/icons-material/DoNotDisturbSharp')),
    DoNotDisturbTwoTone: React.lazy(() => import('@mui/icons-material/DoNotDisturbTwoTone')),
    DoNotStep: React.lazy(() => import('@mui/icons-material/DoNotStep')),
    DoNotStepOutlined: React.lazy(() => import('@mui/icons-material/DoNotStepOutlined')),
    DoNotStepRounded: React.lazy(() => import('@mui/icons-material/DoNotStepRounded')),
    DoNotStepSharp: React.lazy(() => import('@mui/icons-material/DoNotStepSharp')),
    DoNotStepTwoTone: React.lazy(() => import('@mui/icons-material/DoNotStepTwoTone')),
    DoNotTouch: React.lazy(() => import('@mui/icons-material/DoNotTouch')),
    DoNotTouchOutlined: React.lazy(() => import('@mui/icons-material/DoNotTouchOutlined')),
    DoNotTouchRounded: React.lazy(() => import('@mui/icons-material/DoNotTouchRounded')),
    DoNotTouchSharp: React.lazy(() => import('@mui/icons-material/DoNotTouchSharp')),
    DoNotTouchTwoTone: React.lazy(() => import('@mui/icons-material/DoNotTouchTwoTone')),
    DonutLarge: React.lazy(() => import('@mui/icons-material/DonutLarge')),
    DonutLargeOutlined: React.lazy(() => import('@mui/icons-material/DonutLargeOutlined')),
    DonutLargeRounded: React.lazy(() => import('@mui/icons-material/DonutLargeRounded')),
    DonutLargeSharp: React.lazy(() => import('@mui/icons-material/DonutLargeSharp')),
    DonutLargeTwoTone: React.lazy(() => import('@mui/icons-material/DonutLargeTwoTone')),
    DonutSmall: React.lazy(() => import('@mui/icons-material/DonutSmall')),
    DonutSmallOutlined: React.lazy(() => import('@mui/icons-material/DonutSmallOutlined')),
    DonutSmallRounded: React.lazy(() => import('@mui/icons-material/DonutSmallRounded')),
    DonutSmallSharp: React.lazy(() => import('@mui/icons-material/DonutSmallSharp')),
    DonutSmallTwoTone: React.lazy(() => import('@mui/icons-material/DonutSmallTwoTone')),
    DoorBack: React.lazy(() => import('@mui/icons-material/DoorBack')),
    DoorBackOutlined: React.lazy(() => import('@mui/icons-material/DoorBackOutlined')),
    DoorBackRounded: React.lazy(() => import('@mui/icons-material/DoorBackRounded')),
    DoorBackSharp: React.lazy(() => import('@mui/icons-material/DoorBackSharp')),
    DoorBackTwoTone: React.lazy(() => import('@mui/icons-material/DoorBackTwoTone')),
    Doorbell: React.lazy(() => import('@mui/icons-material/Doorbell')),
    DoorbellOutlined: React.lazy(() => import('@mui/icons-material/DoorbellOutlined')),
    DoorbellRounded: React.lazy(() => import('@mui/icons-material/DoorbellRounded')),
    DoorbellSharp: React.lazy(() => import('@mui/icons-material/DoorbellSharp')),
    DoorbellTwoTone: React.lazy(() => import('@mui/icons-material/DoorbellTwoTone')),
    DoorFront: React.lazy(() => import('@mui/icons-material/DoorFront')),
    DoorFrontOutlined: React.lazy(() => import('@mui/icons-material/DoorFrontOutlined')),
    DoorFrontRounded: React.lazy(() => import('@mui/icons-material/DoorFrontRounded')),
    DoorFrontSharp: React.lazy(() => import('@mui/icons-material/DoorFrontSharp')),
    DoorFrontTwoTone: React.lazy(() => import('@mui/icons-material/DoorFrontTwoTone')),
    DoorSliding: React.lazy(() => import('@mui/icons-material/DoorSliding')),
    DoorSlidingOutlined: React.lazy(() => import('@mui/icons-material/DoorSlidingOutlined')),
    DoorSlidingRounded: React.lazy(() => import('@mui/icons-material/DoorSlidingRounded')),
    DoorSlidingSharp: React.lazy(() => import('@mui/icons-material/DoorSlidingSharp')),
    DoorSlidingTwoTone: React.lazy(() => import('@mui/icons-material/DoorSlidingTwoTone')),
    DoubleArrow: React.lazy(() => import('@mui/icons-material/DoubleArrow')),
    DoubleArrowOutlined: React.lazy(() => import('@mui/icons-material/DoubleArrowOutlined')),
    DoubleArrowRounded: React.lazy(() => import('@mui/icons-material/DoubleArrowRounded')),
    DoubleArrowSharp: React.lazy(() => import('@mui/icons-material/DoubleArrowSharp')),
    DoubleArrowTwoTone: React.lazy(() => import('@mui/icons-material/DoubleArrowTwoTone')),
    DownhillSkiing: React.lazy(() => import('@mui/icons-material/DownhillSkiing')),
    DownhillSkiingOutlined: React.lazy(() => import('@mui/icons-material/DownhillSkiingOutlined')),
    DownhillSkiingRounded: React.lazy(() => import('@mui/icons-material/DownhillSkiingRounded')),
    DownhillSkiingSharp: React.lazy(() => import('@mui/icons-material/DownhillSkiingSharp')),
    DownhillSkiingTwoTone: React.lazy(() => import('@mui/icons-material/DownhillSkiingTwoTone')),
    DownloadDone: React.lazy(() => import('@mui/icons-material/DownloadDone')),
    DownloadDoneOutlined: React.lazy(() => import('@mui/icons-material/DownloadDoneOutlined')),
    DownloadDoneRounded: React.lazy(() => import('@mui/icons-material/DownloadDoneRounded')),
    DownloadDoneSharp: React.lazy(() => import('@mui/icons-material/DownloadDoneSharp')),
    DownloadDoneTwoTone: React.lazy(() => import('@mui/icons-material/DownloadDoneTwoTone')),
    Download: React.lazy(() => import('@mui/icons-material/Download')),
    DownloadForOffline: React.lazy(() => import('@mui/icons-material/DownloadForOffline')),
    DownloadForOfflineOutlined: React.lazy(() => import('@mui/icons-material/DownloadForOfflineOutlined')),
    DownloadForOfflineRounded: React.lazy(() => import('@mui/icons-material/DownloadForOfflineRounded')),
    DownloadForOfflineSharp: React.lazy(() => import('@mui/icons-material/DownloadForOfflineSharp')),
    DownloadForOfflineTwoTone: React.lazy(() => import('@mui/icons-material/DownloadForOfflineTwoTone')),
    Downloading: React.lazy(() => import('@mui/icons-material/Downloading')),
    DownloadingOutlined: React.lazy(() => import('@mui/icons-material/DownloadingOutlined')),
    DownloadingRounded: React.lazy(() => import('@mui/icons-material/DownloadingRounded')),
    DownloadingSharp: React.lazy(() => import('@mui/icons-material/DownloadingSharp')),
    DownloadingTwoTone: React.lazy(() => import('@mui/icons-material/DownloadingTwoTone')),
    DownloadOutlined: React.lazy(() => import('@mui/icons-material/DownloadOutlined')),
    DownloadRounded: React.lazy(() => import('@mui/icons-material/DownloadRounded')),
    DownloadSharp: React.lazy(() => import('@mui/icons-material/DownloadSharp')),
    DownloadTwoTone: React.lazy(() => import('@mui/icons-material/DownloadTwoTone')),
    Drafts: React.lazy(() => import('@mui/icons-material/Drafts')),
    DraftsOutlined: React.lazy(() => import('@mui/icons-material/DraftsOutlined')),
    DraftsRounded: React.lazy(() => import('@mui/icons-material/DraftsRounded')),
    DraftsSharp: React.lazy(() => import('@mui/icons-material/DraftsSharp')),
    DraftsTwoTone: React.lazy(() => import('@mui/icons-material/DraftsTwoTone')),
    DragHandle: React.lazy(() => import('@mui/icons-material/DragHandle')),
    DragHandleOutlined: React.lazy(() => import('@mui/icons-material/DragHandleOutlined')),
    DragHandleRounded: React.lazy(() => import('@mui/icons-material/DragHandleRounded')),
    DragHandleSharp: React.lazy(() => import('@mui/icons-material/DragHandleSharp')),
    DragHandleTwoTone: React.lazy(() => import('@mui/icons-material/DragHandleTwoTone')),
    DragIndicator: React.lazy(() => import('@mui/icons-material/DragIndicator')),
    DragIndicatorOutlined: React.lazy(() => import('@mui/icons-material/DragIndicatorOutlined')),
    DragIndicatorRounded: React.lazy(() => import('@mui/icons-material/DragIndicatorRounded')),
    DragIndicatorSharp: React.lazy(() => import('@mui/icons-material/DragIndicatorSharp')),
    DragIndicatorTwoTone: React.lazy(() => import('@mui/icons-material/DragIndicatorTwoTone')),
    DriveEta: React.lazy(() => import('@mui/icons-material/DriveEta')),
    DriveEtaOutlined: React.lazy(() => import('@mui/icons-material/DriveEtaOutlined')),
    DriveEtaRounded: React.lazy(() => import('@mui/icons-material/DriveEtaRounded')),
    DriveEtaSharp: React.lazy(() => import('@mui/icons-material/DriveEtaSharp')),
    DriveEtaTwoTone: React.lazy(() => import('@mui/icons-material/DriveEtaTwoTone')),
    DriveFileMove: React.lazy(() => import('@mui/icons-material/DriveFileMove')),
    DriveFileMoveOutlined: React.lazy(() => import('@mui/icons-material/DriveFileMoveOutlined')),
    DriveFileMoveRounded: React.lazy(() => import('@mui/icons-material/DriveFileMoveRounded')),
    DriveFileMoveSharp: React.lazy(() => import('@mui/icons-material/DriveFileMoveSharp')),
    DriveFileMoveTwoTone: React.lazy(() => import('@mui/icons-material/DriveFileMoveTwoTone')),
    DriveFileRenameOutline: React.lazy(() => import('@mui/icons-material/DriveFileRenameOutline')),
    DriveFileRenameOutlineOutlined: React.lazy(() => import('@mui/icons-material/DriveFileRenameOutlineOutlined')),
    DriveFileRenameOutlineRounded: React.lazy(() => import('@mui/icons-material/DriveFileRenameOutlineRounded')),
    DriveFileRenameOutlineSharp: React.lazy(() => import('@mui/icons-material/DriveFileRenameOutlineSharp')),
    DriveFileRenameOutlineTwoTone: React.lazy(() => import('@mui/icons-material/DriveFileRenameOutlineTwoTone')),
    DriveFolderUpload: React.lazy(() => import('@mui/icons-material/DriveFolderUpload')),
    DriveFolderUploadOutlined: React.lazy(() => import('@mui/icons-material/DriveFolderUploadOutlined')),
    DriveFolderUploadRounded: React.lazy(() => import('@mui/icons-material/DriveFolderUploadRounded')),
    DriveFolderUploadSharp: React.lazy(() => import('@mui/icons-material/DriveFolderUploadSharp')),
    DriveFolderUploadTwoTone: React.lazy(() => import('@mui/icons-material/DriveFolderUploadTwoTone')),
    DryCleaning: React.lazy(() => import('@mui/icons-material/DryCleaning')),
    DryCleaningOutlined: React.lazy(() => import('@mui/icons-material/DryCleaningOutlined')),
    DryCleaningRounded: React.lazy(() => import('@mui/icons-material/DryCleaningRounded')),
    DryCleaningSharp: React.lazy(() => import('@mui/icons-material/DryCleaningSharp')),
    DryCleaningTwoTone: React.lazy(() => import('@mui/icons-material/DryCleaningTwoTone')),
    Dry: React.lazy(() => import('@mui/icons-material/Dry')),
    DryOutlined: React.lazy(() => import('@mui/icons-material/DryOutlined')),
    DryRounded: React.lazy(() => import('@mui/icons-material/DryRounded')),
    DrySharp: React.lazy(() => import('@mui/icons-material/DrySharp')),
    DryTwoTone: React.lazy(() => import('@mui/icons-material/DryTwoTone')),
    Duo: React.lazy(() => import('@mui/icons-material/Duo')),
    DuoOutlined: React.lazy(() => import('@mui/icons-material/DuoOutlined')),
    DuoRounded: React.lazy(() => import('@mui/icons-material/DuoRounded')),
    DuoSharp: React.lazy(() => import('@mui/icons-material/DuoSharp')),
    DuoTwoTone: React.lazy(() => import('@mui/icons-material/DuoTwoTone')),
    Dvr: React.lazy(() => import('@mui/icons-material/Dvr')),
    DvrOutlined: React.lazy(() => import('@mui/icons-material/DvrOutlined')),
    DvrRounded: React.lazy(() => import('@mui/icons-material/DvrRounded')),
    DvrSharp: React.lazy(() => import('@mui/icons-material/DvrSharp')),
    DvrTwoTone: React.lazy(() => import('@mui/icons-material/DvrTwoTone')),
    DynamicFeed: React.lazy(() => import('@mui/icons-material/DynamicFeed')),
    DynamicFeedOutlined: React.lazy(() => import('@mui/icons-material/DynamicFeedOutlined')),
    DynamicFeedRounded: React.lazy(() => import('@mui/icons-material/DynamicFeedRounded')),
    DynamicFeedSharp: React.lazy(() => import('@mui/icons-material/DynamicFeedSharp')),
    DynamicFeedTwoTone: React.lazy(() => import('@mui/icons-material/DynamicFeedTwoTone')),
    DynamicForm: React.lazy(() => import('@mui/icons-material/DynamicForm')),
    DynamicFormOutlined: React.lazy(() => import('@mui/icons-material/DynamicFormOutlined')),
    DynamicFormRounded: React.lazy(() => import('@mui/icons-material/DynamicFormRounded')),
    DynamicFormSharp: React.lazy(() => import('@mui/icons-material/DynamicFormSharp')),
    DynamicFormTwoTone: React.lazy(() => import('@mui/icons-material/DynamicFormTwoTone')),
    EarbudsBattery: React.lazy(() => import('@mui/icons-material/EarbudsBattery')),
    EarbudsBatteryOutlined: React.lazy(() => import('@mui/icons-material/EarbudsBatteryOutlined')),
    EarbudsBatteryRounded: React.lazy(() => import('@mui/icons-material/EarbudsBatteryRounded')),
    EarbudsBatterySharp: React.lazy(() => import('@mui/icons-material/EarbudsBatterySharp')),
    EarbudsBatteryTwoTone: React.lazy(() => import('@mui/icons-material/EarbudsBatteryTwoTone')),
    Earbuds: React.lazy(() => import('@mui/icons-material/Earbuds')),
    EarbudsOutlined: React.lazy(() => import('@mui/icons-material/EarbudsOutlined')),
    EarbudsRounded: React.lazy(() => import('@mui/icons-material/EarbudsRounded')),
    EarbudsSharp: React.lazy(() => import('@mui/icons-material/EarbudsSharp')),
    EarbudsTwoTone: React.lazy(() => import('@mui/icons-material/EarbudsTwoTone')),
    East: React.lazy(() => import('@mui/icons-material/East')),
    EastOutlined: React.lazy(() => import('@mui/icons-material/EastOutlined')),
    EastRounded: React.lazy(() => import('@mui/icons-material/EastRounded')),
    EastSharp: React.lazy(() => import('@mui/icons-material/EastSharp')),
    EastTwoTone: React.lazy(() => import('@mui/icons-material/EastTwoTone')),
    EdgesensorHigh: React.lazy(() => import('@mui/icons-material/EdgesensorHigh')),
    EdgesensorHighOutlined: React.lazy(() => import('@mui/icons-material/EdgesensorHighOutlined')),
    EdgesensorHighRounded: React.lazy(() => import('@mui/icons-material/EdgesensorHighRounded')),
    EdgesensorHighSharp: React.lazy(() => import('@mui/icons-material/EdgesensorHighSharp')),
    EdgesensorHighTwoTone: React.lazy(() => import('@mui/icons-material/EdgesensorHighTwoTone')),
    EdgesensorLow: React.lazy(() => import('@mui/icons-material/EdgesensorLow')),
    EdgesensorLowOutlined: React.lazy(() => import('@mui/icons-material/EdgesensorLowOutlined')),
    EdgesensorLowRounded: React.lazy(() => import('@mui/icons-material/EdgesensorLowRounded')),
    EdgesensorLowSharp: React.lazy(() => import('@mui/icons-material/EdgesensorLowSharp')),
    EdgesensorLowTwoTone: React.lazy(() => import('@mui/icons-material/EdgesensorLowTwoTone')),
    EditAttributes: React.lazy(() => import('@mui/icons-material/EditAttributes')),
    EditAttributesOutlined: React.lazy(() => import('@mui/icons-material/EditAttributesOutlined')),
    EditAttributesRounded: React.lazy(() => import('@mui/icons-material/EditAttributesRounded')),
    EditAttributesSharp: React.lazy(() => import('@mui/icons-material/EditAttributesSharp')),
    EditAttributesTwoTone: React.lazy(() => import('@mui/icons-material/EditAttributesTwoTone')),
    Edit: React.lazy(() => import('@mui/icons-material/Edit')),
    EditLocationAlt: React.lazy(() => import('@mui/icons-material/EditLocationAlt')),
    EditLocationAltOutlined: React.lazy(() => import('@mui/icons-material/EditLocationAltOutlined')),
    EditLocationAltRounded: React.lazy(() => import('@mui/icons-material/EditLocationAltRounded')),
    EditLocationAltSharp: React.lazy(() => import('@mui/icons-material/EditLocationAltSharp')),
    EditLocationAltTwoTone: React.lazy(() => import('@mui/icons-material/EditLocationAltTwoTone')),
    EditLocation: React.lazy(() => import('@mui/icons-material/EditLocation')),
    EditLocationOutlined: React.lazy(() => import('@mui/icons-material/EditLocationOutlined')),
    EditLocationRounded: React.lazy(() => import('@mui/icons-material/EditLocationRounded')),
    EditLocationSharp: React.lazy(() => import('@mui/icons-material/EditLocationSharp')),
    EditLocationTwoTone: React.lazy(() => import('@mui/icons-material/EditLocationTwoTone')),
    EditNotifications: React.lazy(() => import('@mui/icons-material/EditNotifications')),
    EditNotificationsOutlined: React.lazy(() => import('@mui/icons-material/EditNotificationsOutlined')),
    EditNotificationsRounded: React.lazy(() => import('@mui/icons-material/EditNotificationsRounded')),
    EditNotificationsSharp: React.lazy(() => import('@mui/icons-material/EditNotificationsSharp')),
    EditNotificationsTwoTone: React.lazy(() => import('@mui/icons-material/EditNotificationsTwoTone')),
    EditOff: React.lazy(() => import('@mui/icons-material/EditOff')),
    EditOffOutlined: React.lazy(() => import('@mui/icons-material/EditOffOutlined')),
    EditOffRounded: React.lazy(() => import('@mui/icons-material/EditOffRounded')),
    EditOffSharp: React.lazy(() => import('@mui/icons-material/EditOffSharp')),
    EditOffTwoTone: React.lazy(() => import('@mui/icons-material/EditOffTwoTone')),
    EditOutlined: React.lazy(() => import('@mui/icons-material/EditOutlined')),
    EditRoad: React.lazy(() => import('@mui/icons-material/EditRoad')),
    EditRoadOutlined: React.lazy(() => import('@mui/icons-material/EditRoadOutlined')),
    EditRoadRounded: React.lazy(() => import('@mui/icons-material/EditRoadRounded')),
    EditRoadSharp: React.lazy(() => import('@mui/icons-material/EditRoadSharp')),
    EditRoadTwoTone: React.lazy(() => import('@mui/icons-material/EditRoadTwoTone')),
    EditRounded: React.lazy(() => import('@mui/icons-material/EditRounded')),
    EditSharp: React.lazy(() => import('@mui/icons-material/EditSharp')),
    EditTwoTone: React.lazy(() => import('@mui/icons-material/EditTwoTone')),
    EggAlt: React.lazy(() => import('@mui/icons-material/EggAlt')),
    EggAltOutlined: React.lazy(() => import('@mui/icons-material/EggAltOutlined')),
    EggAltRounded: React.lazy(() => import('@mui/icons-material/EggAltRounded')),
    EggAltSharp: React.lazy(() => import('@mui/icons-material/EggAltSharp')),
    EggAltTwoTone: React.lazy(() => import('@mui/icons-material/EggAltTwoTone')),
    Egg: React.lazy(() => import('@mui/icons-material/Egg')),
    EggOutlined: React.lazy(() => import('@mui/icons-material/EggOutlined')),
    EggRounded: React.lazy(() => import('@mui/icons-material/EggRounded')),
    EggSharp: React.lazy(() => import('@mui/icons-material/EggSharp')),
    EggTwoTone: React.lazy(() => import('@mui/icons-material/EggTwoTone')),
    EighteenMp: React.lazy(() => import('@mui/icons-material/EighteenMp')),
    EighteenMpOutlined: React.lazy(() => import('@mui/icons-material/EighteenMpOutlined')),
    EighteenMpRounded: React.lazy(() => import('@mui/icons-material/EighteenMpRounded')),
    EighteenMpSharp: React.lazy(() => import('@mui/icons-material/EighteenMpSharp')),
    EighteenMpTwoTone: React.lazy(() => import('@mui/icons-material/EighteenMpTwoTone')),
    EighteenUpRating: React.lazy(() => import('@mui/icons-material/EighteenUpRating')),
    EighteenUpRatingOutlined: React.lazy(() => import('@mui/icons-material/EighteenUpRatingOutlined')),
    EighteenUpRatingRounded: React.lazy(() => import('@mui/icons-material/EighteenUpRatingRounded')),
    EighteenUpRatingSharp: React.lazy(() => import('@mui/icons-material/EighteenUpRatingSharp')),
    EighteenUpRatingTwoTone: React.lazy(() => import('@mui/icons-material/EighteenUpRatingTwoTone')),
    EightK: React.lazy(() => import('@mui/icons-material/EightK')),
    EightKOutlined: React.lazy(() => import('@mui/icons-material/EightKOutlined')),
    EightKPlus: React.lazy(() => import('@mui/icons-material/EightKPlus')),
    EightKPlusOutlined: React.lazy(() => import('@mui/icons-material/EightKPlusOutlined')),
    EightKPlusRounded: React.lazy(() => import('@mui/icons-material/EightKPlusRounded')),
    EightKPlusSharp: React.lazy(() => import('@mui/icons-material/EightKPlusSharp')),
    EightKPlusTwoTone: React.lazy(() => import('@mui/icons-material/EightKPlusTwoTone')),
    EightKRounded: React.lazy(() => import('@mui/icons-material/EightKRounded')),
    EightKSharp: React.lazy(() => import('@mui/icons-material/EightKSharp')),
    EightKTwoTone: React.lazy(() => import('@mui/icons-material/EightKTwoTone')),
    EightMp: React.lazy(() => import('@mui/icons-material/EightMp')),
    EightMpOutlined: React.lazy(() => import('@mui/icons-material/EightMpOutlined')),
    EightMpRounded: React.lazy(() => import('@mui/icons-material/EightMpRounded')),
    EightMpSharp: React.lazy(() => import('@mui/icons-material/EightMpSharp')),
    EightMpTwoTone: React.lazy(() => import('@mui/icons-material/EightMpTwoTone')),
    EightteenMp: React.lazy(() => import('@mui/icons-material/EightteenMp')),
    EightteenMpOutlined: React.lazy(() => import('@mui/icons-material/EightteenMpOutlined')),
    EightteenMpRounded: React.lazy(() => import('@mui/icons-material/EightteenMpRounded')),
    EightteenMpSharp: React.lazy(() => import('@mui/icons-material/EightteenMpSharp')),
    EightteenMpTwoTone: React.lazy(() => import('@mui/icons-material/EightteenMpTwoTone')),
    Eject: React.lazy(() => import('@mui/icons-material/Eject')),
    EjectOutlined: React.lazy(() => import('@mui/icons-material/EjectOutlined')),
    EjectRounded: React.lazy(() => import('@mui/icons-material/EjectRounded')),
    EjectSharp: React.lazy(() => import('@mui/icons-material/EjectSharp')),
    EjectTwoTone: React.lazy(() => import('@mui/icons-material/EjectTwoTone')),
    Elderly: React.lazy(() => import('@mui/icons-material/Elderly')),
    ElderlyOutlined: React.lazy(() => import('@mui/icons-material/ElderlyOutlined')),
    ElderlyRounded: React.lazy(() => import('@mui/icons-material/ElderlyRounded')),
    ElderlySharp: React.lazy(() => import('@mui/icons-material/ElderlySharp')),
    ElderlyTwoTone: React.lazy(() => import('@mui/icons-material/ElderlyTwoTone')),
    ElderlyWoman: React.lazy(() => import('@mui/icons-material/ElderlyWoman')),
    ElderlyWomanOutlined: React.lazy(() => import('@mui/icons-material/ElderlyWomanOutlined')),
    ElderlyWomanRounded: React.lazy(() => import('@mui/icons-material/ElderlyWomanRounded')),
    ElderlyWomanSharp: React.lazy(() => import('@mui/icons-material/ElderlyWomanSharp')),
    ElderlyWomanTwoTone: React.lazy(() => import('@mui/icons-material/ElderlyWomanTwoTone')),
    ElectricalServices: React.lazy(() => import('@mui/icons-material/ElectricalServices')),
    ElectricalServicesOutlined: React.lazy(() => import('@mui/icons-material/ElectricalServicesOutlined')),
    ElectricalServicesRounded: React.lazy(() => import('@mui/icons-material/ElectricalServicesRounded')),
    ElectricalServicesSharp: React.lazy(() => import('@mui/icons-material/ElectricalServicesSharp')),
    ElectricalServicesTwoTone: React.lazy(() => import('@mui/icons-material/ElectricalServicesTwoTone')),
    ElectricBike: React.lazy(() => import('@mui/icons-material/ElectricBike')),
    ElectricBikeOutlined: React.lazy(() => import('@mui/icons-material/ElectricBikeOutlined')),
    ElectricBikeRounded: React.lazy(() => import('@mui/icons-material/ElectricBikeRounded')),
    ElectricBikeSharp: React.lazy(() => import('@mui/icons-material/ElectricBikeSharp')),
    ElectricBikeTwoTone: React.lazy(() => import('@mui/icons-material/ElectricBikeTwoTone')),
    ElectricBolt: React.lazy(() => import('@mui/icons-material/ElectricBolt')),
    ElectricBoltOutlined: React.lazy(() => import('@mui/icons-material/ElectricBoltOutlined')),
    ElectricBoltRounded: React.lazy(() => import('@mui/icons-material/ElectricBoltRounded')),
    ElectricBoltSharp: React.lazy(() => import('@mui/icons-material/ElectricBoltSharp')),
    ElectricBoltTwoTone: React.lazy(() => import('@mui/icons-material/ElectricBoltTwoTone')),
    ElectricCar: React.lazy(() => import('@mui/icons-material/ElectricCar')),
    ElectricCarOutlined: React.lazy(() => import('@mui/icons-material/ElectricCarOutlined')),
    ElectricCarRounded: React.lazy(() => import('@mui/icons-material/ElectricCarRounded')),
    ElectricCarSharp: React.lazy(() => import('@mui/icons-material/ElectricCarSharp')),
    ElectricCarTwoTone: React.lazy(() => import('@mui/icons-material/ElectricCarTwoTone')),
    ElectricMeter: React.lazy(() => import('@mui/icons-material/ElectricMeter')),
    ElectricMeterOutlined: React.lazy(() => import('@mui/icons-material/ElectricMeterOutlined')),
    ElectricMeterRounded: React.lazy(() => import('@mui/icons-material/ElectricMeterRounded')),
    ElectricMeterSharp: React.lazy(() => import('@mui/icons-material/ElectricMeterSharp')),
    ElectricMeterTwoTone: React.lazy(() => import('@mui/icons-material/ElectricMeterTwoTone')),
    ElectricMoped: React.lazy(() => import('@mui/icons-material/ElectricMoped')),
    ElectricMopedOutlined: React.lazy(() => import('@mui/icons-material/ElectricMopedOutlined')),
    ElectricMopedRounded: React.lazy(() => import('@mui/icons-material/ElectricMopedRounded')),
    ElectricMopedSharp: React.lazy(() => import('@mui/icons-material/ElectricMopedSharp')),
    ElectricMopedTwoTone: React.lazy(() => import('@mui/icons-material/ElectricMopedTwoTone')),
    ElectricRickshaw: React.lazy(() => import('@mui/icons-material/ElectricRickshaw')),
    ElectricRickshawOutlined: React.lazy(() => import('@mui/icons-material/ElectricRickshawOutlined')),
    ElectricRickshawRounded: React.lazy(() => import('@mui/icons-material/ElectricRickshawRounded')),
    ElectricRickshawSharp: React.lazy(() => import('@mui/icons-material/ElectricRickshawSharp')),
    ElectricRickshawTwoTone: React.lazy(() => import('@mui/icons-material/ElectricRickshawTwoTone')),
    ElectricScooter: React.lazy(() => import('@mui/icons-material/ElectricScooter')),
    ElectricScooterOutlined: React.lazy(() => import('@mui/icons-material/ElectricScooterOutlined')),
    ElectricScooterRounded: React.lazy(() => import('@mui/icons-material/ElectricScooterRounded')),
    ElectricScooterSharp: React.lazy(() => import('@mui/icons-material/ElectricScooterSharp')),
    ElectricScooterTwoTone: React.lazy(() => import('@mui/icons-material/ElectricScooterTwoTone')),
    Elevator: React.lazy(() => import('@mui/icons-material/Elevator')),
    ElevatorOutlined: React.lazy(() => import('@mui/icons-material/ElevatorOutlined')),
    ElevatorRounded: React.lazy(() => import('@mui/icons-material/ElevatorRounded')),
    ElevatorSharp: React.lazy(() => import('@mui/icons-material/ElevatorSharp')),
    ElevatorTwoTone: React.lazy(() => import('@mui/icons-material/ElevatorTwoTone')),
    ElevenMp: React.lazy(() => import('@mui/icons-material/ElevenMp')),
    ElevenMpOutlined: React.lazy(() => import('@mui/icons-material/ElevenMpOutlined')),
    ElevenMpRounded: React.lazy(() => import('@mui/icons-material/ElevenMpRounded')),
    ElevenMpSharp: React.lazy(() => import('@mui/icons-material/ElevenMpSharp')),
    ElevenMpTwoTone: React.lazy(() => import('@mui/icons-material/ElevenMpTwoTone')),
    Email: React.lazy(() => import('@mui/icons-material/Email')),
    EmailOutlined: React.lazy(() => import('@mui/icons-material/EmailOutlined')),
    EmailRounded: React.lazy(() => import('@mui/icons-material/EmailRounded')),
    EmailSharp: React.lazy(() => import('@mui/icons-material/EmailSharp')),
    EmailTwoTone: React.lazy(() => import('@mui/icons-material/EmailTwoTone')),
    EmergencyRecording: React.lazy(() => import('@mui/icons-material/EmergencyRecording')),
    EmergencyRecordingOutlined: React.lazy(() => import('@mui/icons-material/EmergencyRecordingOutlined')),
    EmergencyRecordingRounded: React.lazy(() => import('@mui/icons-material/EmergencyRecordingRounded')),
    EmergencyRecordingSharp: React.lazy(() => import('@mui/icons-material/EmergencyRecordingSharp')),
    EmergencyRecordingTwoTone: React.lazy(() => import('@mui/icons-material/EmergencyRecordingTwoTone')),
    EmergencyShare: React.lazy(() => import('@mui/icons-material/EmergencyShare')),
    EmergencyShareOutlined: React.lazy(() => import('@mui/icons-material/EmergencyShareOutlined')),
    EmergencyShareRounded: React.lazy(() => import('@mui/icons-material/EmergencyShareRounded')),
    EmergencyShareSharp: React.lazy(() => import('@mui/icons-material/EmergencyShareSharp')),
    EmergencyShareTwoTone: React.lazy(() => import('@mui/icons-material/EmergencyShareTwoTone')),
    EMobiledata: React.lazy(() => import('@mui/icons-material/EMobiledata')),
    EMobiledataOutlined: React.lazy(() => import('@mui/icons-material/EMobiledataOutlined')),
    EMobiledataRounded: React.lazy(() => import('@mui/icons-material/EMobiledataRounded')),
    EMobiledataSharp: React.lazy(() => import('@mui/icons-material/EMobiledataSharp')),
    EMobiledataTwoTone: React.lazy(() => import('@mui/icons-material/EMobiledataTwoTone')),
    EmojiEmotions: React.lazy(() => import('@mui/icons-material/EmojiEmotions')),
    EmojiEmotionsOutlined: React.lazy(() => import('@mui/icons-material/EmojiEmotionsOutlined')),
    EmojiEmotionsRounded: React.lazy(() => import('@mui/icons-material/EmojiEmotionsRounded')),
    EmojiEmotionsSharp: React.lazy(() => import('@mui/icons-material/EmojiEmotionsSharp')),
    EmojiEmotionsTwoTone: React.lazy(() => import('@mui/icons-material/EmojiEmotionsTwoTone')),
    EmojiEvents: React.lazy(() => import('@mui/icons-material/EmojiEvents')),
    EmojiEventsOutlined: React.lazy(() => import('@mui/icons-material/EmojiEventsOutlined')),
    EmojiEventsRounded: React.lazy(() => import('@mui/icons-material/EmojiEventsRounded')),
    EmojiEventsSharp: React.lazy(() => import('@mui/icons-material/EmojiEventsSharp')),
    EmojiEventsTwoTone: React.lazy(() => import('@mui/icons-material/EmojiEventsTwoTone')),
    EmojiFlags: React.lazy(() => import('@mui/icons-material/EmojiFlags')),
    EmojiFlagsOutlined: React.lazy(() => import('@mui/icons-material/EmojiFlagsOutlined')),
    EmojiFlagsRounded: React.lazy(() => import('@mui/icons-material/EmojiFlagsRounded')),
    EmojiFlagsSharp: React.lazy(() => import('@mui/icons-material/EmojiFlagsSharp')),
    EmojiFlagsTwoTone: React.lazy(() => import('@mui/icons-material/EmojiFlagsTwoTone')),
    EmojiFoodBeverage: React.lazy(() => import('@mui/icons-material/EmojiFoodBeverage')),
    EmojiFoodBeverageOutlined: React.lazy(() => import('@mui/icons-material/EmojiFoodBeverageOutlined')),
    EmojiFoodBeverageRounded: React.lazy(() => import('@mui/icons-material/EmojiFoodBeverageRounded')),
    EmojiFoodBeverageSharp: React.lazy(() => import('@mui/icons-material/EmojiFoodBeverageSharp')),
    EmojiFoodBeverageTwoTone: React.lazy(() => import('@mui/icons-material/EmojiFoodBeverageTwoTone')),
    EmojiNature: React.lazy(() => import('@mui/icons-material/EmojiNature')),
    EmojiNatureOutlined: React.lazy(() => import('@mui/icons-material/EmojiNatureOutlined')),
    EmojiNatureRounded: React.lazy(() => import('@mui/icons-material/EmojiNatureRounded')),
    EmojiNatureSharp: React.lazy(() => import('@mui/icons-material/EmojiNatureSharp')),
    EmojiNatureTwoTone: React.lazy(() => import('@mui/icons-material/EmojiNatureTwoTone')),
    EmojiObjects: React.lazy(() => import('@mui/icons-material/EmojiObjects')),
    EmojiObjectsOutlined: React.lazy(() => import('@mui/icons-material/EmojiObjectsOutlined')),
    EmojiObjectsRounded: React.lazy(() => import('@mui/icons-material/EmojiObjectsRounded')),
    EmojiObjectsSharp: React.lazy(() => import('@mui/icons-material/EmojiObjectsSharp')),
    EmojiObjectsTwoTone: React.lazy(() => import('@mui/icons-material/EmojiObjectsTwoTone')),
    EmojiPeople: React.lazy(() => import('@mui/icons-material/EmojiPeople')),
    EmojiPeopleOutlined: React.lazy(() => import('@mui/icons-material/EmojiPeopleOutlined')),
    EmojiPeopleRounded: React.lazy(() => import('@mui/icons-material/EmojiPeopleRounded')),
    EmojiPeopleSharp: React.lazy(() => import('@mui/icons-material/EmojiPeopleSharp')),
    EmojiPeopleTwoTone: React.lazy(() => import('@mui/icons-material/EmojiPeopleTwoTone')),
    EmojiSymbols: React.lazy(() => import('@mui/icons-material/EmojiSymbols')),
    EmojiSymbolsOutlined: React.lazy(() => import('@mui/icons-material/EmojiSymbolsOutlined')),
    EmojiSymbolsRounded: React.lazy(() => import('@mui/icons-material/EmojiSymbolsRounded')),
    EmojiSymbolsSharp: React.lazy(() => import('@mui/icons-material/EmojiSymbolsSharp')),
    EmojiSymbolsTwoTone: React.lazy(() => import('@mui/icons-material/EmojiSymbolsTwoTone')),
    EmojiTransportation: React.lazy(() => import('@mui/icons-material/EmojiTransportation')),
    EmojiTransportationOutlined: React.lazy(() => import('@mui/icons-material/EmojiTransportationOutlined')),
    EmojiTransportationRounded: React.lazy(() => import('@mui/icons-material/EmojiTransportationRounded')),
    EmojiTransportationSharp: React.lazy(() => import('@mui/icons-material/EmojiTransportationSharp')),
    EmojiTransportationTwoTone: React.lazy(() => import('@mui/icons-material/EmojiTransportationTwoTone')),
    EnergySavingsLeaf: React.lazy(() => import('@mui/icons-material/EnergySavingsLeaf')),
    EnergySavingsLeafOutlined: React.lazy(() => import('@mui/icons-material/EnergySavingsLeafOutlined')),
    EnergySavingsLeafRounded: React.lazy(() => import('@mui/icons-material/EnergySavingsLeafRounded')),
    EnergySavingsLeafSharp: React.lazy(() => import('@mui/icons-material/EnergySavingsLeafSharp')),
    EnergySavingsLeafTwoTone: React.lazy(() => import('@mui/icons-material/EnergySavingsLeafTwoTone')),
    Engineering: React.lazy(() => import('@mui/icons-material/Engineering')),
    EngineeringOutlined: React.lazy(() => import('@mui/icons-material/EngineeringOutlined')),
    EngineeringRounded: React.lazy(() => import('@mui/icons-material/EngineeringRounded')),
    EngineeringSharp: React.lazy(() => import('@mui/icons-material/EngineeringSharp')),
    EngineeringTwoTone: React.lazy(() => import('@mui/icons-material/EngineeringTwoTone')),
    EnhancedEncryption: React.lazy(() => import('@mui/icons-material/EnhancedEncryption')),
    EnhancedEncryptionOutlined: React.lazy(() => import('@mui/icons-material/EnhancedEncryptionOutlined')),
    EnhancedEncryptionRounded: React.lazy(() => import('@mui/icons-material/EnhancedEncryptionRounded')),
    EnhancedEncryptionSharp: React.lazy(() => import('@mui/icons-material/EnhancedEncryptionSharp')),
    EnhancedEncryptionTwoTone: React.lazy(() => import('@mui/icons-material/EnhancedEncryptionTwoTone')),
    Equalizer: React.lazy(() => import('@mui/icons-material/Equalizer')),
    EqualizerOutlined: React.lazy(() => import('@mui/icons-material/EqualizerOutlined')),
    EqualizerRounded: React.lazy(() => import('@mui/icons-material/EqualizerRounded')),
    EqualizerSharp: React.lazy(() => import('@mui/icons-material/EqualizerSharp')),
    EqualizerTwoTone: React.lazy(() => import('@mui/icons-material/EqualizerTwoTone')),
    Error: React.lazy(() => import('@mui/icons-material/Error')),
    ErrorOutlined: React.lazy(() => import('@mui/icons-material/ErrorOutlined')),
    ErrorOutline: React.lazy(() => import('@mui/icons-material/ErrorOutline')),
    ErrorOutlineOutlined: React.lazy(() => import('@mui/icons-material/ErrorOutlineOutlined')),
    ErrorOutlineRounded: React.lazy(() => import('@mui/icons-material/ErrorOutlineRounded')),
    ErrorOutlineSharp: React.lazy(() => import('@mui/icons-material/ErrorOutlineSharp')),
    ErrorOutlineTwoTone: React.lazy(() => import('@mui/icons-material/ErrorOutlineTwoTone')),
    ErrorRounded: React.lazy(() => import('@mui/icons-material/ErrorRounded')),
    ErrorSharp: React.lazy(() => import('@mui/icons-material/ErrorSharp')),
    ErrorTwoTone: React.lazy(() => import('@mui/icons-material/ErrorTwoTone')),
    Escalator: React.lazy(() => import('@mui/icons-material/Escalator')),
    EscalatorOutlined: React.lazy(() => import('@mui/icons-material/EscalatorOutlined')),
    EscalatorRounded: React.lazy(() => import('@mui/icons-material/EscalatorRounded')),
    EscalatorSharp: React.lazy(() => import('@mui/icons-material/EscalatorSharp')),
    EscalatorTwoTone: React.lazy(() => import('@mui/icons-material/EscalatorTwoTone')),
    EscalatorWarning: React.lazy(() => import('@mui/icons-material/EscalatorWarning')),
    EscalatorWarningOutlined: React.lazy(() => import('@mui/icons-material/EscalatorWarningOutlined')),
    EscalatorWarningRounded: React.lazy(() => import('@mui/icons-material/EscalatorWarningRounded')),
    EscalatorWarningSharp: React.lazy(() => import('@mui/icons-material/EscalatorWarningSharp')),
    EscalatorWarningTwoTone: React.lazy(() => import('@mui/icons-material/EscalatorWarningTwoTone')),
    Euro: React.lazy(() => import('@mui/icons-material/Euro')),
    EuroOutlined: React.lazy(() => import('@mui/icons-material/EuroOutlined')),
    EuroRounded: React.lazy(() => import('@mui/icons-material/EuroRounded')),
    EuroSharp: React.lazy(() => import('@mui/icons-material/EuroSharp')),
    EuroSymbol: React.lazy(() => import('@mui/icons-material/EuroSymbol')),
    EuroSymbolOutlined: React.lazy(() => import('@mui/icons-material/EuroSymbolOutlined')),
    EuroSymbolRounded: React.lazy(() => import('@mui/icons-material/EuroSymbolRounded')),
    EuroSymbolSharp: React.lazy(() => import('@mui/icons-material/EuroSymbolSharp')),
    EuroSymbolTwoTone: React.lazy(() => import('@mui/icons-material/EuroSymbolTwoTone')),
    EuroTwoTone: React.lazy(() => import('@mui/icons-material/EuroTwoTone')),
    EventAvailable: React.lazy(() => import('@mui/icons-material/EventAvailable')),
    EventAvailableOutlined: React.lazy(() => import('@mui/icons-material/EventAvailableOutlined')),
    EventAvailableRounded: React.lazy(() => import('@mui/icons-material/EventAvailableRounded')),
    EventAvailableSharp: React.lazy(() => import('@mui/icons-material/EventAvailableSharp')),
    EventAvailableTwoTone: React.lazy(() => import('@mui/icons-material/EventAvailableTwoTone')),
    EventBusy: React.lazy(() => import('@mui/icons-material/EventBusy')),
    EventBusyOutlined: React.lazy(() => import('@mui/icons-material/EventBusyOutlined')),
    EventBusyRounded: React.lazy(() => import('@mui/icons-material/EventBusyRounded')),
    EventBusySharp: React.lazy(() => import('@mui/icons-material/EventBusySharp')),
    EventBusyTwoTone: React.lazy(() => import('@mui/icons-material/EventBusyTwoTone')),
    Event: React.lazy(() => import('@mui/icons-material/Event')),
    EventNote: React.lazy(() => import('@mui/icons-material/EventNote')),
    EventNoteOutlined: React.lazy(() => import('@mui/icons-material/EventNoteOutlined')),
    EventNoteRounded: React.lazy(() => import('@mui/icons-material/EventNoteRounded')),
    EventNoteSharp: React.lazy(() => import('@mui/icons-material/EventNoteSharp')),
    EventNoteTwoTone: React.lazy(() => import('@mui/icons-material/EventNoteTwoTone')),
    EventOutlined: React.lazy(() => import('@mui/icons-material/EventOutlined')),
    EventRepeat: React.lazy(() => import('@mui/icons-material/EventRepeat')),
    EventRepeatOutlined: React.lazy(() => import('@mui/icons-material/EventRepeatOutlined')),
    EventRepeatRounded: React.lazy(() => import('@mui/icons-material/EventRepeatRounded')),
    EventRepeatSharp: React.lazy(() => import('@mui/icons-material/EventRepeatSharp')),
    EventRepeatTwoTone: React.lazy(() => import('@mui/icons-material/EventRepeatTwoTone')),
    EventRounded: React.lazy(() => import('@mui/icons-material/EventRounded')),
    EventSeat: React.lazy(() => import('@mui/icons-material/EventSeat')),
    EventSeatOutlined: React.lazy(() => import('@mui/icons-material/EventSeatOutlined')),
    EventSeatRounded: React.lazy(() => import('@mui/icons-material/EventSeatRounded')),
    EventSeatSharp: React.lazy(() => import('@mui/icons-material/EventSeatSharp')),
    EventSeatTwoTone: React.lazy(() => import('@mui/icons-material/EventSeatTwoTone')),
    EventSharp: React.lazy(() => import('@mui/icons-material/EventSharp')),
    EventTwoTone: React.lazy(() => import('@mui/icons-material/EventTwoTone')),
    EvStation: React.lazy(() => import('@mui/icons-material/EvStation')),
    EvStationOutlined: React.lazy(() => import('@mui/icons-material/EvStationOutlined')),
    EvStationRounded: React.lazy(() => import('@mui/icons-material/EvStationRounded')),
    EvStationSharp: React.lazy(() => import('@mui/icons-material/EvStationSharp')),
    EvStationTwoTone: React.lazy(() => import('@mui/icons-material/EvStationTwoTone')),
    ExitToApp: React.lazy(() => import('@mui/icons-material/ExitToApp')),
    ExitToAppOutlined: React.lazy(() => import('@mui/icons-material/ExitToAppOutlined')),
    ExitToAppRounded: React.lazy(() => import('@mui/icons-material/ExitToAppRounded')),
    ExitToAppSharp: React.lazy(() => import('@mui/icons-material/ExitToAppSharp')),
    ExitToAppTwoTone: React.lazy(() => import('@mui/icons-material/ExitToAppTwoTone')),
    ExpandCircleDown: React.lazy(() => import('@mui/icons-material/ExpandCircleDown')),
    ExpandCircleDownOutlined: React.lazy(() => import('@mui/icons-material/ExpandCircleDownOutlined')),
    ExpandCircleDownRounded: React.lazy(() => import('@mui/icons-material/ExpandCircleDownRounded')),
    ExpandCircleDownSharp: React.lazy(() => import('@mui/icons-material/ExpandCircleDownSharp')),
    ExpandCircleDownTwoTone: React.lazy(() => import('@mui/icons-material/ExpandCircleDownTwoTone')),
    Expand: React.lazy(() => import('@mui/icons-material/Expand')),
    ExpandLess: React.lazy(() => import('@mui/icons-material/ExpandLess')),
    ExpandLessOutlined: React.lazy(() => import('@mui/icons-material/ExpandLessOutlined')),
    ExpandLessRounded: React.lazy(() => import('@mui/icons-material/ExpandLessRounded')),
    ExpandLessSharp: React.lazy(() => import('@mui/icons-material/ExpandLessSharp')),
    ExpandLessTwoTone: React.lazy(() => import('@mui/icons-material/ExpandLessTwoTone')),
    ExpandMore: React.lazy(() => import('@mui/icons-material/ExpandMore')),
    ExpandMoreOutlined: React.lazy(() => import('@mui/icons-material/ExpandMoreOutlined')),
    ExpandMoreRounded: React.lazy(() => import('@mui/icons-material/ExpandMoreRounded')),
    ExpandMoreSharp: React.lazy(() => import('@mui/icons-material/ExpandMoreSharp')),
    ExpandMoreTwoTone: React.lazy(() => import('@mui/icons-material/ExpandMoreTwoTone')),
    ExpandOutlined: React.lazy(() => import('@mui/icons-material/ExpandOutlined')),
    ExpandRounded: React.lazy(() => import('@mui/icons-material/ExpandRounded')),
    ExpandSharp: React.lazy(() => import('@mui/icons-material/ExpandSharp')),
    ExpandTwoTone: React.lazy(() => import('@mui/icons-material/ExpandTwoTone')),
    Explicit: React.lazy(() => import('@mui/icons-material/Explicit')),
    ExplicitOutlined: React.lazy(() => import('@mui/icons-material/ExplicitOutlined')),
    ExplicitRounded: React.lazy(() => import('@mui/icons-material/ExplicitRounded')),
    ExplicitSharp: React.lazy(() => import('@mui/icons-material/ExplicitSharp')),
    ExplicitTwoTone: React.lazy(() => import('@mui/icons-material/ExplicitTwoTone')),
    Explore: React.lazy(() => import('@mui/icons-material/Explore')),
    ExploreOff: React.lazy(() => import('@mui/icons-material/ExploreOff')),
    ExploreOffOutlined: React.lazy(() => import('@mui/icons-material/ExploreOffOutlined')),
    ExploreOffRounded: React.lazy(() => import('@mui/icons-material/ExploreOffRounded')),
    ExploreOffSharp: React.lazy(() => import('@mui/icons-material/ExploreOffSharp')),
    ExploreOffTwoTone: React.lazy(() => import('@mui/icons-material/ExploreOffTwoTone')),
    ExploreOutlined: React.lazy(() => import('@mui/icons-material/ExploreOutlined')),
    ExploreRounded: React.lazy(() => import('@mui/icons-material/ExploreRounded')),
    ExploreSharp: React.lazy(() => import('@mui/icons-material/ExploreSharp')),
    ExploreTwoTone: React.lazy(() => import('@mui/icons-material/ExploreTwoTone')),
    Exposure: React.lazy(() => import('@mui/icons-material/Exposure')),
    ExposureOutlined: React.lazy(() => import('@mui/icons-material/ExposureOutlined')),
    ExposureRounded: React.lazy(() => import('@mui/icons-material/ExposureRounded')),
    ExposureSharp: React.lazy(() => import('@mui/icons-material/ExposureSharp')),
    ExposureTwoTone: React.lazy(() => import('@mui/icons-material/ExposureTwoTone')),
    Extension: React.lazy(() => import('@mui/icons-material/Extension')),
    ExtensionOff: React.lazy(() => import('@mui/icons-material/ExtensionOff')),
    ExtensionOffOutlined: React.lazy(() => import('@mui/icons-material/ExtensionOffOutlined')),
    ExtensionOffRounded: React.lazy(() => import('@mui/icons-material/ExtensionOffRounded')),
    ExtensionOffSharp: React.lazy(() => import('@mui/icons-material/ExtensionOffSharp')),
    ExtensionOffTwoTone: React.lazy(() => import('@mui/icons-material/ExtensionOffTwoTone')),
    ExtensionOutlined: React.lazy(() => import('@mui/icons-material/ExtensionOutlined')),
    ExtensionRounded: React.lazy(() => import('@mui/icons-material/ExtensionRounded')),
    ExtensionSharp: React.lazy(() => import('@mui/icons-material/ExtensionSharp')),
    ExtensionTwoTone: React.lazy(() => import('@mui/icons-material/ExtensionTwoTone')),
    Face2: React.lazy(() => import('@mui/icons-material/Face2')),
    Face2Outlined: React.lazy(() => import('@mui/icons-material/Face2Outlined')),
    Face2Rounded: React.lazy(() => import('@mui/icons-material/Face2Rounded')),
    Face2Sharp: React.lazy(() => import('@mui/icons-material/Face2Sharp')),
    Face2TwoTone: React.lazy(() => import('@mui/icons-material/Face2TwoTone')),
    Face3: React.lazy(() => import('@mui/icons-material/Face3')),
    Face3Outlined: React.lazy(() => import('@mui/icons-material/Face3Outlined')),
    Face3Rounded: React.lazy(() => import('@mui/icons-material/Face3Rounded')),
    Face3Sharp: React.lazy(() => import('@mui/icons-material/Face3Sharp')),
    Face3TwoTone: React.lazy(() => import('@mui/icons-material/Face3TwoTone')),
    Face4: React.lazy(() => import('@mui/icons-material/Face4')),
    Face4Outlined: React.lazy(() => import('@mui/icons-material/Face4Outlined')),
    Face4Rounded: React.lazy(() => import('@mui/icons-material/Face4Rounded')),
    Face4Sharp: React.lazy(() => import('@mui/icons-material/Face4Sharp')),
    Face4TwoTone: React.lazy(() => import('@mui/icons-material/Face4TwoTone')),
    Face5: React.lazy(() => import('@mui/icons-material/Face5')),
    Face5Outlined: React.lazy(() => import('@mui/icons-material/Face5Outlined')),
    Face5Rounded: React.lazy(() => import('@mui/icons-material/Face5Rounded')),
    Face5Sharp: React.lazy(() => import('@mui/icons-material/Face5Sharp')),
    Face5TwoTone: React.lazy(() => import('@mui/icons-material/Face5TwoTone')),
    Face6: React.lazy(() => import('@mui/icons-material/Face6')),
    Face6Outlined: React.lazy(() => import('@mui/icons-material/Face6Outlined')),
    Face6Rounded: React.lazy(() => import('@mui/icons-material/Face6Rounded')),
    Face6Sharp: React.lazy(() => import('@mui/icons-material/Face6Sharp')),
    Face6TwoTone: React.lazy(() => import('@mui/icons-material/Face6TwoTone')),
    Facebook: React.lazy(() => import('@mui/icons-material/Facebook')),
    FacebookOutlined: React.lazy(() => import('@mui/icons-material/FacebookOutlined')),
    FacebookRounded: React.lazy(() => import('@mui/icons-material/FacebookRounded')),
    FacebookSharp: React.lazy(() => import('@mui/icons-material/FacebookSharp')),
    FacebookTwoTone: React.lazy(() => import('@mui/icons-material/FacebookTwoTone')),
    Face: React.lazy(() => import('@mui/icons-material/Face')),
    FaceOutlined: React.lazy(() => import('@mui/icons-material/FaceOutlined')),
    FaceRetouchingNatural: React.lazy(() => import('@mui/icons-material/FaceRetouchingNatural')),
    FaceRetouchingNaturalOutlined: React.lazy(() => import('@mui/icons-material/FaceRetouchingNaturalOutlined')),
    FaceRetouchingNaturalRounded: React.lazy(() => import('@mui/icons-material/FaceRetouchingNaturalRounded')),
    FaceRetouchingNaturalSharp: React.lazy(() => import('@mui/icons-material/FaceRetouchingNaturalSharp')),
    FaceRetouchingNaturalTwoTone: React.lazy(() => import('@mui/icons-material/FaceRetouchingNaturalTwoTone')),
    FaceRetouchingOff: React.lazy(() => import('@mui/icons-material/FaceRetouchingOff')),
    FaceRetouchingOffOutlined: React.lazy(() => import('@mui/icons-material/FaceRetouchingOffOutlined')),
    FaceRetouchingOffRounded: React.lazy(() => import('@mui/icons-material/FaceRetouchingOffRounded')),
    FaceRetouchingOffSharp: React.lazy(() => import('@mui/icons-material/FaceRetouchingOffSharp')),
    FaceRetouchingOffTwoTone: React.lazy(() => import('@mui/icons-material/FaceRetouchingOffTwoTone')),
    FaceRounded: React.lazy(() => import('@mui/icons-material/FaceRounded')),
    FaceSharp: React.lazy(() => import('@mui/icons-material/FaceSharp')),
    FaceTwoTone: React.lazy(() => import('@mui/icons-material/FaceTwoTone')),
    FactCheck: React.lazy(() => import('@mui/icons-material/FactCheck')),
    FactCheckOutlined: React.lazy(() => import('@mui/icons-material/FactCheckOutlined')),
    FactCheckRounded: React.lazy(() => import('@mui/icons-material/FactCheckRounded')),
    FactCheckSharp: React.lazy(() => import('@mui/icons-material/FactCheckSharp')),
    FactCheckTwoTone: React.lazy(() => import('@mui/icons-material/FactCheckTwoTone')),
    Factory: React.lazy(() => import('@mui/icons-material/Factory')),
    FactoryOutlined: React.lazy(() => import('@mui/icons-material/FactoryOutlined')),
    FactoryRounded: React.lazy(() => import('@mui/icons-material/FactoryRounded')),
    FactorySharp: React.lazy(() => import('@mui/icons-material/FactorySharp')),
    FactoryTwoTone: React.lazy(() => import('@mui/icons-material/FactoryTwoTone')),
    FamilyRestroom: React.lazy(() => import('@mui/icons-material/FamilyRestroom')),
    FamilyRestroomOutlined: React.lazy(() => import('@mui/icons-material/FamilyRestroomOutlined')),
    FamilyRestroomRounded: React.lazy(() => import('@mui/icons-material/FamilyRestroomRounded')),
    FamilyRestroomSharp: React.lazy(() => import('@mui/icons-material/FamilyRestroomSharp')),
    FamilyRestroomTwoTone: React.lazy(() => import('@mui/icons-material/FamilyRestroomTwoTone')),
    Fastfood: React.lazy(() => import('@mui/icons-material/Fastfood')),
    FastfoodOutlined: React.lazy(() => import('@mui/icons-material/FastfoodOutlined')),
    FastfoodRounded: React.lazy(() => import('@mui/icons-material/FastfoodRounded')),
    FastfoodSharp: React.lazy(() => import('@mui/icons-material/FastfoodSharp')),
    FastfoodTwoTone: React.lazy(() => import('@mui/icons-material/FastfoodTwoTone')),
    FastForward: React.lazy(() => import('@mui/icons-material/FastForward')),
    FastForwardOutlined: React.lazy(() => import('@mui/icons-material/FastForwardOutlined')),
    FastForwardRounded: React.lazy(() => import('@mui/icons-material/FastForwardRounded')),
    FastForwardSharp: React.lazy(() => import('@mui/icons-material/FastForwardSharp')),
    FastForwardTwoTone: React.lazy(() => import('@mui/icons-material/FastForwardTwoTone')),
    FastRewind: React.lazy(() => import('@mui/icons-material/FastRewind')),
    FastRewindOutlined: React.lazy(() => import('@mui/icons-material/FastRewindOutlined')),
    FastRewindRounded: React.lazy(() => import('@mui/icons-material/FastRewindRounded')),
    FastRewindSharp: React.lazy(() => import('@mui/icons-material/FastRewindSharp')),
    FastRewindTwoTone: React.lazy(() => import('@mui/icons-material/FastRewindTwoTone')),
    FavoriteBorder: React.lazy(() => import('@mui/icons-material/FavoriteBorder')),
    FavoriteBorderOutlined: React.lazy(() => import('@mui/icons-material/FavoriteBorderOutlined')),
    FavoriteBorderRounded: React.lazy(() => import('@mui/icons-material/FavoriteBorderRounded')),
    FavoriteBorderSharp: React.lazy(() => import('@mui/icons-material/FavoriteBorderSharp')),
    FavoriteBorderTwoTone: React.lazy(() => import('@mui/icons-material/FavoriteBorderTwoTone')),
    Favorite: React.lazy(() => import('@mui/icons-material/Favorite')),
    FavoriteOutlined: React.lazy(() => import('@mui/icons-material/FavoriteOutlined')),
    FavoriteRounded: React.lazy(() => import('@mui/icons-material/FavoriteRounded')),
    FavoriteSharp: React.lazy(() => import('@mui/icons-material/FavoriteSharp')),
    FavoriteTwoTone: React.lazy(() => import('@mui/icons-material/FavoriteTwoTone')),
    Fax: React.lazy(() => import('@mui/icons-material/Fax')),
    FaxOutlined: React.lazy(() => import('@mui/icons-material/FaxOutlined')),
    FaxRounded: React.lazy(() => import('@mui/icons-material/FaxRounded')),
    FaxSharp: React.lazy(() => import('@mui/icons-material/FaxSharp')),
    FaxTwoTone: React.lazy(() => import('@mui/icons-material/FaxTwoTone')),
    FeaturedPlayList: React.lazy(() => import('@mui/icons-material/FeaturedPlayList')),
    FeaturedPlayListOutlined: React.lazy(() => import('@mui/icons-material/FeaturedPlayListOutlined')),
    FeaturedPlayListRounded: React.lazy(() => import('@mui/icons-material/FeaturedPlayListRounded')),
    FeaturedPlayListSharp: React.lazy(() => import('@mui/icons-material/FeaturedPlayListSharp')),
    FeaturedPlayListTwoTone: React.lazy(() => import('@mui/icons-material/FeaturedPlayListTwoTone')),
    FeaturedVideo: React.lazy(() => import('@mui/icons-material/FeaturedVideo')),
    FeaturedVideoOutlined: React.lazy(() => import('@mui/icons-material/FeaturedVideoOutlined')),
    FeaturedVideoRounded: React.lazy(() => import('@mui/icons-material/FeaturedVideoRounded')),
    FeaturedVideoSharp: React.lazy(() => import('@mui/icons-material/FeaturedVideoSharp')),
    FeaturedVideoTwoTone: React.lazy(() => import('@mui/icons-material/FeaturedVideoTwoTone')),
    Feedback: React.lazy(() => import('@mui/icons-material/Feedback')),
    FeedbackOutlined: React.lazy(() => import('@mui/icons-material/FeedbackOutlined')),
    FeedbackRounded: React.lazy(() => import('@mui/icons-material/FeedbackRounded')),
    FeedbackSharp: React.lazy(() => import('@mui/icons-material/FeedbackSharp')),
    FeedbackTwoTone: React.lazy(() => import('@mui/icons-material/FeedbackTwoTone')),
    Feed: React.lazy(() => import('@mui/icons-material/Feed')),
    FeedOutlined: React.lazy(() => import('@mui/icons-material/FeedOutlined')),
    FeedRounded: React.lazy(() => import('@mui/icons-material/FeedRounded')),
    FeedSharp: React.lazy(() => import('@mui/icons-material/FeedSharp')),
    FeedTwoTone: React.lazy(() => import('@mui/icons-material/FeedTwoTone')),
    Female: React.lazy(() => import('@mui/icons-material/Female')),
    FemaleOutlined: React.lazy(() => import('@mui/icons-material/FemaleOutlined')),
    FemaleRounded: React.lazy(() => import('@mui/icons-material/FemaleRounded')),
    FemaleSharp: React.lazy(() => import('@mui/icons-material/FemaleSharp')),
    FemaleTwoTone: React.lazy(() => import('@mui/icons-material/FemaleTwoTone')),
    Fence: React.lazy(() => import('@mui/icons-material/Fence')),
    FenceOutlined: React.lazy(() => import('@mui/icons-material/FenceOutlined')),
    FenceRounded: React.lazy(() => import('@mui/icons-material/FenceRounded')),
    FenceSharp: React.lazy(() => import('@mui/icons-material/FenceSharp')),
    FenceTwoTone: React.lazy(() => import('@mui/icons-material/FenceTwoTone')),
    Festival: React.lazy(() => import('@mui/icons-material/Festival')),
    FestivalOutlined: React.lazy(() => import('@mui/icons-material/FestivalOutlined')),
    FestivalRounded: React.lazy(() => import('@mui/icons-material/FestivalRounded')),
    FestivalSharp: React.lazy(() => import('@mui/icons-material/FestivalSharp')),
    FestivalTwoTone: React.lazy(() => import('@mui/icons-material/FestivalTwoTone')),
    FiberDvr: React.lazy(() => import('@mui/icons-material/FiberDvr')),
    FiberDvrOutlined: React.lazy(() => import('@mui/icons-material/FiberDvrOutlined')),
    FiberDvrRounded: React.lazy(() => import('@mui/icons-material/FiberDvrRounded')),
    FiberDvrSharp: React.lazy(() => import('@mui/icons-material/FiberDvrSharp')),
    FiberDvrTwoTone: React.lazy(() => import('@mui/icons-material/FiberDvrTwoTone')),
    FiberManualRecord: React.lazy(() => import('@mui/icons-material/FiberManualRecord')),
    FiberManualRecordOutlined: React.lazy(() => import('@mui/icons-material/FiberManualRecordOutlined')),
    FiberManualRecordRounded: React.lazy(() => import('@mui/icons-material/FiberManualRecordRounded')),
    FiberManualRecordSharp: React.lazy(() => import('@mui/icons-material/FiberManualRecordSharp')),
    FiberManualRecordTwoTone: React.lazy(() => import('@mui/icons-material/FiberManualRecordTwoTone')),
    FiberNew: React.lazy(() => import('@mui/icons-material/FiberNew')),
    FiberNewOutlined: React.lazy(() => import('@mui/icons-material/FiberNewOutlined')),
    FiberNewRounded: React.lazy(() => import('@mui/icons-material/FiberNewRounded')),
    FiberNewSharp: React.lazy(() => import('@mui/icons-material/FiberNewSharp')),
    FiberNewTwoTone: React.lazy(() => import('@mui/icons-material/FiberNewTwoTone')),
    FiberPin: React.lazy(() => import('@mui/icons-material/FiberPin')),
    FiberPinOutlined: React.lazy(() => import('@mui/icons-material/FiberPinOutlined')),
    FiberPinRounded: React.lazy(() => import('@mui/icons-material/FiberPinRounded')),
    FiberPinSharp: React.lazy(() => import('@mui/icons-material/FiberPinSharp')),
    FiberPinTwoTone: React.lazy(() => import('@mui/icons-material/FiberPinTwoTone')),
    FiberSmartRecord: React.lazy(() => import('@mui/icons-material/FiberSmartRecord')),
    FiberSmartRecordOutlined: React.lazy(() => import('@mui/icons-material/FiberSmartRecordOutlined')),
    FiberSmartRecordRounded: React.lazy(() => import('@mui/icons-material/FiberSmartRecordRounded')),
    FiberSmartRecordSharp: React.lazy(() => import('@mui/icons-material/FiberSmartRecordSharp')),
    FiberSmartRecordTwoTone: React.lazy(() => import('@mui/icons-material/FiberSmartRecordTwoTone')),
    FifteenMp: React.lazy(() => import('@mui/icons-material/FifteenMp')),
    FifteenMpOutlined: React.lazy(() => import('@mui/icons-material/FifteenMpOutlined')),
    FifteenMpRounded: React.lazy(() => import('@mui/icons-material/FifteenMpRounded')),
    FifteenMpSharp: React.lazy(() => import('@mui/icons-material/FifteenMpSharp')),
    FifteenMpTwoTone: React.lazy(() => import('@mui/icons-material/FifteenMpTwoTone')),
    FileCopy: React.lazy(() => import('@mui/icons-material/FileCopy')),
    FileCopyOutlined: React.lazy(() => import('@mui/icons-material/FileCopyOutlined')),
    FileCopyRounded: React.lazy(() => import('@mui/icons-material/FileCopyRounded')),
    FileCopySharp: React.lazy(() => import('@mui/icons-material/FileCopySharp')),
    FileCopyTwoTone: React.lazy(() => import('@mui/icons-material/FileCopyTwoTone')),
    FileDownloadDone: React.lazy(() => import('@mui/icons-material/FileDownloadDone')),
    FileDownloadDoneOutlined: React.lazy(() => import('@mui/icons-material/FileDownloadDoneOutlined')),
    FileDownloadDoneRounded: React.lazy(() => import('@mui/icons-material/FileDownloadDoneRounded')),
    FileDownloadDoneSharp: React.lazy(() => import('@mui/icons-material/FileDownloadDoneSharp')),
    FileDownloadDoneTwoTone: React.lazy(() => import('@mui/icons-material/FileDownloadDoneTwoTone')),
    FileDownload: React.lazy(() => import('@mui/icons-material/FileDownload')),
    FileDownloadOff: React.lazy(() => import('@mui/icons-material/FileDownloadOff')),
    FileDownloadOffOutlined: React.lazy(() => import('@mui/icons-material/FileDownloadOffOutlined')),
    FileDownloadOffRounded: React.lazy(() => import('@mui/icons-material/FileDownloadOffRounded')),
    FileDownloadOffSharp: React.lazy(() => import('@mui/icons-material/FileDownloadOffSharp')),
    FileDownloadOffTwoTone: React.lazy(() => import('@mui/icons-material/FileDownloadOffTwoTone')),
    FileDownloadOutlined: React.lazy(() => import('@mui/icons-material/FileDownloadOutlined')),
    FileDownloadRounded: React.lazy(() => import('@mui/icons-material/FileDownloadRounded')),
    FileDownloadSharp: React.lazy(() => import('@mui/icons-material/FileDownloadSharp')),
    FileDownloadTwoTone: React.lazy(() => import('@mui/icons-material/FileDownloadTwoTone')),
    FileOpen: React.lazy(() => import('@mui/icons-material/FileOpen')),
    FileOpenOutlined: React.lazy(() => import('@mui/icons-material/FileOpenOutlined')),
    FileOpenRounded: React.lazy(() => import('@mui/icons-material/FileOpenRounded')),
    FileOpenSharp: React.lazy(() => import('@mui/icons-material/FileOpenSharp')),
    FileOpenTwoTone: React.lazy(() => import('@mui/icons-material/FileOpenTwoTone')),
    FilePresent: React.lazy(() => import('@mui/icons-material/FilePresent')),
    FilePresentOutlined: React.lazy(() => import('@mui/icons-material/FilePresentOutlined')),
    FilePresentRounded: React.lazy(() => import('@mui/icons-material/FilePresentRounded')),
    FilePresentSharp: React.lazy(() => import('@mui/icons-material/FilePresentSharp')),
    FilePresentTwoTone: React.lazy(() => import('@mui/icons-material/FilePresentTwoTone')),
    FileUpload: React.lazy(() => import('@mui/icons-material/FileUpload')),
    FileUploadOutlined: React.lazy(() => import('@mui/icons-material/FileUploadOutlined')),
    FileUploadRounded: React.lazy(() => import('@mui/icons-material/FileUploadRounded')),
    FileUploadSharp: React.lazy(() => import('@mui/icons-material/FileUploadSharp')),
    FileUploadTwoTone: React.lazy(() => import('@mui/icons-material/FileUploadTwoTone')),
    Filter1: React.lazy(() => import('@mui/icons-material/Filter1')),
    Filter1Outlined: React.lazy(() => import('@mui/icons-material/Filter1Outlined')),
    Filter1Rounded: React.lazy(() => import('@mui/icons-material/Filter1Rounded')),
    Filter1Sharp: React.lazy(() => import('@mui/icons-material/Filter1Sharp')),
    Filter1TwoTone: React.lazy(() => import('@mui/icons-material/Filter1TwoTone')),
    Filter2: React.lazy(() => import('@mui/icons-material/Filter2')),
    Filter2Outlined: React.lazy(() => import('@mui/icons-material/Filter2Outlined')),
    Filter2Rounded: React.lazy(() => import('@mui/icons-material/Filter2Rounded')),
    Filter2Sharp: React.lazy(() => import('@mui/icons-material/Filter2Sharp')),
    Filter2TwoTone: React.lazy(() => import('@mui/icons-material/Filter2TwoTone')),
    Filter3: React.lazy(() => import('@mui/icons-material/Filter3')),
    Filter3Outlined: React.lazy(() => import('@mui/icons-material/Filter3Outlined')),
    Filter3Rounded: React.lazy(() => import('@mui/icons-material/Filter3Rounded')),
    Filter3Sharp: React.lazy(() => import('@mui/icons-material/Filter3Sharp')),
    Filter3TwoTone: React.lazy(() => import('@mui/icons-material/Filter3TwoTone')),
    Filter4: React.lazy(() => import('@mui/icons-material/Filter4')),
    Filter4Outlined: React.lazy(() => import('@mui/icons-material/Filter4Outlined')),
    Filter4Rounded: React.lazy(() => import('@mui/icons-material/Filter4Rounded')),
    Filter4Sharp: React.lazy(() => import('@mui/icons-material/Filter4Sharp')),
    Filter4TwoTone: React.lazy(() => import('@mui/icons-material/Filter4TwoTone')),
    Filter5: React.lazy(() => import('@mui/icons-material/Filter5')),
    Filter5Outlined: React.lazy(() => import('@mui/icons-material/Filter5Outlined')),
    Filter5Rounded: React.lazy(() => import('@mui/icons-material/Filter5Rounded')),
    Filter5Sharp: React.lazy(() => import('@mui/icons-material/Filter5Sharp')),
    Filter5TwoTone: React.lazy(() => import('@mui/icons-material/Filter5TwoTone')),
    Filter6: React.lazy(() => import('@mui/icons-material/Filter6')),
    Filter6Outlined: React.lazy(() => import('@mui/icons-material/Filter6Outlined')),
    Filter6Rounded: React.lazy(() => import('@mui/icons-material/Filter6Rounded')),
    Filter6Sharp: React.lazy(() => import('@mui/icons-material/Filter6Sharp')),
    Filter6TwoTone: React.lazy(() => import('@mui/icons-material/Filter6TwoTone')),
    Filter7: React.lazy(() => import('@mui/icons-material/Filter7')),
    Filter7Outlined: React.lazy(() => import('@mui/icons-material/Filter7Outlined')),
    Filter7Rounded: React.lazy(() => import('@mui/icons-material/Filter7Rounded')),
    Filter7Sharp: React.lazy(() => import('@mui/icons-material/Filter7Sharp')),
    Filter7TwoTone: React.lazy(() => import('@mui/icons-material/Filter7TwoTone')),
    Filter8: React.lazy(() => import('@mui/icons-material/Filter8')),
    Filter8Outlined: React.lazy(() => import('@mui/icons-material/Filter8Outlined')),
    Filter8Rounded: React.lazy(() => import('@mui/icons-material/Filter8Rounded')),
    Filter8Sharp: React.lazy(() => import('@mui/icons-material/Filter8Sharp')),
    Filter8TwoTone: React.lazy(() => import('@mui/icons-material/Filter8TwoTone')),
    Filter9: React.lazy(() => import('@mui/icons-material/Filter9')),
    Filter9Outlined: React.lazy(() => import('@mui/icons-material/Filter9Outlined')),
    Filter9Plus: React.lazy(() => import('@mui/icons-material/Filter9Plus')),
    Filter9PlusOutlined: React.lazy(() => import('@mui/icons-material/Filter9PlusOutlined')),
    Filter9PlusRounded: React.lazy(() => import('@mui/icons-material/Filter9PlusRounded')),
    Filter9PlusSharp: React.lazy(() => import('@mui/icons-material/Filter9PlusSharp')),
    Filter9PlusTwoTone: React.lazy(() => import('@mui/icons-material/Filter9PlusTwoTone')),
    Filter9Rounded: React.lazy(() => import('@mui/icons-material/Filter9Rounded')),
    Filter9Sharp: React.lazy(() => import('@mui/icons-material/Filter9Sharp')),
    Filter9TwoTone: React.lazy(() => import('@mui/icons-material/Filter9TwoTone')),
    FilterAlt: React.lazy(() => import('@mui/icons-material/FilterAlt')),
    FilterAltOff: React.lazy(() => import('@mui/icons-material/FilterAltOff')),
    FilterAltOffOutlined: React.lazy(() => import('@mui/icons-material/FilterAltOffOutlined')),
    FilterAltOffRounded: React.lazy(() => import('@mui/icons-material/FilterAltOffRounded')),
    FilterAltOffSharp: React.lazy(() => import('@mui/icons-material/FilterAltOffSharp')),
    FilterAltOffTwoTone: React.lazy(() => import('@mui/icons-material/FilterAltOffTwoTone')),
    FilterAltOutlined: React.lazy(() => import('@mui/icons-material/FilterAltOutlined')),
    FilterAltRounded: React.lazy(() => import('@mui/icons-material/FilterAltRounded')),
    FilterAltSharp: React.lazy(() => import('@mui/icons-material/FilterAltSharp')),
    FilterAltTwoTone: React.lazy(() => import('@mui/icons-material/FilterAltTwoTone')),
    FilterBAndW: React.lazy(() => import('@mui/icons-material/FilterBAndW')),
    FilterBAndWOutlined: React.lazy(() => import('@mui/icons-material/FilterBAndWOutlined')),
    FilterBAndWRounded: React.lazy(() => import('@mui/icons-material/FilterBAndWRounded')),
    FilterBAndWSharp: React.lazy(() => import('@mui/icons-material/FilterBAndWSharp')),
    FilterBAndWTwoTone: React.lazy(() => import('@mui/icons-material/FilterBAndWTwoTone')),
    FilterCenterFocus: React.lazy(() => import('@mui/icons-material/FilterCenterFocus')),
    FilterCenterFocusOutlined: React.lazy(() => import('@mui/icons-material/FilterCenterFocusOutlined')),
    FilterCenterFocusRounded: React.lazy(() => import('@mui/icons-material/FilterCenterFocusRounded')),
    FilterCenterFocusSharp: React.lazy(() => import('@mui/icons-material/FilterCenterFocusSharp')),
    FilterCenterFocusTwoTone: React.lazy(() => import('@mui/icons-material/FilterCenterFocusTwoTone')),
    FilterDrama: React.lazy(() => import('@mui/icons-material/FilterDrama')),
    FilterDramaOutlined: React.lazy(() => import('@mui/icons-material/FilterDramaOutlined')),
    FilterDramaRounded: React.lazy(() => import('@mui/icons-material/FilterDramaRounded')),
    FilterDramaSharp: React.lazy(() => import('@mui/icons-material/FilterDramaSharp')),
    FilterDramaTwoTone: React.lazy(() => import('@mui/icons-material/FilterDramaTwoTone')),
    Filter: React.lazy(() => import('@mui/icons-material/Filter')),
    FilterFrames: React.lazy(() => import('@mui/icons-material/FilterFrames')),
    FilterFramesOutlined: React.lazy(() => import('@mui/icons-material/FilterFramesOutlined')),
    FilterFramesRounded: React.lazy(() => import('@mui/icons-material/FilterFramesRounded')),
    FilterFramesSharp: React.lazy(() => import('@mui/icons-material/FilterFramesSharp')),
    FilterFramesTwoTone: React.lazy(() => import('@mui/icons-material/FilterFramesTwoTone')),
    FilterHdr: React.lazy(() => import('@mui/icons-material/FilterHdr')),
    FilterHdrOutlined: React.lazy(() => import('@mui/icons-material/FilterHdrOutlined')),
    FilterHdrRounded: React.lazy(() => import('@mui/icons-material/FilterHdrRounded')),
    FilterHdrSharp: React.lazy(() => import('@mui/icons-material/FilterHdrSharp')),
    FilterHdrTwoTone: React.lazy(() => import('@mui/icons-material/FilterHdrTwoTone')),
    FilterList: React.lazy(() => import('@mui/icons-material/FilterList')),
    FilterListOff: React.lazy(() => import('@mui/icons-material/FilterListOff')),
    FilterListOffOutlined: React.lazy(() => import('@mui/icons-material/FilterListOffOutlined')),
    FilterListOffRounded: React.lazy(() => import('@mui/icons-material/FilterListOffRounded')),
    FilterListOffSharp: React.lazy(() => import('@mui/icons-material/FilterListOffSharp')),
    FilterListOffTwoTone: React.lazy(() => import('@mui/icons-material/FilterListOffTwoTone')),
    FilterListOutlined: React.lazy(() => import('@mui/icons-material/FilterListOutlined')),
    FilterListRounded: React.lazy(() => import('@mui/icons-material/FilterListRounded')),
    FilterListSharp: React.lazy(() => import('@mui/icons-material/FilterListSharp')),
    FilterListTwoTone: React.lazy(() => import('@mui/icons-material/FilterListTwoTone')),
    FilterNone: React.lazy(() => import('@mui/icons-material/FilterNone')),
    FilterNoneOutlined: React.lazy(() => import('@mui/icons-material/FilterNoneOutlined')),
    FilterNoneRounded: React.lazy(() => import('@mui/icons-material/FilterNoneRounded')),
    FilterNoneSharp: React.lazy(() => import('@mui/icons-material/FilterNoneSharp')),
    FilterNoneTwoTone: React.lazy(() => import('@mui/icons-material/FilterNoneTwoTone')),
    FilterOutlined: React.lazy(() => import('@mui/icons-material/FilterOutlined')),
    FilterRounded: React.lazy(() => import('@mui/icons-material/FilterRounded')),
    FilterSharp: React.lazy(() => import('@mui/icons-material/FilterSharp')),
    FilterTiltShift: React.lazy(() => import('@mui/icons-material/FilterTiltShift')),
    FilterTiltShiftOutlined: React.lazy(() => import('@mui/icons-material/FilterTiltShiftOutlined')),
    FilterTiltShiftRounded: React.lazy(() => import('@mui/icons-material/FilterTiltShiftRounded')),
    FilterTiltShiftSharp: React.lazy(() => import('@mui/icons-material/FilterTiltShiftSharp')),
    FilterTiltShiftTwoTone: React.lazy(() => import('@mui/icons-material/FilterTiltShiftTwoTone')),
    FilterTwoTone: React.lazy(() => import('@mui/icons-material/FilterTwoTone')),
    FilterVintage: React.lazy(() => import('@mui/icons-material/FilterVintage')),
    FilterVintageOutlined: React.lazy(() => import('@mui/icons-material/FilterVintageOutlined')),
    FilterVintageRounded: React.lazy(() => import('@mui/icons-material/FilterVintageRounded')),
    FilterVintageSharp: React.lazy(() => import('@mui/icons-material/FilterVintageSharp')),
    FilterVintageTwoTone: React.lazy(() => import('@mui/icons-material/FilterVintageTwoTone')),
    FindInPage: React.lazy(() => import('@mui/icons-material/FindInPage')),
    FindInPageOutlined: React.lazy(() => import('@mui/icons-material/FindInPageOutlined')),
    FindInPageRounded: React.lazy(() => import('@mui/icons-material/FindInPageRounded')),
    FindInPageSharp: React.lazy(() => import('@mui/icons-material/FindInPageSharp')),
    FindInPageTwoTone: React.lazy(() => import('@mui/icons-material/FindInPageTwoTone')),
    FindReplace: React.lazy(() => import('@mui/icons-material/FindReplace')),
    FindReplaceOutlined: React.lazy(() => import('@mui/icons-material/FindReplaceOutlined')),
    FindReplaceRounded: React.lazy(() => import('@mui/icons-material/FindReplaceRounded')),
    FindReplaceSharp: React.lazy(() => import('@mui/icons-material/FindReplaceSharp')),
    FindReplaceTwoTone: React.lazy(() => import('@mui/icons-material/FindReplaceTwoTone')),
    Fingerprint: React.lazy(() => import('@mui/icons-material/Fingerprint')),
    FingerprintOutlined: React.lazy(() => import('@mui/icons-material/FingerprintOutlined')),
    FingerprintRounded: React.lazy(() => import('@mui/icons-material/FingerprintRounded')),
    FingerprintSharp: React.lazy(() => import('@mui/icons-material/FingerprintSharp')),
    FingerprintTwoTone: React.lazy(() => import('@mui/icons-material/FingerprintTwoTone')),
    FireExtinguisher: React.lazy(() => import('@mui/icons-material/FireExtinguisher')),
    FireExtinguisherOutlined: React.lazy(() => import('@mui/icons-material/FireExtinguisherOutlined')),
    FireExtinguisherRounded: React.lazy(() => import('@mui/icons-material/FireExtinguisherRounded')),
    FireExtinguisherSharp: React.lazy(() => import('@mui/icons-material/FireExtinguisherSharp')),
    FireExtinguisherTwoTone: React.lazy(() => import('@mui/icons-material/FireExtinguisherTwoTone')),
    FireHydrantAlt: React.lazy(() => import('@mui/icons-material/FireHydrantAlt')),
    FireHydrantAltOutlined: React.lazy(() => import('@mui/icons-material/FireHydrantAltOutlined')),
    FireHydrantAltRounded: React.lazy(() => import('@mui/icons-material/FireHydrantAltRounded')),
    FireHydrantAltSharp: React.lazy(() => import('@mui/icons-material/FireHydrantAltSharp')),
    FireHydrantAltTwoTone: React.lazy(() => import('@mui/icons-material/FireHydrantAltTwoTone')),
    Fireplace: React.lazy(() => import('@mui/icons-material/Fireplace')),
    FireplaceOutlined: React.lazy(() => import('@mui/icons-material/FireplaceOutlined')),
    FireplaceRounded: React.lazy(() => import('@mui/icons-material/FireplaceRounded')),
    FireplaceSharp: React.lazy(() => import('@mui/icons-material/FireplaceSharp')),
    FireplaceTwoTone: React.lazy(() => import('@mui/icons-material/FireplaceTwoTone')),
    FireTruck: React.lazy(() => import('@mui/icons-material/FireTruck')),
    FireTruckOutlined: React.lazy(() => import('@mui/icons-material/FireTruckOutlined')),
    FireTruckRounded: React.lazy(() => import('@mui/icons-material/FireTruckRounded')),
    FireTruckSharp: React.lazy(() => import('@mui/icons-material/FireTruckSharp')),
    FireTruckTwoTone: React.lazy(() => import('@mui/icons-material/FireTruckTwoTone')),
    FirstPage: React.lazy(() => import('@mui/icons-material/FirstPage')),
    FirstPageOutlined: React.lazy(() => import('@mui/icons-material/FirstPageOutlined')),
    FirstPageRounded: React.lazy(() => import('@mui/icons-material/FirstPageRounded')),
    FirstPageSharp: React.lazy(() => import('@mui/icons-material/FirstPageSharp')),
    FirstPageTwoTone: React.lazy(() => import('@mui/icons-material/FirstPageTwoTone')),
    Fitbit: React.lazy(() => import('@mui/icons-material/Fitbit')),
    FitbitOutlined: React.lazy(() => import('@mui/icons-material/FitbitOutlined')),
    FitbitRounded: React.lazy(() => import('@mui/icons-material/FitbitRounded')),
    FitbitSharp: React.lazy(() => import('@mui/icons-material/FitbitSharp')),
    FitbitTwoTone: React.lazy(() => import('@mui/icons-material/FitbitTwoTone')),
    FitnessCenter: React.lazy(() => import('@mui/icons-material/FitnessCenter')),
    FitnessCenterOutlined: React.lazy(() => import('@mui/icons-material/FitnessCenterOutlined')),
    FitnessCenterRounded: React.lazy(() => import('@mui/icons-material/FitnessCenterRounded')),
    FitnessCenterSharp: React.lazy(() => import('@mui/icons-material/FitnessCenterSharp')),
    FitnessCenterTwoTone: React.lazy(() => import('@mui/icons-material/FitnessCenterTwoTone')),
    FitScreen: React.lazy(() => import('@mui/icons-material/FitScreen')),
    FitScreenOutlined: React.lazy(() => import('@mui/icons-material/FitScreenOutlined')),
    FitScreenRounded: React.lazy(() => import('@mui/icons-material/FitScreenRounded')),
    FitScreenSharp: React.lazy(() => import('@mui/icons-material/FitScreenSharp')),
    FitScreenTwoTone: React.lazy(() => import('@mui/icons-material/FitScreenTwoTone')),
    FiveG: React.lazy(() => import('@mui/icons-material/FiveG')),
    FiveGOutlined: React.lazy(() => import('@mui/icons-material/FiveGOutlined')),
    FiveGRounded: React.lazy(() => import('@mui/icons-material/FiveGRounded')),
    FiveGSharp: React.lazy(() => import('@mui/icons-material/FiveGSharp')),
    FiveGTwoTone: React.lazy(() => import('@mui/icons-material/FiveGTwoTone')),
    FiveK: React.lazy(() => import('@mui/icons-material/FiveK')),
    FiveKOutlined: React.lazy(() => import('@mui/icons-material/FiveKOutlined')),
    FiveKPlus: React.lazy(() => import('@mui/icons-material/FiveKPlus')),
    FiveKPlusOutlined: React.lazy(() => import('@mui/icons-material/FiveKPlusOutlined')),
    FiveKPlusRounded: React.lazy(() => import('@mui/icons-material/FiveKPlusRounded')),
    FiveKPlusSharp: React.lazy(() => import('@mui/icons-material/FiveKPlusSharp')),
    FiveKPlusTwoTone: React.lazy(() => import('@mui/icons-material/FiveKPlusTwoTone')),
    FiveKRounded: React.lazy(() => import('@mui/icons-material/FiveKRounded')),
    FiveKSharp: React.lazy(() => import('@mui/icons-material/FiveKSharp')),
    FiveKTwoTone: React.lazy(() => import('@mui/icons-material/FiveKTwoTone')),
    FiveMp: React.lazy(() => import('@mui/icons-material/FiveMp')),
    FiveMpOutlined: React.lazy(() => import('@mui/icons-material/FiveMpOutlined')),
    FiveMpRounded: React.lazy(() => import('@mui/icons-material/FiveMpRounded')),
    FiveMpSharp: React.lazy(() => import('@mui/icons-material/FiveMpSharp')),
    FiveMpTwoTone: React.lazy(() => import('@mui/icons-material/FiveMpTwoTone')),
    FivteenMp: React.lazy(() => import('@mui/icons-material/FivteenMp')),
    FivteenMpOutlined: React.lazy(() => import('@mui/icons-material/FivteenMpOutlined')),
    FivteenMpRounded: React.lazy(() => import('@mui/icons-material/FivteenMpRounded')),
    FivteenMpSharp: React.lazy(() => import('@mui/icons-material/FivteenMpSharp')),
    FivteenMpTwoTone: React.lazy(() => import('@mui/icons-material/FivteenMpTwoTone')),
    FlagCircle: React.lazy(() => import('@mui/icons-material/FlagCircle')),
    FlagCircleOutlined: React.lazy(() => import('@mui/icons-material/FlagCircleOutlined')),
    FlagCircleRounded: React.lazy(() => import('@mui/icons-material/FlagCircleRounded')),
    FlagCircleSharp: React.lazy(() => import('@mui/icons-material/FlagCircleSharp')),
    FlagCircleTwoTone: React.lazy(() => import('@mui/icons-material/FlagCircleTwoTone')),
    Flag: React.lazy(() => import('@mui/icons-material/Flag')),
    FlagOutlined: React.lazy(() => import('@mui/icons-material/FlagOutlined')),
    FlagRounded: React.lazy(() => import('@mui/icons-material/FlagRounded')),
    FlagSharp: React.lazy(() => import('@mui/icons-material/FlagSharp')),
    FlagTwoTone: React.lazy(() => import('@mui/icons-material/FlagTwoTone')),
    Flaky: React.lazy(() => import('@mui/icons-material/Flaky')),
    FlakyOutlined: React.lazy(() => import('@mui/icons-material/FlakyOutlined')),
    FlakyRounded: React.lazy(() => import('@mui/icons-material/FlakyRounded')),
    FlakySharp: React.lazy(() => import('@mui/icons-material/FlakySharp')),
    FlakyTwoTone: React.lazy(() => import('@mui/icons-material/FlakyTwoTone')),
    Flare: React.lazy(() => import('@mui/icons-material/Flare')),
    FlareOutlined: React.lazy(() => import('@mui/icons-material/FlareOutlined')),
    FlareRounded: React.lazy(() => import('@mui/icons-material/FlareRounded')),
    FlareSharp: React.lazy(() => import('@mui/icons-material/FlareSharp')),
    FlareTwoTone: React.lazy(() => import('@mui/icons-material/FlareTwoTone')),
    FlashAuto: React.lazy(() => import('@mui/icons-material/FlashAuto')),
    FlashAutoOutlined: React.lazy(() => import('@mui/icons-material/FlashAutoOutlined')),
    FlashAutoRounded: React.lazy(() => import('@mui/icons-material/FlashAutoRounded')),
    FlashAutoSharp: React.lazy(() => import('@mui/icons-material/FlashAutoSharp')),
    FlashAutoTwoTone: React.lazy(() => import('@mui/icons-material/FlashAutoTwoTone')),
    FlashlightOff: React.lazy(() => import('@mui/icons-material/FlashlightOff')),
    FlashlightOffOutlined: React.lazy(() => import('@mui/icons-material/FlashlightOffOutlined')),
    FlashlightOffRounded: React.lazy(() => import('@mui/icons-material/FlashlightOffRounded')),
    FlashlightOffSharp: React.lazy(() => import('@mui/icons-material/FlashlightOffSharp')),
    FlashlightOffTwoTone: React.lazy(() => import('@mui/icons-material/FlashlightOffTwoTone')),
    FlashlightOn: React.lazy(() => import('@mui/icons-material/FlashlightOn')),
    FlashlightOnOutlined: React.lazy(() => import('@mui/icons-material/FlashlightOnOutlined')),
    FlashlightOnRounded: React.lazy(() => import('@mui/icons-material/FlashlightOnRounded')),
    FlashlightOnSharp: React.lazy(() => import('@mui/icons-material/FlashlightOnSharp')),
    FlashlightOnTwoTone: React.lazy(() => import('@mui/icons-material/FlashlightOnTwoTone')),
    FlashOff: React.lazy(() => import('@mui/icons-material/FlashOff')),
    FlashOffOutlined: React.lazy(() => import('@mui/icons-material/FlashOffOutlined')),
    FlashOffRounded: React.lazy(() => import('@mui/icons-material/FlashOffRounded')),
    FlashOffSharp: React.lazy(() => import('@mui/icons-material/FlashOffSharp')),
    FlashOffTwoTone: React.lazy(() => import('@mui/icons-material/FlashOffTwoTone')),
    FlashOn: React.lazy(() => import('@mui/icons-material/FlashOn')),
    FlashOnOutlined: React.lazy(() => import('@mui/icons-material/FlashOnOutlined')),
    FlashOnRounded: React.lazy(() => import('@mui/icons-material/FlashOnRounded')),
    FlashOnSharp: React.lazy(() => import('@mui/icons-material/FlashOnSharp')),
    FlashOnTwoTone: React.lazy(() => import('@mui/icons-material/FlashOnTwoTone')),
    Flatware: React.lazy(() => import('@mui/icons-material/Flatware')),
    FlatwareOutlined: React.lazy(() => import('@mui/icons-material/FlatwareOutlined')),
    FlatwareRounded: React.lazy(() => import('@mui/icons-material/FlatwareRounded')),
    FlatwareSharp: React.lazy(() => import('@mui/icons-material/FlatwareSharp')),
    FlatwareTwoTone: React.lazy(() => import('@mui/icons-material/FlatwareTwoTone')),
    FlightClass: React.lazy(() => import('@mui/icons-material/FlightClass')),
    FlightClassOutlined: React.lazy(() => import('@mui/icons-material/FlightClassOutlined')),
    FlightClassRounded: React.lazy(() => import('@mui/icons-material/FlightClassRounded')),
    FlightClassSharp: React.lazy(() => import('@mui/icons-material/FlightClassSharp')),
    FlightClassTwoTone: React.lazy(() => import('@mui/icons-material/FlightClassTwoTone')),
    Flight: React.lazy(() => import('@mui/icons-material/Flight')),
    FlightLand: React.lazy(() => import('@mui/icons-material/FlightLand')),
    FlightLandOutlined: React.lazy(() => import('@mui/icons-material/FlightLandOutlined')),
    FlightLandRounded: React.lazy(() => import('@mui/icons-material/FlightLandRounded')),
    FlightLandSharp: React.lazy(() => import('@mui/icons-material/FlightLandSharp')),
    FlightLandTwoTone: React.lazy(() => import('@mui/icons-material/FlightLandTwoTone')),
    FlightOutlined: React.lazy(() => import('@mui/icons-material/FlightOutlined')),
    FlightRounded: React.lazy(() => import('@mui/icons-material/FlightRounded')),
    FlightSharp: React.lazy(() => import('@mui/icons-material/FlightSharp')),
    FlightTakeoff: React.lazy(() => import('@mui/icons-material/FlightTakeoff')),
    FlightTakeoffOutlined: React.lazy(() => import('@mui/icons-material/FlightTakeoffOutlined')),
    FlightTakeoffRounded: React.lazy(() => import('@mui/icons-material/FlightTakeoffRounded')),
    FlightTakeoffSharp: React.lazy(() => import('@mui/icons-material/FlightTakeoffSharp')),
    FlightTakeoffTwoTone: React.lazy(() => import('@mui/icons-material/FlightTakeoffTwoTone')),
    FlightTwoTone: React.lazy(() => import('@mui/icons-material/FlightTwoTone')),
    FlipCameraAndroid: React.lazy(() => import('@mui/icons-material/FlipCameraAndroid')),
    FlipCameraAndroidOutlined: React.lazy(() => import('@mui/icons-material/FlipCameraAndroidOutlined')),
    FlipCameraAndroidRounded: React.lazy(() => import('@mui/icons-material/FlipCameraAndroidRounded')),
    FlipCameraAndroidSharp: React.lazy(() => import('@mui/icons-material/FlipCameraAndroidSharp')),
    FlipCameraAndroidTwoTone: React.lazy(() => import('@mui/icons-material/FlipCameraAndroidTwoTone')),
    FlipCameraIos: React.lazy(() => import('@mui/icons-material/FlipCameraIos')),
    FlipCameraIosOutlined: React.lazy(() => import('@mui/icons-material/FlipCameraIosOutlined')),
    FlipCameraIosRounded: React.lazy(() => import('@mui/icons-material/FlipCameraIosRounded')),
    FlipCameraIosSharp: React.lazy(() => import('@mui/icons-material/FlipCameraIosSharp')),
    FlipCameraIosTwoTone: React.lazy(() => import('@mui/icons-material/FlipCameraIosTwoTone')),
    Flip: React.lazy(() => import('@mui/icons-material/Flip')),
    FlipOutlined: React.lazy(() => import('@mui/icons-material/FlipOutlined')),
    FlipRounded: React.lazy(() => import('@mui/icons-material/FlipRounded')),
    FlipSharp: React.lazy(() => import('@mui/icons-material/FlipSharp')),
    FlipToBack: React.lazy(() => import('@mui/icons-material/FlipToBack')),
    FlipToBackOutlined: React.lazy(() => import('@mui/icons-material/FlipToBackOutlined')),
    FlipToBackRounded: React.lazy(() => import('@mui/icons-material/FlipToBackRounded')),
    FlipToBackSharp: React.lazy(() => import('@mui/icons-material/FlipToBackSharp')),
    FlipToBackTwoTone: React.lazy(() => import('@mui/icons-material/FlipToBackTwoTone')),
    FlipToFront: React.lazy(() => import('@mui/icons-material/FlipToFront')),
    FlipToFrontOutlined: React.lazy(() => import('@mui/icons-material/FlipToFrontOutlined')),
    FlipToFrontRounded: React.lazy(() => import('@mui/icons-material/FlipToFrontRounded')),
    FlipToFrontSharp: React.lazy(() => import('@mui/icons-material/FlipToFrontSharp')),
    FlipToFrontTwoTone: React.lazy(() => import('@mui/icons-material/FlipToFrontTwoTone')),
    FlipTwoTone: React.lazy(() => import('@mui/icons-material/FlipTwoTone')),
    Flood: React.lazy(() => import('@mui/icons-material/Flood')),
    FloodOutlined: React.lazy(() => import('@mui/icons-material/FloodOutlined')),
    FloodRounded: React.lazy(() => import('@mui/icons-material/FloodRounded')),
    FloodSharp: React.lazy(() => import('@mui/icons-material/FloodSharp')),
    FloodTwoTone: React.lazy(() => import('@mui/icons-material/FloodTwoTone')),
    Fluorescent: React.lazy(() => import('@mui/icons-material/Fluorescent')),
    FluorescentOutlined: React.lazy(() => import('@mui/icons-material/FluorescentOutlined')),
    FluorescentRounded: React.lazy(() => import('@mui/icons-material/FluorescentRounded')),
    FluorescentSharp: React.lazy(() => import('@mui/icons-material/FluorescentSharp')),
    FluorescentTwoTone: React.lazy(() => import('@mui/icons-material/FluorescentTwoTone')),
    FlutterDash: React.lazy(() => import('@mui/icons-material/FlutterDash')),
    FlutterDashOutlined: React.lazy(() => import('@mui/icons-material/FlutterDashOutlined')),
    FlutterDashRounded: React.lazy(() => import('@mui/icons-material/FlutterDashRounded')),
    FlutterDashSharp: React.lazy(() => import('@mui/icons-material/FlutterDashSharp')),
    FlutterDashTwoTone: React.lazy(() => import('@mui/icons-material/FlutterDashTwoTone')),
    FmdBad: React.lazy(() => import('@mui/icons-material/FmdBad')),
    FmdBadOutlined: React.lazy(() => import('@mui/icons-material/FmdBadOutlined')),
    FmdBadRounded: React.lazy(() => import('@mui/icons-material/FmdBadRounded')),
    FmdBadSharp: React.lazy(() => import('@mui/icons-material/FmdBadSharp')),
    FmdBadTwoTone: React.lazy(() => import('@mui/icons-material/FmdBadTwoTone')),
    FmdGood: React.lazy(() => import('@mui/icons-material/FmdGood')),
    FmdGoodOutlined: React.lazy(() => import('@mui/icons-material/FmdGoodOutlined')),
    FmdGoodRounded: React.lazy(() => import('@mui/icons-material/FmdGoodRounded')),
    FmdGoodSharp: React.lazy(() => import('@mui/icons-material/FmdGoodSharp')),
    FmdGoodTwoTone: React.lazy(() => import('@mui/icons-material/FmdGoodTwoTone')),
    FolderCopy: React.lazy(() => import('@mui/icons-material/FolderCopy')),
    FolderCopyOutlined: React.lazy(() => import('@mui/icons-material/FolderCopyOutlined')),
    FolderCopyRounded: React.lazy(() => import('@mui/icons-material/FolderCopyRounded')),
    FolderCopySharp: React.lazy(() => import('@mui/icons-material/FolderCopySharp')),
    FolderCopyTwoTone: React.lazy(() => import('@mui/icons-material/FolderCopyTwoTone')),
    FolderDelete: React.lazy(() => import('@mui/icons-material/FolderDelete')),
    FolderDeleteOutlined: React.lazy(() => import('@mui/icons-material/FolderDeleteOutlined')),
    FolderDeleteRounded: React.lazy(() => import('@mui/icons-material/FolderDeleteRounded')),
    FolderDeleteSharp: React.lazy(() => import('@mui/icons-material/FolderDeleteSharp')),
    FolderDeleteTwoTone: React.lazy(() => import('@mui/icons-material/FolderDeleteTwoTone')),
    Folder: React.lazy(() => import('@mui/icons-material/Folder')),
    FolderOff: React.lazy(() => import('@mui/icons-material/FolderOff')),
    FolderOffOutlined: React.lazy(() => import('@mui/icons-material/FolderOffOutlined')),
    FolderOffRounded: React.lazy(() => import('@mui/icons-material/FolderOffRounded')),
    FolderOffSharp: React.lazy(() => import('@mui/icons-material/FolderOffSharp')),
    FolderOffTwoTone: React.lazy(() => import('@mui/icons-material/FolderOffTwoTone')),
    FolderOpen: React.lazy(() => import('@mui/icons-material/FolderOpen')),
    FolderOpenOutlined: React.lazy(() => import('@mui/icons-material/FolderOpenOutlined')),
    FolderOpenRounded: React.lazy(() => import('@mui/icons-material/FolderOpenRounded')),
    FolderOpenSharp: React.lazy(() => import('@mui/icons-material/FolderOpenSharp')),
    FolderOpenTwoTone: React.lazy(() => import('@mui/icons-material/FolderOpenTwoTone')),
    FolderOutlined: React.lazy(() => import('@mui/icons-material/FolderOutlined')),
    FolderRounded: React.lazy(() => import('@mui/icons-material/FolderRounded')),
    FolderShared: React.lazy(() => import('@mui/icons-material/FolderShared')),
    FolderSharedOutlined: React.lazy(() => import('@mui/icons-material/FolderSharedOutlined')),
    FolderSharedRounded: React.lazy(() => import('@mui/icons-material/FolderSharedRounded')),
    FolderSharedSharp: React.lazy(() => import('@mui/icons-material/FolderSharedSharp')),
    FolderSharedTwoTone: React.lazy(() => import('@mui/icons-material/FolderSharedTwoTone')),
    FolderSharp: React.lazy(() => import('@mui/icons-material/FolderSharp')),
    FolderSpecial: React.lazy(() => import('@mui/icons-material/FolderSpecial')),
    FolderSpecialOutlined: React.lazy(() => import('@mui/icons-material/FolderSpecialOutlined')),
    FolderSpecialRounded: React.lazy(() => import('@mui/icons-material/FolderSpecialRounded')),
    FolderSpecialSharp: React.lazy(() => import('@mui/icons-material/FolderSpecialSharp')),
    FolderSpecialTwoTone: React.lazy(() => import('@mui/icons-material/FolderSpecialTwoTone')),
    FolderTwoTone: React.lazy(() => import('@mui/icons-material/FolderTwoTone')),
    FolderZip: React.lazy(() => import('@mui/icons-material/FolderZip')),
    FolderZipOutlined: React.lazy(() => import('@mui/icons-material/FolderZipOutlined')),
    FolderZipRounded: React.lazy(() => import('@mui/icons-material/FolderZipRounded')),
    FolderZipSharp: React.lazy(() => import('@mui/icons-material/FolderZipSharp')),
    FolderZipTwoTone: React.lazy(() => import('@mui/icons-material/FolderZipTwoTone')),
    FollowTheSigns: React.lazy(() => import('@mui/icons-material/FollowTheSigns')),
    FollowTheSignsOutlined: React.lazy(() => import('@mui/icons-material/FollowTheSignsOutlined')),
    FollowTheSignsRounded: React.lazy(() => import('@mui/icons-material/FollowTheSignsRounded')),
    FollowTheSignsSharp: React.lazy(() => import('@mui/icons-material/FollowTheSignsSharp')),
    FollowTheSignsTwoTone: React.lazy(() => import('@mui/icons-material/FollowTheSignsTwoTone')),
    FontDownload: React.lazy(() => import('@mui/icons-material/FontDownload')),
    FontDownloadOff: React.lazy(() => import('@mui/icons-material/FontDownloadOff')),
    FontDownloadOffOutlined: React.lazy(() => import('@mui/icons-material/FontDownloadOffOutlined')),
    FontDownloadOffRounded: React.lazy(() => import('@mui/icons-material/FontDownloadOffRounded')),
    FontDownloadOffSharp: React.lazy(() => import('@mui/icons-material/FontDownloadOffSharp')),
    FontDownloadOffTwoTone: React.lazy(() => import('@mui/icons-material/FontDownloadOffTwoTone')),
    FontDownloadOutlined: React.lazy(() => import('@mui/icons-material/FontDownloadOutlined')),
    FontDownloadRounded: React.lazy(() => import('@mui/icons-material/FontDownloadRounded')),
    FontDownloadSharp: React.lazy(() => import('@mui/icons-material/FontDownloadSharp')),
    FontDownloadTwoTone: React.lazy(() => import('@mui/icons-material/FontDownloadTwoTone')),
    FoodBank: React.lazy(() => import('@mui/icons-material/FoodBank')),
    FoodBankOutlined: React.lazy(() => import('@mui/icons-material/FoodBankOutlined')),
    FoodBankRounded: React.lazy(() => import('@mui/icons-material/FoodBankRounded')),
    FoodBankSharp: React.lazy(() => import('@mui/icons-material/FoodBankSharp')),
    FoodBankTwoTone: React.lazy(() => import('@mui/icons-material/FoodBankTwoTone')),
    Forest: React.lazy(() => import('@mui/icons-material/Forest')),
    ForestOutlined: React.lazy(() => import('@mui/icons-material/ForestOutlined')),
    ForestRounded: React.lazy(() => import('@mui/icons-material/ForestRounded')),
    ForestSharp: React.lazy(() => import('@mui/icons-material/ForestSharp')),
    ForestTwoTone: React.lazy(() => import('@mui/icons-material/ForestTwoTone')),
    ForkLeft: React.lazy(() => import('@mui/icons-material/ForkLeft')),
    ForkLeftOutlined: React.lazy(() => import('@mui/icons-material/ForkLeftOutlined')),
    ForkLeftRounded: React.lazy(() => import('@mui/icons-material/ForkLeftRounded')),
    ForkLeftSharp: React.lazy(() => import('@mui/icons-material/ForkLeftSharp')),
    ForkLeftTwoTone: React.lazy(() => import('@mui/icons-material/ForkLeftTwoTone')),
    ForkRight: React.lazy(() => import('@mui/icons-material/ForkRight')),
    ForkRightOutlined: React.lazy(() => import('@mui/icons-material/ForkRightOutlined')),
    ForkRightRounded: React.lazy(() => import('@mui/icons-material/ForkRightRounded')),
    ForkRightSharp: React.lazy(() => import('@mui/icons-material/ForkRightSharp')),
    ForkRightTwoTone: React.lazy(() => import('@mui/icons-material/ForkRightTwoTone')),
    FormatAlignCenter: React.lazy(() => import('@mui/icons-material/FormatAlignCenter')),
    FormatAlignCenterOutlined: React.lazy(() => import('@mui/icons-material/FormatAlignCenterOutlined')),
    FormatAlignCenterRounded: React.lazy(() => import('@mui/icons-material/FormatAlignCenterRounded')),
    FormatAlignCenterSharp: React.lazy(() => import('@mui/icons-material/FormatAlignCenterSharp')),
    FormatAlignCenterTwoTone: React.lazy(() => import('@mui/icons-material/FormatAlignCenterTwoTone')),
    FormatAlignJustify: React.lazy(() => import('@mui/icons-material/FormatAlignJustify')),
    FormatAlignJustifyOutlined: React.lazy(() => import('@mui/icons-material/FormatAlignJustifyOutlined')),
    FormatAlignJustifyRounded: React.lazy(() => import('@mui/icons-material/FormatAlignJustifyRounded')),
    FormatAlignJustifySharp: React.lazy(() => import('@mui/icons-material/FormatAlignJustifySharp')),
    FormatAlignJustifyTwoTone: React.lazy(() => import('@mui/icons-material/FormatAlignJustifyTwoTone')),
    FormatAlignLeft: React.lazy(() => import('@mui/icons-material/FormatAlignLeft')),
    FormatAlignLeftOutlined: React.lazy(() => import('@mui/icons-material/FormatAlignLeftOutlined')),
    FormatAlignLeftRounded: React.lazy(() => import('@mui/icons-material/FormatAlignLeftRounded')),
    FormatAlignLeftSharp: React.lazy(() => import('@mui/icons-material/FormatAlignLeftSharp')),
    FormatAlignLeftTwoTone: React.lazy(() => import('@mui/icons-material/FormatAlignLeftTwoTone')),
    FormatAlignRight: React.lazy(() => import('@mui/icons-material/FormatAlignRight')),
    FormatAlignRightOutlined: React.lazy(() => import('@mui/icons-material/FormatAlignRightOutlined')),
    FormatAlignRightRounded: React.lazy(() => import('@mui/icons-material/FormatAlignRightRounded')),
    FormatAlignRightSharp: React.lazy(() => import('@mui/icons-material/FormatAlignRightSharp')),
    FormatAlignRightTwoTone: React.lazy(() => import('@mui/icons-material/FormatAlignRightTwoTone')),
    FormatBold: React.lazy(() => import('@mui/icons-material/FormatBold')),
    FormatBoldOutlined: React.lazy(() => import('@mui/icons-material/FormatBoldOutlined')),
    FormatBoldRounded: React.lazy(() => import('@mui/icons-material/FormatBoldRounded')),
    FormatBoldSharp: React.lazy(() => import('@mui/icons-material/FormatBoldSharp')),
    FormatBoldTwoTone: React.lazy(() => import('@mui/icons-material/FormatBoldTwoTone')),
    FormatClear: React.lazy(() => import('@mui/icons-material/FormatClear')),
    FormatClearOutlined: React.lazy(() => import('@mui/icons-material/FormatClearOutlined')),
    FormatClearRounded: React.lazy(() => import('@mui/icons-material/FormatClearRounded')),
    FormatClearSharp: React.lazy(() => import('@mui/icons-material/FormatClearSharp')),
    FormatClearTwoTone: React.lazy(() => import('@mui/icons-material/FormatClearTwoTone')),
    FormatColorFill: React.lazy(() => import('@mui/icons-material/FormatColorFill')),
    FormatColorFillOutlined: React.lazy(() => import('@mui/icons-material/FormatColorFillOutlined')),
    FormatColorFillRounded: React.lazy(() => import('@mui/icons-material/FormatColorFillRounded')),
    FormatColorFillSharp: React.lazy(() => import('@mui/icons-material/FormatColorFillSharp')),
    FormatColorFillTwoTone: React.lazy(() => import('@mui/icons-material/FormatColorFillTwoTone')),
    FormatColorReset: React.lazy(() => import('@mui/icons-material/FormatColorReset')),
    FormatColorResetOutlined: React.lazy(() => import('@mui/icons-material/FormatColorResetOutlined')),
    FormatColorResetRounded: React.lazy(() => import('@mui/icons-material/FormatColorResetRounded')),
    FormatColorResetSharp: React.lazy(() => import('@mui/icons-material/FormatColorResetSharp')),
    FormatColorResetTwoTone: React.lazy(() => import('@mui/icons-material/FormatColorResetTwoTone')),
    FormatColorText: React.lazy(() => import('@mui/icons-material/FormatColorText')),
    FormatColorTextOutlined: React.lazy(() => import('@mui/icons-material/FormatColorTextOutlined')),
    FormatColorTextRounded: React.lazy(() => import('@mui/icons-material/FormatColorTextRounded')),
    FormatColorTextSharp: React.lazy(() => import('@mui/icons-material/FormatColorTextSharp')),
    FormatColorTextTwoTone: React.lazy(() => import('@mui/icons-material/FormatColorTextTwoTone')),
    FormatIndentDecrease: React.lazy(() => import('@mui/icons-material/FormatIndentDecrease')),
    FormatIndentDecreaseOutlined: React.lazy(() => import('@mui/icons-material/FormatIndentDecreaseOutlined')),
    FormatIndentDecreaseRounded: React.lazy(() => import('@mui/icons-material/FormatIndentDecreaseRounded')),
    FormatIndentDecreaseSharp: React.lazy(() => import('@mui/icons-material/FormatIndentDecreaseSharp')),
    FormatIndentDecreaseTwoTone: React.lazy(() => import('@mui/icons-material/FormatIndentDecreaseTwoTone')),
    FormatIndentIncrease: React.lazy(() => import('@mui/icons-material/FormatIndentIncrease')),
    FormatIndentIncreaseOutlined: React.lazy(() => import('@mui/icons-material/FormatIndentIncreaseOutlined')),
    FormatIndentIncreaseRounded: React.lazy(() => import('@mui/icons-material/FormatIndentIncreaseRounded')),
    FormatIndentIncreaseSharp: React.lazy(() => import('@mui/icons-material/FormatIndentIncreaseSharp')),
    FormatIndentIncreaseTwoTone: React.lazy(() => import('@mui/icons-material/FormatIndentIncreaseTwoTone')),
    FormatItalic: React.lazy(() => import('@mui/icons-material/FormatItalic')),
    FormatItalicOutlined: React.lazy(() => import('@mui/icons-material/FormatItalicOutlined')),
    FormatItalicRounded: React.lazy(() => import('@mui/icons-material/FormatItalicRounded')),
    FormatItalicSharp: React.lazy(() => import('@mui/icons-material/FormatItalicSharp')),
    FormatItalicTwoTone: React.lazy(() => import('@mui/icons-material/FormatItalicTwoTone')),
    FormatLineSpacing: React.lazy(() => import('@mui/icons-material/FormatLineSpacing')),
    FormatLineSpacingOutlined: React.lazy(() => import('@mui/icons-material/FormatLineSpacingOutlined')),
    FormatLineSpacingRounded: React.lazy(() => import('@mui/icons-material/FormatLineSpacingRounded')),
    FormatLineSpacingSharp: React.lazy(() => import('@mui/icons-material/FormatLineSpacingSharp')),
    FormatLineSpacingTwoTone: React.lazy(() => import('@mui/icons-material/FormatLineSpacingTwoTone')),
    FormatListBulleted: React.lazy(() => import('@mui/icons-material/FormatListBulleted')),
    FormatListBulletedOutlined: React.lazy(() => import('@mui/icons-material/FormatListBulletedOutlined')),
    FormatListBulletedRounded: React.lazy(() => import('@mui/icons-material/FormatListBulletedRounded')),
    FormatListBulletedSharp: React.lazy(() => import('@mui/icons-material/FormatListBulletedSharp')),
    FormatListBulletedTwoTone: React.lazy(() => import('@mui/icons-material/FormatListBulletedTwoTone')),
    FormatListNumbered: React.lazy(() => import('@mui/icons-material/FormatListNumbered')),
    FormatListNumberedOutlined: React.lazy(() => import('@mui/icons-material/FormatListNumberedOutlined')),
    FormatListNumberedRounded: React.lazy(() => import('@mui/icons-material/FormatListNumberedRounded')),
    FormatListNumberedRtl: React.lazy(() => import('@mui/icons-material/FormatListNumberedRtl')),
    FormatListNumberedRtlOutlined: React.lazy(() => import('@mui/icons-material/FormatListNumberedRtlOutlined')),
    FormatListNumberedRtlRounded: React.lazy(() => import('@mui/icons-material/FormatListNumberedRtlRounded')),
    FormatListNumberedRtlSharp: React.lazy(() => import('@mui/icons-material/FormatListNumberedRtlSharp')),
    FormatListNumberedRtlTwoTone: React.lazy(() => import('@mui/icons-material/FormatListNumberedRtlTwoTone')),
    FormatListNumberedSharp: React.lazy(() => import('@mui/icons-material/FormatListNumberedSharp')),
    FormatListNumberedTwoTone: React.lazy(() => import('@mui/icons-material/FormatListNumberedTwoTone')),
    FormatOverline: React.lazy(() => import('@mui/icons-material/FormatOverline')),
    FormatOverlineOutlined: React.lazy(() => import('@mui/icons-material/FormatOverlineOutlined')),
    FormatOverlineRounded: React.lazy(() => import('@mui/icons-material/FormatOverlineRounded')),
    FormatOverlineSharp: React.lazy(() => import('@mui/icons-material/FormatOverlineSharp')),
    FormatOverlineTwoTone: React.lazy(() => import('@mui/icons-material/FormatOverlineTwoTone')),
    FormatPaint: React.lazy(() => import('@mui/icons-material/FormatPaint')),
    FormatPaintOutlined: React.lazy(() => import('@mui/icons-material/FormatPaintOutlined')),
    FormatPaintRounded: React.lazy(() => import('@mui/icons-material/FormatPaintRounded')),
    FormatPaintSharp: React.lazy(() => import('@mui/icons-material/FormatPaintSharp')),
    FormatPaintTwoTone: React.lazy(() => import('@mui/icons-material/FormatPaintTwoTone')),
    FormatQuote: React.lazy(() => import('@mui/icons-material/FormatQuote')),
    FormatQuoteOutlined: React.lazy(() => import('@mui/icons-material/FormatQuoteOutlined')),
    FormatQuoteRounded: React.lazy(() => import('@mui/icons-material/FormatQuoteRounded')),
    FormatQuoteSharp: React.lazy(() => import('@mui/icons-material/FormatQuoteSharp')),
    FormatQuoteTwoTone: React.lazy(() => import('@mui/icons-material/FormatQuoteTwoTone')),
    FormatShapes: React.lazy(() => import('@mui/icons-material/FormatShapes')),
    FormatShapesOutlined: React.lazy(() => import('@mui/icons-material/FormatShapesOutlined')),
    FormatShapesRounded: React.lazy(() => import('@mui/icons-material/FormatShapesRounded')),
    FormatShapesSharp: React.lazy(() => import('@mui/icons-material/FormatShapesSharp')),
    FormatShapesTwoTone: React.lazy(() => import('@mui/icons-material/FormatShapesTwoTone')),
    FormatSize: React.lazy(() => import('@mui/icons-material/FormatSize')),
    FormatSizeOutlined: React.lazy(() => import('@mui/icons-material/FormatSizeOutlined')),
    FormatSizeRounded: React.lazy(() => import('@mui/icons-material/FormatSizeRounded')),
    FormatSizeSharp: React.lazy(() => import('@mui/icons-material/FormatSizeSharp')),
    FormatSizeTwoTone: React.lazy(() => import('@mui/icons-material/FormatSizeTwoTone')),
    FormatStrikethrough: React.lazy(() => import('@mui/icons-material/FormatStrikethrough')),
    FormatStrikethroughOutlined: React.lazy(() => import('@mui/icons-material/FormatStrikethroughOutlined')),
    FormatStrikethroughRounded: React.lazy(() => import('@mui/icons-material/FormatStrikethroughRounded')),
    FormatStrikethroughSharp: React.lazy(() => import('@mui/icons-material/FormatStrikethroughSharp')),
    FormatStrikethroughTwoTone: React.lazy(() => import('@mui/icons-material/FormatStrikethroughTwoTone')),
    FormatTextdirectionLToR: React.lazy(() => import('@mui/icons-material/FormatTextdirectionLToR')),
    FormatTextdirectionLToROutlined: React.lazy(() => import('@mui/icons-material/FormatTextdirectionLToROutlined')),
    FormatTextdirectionLToRRounded: React.lazy(() => import('@mui/icons-material/FormatTextdirectionLToRRounded')),
    FormatTextdirectionLToRSharp: React.lazy(() => import('@mui/icons-material/FormatTextdirectionLToRSharp')),
    FormatTextdirectionLToRTwoTone: React.lazy(() => import('@mui/icons-material/FormatTextdirectionLToRTwoTone')),
    FormatTextdirectionRToL: React.lazy(() => import('@mui/icons-material/FormatTextdirectionRToL')),
    FormatTextdirectionRToLOutlined: React.lazy(() => import('@mui/icons-material/FormatTextdirectionRToLOutlined')),
    FormatTextdirectionRToLRounded: React.lazy(() => import('@mui/icons-material/FormatTextdirectionRToLRounded')),
    FormatTextdirectionRToLSharp: React.lazy(() => import('@mui/icons-material/FormatTextdirectionRToLSharp')),
    FormatTextdirectionRToLTwoTone: React.lazy(() => import('@mui/icons-material/FormatTextdirectionRToLTwoTone')),
    FormatUnderlined: React.lazy(() => import('@mui/icons-material/FormatUnderlined')),
    FormatUnderlinedOutlined: React.lazy(() => import('@mui/icons-material/FormatUnderlinedOutlined')),
    FormatUnderlinedRounded: React.lazy(() => import('@mui/icons-material/FormatUnderlinedRounded')),
    FormatUnderlinedSharp: React.lazy(() => import('@mui/icons-material/FormatUnderlinedSharp')),
    FormatUnderlinedTwoTone: React.lazy(() => import('@mui/icons-material/FormatUnderlinedTwoTone')),
    Fort: React.lazy(() => import('@mui/icons-material/Fort')),
    FortOutlined: React.lazy(() => import('@mui/icons-material/FortOutlined')),
    FortRounded: React.lazy(() => import('@mui/icons-material/FortRounded')),
    FortSharp: React.lazy(() => import('@mui/icons-material/FortSharp')),
    FortTwoTone: React.lazy(() => import('@mui/icons-material/FortTwoTone')),
    Forum: React.lazy(() => import('@mui/icons-material/Forum')),
    ForumOutlined: React.lazy(() => import('@mui/icons-material/ForumOutlined')),
    ForumRounded: React.lazy(() => import('@mui/icons-material/ForumRounded')),
    ForumSharp: React.lazy(() => import('@mui/icons-material/ForumSharp')),
    ForumTwoTone: React.lazy(() => import('@mui/icons-material/ForumTwoTone')),
    Forward10: React.lazy(() => import('@mui/icons-material/Forward10')),
    Forward10Outlined: React.lazy(() => import('@mui/icons-material/Forward10Outlined')),
    Forward10Rounded: React.lazy(() => import('@mui/icons-material/Forward10Rounded')),
    Forward10Sharp: React.lazy(() => import('@mui/icons-material/Forward10Sharp')),
    Forward10TwoTone: React.lazy(() => import('@mui/icons-material/Forward10TwoTone')),
    Forward30: React.lazy(() => import('@mui/icons-material/Forward30')),
    Forward30Outlined: React.lazy(() => import('@mui/icons-material/Forward30Outlined')),
    Forward30Rounded: React.lazy(() => import('@mui/icons-material/Forward30Rounded')),
    Forward30Sharp: React.lazy(() => import('@mui/icons-material/Forward30Sharp')),
    Forward30TwoTone: React.lazy(() => import('@mui/icons-material/Forward30TwoTone')),
    Forward5: React.lazy(() => import('@mui/icons-material/Forward5')),
    Forward5Outlined: React.lazy(() => import('@mui/icons-material/Forward5Outlined')),
    Forward5Rounded: React.lazy(() => import('@mui/icons-material/Forward5Rounded')),
    Forward5Sharp: React.lazy(() => import('@mui/icons-material/Forward5Sharp')),
    Forward5TwoTone: React.lazy(() => import('@mui/icons-material/Forward5TwoTone')),
    Forward: React.lazy(() => import('@mui/icons-material/Forward')),
    ForwardOutlined: React.lazy(() => import('@mui/icons-material/ForwardOutlined')),
    ForwardRounded: React.lazy(() => import('@mui/icons-material/ForwardRounded')),
    ForwardSharp: React.lazy(() => import('@mui/icons-material/ForwardSharp')),
    ForwardToInbox: React.lazy(() => import('@mui/icons-material/ForwardToInbox')),
    ForwardToInboxOutlined: React.lazy(() => import('@mui/icons-material/ForwardToInboxOutlined')),
    ForwardToInboxRounded: React.lazy(() => import('@mui/icons-material/ForwardToInboxRounded')),
    ForwardToInboxSharp: React.lazy(() => import('@mui/icons-material/ForwardToInboxSharp')),
    ForwardToInboxTwoTone: React.lazy(() => import('@mui/icons-material/ForwardToInboxTwoTone')),
    ForwardTwoTone: React.lazy(() => import('@mui/icons-material/ForwardTwoTone')),
    Foundation: React.lazy(() => import('@mui/icons-material/Foundation')),
    FoundationOutlined: React.lazy(() => import('@mui/icons-material/FoundationOutlined')),
    FoundationRounded: React.lazy(() => import('@mui/icons-material/FoundationRounded')),
    FoundationSharp: React.lazy(() => import('@mui/icons-material/FoundationSharp')),
    FoundationTwoTone: React.lazy(() => import('@mui/icons-material/FoundationTwoTone')),
    FourGMobiledata: React.lazy(() => import('@mui/icons-material/FourGMobiledata')),
    FourGMobiledataOutlined: React.lazy(() => import('@mui/icons-material/FourGMobiledataOutlined')),
    FourGMobiledataRounded: React.lazy(() => import('@mui/icons-material/FourGMobiledataRounded')),
    FourGMobiledataSharp: React.lazy(() => import('@mui/icons-material/FourGMobiledataSharp')),
    FourGMobiledataTwoTone: React.lazy(() => import('@mui/icons-material/FourGMobiledataTwoTone')),
    FourGPlusMobiledata: React.lazy(() => import('@mui/icons-material/FourGPlusMobiledata')),
    FourGPlusMobiledataOutlined: React.lazy(() => import('@mui/icons-material/FourGPlusMobiledataOutlined')),
    FourGPlusMobiledataRounded: React.lazy(() => import('@mui/icons-material/FourGPlusMobiledataRounded')),
    FourGPlusMobiledataSharp: React.lazy(() => import('@mui/icons-material/FourGPlusMobiledataSharp')),
    FourGPlusMobiledataTwoTone: React.lazy(() => import('@mui/icons-material/FourGPlusMobiledataTwoTone')),
    FourK: React.lazy(() => import('@mui/icons-material/FourK')),
    FourKOutlined: React.lazy(() => import('@mui/icons-material/FourKOutlined')),
    FourKPlus: React.lazy(() => import('@mui/icons-material/FourKPlus')),
    FourKPlusOutlined: React.lazy(() => import('@mui/icons-material/FourKPlusOutlined')),
    FourKPlusRounded: React.lazy(() => import('@mui/icons-material/FourKPlusRounded')),
    FourKPlusSharp: React.lazy(() => import('@mui/icons-material/FourKPlusSharp')),
    FourKPlusTwoTone: React.lazy(() => import('@mui/icons-material/FourKPlusTwoTone')),
    FourKRounded: React.lazy(() => import('@mui/icons-material/FourKRounded')),
    FourKSharp: React.lazy(() => import('@mui/icons-material/FourKSharp')),
    FourKTwoTone: React.lazy(() => import('@mui/icons-material/FourKTwoTone')),
    FourMp: React.lazy(() => import('@mui/icons-material/FourMp')),
    FourMpOutlined: React.lazy(() => import('@mui/icons-material/FourMpOutlined')),
    FourMpRounded: React.lazy(() => import('@mui/icons-material/FourMpRounded')),
    FourMpSharp: React.lazy(() => import('@mui/icons-material/FourMpSharp')),
    FourMpTwoTone: React.lazy(() => import('@mui/icons-material/FourMpTwoTone')),
    FourteenMp: React.lazy(() => import('@mui/icons-material/FourteenMp')),
    FourteenMpOutlined: React.lazy(() => import('@mui/icons-material/FourteenMpOutlined')),
    FourteenMpRounded: React.lazy(() => import('@mui/icons-material/FourteenMpRounded')),
    FourteenMpSharp: React.lazy(() => import('@mui/icons-material/FourteenMpSharp')),
    FourteenMpTwoTone: React.lazy(() => import('@mui/icons-material/FourteenMpTwoTone')),
    FreeBreakfast: React.lazy(() => import('@mui/icons-material/FreeBreakfast')),
    FreeBreakfastOutlined: React.lazy(() => import('@mui/icons-material/FreeBreakfastOutlined')),
    FreeBreakfastRounded: React.lazy(() => import('@mui/icons-material/FreeBreakfastRounded')),
    FreeBreakfastSharp: React.lazy(() => import('@mui/icons-material/FreeBreakfastSharp')),
    FreeBreakfastTwoTone: React.lazy(() => import('@mui/icons-material/FreeBreakfastTwoTone')),
    Fullscreen: React.lazy(() => import('@mui/icons-material/Fullscreen')),
    FullscreenExit: React.lazy(() => import('@mui/icons-material/FullscreenExit')),
    FullscreenExitOutlined: React.lazy(() => import('@mui/icons-material/FullscreenExitOutlined')),
    FullscreenExitRounded: React.lazy(() => import('@mui/icons-material/FullscreenExitRounded')),
    FullscreenExitSharp: React.lazy(() => import('@mui/icons-material/FullscreenExitSharp')),
    FullscreenExitTwoTone: React.lazy(() => import('@mui/icons-material/FullscreenExitTwoTone')),
    FullscreenOutlined: React.lazy(() => import('@mui/icons-material/FullscreenOutlined')),
    FullscreenRounded: React.lazy(() => import('@mui/icons-material/FullscreenRounded')),
    FullscreenSharp: React.lazy(() => import('@mui/icons-material/FullscreenSharp')),
    FullscreenTwoTone: React.lazy(() => import('@mui/icons-material/FullscreenTwoTone')),
    Functions: React.lazy(() => import('@mui/icons-material/Functions')),
    FunctionsOutlined: React.lazy(() => import('@mui/icons-material/FunctionsOutlined')),
    FunctionsRounded: React.lazy(() => import('@mui/icons-material/FunctionsRounded')),
    FunctionsSharp: React.lazy(() => import('@mui/icons-material/FunctionsSharp')),
    FunctionsTwoTone: React.lazy(() => import('@mui/icons-material/FunctionsTwoTone')),
    Gamepad: React.lazy(() => import('@mui/icons-material/Gamepad')),
    GamepadOutlined: React.lazy(() => import('@mui/icons-material/GamepadOutlined')),
    GamepadRounded: React.lazy(() => import('@mui/icons-material/GamepadRounded')),
    GamepadSharp: React.lazy(() => import('@mui/icons-material/GamepadSharp')),
    GamepadTwoTone: React.lazy(() => import('@mui/icons-material/GamepadTwoTone')),
    Games: React.lazy(() => import('@mui/icons-material/Games')),
    GamesOutlined: React.lazy(() => import('@mui/icons-material/GamesOutlined')),
    GamesRounded: React.lazy(() => import('@mui/icons-material/GamesRounded')),
    GamesSharp: React.lazy(() => import('@mui/icons-material/GamesSharp')),
    GamesTwoTone: React.lazy(() => import('@mui/icons-material/GamesTwoTone')),
    Garage: React.lazy(() => import('@mui/icons-material/Garage')),
    GarageOutlined: React.lazy(() => import('@mui/icons-material/GarageOutlined')),
    GarageRounded: React.lazy(() => import('@mui/icons-material/GarageRounded')),
    GarageSharp: React.lazy(() => import('@mui/icons-material/GarageSharp')),
    GarageTwoTone: React.lazy(() => import('@mui/icons-material/GarageTwoTone')),
    GasMeter: React.lazy(() => import('@mui/icons-material/GasMeter')),
    GasMeterOutlined: React.lazy(() => import('@mui/icons-material/GasMeterOutlined')),
    GasMeterRounded: React.lazy(() => import('@mui/icons-material/GasMeterRounded')),
    GasMeterSharp: React.lazy(() => import('@mui/icons-material/GasMeterSharp')),
    GasMeterTwoTone: React.lazy(() => import('@mui/icons-material/GasMeterTwoTone')),
    Gavel: React.lazy(() => import('@mui/icons-material/Gavel')),
    GavelOutlined: React.lazy(() => import('@mui/icons-material/GavelOutlined')),
    GavelRounded: React.lazy(() => import('@mui/icons-material/GavelRounded')),
    GavelSharp: React.lazy(() => import('@mui/icons-material/GavelSharp')),
    GavelTwoTone: React.lazy(() => import('@mui/icons-material/GavelTwoTone')),
    Gesture: React.lazy(() => import('@mui/icons-material/Gesture')),
    GestureOutlined: React.lazy(() => import('@mui/icons-material/GestureOutlined')),
    GestureRounded: React.lazy(() => import('@mui/icons-material/GestureRounded')),
    GestureSharp: React.lazy(() => import('@mui/icons-material/GestureSharp')),
    GestureTwoTone: React.lazy(() => import('@mui/icons-material/GestureTwoTone')),
    GetApp: React.lazy(() => import('@mui/icons-material/GetApp')),
    GetAppOutlined: React.lazy(() => import('@mui/icons-material/GetAppOutlined')),
    GetAppRounded: React.lazy(() => import('@mui/icons-material/GetAppRounded')),
    GetAppSharp: React.lazy(() => import('@mui/icons-material/GetAppSharp')),
    GetAppTwoTone: React.lazy(() => import('@mui/icons-material/GetAppTwoTone')),
    GifBox: React.lazy(() => import('@mui/icons-material/GifBox')),
    GifBoxOutlined: React.lazy(() => import('@mui/icons-material/GifBoxOutlined')),
    GifBoxRounded: React.lazy(() => import('@mui/icons-material/GifBoxRounded')),
    GifBoxSharp: React.lazy(() => import('@mui/icons-material/GifBoxSharp')),
    GifBoxTwoTone: React.lazy(() => import('@mui/icons-material/GifBoxTwoTone')),
    Gif: React.lazy(() => import('@mui/icons-material/Gif')),
    GifOutlined: React.lazy(() => import('@mui/icons-material/GifOutlined')),
    GifRounded: React.lazy(() => import('@mui/icons-material/GifRounded')),
    GifSharp: React.lazy(() => import('@mui/icons-material/GifSharp')),
    GifTwoTone: React.lazy(() => import('@mui/icons-material/GifTwoTone')),
    Girl: React.lazy(() => import('@mui/icons-material/Girl')),
    GirlOutlined: React.lazy(() => import('@mui/icons-material/GirlOutlined')),
    GirlRounded: React.lazy(() => import('@mui/icons-material/GirlRounded')),
    GirlSharp: React.lazy(() => import('@mui/icons-material/GirlSharp')),
    GirlTwoTone: React.lazy(() => import('@mui/icons-material/GirlTwoTone')),
    Gite: React.lazy(() => import('@mui/icons-material/Gite')),
    GiteOutlined: React.lazy(() => import('@mui/icons-material/GiteOutlined')),
    GiteRounded: React.lazy(() => import('@mui/icons-material/GiteRounded')),
    GiteSharp: React.lazy(() => import('@mui/icons-material/GiteSharp')),
    GiteTwoTone: React.lazy(() => import('@mui/icons-material/GiteTwoTone')),
    GitHub: React.lazy(() => import('@mui/icons-material/GitHub')),
    GMobiledata: React.lazy(() => import('@mui/icons-material/GMobiledata')),
    GMobiledataOutlined: React.lazy(() => import('@mui/icons-material/GMobiledataOutlined')),
    GMobiledataRounded: React.lazy(() => import('@mui/icons-material/GMobiledataRounded')),
    GMobiledataSharp: React.lazy(() => import('@mui/icons-material/GMobiledataSharp')),
    GMobiledataTwoTone: React.lazy(() => import('@mui/icons-material/GMobiledataTwoTone')),
    GolfCourse: React.lazy(() => import('@mui/icons-material/GolfCourse')),
    GolfCourseOutlined: React.lazy(() => import('@mui/icons-material/GolfCourseOutlined')),
    GolfCourseRounded: React.lazy(() => import('@mui/icons-material/GolfCourseRounded')),
    GolfCourseSharp: React.lazy(() => import('@mui/icons-material/GolfCourseSharp')),
    GolfCourseTwoTone: React.lazy(() => import('@mui/icons-material/GolfCourseTwoTone')),
    Google: React.lazy(() => import('@mui/icons-material/Google')),
    GppBad: React.lazy(() => import('@mui/icons-material/GppBad')),
    GppBadOutlined: React.lazy(() => import('@mui/icons-material/GppBadOutlined')),
    GppBadRounded: React.lazy(() => import('@mui/icons-material/GppBadRounded')),
    GppBadSharp: React.lazy(() => import('@mui/icons-material/GppBadSharp')),
    GppBadTwoTone: React.lazy(() => import('@mui/icons-material/GppBadTwoTone')),
    GppGood: React.lazy(() => import('@mui/icons-material/GppGood')),
    GppGoodOutlined: React.lazy(() => import('@mui/icons-material/GppGoodOutlined')),
    GppGoodRounded: React.lazy(() => import('@mui/icons-material/GppGoodRounded')),
    GppGoodSharp: React.lazy(() => import('@mui/icons-material/GppGoodSharp')),
    GppGoodTwoTone: React.lazy(() => import('@mui/icons-material/GppGoodTwoTone')),
    GppMaybe: React.lazy(() => import('@mui/icons-material/GppMaybe')),
    GppMaybeOutlined: React.lazy(() => import('@mui/icons-material/GppMaybeOutlined')),
    GppMaybeRounded: React.lazy(() => import('@mui/icons-material/GppMaybeRounded')),
    GppMaybeSharp: React.lazy(() => import('@mui/icons-material/GppMaybeSharp')),
    GppMaybeTwoTone: React.lazy(() => import('@mui/icons-material/GppMaybeTwoTone')),
    GpsFixed: React.lazy(() => import('@mui/icons-material/GpsFixed')),
    GpsFixedOutlined: React.lazy(() => import('@mui/icons-material/GpsFixedOutlined')),
    GpsFixedRounded: React.lazy(() => import('@mui/icons-material/GpsFixedRounded')),
    GpsFixedSharp: React.lazy(() => import('@mui/icons-material/GpsFixedSharp')),
    GpsFixedTwoTone: React.lazy(() => import('@mui/icons-material/GpsFixedTwoTone')),
    GpsNotFixed: React.lazy(() => import('@mui/icons-material/GpsNotFixed')),
    GpsNotFixedOutlined: React.lazy(() => import('@mui/icons-material/GpsNotFixedOutlined')),
    GpsNotFixedRounded: React.lazy(() => import('@mui/icons-material/GpsNotFixedRounded')),
    GpsNotFixedSharp: React.lazy(() => import('@mui/icons-material/GpsNotFixedSharp')),
    GpsNotFixedTwoTone: React.lazy(() => import('@mui/icons-material/GpsNotFixedTwoTone')),
    GpsOff: React.lazy(() => import('@mui/icons-material/GpsOff')),
    GpsOffOutlined: React.lazy(() => import('@mui/icons-material/GpsOffOutlined')),
    GpsOffRounded: React.lazy(() => import('@mui/icons-material/GpsOffRounded')),
    GpsOffSharp: React.lazy(() => import('@mui/icons-material/GpsOffSharp')),
    GpsOffTwoTone: React.lazy(() => import('@mui/icons-material/GpsOffTwoTone')),
    Grade: React.lazy(() => import('@mui/icons-material/Grade')),
    GradeOutlined: React.lazy(() => import('@mui/icons-material/GradeOutlined')),
    GradeRounded: React.lazy(() => import('@mui/icons-material/GradeRounded')),
    GradeSharp: React.lazy(() => import('@mui/icons-material/GradeSharp')),
    GradeTwoTone: React.lazy(() => import('@mui/icons-material/GradeTwoTone')),
    Gradient: React.lazy(() => import('@mui/icons-material/Gradient')),
    GradientOutlined: React.lazy(() => import('@mui/icons-material/GradientOutlined')),
    GradientRounded: React.lazy(() => import('@mui/icons-material/GradientRounded')),
    GradientSharp: React.lazy(() => import('@mui/icons-material/GradientSharp')),
    GradientTwoTone: React.lazy(() => import('@mui/icons-material/GradientTwoTone')),
    Grading: React.lazy(() => import('@mui/icons-material/Grading')),
    GradingOutlined: React.lazy(() => import('@mui/icons-material/GradingOutlined')),
    GradingRounded: React.lazy(() => import('@mui/icons-material/GradingRounded')),
    GradingSharp: React.lazy(() => import('@mui/icons-material/GradingSharp')),
    GradingTwoTone: React.lazy(() => import('@mui/icons-material/GradingTwoTone')),
    Grain: React.lazy(() => import('@mui/icons-material/Grain')),
    GrainOutlined: React.lazy(() => import('@mui/icons-material/GrainOutlined')),
    GrainRounded: React.lazy(() => import('@mui/icons-material/GrainRounded')),
    GrainSharp: React.lazy(() => import('@mui/icons-material/GrainSharp')),
    GrainTwoTone: React.lazy(() => import('@mui/icons-material/GrainTwoTone')),
    GraphicEq: React.lazy(() => import('@mui/icons-material/GraphicEq')),
    GraphicEqOutlined: React.lazy(() => import('@mui/icons-material/GraphicEqOutlined')),
    GraphicEqRounded: React.lazy(() => import('@mui/icons-material/GraphicEqRounded')),
    GraphicEqSharp: React.lazy(() => import('@mui/icons-material/GraphicEqSharp')),
    GraphicEqTwoTone: React.lazy(() => import('@mui/icons-material/GraphicEqTwoTone')),
    Grass: React.lazy(() => import('@mui/icons-material/Grass')),
    GrassOutlined: React.lazy(() => import('@mui/icons-material/GrassOutlined')),
    GrassRounded: React.lazy(() => import('@mui/icons-material/GrassRounded')),
    GrassSharp: React.lazy(() => import('@mui/icons-material/GrassSharp')),
    GrassTwoTone: React.lazy(() => import('@mui/icons-material/GrassTwoTone')),
    Grid3x3: React.lazy(() => import('@mui/icons-material/Grid3x3')),
    Grid3x3Outlined: React.lazy(() => import('@mui/icons-material/Grid3x3Outlined')),
    Grid3x3Rounded: React.lazy(() => import('@mui/icons-material/Grid3x3Rounded')),
    Grid3x3Sharp: React.lazy(() => import('@mui/icons-material/Grid3x3Sharp')),
    Grid3x3TwoTone: React.lazy(() => import('@mui/icons-material/Grid3x3TwoTone')),
    Grid4x4: React.lazy(() => import('@mui/icons-material/Grid4x4')),
    Grid4x4Outlined: React.lazy(() => import('@mui/icons-material/Grid4x4Outlined')),
    Grid4x4Rounded: React.lazy(() => import('@mui/icons-material/Grid4x4Rounded')),
    Grid4x4Sharp: React.lazy(() => import('@mui/icons-material/Grid4x4Sharp')),
    Grid4x4TwoTone: React.lazy(() => import('@mui/icons-material/Grid4x4TwoTone')),
    GridGoldenratio: React.lazy(() => import('@mui/icons-material/GridGoldenratio')),
    GridGoldenratioOutlined: React.lazy(() => import('@mui/icons-material/GridGoldenratioOutlined')),
    GridGoldenratioRounded: React.lazy(() => import('@mui/icons-material/GridGoldenratioRounded')),
    GridGoldenratioSharp: React.lazy(() => import('@mui/icons-material/GridGoldenratioSharp')),
    GridGoldenratioTwoTone: React.lazy(() => import('@mui/icons-material/GridGoldenratioTwoTone')),
    GridOff: React.lazy(() => import('@mui/icons-material/GridOff')),
    GridOffOutlined: React.lazy(() => import('@mui/icons-material/GridOffOutlined')),
    GridOffRounded: React.lazy(() => import('@mui/icons-material/GridOffRounded')),
    GridOffSharp: React.lazy(() => import('@mui/icons-material/GridOffSharp')),
    GridOffTwoTone: React.lazy(() => import('@mui/icons-material/GridOffTwoTone')),
    GridOn: React.lazy(() => import('@mui/icons-material/GridOn')),
    GridOnOutlined: React.lazy(() => import('@mui/icons-material/GridOnOutlined')),
    GridOnRounded: React.lazy(() => import('@mui/icons-material/GridOnRounded')),
    GridOnSharp: React.lazy(() => import('@mui/icons-material/GridOnSharp')),
    GridOnTwoTone: React.lazy(() => import('@mui/icons-material/GridOnTwoTone')),
    GridView: React.lazy(() => import('@mui/icons-material/GridView')),
    GridViewOutlined: React.lazy(() => import('@mui/icons-material/GridViewOutlined')),
    GridViewRounded: React.lazy(() => import('@mui/icons-material/GridViewRounded')),
    GridViewSharp: React.lazy(() => import('@mui/icons-material/GridViewSharp')),
    GridViewTwoTone: React.lazy(() => import('@mui/icons-material/GridViewTwoTone')),
    GroupAdd: React.lazy(() => import('@mui/icons-material/GroupAdd')),
    GroupAddOutlined: React.lazy(() => import('@mui/icons-material/GroupAddOutlined')),
    GroupAddRounded: React.lazy(() => import('@mui/icons-material/GroupAddRounded')),
    GroupAddSharp: React.lazy(() => import('@mui/icons-material/GroupAddSharp')),
    GroupAddTwoTone: React.lazy(() => import('@mui/icons-material/GroupAddTwoTone')),
    Group: React.lazy(() => import('@mui/icons-material/Group')),
    GroupOutlined: React.lazy(() => import('@mui/icons-material/GroupOutlined')),
    GroupRemove: React.lazy(() => import('@mui/icons-material/GroupRemove')),
    GroupRemoveOutlined: React.lazy(() => import('@mui/icons-material/GroupRemoveOutlined')),
    GroupRemoveRounded: React.lazy(() => import('@mui/icons-material/GroupRemoveRounded')),
    GroupRemoveSharp: React.lazy(() => import('@mui/icons-material/GroupRemoveSharp')),
    GroupRemoveTwoTone: React.lazy(() => import('@mui/icons-material/GroupRemoveTwoTone')),
    GroupRounded: React.lazy(() => import('@mui/icons-material/GroupRounded')),
    Groups2: React.lazy(() => import('@mui/icons-material/Groups2')),
    Groups2Outlined: React.lazy(() => import('@mui/icons-material/Groups2Outlined')),
    Groups2Rounded: React.lazy(() => import('@mui/icons-material/Groups2Rounded')),
    Groups2Sharp: React.lazy(() => import('@mui/icons-material/Groups2Sharp')),
    Groups2TwoTone: React.lazy(() => import('@mui/icons-material/Groups2TwoTone')),
    Groups3: React.lazy(() => import('@mui/icons-material/Groups3')),
    Groups3Outlined: React.lazy(() => import('@mui/icons-material/Groups3Outlined')),
    Groups3Rounded: React.lazy(() => import('@mui/icons-material/Groups3Rounded')),
    Groups3Sharp: React.lazy(() => import('@mui/icons-material/Groups3Sharp')),
    Groups3TwoTone: React.lazy(() => import('@mui/icons-material/Groups3TwoTone')),
    Groups: React.lazy(() => import('@mui/icons-material/Groups')),
    GroupSharp: React.lazy(() => import('@mui/icons-material/GroupSharp')),
    GroupsOutlined: React.lazy(() => import('@mui/icons-material/GroupsOutlined')),
    GroupsRounded: React.lazy(() => import('@mui/icons-material/GroupsRounded')),
    GroupsSharp: React.lazy(() => import('@mui/icons-material/GroupsSharp')),
    GroupsTwoTone: React.lazy(() => import('@mui/icons-material/GroupsTwoTone')),
    GroupTwoTone: React.lazy(() => import('@mui/icons-material/GroupTwoTone')),
    GroupWork: React.lazy(() => import('@mui/icons-material/GroupWork')),
    GroupWorkOutlined: React.lazy(() => import('@mui/icons-material/GroupWorkOutlined')),
    GroupWorkRounded: React.lazy(() => import('@mui/icons-material/GroupWorkRounded')),
    GroupWorkSharp: React.lazy(() => import('@mui/icons-material/GroupWorkSharp')),
    GroupWorkTwoTone: React.lazy(() => import('@mui/icons-material/GroupWorkTwoTone')),
    GTranslate: React.lazy(() => import('@mui/icons-material/GTranslate')),
    GTranslateOutlined: React.lazy(() => import('@mui/icons-material/GTranslateOutlined')),
    GTranslateRounded: React.lazy(() => import('@mui/icons-material/GTranslateRounded')),
    GTranslateSharp: React.lazy(() => import('@mui/icons-material/GTranslateSharp')),
    GTranslateTwoTone: React.lazy(() => import('@mui/icons-material/GTranslateTwoTone')),
    Hail: React.lazy(() => import('@mui/icons-material/Hail')),
    HailOutlined: React.lazy(() => import('@mui/icons-material/HailOutlined')),
    HailRounded: React.lazy(() => import('@mui/icons-material/HailRounded')),
    HailSharp: React.lazy(() => import('@mui/icons-material/HailSharp')),
    HailTwoTone: React.lazy(() => import('@mui/icons-material/HailTwoTone')),
    Handshake: React.lazy(() => import('@mui/icons-material/Handshake')),
    HandshakeOutlined: React.lazy(() => import('@mui/icons-material/HandshakeOutlined')),
    HandshakeRounded: React.lazy(() => import('@mui/icons-material/HandshakeRounded')),
    HandshakeSharp: React.lazy(() => import('@mui/icons-material/HandshakeSharp')),
    HandshakeTwoTone: React.lazy(() => import('@mui/icons-material/HandshakeTwoTone')),
    Handyman: React.lazy(() => import('@mui/icons-material/Handyman')),
    HandymanOutlined: React.lazy(() => import('@mui/icons-material/HandymanOutlined')),
    HandymanRounded: React.lazy(() => import('@mui/icons-material/HandymanRounded')),
    HandymanSharp: React.lazy(() => import('@mui/icons-material/HandymanSharp')),
    HandymanTwoTone: React.lazy(() => import('@mui/icons-material/HandymanTwoTone')),
    Hardware: React.lazy(() => import('@mui/icons-material/Hardware')),
    HardwareOutlined: React.lazy(() => import('@mui/icons-material/HardwareOutlined')),
    HardwareRounded: React.lazy(() => import('@mui/icons-material/HardwareRounded')),
    HardwareSharp: React.lazy(() => import('@mui/icons-material/HardwareSharp')),
    HardwareTwoTone: React.lazy(() => import('@mui/icons-material/HardwareTwoTone')),
    Hd: React.lazy(() => import('@mui/icons-material/Hd')),
    HdOutlined: React.lazy(() => import('@mui/icons-material/HdOutlined')),
    HdrAuto: React.lazy(() => import('@mui/icons-material/HdrAuto')),
    HdrAutoOutlined: React.lazy(() => import('@mui/icons-material/HdrAutoOutlined')),
    HdrAutoRounded: React.lazy(() => import('@mui/icons-material/HdrAutoRounded')),
    HdrAutoSelect: React.lazy(() => import('@mui/icons-material/HdrAutoSelect')),
    HdrAutoSelectOutlined: React.lazy(() => import('@mui/icons-material/HdrAutoSelectOutlined')),
    HdrAutoSelectRounded: React.lazy(() => import('@mui/icons-material/HdrAutoSelectRounded')),
    HdrAutoSelectSharp: React.lazy(() => import('@mui/icons-material/HdrAutoSelectSharp')),
    HdrAutoSelectTwoTone: React.lazy(() => import('@mui/icons-material/HdrAutoSelectTwoTone')),
    HdrAutoSharp: React.lazy(() => import('@mui/icons-material/HdrAutoSharp')),
    HdrAutoTwoTone: React.lazy(() => import('@mui/icons-material/HdrAutoTwoTone')),
    HdrEnhancedSelect: React.lazy(() => import('@mui/icons-material/HdrEnhancedSelect')),
    HdrEnhancedSelectOutlined: React.lazy(() => import('@mui/icons-material/HdrEnhancedSelectOutlined')),
    HdrEnhancedSelectRounded: React.lazy(() => import('@mui/icons-material/HdrEnhancedSelectRounded')),
    HdrEnhancedSelectSharp: React.lazy(() => import('@mui/icons-material/HdrEnhancedSelectSharp')),
    HdrEnhancedSelectTwoTone: React.lazy(() => import('@mui/icons-material/HdrEnhancedSelectTwoTone')),
    HdrOff: React.lazy(() => import('@mui/icons-material/HdrOff')),
    HdrOffOutlined: React.lazy(() => import('@mui/icons-material/HdrOffOutlined')),
    HdrOffRounded: React.lazy(() => import('@mui/icons-material/HdrOffRounded')),
    HdrOffSelect: React.lazy(() => import('@mui/icons-material/HdrOffSelect')),
    HdrOffSelectOutlined: React.lazy(() => import('@mui/icons-material/HdrOffSelectOutlined')),
    HdrOffSelectRounded: React.lazy(() => import('@mui/icons-material/HdrOffSelectRounded')),
    HdrOffSelectSharp: React.lazy(() => import('@mui/icons-material/HdrOffSelectSharp')),
    HdrOffSelectTwoTone: React.lazy(() => import('@mui/icons-material/HdrOffSelectTwoTone')),
    HdrOffSharp: React.lazy(() => import('@mui/icons-material/HdrOffSharp')),
    HdrOffTwoTone: React.lazy(() => import('@mui/icons-material/HdrOffTwoTone')),
    HdrOn: React.lazy(() => import('@mui/icons-material/HdrOn')),
    HdrOnOutlined: React.lazy(() => import('@mui/icons-material/HdrOnOutlined')),
    HdrOnRounded: React.lazy(() => import('@mui/icons-material/HdrOnRounded')),
    HdrOnSelect: React.lazy(() => import('@mui/icons-material/HdrOnSelect')),
    HdrOnSelectOutlined: React.lazy(() => import('@mui/icons-material/HdrOnSelectOutlined')),
    HdrOnSelectRounded: React.lazy(() => import('@mui/icons-material/HdrOnSelectRounded')),
    HdrOnSelectSharp: React.lazy(() => import('@mui/icons-material/HdrOnSelectSharp')),
    HdrOnSelectTwoTone: React.lazy(() => import('@mui/icons-material/HdrOnSelectTwoTone')),
    HdrOnSharp: React.lazy(() => import('@mui/icons-material/HdrOnSharp')),
    HdrOnTwoTone: React.lazy(() => import('@mui/icons-material/HdrOnTwoTone')),
    HdRounded: React.lazy(() => import('@mui/icons-material/HdRounded')),
    HdrPlus: React.lazy(() => import('@mui/icons-material/HdrPlus')),
    HdrPlusOutlined: React.lazy(() => import('@mui/icons-material/HdrPlusOutlined')),
    HdrPlusRounded: React.lazy(() => import('@mui/icons-material/HdrPlusRounded')),
    HdrPlusSharp: React.lazy(() => import('@mui/icons-material/HdrPlusSharp')),
    HdrPlusTwoTone: React.lazy(() => import('@mui/icons-material/HdrPlusTwoTone')),
    HdrStrong: React.lazy(() => import('@mui/icons-material/HdrStrong')),
    HdrStrongOutlined: React.lazy(() => import('@mui/icons-material/HdrStrongOutlined')),
    HdrStrongRounded: React.lazy(() => import('@mui/icons-material/HdrStrongRounded')),
    HdrStrongSharp: React.lazy(() => import('@mui/icons-material/HdrStrongSharp')),
    HdrStrongTwoTone: React.lazy(() => import('@mui/icons-material/HdrStrongTwoTone')),
    HdrWeak: React.lazy(() => import('@mui/icons-material/HdrWeak')),
    HdrWeakOutlined: React.lazy(() => import('@mui/icons-material/HdrWeakOutlined')),
    HdrWeakRounded: React.lazy(() => import('@mui/icons-material/HdrWeakRounded')),
    HdrWeakSharp: React.lazy(() => import('@mui/icons-material/HdrWeakSharp')),
    HdrWeakTwoTone: React.lazy(() => import('@mui/icons-material/HdrWeakTwoTone')),
    HdSharp: React.lazy(() => import('@mui/icons-material/HdSharp')),
    HdTwoTone: React.lazy(() => import('@mui/icons-material/HdTwoTone')),
    HeadphonesBattery: React.lazy(() => import('@mui/icons-material/HeadphonesBattery')),
    HeadphonesBatteryOutlined: React.lazy(() => import('@mui/icons-material/HeadphonesBatteryOutlined')),
    HeadphonesBatteryRounded: React.lazy(() => import('@mui/icons-material/HeadphonesBatteryRounded')),
    HeadphonesBatterySharp: React.lazy(() => import('@mui/icons-material/HeadphonesBatterySharp')),
    HeadphonesBatteryTwoTone: React.lazy(() => import('@mui/icons-material/HeadphonesBatteryTwoTone')),
    Headphones: React.lazy(() => import('@mui/icons-material/Headphones')),
    HeadphonesOutlined: React.lazy(() => import('@mui/icons-material/HeadphonesOutlined')),
    HeadphonesRounded: React.lazy(() => import('@mui/icons-material/HeadphonesRounded')),
    HeadphonesSharp: React.lazy(() => import('@mui/icons-material/HeadphonesSharp')),
    HeadphonesTwoTone: React.lazy(() => import('@mui/icons-material/HeadphonesTwoTone')),
    Headset: React.lazy(() => import('@mui/icons-material/Headset')),
    HeadsetMic: React.lazy(() => import('@mui/icons-material/HeadsetMic')),
    HeadsetMicOutlined: React.lazy(() => import('@mui/icons-material/HeadsetMicOutlined')),
    HeadsetMicRounded: React.lazy(() => import('@mui/icons-material/HeadsetMicRounded')),
    HeadsetMicSharp: React.lazy(() => import('@mui/icons-material/HeadsetMicSharp')),
    HeadsetMicTwoTone: React.lazy(() => import('@mui/icons-material/HeadsetMicTwoTone')),
    HeadsetOff: React.lazy(() => import('@mui/icons-material/HeadsetOff')),
    HeadsetOffOutlined: React.lazy(() => import('@mui/icons-material/HeadsetOffOutlined')),
    HeadsetOffRounded: React.lazy(() => import('@mui/icons-material/HeadsetOffRounded')),
    HeadsetOffSharp: React.lazy(() => import('@mui/icons-material/HeadsetOffSharp')),
    HeadsetOffTwoTone: React.lazy(() => import('@mui/icons-material/HeadsetOffTwoTone')),
    HeadsetOutlined: React.lazy(() => import('@mui/icons-material/HeadsetOutlined')),
    HeadsetRounded: React.lazy(() => import('@mui/icons-material/HeadsetRounded')),
    HeadsetSharp: React.lazy(() => import('@mui/icons-material/HeadsetSharp')),
    HeadsetTwoTone: React.lazy(() => import('@mui/icons-material/HeadsetTwoTone')),
    Healing: React.lazy(() => import('@mui/icons-material/Healing')),
    HealingOutlined: React.lazy(() => import('@mui/icons-material/HealingOutlined')),
    HealingRounded: React.lazy(() => import('@mui/icons-material/HealingRounded')),
    HealingSharp: React.lazy(() => import('@mui/icons-material/HealingSharp')),
    HealingTwoTone: React.lazy(() => import('@mui/icons-material/HealingTwoTone')),
    HealthAndSafety: React.lazy(() => import('@mui/icons-material/HealthAndSafety')),
    HealthAndSafetyOutlined: React.lazy(() => import('@mui/icons-material/HealthAndSafetyOutlined')),
    HealthAndSafetyRounded: React.lazy(() => import('@mui/icons-material/HealthAndSafetyRounded')),
    HealthAndSafetySharp: React.lazy(() => import('@mui/icons-material/HealthAndSafetySharp')),
    HealthAndSafetyTwoTone: React.lazy(() => import('@mui/icons-material/HealthAndSafetyTwoTone')),
    HearingDisabled: React.lazy(() => import('@mui/icons-material/HearingDisabled')),
    HearingDisabledOutlined: React.lazy(() => import('@mui/icons-material/HearingDisabledOutlined')),
    HearingDisabledRounded: React.lazy(() => import('@mui/icons-material/HearingDisabledRounded')),
    HearingDisabledSharp: React.lazy(() => import('@mui/icons-material/HearingDisabledSharp')),
    HearingDisabledTwoTone: React.lazy(() => import('@mui/icons-material/HearingDisabledTwoTone')),
    Hearing: React.lazy(() => import('@mui/icons-material/Hearing')),
    HearingOutlined: React.lazy(() => import('@mui/icons-material/HearingOutlined')),
    HearingRounded: React.lazy(() => import('@mui/icons-material/HearingRounded')),
    HearingSharp: React.lazy(() => import('@mui/icons-material/HearingSharp')),
    HearingTwoTone: React.lazy(() => import('@mui/icons-material/HearingTwoTone')),
    HeartBroken: React.lazy(() => import('@mui/icons-material/HeartBroken')),
    HeartBrokenOutlined: React.lazy(() => import('@mui/icons-material/HeartBrokenOutlined')),
    HeartBrokenRounded: React.lazy(() => import('@mui/icons-material/HeartBrokenRounded')),
    HeartBrokenSharp: React.lazy(() => import('@mui/icons-material/HeartBrokenSharp')),
    HeartBrokenTwoTone: React.lazy(() => import('@mui/icons-material/HeartBrokenTwoTone')),
    HeatPump: React.lazy(() => import('@mui/icons-material/HeatPump')),
    HeatPumpOutlined: React.lazy(() => import('@mui/icons-material/HeatPumpOutlined')),
    HeatPumpRounded: React.lazy(() => import('@mui/icons-material/HeatPumpRounded')),
    HeatPumpSharp: React.lazy(() => import('@mui/icons-material/HeatPumpSharp')),
    HeatPumpTwoTone: React.lazy(() => import('@mui/icons-material/HeatPumpTwoTone')),
    Height: React.lazy(() => import('@mui/icons-material/Height')),
    HeightOutlined: React.lazy(() => import('@mui/icons-material/HeightOutlined')),
    HeightRounded: React.lazy(() => import('@mui/icons-material/HeightRounded')),
    HeightSharp: React.lazy(() => import('@mui/icons-material/HeightSharp')),
    HeightTwoTone: React.lazy(() => import('@mui/icons-material/HeightTwoTone')),
    HelpCenter: React.lazy(() => import('@mui/icons-material/HelpCenter')),
    HelpCenterOutlined: React.lazy(() => import('@mui/icons-material/HelpCenterOutlined')),
    HelpCenterRounded: React.lazy(() => import('@mui/icons-material/HelpCenterRounded')),
    HelpCenterSharp: React.lazy(() => import('@mui/icons-material/HelpCenterSharp')),
    HelpCenterTwoTone: React.lazy(() => import('@mui/icons-material/HelpCenterTwoTone')),
    Help: React.lazy(() => import('@mui/icons-material/Help')),
    HelpOutlined: React.lazy(() => import('@mui/icons-material/HelpOutlined')),
    HelpOutline: React.lazy(() => import('@mui/icons-material/HelpOutline')),
    HelpOutlineOutlined: React.lazy(() => import('@mui/icons-material/HelpOutlineOutlined')),
    HelpOutlineRounded: React.lazy(() => import('@mui/icons-material/HelpOutlineRounded')),
    HelpOutlineSharp: React.lazy(() => import('@mui/icons-material/HelpOutlineSharp')),
    HelpOutlineTwoTone: React.lazy(() => import('@mui/icons-material/HelpOutlineTwoTone')),
    HelpRounded: React.lazy(() => import('@mui/icons-material/HelpRounded')),
    HelpSharp: React.lazy(() => import('@mui/icons-material/HelpSharp')),
    HelpTwoTone: React.lazy(() => import('@mui/icons-material/HelpTwoTone')),
    Hevc: React.lazy(() => import('@mui/icons-material/Hevc')),
    HevcOutlined: React.lazy(() => import('@mui/icons-material/HevcOutlined')),
    HevcRounded: React.lazy(() => import('@mui/icons-material/HevcRounded')),
    HevcSharp: React.lazy(() => import('@mui/icons-material/HevcSharp')),
    HevcTwoTone: React.lazy(() => import('@mui/icons-material/HevcTwoTone')),
    Hexagon: React.lazy(() => import('@mui/icons-material/Hexagon')),
    HexagonOutlined: React.lazy(() => import('@mui/icons-material/HexagonOutlined')),
    HexagonRounded: React.lazy(() => import('@mui/icons-material/HexagonRounded')),
    HexagonSharp: React.lazy(() => import('@mui/icons-material/HexagonSharp')),
    HexagonTwoTone: React.lazy(() => import('@mui/icons-material/HexagonTwoTone')),
    HideImage: React.lazy(() => import('@mui/icons-material/HideImage')),
    HideImageOutlined: React.lazy(() => import('@mui/icons-material/HideImageOutlined')),
    HideImageRounded: React.lazy(() => import('@mui/icons-material/HideImageRounded')),
    HideImageSharp: React.lazy(() => import('@mui/icons-material/HideImageSharp')),
    HideImageTwoTone: React.lazy(() => import('@mui/icons-material/HideImageTwoTone')),
    HideSource: React.lazy(() => import('@mui/icons-material/HideSource')),
    HideSourceOutlined: React.lazy(() => import('@mui/icons-material/HideSourceOutlined')),
    HideSourceRounded: React.lazy(() => import('@mui/icons-material/HideSourceRounded')),
    HideSourceSharp: React.lazy(() => import('@mui/icons-material/HideSourceSharp')),
    HideSourceTwoTone: React.lazy(() => import('@mui/icons-material/HideSourceTwoTone')),
    HighlightAlt: React.lazy(() => import('@mui/icons-material/HighlightAlt')),
    HighlightAltOutlined: React.lazy(() => import('@mui/icons-material/HighlightAltOutlined')),
    HighlightAltRounded: React.lazy(() => import('@mui/icons-material/HighlightAltRounded')),
    HighlightAltSharp: React.lazy(() => import('@mui/icons-material/HighlightAltSharp')),
    HighlightAltTwoTone: React.lazy(() => import('@mui/icons-material/HighlightAltTwoTone')),
    Highlight: React.lazy(() => import('@mui/icons-material/Highlight')),
    HighlightOff: React.lazy(() => import('@mui/icons-material/HighlightOff')),
    HighlightOffOutlined: React.lazy(() => import('@mui/icons-material/HighlightOffOutlined')),
    HighlightOffRounded: React.lazy(() => import('@mui/icons-material/HighlightOffRounded')),
    HighlightOffSharp: React.lazy(() => import('@mui/icons-material/HighlightOffSharp')),
    HighlightOffTwoTone: React.lazy(() => import('@mui/icons-material/HighlightOffTwoTone')),
    HighlightOutlined: React.lazy(() => import('@mui/icons-material/HighlightOutlined')),
    HighlightRounded: React.lazy(() => import('@mui/icons-material/HighlightRounded')),
    HighlightSharp: React.lazy(() => import('@mui/icons-material/HighlightSharp')),
    HighlightTwoTone: React.lazy(() => import('@mui/icons-material/HighlightTwoTone')),
    HighQuality: React.lazy(() => import('@mui/icons-material/HighQuality')),
    HighQualityOutlined: React.lazy(() => import('@mui/icons-material/HighQualityOutlined')),
    HighQualityRounded: React.lazy(() => import('@mui/icons-material/HighQualityRounded')),
    HighQualitySharp: React.lazy(() => import('@mui/icons-material/HighQualitySharp')),
    HighQualityTwoTone: React.lazy(() => import('@mui/icons-material/HighQualityTwoTone')),
    Hiking: React.lazy(() => import('@mui/icons-material/Hiking')),
    HikingOutlined: React.lazy(() => import('@mui/icons-material/HikingOutlined')),
    HikingRounded: React.lazy(() => import('@mui/icons-material/HikingRounded')),
    HikingSharp: React.lazy(() => import('@mui/icons-material/HikingSharp')),
    HikingTwoTone: React.lazy(() => import('@mui/icons-material/HikingTwoTone')),
    History: React.lazy(() => import('@mui/icons-material/History')),
    HistoryEdu: React.lazy(() => import('@mui/icons-material/HistoryEdu')),
    HistoryEduOutlined: React.lazy(() => import('@mui/icons-material/HistoryEduOutlined')),
    HistoryEduRounded: React.lazy(() => import('@mui/icons-material/HistoryEduRounded')),
    HistoryEduSharp: React.lazy(() => import('@mui/icons-material/HistoryEduSharp')),
    HistoryEduTwoTone: React.lazy(() => import('@mui/icons-material/HistoryEduTwoTone')),
    HistoryOutlined: React.lazy(() => import('@mui/icons-material/HistoryOutlined')),
    HistoryRounded: React.lazy(() => import('@mui/icons-material/HistoryRounded')),
    HistorySharp: React.lazy(() => import('@mui/icons-material/HistorySharp')),
    HistoryToggleOff: React.lazy(() => import('@mui/icons-material/HistoryToggleOff')),
    HistoryToggleOffOutlined: React.lazy(() => import('@mui/icons-material/HistoryToggleOffOutlined')),
    HistoryToggleOffRounded: React.lazy(() => import('@mui/icons-material/HistoryToggleOffRounded')),
    HistoryToggleOffSharp: React.lazy(() => import('@mui/icons-material/HistoryToggleOffSharp')),
    HistoryToggleOffTwoTone: React.lazy(() => import('@mui/icons-material/HistoryToggleOffTwoTone')),
    HistoryTwoTone: React.lazy(() => import('@mui/icons-material/HistoryTwoTone')),
    Hive: React.lazy(() => import('@mui/icons-material/Hive')),
    HiveOutlined: React.lazy(() => import('@mui/icons-material/HiveOutlined')),
    HiveRounded: React.lazy(() => import('@mui/icons-material/HiveRounded')),
    HiveSharp: React.lazy(() => import('@mui/icons-material/HiveSharp')),
    HiveTwoTone: React.lazy(() => import('@mui/icons-material/HiveTwoTone')),
    Hls: React.lazy(() => import('@mui/icons-material/Hls')),
    HlsOff: React.lazy(() => import('@mui/icons-material/HlsOff')),
    HlsOffOutlined: React.lazy(() => import('@mui/icons-material/HlsOffOutlined')),
    HlsOffRounded: React.lazy(() => import('@mui/icons-material/HlsOffRounded')),
    HlsOffSharp: React.lazy(() => import('@mui/icons-material/HlsOffSharp')),
    HlsOffTwoTone: React.lazy(() => import('@mui/icons-material/HlsOffTwoTone')),
    HlsOutlined: React.lazy(() => import('@mui/icons-material/HlsOutlined')),
    HlsRounded: React.lazy(() => import('@mui/icons-material/HlsRounded')),
    HlsSharp: React.lazy(() => import('@mui/icons-material/HlsSharp')),
    HlsTwoTone: React.lazy(() => import('@mui/icons-material/HlsTwoTone')),
    HMobiledata: React.lazy(() => import('@mui/icons-material/HMobiledata')),
    HMobiledataOutlined: React.lazy(() => import('@mui/icons-material/HMobiledataOutlined')),
    HMobiledataRounded: React.lazy(() => import('@mui/icons-material/HMobiledataRounded')),
    HMobiledataSharp: React.lazy(() => import('@mui/icons-material/HMobiledataSharp')),
    HMobiledataTwoTone: React.lazy(() => import('@mui/icons-material/HMobiledataTwoTone')),
    HolidayVillage: React.lazy(() => import('@mui/icons-material/HolidayVillage')),
    HolidayVillageOutlined: React.lazy(() => import('@mui/icons-material/HolidayVillageOutlined')),
    HolidayVillageRounded: React.lazy(() => import('@mui/icons-material/HolidayVillageRounded')),
    HolidayVillageSharp: React.lazy(() => import('@mui/icons-material/HolidayVillageSharp')),
    HolidayVillageTwoTone: React.lazy(() => import('@mui/icons-material/HolidayVillageTwoTone')),
    Home: React.lazy(() => import('@mui/icons-material/Home')),
    HomeMax: React.lazy(() => import('@mui/icons-material/HomeMax')),
    HomeMaxOutlined: React.lazy(() => import('@mui/icons-material/HomeMaxOutlined')),
    HomeMaxRounded: React.lazy(() => import('@mui/icons-material/HomeMaxRounded')),
    HomeMaxSharp: React.lazy(() => import('@mui/icons-material/HomeMaxSharp')),
    HomeMaxTwoTone: React.lazy(() => import('@mui/icons-material/HomeMaxTwoTone')),
    HomeMini: React.lazy(() => import('@mui/icons-material/HomeMini')),
    HomeMiniOutlined: React.lazy(() => import('@mui/icons-material/HomeMiniOutlined')),
    HomeMiniRounded: React.lazy(() => import('@mui/icons-material/HomeMiniRounded')),
    HomeMiniSharp: React.lazy(() => import('@mui/icons-material/HomeMiniSharp')),
    HomeMiniTwoTone: React.lazy(() => import('@mui/icons-material/HomeMiniTwoTone')),
    HomeOutlined: React.lazy(() => import('@mui/icons-material/HomeOutlined')),
    HomeRepairService: React.lazy(() => import('@mui/icons-material/HomeRepairService')),
    HomeRepairServiceOutlined: React.lazy(() => import('@mui/icons-material/HomeRepairServiceOutlined')),
    HomeRepairServiceRounded: React.lazy(() => import('@mui/icons-material/HomeRepairServiceRounded')),
    HomeRepairServiceSharp: React.lazy(() => import('@mui/icons-material/HomeRepairServiceSharp')),
    HomeRepairServiceTwoTone: React.lazy(() => import('@mui/icons-material/HomeRepairServiceTwoTone')),
    HomeRounded: React.lazy(() => import('@mui/icons-material/HomeRounded')),
    HomeSharp: React.lazy(() => import('@mui/icons-material/HomeSharp')),
    HomeTwoTone: React.lazy(() => import('@mui/icons-material/HomeTwoTone')),
    HomeWork: React.lazy(() => import('@mui/icons-material/HomeWork')),
    HomeWorkOutlined: React.lazy(() => import('@mui/icons-material/HomeWorkOutlined')),
    HomeWorkRounded: React.lazy(() => import('@mui/icons-material/HomeWorkRounded')),
    HomeWorkSharp: React.lazy(() => import('@mui/icons-material/HomeWorkSharp')),
    HomeWorkTwoTone: React.lazy(() => import('@mui/icons-material/HomeWorkTwoTone')),
    HorizontalRule: React.lazy(() => import('@mui/icons-material/HorizontalRule')),
    HorizontalRuleOutlined: React.lazy(() => import('@mui/icons-material/HorizontalRuleOutlined')),
    HorizontalRuleRounded: React.lazy(() => import('@mui/icons-material/HorizontalRuleRounded')),
    HorizontalRuleSharp: React.lazy(() => import('@mui/icons-material/HorizontalRuleSharp')),
    HorizontalRuleTwoTone: React.lazy(() => import('@mui/icons-material/HorizontalRuleTwoTone')),
    HorizontalSplit: React.lazy(() => import('@mui/icons-material/HorizontalSplit')),
    HorizontalSplitOutlined: React.lazy(() => import('@mui/icons-material/HorizontalSplitOutlined')),
    HorizontalSplitRounded: React.lazy(() => import('@mui/icons-material/HorizontalSplitRounded')),
    HorizontalSplitSharp: React.lazy(() => import('@mui/icons-material/HorizontalSplitSharp')),
    HorizontalSplitTwoTone: React.lazy(() => import('@mui/icons-material/HorizontalSplitTwoTone')),
    Hotel: React.lazy(() => import('@mui/icons-material/Hotel')),
    HotelOutlined: React.lazy(() => import('@mui/icons-material/HotelOutlined')),
    HotelRounded: React.lazy(() => import('@mui/icons-material/HotelRounded')),
    HotelSharp: React.lazy(() => import('@mui/icons-material/HotelSharp')),
    HotelTwoTone: React.lazy(() => import('@mui/icons-material/HotelTwoTone')),
    HotTub: React.lazy(() => import('@mui/icons-material/HotTub')),
    HotTubOutlined: React.lazy(() => import('@mui/icons-material/HotTubOutlined')),
    HotTubRounded: React.lazy(() => import('@mui/icons-material/HotTubRounded')),
    HotTubSharp: React.lazy(() => import('@mui/icons-material/HotTubSharp')),
    HotTubTwoTone: React.lazy(() => import('@mui/icons-material/HotTubTwoTone')),
    HourglassBottom: React.lazy(() => import('@mui/icons-material/HourglassBottom')),
    HourglassBottomOutlined: React.lazy(() => import('@mui/icons-material/HourglassBottomOutlined')),
    HourglassBottomRounded: React.lazy(() => import('@mui/icons-material/HourglassBottomRounded')),
    HourglassBottomSharp: React.lazy(() => import('@mui/icons-material/HourglassBottomSharp')),
    HourglassBottomTwoTone: React.lazy(() => import('@mui/icons-material/HourglassBottomTwoTone')),
    HourglassDisabled: React.lazy(() => import('@mui/icons-material/HourglassDisabled')),
    HourglassDisabledOutlined: React.lazy(() => import('@mui/icons-material/HourglassDisabledOutlined')),
    HourglassDisabledRounded: React.lazy(() => import('@mui/icons-material/HourglassDisabledRounded')),
    HourglassDisabledSharp: React.lazy(() => import('@mui/icons-material/HourglassDisabledSharp')),
    HourglassDisabledTwoTone: React.lazy(() => import('@mui/icons-material/HourglassDisabledTwoTone')),
    HourglassEmpty: React.lazy(() => import('@mui/icons-material/HourglassEmpty')),
    HourglassEmptyOutlined: React.lazy(() => import('@mui/icons-material/HourglassEmptyOutlined')),
    HourglassEmptyRounded: React.lazy(() => import('@mui/icons-material/HourglassEmptyRounded')),
    HourglassEmptySharp: React.lazy(() => import('@mui/icons-material/HourglassEmptySharp')),
    HourglassEmptyTwoTone: React.lazy(() => import('@mui/icons-material/HourglassEmptyTwoTone')),
    HourglassFull: React.lazy(() => import('@mui/icons-material/HourglassFull')),
    HourglassFullOutlined: React.lazy(() => import('@mui/icons-material/HourglassFullOutlined')),
    HourglassFullRounded: React.lazy(() => import('@mui/icons-material/HourglassFullRounded')),
    HourglassFullSharp: React.lazy(() => import('@mui/icons-material/HourglassFullSharp')),
    HourglassFullTwoTone: React.lazy(() => import('@mui/icons-material/HourglassFullTwoTone')),
    HourglassTop: React.lazy(() => import('@mui/icons-material/HourglassTop')),
    HourglassTopOutlined: React.lazy(() => import('@mui/icons-material/HourglassTopOutlined')),
    HourglassTopRounded: React.lazy(() => import('@mui/icons-material/HourglassTopRounded')),
    HourglassTopSharp: React.lazy(() => import('@mui/icons-material/HourglassTopSharp')),
    HourglassTopTwoTone: React.lazy(() => import('@mui/icons-material/HourglassTopTwoTone')),
    Houseboat: React.lazy(() => import('@mui/icons-material/Houseboat')),
    HouseboatOutlined: React.lazy(() => import('@mui/icons-material/HouseboatOutlined')),
    HouseboatRounded: React.lazy(() => import('@mui/icons-material/HouseboatRounded')),
    HouseboatSharp: React.lazy(() => import('@mui/icons-material/HouseboatSharp')),
    HouseboatTwoTone: React.lazy(() => import('@mui/icons-material/HouseboatTwoTone')),
    House: React.lazy(() => import('@mui/icons-material/House')),
    HouseOutlined: React.lazy(() => import('@mui/icons-material/HouseOutlined')),
    HouseRounded: React.lazy(() => import('@mui/icons-material/HouseRounded')),
    HouseSharp: React.lazy(() => import('@mui/icons-material/HouseSharp')),
    HouseSiding: React.lazy(() => import('@mui/icons-material/HouseSiding')),
    HouseSidingOutlined: React.lazy(() => import('@mui/icons-material/HouseSidingOutlined')),
    HouseSidingRounded: React.lazy(() => import('@mui/icons-material/HouseSidingRounded')),
    HouseSidingSharp: React.lazy(() => import('@mui/icons-material/HouseSidingSharp')),
    HouseSidingTwoTone: React.lazy(() => import('@mui/icons-material/HouseSidingTwoTone')),
    HouseTwoTone: React.lazy(() => import('@mui/icons-material/HouseTwoTone')),
    HowToReg: React.lazy(() => import('@mui/icons-material/HowToReg')),
    HowToRegOutlined: React.lazy(() => import('@mui/icons-material/HowToRegOutlined')),
    HowToRegRounded: React.lazy(() => import('@mui/icons-material/HowToRegRounded')),
    HowToRegSharp: React.lazy(() => import('@mui/icons-material/HowToRegSharp')),
    HowToRegTwoTone: React.lazy(() => import('@mui/icons-material/HowToRegTwoTone')),
    HowToVote: React.lazy(() => import('@mui/icons-material/HowToVote')),
    HowToVoteOutlined: React.lazy(() => import('@mui/icons-material/HowToVoteOutlined')),
    HowToVoteRounded: React.lazy(() => import('@mui/icons-material/HowToVoteRounded')),
    HowToVoteSharp: React.lazy(() => import('@mui/icons-material/HowToVoteSharp')),
    HowToVoteTwoTone: React.lazy(() => import('@mui/icons-material/HowToVoteTwoTone')),
    HPlusMobiledata: React.lazy(() => import('@mui/icons-material/HPlusMobiledata')),
    HPlusMobiledataOutlined: React.lazy(() => import('@mui/icons-material/HPlusMobiledataOutlined')),
    HPlusMobiledataRounded: React.lazy(() => import('@mui/icons-material/HPlusMobiledataRounded')),
    HPlusMobiledataSharp: React.lazy(() => import('@mui/icons-material/HPlusMobiledataSharp')),
    HPlusMobiledataTwoTone: React.lazy(() => import('@mui/icons-material/HPlusMobiledataTwoTone')),
    Html: React.lazy(() => import('@mui/icons-material/Html')),
    HtmlOutlined: React.lazy(() => import('@mui/icons-material/HtmlOutlined')),
    HtmlRounded: React.lazy(() => import('@mui/icons-material/HtmlRounded')),
    HtmlSharp: React.lazy(() => import('@mui/icons-material/HtmlSharp')),
    HtmlTwoTone: React.lazy(() => import('@mui/icons-material/HtmlTwoTone')),
    Http: React.lazy(() => import('@mui/icons-material/Http')),
    HttpOutlined: React.lazy(() => import('@mui/icons-material/HttpOutlined')),
    HttpRounded: React.lazy(() => import('@mui/icons-material/HttpRounded')),
    Https: React.lazy(() => import('@mui/icons-material/Https')),
    HttpSharp: React.lazy(() => import('@mui/icons-material/HttpSharp')),
    HttpsOutlined: React.lazy(() => import('@mui/icons-material/HttpsOutlined')),
    HttpsRounded: React.lazy(() => import('@mui/icons-material/HttpsRounded')),
    HttpsSharp: React.lazy(() => import('@mui/icons-material/HttpsSharp')),
    HttpsTwoTone: React.lazy(() => import('@mui/icons-material/HttpsTwoTone')),
    HttpTwoTone: React.lazy(() => import('@mui/icons-material/HttpTwoTone')),
    Hub: React.lazy(() => import('@mui/icons-material/Hub')),
    HubOutlined: React.lazy(() => import('@mui/icons-material/HubOutlined')),
    HubRounded: React.lazy(() => import('@mui/icons-material/HubRounded')),
    HubSharp: React.lazy(() => import('@mui/icons-material/HubSharp')),
    HubTwoTone: React.lazy(() => import('@mui/icons-material/HubTwoTone')),
    Hvac: React.lazy(() => import('@mui/icons-material/Hvac')),
    HvacOutlined: React.lazy(() => import('@mui/icons-material/HvacOutlined')),
    HvacRounded: React.lazy(() => import('@mui/icons-material/HvacRounded')),
    HvacSharp: React.lazy(() => import('@mui/icons-material/HvacSharp')),
    HvacTwoTone: React.lazy(() => import('@mui/icons-material/HvacTwoTone')),
    Icecream: React.lazy(() => import('@mui/icons-material/Icecream')),
    IcecreamOutlined: React.lazy(() => import('@mui/icons-material/IcecreamOutlined')),
    IcecreamRounded: React.lazy(() => import('@mui/icons-material/IcecreamRounded')),
    IcecreamSharp: React.lazy(() => import('@mui/icons-material/IcecreamSharp')),
    IcecreamTwoTone: React.lazy(() => import('@mui/icons-material/IcecreamTwoTone')),
    IceSkating: React.lazy(() => import('@mui/icons-material/IceSkating')),
    IceSkatingOutlined: React.lazy(() => import('@mui/icons-material/IceSkatingOutlined')),
    IceSkatingRounded: React.lazy(() => import('@mui/icons-material/IceSkatingRounded')),
    IceSkatingSharp: React.lazy(() => import('@mui/icons-material/IceSkatingSharp')),
    IceSkatingTwoTone: React.lazy(() => import('@mui/icons-material/IceSkatingTwoTone')),
    ImageAspectRatio: React.lazy(() => import('@mui/icons-material/ImageAspectRatio')),
    ImageAspectRatioOutlined: React.lazy(() => import('@mui/icons-material/ImageAspectRatioOutlined')),
    ImageAspectRatioRounded: React.lazy(() => import('@mui/icons-material/ImageAspectRatioRounded')),
    ImageAspectRatioSharp: React.lazy(() => import('@mui/icons-material/ImageAspectRatioSharp')),
    ImageAspectRatioTwoTone: React.lazy(() => import('@mui/icons-material/ImageAspectRatioTwoTone')),
    Image: React.lazy(() => import('@mui/icons-material/Image')),
    ImageNotSupported: React.lazy(() => import('@mui/icons-material/ImageNotSupported')),
    ImageNotSupportedOutlined: React.lazy(() => import('@mui/icons-material/ImageNotSupportedOutlined')),
    ImageNotSupportedRounded: React.lazy(() => import('@mui/icons-material/ImageNotSupportedRounded')),
    ImageNotSupportedSharp: React.lazy(() => import('@mui/icons-material/ImageNotSupportedSharp')),
    ImageNotSupportedTwoTone: React.lazy(() => import('@mui/icons-material/ImageNotSupportedTwoTone')),
    ImageOutlined: React.lazy(() => import('@mui/icons-material/ImageOutlined')),
    ImageRounded: React.lazy(() => import('@mui/icons-material/ImageRounded')),
    ImageSearch: React.lazy(() => import('@mui/icons-material/ImageSearch')),
    ImageSearchOutlined: React.lazy(() => import('@mui/icons-material/ImageSearchOutlined')),
    ImagesearchRoller: React.lazy(() => import('@mui/icons-material/ImagesearchRoller')),
    ImagesearchRollerOutlined: React.lazy(() => import('@mui/icons-material/ImagesearchRollerOutlined')),
    ImagesearchRollerRounded: React.lazy(() => import('@mui/icons-material/ImagesearchRollerRounded')),
    ImagesearchRollerSharp: React.lazy(() => import('@mui/icons-material/ImagesearchRollerSharp')),
    ImagesearchRollerTwoTone: React.lazy(() => import('@mui/icons-material/ImagesearchRollerTwoTone')),
    ImageSearchRounded: React.lazy(() => import('@mui/icons-material/ImageSearchRounded')),
    ImageSearchSharp: React.lazy(() => import('@mui/icons-material/ImageSearchSharp')),
    ImageSearchTwoTone: React.lazy(() => import('@mui/icons-material/ImageSearchTwoTone')),
    ImageSharp: React.lazy(() => import('@mui/icons-material/ImageSharp')),
    ImageTwoTone: React.lazy(() => import('@mui/icons-material/ImageTwoTone')),
    ImportantDevices: React.lazy(() => import('@mui/icons-material/ImportantDevices')),
    ImportantDevicesOutlined: React.lazy(() => import('@mui/icons-material/ImportantDevicesOutlined')),
    ImportantDevicesRounded: React.lazy(() => import('@mui/icons-material/ImportantDevicesRounded')),
    ImportantDevicesSharp: React.lazy(() => import('@mui/icons-material/ImportantDevicesSharp')),
    ImportantDevicesTwoTone: React.lazy(() => import('@mui/icons-material/ImportantDevicesTwoTone')),
    ImportContacts: React.lazy(() => import('@mui/icons-material/ImportContacts')),
    ImportContactsOutlined: React.lazy(() => import('@mui/icons-material/ImportContactsOutlined')),
    ImportContactsRounded: React.lazy(() => import('@mui/icons-material/ImportContactsRounded')),
    ImportContactsSharp: React.lazy(() => import('@mui/icons-material/ImportContactsSharp')),
    ImportContactsTwoTone: React.lazy(() => import('@mui/icons-material/ImportContactsTwoTone')),
    ImportExport: React.lazy(() => import('@mui/icons-material/ImportExport')),
    ImportExportOutlined: React.lazy(() => import('@mui/icons-material/ImportExportOutlined')),
    ImportExportRounded: React.lazy(() => import('@mui/icons-material/ImportExportRounded')),
    ImportExportSharp: React.lazy(() => import('@mui/icons-material/ImportExportSharp')),
    ImportExportTwoTone: React.lazy(() => import('@mui/icons-material/ImportExportTwoTone')),
    Inbox: React.lazy(() => import('@mui/icons-material/Inbox')),
    InboxOutlined: React.lazy(() => import('@mui/icons-material/InboxOutlined')),
    InboxRounded: React.lazy(() => import('@mui/icons-material/InboxRounded')),
    InboxSharp: React.lazy(() => import('@mui/icons-material/InboxSharp')),
    InboxTwoTone: React.lazy(() => import('@mui/icons-material/InboxTwoTone')),
    IndeterminateCheckBox: React.lazy(() => import('@mui/icons-material/IndeterminateCheckBox')),
    IndeterminateCheckBoxOutlined: React.lazy(() => import('@mui/icons-material/IndeterminateCheckBoxOutlined')),
    IndeterminateCheckBoxRounded: React.lazy(() => import('@mui/icons-material/IndeterminateCheckBoxRounded')),
    IndeterminateCheckBoxSharp: React.lazy(() => import('@mui/icons-material/IndeterminateCheckBoxSharp')),
    IndeterminateCheckBoxTwoTone: React.lazy(() => import('@mui/icons-material/IndeterminateCheckBoxTwoTone')),
    Info: React.lazy(() => import('@mui/icons-material/Info')),
    InfoOutlined: React.lazy(() => import('@mui/icons-material/InfoOutlined')),
    InfoRounded: React.lazy(() => import('@mui/icons-material/InfoRounded')),
    InfoSharp: React.lazy(() => import('@mui/icons-material/InfoSharp')),
    InfoTwoTone: React.lazy(() => import('@mui/icons-material/InfoTwoTone')),
    Input: React.lazy(() => import('@mui/icons-material/Input')),
    InputOutlined: React.lazy(() => import('@mui/icons-material/InputOutlined')),
    InputRounded: React.lazy(() => import('@mui/icons-material/InputRounded')),
    InputSharp: React.lazy(() => import('@mui/icons-material/InputSharp')),
    InputTwoTone: React.lazy(() => import('@mui/icons-material/InputTwoTone')),
    InsertChart: React.lazy(() => import('@mui/icons-material/InsertChart')),
    InsertChartOutlined: React.lazy(() => import('@mui/icons-material/InsertChartOutlined')),
    InsertChartOutlinedOutlined: React.lazy(() => import('@mui/icons-material/InsertChartOutlinedOutlined')),
    InsertChartOutlinedRounded: React.lazy(() => import('@mui/icons-material/InsertChartOutlinedRounded')),
    InsertChartOutlinedSharp: React.lazy(() => import('@mui/icons-material/InsertChartOutlinedSharp')),
    InsertChartOutlinedTwoTone: React.lazy(() => import('@mui/icons-material/InsertChartOutlinedTwoTone')),
    InsertChartRounded: React.lazy(() => import('@mui/icons-material/InsertChartRounded')),
    InsertChartSharp: React.lazy(() => import('@mui/icons-material/InsertChartSharp')),
    InsertChartTwoTone: React.lazy(() => import('@mui/icons-material/InsertChartTwoTone')),
    InsertComment: React.lazy(() => import('@mui/icons-material/InsertComment')),
    InsertCommentOutlined: React.lazy(() => import('@mui/icons-material/InsertCommentOutlined')),
    InsertCommentRounded: React.lazy(() => import('@mui/icons-material/InsertCommentRounded')),
    InsertCommentSharp: React.lazy(() => import('@mui/icons-material/InsertCommentSharp')),
    InsertCommentTwoTone: React.lazy(() => import('@mui/icons-material/InsertCommentTwoTone')),
    InsertDriveFile: React.lazy(() => import('@mui/icons-material/InsertDriveFile')),
    InsertDriveFileOutlined: React.lazy(() => import('@mui/icons-material/InsertDriveFileOutlined')),
    InsertDriveFileRounded: React.lazy(() => import('@mui/icons-material/InsertDriveFileRounded')),
    InsertDriveFileSharp: React.lazy(() => import('@mui/icons-material/InsertDriveFileSharp')),
    InsertDriveFileTwoTone: React.lazy(() => import('@mui/icons-material/InsertDriveFileTwoTone')),
    InsertEmoticon: React.lazy(() => import('@mui/icons-material/InsertEmoticon')),
    InsertEmoticonOutlined: React.lazy(() => import('@mui/icons-material/InsertEmoticonOutlined')),
    InsertEmoticonRounded: React.lazy(() => import('@mui/icons-material/InsertEmoticonRounded')),
    InsertEmoticonSharp: React.lazy(() => import('@mui/icons-material/InsertEmoticonSharp')),
    InsertEmoticonTwoTone: React.lazy(() => import('@mui/icons-material/InsertEmoticonTwoTone')),
    InsertInvitation: React.lazy(() => import('@mui/icons-material/InsertInvitation')),
    InsertInvitationOutlined: React.lazy(() => import('@mui/icons-material/InsertInvitationOutlined')),
    InsertInvitationRounded: React.lazy(() => import('@mui/icons-material/InsertInvitationRounded')),
    InsertInvitationSharp: React.lazy(() => import('@mui/icons-material/InsertInvitationSharp')),
    InsertInvitationTwoTone: React.lazy(() => import('@mui/icons-material/InsertInvitationTwoTone')),
    InsertLink: React.lazy(() => import('@mui/icons-material/InsertLink')),
    InsertLinkOutlined: React.lazy(() => import('@mui/icons-material/InsertLinkOutlined')),
    InsertLinkRounded: React.lazy(() => import('@mui/icons-material/InsertLinkRounded')),
    InsertLinkSharp: React.lazy(() => import('@mui/icons-material/InsertLinkSharp')),
    InsertLinkTwoTone: React.lazy(() => import('@mui/icons-material/InsertLinkTwoTone')),
    InsertPageBreak: React.lazy(() => import('@mui/icons-material/InsertPageBreak')),
    InsertPageBreakOutlined: React.lazy(() => import('@mui/icons-material/InsertPageBreakOutlined')),
    InsertPageBreakRounded: React.lazy(() => import('@mui/icons-material/InsertPageBreakRounded')),
    InsertPageBreakSharp: React.lazy(() => import('@mui/icons-material/InsertPageBreakSharp')),
    InsertPageBreakTwoTone: React.lazy(() => import('@mui/icons-material/InsertPageBreakTwoTone')),
    InsertPhoto: React.lazy(() => import('@mui/icons-material/InsertPhoto')),
    InsertPhotoOutlined: React.lazy(() => import('@mui/icons-material/InsertPhotoOutlined')),
    InsertPhotoRounded: React.lazy(() => import('@mui/icons-material/InsertPhotoRounded')),
    InsertPhotoSharp: React.lazy(() => import('@mui/icons-material/InsertPhotoSharp')),
    InsertPhotoTwoTone: React.lazy(() => import('@mui/icons-material/InsertPhotoTwoTone')),
    Insights: React.lazy(() => import('@mui/icons-material/Insights')),
    InsightsOutlined: React.lazy(() => import('@mui/icons-material/InsightsOutlined')),
    InsightsRounded: React.lazy(() => import('@mui/icons-material/InsightsRounded')),
    InsightsSharp: React.lazy(() => import('@mui/icons-material/InsightsSharp')),
    InsightsTwoTone: React.lazy(() => import('@mui/icons-material/InsightsTwoTone')),
    Instagram: React.lazy(() => import('@mui/icons-material/Instagram')),
    InstallDesktop: React.lazy(() => import('@mui/icons-material/InstallDesktop')),
    InstallDesktopOutlined: React.lazy(() => import('@mui/icons-material/InstallDesktopOutlined')),
    InstallDesktopRounded: React.lazy(() => import('@mui/icons-material/InstallDesktopRounded')),
    InstallDesktopSharp: React.lazy(() => import('@mui/icons-material/InstallDesktopSharp')),
    InstallDesktopTwoTone: React.lazy(() => import('@mui/icons-material/InstallDesktopTwoTone')),
    InstallMobile: React.lazy(() => import('@mui/icons-material/InstallMobile')),
    InstallMobileOutlined: React.lazy(() => import('@mui/icons-material/InstallMobileOutlined')),
    InstallMobileRounded: React.lazy(() => import('@mui/icons-material/InstallMobileRounded')),
    InstallMobileSharp: React.lazy(() => import('@mui/icons-material/InstallMobileSharp')),
    InstallMobileTwoTone: React.lazy(() => import('@mui/icons-material/InstallMobileTwoTone')),
    IntegrationInstructions: React.lazy(() => import('@mui/icons-material/IntegrationInstructions')),
    IntegrationInstructionsOutlined: React.lazy(() => import('@mui/icons-material/IntegrationInstructionsOutlined')),
    IntegrationInstructionsRounded: React.lazy(() => import('@mui/icons-material/IntegrationInstructionsRounded')),
    IntegrationInstructionsSharp: React.lazy(() => import('@mui/icons-material/IntegrationInstructionsSharp')),
    IntegrationInstructionsTwoTone: React.lazy(() => import('@mui/icons-material/IntegrationInstructionsTwoTone')),
    Interests: React.lazy(() => import('@mui/icons-material/Interests')),
    InterestsOutlined: React.lazy(() => import('@mui/icons-material/InterestsOutlined')),
    InterestsRounded: React.lazy(() => import('@mui/icons-material/InterestsRounded')),
    InterestsSharp: React.lazy(() => import('@mui/icons-material/InterestsSharp')),
    InterestsTwoTone: React.lazy(() => import('@mui/icons-material/InterestsTwoTone')),
    InterpreterMode: React.lazy(() => import('@mui/icons-material/InterpreterMode')),
    InterpreterModeOutlined: React.lazy(() => import('@mui/icons-material/InterpreterModeOutlined')),
    InterpreterModeRounded: React.lazy(() => import('@mui/icons-material/InterpreterModeRounded')),
    InterpreterModeSharp: React.lazy(() => import('@mui/icons-material/InterpreterModeSharp')),
    InterpreterModeTwoTone: React.lazy(() => import('@mui/icons-material/InterpreterModeTwoTone')),
    Inventory2: React.lazy(() => import('@mui/icons-material/Inventory2')),
    Inventory2Outlined: React.lazy(() => import('@mui/icons-material/Inventory2Outlined')),
    Inventory2Rounded: React.lazy(() => import('@mui/icons-material/Inventory2Rounded')),
    Inventory2Sharp: React.lazy(() => import('@mui/icons-material/Inventory2Sharp')),
    Inventory2TwoTone: React.lazy(() => import('@mui/icons-material/Inventory2TwoTone')),
    Inventory: React.lazy(() => import('@mui/icons-material/Inventory')),
    InventoryOutlined: React.lazy(() => import('@mui/icons-material/InventoryOutlined')),
    InventoryRounded: React.lazy(() => import('@mui/icons-material/InventoryRounded')),
    InventorySharp: React.lazy(() => import('@mui/icons-material/InventorySharp')),
    InventoryTwoTone: React.lazy(() => import('@mui/icons-material/InventoryTwoTone')),
    InvertColors: React.lazy(() => import('@mui/icons-material/InvertColors')),
    InvertColorsOff: React.lazy(() => import('@mui/icons-material/InvertColorsOff')),
    InvertColorsOffOutlined: React.lazy(() => import('@mui/icons-material/InvertColorsOffOutlined')),
    InvertColorsOffRounded: React.lazy(() => import('@mui/icons-material/InvertColorsOffRounded')),
    InvertColorsOffSharp: React.lazy(() => import('@mui/icons-material/InvertColorsOffSharp')),
    InvertColorsOffTwoTone: React.lazy(() => import('@mui/icons-material/InvertColorsOffTwoTone')),
    InvertColorsOutlined: React.lazy(() => import('@mui/icons-material/InvertColorsOutlined')),
    InvertColorsRounded: React.lazy(() => import('@mui/icons-material/InvertColorsRounded')),
    InvertColorsSharp: React.lazy(() => import('@mui/icons-material/InvertColorsSharp')),
    InvertColorsTwoTone: React.lazy(() => import('@mui/icons-material/InvertColorsTwoTone')),
    IosShare: React.lazy(() => import('@mui/icons-material/IosShare')),
    IosShareOutlined: React.lazy(() => import('@mui/icons-material/IosShareOutlined')),
    IosShareRounded: React.lazy(() => import('@mui/icons-material/IosShareRounded')),
    IosShareSharp: React.lazy(() => import('@mui/icons-material/IosShareSharp')),
    IosShareTwoTone: React.lazy(() => import('@mui/icons-material/IosShareTwoTone')),
    Iron: React.lazy(() => import('@mui/icons-material/Iron')),
    IronOutlined: React.lazy(() => import('@mui/icons-material/IronOutlined')),
    IronRounded: React.lazy(() => import('@mui/icons-material/IronRounded')),
    IronSharp: React.lazy(() => import('@mui/icons-material/IronSharp')),
    IronTwoTone: React.lazy(() => import('@mui/icons-material/IronTwoTone')),
    Iso: React.lazy(() => import('@mui/icons-material/Iso')),
    IsoOutlined: React.lazy(() => import('@mui/icons-material/IsoOutlined')),
    IsoRounded: React.lazy(() => import('@mui/icons-material/IsoRounded')),
    IsoSharp: React.lazy(() => import('@mui/icons-material/IsoSharp')),
    IsoTwoTone: React.lazy(() => import('@mui/icons-material/IsoTwoTone')),
    Javascript: React.lazy(() => import('@mui/icons-material/Javascript')),
    JavascriptOutlined: React.lazy(() => import('@mui/icons-material/JavascriptOutlined')),
    JavascriptRounded: React.lazy(() => import('@mui/icons-material/JavascriptRounded')),
    JavascriptSharp: React.lazy(() => import('@mui/icons-material/JavascriptSharp')),
    JavascriptTwoTone: React.lazy(() => import('@mui/icons-material/JavascriptTwoTone')),
    JoinFull: React.lazy(() => import('@mui/icons-material/JoinFull')),
    JoinFullOutlined: React.lazy(() => import('@mui/icons-material/JoinFullOutlined')),
    JoinFullRounded: React.lazy(() => import('@mui/icons-material/JoinFullRounded')),
    JoinFullSharp: React.lazy(() => import('@mui/icons-material/JoinFullSharp')),
    JoinFullTwoTone: React.lazy(() => import('@mui/icons-material/JoinFullTwoTone')),
    JoinInner: React.lazy(() => import('@mui/icons-material/JoinInner')),
    JoinInnerOutlined: React.lazy(() => import('@mui/icons-material/JoinInnerOutlined')),
    JoinInnerRounded: React.lazy(() => import('@mui/icons-material/JoinInnerRounded')),
    JoinInnerSharp: React.lazy(() => import('@mui/icons-material/JoinInnerSharp')),
    JoinInnerTwoTone: React.lazy(() => import('@mui/icons-material/JoinInnerTwoTone')),
    JoinLeft: React.lazy(() => import('@mui/icons-material/JoinLeft')),
    JoinLeftOutlined: React.lazy(() => import('@mui/icons-material/JoinLeftOutlined')),
    JoinLeftRounded: React.lazy(() => import('@mui/icons-material/JoinLeftRounded')),
    JoinLeftSharp: React.lazy(() => import('@mui/icons-material/JoinLeftSharp')),
    JoinLeftTwoTone: React.lazy(() => import('@mui/icons-material/JoinLeftTwoTone')),
    JoinRight: React.lazy(() => import('@mui/icons-material/JoinRight')),
    JoinRightOutlined: React.lazy(() => import('@mui/icons-material/JoinRightOutlined')),
    JoinRightRounded: React.lazy(() => import('@mui/icons-material/JoinRightRounded')),
    JoinRightSharp: React.lazy(() => import('@mui/icons-material/JoinRightSharp')),
    JoinRightTwoTone: React.lazy(() => import('@mui/icons-material/JoinRightTwoTone')),
    Kayaking: React.lazy(() => import('@mui/icons-material/Kayaking')),
    KayakingOutlined: React.lazy(() => import('@mui/icons-material/KayakingOutlined')),
    KayakingRounded: React.lazy(() => import('@mui/icons-material/KayakingRounded')),
    KayakingSharp: React.lazy(() => import('@mui/icons-material/KayakingSharp')),
    KayakingTwoTone: React.lazy(() => import('@mui/icons-material/KayakingTwoTone')),
    KebabDining: React.lazy(() => import('@mui/icons-material/KebabDining')),
    KebabDiningOutlined: React.lazy(() => import('@mui/icons-material/KebabDiningOutlined')),
    KebabDiningRounded: React.lazy(() => import('@mui/icons-material/KebabDiningRounded')),
    KebabDiningSharp: React.lazy(() => import('@mui/icons-material/KebabDiningSharp')),
    KebabDiningTwoTone: React.lazy(() => import('@mui/icons-material/KebabDiningTwoTone')),
    KeyboardAlt: React.lazy(() => import('@mui/icons-material/KeyboardAlt')),
    KeyboardAltOutlined: React.lazy(() => import('@mui/icons-material/KeyboardAltOutlined')),
    KeyboardAltRounded: React.lazy(() => import('@mui/icons-material/KeyboardAltRounded')),
    KeyboardAltSharp: React.lazy(() => import('@mui/icons-material/KeyboardAltSharp')),
    KeyboardAltTwoTone: React.lazy(() => import('@mui/icons-material/KeyboardAltTwoTone')),
    KeyboardArrowDown: React.lazy(() => import('@mui/icons-material/KeyboardArrowDown')),
    KeyboardArrowDownOutlined: React.lazy(() => import('@mui/icons-material/KeyboardArrowDownOutlined')),
    KeyboardArrowDownRounded: React.lazy(() => import('@mui/icons-material/KeyboardArrowDownRounded')),
    KeyboardArrowDownSharp: React.lazy(() => import('@mui/icons-material/KeyboardArrowDownSharp')),
    KeyboardArrowDownTwoTone: React.lazy(() => import('@mui/icons-material/KeyboardArrowDownTwoTone')),
    KeyboardArrowLeft: React.lazy(() => import('@mui/icons-material/KeyboardArrowLeft')),
    KeyboardArrowLeftOutlined: React.lazy(() => import('@mui/icons-material/KeyboardArrowLeftOutlined')),
    KeyboardArrowLeftRounded: React.lazy(() => import('@mui/icons-material/KeyboardArrowLeftRounded')),
    KeyboardArrowLeftSharp: React.lazy(() => import('@mui/icons-material/KeyboardArrowLeftSharp')),
    KeyboardArrowLeftTwoTone: React.lazy(() => import('@mui/icons-material/KeyboardArrowLeftTwoTone')),
    KeyboardArrowRight: React.lazy(() => import('@mui/icons-material/KeyboardArrowRight')),
    KeyboardArrowRightOutlined: React.lazy(() => import('@mui/icons-material/KeyboardArrowRightOutlined')),
    KeyboardArrowRightRounded: React.lazy(() => import('@mui/icons-material/KeyboardArrowRightRounded')),
    KeyboardArrowRightSharp: React.lazy(() => import('@mui/icons-material/KeyboardArrowRightSharp')),
    KeyboardArrowRightTwoTone: React.lazy(() => import('@mui/icons-material/KeyboardArrowRightTwoTone')),
    KeyboardArrowUp: React.lazy(() => import('@mui/icons-material/KeyboardArrowUp')),
    KeyboardArrowUpOutlined: React.lazy(() => import('@mui/icons-material/KeyboardArrowUpOutlined')),
    KeyboardArrowUpRounded: React.lazy(() => import('@mui/icons-material/KeyboardArrowUpRounded')),
    KeyboardArrowUpSharp: React.lazy(() => import('@mui/icons-material/KeyboardArrowUpSharp')),
    KeyboardArrowUpTwoTone: React.lazy(() => import('@mui/icons-material/KeyboardArrowUpTwoTone')),
    KeyboardBackspace: React.lazy(() => import('@mui/icons-material/KeyboardBackspace')),
    KeyboardBackspaceOutlined: React.lazy(() => import('@mui/icons-material/KeyboardBackspaceOutlined')),
    KeyboardBackspaceRounded: React.lazy(() => import('@mui/icons-material/KeyboardBackspaceRounded')),
    KeyboardBackspaceSharp: React.lazy(() => import('@mui/icons-material/KeyboardBackspaceSharp')),
    KeyboardBackspaceTwoTone: React.lazy(() => import('@mui/icons-material/KeyboardBackspaceTwoTone')),
    KeyboardCapslock: React.lazy(() => import('@mui/icons-material/KeyboardCapslock')),
    KeyboardCapslockOutlined: React.lazy(() => import('@mui/icons-material/KeyboardCapslockOutlined')),
    KeyboardCapslockRounded: React.lazy(() => import('@mui/icons-material/KeyboardCapslockRounded')),
    KeyboardCapslockSharp: React.lazy(() => import('@mui/icons-material/KeyboardCapslockSharp')),
    KeyboardCapslockTwoTone: React.lazy(() => import('@mui/icons-material/KeyboardCapslockTwoTone')),
    KeyboardCommandKey: React.lazy(() => import('@mui/icons-material/KeyboardCommandKey')),
    KeyboardCommandKeyOutlined: React.lazy(() => import('@mui/icons-material/KeyboardCommandKeyOutlined')),
    KeyboardCommandKeyRounded: React.lazy(() => import('@mui/icons-material/KeyboardCommandKeyRounded')),
    KeyboardCommandKeySharp: React.lazy(() => import('@mui/icons-material/KeyboardCommandKeySharp')),
    KeyboardCommandKeyTwoTone: React.lazy(() => import('@mui/icons-material/KeyboardCommandKeyTwoTone')),
    KeyboardControlKey: React.lazy(() => import('@mui/icons-material/KeyboardControlKey')),
    KeyboardControlKeyOutlined: React.lazy(() => import('@mui/icons-material/KeyboardControlKeyOutlined')),
    KeyboardControlKeyRounded: React.lazy(() => import('@mui/icons-material/KeyboardControlKeyRounded')),
    KeyboardControlKeySharp: React.lazy(() => import('@mui/icons-material/KeyboardControlKeySharp')),
    KeyboardControlKeyTwoTone: React.lazy(() => import('@mui/icons-material/KeyboardControlKeyTwoTone')),
    KeyboardDoubleArrowDown: React.lazy(() => import('@mui/icons-material/KeyboardDoubleArrowDown')),
    KeyboardDoubleArrowDownOutlined: React.lazy(() => import('@mui/icons-material/KeyboardDoubleArrowDownOutlined')),
    KeyboardDoubleArrowDownRounded: React.lazy(() => import('@mui/icons-material/KeyboardDoubleArrowDownRounded')),
    KeyboardDoubleArrowDownSharp: React.lazy(() => import('@mui/icons-material/KeyboardDoubleArrowDownSharp')),
    KeyboardDoubleArrowDownTwoTone: React.lazy(() => import('@mui/icons-material/KeyboardDoubleArrowDownTwoTone')),
    KeyboardDoubleArrowLeft: React.lazy(() => import('@mui/icons-material/KeyboardDoubleArrowLeft')),
    KeyboardDoubleArrowLeftOutlined: React.lazy(() => import('@mui/icons-material/KeyboardDoubleArrowLeftOutlined')),
    KeyboardDoubleArrowLeftRounded: React.lazy(() => import('@mui/icons-material/KeyboardDoubleArrowLeftRounded')),
    KeyboardDoubleArrowLeftSharp: React.lazy(() => import('@mui/icons-material/KeyboardDoubleArrowLeftSharp')),
    KeyboardDoubleArrowLeftTwoTone: React.lazy(() => import('@mui/icons-material/KeyboardDoubleArrowLeftTwoTone')),
    KeyboardDoubleArrowRight: React.lazy(() => import('@mui/icons-material/KeyboardDoubleArrowRight')),
    KeyboardDoubleArrowRightOutlined: React.lazy(() => import('@mui/icons-material/KeyboardDoubleArrowRightOutlined')),
    KeyboardDoubleArrowRightRounded: React.lazy(() => import('@mui/icons-material/KeyboardDoubleArrowRightRounded')),
    KeyboardDoubleArrowRightSharp: React.lazy(() => import('@mui/icons-material/KeyboardDoubleArrowRightSharp')),
    KeyboardDoubleArrowRightTwoTone: React.lazy(() => import('@mui/icons-material/KeyboardDoubleArrowRightTwoTone')),
    KeyboardDoubleArrowUp: React.lazy(() => import('@mui/icons-material/KeyboardDoubleArrowUp')),
    KeyboardDoubleArrowUpOutlined: React.lazy(() => import('@mui/icons-material/KeyboardDoubleArrowUpOutlined')),
    KeyboardDoubleArrowUpRounded: React.lazy(() => import('@mui/icons-material/KeyboardDoubleArrowUpRounded')),
    KeyboardDoubleArrowUpSharp: React.lazy(() => import('@mui/icons-material/KeyboardDoubleArrowUpSharp')),
    KeyboardDoubleArrowUpTwoTone: React.lazy(() => import('@mui/icons-material/KeyboardDoubleArrowUpTwoTone')),
    Keyboard: React.lazy(() => import('@mui/icons-material/Keyboard')),
    KeyboardHide: React.lazy(() => import('@mui/icons-material/KeyboardHide')),
    KeyboardHideOutlined: React.lazy(() => import('@mui/icons-material/KeyboardHideOutlined')),
    KeyboardHideRounded: React.lazy(() => import('@mui/icons-material/KeyboardHideRounded')),
    KeyboardHideSharp: React.lazy(() => import('@mui/icons-material/KeyboardHideSharp')),
    KeyboardHideTwoTone: React.lazy(() => import('@mui/icons-material/KeyboardHideTwoTone')),
    KeyboardOptionKey: React.lazy(() => import('@mui/icons-material/KeyboardOptionKey')),
    KeyboardOptionKeyOutlined: React.lazy(() => import('@mui/icons-material/KeyboardOptionKeyOutlined')),
    KeyboardOptionKeyRounded: React.lazy(() => import('@mui/icons-material/KeyboardOptionKeyRounded')),
    KeyboardOptionKeySharp: React.lazy(() => import('@mui/icons-material/KeyboardOptionKeySharp')),
    KeyboardOptionKeyTwoTone: React.lazy(() => import('@mui/icons-material/KeyboardOptionKeyTwoTone')),
    KeyboardOutlined: React.lazy(() => import('@mui/icons-material/KeyboardOutlined')),
    KeyboardReturn: React.lazy(() => import('@mui/icons-material/KeyboardReturn')),
    KeyboardReturnOutlined: React.lazy(() => import('@mui/icons-material/KeyboardReturnOutlined')),
    KeyboardReturnRounded: React.lazy(() => import('@mui/icons-material/KeyboardReturnRounded')),
    KeyboardReturnSharp: React.lazy(() => import('@mui/icons-material/KeyboardReturnSharp')),
    KeyboardReturnTwoTone: React.lazy(() => import('@mui/icons-material/KeyboardReturnTwoTone')),
    KeyboardRounded: React.lazy(() => import('@mui/icons-material/KeyboardRounded')),
    KeyboardSharp: React.lazy(() => import('@mui/icons-material/KeyboardSharp')),
    KeyboardTab: React.lazy(() => import('@mui/icons-material/KeyboardTab')),
    KeyboardTabOutlined: React.lazy(() => import('@mui/icons-material/KeyboardTabOutlined')),
    KeyboardTabRounded: React.lazy(() => import('@mui/icons-material/KeyboardTabRounded')),
    KeyboardTabSharp: React.lazy(() => import('@mui/icons-material/KeyboardTabSharp')),
    KeyboardTabTwoTone: React.lazy(() => import('@mui/icons-material/KeyboardTabTwoTone')),
    KeyboardTwoTone: React.lazy(() => import('@mui/icons-material/KeyboardTwoTone')),
    KeyboardVoice: React.lazy(() => import('@mui/icons-material/KeyboardVoice')),
    KeyboardVoiceOutlined: React.lazy(() => import('@mui/icons-material/KeyboardVoiceOutlined')),
    KeyboardVoiceRounded: React.lazy(() => import('@mui/icons-material/KeyboardVoiceRounded')),
    KeyboardVoiceSharp: React.lazy(() => import('@mui/icons-material/KeyboardVoiceSharp')),
    KeyboardVoiceTwoTone: React.lazy(() => import('@mui/icons-material/KeyboardVoiceTwoTone')),
    Key: React.lazy(() => import('@mui/icons-material/Key')),
    KeyOff: React.lazy(() => import('@mui/icons-material/KeyOff')),
    KeyOffOutlined: React.lazy(() => import('@mui/icons-material/KeyOffOutlined')),
    KeyOffRounded: React.lazy(() => import('@mui/icons-material/KeyOffRounded')),
    KeyOffSharp: React.lazy(() => import('@mui/icons-material/KeyOffSharp')),
    KeyOffTwoTone: React.lazy(() => import('@mui/icons-material/KeyOffTwoTone')),
    KeyOutlined: React.lazy(() => import('@mui/icons-material/KeyOutlined')),
    KeyRounded: React.lazy(() => import('@mui/icons-material/KeyRounded')),
    KeySharp: React.lazy(() => import('@mui/icons-material/KeySharp')),
    KeyTwoTone: React.lazy(() => import('@mui/icons-material/KeyTwoTone')),
    KingBed: React.lazy(() => import('@mui/icons-material/KingBed')),
    KingBedOutlined: React.lazy(() => import('@mui/icons-material/KingBedOutlined')),
    KingBedRounded: React.lazy(() => import('@mui/icons-material/KingBedRounded')),
    KingBedSharp: React.lazy(() => import('@mui/icons-material/KingBedSharp')),
    KingBedTwoTone: React.lazy(() => import('@mui/icons-material/KingBedTwoTone')),
    Kitchen: React.lazy(() => import('@mui/icons-material/Kitchen')),
    KitchenOutlined: React.lazy(() => import('@mui/icons-material/KitchenOutlined')),
    KitchenRounded: React.lazy(() => import('@mui/icons-material/KitchenRounded')),
    KitchenSharp: React.lazy(() => import('@mui/icons-material/KitchenSharp')),
    KitchenTwoTone: React.lazy(() => import('@mui/icons-material/KitchenTwoTone')),
    Kitesurfing: React.lazy(() => import('@mui/icons-material/Kitesurfing')),
    KitesurfingOutlined: React.lazy(() => import('@mui/icons-material/KitesurfingOutlined')),
    KitesurfingRounded: React.lazy(() => import('@mui/icons-material/KitesurfingRounded')),
    KitesurfingSharp: React.lazy(() => import('@mui/icons-material/KitesurfingSharp')),
    KitesurfingTwoTone: React.lazy(() => import('@mui/icons-material/KitesurfingTwoTone')),
    Label: React.lazy(() => import('@mui/icons-material/Label')),
    LabelImportant: React.lazy(() => import('@mui/icons-material/LabelImportant')),
    LabelImportantOutlined: React.lazy(() => import('@mui/icons-material/LabelImportantOutlined')),
    LabelImportantRounded: React.lazy(() => import('@mui/icons-material/LabelImportantRounded')),
    LabelImportantSharp: React.lazy(() => import('@mui/icons-material/LabelImportantSharp')),
    LabelImportantTwoTone: React.lazy(() => import('@mui/icons-material/LabelImportantTwoTone')),
    LabelOff: React.lazy(() => import('@mui/icons-material/LabelOff')),
    LabelOffOutlined: React.lazy(() => import('@mui/icons-material/LabelOffOutlined')),
    LabelOffRounded: React.lazy(() => import('@mui/icons-material/LabelOffRounded')),
    LabelOffSharp: React.lazy(() => import('@mui/icons-material/LabelOffSharp')),
    LabelOffTwoTone: React.lazy(() => import('@mui/icons-material/LabelOffTwoTone')),
    LabelOutlined: React.lazy(() => import('@mui/icons-material/LabelOutlined')),
    LabelRounded: React.lazy(() => import('@mui/icons-material/LabelRounded')),
    LabelSharp: React.lazy(() => import('@mui/icons-material/LabelSharp')),
    LabelTwoTone: React.lazy(() => import('@mui/icons-material/LabelTwoTone')),
    Landscape: React.lazy(() => import('@mui/icons-material/Landscape')),
    LandscapeOutlined: React.lazy(() => import('@mui/icons-material/LandscapeOutlined')),
    LandscapeRounded: React.lazy(() => import('@mui/icons-material/LandscapeRounded')),
    LandscapeSharp: React.lazy(() => import('@mui/icons-material/LandscapeSharp')),
    LandscapeTwoTone: React.lazy(() => import('@mui/icons-material/LandscapeTwoTone')),
    Landslide: React.lazy(() => import('@mui/icons-material/Landslide')),
    LandslideOutlined: React.lazy(() => import('@mui/icons-material/LandslideOutlined')),
    LandslideRounded: React.lazy(() => import('@mui/icons-material/LandslideRounded')),
    LandslideSharp: React.lazy(() => import('@mui/icons-material/LandslideSharp')),
    LandslideTwoTone: React.lazy(() => import('@mui/icons-material/LandslideTwoTone')),
    Lan: React.lazy(() => import('@mui/icons-material/Lan')),
    Language: React.lazy(() => import('@mui/icons-material/Language')),
    LanguageOutlined: React.lazy(() => import('@mui/icons-material/LanguageOutlined')),
    LanguageRounded: React.lazy(() => import('@mui/icons-material/LanguageRounded')),
    LanguageSharp: React.lazy(() => import('@mui/icons-material/LanguageSharp')),
    LanguageTwoTone: React.lazy(() => import('@mui/icons-material/LanguageTwoTone')),
    LanOutlined: React.lazy(() => import('@mui/icons-material/LanOutlined')),
    LanRounded: React.lazy(() => import('@mui/icons-material/LanRounded')),
    LanSharp: React.lazy(() => import('@mui/icons-material/LanSharp')),
    LanTwoTone: React.lazy(() => import('@mui/icons-material/LanTwoTone')),
    LaptopChromebook: React.lazy(() => import('@mui/icons-material/LaptopChromebook')),
    LaptopChromebookOutlined: React.lazy(() => import('@mui/icons-material/LaptopChromebookOutlined')),
    LaptopChromebookRounded: React.lazy(() => import('@mui/icons-material/LaptopChromebookRounded')),
    LaptopChromebookSharp: React.lazy(() => import('@mui/icons-material/LaptopChromebookSharp')),
    LaptopChromebookTwoTone: React.lazy(() => import('@mui/icons-material/LaptopChromebookTwoTone')),
    Laptop: React.lazy(() => import('@mui/icons-material/Laptop')),
    LaptopMac: React.lazy(() => import('@mui/icons-material/LaptopMac')),
    LaptopMacOutlined: React.lazy(() => import('@mui/icons-material/LaptopMacOutlined')),
    LaptopMacRounded: React.lazy(() => import('@mui/icons-material/LaptopMacRounded')),
    LaptopMacSharp: React.lazy(() => import('@mui/icons-material/LaptopMacSharp')),
    LaptopMacTwoTone: React.lazy(() => import('@mui/icons-material/LaptopMacTwoTone')),
    LaptopOutlined: React.lazy(() => import('@mui/icons-material/LaptopOutlined')),
    LaptopRounded: React.lazy(() => import('@mui/icons-material/LaptopRounded')),
    LaptopSharp: React.lazy(() => import('@mui/icons-material/LaptopSharp')),
    LaptopTwoTone: React.lazy(() => import('@mui/icons-material/LaptopTwoTone')),
    LaptopWindows: React.lazy(() => import('@mui/icons-material/LaptopWindows')),
    LaptopWindowsOutlined: React.lazy(() => import('@mui/icons-material/LaptopWindowsOutlined')),
    LaptopWindowsRounded: React.lazy(() => import('@mui/icons-material/LaptopWindowsRounded')),
    LaptopWindowsSharp: React.lazy(() => import('@mui/icons-material/LaptopWindowsSharp')),
    LaptopWindowsTwoTone: React.lazy(() => import('@mui/icons-material/LaptopWindowsTwoTone')),
    LastPage: React.lazy(() => import('@mui/icons-material/LastPage')),
    LastPageOutlined: React.lazy(() => import('@mui/icons-material/LastPageOutlined')),
    LastPageRounded: React.lazy(() => import('@mui/icons-material/LastPageRounded')),
    LastPageSharp: React.lazy(() => import('@mui/icons-material/LastPageSharp')),
    LastPageTwoTone: React.lazy(() => import('@mui/icons-material/LastPageTwoTone')),
    Launch: React.lazy(() => import('@mui/icons-material/Launch')),
    LaunchOutlined: React.lazy(() => import('@mui/icons-material/LaunchOutlined')),
    LaunchRounded: React.lazy(() => import('@mui/icons-material/LaunchRounded')),
    LaunchSharp: React.lazy(() => import('@mui/icons-material/LaunchSharp')),
    LaunchTwoTone: React.lazy(() => import('@mui/icons-material/LaunchTwoTone')),
    LayersClear: React.lazy(() => import('@mui/icons-material/LayersClear')),
    LayersClearOutlined: React.lazy(() => import('@mui/icons-material/LayersClearOutlined')),
    LayersClearRounded: React.lazy(() => import('@mui/icons-material/LayersClearRounded')),
    LayersClearSharp: React.lazy(() => import('@mui/icons-material/LayersClearSharp')),
    LayersClearTwoTone: React.lazy(() => import('@mui/icons-material/LayersClearTwoTone')),
    Layers: React.lazy(() => import('@mui/icons-material/Layers')),
    LayersOutlined: React.lazy(() => import('@mui/icons-material/LayersOutlined')),
    LayersRounded: React.lazy(() => import('@mui/icons-material/LayersRounded')),
    LayersSharp: React.lazy(() => import('@mui/icons-material/LayersSharp')),
    LayersTwoTone: React.lazy(() => import('@mui/icons-material/LayersTwoTone')),
    Leaderboard: React.lazy(() => import('@mui/icons-material/Leaderboard')),
    LeaderboardOutlined: React.lazy(() => import('@mui/icons-material/LeaderboardOutlined')),
    LeaderboardRounded: React.lazy(() => import('@mui/icons-material/LeaderboardRounded')),
    LeaderboardSharp: React.lazy(() => import('@mui/icons-material/LeaderboardSharp')),
    LeaderboardTwoTone: React.lazy(() => import('@mui/icons-material/LeaderboardTwoTone')),
    LeakAdd: React.lazy(() => import('@mui/icons-material/LeakAdd')),
    LeakAddOutlined: React.lazy(() => import('@mui/icons-material/LeakAddOutlined')),
    LeakAddRounded: React.lazy(() => import('@mui/icons-material/LeakAddRounded')),
    LeakAddSharp: React.lazy(() => import('@mui/icons-material/LeakAddSharp')),
    LeakAddTwoTone: React.lazy(() => import('@mui/icons-material/LeakAddTwoTone')),
    LeakRemove: React.lazy(() => import('@mui/icons-material/LeakRemove')),
    LeakRemoveOutlined: React.lazy(() => import('@mui/icons-material/LeakRemoveOutlined')),
    LeakRemoveRounded: React.lazy(() => import('@mui/icons-material/LeakRemoveRounded')),
    LeakRemoveSharp: React.lazy(() => import('@mui/icons-material/LeakRemoveSharp')),
    LeakRemoveTwoTone: React.lazy(() => import('@mui/icons-material/LeakRemoveTwoTone')),
    LegendToggle: React.lazy(() => import('@mui/icons-material/LegendToggle')),
    LegendToggleOutlined: React.lazy(() => import('@mui/icons-material/LegendToggleOutlined')),
    LegendToggleRounded: React.lazy(() => import('@mui/icons-material/LegendToggleRounded')),
    LegendToggleSharp: React.lazy(() => import('@mui/icons-material/LegendToggleSharp')),
    LegendToggleTwoTone: React.lazy(() => import('@mui/icons-material/LegendToggleTwoTone')),
    LensBlur: React.lazy(() => import('@mui/icons-material/LensBlur')),
    LensBlurOutlined: React.lazy(() => import('@mui/icons-material/LensBlurOutlined')),
    LensBlurRounded: React.lazy(() => import('@mui/icons-material/LensBlurRounded')),
    LensBlurSharp: React.lazy(() => import('@mui/icons-material/LensBlurSharp')),
    LensBlurTwoTone: React.lazy(() => import('@mui/icons-material/LensBlurTwoTone')),
    Lens: React.lazy(() => import('@mui/icons-material/Lens')),
    LensOutlined: React.lazy(() => import('@mui/icons-material/LensOutlined')),
    LensRounded: React.lazy(() => import('@mui/icons-material/LensRounded')),
    LensSharp: React.lazy(() => import('@mui/icons-material/LensSharp')),
    LensTwoTone: React.lazy(() => import('@mui/icons-material/LensTwoTone')),
    LibraryAddCheck: React.lazy(() => import('@mui/icons-material/LibraryAddCheck')),
    LibraryAddCheckOutlined: React.lazy(() => import('@mui/icons-material/LibraryAddCheckOutlined')),
    LibraryAddCheckRounded: React.lazy(() => import('@mui/icons-material/LibraryAddCheckRounded')),
    LibraryAddCheckSharp: React.lazy(() => import('@mui/icons-material/LibraryAddCheckSharp')),
    LibraryAddCheckTwoTone: React.lazy(() => import('@mui/icons-material/LibraryAddCheckTwoTone')),
    LibraryAdd: React.lazy(() => import('@mui/icons-material/LibraryAdd')),
    LibraryAddOutlined: React.lazy(() => import('@mui/icons-material/LibraryAddOutlined')),
    LibraryAddRounded: React.lazy(() => import('@mui/icons-material/LibraryAddRounded')),
    LibraryAddSharp: React.lazy(() => import('@mui/icons-material/LibraryAddSharp')),
    LibraryAddTwoTone: React.lazy(() => import('@mui/icons-material/LibraryAddTwoTone')),
    LibraryBooks: React.lazy(() => import('@mui/icons-material/LibraryBooks')),
    LibraryBooksOutlined: React.lazy(() => import('@mui/icons-material/LibraryBooksOutlined')),
    LibraryBooksRounded: React.lazy(() => import('@mui/icons-material/LibraryBooksRounded')),
    LibraryBooksSharp: React.lazy(() => import('@mui/icons-material/LibraryBooksSharp')),
    LibraryBooksTwoTone: React.lazy(() => import('@mui/icons-material/LibraryBooksTwoTone')),
    LibraryMusic: React.lazy(() => import('@mui/icons-material/LibraryMusic')),
    LibraryMusicOutlined: React.lazy(() => import('@mui/icons-material/LibraryMusicOutlined')),
    LibraryMusicRounded: React.lazy(() => import('@mui/icons-material/LibraryMusicRounded')),
    LibraryMusicSharp: React.lazy(() => import('@mui/icons-material/LibraryMusicSharp')),
    LibraryMusicTwoTone: React.lazy(() => import('@mui/icons-material/LibraryMusicTwoTone')),
    LightbulbCircle: React.lazy(() => import('@mui/icons-material/LightbulbCircle')),
    LightbulbCircleOutlined: React.lazy(() => import('@mui/icons-material/LightbulbCircleOutlined')),
    LightbulbCircleRounded: React.lazy(() => import('@mui/icons-material/LightbulbCircleRounded')),
    LightbulbCircleSharp: React.lazy(() => import('@mui/icons-material/LightbulbCircleSharp')),
    LightbulbCircleTwoTone: React.lazy(() => import('@mui/icons-material/LightbulbCircleTwoTone')),
    Lightbulb: React.lazy(() => import('@mui/icons-material/Lightbulb')),
    LightbulbOutlined: React.lazy(() => import('@mui/icons-material/LightbulbOutlined')),
    LightbulbRounded: React.lazy(() => import('@mui/icons-material/LightbulbRounded')),
    LightbulbSharp: React.lazy(() => import('@mui/icons-material/LightbulbSharp')),
    LightbulbTwoTone: React.lazy(() => import('@mui/icons-material/LightbulbTwoTone')),
    Light: React.lazy(() => import('@mui/icons-material/Light')),
    LightMode: React.lazy(() => import('@mui/icons-material/LightMode')),
    LightModeOutlined: React.lazy(() => import('@mui/icons-material/LightModeOutlined')),
    LightModeRounded: React.lazy(() => import('@mui/icons-material/LightModeRounded')),
    LightModeSharp: React.lazy(() => import('@mui/icons-material/LightModeSharp')),
    LightModeTwoTone: React.lazy(() => import('@mui/icons-material/LightModeTwoTone')),
    LightOutlined: React.lazy(() => import('@mui/icons-material/LightOutlined')),
    LightRounded: React.lazy(() => import('@mui/icons-material/LightRounded')),
    LightSharp: React.lazy(() => import('@mui/icons-material/LightSharp')),
    LightTwoTone: React.lazy(() => import('@mui/icons-material/LightTwoTone')),
    LinearScale: React.lazy(() => import('@mui/icons-material/LinearScale')),
    LinearScaleOutlined: React.lazy(() => import('@mui/icons-material/LinearScaleOutlined')),
    LinearScaleRounded: React.lazy(() => import('@mui/icons-material/LinearScaleRounded')),
    LinearScaleSharp: React.lazy(() => import('@mui/icons-material/LinearScaleSharp')),
    LinearScaleTwoTone: React.lazy(() => import('@mui/icons-material/LinearScaleTwoTone')),
    LineAxis: React.lazy(() => import('@mui/icons-material/LineAxis')),
    LineAxisOutlined: React.lazy(() => import('@mui/icons-material/LineAxisOutlined')),
    LineAxisRounded: React.lazy(() => import('@mui/icons-material/LineAxisRounded')),
    LineAxisSharp: React.lazy(() => import('@mui/icons-material/LineAxisSharp')),
    LineAxisTwoTone: React.lazy(() => import('@mui/icons-material/LineAxisTwoTone')),
    LineStyle: React.lazy(() => import('@mui/icons-material/LineStyle')),
    LineStyleOutlined: React.lazy(() => import('@mui/icons-material/LineStyleOutlined')),
    LineStyleRounded: React.lazy(() => import('@mui/icons-material/LineStyleRounded')),
    LineStyleSharp: React.lazy(() => import('@mui/icons-material/LineStyleSharp')),
    LineStyleTwoTone: React.lazy(() => import('@mui/icons-material/LineStyleTwoTone')),
    LineWeight: React.lazy(() => import('@mui/icons-material/LineWeight')),
    LineWeightOutlined: React.lazy(() => import('@mui/icons-material/LineWeightOutlined')),
    LineWeightRounded: React.lazy(() => import('@mui/icons-material/LineWeightRounded')),
    LineWeightSharp: React.lazy(() => import('@mui/icons-material/LineWeightSharp')),
    LineWeightTwoTone: React.lazy(() => import('@mui/icons-material/LineWeightTwoTone')),
    Link: React.lazy(() => import('@mui/icons-material/Link')),
    LinkedCamera: React.lazy(() => import('@mui/icons-material/LinkedCamera')),
    LinkedCameraOutlined: React.lazy(() => import('@mui/icons-material/LinkedCameraOutlined')),
    LinkedCameraRounded: React.lazy(() => import('@mui/icons-material/LinkedCameraRounded')),
    LinkedCameraSharp: React.lazy(() => import('@mui/icons-material/LinkedCameraSharp')),
    LinkedCameraTwoTone: React.lazy(() => import('@mui/icons-material/LinkedCameraTwoTone')),
    LinkedIn: React.lazy(() => import('@mui/icons-material/LinkedIn')),
    LinkOff: React.lazy(() => import('@mui/icons-material/LinkOff')),
    LinkOffOutlined: React.lazy(() => import('@mui/icons-material/LinkOffOutlined')),
    LinkOffRounded: React.lazy(() => import('@mui/icons-material/LinkOffRounded')),
    LinkOffSharp: React.lazy(() => import('@mui/icons-material/LinkOffSharp')),
    LinkOffTwoTone: React.lazy(() => import('@mui/icons-material/LinkOffTwoTone')),
    LinkOutlined: React.lazy(() => import('@mui/icons-material/LinkOutlined')),
    LinkRounded: React.lazy(() => import('@mui/icons-material/LinkRounded')),
    LinkSharp: React.lazy(() => import('@mui/icons-material/LinkSharp')),
    LinkTwoTone: React.lazy(() => import('@mui/icons-material/LinkTwoTone')),
    Liquor: React.lazy(() => import('@mui/icons-material/Liquor')),
    LiquorOutlined: React.lazy(() => import('@mui/icons-material/LiquorOutlined')),
    LiquorRounded: React.lazy(() => import('@mui/icons-material/LiquorRounded')),
    LiquorSharp: React.lazy(() => import('@mui/icons-material/LiquorSharp')),
    LiquorTwoTone: React.lazy(() => import('@mui/icons-material/LiquorTwoTone')),
    ListAlt: React.lazy(() => import('@mui/icons-material/ListAlt')),
    ListAltOutlined: React.lazy(() => import('@mui/icons-material/ListAltOutlined')),
    ListAltRounded: React.lazy(() => import('@mui/icons-material/ListAltRounded')),
    ListAltSharp: React.lazy(() => import('@mui/icons-material/ListAltSharp')),
    ListAltTwoTone: React.lazy(() => import('@mui/icons-material/ListAltTwoTone')),
    List: React.lazy(() => import('@mui/icons-material/List')),
    ListOutlined: React.lazy(() => import('@mui/icons-material/ListOutlined')),
    ListRounded: React.lazy(() => import('@mui/icons-material/ListRounded')),
    ListSharp: React.lazy(() => import('@mui/icons-material/ListSharp')),
    ListTwoTone: React.lazy(() => import('@mui/icons-material/ListTwoTone')),
    LiveHelp: React.lazy(() => import('@mui/icons-material/LiveHelp')),
    LiveHelpOutlined: React.lazy(() => import('@mui/icons-material/LiveHelpOutlined')),
    LiveHelpRounded: React.lazy(() => import('@mui/icons-material/LiveHelpRounded')),
    LiveHelpSharp: React.lazy(() => import('@mui/icons-material/LiveHelpSharp')),
    LiveHelpTwoTone: React.lazy(() => import('@mui/icons-material/LiveHelpTwoTone')),
    LiveTv: React.lazy(() => import('@mui/icons-material/LiveTv')),
    LiveTvOutlined: React.lazy(() => import('@mui/icons-material/LiveTvOutlined')),
    LiveTvRounded: React.lazy(() => import('@mui/icons-material/LiveTvRounded')),
    LiveTvSharp: React.lazy(() => import('@mui/icons-material/LiveTvSharp')),
    LiveTvTwoTone: React.lazy(() => import('@mui/icons-material/LiveTvTwoTone')),
    Living: React.lazy(() => import('@mui/icons-material/Living')),
    LivingOutlined: React.lazy(() => import('@mui/icons-material/LivingOutlined')),
    LivingRounded: React.lazy(() => import('@mui/icons-material/LivingRounded')),
    LivingSharp: React.lazy(() => import('@mui/icons-material/LivingSharp')),
    LivingTwoTone: React.lazy(() => import('@mui/icons-material/LivingTwoTone')),
    LocalActivity: React.lazy(() => import('@mui/icons-material/LocalActivity')),
    LocalActivityOutlined: React.lazy(() => import('@mui/icons-material/LocalActivityOutlined')),
    LocalActivityRounded: React.lazy(() => import('@mui/icons-material/LocalActivityRounded')),
    LocalActivitySharp: React.lazy(() => import('@mui/icons-material/LocalActivitySharp')),
    LocalActivityTwoTone: React.lazy(() => import('@mui/icons-material/LocalActivityTwoTone')),
    LocalAirport: React.lazy(() => import('@mui/icons-material/LocalAirport')),
    LocalAirportOutlined: React.lazy(() => import('@mui/icons-material/LocalAirportOutlined')),
    LocalAirportRounded: React.lazy(() => import('@mui/icons-material/LocalAirportRounded')),
    LocalAirportSharp: React.lazy(() => import('@mui/icons-material/LocalAirportSharp')),
    LocalAirportTwoTone: React.lazy(() => import('@mui/icons-material/LocalAirportTwoTone')),
    LocalAtm: React.lazy(() => import('@mui/icons-material/LocalAtm')),
    LocalAtmOutlined: React.lazy(() => import('@mui/icons-material/LocalAtmOutlined')),
    LocalAtmRounded: React.lazy(() => import('@mui/icons-material/LocalAtmRounded')),
    LocalAtmSharp: React.lazy(() => import('@mui/icons-material/LocalAtmSharp')),
    LocalAtmTwoTone: React.lazy(() => import('@mui/icons-material/LocalAtmTwoTone')),
    LocalBar: React.lazy(() => import('@mui/icons-material/LocalBar')),
    LocalBarOutlined: React.lazy(() => import('@mui/icons-material/LocalBarOutlined')),
    LocalBarRounded: React.lazy(() => import('@mui/icons-material/LocalBarRounded')),
    LocalBarSharp: React.lazy(() => import('@mui/icons-material/LocalBarSharp')),
    LocalBarTwoTone: React.lazy(() => import('@mui/icons-material/LocalBarTwoTone')),
    LocalCafe: React.lazy(() => import('@mui/icons-material/LocalCafe')),
    LocalCafeOutlined: React.lazy(() => import('@mui/icons-material/LocalCafeOutlined')),
    LocalCafeRounded: React.lazy(() => import('@mui/icons-material/LocalCafeRounded')),
    LocalCafeSharp: React.lazy(() => import('@mui/icons-material/LocalCafeSharp')),
    LocalCafeTwoTone: React.lazy(() => import('@mui/icons-material/LocalCafeTwoTone')),
    LocalCarWash: React.lazy(() => import('@mui/icons-material/LocalCarWash')),
    LocalCarWashOutlined: React.lazy(() => import('@mui/icons-material/LocalCarWashOutlined')),
    LocalCarWashRounded: React.lazy(() => import('@mui/icons-material/LocalCarWashRounded')),
    LocalCarWashSharp: React.lazy(() => import('@mui/icons-material/LocalCarWashSharp')),
    LocalCarWashTwoTone: React.lazy(() => import('@mui/icons-material/LocalCarWashTwoTone')),
    LocalConvenienceStore: React.lazy(() => import('@mui/icons-material/LocalConvenienceStore')),
    LocalConvenienceStoreOutlined: React.lazy(() => import('@mui/icons-material/LocalConvenienceStoreOutlined')),
    LocalConvenienceStoreRounded: React.lazy(() => import('@mui/icons-material/LocalConvenienceStoreRounded')),
    LocalConvenienceStoreSharp: React.lazy(() => import('@mui/icons-material/LocalConvenienceStoreSharp')),
    LocalConvenienceStoreTwoTone: React.lazy(() => import('@mui/icons-material/LocalConvenienceStoreTwoTone')),
    LocalDining: React.lazy(() => import('@mui/icons-material/LocalDining')),
    LocalDiningOutlined: React.lazy(() => import('@mui/icons-material/LocalDiningOutlined')),
    LocalDiningRounded: React.lazy(() => import('@mui/icons-material/LocalDiningRounded')),
    LocalDiningSharp: React.lazy(() => import('@mui/icons-material/LocalDiningSharp')),
    LocalDiningTwoTone: React.lazy(() => import('@mui/icons-material/LocalDiningTwoTone')),
    LocalDrink: React.lazy(() => import('@mui/icons-material/LocalDrink')),
    LocalDrinkOutlined: React.lazy(() => import('@mui/icons-material/LocalDrinkOutlined')),
    LocalDrinkRounded: React.lazy(() => import('@mui/icons-material/LocalDrinkRounded')),
    LocalDrinkSharp: React.lazy(() => import('@mui/icons-material/LocalDrinkSharp')),
    LocalDrinkTwoTone: React.lazy(() => import('@mui/icons-material/LocalDrinkTwoTone')),
    LocalFireDepartment: React.lazy(() => import('@mui/icons-material/LocalFireDepartment')),
    LocalFireDepartmentOutlined: React.lazy(() => import('@mui/icons-material/LocalFireDepartmentOutlined')),
    LocalFireDepartmentRounded: React.lazy(() => import('@mui/icons-material/LocalFireDepartmentRounded')),
    LocalFireDepartmentSharp: React.lazy(() => import('@mui/icons-material/LocalFireDepartmentSharp')),
    LocalFireDepartmentTwoTone: React.lazy(() => import('@mui/icons-material/LocalFireDepartmentTwoTone')),
    LocalFlorist: React.lazy(() => import('@mui/icons-material/LocalFlorist')),
    LocalFloristOutlined: React.lazy(() => import('@mui/icons-material/LocalFloristOutlined')),
    LocalFloristRounded: React.lazy(() => import('@mui/icons-material/LocalFloristRounded')),
    LocalFloristSharp: React.lazy(() => import('@mui/icons-material/LocalFloristSharp')),
    LocalFloristTwoTone: React.lazy(() => import('@mui/icons-material/LocalFloristTwoTone')),
    LocalGasStation: React.lazy(() => import('@mui/icons-material/LocalGasStation')),
    LocalGasStationOutlined: React.lazy(() => import('@mui/icons-material/LocalGasStationOutlined')),
    LocalGasStationRounded: React.lazy(() => import('@mui/icons-material/LocalGasStationRounded')),
    LocalGasStationSharp: React.lazy(() => import('@mui/icons-material/LocalGasStationSharp')),
    LocalGasStationTwoTone: React.lazy(() => import('@mui/icons-material/LocalGasStationTwoTone')),
    LocalGroceryStore: React.lazy(() => import('@mui/icons-material/LocalGroceryStore')),
    LocalGroceryStoreOutlined: React.lazy(() => import('@mui/icons-material/LocalGroceryStoreOutlined')),
    LocalGroceryStoreRounded: React.lazy(() => import('@mui/icons-material/LocalGroceryStoreRounded')),
    LocalGroceryStoreSharp: React.lazy(() => import('@mui/icons-material/LocalGroceryStoreSharp')),
    LocalGroceryStoreTwoTone: React.lazy(() => import('@mui/icons-material/LocalGroceryStoreTwoTone')),
    LocalHospital: React.lazy(() => import('@mui/icons-material/LocalHospital')),
    LocalHospitalOutlined: React.lazy(() => import('@mui/icons-material/LocalHospitalOutlined')),
    LocalHospitalRounded: React.lazy(() => import('@mui/icons-material/LocalHospitalRounded')),
    LocalHospitalSharp: React.lazy(() => import('@mui/icons-material/LocalHospitalSharp')),
    LocalHospitalTwoTone: React.lazy(() => import('@mui/icons-material/LocalHospitalTwoTone')),
    LocalHotel: React.lazy(() => import('@mui/icons-material/LocalHotel')),
    LocalHotelOutlined: React.lazy(() => import('@mui/icons-material/LocalHotelOutlined')),
    LocalHotelRounded: React.lazy(() => import('@mui/icons-material/LocalHotelRounded')),
    LocalHotelSharp: React.lazy(() => import('@mui/icons-material/LocalHotelSharp')),
    LocalHotelTwoTone: React.lazy(() => import('@mui/icons-material/LocalHotelTwoTone')),
    LocalLaundryService: React.lazy(() => import('@mui/icons-material/LocalLaundryService')),
    LocalLaundryServiceOutlined: React.lazy(() => import('@mui/icons-material/LocalLaundryServiceOutlined')),
    LocalLaundryServiceRounded: React.lazy(() => import('@mui/icons-material/LocalLaundryServiceRounded')),
    LocalLaundryServiceSharp: React.lazy(() => import('@mui/icons-material/LocalLaundryServiceSharp')),
    LocalLaundryServiceTwoTone: React.lazy(() => import('@mui/icons-material/LocalLaundryServiceTwoTone')),
    LocalLibrary: React.lazy(() => import('@mui/icons-material/LocalLibrary')),
    LocalLibraryOutlined: React.lazy(() => import('@mui/icons-material/LocalLibraryOutlined')),
    LocalLibraryRounded: React.lazy(() => import('@mui/icons-material/LocalLibraryRounded')),
    LocalLibrarySharp: React.lazy(() => import('@mui/icons-material/LocalLibrarySharp')),
    LocalLibraryTwoTone: React.lazy(() => import('@mui/icons-material/LocalLibraryTwoTone')),
    LocalMall: React.lazy(() => import('@mui/icons-material/LocalMall')),
    LocalMallOutlined: React.lazy(() => import('@mui/icons-material/LocalMallOutlined')),
    LocalMallRounded: React.lazy(() => import('@mui/icons-material/LocalMallRounded')),
    LocalMallSharp: React.lazy(() => import('@mui/icons-material/LocalMallSharp')),
    LocalMallTwoTone: React.lazy(() => import('@mui/icons-material/LocalMallTwoTone')),
    LocalMovies: React.lazy(() => import('@mui/icons-material/LocalMovies')),
    LocalMoviesOutlined: React.lazy(() => import('@mui/icons-material/LocalMoviesOutlined')),
    LocalMoviesRounded: React.lazy(() => import('@mui/icons-material/LocalMoviesRounded')),
    LocalMoviesSharp: React.lazy(() => import('@mui/icons-material/LocalMoviesSharp')),
    LocalMoviesTwoTone: React.lazy(() => import('@mui/icons-material/LocalMoviesTwoTone')),
    LocalOffer: React.lazy(() => import('@mui/icons-material/LocalOffer')),
    LocalOfferOutlined: React.lazy(() => import('@mui/icons-material/LocalOfferOutlined')),
    LocalOfferRounded: React.lazy(() => import('@mui/icons-material/LocalOfferRounded')),
    LocalOfferSharp: React.lazy(() => import('@mui/icons-material/LocalOfferSharp')),
    LocalOfferTwoTone: React.lazy(() => import('@mui/icons-material/LocalOfferTwoTone')),
    LocalParking: React.lazy(() => import('@mui/icons-material/LocalParking')),
    LocalParkingOutlined: React.lazy(() => import('@mui/icons-material/LocalParkingOutlined')),
    LocalParkingRounded: React.lazy(() => import('@mui/icons-material/LocalParkingRounded')),
    LocalParkingSharp: React.lazy(() => import('@mui/icons-material/LocalParkingSharp')),
    LocalParkingTwoTone: React.lazy(() => import('@mui/icons-material/LocalParkingTwoTone')),
    LocalPharmacy: React.lazy(() => import('@mui/icons-material/LocalPharmacy')),
    LocalPharmacyOutlined: React.lazy(() => import('@mui/icons-material/LocalPharmacyOutlined')),
    LocalPharmacyRounded: React.lazy(() => import('@mui/icons-material/LocalPharmacyRounded')),
    LocalPharmacySharp: React.lazy(() => import('@mui/icons-material/LocalPharmacySharp')),
    LocalPharmacyTwoTone: React.lazy(() => import('@mui/icons-material/LocalPharmacyTwoTone')),
    LocalPhone: React.lazy(() => import('@mui/icons-material/LocalPhone')),
    LocalPhoneOutlined: React.lazy(() => import('@mui/icons-material/LocalPhoneOutlined')),
    LocalPhoneRounded: React.lazy(() => import('@mui/icons-material/LocalPhoneRounded')),
    LocalPhoneSharp: React.lazy(() => import('@mui/icons-material/LocalPhoneSharp')),
    LocalPhoneTwoTone: React.lazy(() => import('@mui/icons-material/LocalPhoneTwoTone')),
    LocalPizza: React.lazy(() => import('@mui/icons-material/LocalPizza')),
    LocalPizzaOutlined: React.lazy(() => import('@mui/icons-material/LocalPizzaOutlined')),
    LocalPizzaRounded: React.lazy(() => import('@mui/icons-material/LocalPizzaRounded')),
    LocalPizzaSharp: React.lazy(() => import('@mui/icons-material/LocalPizzaSharp')),
    LocalPizzaTwoTone: React.lazy(() => import('@mui/icons-material/LocalPizzaTwoTone')),
    LocalPlay: React.lazy(() => import('@mui/icons-material/LocalPlay')),
    LocalPlayOutlined: React.lazy(() => import('@mui/icons-material/LocalPlayOutlined')),
    LocalPlayRounded: React.lazy(() => import('@mui/icons-material/LocalPlayRounded')),
    LocalPlaySharp: React.lazy(() => import('@mui/icons-material/LocalPlaySharp')),
    LocalPlayTwoTone: React.lazy(() => import('@mui/icons-material/LocalPlayTwoTone')),
    LocalPolice: React.lazy(() => import('@mui/icons-material/LocalPolice')),
    LocalPoliceOutlined: React.lazy(() => import('@mui/icons-material/LocalPoliceOutlined')),
    LocalPoliceRounded: React.lazy(() => import('@mui/icons-material/LocalPoliceRounded')),
    LocalPoliceSharp: React.lazy(() => import('@mui/icons-material/LocalPoliceSharp')),
    LocalPoliceTwoTone: React.lazy(() => import('@mui/icons-material/LocalPoliceTwoTone')),
    LocalPostOffice: React.lazy(() => import('@mui/icons-material/LocalPostOffice')),
    LocalPostOfficeOutlined: React.lazy(() => import('@mui/icons-material/LocalPostOfficeOutlined')),
    LocalPostOfficeRounded: React.lazy(() => import('@mui/icons-material/LocalPostOfficeRounded')),
    LocalPostOfficeSharp: React.lazy(() => import('@mui/icons-material/LocalPostOfficeSharp')),
    LocalPostOfficeTwoTone: React.lazy(() => import('@mui/icons-material/LocalPostOfficeTwoTone')),
    LocalPrintshop: React.lazy(() => import('@mui/icons-material/LocalPrintshop')),
    LocalPrintshopOutlined: React.lazy(() => import('@mui/icons-material/LocalPrintshopOutlined')),
    LocalPrintshopRounded: React.lazy(() => import('@mui/icons-material/LocalPrintshopRounded')),
    LocalPrintshopSharp: React.lazy(() => import('@mui/icons-material/LocalPrintshopSharp')),
    LocalPrintshopTwoTone: React.lazy(() => import('@mui/icons-material/LocalPrintshopTwoTone')),
    LocalSee: React.lazy(() => import('@mui/icons-material/LocalSee')),
    LocalSeeOutlined: React.lazy(() => import('@mui/icons-material/LocalSeeOutlined')),
    LocalSeeRounded: React.lazy(() => import('@mui/icons-material/LocalSeeRounded')),
    LocalSeeSharp: React.lazy(() => import('@mui/icons-material/LocalSeeSharp')),
    LocalSeeTwoTone: React.lazy(() => import('@mui/icons-material/LocalSeeTwoTone')),
    LocalShipping: React.lazy(() => import('@mui/icons-material/LocalShipping')),
    LocalShippingOutlined: React.lazy(() => import('@mui/icons-material/LocalShippingOutlined')),
    LocalShippingRounded: React.lazy(() => import('@mui/icons-material/LocalShippingRounded')),
    LocalShippingSharp: React.lazy(() => import('@mui/icons-material/LocalShippingSharp')),
    LocalShippingTwoTone: React.lazy(() => import('@mui/icons-material/LocalShippingTwoTone')),
    LocalTaxi: React.lazy(() => import('@mui/icons-material/LocalTaxi')),
    LocalTaxiOutlined: React.lazy(() => import('@mui/icons-material/LocalTaxiOutlined')),
    LocalTaxiRounded: React.lazy(() => import('@mui/icons-material/LocalTaxiRounded')),
    LocalTaxiSharp: React.lazy(() => import('@mui/icons-material/LocalTaxiSharp')),
    LocalTaxiTwoTone: React.lazy(() => import('@mui/icons-material/LocalTaxiTwoTone')),
    LocationCity: React.lazy(() => import('@mui/icons-material/LocationCity')),
    LocationCityOutlined: React.lazy(() => import('@mui/icons-material/LocationCityOutlined')),
    LocationCityRounded: React.lazy(() => import('@mui/icons-material/LocationCityRounded')),
    LocationCitySharp: React.lazy(() => import('@mui/icons-material/LocationCitySharp')),
    LocationCityTwoTone: React.lazy(() => import('@mui/icons-material/LocationCityTwoTone')),
    LocationDisabled: React.lazy(() => import('@mui/icons-material/LocationDisabled')),
    LocationDisabledOutlined: React.lazy(() => import('@mui/icons-material/LocationDisabledOutlined')),
    LocationDisabledRounded: React.lazy(() => import('@mui/icons-material/LocationDisabledRounded')),
    LocationDisabledSharp: React.lazy(() => import('@mui/icons-material/LocationDisabledSharp')),
    LocationDisabledTwoTone: React.lazy(() => import('@mui/icons-material/LocationDisabledTwoTone')),
    LocationOff: React.lazy(() => import('@mui/icons-material/LocationOff')),
    LocationOffOutlined: React.lazy(() => import('@mui/icons-material/LocationOffOutlined')),
    LocationOffRounded: React.lazy(() => import('@mui/icons-material/LocationOffRounded')),
    LocationOffSharp: React.lazy(() => import('@mui/icons-material/LocationOffSharp')),
    LocationOffTwoTone: React.lazy(() => import('@mui/icons-material/LocationOffTwoTone')),
    LocationOn: React.lazy(() => import('@mui/icons-material/LocationOn')),
    LocationOnOutlined: React.lazy(() => import('@mui/icons-material/LocationOnOutlined')),
    LocationOnRounded: React.lazy(() => import('@mui/icons-material/LocationOnRounded')),
    LocationOnSharp: React.lazy(() => import('@mui/icons-material/LocationOnSharp')),
    LocationOnTwoTone: React.lazy(() => import('@mui/icons-material/LocationOnTwoTone')),
    LocationSearching: React.lazy(() => import('@mui/icons-material/LocationSearching')),
    LocationSearchingOutlined: React.lazy(() => import('@mui/icons-material/LocationSearchingOutlined')),
    LocationSearchingRounded: React.lazy(() => import('@mui/icons-material/LocationSearchingRounded')),
    LocationSearchingSharp: React.lazy(() => import('@mui/icons-material/LocationSearchingSharp')),
    LocationSearchingTwoTone: React.lazy(() => import('@mui/icons-material/LocationSearchingTwoTone')),
    LockClock: React.lazy(() => import('@mui/icons-material/LockClock')),
    LockClockOutlined: React.lazy(() => import('@mui/icons-material/LockClockOutlined')),
    LockClockRounded: React.lazy(() => import('@mui/icons-material/LockClockRounded')),
    LockClockSharp: React.lazy(() => import('@mui/icons-material/LockClockSharp')),
    LockClockTwoTone: React.lazy(() => import('@mui/icons-material/LockClockTwoTone')),
    Lock: React.lazy(() => import('@mui/icons-material/Lock')),
    LockOpen: React.lazy(() => import('@mui/icons-material/LockOpen')),
    LockOpenOutlined: React.lazy(() => import('@mui/icons-material/LockOpenOutlined')),
    LockOpenRounded: React.lazy(() => import('@mui/icons-material/LockOpenRounded')),
    LockOpenSharp: React.lazy(() => import('@mui/icons-material/LockOpenSharp')),
    LockOpenTwoTone: React.lazy(() => import('@mui/icons-material/LockOpenTwoTone')),
    LockOutlined: React.lazy(() => import('@mui/icons-material/LockOutlined')),
    LockPerson: React.lazy(() => import('@mui/icons-material/LockPerson')),
    LockPersonOutlined: React.lazy(() => import('@mui/icons-material/LockPersonOutlined')),
    LockPersonRounded: React.lazy(() => import('@mui/icons-material/LockPersonRounded')),
    LockPersonSharp: React.lazy(() => import('@mui/icons-material/LockPersonSharp')),
    LockPersonTwoTone: React.lazy(() => import('@mui/icons-material/LockPersonTwoTone')),
    LockReset: React.lazy(() => import('@mui/icons-material/LockReset')),
    LockResetOutlined: React.lazy(() => import('@mui/icons-material/LockResetOutlined')),
    LockResetRounded: React.lazy(() => import('@mui/icons-material/LockResetRounded')),
    LockResetSharp: React.lazy(() => import('@mui/icons-material/LockResetSharp')),
    LockResetTwoTone: React.lazy(() => import('@mui/icons-material/LockResetTwoTone')),
    LockRounded: React.lazy(() => import('@mui/icons-material/LockRounded')),
    LockSharp: React.lazy(() => import('@mui/icons-material/LockSharp')),
    LockTwoTone: React.lazy(() => import('@mui/icons-material/LockTwoTone')),
    Login: React.lazy(() => import('@mui/icons-material/Login')),
    LoginOutlined: React.lazy(() => import('@mui/icons-material/LoginOutlined')),
    LoginRounded: React.lazy(() => import('@mui/icons-material/LoginRounded')),
    LoginSharp: React.lazy(() => import('@mui/icons-material/LoginSharp')),
    LoginTwoTone: React.lazy(() => import('@mui/icons-material/LoginTwoTone')),
    LogoDev: React.lazy(() => import('@mui/icons-material/LogoDev')),
    LogoDevOutlined: React.lazy(() => import('@mui/icons-material/LogoDevOutlined')),
    LogoDevRounded: React.lazy(() => import('@mui/icons-material/LogoDevRounded')),
    LogoDevSharp: React.lazy(() => import('@mui/icons-material/LogoDevSharp')),
    LogoDevTwoTone: React.lazy(() => import('@mui/icons-material/LogoDevTwoTone')),
    Logout: React.lazy(() => import('@mui/icons-material/Logout')),
    LogoutOutlined: React.lazy(() => import('@mui/icons-material/LogoutOutlined')),
    LogoutRounded: React.lazy(() => import('@mui/icons-material/LogoutRounded')),
    LogoutSharp: React.lazy(() => import('@mui/icons-material/LogoutSharp')),
    LogoutTwoTone: React.lazy(() => import('@mui/icons-material/LogoutTwoTone')),
    Looks3: React.lazy(() => import('@mui/icons-material/Looks3')),
    Looks3Outlined: React.lazy(() => import('@mui/icons-material/Looks3Outlined')),
    Looks3Rounded: React.lazy(() => import('@mui/icons-material/Looks3Rounded')),
    Looks3Sharp: React.lazy(() => import('@mui/icons-material/Looks3Sharp')),
    Looks3TwoTone: React.lazy(() => import('@mui/icons-material/Looks3TwoTone')),
    Looks4: React.lazy(() => import('@mui/icons-material/Looks4')),
    Looks4Outlined: React.lazy(() => import('@mui/icons-material/Looks4Outlined')),
    Looks4Rounded: React.lazy(() => import('@mui/icons-material/Looks4Rounded')),
    Looks4Sharp: React.lazy(() => import('@mui/icons-material/Looks4Sharp')),
    Looks4TwoTone: React.lazy(() => import('@mui/icons-material/Looks4TwoTone')),
    Looks5: React.lazy(() => import('@mui/icons-material/Looks5')),
    Looks5Outlined: React.lazy(() => import('@mui/icons-material/Looks5Outlined')),
    Looks5Rounded: React.lazy(() => import('@mui/icons-material/Looks5Rounded')),
    Looks5Sharp: React.lazy(() => import('@mui/icons-material/Looks5Sharp')),
    Looks5TwoTone: React.lazy(() => import('@mui/icons-material/Looks5TwoTone')),
    Looks6: React.lazy(() => import('@mui/icons-material/Looks6')),
    Looks6Outlined: React.lazy(() => import('@mui/icons-material/Looks6Outlined')),
    Looks6Rounded: React.lazy(() => import('@mui/icons-material/Looks6Rounded')),
    Looks6Sharp: React.lazy(() => import('@mui/icons-material/Looks6Sharp')),
    Looks6TwoTone: React.lazy(() => import('@mui/icons-material/Looks6TwoTone')),
    Looks: React.lazy(() => import('@mui/icons-material/Looks')),
    LooksOne: React.lazy(() => import('@mui/icons-material/LooksOne')),
    LooksOneOutlined: React.lazy(() => import('@mui/icons-material/LooksOneOutlined')),
    LooksOneRounded: React.lazy(() => import('@mui/icons-material/LooksOneRounded')),
    LooksOneSharp: React.lazy(() => import('@mui/icons-material/LooksOneSharp')),
    LooksOneTwoTone: React.lazy(() => import('@mui/icons-material/LooksOneTwoTone')),
    LooksOutlined: React.lazy(() => import('@mui/icons-material/LooksOutlined')),
    LooksRounded: React.lazy(() => import('@mui/icons-material/LooksRounded')),
    LooksSharp: React.lazy(() => import('@mui/icons-material/LooksSharp')),
    LooksTwo: React.lazy(() => import('@mui/icons-material/LooksTwo')),
    LooksTwoOutlined: React.lazy(() => import('@mui/icons-material/LooksTwoOutlined')),
    LooksTwoRounded: React.lazy(() => import('@mui/icons-material/LooksTwoRounded')),
    LooksTwoSharp: React.lazy(() => import('@mui/icons-material/LooksTwoSharp')),
    LooksTwoTone: React.lazy(() => import('@mui/icons-material/LooksTwoTone')),
    LooksTwoTwoTone: React.lazy(() => import('@mui/icons-material/LooksTwoTwoTone')),
    Loop: React.lazy(() => import('@mui/icons-material/Loop')),
    LoopOutlined: React.lazy(() => import('@mui/icons-material/LoopOutlined')),
    LoopRounded: React.lazy(() => import('@mui/icons-material/LoopRounded')),
    LoopSharp: React.lazy(() => import('@mui/icons-material/LoopSharp')),
    LoopTwoTone: React.lazy(() => import('@mui/icons-material/LoopTwoTone')),
    Loupe: React.lazy(() => import('@mui/icons-material/Loupe')),
    LoupeOutlined: React.lazy(() => import('@mui/icons-material/LoupeOutlined')),
    LoupeRounded: React.lazy(() => import('@mui/icons-material/LoupeRounded')),
    LoupeSharp: React.lazy(() => import('@mui/icons-material/LoupeSharp')),
    LoupeTwoTone: React.lazy(() => import('@mui/icons-material/LoupeTwoTone')),
    LowPriority: React.lazy(() => import('@mui/icons-material/LowPriority')),
    LowPriorityOutlined: React.lazy(() => import('@mui/icons-material/LowPriorityOutlined')),
    LowPriorityRounded: React.lazy(() => import('@mui/icons-material/LowPriorityRounded')),
    LowPrioritySharp: React.lazy(() => import('@mui/icons-material/LowPrioritySharp')),
    LowPriorityTwoTone: React.lazy(() => import('@mui/icons-material/LowPriorityTwoTone')),
    Loyalty: React.lazy(() => import('@mui/icons-material/Loyalty')),
    LoyaltyOutlined: React.lazy(() => import('@mui/icons-material/LoyaltyOutlined')),
    LoyaltyRounded: React.lazy(() => import('@mui/icons-material/LoyaltyRounded')),
    LoyaltySharp: React.lazy(() => import('@mui/icons-material/LoyaltySharp')),
    LoyaltyTwoTone: React.lazy(() => import('@mui/icons-material/LoyaltyTwoTone')),
    LteMobiledata: React.lazy(() => import('@mui/icons-material/LteMobiledata')),
    LteMobiledataOutlined: React.lazy(() => import('@mui/icons-material/LteMobiledataOutlined')),
    LteMobiledataRounded: React.lazy(() => import('@mui/icons-material/LteMobiledataRounded')),
    LteMobiledataSharp: React.lazy(() => import('@mui/icons-material/LteMobiledataSharp')),
    LteMobiledataTwoTone: React.lazy(() => import('@mui/icons-material/LteMobiledataTwoTone')),
    LtePlusMobiledata: React.lazy(() => import('@mui/icons-material/LtePlusMobiledata')),
    LtePlusMobiledataOutlined: React.lazy(() => import('@mui/icons-material/LtePlusMobiledataOutlined')),
    LtePlusMobiledataRounded: React.lazy(() => import('@mui/icons-material/LtePlusMobiledataRounded')),
    LtePlusMobiledataSharp: React.lazy(() => import('@mui/icons-material/LtePlusMobiledataSharp')),
    LtePlusMobiledataTwoTone: React.lazy(() => import('@mui/icons-material/LtePlusMobiledataTwoTone')),
    Luggage: React.lazy(() => import('@mui/icons-material/Luggage')),
    LuggageOutlined: React.lazy(() => import('@mui/icons-material/LuggageOutlined')),
    LuggageRounded: React.lazy(() => import('@mui/icons-material/LuggageRounded')),
    LuggageSharp: React.lazy(() => import('@mui/icons-material/LuggageSharp')),
    LuggageTwoTone: React.lazy(() => import('@mui/icons-material/LuggageTwoTone')),
    LunchDining: React.lazy(() => import('@mui/icons-material/LunchDining')),
    LunchDiningOutlined: React.lazy(() => import('@mui/icons-material/LunchDiningOutlined')),
    LunchDiningRounded: React.lazy(() => import('@mui/icons-material/LunchDiningRounded')),
    LunchDiningSharp: React.lazy(() => import('@mui/icons-material/LunchDiningSharp')),
    LunchDiningTwoTone: React.lazy(() => import('@mui/icons-material/LunchDiningTwoTone')),
    Lyrics: React.lazy(() => import('@mui/icons-material/Lyrics')),
    LyricsOutlined: React.lazy(() => import('@mui/icons-material/LyricsOutlined')),
    LyricsRounded: React.lazy(() => import('@mui/icons-material/LyricsRounded')),
    LyricsSharp: React.lazy(() => import('@mui/icons-material/LyricsSharp')),
    LyricsTwoTone: React.lazy(() => import('@mui/icons-material/LyricsTwoTone')),
    MacroOff: React.lazy(() => import('@mui/icons-material/MacroOff')),
    MacroOffOutlined: React.lazy(() => import('@mui/icons-material/MacroOffOutlined')),
    MacroOffRounded: React.lazy(() => import('@mui/icons-material/MacroOffRounded')),
    MacroOffSharp: React.lazy(() => import('@mui/icons-material/MacroOffSharp')),
    MacroOffTwoTone: React.lazy(() => import('@mui/icons-material/MacroOffTwoTone')),
    Mail: React.lazy(() => import('@mui/icons-material/Mail')),
    MailLock: React.lazy(() => import('@mui/icons-material/MailLock')),
    MailLockOutlined: React.lazy(() => import('@mui/icons-material/MailLockOutlined')),
    MailLockRounded: React.lazy(() => import('@mui/icons-material/MailLockRounded')),
    MailLockSharp: React.lazy(() => import('@mui/icons-material/MailLockSharp')),
    MailLockTwoTone: React.lazy(() => import('@mui/icons-material/MailLockTwoTone')),
    MailOutlined: React.lazy(() => import('@mui/icons-material/MailOutlined')),
    MailOutline: React.lazy(() => import('@mui/icons-material/MailOutline')),
    MailOutlineOutlined: React.lazy(() => import('@mui/icons-material/MailOutlineOutlined')),
    MailOutlineRounded: React.lazy(() => import('@mui/icons-material/MailOutlineRounded')),
    MailOutlineSharp: React.lazy(() => import('@mui/icons-material/MailOutlineSharp')),
    MailOutlineTwoTone: React.lazy(() => import('@mui/icons-material/MailOutlineTwoTone')),
    MailRounded: React.lazy(() => import('@mui/icons-material/MailRounded')),
    MailSharp: React.lazy(() => import('@mui/icons-material/MailSharp')),
    MailTwoTone: React.lazy(() => import('@mui/icons-material/MailTwoTone')),
    Male: React.lazy(() => import('@mui/icons-material/Male')),
    MaleOutlined: React.lazy(() => import('@mui/icons-material/MaleOutlined')),
    MaleRounded: React.lazy(() => import('@mui/icons-material/MaleRounded')),
    MaleSharp: React.lazy(() => import('@mui/icons-material/MaleSharp')),
    MaleTwoTone: React.lazy(() => import('@mui/icons-material/MaleTwoTone')),
    Man2: React.lazy(() => import('@mui/icons-material/Man2')),
    Man2Outlined: React.lazy(() => import('@mui/icons-material/Man2Outlined')),
    Man2Rounded: React.lazy(() => import('@mui/icons-material/Man2Rounded')),
    Man2Sharp: React.lazy(() => import('@mui/icons-material/Man2Sharp')),
    Man2TwoTone: React.lazy(() => import('@mui/icons-material/Man2TwoTone')),
    Man3: React.lazy(() => import('@mui/icons-material/Man3')),
    Man3Outlined: React.lazy(() => import('@mui/icons-material/Man3Outlined')),
    Man3Rounded: React.lazy(() => import('@mui/icons-material/Man3Rounded')),
    Man3Sharp: React.lazy(() => import('@mui/icons-material/Man3Sharp')),
    Man3TwoTone: React.lazy(() => import('@mui/icons-material/Man3TwoTone')),
    Man4: React.lazy(() => import('@mui/icons-material/Man4')),
    Man4Outlined: React.lazy(() => import('@mui/icons-material/Man4Outlined')),
    Man4Rounded: React.lazy(() => import('@mui/icons-material/Man4Rounded')),
    Man4Sharp: React.lazy(() => import('@mui/icons-material/Man4Sharp')),
    Man4TwoTone: React.lazy(() => import('@mui/icons-material/Man4TwoTone')),
    ManageAccounts: React.lazy(() => import('@mui/icons-material/ManageAccounts')),
    ManageAccountsOutlined: React.lazy(() => import('@mui/icons-material/ManageAccountsOutlined')),
    ManageAccountsRounded: React.lazy(() => import('@mui/icons-material/ManageAccountsRounded')),
    ManageAccountsSharp: React.lazy(() => import('@mui/icons-material/ManageAccountsSharp')),
    ManageAccountsTwoTone: React.lazy(() => import('@mui/icons-material/ManageAccountsTwoTone')),
    ManageHistory: React.lazy(() => import('@mui/icons-material/ManageHistory')),
    ManageHistoryOutlined: React.lazy(() => import('@mui/icons-material/ManageHistoryOutlined')),
    ManageHistoryRounded: React.lazy(() => import('@mui/icons-material/ManageHistoryRounded')),
    ManageHistorySharp: React.lazy(() => import('@mui/icons-material/ManageHistorySharp')),
    ManageHistoryTwoTone: React.lazy(() => import('@mui/icons-material/ManageHistoryTwoTone')),
    ManageSearch: React.lazy(() => import('@mui/icons-material/ManageSearch')),
    ManageSearchOutlined: React.lazy(() => import('@mui/icons-material/ManageSearchOutlined')),
    ManageSearchRounded: React.lazy(() => import('@mui/icons-material/ManageSearchRounded')),
    ManageSearchSharp: React.lazy(() => import('@mui/icons-material/ManageSearchSharp')),
    ManageSearchTwoTone: React.lazy(() => import('@mui/icons-material/ManageSearchTwoTone')),
    Man: React.lazy(() => import('@mui/icons-material/Man')),
    ManOutlined: React.lazy(() => import('@mui/icons-material/ManOutlined')),
    ManRounded: React.lazy(() => import('@mui/icons-material/ManRounded')),
    ManSharp: React.lazy(() => import('@mui/icons-material/ManSharp')),
    ManTwoTone: React.lazy(() => import('@mui/icons-material/ManTwoTone')),
    Map: React.lazy(() => import('@mui/icons-material/Map')),
    MapOutlined: React.lazy(() => import('@mui/icons-material/MapOutlined')),
    MapRounded: React.lazy(() => import('@mui/icons-material/MapRounded')),
    MapSharp: React.lazy(() => import('@mui/icons-material/MapSharp')),
    MapsHomeWork: React.lazy(() => import('@mui/icons-material/MapsHomeWork')),
    MapsHomeWorkOutlined: React.lazy(() => import('@mui/icons-material/MapsHomeWorkOutlined')),
    MapsHomeWorkRounded: React.lazy(() => import('@mui/icons-material/MapsHomeWorkRounded')),
    MapsHomeWorkSharp: React.lazy(() => import('@mui/icons-material/MapsHomeWorkSharp')),
    MapsHomeWorkTwoTone: React.lazy(() => import('@mui/icons-material/MapsHomeWorkTwoTone')),
    MapsUgc: React.lazy(() => import('@mui/icons-material/MapsUgc')),
    MapsUgcOutlined: React.lazy(() => import('@mui/icons-material/MapsUgcOutlined')),
    MapsUgcRounded: React.lazy(() => import('@mui/icons-material/MapsUgcRounded')),
    MapsUgcSharp: React.lazy(() => import('@mui/icons-material/MapsUgcSharp')),
    MapsUgcTwoTone: React.lazy(() => import('@mui/icons-material/MapsUgcTwoTone')),
    MapTwoTone: React.lazy(() => import('@mui/icons-material/MapTwoTone')),
    Margin: React.lazy(() => import('@mui/icons-material/Margin')),
    MarginOutlined: React.lazy(() => import('@mui/icons-material/MarginOutlined')),
    MarginRounded: React.lazy(() => import('@mui/icons-material/MarginRounded')),
    MarginSharp: React.lazy(() => import('@mui/icons-material/MarginSharp')),
    MarginTwoTone: React.lazy(() => import('@mui/icons-material/MarginTwoTone')),
    MarkAsUnread: React.lazy(() => import('@mui/icons-material/MarkAsUnread')),
    MarkAsUnreadOutlined: React.lazy(() => import('@mui/icons-material/MarkAsUnreadOutlined')),
    MarkAsUnreadRounded: React.lazy(() => import('@mui/icons-material/MarkAsUnreadRounded')),
    MarkAsUnreadSharp: React.lazy(() => import('@mui/icons-material/MarkAsUnreadSharp')),
    MarkAsUnreadTwoTone: React.lazy(() => import('@mui/icons-material/MarkAsUnreadTwoTone')),
    MarkChatRead: React.lazy(() => import('@mui/icons-material/MarkChatRead')),
    MarkChatReadOutlined: React.lazy(() => import('@mui/icons-material/MarkChatReadOutlined')),
    MarkChatReadRounded: React.lazy(() => import('@mui/icons-material/MarkChatReadRounded')),
    MarkChatReadSharp: React.lazy(() => import('@mui/icons-material/MarkChatReadSharp')),
    MarkChatReadTwoTone: React.lazy(() => import('@mui/icons-material/MarkChatReadTwoTone')),
    MarkChatUnread: React.lazy(() => import('@mui/icons-material/MarkChatUnread')),
    MarkChatUnreadOutlined: React.lazy(() => import('@mui/icons-material/MarkChatUnreadOutlined')),
    MarkChatUnreadRounded: React.lazy(() => import('@mui/icons-material/MarkChatUnreadRounded')),
    MarkChatUnreadSharp: React.lazy(() => import('@mui/icons-material/MarkChatUnreadSharp')),
    MarkChatUnreadTwoTone: React.lazy(() => import('@mui/icons-material/MarkChatUnreadTwoTone')),
    MarkEmailRead: React.lazy(() => import('@mui/icons-material/MarkEmailRead')),
    MarkEmailReadOutlined: React.lazy(() => import('@mui/icons-material/MarkEmailReadOutlined')),
    MarkEmailReadRounded: React.lazy(() => import('@mui/icons-material/MarkEmailReadRounded')),
    MarkEmailReadSharp: React.lazy(() => import('@mui/icons-material/MarkEmailReadSharp')),
    MarkEmailReadTwoTone: React.lazy(() => import('@mui/icons-material/MarkEmailReadTwoTone')),
    MarkEmailUnread: React.lazy(() => import('@mui/icons-material/MarkEmailUnread')),
    MarkEmailUnreadOutlined: React.lazy(() => import('@mui/icons-material/MarkEmailUnreadOutlined')),
    MarkEmailUnreadRounded: React.lazy(() => import('@mui/icons-material/MarkEmailUnreadRounded')),
    MarkEmailUnreadSharp: React.lazy(() => import('@mui/icons-material/MarkEmailUnreadSharp')),
    MarkEmailUnreadTwoTone: React.lazy(() => import('@mui/icons-material/MarkEmailUnreadTwoTone')),
    MarkUnreadChatAlt: React.lazy(() => import('@mui/icons-material/MarkUnreadChatAlt')),
    MarkUnreadChatAltOutlined: React.lazy(() => import('@mui/icons-material/MarkUnreadChatAltOutlined')),
    MarkUnreadChatAltRounded: React.lazy(() => import('@mui/icons-material/MarkUnreadChatAltRounded')),
    MarkUnreadChatAltSharp: React.lazy(() => import('@mui/icons-material/MarkUnreadChatAltSharp')),
    MarkUnreadChatAltTwoTone: React.lazy(() => import('@mui/icons-material/MarkUnreadChatAltTwoTone')),
    Markunread: React.lazy(() => import('@mui/icons-material/Markunread')),
    MarkunreadMailbox: React.lazy(() => import('@mui/icons-material/MarkunreadMailbox')),
    MarkunreadMailboxOutlined: React.lazy(() => import('@mui/icons-material/MarkunreadMailboxOutlined')),
    MarkunreadMailboxRounded: React.lazy(() => import('@mui/icons-material/MarkunreadMailboxRounded')),
    MarkunreadMailboxSharp: React.lazy(() => import('@mui/icons-material/MarkunreadMailboxSharp')),
    MarkunreadMailboxTwoTone: React.lazy(() => import('@mui/icons-material/MarkunreadMailboxTwoTone')),
    MarkunreadOutlined: React.lazy(() => import('@mui/icons-material/MarkunreadOutlined')),
    MarkunreadRounded: React.lazy(() => import('@mui/icons-material/MarkunreadRounded')),
    MarkunreadSharp: React.lazy(() => import('@mui/icons-material/MarkunreadSharp')),
    MarkunreadTwoTone: React.lazy(() => import('@mui/icons-material/MarkunreadTwoTone')),
    Masks: React.lazy(() => import('@mui/icons-material/Masks')),
    MasksOutlined: React.lazy(() => import('@mui/icons-material/MasksOutlined')),
    MasksRounded: React.lazy(() => import('@mui/icons-material/MasksRounded')),
    MasksSharp: React.lazy(() => import('@mui/icons-material/MasksSharp')),
    MasksTwoTone: React.lazy(() => import('@mui/icons-material/MasksTwoTone')),
    Maximize: React.lazy(() => import('@mui/icons-material/Maximize')),
    MaximizeOutlined: React.lazy(() => import('@mui/icons-material/MaximizeOutlined')),
    MaximizeRounded: React.lazy(() => import('@mui/icons-material/MaximizeRounded')),
    MaximizeSharp: React.lazy(() => import('@mui/icons-material/MaximizeSharp')),
    MaximizeTwoTone: React.lazy(() => import('@mui/icons-material/MaximizeTwoTone')),
    MediaBluetoothOff: React.lazy(() => import('@mui/icons-material/MediaBluetoothOff')),
    MediaBluetoothOffOutlined: React.lazy(() => import('@mui/icons-material/MediaBluetoothOffOutlined')),
    MediaBluetoothOffRounded: React.lazy(() => import('@mui/icons-material/MediaBluetoothOffRounded')),
    MediaBluetoothOffSharp: React.lazy(() => import('@mui/icons-material/MediaBluetoothOffSharp')),
    MediaBluetoothOffTwoTone: React.lazy(() => import('@mui/icons-material/MediaBluetoothOffTwoTone')),
    MediaBluetoothOn: React.lazy(() => import('@mui/icons-material/MediaBluetoothOn')),
    MediaBluetoothOnOutlined: React.lazy(() => import('@mui/icons-material/MediaBluetoothOnOutlined')),
    MediaBluetoothOnRounded: React.lazy(() => import('@mui/icons-material/MediaBluetoothOnRounded')),
    MediaBluetoothOnSharp: React.lazy(() => import('@mui/icons-material/MediaBluetoothOnSharp')),
    MediaBluetoothOnTwoTone: React.lazy(() => import('@mui/icons-material/MediaBluetoothOnTwoTone')),
    Mediation: React.lazy(() => import('@mui/icons-material/Mediation')),
    MediationOutlined: React.lazy(() => import('@mui/icons-material/MediationOutlined')),
    MediationRounded: React.lazy(() => import('@mui/icons-material/MediationRounded')),
    MediationSharp: React.lazy(() => import('@mui/icons-material/MediationSharp')),
    MediationTwoTone: React.lazy(() => import('@mui/icons-material/MediationTwoTone')),
    MedicalInformation: React.lazy(() => import('@mui/icons-material/MedicalInformation')),
    MedicalInformationOutlined: React.lazy(() => import('@mui/icons-material/MedicalInformationOutlined')),
    MedicalInformationRounded: React.lazy(() => import('@mui/icons-material/MedicalInformationRounded')),
    MedicalInformationSharp: React.lazy(() => import('@mui/icons-material/MedicalInformationSharp')),
    MedicalInformationTwoTone: React.lazy(() => import('@mui/icons-material/MedicalInformationTwoTone')),
    MedicalServices: React.lazy(() => import('@mui/icons-material/MedicalServices')),
    MedicalServicesOutlined: React.lazy(() => import('@mui/icons-material/MedicalServicesOutlined')),
    MedicalServicesRounded: React.lazy(() => import('@mui/icons-material/MedicalServicesRounded')),
    MedicalServicesSharp: React.lazy(() => import('@mui/icons-material/MedicalServicesSharp')),
    MedicalServicesTwoTone: React.lazy(() => import('@mui/icons-material/MedicalServicesTwoTone')),
    Medication: React.lazy(() => import('@mui/icons-material/Medication')),
    MedicationLiquid: React.lazy(() => import('@mui/icons-material/MedicationLiquid')),
    MedicationLiquidOutlined: React.lazy(() => import('@mui/icons-material/MedicationLiquidOutlined')),
    MedicationLiquidRounded: React.lazy(() => import('@mui/icons-material/MedicationLiquidRounded')),
    MedicationLiquidSharp: React.lazy(() => import('@mui/icons-material/MedicationLiquidSharp')),
    MedicationLiquidTwoTone: React.lazy(() => import('@mui/icons-material/MedicationLiquidTwoTone')),
    MedicationOutlined: React.lazy(() => import('@mui/icons-material/MedicationOutlined')),
    MedicationRounded: React.lazy(() => import('@mui/icons-material/MedicationRounded')),
    MedicationSharp: React.lazy(() => import('@mui/icons-material/MedicationSharp')),
    MedicationTwoTone: React.lazy(() => import('@mui/icons-material/MedicationTwoTone')),
    MeetingRoom: React.lazy(() => import('@mui/icons-material/MeetingRoom')),
    MeetingRoomOutlined: React.lazy(() => import('@mui/icons-material/MeetingRoomOutlined')),
    MeetingRoomRounded: React.lazy(() => import('@mui/icons-material/MeetingRoomRounded')),
    MeetingRoomSharp: React.lazy(() => import('@mui/icons-material/MeetingRoomSharp')),
    MeetingRoomTwoTone: React.lazy(() => import('@mui/icons-material/MeetingRoomTwoTone')),
    Memory: React.lazy(() => import('@mui/icons-material/Memory')),
    MemoryOutlined: React.lazy(() => import('@mui/icons-material/MemoryOutlined')),
    MemoryRounded: React.lazy(() => import('@mui/icons-material/MemoryRounded')),
    MemorySharp: React.lazy(() => import('@mui/icons-material/MemorySharp')),
    MemoryTwoTone: React.lazy(() => import('@mui/icons-material/MemoryTwoTone')),
    MenuBook: React.lazy(() => import('@mui/icons-material/MenuBook')),
    MenuBookOutlined: React.lazy(() => import('@mui/icons-material/MenuBookOutlined')),
    MenuBookRounded: React.lazy(() => import('@mui/icons-material/MenuBookRounded')),
    MenuBookSharp: React.lazy(() => import('@mui/icons-material/MenuBookSharp')),
    MenuBookTwoTone: React.lazy(() => import('@mui/icons-material/MenuBookTwoTone')),
    Menu: React.lazy(() => import('@mui/icons-material/Menu')),
    MenuOpen: React.lazy(() => import('@mui/icons-material/MenuOpen')),
    MenuOpenOutlined: React.lazy(() => import('@mui/icons-material/MenuOpenOutlined')),
    MenuOpenRounded: React.lazy(() => import('@mui/icons-material/MenuOpenRounded')),
    MenuOpenSharp: React.lazy(() => import('@mui/icons-material/MenuOpenSharp')),
    MenuOpenTwoTone: React.lazy(() => import('@mui/icons-material/MenuOpenTwoTone')),
    MenuOutlined: React.lazy(() => import('@mui/icons-material/MenuOutlined')),
    MenuRounded: React.lazy(() => import('@mui/icons-material/MenuRounded')),
    MenuSharp: React.lazy(() => import('@mui/icons-material/MenuSharp')),
    MenuTwoTone: React.lazy(() => import('@mui/icons-material/MenuTwoTone')),
    Merge: React.lazy(() => import('@mui/icons-material/Merge')),
    MergeOutlined: React.lazy(() => import('@mui/icons-material/MergeOutlined')),
    MergeRounded: React.lazy(() => import('@mui/icons-material/MergeRounded')),
    MergeSharp: React.lazy(() => import('@mui/icons-material/MergeSharp')),
    MergeTwoTone: React.lazy(() => import('@mui/icons-material/MergeTwoTone')),
    MergeType: React.lazy(() => import('@mui/icons-material/MergeType')),
    MergeTypeOutlined: React.lazy(() => import('@mui/icons-material/MergeTypeOutlined')),
    MergeTypeRounded: React.lazy(() => import('@mui/icons-material/MergeTypeRounded')),
    MergeTypeSharp: React.lazy(() => import('@mui/icons-material/MergeTypeSharp')),
    MergeTypeTwoTone: React.lazy(() => import('@mui/icons-material/MergeTypeTwoTone')),
    Message: React.lazy(() => import('@mui/icons-material/Message')),
    MessageOutlined: React.lazy(() => import('@mui/icons-material/MessageOutlined')),
    MessageRounded: React.lazy(() => import('@mui/icons-material/MessageRounded')),
    MessageSharp: React.lazy(() => import('@mui/icons-material/MessageSharp')),
    MessageTwoTone: React.lazy(() => import('@mui/icons-material/MessageTwoTone')),
    Mic: React.lazy(() => import('@mui/icons-material/Mic')),
    MicExternalOff: React.lazy(() => import('@mui/icons-material/MicExternalOff')),
    MicExternalOffOutlined: React.lazy(() => import('@mui/icons-material/MicExternalOffOutlined')),
    MicExternalOffRounded: React.lazy(() => import('@mui/icons-material/MicExternalOffRounded')),
    MicExternalOffSharp: React.lazy(() => import('@mui/icons-material/MicExternalOffSharp')),
    MicExternalOffTwoTone: React.lazy(() => import('@mui/icons-material/MicExternalOffTwoTone')),
    MicExternalOn: React.lazy(() => import('@mui/icons-material/MicExternalOn')),
    MicExternalOnOutlined: React.lazy(() => import('@mui/icons-material/MicExternalOnOutlined')),
    MicExternalOnRounded: React.lazy(() => import('@mui/icons-material/MicExternalOnRounded')),
    MicExternalOnSharp: React.lazy(() => import('@mui/icons-material/MicExternalOnSharp')),
    MicExternalOnTwoTone: React.lazy(() => import('@mui/icons-material/MicExternalOnTwoTone')),
    MicNone: React.lazy(() => import('@mui/icons-material/MicNone')),
    MicNoneOutlined: React.lazy(() => import('@mui/icons-material/MicNoneOutlined')),
    MicNoneRounded: React.lazy(() => import('@mui/icons-material/MicNoneRounded')),
    MicNoneSharp: React.lazy(() => import('@mui/icons-material/MicNoneSharp')),
    MicNoneTwoTone: React.lazy(() => import('@mui/icons-material/MicNoneTwoTone')),
    MicOff: React.lazy(() => import('@mui/icons-material/MicOff')),
    MicOffOutlined: React.lazy(() => import('@mui/icons-material/MicOffOutlined')),
    MicOffRounded: React.lazy(() => import('@mui/icons-material/MicOffRounded')),
    MicOffSharp: React.lazy(() => import('@mui/icons-material/MicOffSharp')),
    MicOffTwoTone: React.lazy(() => import('@mui/icons-material/MicOffTwoTone')),
    MicOutlined: React.lazy(() => import('@mui/icons-material/MicOutlined')),
    MicRounded: React.lazy(() => import('@mui/icons-material/MicRounded')),
    Microwave: React.lazy(() => import('@mui/icons-material/Microwave')),
    MicrowaveOutlined: React.lazy(() => import('@mui/icons-material/MicrowaveOutlined')),
    MicrowaveRounded: React.lazy(() => import('@mui/icons-material/MicrowaveRounded')),
    MicrowaveSharp: React.lazy(() => import('@mui/icons-material/MicrowaveSharp')),
    MicrowaveTwoTone: React.lazy(() => import('@mui/icons-material/MicrowaveTwoTone')),
    MicSharp: React.lazy(() => import('@mui/icons-material/MicSharp')),
    MicTwoTone: React.lazy(() => import('@mui/icons-material/MicTwoTone')),
    MilitaryTech: React.lazy(() => import('@mui/icons-material/MilitaryTech')),
    MilitaryTechOutlined: React.lazy(() => import('@mui/icons-material/MilitaryTechOutlined')),
    MilitaryTechRounded: React.lazy(() => import('@mui/icons-material/MilitaryTechRounded')),
    MilitaryTechSharp: React.lazy(() => import('@mui/icons-material/MilitaryTechSharp')),
    MilitaryTechTwoTone: React.lazy(() => import('@mui/icons-material/MilitaryTechTwoTone')),
    Minimize: React.lazy(() => import('@mui/icons-material/Minimize')),
    MinimizeOutlined: React.lazy(() => import('@mui/icons-material/MinimizeOutlined')),
    MinimizeRounded: React.lazy(() => import('@mui/icons-material/MinimizeRounded')),
    MinimizeSharp: React.lazy(() => import('@mui/icons-material/MinimizeSharp')),
    MinimizeTwoTone: React.lazy(() => import('@mui/icons-material/MinimizeTwoTone')),
    MinorCrash: React.lazy(() => import('@mui/icons-material/MinorCrash')),
    MinorCrashOutlined: React.lazy(() => import('@mui/icons-material/MinorCrashOutlined')),
    MinorCrashRounded: React.lazy(() => import('@mui/icons-material/MinorCrashRounded')),
    MinorCrashSharp: React.lazy(() => import('@mui/icons-material/MinorCrashSharp')),
    MinorCrashTwoTone: React.lazy(() => import('@mui/icons-material/MinorCrashTwoTone')),
    MiscellaneousServices: React.lazy(() => import('@mui/icons-material/MiscellaneousServices')),
    MiscellaneousServicesOutlined: React.lazy(() => import('@mui/icons-material/MiscellaneousServicesOutlined')),
    MiscellaneousServicesRounded: React.lazy(() => import('@mui/icons-material/MiscellaneousServicesRounded')),
    MiscellaneousServicesSharp: React.lazy(() => import('@mui/icons-material/MiscellaneousServicesSharp')),
    MiscellaneousServicesTwoTone: React.lazy(() => import('@mui/icons-material/MiscellaneousServicesTwoTone')),
    MissedVideoCall: React.lazy(() => import('@mui/icons-material/MissedVideoCall')),
    MissedVideoCallOutlined: React.lazy(() => import('@mui/icons-material/MissedVideoCallOutlined')),
    MissedVideoCallRounded: React.lazy(() => import('@mui/icons-material/MissedVideoCallRounded')),
    MissedVideoCallSharp: React.lazy(() => import('@mui/icons-material/MissedVideoCallSharp')),
    MissedVideoCallTwoTone: React.lazy(() => import('@mui/icons-material/MissedVideoCallTwoTone')),
    Mms: React.lazy(() => import('@mui/icons-material/Mms')),
    MmsOutlined: React.lazy(() => import('@mui/icons-material/MmsOutlined')),
    MmsRounded: React.lazy(() => import('@mui/icons-material/MmsRounded')),
    MmsSharp: React.lazy(() => import('@mui/icons-material/MmsSharp')),
    MmsTwoTone: React.lazy(() => import('@mui/icons-material/MmsTwoTone')),
    MobiledataOff: React.lazy(() => import('@mui/icons-material/MobiledataOff')),
    MobiledataOffOutlined: React.lazy(() => import('@mui/icons-material/MobiledataOffOutlined')),
    MobiledataOffRounded: React.lazy(() => import('@mui/icons-material/MobiledataOffRounded')),
    MobiledataOffSharp: React.lazy(() => import('@mui/icons-material/MobiledataOffSharp')),
    MobiledataOffTwoTone: React.lazy(() => import('@mui/icons-material/MobiledataOffTwoTone')),
    MobileFriendly: React.lazy(() => import('@mui/icons-material/MobileFriendly')),
    MobileFriendlyOutlined: React.lazy(() => import('@mui/icons-material/MobileFriendlyOutlined')),
    MobileFriendlyRounded: React.lazy(() => import('@mui/icons-material/MobileFriendlyRounded')),
    MobileFriendlySharp: React.lazy(() => import('@mui/icons-material/MobileFriendlySharp')),
    MobileFriendlyTwoTone: React.lazy(() => import('@mui/icons-material/MobileFriendlyTwoTone')),
    MobileOff: React.lazy(() => import('@mui/icons-material/MobileOff')),
    MobileOffOutlined: React.lazy(() => import('@mui/icons-material/MobileOffOutlined')),
    MobileOffRounded: React.lazy(() => import('@mui/icons-material/MobileOffRounded')),
    MobileOffSharp: React.lazy(() => import('@mui/icons-material/MobileOffSharp')),
    MobileOffTwoTone: React.lazy(() => import('@mui/icons-material/MobileOffTwoTone')),
    MobileScreenShare: React.lazy(() => import('@mui/icons-material/MobileScreenShare')),
    MobileScreenShareOutlined: React.lazy(() => import('@mui/icons-material/MobileScreenShareOutlined')),
    MobileScreenShareRounded: React.lazy(() => import('@mui/icons-material/MobileScreenShareRounded')),
    MobileScreenShareSharp: React.lazy(() => import('@mui/icons-material/MobileScreenShareSharp')),
    MobileScreenShareTwoTone: React.lazy(() => import('@mui/icons-material/MobileScreenShareTwoTone')),
    ModeComment: React.lazy(() => import('@mui/icons-material/ModeComment')),
    ModeCommentOutlined: React.lazy(() => import('@mui/icons-material/ModeCommentOutlined')),
    ModeCommentRounded: React.lazy(() => import('@mui/icons-material/ModeCommentRounded')),
    ModeCommentSharp: React.lazy(() => import('@mui/icons-material/ModeCommentSharp')),
    ModeCommentTwoTone: React.lazy(() => import('@mui/icons-material/ModeCommentTwoTone')),
    Mode: React.lazy(() => import('@mui/icons-material/Mode')),
    ModeEdit: React.lazy(() => import('@mui/icons-material/ModeEdit')),
    ModeEditOutlined: React.lazy(() => import('@mui/icons-material/ModeEditOutlined')),
    ModeEditOutline: React.lazy(() => import('@mui/icons-material/ModeEditOutline')),
    ModeEditOutlineOutlined: React.lazy(() => import('@mui/icons-material/ModeEditOutlineOutlined')),
    ModeEditOutlineRounded: React.lazy(() => import('@mui/icons-material/ModeEditOutlineRounded')),
    ModeEditOutlineSharp: React.lazy(() => import('@mui/icons-material/ModeEditOutlineSharp')),
    ModeEditOutlineTwoTone: React.lazy(() => import('@mui/icons-material/ModeEditOutlineTwoTone')),
    ModeEditRounded: React.lazy(() => import('@mui/icons-material/ModeEditRounded')),
    ModeEditSharp: React.lazy(() => import('@mui/icons-material/ModeEditSharp')),
    ModeEditTwoTone: React.lazy(() => import('@mui/icons-material/ModeEditTwoTone')),
    ModeFanOff: React.lazy(() => import('@mui/icons-material/ModeFanOff')),
    ModeFanOffOutlined: React.lazy(() => import('@mui/icons-material/ModeFanOffOutlined')),
    ModeFanOffRounded: React.lazy(() => import('@mui/icons-material/ModeFanOffRounded')),
    ModeFanOffSharp: React.lazy(() => import('@mui/icons-material/ModeFanOffSharp')),
    ModeFanOffTwoTone: React.lazy(() => import('@mui/icons-material/ModeFanOffTwoTone')),
    ModelTraining: React.lazy(() => import('@mui/icons-material/ModelTraining')),
    ModelTrainingOutlined: React.lazy(() => import('@mui/icons-material/ModelTrainingOutlined')),
    ModelTrainingRounded: React.lazy(() => import('@mui/icons-material/ModelTrainingRounded')),
    ModelTrainingSharp: React.lazy(() => import('@mui/icons-material/ModelTrainingSharp')),
    ModelTrainingTwoTone: React.lazy(() => import('@mui/icons-material/ModelTrainingTwoTone')),
    ModeNight: React.lazy(() => import('@mui/icons-material/ModeNight')),
    ModeNightOutlined: React.lazy(() => import('@mui/icons-material/ModeNightOutlined')),
    ModeNightRounded: React.lazy(() => import('@mui/icons-material/ModeNightRounded')),
    ModeNightSharp: React.lazy(() => import('@mui/icons-material/ModeNightSharp')),
    ModeNightTwoTone: React.lazy(() => import('@mui/icons-material/ModeNightTwoTone')),
    ModeOfTravel: React.lazy(() => import('@mui/icons-material/ModeOfTravel')),
    ModeOfTravelOutlined: React.lazy(() => import('@mui/icons-material/ModeOfTravelOutlined')),
    ModeOfTravelRounded: React.lazy(() => import('@mui/icons-material/ModeOfTravelRounded')),
    ModeOfTravelSharp: React.lazy(() => import('@mui/icons-material/ModeOfTravelSharp')),
    ModeOfTravelTwoTone: React.lazy(() => import('@mui/icons-material/ModeOfTravelTwoTone')),
    ModeOutlined: React.lazy(() => import('@mui/icons-material/ModeOutlined')),
    ModeRounded: React.lazy(() => import('@mui/icons-material/ModeRounded')),
    ModeSharp: React.lazy(() => import('@mui/icons-material/ModeSharp')),
    ModeStandby: React.lazy(() => import('@mui/icons-material/ModeStandby')),
    ModeStandbyOutlined: React.lazy(() => import('@mui/icons-material/ModeStandbyOutlined')),
    ModeStandbyRounded: React.lazy(() => import('@mui/icons-material/ModeStandbyRounded')),
    ModeStandbySharp: React.lazy(() => import('@mui/icons-material/ModeStandbySharp')),
    ModeStandbyTwoTone: React.lazy(() => import('@mui/icons-material/ModeStandbyTwoTone')),
    ModeTwoTone: React.lazy(() => import('@mui/icons-material/ModeTwoTone')),
    MonetizationOn: React.lazy(() => import('@mui/icons-material/MonetizationOn')),
    MonetizationOnOutlined: React.lazy(() => import('@mui/icons-material/MonetizationOnOutlined')),
    MonetizationOnRounded: React.lazy(() => import('@mui/icons-material/MonetizationOnRounded')),
    MonetizationOnSharp: React.lazy(() => import('@mui/icons-material/MonetizationOnSharp')),
    MonetizationOnTwoTone: React.lazy(() => import('@mui/icons-material/MonetizationOnTwoTone')),
    Money: React.lazy(() => import('@mui/icons-material/Money')),
    MoneyOffCsred: React.lazy(() => import('@mui/icons-material/MoneyOffCsred')),
    MoneyOffCsredOutlined: React.lazy(() => import('@mui/icons-material/MoneyOffCsredOutlined')),
    MoneyOffCsredRounded: React.lazy(() => import('@mui/icons-material/MoneyOffCsredRounded')),
    MoneyOffCsredSharp: React.lazy(() => import('@mui/icons-material/MoneyOffCsredSharp')),
    MoneyOffCsredTwoTone: React.lazy(() => import('@mui/icons-material/MoneyOffCsredTwoTone')),
    MoneyOff: React.lazy(() => import('@mui/icons-material/MoneyOff')),
    MoneyOffOutlined: React.lazy(() => import('@mui/icons-material/MoneyOffOutlined')),
    MoneyOffRounded: React.lazy(() => import('@mui/icons-material/MoneyOffRounded')),
    MoneyOffSharp: React.lazy(() => import('@mui/icons-material/MoneyOffSharp')),
    MoneyOffTwoTone: React.lazy(() => import('@mui/icons-material/MoneyOffTwoTone')),
    MoneyOutlined: React.lazy(() => import('@mui/icons-material/MoneyOutlined')),
    MoneyRounded: React.lazy(() => import('@mui/icons-material/MoneyRounded')),
    MoneySharp: React.lazy(() => import('@mui/icons-material/MoneySharp')),
    MoneyTwoTone: React.lazy(() => import('@mui/icons-material/MoneyTwoTone')),
    Monitor: React.lazy(() => import('@mui/icons-material/Monitor')),
    MonitorHeart: React.lazy(() => import('@mui/icons-material/MonitorHeart')),
    MonitorHeartOutlined: React.lazy(() => import('@mui/icons-material/MonitorHeartOutlined')),
    MonitorHeartRounded: React.lazy(() => import('@mui/icons-material/MonitorHeartRounded')),
    MonitorHeartSharp: React.lazy(() => import('@mui/icons-material/MonitorHeartSharp')),
    MonitorHeartTwoTone: React.lazy(() => import('@mui/icons-material/MonitorHeartTwoTone')),
    MonitorOutlined: React.lazy(() => import('@mui/icons-material/MonitorOutlined')),
    MonitorRounded: React.lazy(() => import('@mui/icons-material/MonitorRounded')),
    MonitorSharp: React.lazy(() => import('@mui/icons-material/MonitorSharp')),
    MonitorTwoTone: React.lazy(() => import('@mui/icons-material/MonitorTwoTone')),
    MonitorWeight: React.lazy(() => import('@mui/icons-material/MonitorWeight')),
    MonitorWeightOutlined: React.lazy(() => import('@mui/icons-material/MonitorWeightOutlined')),
    MonitorWeightRounded: React.lazy(() => import('@mui/icons-material/MonitorWeightRounded')),
    MonitorWeightSharp: React.lazy(() => import('@mui/icons-material/MonitorWeightSharp')),
    MonitorWeightTwoTone: React.lazy(() => import('@mui/icons-material/MonitorWeightTwoTone')),
    MonochromePhotos: React.lazy(() => import('@mui/icons-material/MonochromePhotos')),
    MonochromePhotosOutlined: React.lazy(() => import('@mui/icons-material/MonochromePhotosOutlined')),
    MonochromePhotosRounded: React.lazy(() => import('@mui/icons-material/MonochromePhotosRounded')),
    MonochromePhotosSharp: React.lazy(() => import('@mui/icons-material/MonochromePhotosSharp')),
    MonochromePhotosTwoTone: React.lazy(() => import('@mui/icons-material/MonochromePhotosTwoTone')),
    MoodBad: React.lazy(() => import('@mui/icons-material/MoodBad')),
    MoodBadOutlined: React.lazy(() => import('@mui/icons-material/MoodBadOutlined')),
    MoodBadRounded: React.lazy(() => import('@mui/icons-material/MoodBadRounded')),
    MoodBadSharp: React.lazy(() => import('@mui/icons-material/MoodBadSharp')),
    MoodBadTwoTone: React.lazy(() => import('@mui/icons-material/MoodBadTwoTone')),
    Mood: React.lazy(() => import('@mui/icons-material/Mood')),
    MoodOutlined: React.lazy(() => import('@mui/icons-material/MoodOutlined')),
    MoodRounded: React.lazy(() => import('@mui/icons-material/MoodRounded')),
    MoodSharp: React.lazy(() => import('@mui/icons-material/MoodSharp')),
    MoodTwoTone: React.lazy(() => import('@mui/icons-material/MoodTwoTone')),
    Moped: React.lazy(() => import('@mui/icons-material/Moped')),
    MopedOutlined: React.lazy(() => import('@mui/icons-material/MopedOutlined')),
    MopedRounded: React.lazy(() => import('@mui/icons-material/MopedRounded')),
    MopedSharp: React.lazy(() => import('@mui/icons-material/MopedSharp')),
    MopedTwoTone: React.lazy(() => import('@mui/icons-material/MopedTwoTone')),
    More: React.lazy(() => import('@mui/icons-material/More')),
    MoreHoriz: React.lazy(() => import('@mui/icons-material/MoreHoriz')),
    MoreHorizOutlined: React.lazy(() => import('@mui/icons-material/MoreHorizOutlined')),
    MoreHorizRounded: React.lazy(() => import('@mui/icons-material/MoreHorizRounded')),
    MoreHorizSharp: React.lazy(() => import('@mui/icons-material/MoreHorizSharp')),
    MoreHorizTwoTone: React.lazy(() => import('@mui/icons-material/MoreHorizTwoTone')),
    MoreOutlined: React.lazy(() => import('@mui/icons-material/MoreOutlined')),
    MoreRounded: React.lazy(() => import('@mui/icons-material/MoreRounded')),
    MoreSharp: React.lazy(() => import('@mui/icons-material/MoreSharp')),
    MoreTime: React.lazy(() => import('@mui/icons-material/MoreTime')),
    MoreTimeOutlined: React.lazy(() => import('@mui/icons-material/MoreTimeOutlined')),
    MoreTimeRounded: React.lazy(() => import('@mui/icons-material/MoreTimeRounded')),
    MoreTimeSharp: React.lazy(() => import('@mui/icons-material/MoreTimeSharp')),
    MoreTimeTwoTone: React.lazy(() => import('@mui/icons-material/MoreTimeTwoTone')),
    MoreTwoTone: React.lazy(() => import('@mui/icons-material/MoreTwoTone')),
    MoreVert: React.lazy(() => import('@mui/icons-material/MoreVert')),
    MoreVertOutlined: React.lazy(() => import('@mui/icons-material/MoreVertOutlined')),
    MoreVertRounded: React.lazy(() => import('@mui/icons-material/MoreVertRounded')),
    MoreVertSharp: React.lazy(() => import('@mui/icons-material/MoreVertSharp')),
    MoreVertTwoTone: React.lazy(() => import('@mui/icons-material/MoreVertTwoTone')),
    Mosque: React.lazy(() => import('@mui/icons-material/Mosque')),
    MosqueOutlined: React.lazy(() => import('@mui/icons-material/MosqueOutlined')),
    MosqueRounded: React.lazy(() => import('@mui/icons-material/MosqueRounded')),
    MosqueSharp: React.lazy(() => import('@mui/icons-material/MosqueSharp')),
    MosqueTwoTone: React.lazy(() => import('@mui/icons-material/MosqueTwoTone')),
    MotionPhotosAuto: React.lazy(() => import('@mui/icons-material/MotionPhotosAuto')),
    MotionPhotosAutoOutlined: React.lazy(() => import('@mui/icons-material/MotionPhotosAutoOutlined')),
    MotionPhotosAutoRounded: React.lazy(() => import('@mui/icons-material/MotionPhotosAutoRounded')),
    MotionPhotosAutoSharp: React.lazy(() => import('@mui/icons-material/MotionPhotosAutoSharp')),
    MotionPhotosAutoTwoTone: React.lazy(() => import('@mui/icons-material/MotionPhotosAutoTwoTone')),
    MotionPhotosOff: React.lazy(() => import('@mui/icons-material/MotionPhotosOff')),
    MotionPhotosOffOutlined: React.lazy(() => import('@mui/icons-material/MotionPhotosOffOutlined')),
    MotionPhotosOffRounded: React.lazy(() => import('@mui/icons-material/MotionPhotosOffRounded')),
    MotionPhotosOffSharp: React.lazy(() => import('@mui/icons-material/MotionPhotosOffSharp')),
    MotionPhotosOffTwoTone: React.lazy(() => import('@mui/icons-material/MotionPhotosOffTwoTone')),
    Mouse: React.lazy(() => import('@mui/icons-material/Mouse')),
    MouseOutlined: React.lazy(() => import('@mui/icons-material/MouseOutlined')),
    MouseRounded: React.lazy(() => import('@mui/icons-material/MouseRounded')),
    MouseSharp: React.lazy(() => import('@mui/icons-material/MouseSharp')),
    MouseTwoTone: React.lazy(() => import('@mui/icons-material/MouseTwoTone')),
    MoveDown: React.lazy(() => import('@mui/icons-material/MoveDown')),
    MoveDownOutlined: React.lazy(() => import('@mui/icons-material/MoveDownOutlined')),
    MoveDownRounded: React.lazy(() => import('@mui/icons-material/MoveDownRounded')),
    MoveDownSharp: React.lazy(() => import('@mui/icons-material/MoveDownSharp')),
    MoveDownTwoTone: React.lazy(() => import('@mui/icons-material/MoveDownTwoTone')),
    MoveToInbox: React.lazy(() => import('@mui/icons-material/MoveToInbox')),
    MoveToInboxOutlined: React.lazy(() => import('@mui/icons-material/MoveToInboxOutlined')),
    MoveToInboxRounded: React.lazy(() => import('@mui/icons-material/MoveToInboxRounded')),
    MoveToInboxSharp: React.lazy(() => import('@mui/icons-material/MoveToInboxSharp')),
    MoveToInboxTwoTone: React.lazy(() => import('@mui/icons-material/MoveToInboxTwoTone')),
    MoveUp: React.lazy(() => import('@mui/icons-material/MoveUp')),
    MoveUpOutlined: React.lazy(() => import('@mui/icons-material/MoveUpOutlined')),
    MoveUpRounded: React.lazy(() => import('@mui/icons-material/MoveUpRounded')),
    MoveUpSharp: React.lazy(() => import('@mui/icons-material/MoveUpSharp')),
    MoveUpTwoTone: React.lazy(() => import('@mui/icons-material/MoveUpTwoTone')),
    MovieCreation: React.lazy(() => import('@mui/icons-material/MovieCreation')),
    MovieCreationOutlined: React.lazy(() => import('@mui/icons-material/MovieCreationOutlined')),
    MovieCreationRounded: React.lazy(() => import('@mui/icons-material/MovieCreationRounded')),
    MovieCreationSharp: React.lazy(() => import('@mui/icons-material/MovieCreationSharp')),
    MovieCreationTwoTone: React.lazy(() => import('@mui/icons-material/MovieCreationTwoTone')),
    Movie: React.lazy(() => import('@mui/icons-material/Movie')),
    MovieFilter: React.lazy(() => import('@mui/icons-material/MovieFilter')),
    MovieFilterOutlined: React.lazy(() => import('@mui/icons-material/MovieFilterOutlined')),
    MovieFilterRounded: React.lazy(() => import('@mui/icons-material/MovieFilterRounded')),
    MovieFilterSharp: React.lazy(() => import('@mui/icons-material/MovieFilterSharp')),
    MovieFilterTwoTone: React.lazy(() => import('@mui/icons-material/MovieFilterTwoTone')),
    MovieOutlined: React.lazy(() => import('@mui/icons-material/MovieOutlined')),
    MovieRounded: React.lazy(() => import('@mui/icons-material/MovieRounded')),
    MovieSharp: React.lazy(() => import('@mui/icons-material/MovieSharp')),
    MovieTwoTone: React.lazy(() => import('@mui/icons-material/MovieTwoTone')),
    Moving: React.lazy(() => import('@mui/icons-material/Moving')),
    MovingOutlined: React.lazy(() => import('@mui/icons-material/MovingOutlined')),
    MovingRounded: React.lazy(() => import('@mui/icons-material/MovingRounded')),
    MovingSharp: React.lazy(() => import('@mui/icons-material/MovingSharp')),
    MovingTwoTone: React.lazy(() => import('@mui/icons-material/MovingTwoTone')),
    Mp: React.lazy(() => import('@mui/icons-material/Mp')),
    MpOutlined: React.lazy(() => import('@mui/icons-material/MpOutlined')),
    MpRounded: React.lazy(() => import('@mui/icons-material/MpRounded')),
    MpSharp: React.lazy(() => import('@mui/icons-material/MpSharp')),
    MpTwoTone: React.lazy(() => import('@mui/icons-material/MpTwoTone')),
    MultilineChart: React.lazy(() => import('@mui/icons-material/MultilineChart')),
    MultilineChartOutlined: React.lazy(() => import('@mui/icons-material/MultilineChartOutlined')),
    MultilineChartRounded: React.lazy(() => import('@mui/icons-material/MultilineChartRounded')),
    MultilineChartSharp: React.lazy(() => import('@mui/icons-material/MultilineChartSharp')),
    MultilineChartTwoTone: React.lazy(() => import('@mui/icons-material/MultilineChartTwoTone')),
    MultipleStop: React.lazy(() => import('@mui/icons-material/MultipleStop')),
    MultipleStopOutlined: React.lazy(() => import('@mui/icons-material/MultipleStopOutlined')),
    MultipleStopRounded: React.lazy(() => import('@mui/icons-material/MultipleStopRounded')),
    MultipleStopSharp: React.lazy(() => import('@mui/icons-material/MultipleStopSharp')),
    MultipleStopTwoTone: React.lazy(() => import('@mui/icons-material/MultipleStopTwoTone')),
    Museum: React.lazy(() => import('@mui/icons-material/Museum')),
    MuseumOutlined: React.lazy(() => import('@mui/icons-material/MuseumOutlined')),
    MuseumRounded: React.lazy(() => import('@mui/icons-material/MuseumRounded')),
    MuseumSharp: React.lazy(() => import('@mui/icons-material/MuseumSharp')),
    MuseumTwoTone: React.lazy(() => import('@mui/icons-material/MuseumTwoTone')),
    MusicNote: React.lazy(() => import('@mui/icons-material/MusicNote')),
    MusicNoteOutlined: React.lazy(() => import('@mui/icons-material/MusicNoteOutlined')),
    MusicNoteRounded: React.lazy(() => import('@mui/icons-material/MusicNoteRounded')),
    MusicNoteSharp: React.lazy(() => import('@mui/icons-material/MusicNoteSharp')),
    MusicNoteTwoTone: React.lazy(() => import('@mui/icons-material/MusicNoteTwoTone')),
    MusicOff: React.lazy(() => import('@mui/icons-material/MusicOff')),
    MusicOffOutlined: React.lazy(() => import('@mui/icons-material/MusicOffOutlined')),
    MusicOffRounded: React.lazy(() => import('@mui/icons-material/MusicOffRounded')),
    MusicOffSharp: React.lazy(() => import('@mui/icons-material/MusicOffSharp')),
    MusicOffTwoTone: React.lazy(() => import('@mui/icons-material/MusicOffTwoTone')),
    MusicVideo: React.lazy(() => import('@mui/icons-material/MusicVideo')),
    MusicVideoOutlined: React.lazy(() => import('@mui/icons-material/MusicVideoOutlined')),
    MusicVideoRounded: React.lazy(() => import('@mui/icons-material/MusicVideoRounded')),
    MusicVideoSharp: React.lazy(() => import('@mui/icons-material/MusicVideoSharp')),
    MusicVideoTwoTone: React.lazy(() => import('@mui/icons-material/MusicVideoTwoTone')),
    MyLocation: React.lazy(() => import('@mui/icons-material/MyLocation')),
    MyLocationOutlined: React.lazy(() => import('@mui/icons-material/MyLocationOutlined')),
    MyLocationRounded: React.lazy(() => import('@mui/icons-material/MyLocationRounded')),
    MyLocationSharp: React.lazy(() => import('@mui/icons-material/MyLocationSharp')),
    MyLocationTwoTone: React.lazy(() => import('@mui/icons-material/MyLocationTwoTone')),
    Nat: React.lazy(() => import('@mui/icons-material/Nat')),
    NatOutlined: React.lazy(() => import('@mui/icons-material/NatOutlined')),
    NatRounded: React.lazy(() => import('@mui/icons-material/NatRounded')),
    NatSharp: React.lazy(() => import('@mui/icons-material/NatSharp')),
    NatTwoTone: React.lazy(() => import('@mui/icons-material/NatTwoTone')),
    Nature: React.lazy(() => import('@mui/icons-material/Nature')),
    NatureOutlined: React.lazy(() => import('@mui/icons-material/NatureOutlined')),
    NaturePeople: React.lazy(() => import('@mui/icons-material/NaturePeople')),
    NaturePeopleOutlined: React.lazy(() => import('@mui/icons-material/NaturePeopleOutlined')),
    NaturePeopleRounded: React.lazy(() => import('@mui/icons-material/NaturePeopleRounded')),
    NaturePeopleSharp: React.lazy(() => import('@mui/icons-material/NaturePeopleSharp')),
    NaturePeopleTwoTone: React.lazy(() => import('@mui/icons-material/NaturePeopleTwoTone')),
    NatureRounded: React.lazy(() => import('@mui/icons-material/NatureRounded')),
    NatureSharp: React.lazy(() => import('@mui/icons-material/NatureSharp')),
    NatureTwoTone: React.lazy(() => import('@mui/icons-material/NatureTwoTone')),
    NavigateBefore: React.lazy(() => import('@mui/icons-material/NavigateBefore')),
    NavigateBeforeOutlined: React.lazy(() => import('@mui/icons-material/NavigateBeforeOutlined')),
    NavigateBeforeRounded: React.lazy(() => import('@mui/icons-material/NavigateBeforeRounded')),
    NavigateBeforeSharp: React.lazy(() => import('@mui/icons-material/NavigateBeforeSharp')),
    NavigateBeforeTwoTone: React.lazy(() => import('@mui/icons-material/NavigateBeforeTwoTone')),
    NavigateNext: React.lazy(() => import('@mui/icons-material/NavigateNext')),
    NavigateNextOutlined: React.lazy(() => import('@mui/icons-material/NavigateNextOutlined')),
    NavigateNextRounded: React.lazy(() => import('@mui/icons-material/NavigateNextRounded')),
    NavigateNextSharp: React.lazy(() => import('@mui/icons-material/NavigateNextSharp')),
    NavigateNextTwoTone: React.lazy(() => import('@mui/icons-material/NavigateNextTwoTone')),
    Navigation: React.lazy(() => import('@mui/icons-material/Navigation')),
    NavigationOutlined: React.lazy(() => import('@mui/icons-material/NavigationOutlined')),
    NavigationRounded: React.lazy(() => import('@mui/icons-material/NavigationRounded')),
    NavigationSharp: React.lazy(() => import('@mui/icons-material/NavigationSharp')),
    NavigationTwoTone: React.lazy(() => import('@mui/icons-material/NavigationTwoTone')),
    NearbyError: React.lazy(() => import('@mui/icons-material/NearbyError')),
    NearbyErrorOutlined: React.lazy(() => import('@mui/icons-material/NearbyErrorOutlined')),
    NearbyErrorRounded: React.lazy(() => import('@mui/icons-material/NearbyErrorRounded')),
    NearbyErrorSharp: React.lazy(() => import('@mui/icons-material/NearbyErrorSharp')),
    NearbyErrorTwoTone: React.lazy(() => import('@mui/icons-material/NearbyErrorTwoTone')),
    NearbyOff: React.lazy(() => import('@mui/icons-material/NearbyOff')),
    NearbyOffOutlined: React.lazy(() => import('@mui/icons-material/NearbyOffOutlined')),
    NearbyOffRounded: React.lazy(() => import('@mui/icons-material/NearbyOffRounded')),
    NearbyOffSharp: React.lazy(() => import('@mui/icons-material/NearbyOffSharp')),
    NearbyOffTwoTone: React.lazy(() => import('@mui/icons-material/NearbyOffTwoTone')),
    NearMeDisabled: React.lazy(() => import('@mui/icons-material/NearMeDisabled')),
    NearMeDisabledOutlined: React.lazy(() => import('@mui/icons-material/NearMeDisabledOutlined')),
    NearMeDisabledRounded: React.lazy(() => import('@mui/icons-material/NearMeDisabledRounded')),
    NearMeDisabledSharp: React.lazy(() => import('@mui/icons-material/NearMeDisabledSharp')),
    NearMeDisabledTwoTone: React.lazy(() => import('@mui/icons-material/NearMeDisabledTwoTone')),
    NearMe: React.lazy(() => import('@mui/icons-material/NearMe')),
    NearMeOutlined: React.lazy(() => import('@mui/icons-material/NearMeOutlined')),
    NearMeRounded: React.lazy(() => import('@mui/icons-material/NearMeRounded')),
    NearMeSharp: React.lazy(() => import('@mui/icons-material/NearMeSharp')),
    NearMeTwoTone: React.lazy(() => import('@mui/icons-material/NearMeTwoTone')),
    NestCamWiredStand: React.lazy(() => import('@mui/icons-material/NestCamWiredStand')),
    NestCamWiredStandOutlined: React.lazy(() => import('@mui/icons-material/NestCamWiredStandOutlined')),
    NestCamWiredStandRounded: React.lazy(() => import('@mui/icons-material/NestCamWiredStandRounded')),
    NestCamWiredStandSharp: React.lazy(() => import('@mui/icons-material/NestCamWiredStandSharp')),
    NestCamWiredStandTwoTone: React.lazy(() => import('@mui/icons-material/NestCamWiredStandTwoTone')),
    NetworkCell: React.lazy(() => import('@mui/icons-material/NetworkCell')),
    NetworkCellOutlined: React.lazy(() => import('@mui/icons-material/NetworkCellOutlined')),
    NetworkCellRounded: React.lazy(() => import('@mui/icons-material/NetworkCellRounded')),
    NetworkCellSharp: React.lazy(() => import('@mui/icons-material/NetworkCellSharp')),
    NetworkCellTwoTone: React.lazy(() => import('@mui/icons-material/NetworkCellTwoTone')),
    NetworkCheck: React.lazy(() => import('@mui/icons-material/NetworkCheck')),
    NetworkCheckOutlined: React.lazy(() => import('@mui/icons-material/NetworkCheckOutlined')),
    NetworkCheckRounded: React.lazy(() => import('@mui/icons-material/NetworkCheckRounded')),
    NetworkCheckSharp: React.lazy(() => import('@mui/icons-material/NetworkCheckSharp')),
    NetworkCheckTwoTone: React.lazy(() => import('@mui/icons-material/NetworkCheckTwoTone')),
    NetworkLocked: React.lazy(() => import('@mui/icons-material/NetworkLocked')),
    NetworkLockedOutlined: React.lazy(() => import('@mui/icons-material/NetworkLockedOutlined')),
    NetworkLockedRounded: React.lazy(() => import('@mui/icons-material/NetworkLockedRounded')),
    NetworkLockedSharp: React.lazy(() => import('@mui/icons-material/NetworkLockedSharp')),
    NetworkLockedTwoTone: React.lazy(() => import('@mui/icons-material/NetworkLockedTwoTone')),
    NetworkPing: React.lazy(() => import('@mui/icons-material/NetworkPing')),
    NetworkPingOutlined: React.lazy(() => import('@mui/icons-material/NetworkPingOutlined')),
    NetworkPingRounded: React.lazy(() => import('@mui/icons-material/NetworkPingRounded')),
    NetworkPingSharp: React.lazy(() => import('@mui/icons-material/NetworkPingSharp')),
    NetworkPingTwoTone: React.lazy(() => import('@mui/icons-material/NetworkPingTwoTone')),
    NetworkWifi1Bar: React.lazy(() => import('@mui/icons-material/NetworkWifi1Bar')),
    NetworkWifi1BarOutlined: React.lazy(() => import('@mui/icons-material/NetworkWifi1BarOutlined')),
    NetworkWifi1BarRounded: React.lazy(() => import('@mui/icons-material/NetworkWifi1BarRounded')),
    NetworkWifi1BarSharp: React.lazy(() => import('@mui/icons-material/NetworkWifi1BarSharp')),
    NetworkWifi1BarTwoTone: React.lazy(() => import('@mui/icons-material/NetworkWifi1BarTwoTone')),
    NetworkWifi2Bar: React.lazy(() => import('@mui/icons-material/NetworkWifi2Bar')),
    NetworkWifi2BarOutlined: React.lazy(() => import('@mui/icons-material/NetworkWifi2BarOutlined')),
    NetworkWifi2BarRounded: React.lazy(() => import('@mui/icons-material/NetworkWifi2BarRounded')),
    NetworkWifi2BarSharp: React.lazy(() => import('@mui/icons-material/NetworkWifi2BarSharp')),
    NetworkWifi2BarTwoTone: React.lazy(() => import('@mui/icons-material/NetworkWifi2BarTwoTone')),
    NetworkWifi3Bar: React.lazy(() => import('@mui/icons-material/NetworkWifi3Bar')),
    NetworkWifi3BarOutlined: React.lazy(() => import('@mui/icons-material/NetworkWifi3BarOutlined')),
    NetworkWifi3BarRounded: React.lazy(() => import('@mui/icons-material/NetworkWifi3BarRounded')),
    NetworkWifi3BarSharp: React.lazy(() => import('@mui/icons-material/NetworkWifi3BarSharp')),
    NetworkWifi3BarTwoTone: React.lazy(() => import('@mui/icons-material/NetworkWifi3BarTwoTone')),
    NetworkWifi: React.lazy(() => import('@mui/icons-material/NetworkWifi')),
    NetworkWifiOutlined: React.lazy(() => import('@mui/icons-material/NetworkWifiOutlined')),
    NetworkWifiRounded: React.lazy(() => import('@mui/icons-material/NetworkWifiRounded')),
    NetworkWifiSharp: React.lazy(() => import('@mui/icons-material/NetworkWifiSharp')),
    NetworkWifiTwoTone: React.lazy(() => import('@mui/icons-material/NetworkWifiTwoTone')),
    NewReleases: React.lazy(() => import('@mui/icons-material/NewReleases')),
    NewReleasesOutlined: React.lazy(() => import('@mui/icons-material/NewReleasesOutlined')),
    NewReleasesRounded: React.lazy(() => import('@mui/icons-material/NewReleasesRounded')),
    NewReleasesSharp: React.lazy(() => import('@mui/icons-material/NewReleasesSharp')),
    NewReleasesTwoTone: React.lazy(() => import('@mui/icons-material/NewReleasesTwoTone')),
    Newspaper: React.lazy(() => import('@mui/icons-material/Newspaper')),
    NewspaperOutlined: React.lazy(() => import('@mui/icons-material/NewspaperOutlined')),
    NewspaperRounded: React.lazy(() => import('@mui/icons-material/NewspaperRounded')),
    NewspaperSharp: React.lazy(() => import('@mui/icons-material/NewspaperSharp')),
    NewspaperTwoTone: React.lazy(() => import('@mui/icons-material/NewspaperTwoTone')),
    NextPlan: React.lazy(() => import('@mui/icons-material/NextPlan')),
    NextPlanOutlined: React.lazy(() => import('@mui/icons-material/NextPlanOutlined')),
    NextPlanRounded: React.lazy(() => import('@mui/icons-material/NextPlanRounded')),
    NextPlanSharp: React.lazy(() => import('@mui/icons-material/NextPlanSharp')),
    NextPlanTwoTone: React.lazy(() => import('@mui/icons-material/NextPlanTwoTone')),
    NextWeek: React.lazy(() => import('@mui/icons-material/NextWeek')),
    NextWeekOutlined: React.lazy(() => import('@mui/icons-material/NextWeekOutlined')),
    NextWeekRounded: React.lazy(() => import('@mui/icons-material/NextWeekRounded')),
    NextWeekSharp: React.lazy(() => import('@mui/icons-material/NextWeekSharp')),
    NextWeekTwoTone: React.lazy(() => import('@mui/icons-material/NextWeekTwoTone')),
    Nfc: React.lazy(() => import('@mui/icons-material/Nfc')),
    NfcOutlined: React.lazy(() => import('@mui/icons-material/NfcOutlined')),
    NfcRounded: React.lazy(() => import('@mui/icons-material/NfcRounded')),
    NfcSharp: React.lazy(() => import('@mui/icons-material/NfcSharp')),
    NfcTwoTone: React.lazy(() => import('@mui/icons-material/NfcTwoTone')),
    Nightlife: React.lazy(() => import('@mui/icons-material/Nightlife')),
    NightlifeOutlined: React.lazy(() => import('@mui/icons-material/NightlifeOutlined')),
    NightlifeRounded: React.lazy(() => import('@mui/icons-material/NightlifeRounded')),
    NightlifeSharp: React.lazy(() => import('@mui/icons-material/NightlifeSharp')),
    NightlifeTwoTone: React.lazy(() => import('@mui/icons-material/NightlifeTwoTone')),
    Nightlight: React.lazy(() => import('@mui/icons-material/Nightlight')),
    NightlightOutlined: React.lazy(() => import('@mui/icons-material/NightlightOutlined')),
    NightlightRound: React.lazy(() => import('@mui/icons-material/NightlightRound')),
    NightlightRounded: React.lazy(() => import('@mui/icons-material/NightlightRounded')),
    NightlightRoundOutlined: React.lazy(() => import('@mui/icons-material/NightlightRoundOutlined')),
    NightlightRoundRounded: React.lazy(() => import('@mui/icons-material/NightlightRoundRounded')),
    NightlightRoundSharp: React.lazy(() => import('@mui/icons-material/NightlightRoundSharp')),
    NightlightRoundTwoTone: React.lazy(() => import('@mui/icons-material/NightlightRoundTwoTone')),
    NightlightSharp: React.lazy(() => import('@mui/icons-material/NightlightSharp')),
    NightlightTwoTone: React.lazy(() => import('@mui/icons-material/NightlightTwoTone')),
    NightShelter: React.lazy(() => import('@mui/icons-material/NightShelter')),
    NightShelterOutlined: React.lazy(() => import('@mui/icons-material/NightShelterOutlined')),
    NightShelterRounded: React.lazy(() => import('@mui/icons-material/NightShelterRounded')),
    NightShelterSharp: React.lazy(() => import('@mui/icons-material/NightShelterSharp')),
    NightShelterTwoTone: React.lazy(() => import('@mui/icons-material/NightShelterTwoTone')),
    NightsStay: React.lazy(() => import('@mui/icons-material/NightsStay')),
    NightsStayOutlined: React.lazy(() => import('@mui/icons-material/NightsStayOutlined')),
    NightsStayRounded: React.lazy(() => import('@mui/icons-material/NightsStayRounded')),
    NightsStaySharp: React.lazy(() => import('@mui/icons-material/NightsStaySharp')),
    NightsStayTwoTone: React.lazy(() => import('@mui/icons-material/NightsStayTwoTone')),
    NineK: React.lazy(() => import('@mui/icons-material/NineK')),
    NineKOutlined: React.lazy(() => import('@mui/icons-material/NineKOutlined')),
    NineKPlus: React.lazy(() => import('@mui/icons-material/NineKPlus')),
    NineKPlusOutlined: React.lazy(() => import('@mui/icons-material/NineKPlusOutlined')),
    NineKPlusRounded: React.lazy(() => import('@mui/icons-material/NineKPlusRounded')),
    NineKPlusSharp: React.lazy(() => import('@mui/icons-material/NineKPlusSharp')),
    NineKPlusTwoTone: React.lazy(() => import('@mui/icons-material/NineKPlusTwoTone')),
    NineKRounded: React.lazy(() => import('@mui/icons-material/NineKRounded')),
    NineKSharp: React.lazy(() => import('@mui/icons-material/NineKSharp')),
    NineKTwoTone: React.lazy(() => import('@mui/icons-material/NineKTwoTone')),
    NineMp: React.lazy(() => import('@mui/icons-material/NineMp')),
    NineMpOutlined: React.lazy(() => import('@mui/icons-material/NineMpOutlined')),
    NineMpRounded: React.lazy(() => import('@mui/icons-material/NineMpRounded')),
    NineMpSharp: React.lazy(() => import('@mui/icons-material/NineMpSharp')),
    NineMpTwoTone: React.lazy(() => import('@mui/icons-material/NineMpTwoTone')),
    NineteenMp: React.lazy(() => import('@mui/icons-material/NineteenMp')),
    NineteenMpOutlined: React.lazy(() => import('@mui/icons-material/NineteenMpOutlined')),
    NineteenMpRounded: React.lazy(() => import('@mui/icons-material/NineteenMpRounded')),
    NineteenMpSharp: React.lazy(() => import('@mui/icons-material/NineteenMpSharp')),
    NineteenMpTwoTone: React.lazy(() => import('@mui/icons-material/NineteenMpTwoTone')),
    NoAccounts: React.lazy(() => import('@mui/icons-material/NoAccounts')),
    NoAccountsOutlined: React.lazy(() => import('@mui/icons-material/NoAccountsOutlined')),
    NoAccountsRounded: React.lazy(() => import('@mui/icons-material/NoAccountsRounded')),
    NoAccountsSharp: React.lazy(() => import('@mui/icons-material/NoAccountsSharp')),
    NoAccountsTwoTone: React.lazy(() => import('@mui/icons-material/NoAccountsTwoTone')),
    NoAdultContent: React.lazy(() => import('@mui/icons-material/NoAdultContent')),
    NoAdultContentOutlined: React.lazy(() => import('@mui/icons-material/NoAdultContentOutlined')),
    NoAdultContentRounded: React.lazy(() => import('@mui/icons-material/NoAdultContentRounded')),
    NoAdultContentSharp: React.lazy(() => import('@mui/icons-material/NoAdultContentSharp')),
    NoAdultContentTwoTone: React.lazy(() => import('@mui/icons-material/NoAdultContentTwoTone')),
    NoBackpack: React.lazy(() => import('@mui/icons-material/NoBackpack')),
    NoBackpackOutlined: React.lazy(() => import('@mui/icons-material/NoBackpackOutlined')),
    NoBackpackRounded: React.lazy(() => import('@mui/icons-material/NoBackpackRounded')),
    NoBackpackSharp: React.lazy(() => import('@mui/icons-material/NoBackpackSharp')),
    NoBackpackTwoTone: React.lazy(() => import('@mui/icons-material/NoBackpackTwoTone')),
    NoCell: React.lazy(() => import('@mui/icons-material/NoCell')),
    NoCellOutlined: React.lazy(() => import('@mui/icons-material/NoCellOutlined')),
    NoCellRounded: React.lazy(() => import('@mui/icons-material/NoCellRounded')),
    NoCellSharp: React.lazy(() => import('@mui/icons-material/NoCellSharp')),
    NoCellTwoTone: React.lazy(() => import('@mui/icons-material/NoCellTwoTone')),
    NoCrash: React.lazy(() => import('@mui/icons-material/NoCrash')),
    NoCrashOutlined: React.lazy(() => import('@mui/icons-material/NoCrashOutlined')),
    NoCrashRounded: React.lazy(() => import('@mui/icons-material/NoCrashRounded')),
    NoCrashSharp: React.lazy(() => import('@mui/icons-material/NoCrashSharp')),
    NoCrashTwoTone: React.lazy(() => import('@mui/icons-material/NoCrashTwoTone')),
    NoDrinks: React.lazy(() => import('@mui/icons-material/NoDrinks')),
    NoDrinksOutlined: React.lazy(() => import('@mui/icons-material/NoDrinksOutlined')),
    NoDrinksRounded: React.lazy(() => import('@mui/icons-material/NoDrinksRounded')),
    NoDrinksSharp: React.lazy(() => import('@mui/icons-material/NoDrinksSharp')),
    NoDrinksTwoTone: React.lazy(() => import('@mui/icons-material/NoDrinksTwoTone')),
    NoEncryption: React.lazy(() => import('@mui/icons-material/NoEncryption')),
    NoEncryptionGmailerrorred: React.lazy(() => import('@mui/icons-material/NoEncryptionGmailerrorred')),
    NoEncryptionGmailerrorredOutlined: React.lazy(() => import('@mui/icons-material/NoEncryptionGmailerrorredOutlined')),
    NoEncryptionGmailerrorredRounded: React.lazy(() => import('@mui/icons-material/NoEncryptionGmailerrorredRounded')),
    NoEncryptionGmailerrorredSharp: React.lazy(() => import('@mui/icons-material/NoEncryptionGmailerrorredSharp')),
    NoEncryptionGmailerrorredTwoTone: React.lazy(() => import('@mui/icons-material/NoEncryptionGmailerrorredTwoTone')),
    NoEncryptionOutlined: React.lazy(() => import('@mui/icons-material/NoEncryptionOutlined')),
    NoEncryptionRounded: React.lazy(() => import('@mui/icons-material/NoEncryptionRounded')),
    NoEncryptionSharp: React.lazy(() => import('@mui/icons-material/NoEncryptionSharp')),
    NoEncryptionTwoTone: React.lazy(() => import('@mui/icons-material/NoEncryptionTwoTone')),
    NoFlash: React.lazy(() => import('@mui/icons-material/NoFlash')),
    NoFlashOutlined: React.lazy(() => import('@mui/icons-material/NoFlashOutlined')),
    NoFlashRounded: React.lazy(() => import('@mui/icons-material/NoFlashRounded')),
    NoFlashSharp: React.lazy(() => import('@mui/icons-material/NoFlashSharp')),
    NoFlashTwoTone: React.lazy(() => import('@mui/icons-material/NoFlashTwoTone')),
    NoFood: React.lazy(() => import('@mui/icons-material/NoFood')),
    NoFoodOutlined: React.lazy(() => import('@mui/icons-material/NoFoodOutlined')),
    NoFoodRounded: React.lazy(() => import('@mui/icons-material/NoFoodRounded')),
    NoFoodSharp: React.lazy(() => import('@mui/icons-material/NoFoodSharp')),
    NoFoodTwoTone: React.lazy(() => import('@mui/icons-material/NoFoodTwoTone')),
    NoiseAware: React.lazy(() => import('@mui/icons-material/NoiseAware')),
    NoiseAwareOutlined: React.lazy(() => import('@mui/icons-material/NoiseAwareOutlined')),
    NoiseAwareRounded: React.lazy(() => import('@mui/icons-material/NoiseAwareRounded')),
    NoiseAwareSharp: React.lazy(() => import('@mui/icons-material/NoiseAwareSharp')),
    NoiseAwareTwoTone: React.lazy(() => import('@mui/icons-material/NoiseAwareTwoTone')),
    NoiseControlOff: React.lazy(() => import('@mui/icons-material/NoiseControlOff')),
    NoiseControlOffOutlined: React.lazy(() => import('@mui/icons-material/NoiseControlOffOutlined')),
    NoiseControlOffRounded: React.lazy(() => import('@mui/icons-material/NoiseControlOffRounded')),
    NoiseControlOffSharp: React.lazy(() => import('@mui/icons-material/NoiseControlOffSharp')),
    NoiseControlOffTwoTone: React.lazy(() => import('@mui/icons-material/NoiseControlOffTwoTone')),
    NoLuggage: React.lazy(() => import('@mui/icons-material/NoLuggage')),
    NoLuggageOutlined: React.lazy(() => import('@mui/icons-material/NoLuggageOutlined')),
    NoLuggageRounded: React.lazy(() => import('@mui/icons-material/NoLuggageRounded')),
    NoLuggageSharp: React.lazy(() => import('@mui/icons-material/NoLuggageSharp')),
    NoLuggageTwoTone: React.lazy(() => import('@mui/icons-material/NoLuggageTwoTone')),
    NoMeals: React.lazy(() => import('@mui/icons-material/NoMeals')),
    NoMealsOutlined: React.lazy(() => import('@mui/icons-material/NoMealsOutlined')),
    NoMealsRounded: React.lazy(() => import('@mui/icons-material/NoMealsRounded')),
    NoMealsSharp: React.lazy(() => import('@mui/icons-material/NoMealsSharp')),
    NoMealsTwoTone: React.lazy(() => import('@mui/icons-material/NoMealsTwoTone')),
    NoMeetingRoom: React.lazy(() => import('@mui/icons-material/NoMeetingRoom')),
    NoMeetingRoomOutlined: React.lazy(() => import('@mui/icons-material/NoMeetingRoomOutlined')),
    NoMeetingRoomRounded: React.lazy(() => import('@mui/icons-material/NoMeetingRoomRounded')),
    NoMeetingRoomSharp: React.lazy(() => import('@mui/icons-material/NoMeetingRoomSharp')),
    NoMeetingRoomTwoTone: React.lazy(() => import('@mui/icons-material/NoMeetingRoomTwoTone')),
    NoPhotography: React.lazy(() => import('@mui/icons-material/NoPhotography')),
    NoPhotographyOutlined: React.lazy(() => import('@mui/icons-material/NoPhotographyOutlined')),
    NoPhotographyRounded: React.lazy(() => import('@mui/icons-material/NoPhotographyRounded')),
    NoPhotographySharp: React.lazy(() => import('@mui/icons-material/NoPhotographySharp')),
    NoPhotographyTwoTone: React.lazy(() => import('@mui/icons-material/NoPhotographyTwoTone')),
    NordicWalking: React.lazy(() => import('@mui/icons-material/NordicWalking')),
    NordicWalkingOutlined: React.lazy(() => import('@mui/icons-material/NordicWalkingOutlined')),
    NordicWalkingRounded: React.lazy(() => import('@mui/icons-material/NordicWalkingRounded')),
    NordicWalkingSharp: React.lazy(() => import('@mui/icons-material/NordicWalkingSharp')),
    NordicWalkingTwoTone: React.lazy(() => import('@mui/icons-material/NordicWalkingTwoTone')),
    North: React.lazy(() => import('@mui/icons-material/North')),
    NorthEast: React.lazy(() => import('@mui/icons-material/NorthEast')),
    NorthEastOutlined: React.lazy(() => import('@mui/icons-material/NorthEastOutlined')),
    NorthEastRounded: React.lazy(() => import('@mui/icons-material/NorthEastRounded')),
    NorthEastSharp: React.lazy(() => import('@mui/icons-material/NorthEastSharp')),
    NorthEastTwoTone: React.lazy(() => import('@mui/icons-material/NorthEastTwoTone')),
    NorthOutlined: React.lazy(() => import('@mui/icons-material/NorthOutlined')),
    NorthRounded: React.lazy(() => import('@mui/icons-material/NorthRounded')),
    NorthSharp: React.lazy(() => import('@mui/icons-material/NorthSharp')),
    NorthTwoTone: React.lazy(() => import('@mui/icons-material/NorthTwoTone')),
    NorthWest: React.lazy(() => import('@mui/icons-material/NorthWest')),
    NorthWestOutlined: React.lazy(() => import('@mui/icons-material/NorthWestOutlined')),
    NorthWestRounded: React.lazy(() => import('@mui/icons-material/NorthWestRounded')),
    NorthWestSharp: React.lazy(() => import('@mui/icons-material/NorthWestSharp')),
    NorthWestTwoTone: React.lazy(() => import('@mui/icons-material/NorthWestTwoTone')),
    NoSim: React.lazy(() => import('@mui/icons-material/NoSim')),
    NoSimOutlined: React.lazy(() => import('@mui/icons-material/NoSimOutlined')),
    NoSimRounded: React.lazy(() => import('@mui/icons-material/NoSimRounded')),
    NoSimSharp: React.lazy(() => import('@mui/icons-material/NoSimSharp')),
    NoSimTwoTone: React.lazy(() => import('@mui/icons-material/NoSimTwoTone')),
    NoStroller: React.lazy(() => import('@mui/icons-material/NoStroller')),
    NoStrollerOutlined: React.lazy(() => import('@mui/icons-material/NoStrollerOutlined')),
    NoStrollerRounded: React.lazy(() => import('@mui/icons-material/NoStrollerRounded')),
    NoStrollerSharp: React.lazy(() => import('@mui/icons-material/NoStrollerSharp')),
    NoStrollerTwoTone: React.lazy(() => import('@mui/icons-material/NoStrollerTwoTone')),
    NotAccessible: React.lazy(() => import('@mui/icons-material/NotAccessible')),
    NotAccessibleOutlined: React.lazy(() => import('@mui/icons-material/NotAccessibleOutlined')),
    NotAccessibleRounded: React.lazy(() => import('@mui/icons-material/NotAccessibleRounded')),
    NotAccessibleSharp: React.lazy(() => import('@mui/icons-material/NotAccessibleSharp')),
    NotAccessibleTwoTone: React.lazy(() => import('@mui/icons-material/NotAccessibleTwoTone')),
    NoteAdd: React.lazy(() => import('@mui/icons-material/NoteAdd')),
    NoteAddOutlined: React.lazy(() => import('@mui/icons-material/NoteAddOutlined')),
    NoteAddRounded: React.lazy(() => import('@mui/icons-material/NoteAddRounded')),
    NoteAddSharp: React.lazy(() => import('@mui/icons-material/NoteAddSharp')),
    NoteAddTwoTone: React.lazy(() => import('@mui/icons-material/NoteAddTwoTone')),
    NoteAlt: React.lazy(() => import('@mui/icons-material/NoteAlt')),
    NoteAltOutlined: React.lazy(() => import('@mui/icons-material/NoteAltOutlined')),
    NoteAltRounded: React.lazy(() => import('@mui/icons-material/NoteAltRounded')),
    NoteAltSharp: React.lazy(() => import('@mui/icons-material/NoteAltSharp')),
    NoteAltTwoTone: React.lazy(() => import('@mui/icons-material/NoteAltTwoTone')),
    Note: React.lazy(() => import('@mui/icons-material/Note')),
    NoteOutlined: React.lazy(() => import('@mui/icons-material/NoteOutlined')),
    NoteRounded: React.lazy(() => import('@mui/icons-material/NoteRounded')),
    Notes: React.lazy(() => import('@mui/icons-material/Notes')),
    NoteSharp: React.lazy(() => import('@mui/icons-material/NoteSharp')),
    NotesOutlined: React.lazy(() => import('@mui/icons-material/NotesOutlined')),
    NotesRounded: React.lazy(() => import('@mui/icons-material/NotesRounded')),
    NotesSharp: React.lazy(() => import('@mui/icons-material/NotesSharp')),
    NotesTwoTone: React.lazy(() => import('@mui/icons-material/NotesTwoTone')),
    NoteTwoTone: React.lazy(() => import('@mui/icons-material/NoteTwoTone')),
    NotificationAdd: React.lazy(() => import('@mui/icons-material/NotificationAdd')),
    NotificationAddOutlined: React.lazy(() => import('@mui/icons-material/NotificationAddOutlined')),
    NotificationAddRounded: React.lazy(() => import('@mui/icons-material/NotificationAddRounded')),
    NotificationAddSharp: React.lazy(() => import('@mui/icons-material/NotificationAddSharp')),
    NotificationAddTwoTone: React.lazy(() => import('@mui/icons-material/NotificationAddTwoTone')),
    NotificationImportant: React.lazy(() => import('@mui/icons-material/NotificationImportant')),
    NotificationImportantOutlined: React.lazy(() => import('@mui/icons-material/NotificationImportantOutlined')),
    NotificationImportantRounded: React.lazy(() => import('@mui/icons-material/NotificationImportantRounded')),
    NotificationImportantSharp: React.lazy(() => import('@mui/icons-material/NotificationImportantSharp')),
    NotificationImportantTwoTone: React.lazy(() => import('@mui/icons-material/NotificationImportantTwoTone')),
    NotificationsActive: React.lazy(() => import('@mui/icons-material/NotificationsActive')),
    NotificationsActiveOutlined: React.lazy(() => import('@mui/icons-material/NotificationsActiveOutlined')),
    NotificationsActiveRounded: React.lazy(() => import('@mui/icons-material/NotificationsActiveRounded')),
    NotificationsActiveSharp: React.lazy(() => import('@mui/icons-material/NotificationsActiveSharp')),
    NotificationsActiveTwoTone: React.lazy(() => import('@mui/icons-material/NotificationsActiveTwoTone')),
    Notifications: React.lazy(() => import('@mui/icons-material/Notifications')),
    NotificationsNone: React.lazy(() => import('@mui/icons-material/NotificationsNone')),
    NotificationsNoneOutlined: React.lazy(() => import('@mui/icons-material/NotificationsNoneOutlined')),
    NotificationsNoneRounded: React.lazy(() => import('@mui/icons-material/NotificationsNoneRounded')),
    NotificationsNoneSharp: React.lazy(() => import('@mui/icons-material/NotificationsNoneSharp')),
    NotificationsNoneTwoTone: React.lazy(() => import('@mui/icons-material/NotificationsNoneTwoTone')),
    NotificationsOff: React.lazy(() => import('@mui/icons-material/NotificationsOff')),
    NotificationsOffOutlined: React.lazy(() => import('@mui/icons-material/NotificationsOffOutlined')),
    NotificationsOffRounded: React.lazy(() => import('@mui/icons-material/NotificationsOffRounded')),
    NotificationsOffSharp: React.lazy(() => import('@mui/icons-material/NotificationsOffSharp')),
    NotificationsOffTwoTone: React.lazy(() => import('@mui/icons-material/NotificationsOffTwoTone')),
    NotificationsOutlined: React.lazy(() => import('@mui/icons-material/NotificationsOutlined')),
    NotificationsPaused: React.lazy(() => import('@mui/icons-material/NotificationsPaused')),
    NotificationsPausedOutlined: React.lazy(() => import('@mui/icons-material/NotificationsPausedOutlined')),
    NotificationsPausedRounded: React.lazy(() => import('@mui/icons-material/NotificationsPausedRounded')),
    NotificationsPausedSharp: React.lazy(() => import('@mui/icons-material/NotificationsPausedSharp')),
    NotificationsPausedTwoTone: React.lazy(() => import('@mui/icons-material/NotificationsPausedTwoTone')),
    NotificationsRounded: React.lazy(() => import('@mui/icons-material/NotificationsRounded')),
    NotificationsSharp: React.lazy(() => import('@mui/icons-material/NotificationsSharp')),
    NotificationsTwoTone: React.lazy(() => import('@mui/icons-material/NotificationsTwoTone')),
    NotInterested: React.lazy(() => import('@mui/icons-material/NotInterested')),
    NotInterestedOutlined: React.lazy(() => import('@mui/icons-material/NotInterestedOutlined')),
    NotInterestedRounded: React.lazy(() => import('@mui/icons-material/NotInterestedRounded')),
    NotInterestedSharp: React.lazy(() => import('@mui/icons-material/NotInterestedSharp')),
    NotInterestedTwoTone: React.lazy(() => import('@mui/icons-material/NotInterestedTwoTone')),
    NotListedLocation: React.lazy(() => import('@mui/icons-material/NotListedLocation')),
    NotListedLocationOutlined: React.lazy(() => import('@mui/icons-material/NotListedLocationOutlined')),
    NotListedLocationRounded: React.lazy(() => import('@mui/icons-material/NotListedLocationRounded')),
    NotListedLocationSharp: React.lazy(() => import('@mui/icons-material/NotListedLocationSharp')),
    NotListedLocationTwoTone: React.lazy(() => import('@mui/icons-material/NotListedLocationTwoTone')),
    NoTransfer: React.lazy(() => import('@mui/icons-material/NoTransfer')),
    NoTransferOutlined: React.lazy(() => import('@mui/icons-material/NoTransferOutlined')),
    NoTransferRounded: React.lazy(() => import('@mui/icons-material/NoTransferRounded')),
    NoTransferSharp: React.lazy(() => import('@mui/icons-material/NoTransferSharp')),
    NoTransferTwoTone: React.lazy(() => import('@mui/icons-material/NoTransferTwoTone')),
    NotStarted: React.lazy(() => import('@mui/icons-material/NotStarted')),
    NotStartedOutlined: React.lazy(() => import('@mui/icons-material/NotStartedOutlined')),
    NotStartedRounded: React.lazy(() => import('@mui/icons-material/NotStartedRounded')),
    NotStartedSharp: React.lazy(() => import('@mui/icons-material/NotStartedSharp')),
    NotStartedTwoTone: React.lazy(() => import('@mui/icons-material/NotStartedTwoTone')),
    Numbers: React.lazy(() => import('@mui/icons-material/Numbers')),
    NumbersOutlined: React.lazy(() => import('@mui/icons-material/NumbersOutlined')),
    NumbersRounded: React.lazy(() => import('@mui/icons-material/NumbersRounded')),
    NumbersSharp: React.lazy(() => import('@mui/icons-material/NumbersSharp')),
    NumbersTwoTone: React.lazy(() => import('@mui/icons-material/NumbersTwoTone')),
    OfflineBolt: React.lazy(() => import('@mui/icons-material/OfflineBolt')),
    OfflineBoltOutlined: React.lazy(() => import('@mui/icons-material/OfflineBoltOutlined')),
    OfflineBoltRounded: React.lazy(() => import('@mui/icons-material/OfflineBoltRounded')),
    OfflineBoltSharp: React.lazy(() => import('@mui/icons-material/OfflineBoltSharp')),
    OfflineBoltTwoTone: React.lazy(() => import('@mui/icons-material/OfflineBoltTwoTone')),
    OfflinePin: React.lazy(() => import('@mui/icons-material/OfflinePin')),
    OfflinePinOutlined: React.lazy(() => import('@mui/icons-material/OfflinePinOutlined')),
    OfflinePinRounded: React.lazy(() => import('@mui/icons-material/OfflinePinRounded')),
    OfflinePinSharp: React.lazy(() => import('@mui/icons-material/OfflinePinSharp')),
    OfflinePinTwoTone: React.lazy(() => import('@mui/icons-material/OfflinePinTwoTone')),
    OfflineShare: React.lazy(() => import('@mui/icons-material/OfflineShare')),
    OfflineShareOutlined: React.lazy(() => import('@mui/icons-material/OfflineShareOutlined')),
    OfflineShareRounded: React.lazy(() => import('@mui/icons-material/OfflineShareRounded')),
    OfflineShareSharp: React.lazy(() => import('@mui/icons-material/OfflineShareSharp')),
    OfflineShareTwoTone: React.lazy(() => import('@mui/icons-material/OfflineShareTwoTone')),
    OilBarrel: React.lazy(() => import('@mui/icons-material/OilBarrel')),
    OilBarrelOutlined: React.lazy(() => import('@mui/icons-material/OilBarrelOutlined')),
    OilBarrelRounded: React.lazy(() => import('@mui/icons-material/OilBarrelRounded')),
    OilBarrelSharp: React.lazy(() => import('@mui/icons-material/OilBarrelSharp')),
    OilBarrelTwoTone: React.lazy(() => import('@mui/icons-material/OilBarrelTwoTone')),
    OndemandVideo: React.lazy(() => import('@mui/icons-material/OndemandVideo')),
    OndemandVideoOutlined: React.lazy(() => import('@mui/icons-material/OndemandVideoOutlined')),
    OndemandVideoRounded: React.lazy(() => import('@mui/icons-material/OndemandVideoRounded')),
    OndemandVideoSharp: React.lazy(() => import('@mui/icons-material/OndemandVideoSharp')),
    OndemandVideoTwoTone: React.lazy(() => import('@mui/icons-material/OndemandVideoTwoTone')),
    OnDeviceTraining: React.lazy(() => import('@mui/icons-material/OnDeviceTraining')),
    OnDeviceTrainingOutlined: React.lazy(() => import('@mui/icons-material/OnDeviceTrainingOutlined')),
    OnDeviceTrainingRounded: React.lazy(() => import('@mui/icons-material/OnDeviceTrainingRounded')),
    OnDeviceTrainingSharp: React.lazy(() => import('@mui/icons-material/OnDeviceTrainingSharp')),
    OnDeviceTrainingTwoTone: React.lazy(() => import('@mui/icons-material/OnDeviceTrainingTwoTone')),
    OneK: React.lazy(() => import('@mui/icons-material/OneK')),
    OneKk: React.lazy(() => import('@mui/icons-material/OneKk')),
    OneKkOutlined: React.lazy(() => import('@mui/icons-material/OneKkOutlined')),
    OneKkRounded: React.lazy(() => import('@mui/icons-material/OneKkRounded')),
    OneKkSharp: React.lazy(() => import('@mui/icons-material/OneKkSharp')),
    OneKkTwoTone: React.lazy(() => import('@mui/icons-material/OneKkTwoTone')),
    OneKOutlined: React.lazy(() => import('@mui/icons-material/OneKOutlined')),
    OneKPlus: React.lazy(() => import('@mui/icons-material/OneKPlus')),
    OneKPlusOutlined: React.lazy(() => import('@mui/icons-material/OneKPlusOutlined')),
    OneKPlusRounded: React.lazy(() => import('@mui/icons-material/OneKPlusRounded')),
    OneKPlusSharp: React.lazy(() => import('@mui/icons-material/OneKPlusSharp')),
    OneKPlusTwoTone: React.lazy(() => import('@mui/icons-material/OneKPlusTwoTone')),
    OneKRounded: React.lazy(() => import('@mui/icons-material/OneKRounded')),
    OneKSharp: React.lazy(() => import('@mui/icons-material/OneKSharp')),
    OneKTwoTone: React.lazy(() => import('@mui/icons-material/OneKTwoTone')),
    OnlinePrediction: React.lazy(() => import('@mui/icons-material/OnlinePrediction')),
    OnlinePredictionOutlined: React.lazy(() => import('@mui/icons-material/OnlinePredictionOutlined')),
    OnlinePredictionRounded: React.lazy(() => import('@mui/icons-material/OnlinePredictionRounded')),
    OnlinePredictionSharp: React.lazy(() => import('@mui/icons-material/OnlinePredictionSharp')),
    OnlinePredictionTwoTone: React.lazy(() => import('@mui/icons-material/OnlinePredictionTwoTone')),
    Opacity: React.lazy(() => import('@mui/icons-material/Opacity')),
    OpacityOutlined: React.lazy(() => import('@mui/icons-material/OpacityOutlined')),
    OpacityRounded: React.lazy(() => import('@mui/icons-material/OpacityRounded')),
    OpacitySharp: React.lazy(() => import('@mui/icons-material/OpacitySharp')),
    OpacityTwoTone: React.lazy(() => import('@mui/icons-material/OpacityTwoTone')),
    OpenInBrowser: React.lazy(() => import('@mui/icons-material/OpenInBrowser')),
    OpenInBrowserOutlined: React.lazy(() => import('@mui/icons-material/OpenInBrowserOutlined')),
    OpenInBrowserRounded: React.lazy(() => import('@mui/icons-material/OpenInBrowserRounded')),
    OpenInBrowserSharp: React.lazy(() => import('@mui/icons-material/OpenInBrowserSharp')),
    OpenInBrowserTwoTone: React.lazy(() => import('@mui/icons-material/OpenInBrowserTwoTone')),
    OpenInFull: React.lazy(() => import('@mui/icons-material/OpenInFull')),
    OpenInFullOutlined: React.lazy(() => import('@mui/icons-material/OpenInFullOutlined')),
    OpenInFullRounded: React.lazy(() => import('@mui/icons-material/OpenInFullRounded')),
    OpenInFullSharp: React.lazy(() => import('@mui/icons-material/OpenInFullSharp')),
    OpenInFullTwoTone: React.lazy(() => import('@mui/icons-material/OpenInFullTwoTone')),
    OpenInNew: React.lazy(() => import('@mui/icons-material/OpenInNew')),
    OpenInNewOff: React.lazy(() => import('@mui/icons-material/OpenInNewOff')),
    OpenInNewOffOutlined: React.lazy(() => import('@mui/icons-material/OpenInNewOffOutlined')),
    OpenInNewOffRounded: React.lazy(() => import('@mui/icons-material/OpenInNewOffRounded')),
    OpenInNewOffSharp: React.lazy(() => import('@mui/icons-material/OpenInNewOffSharp')),
    OpenInNewOffTwoTone: React.lazy(() => import('@mui/icons-material/OpenInNewOffTwoTone')),
    OpenInNewOutlined: React.lazy(() => import('@mui/icons-material/OpenInNewOutlined')),
    OpenInNewRounded: React.lazy(() => import('@mui/icons-material/OpenInNewRounded')),
    OpenInNewSharp: React.lazy(() => import('@mui/icons-material/OpenInNewSharp')),
    OpenInNewTwoTone: React.lazy(() => import('@mui/icons-material/OpenInNewTwoTone')),
    OpenWith: React.lazy(() => import('@mui/icons-material/OpenWith')),
    OpenWithOutlined: React.lazy(() => import('@mui/icons-material/OpenWithOutlined')),
    OpenWithRounded: React.lazy(() => import('@mui/icons-material/OpenWithRounded')),
    OpenWithSharp: React.lazy(() => import('@mui/icons-material/OpenWithSharp')),
    OpenWithTwoTone: React.lazy(() => import('@mui/icons-material/OpenWithTwoTone')),
    OtherHouses: React.lazy(() => import('@mui/icons-material/OtherHouses')),
    OtherHousesOutlined: React.lazy(() => import('@mui/icons-material/OtherHousesOutlined')),
    OtherHousesRounded: React.lazy(() => import('@mui/icons-material/OtherHousesRounded')),
    OtherHousesSharp: React.lazy(() => import('@mui/icons-material/OtherHousesSharp')),
    OtherHousesTwoTone: React.lazy(() => import('@mui/icons-material/OtherHousesTwoTone')),
    Outbound: React.lazy(() => import('@mui/icons-material/Outbound')),
    OutboundOutlined: React.lazy(() => import('@mui/icons-material/OutboundOutlined')),
    OutboundRounded: React.lazy(() => import('@mui/icons-material/OutboundRounded')),
    OutboundSharp: React.lazy(() => import('@mui/icons-material/OutboundSharp')),
    OutboundTwoTone: React.lazy(() => import('@mui/icons-material/OutboundTwoTone')),
    Outbox: React.lazy(() => import('@mui/icons-material/Outbox')),
    OutboxOutlined: React.lazy(() => import('@mui/icons-material/OutboxOutlined')),
    OutboxRounded: React.lazy(() => import('@mui/icons-material/OutboxRounded')),
    OutboxSharp: React.lazy(() => import('@mui/icons-material/OutboxSharp')),
    OutboxTwoTone: React.lazy(() => import('@mui/icons-material/OutboxTwoTone')),
    OutdoorGrill: React.lazy(() => import('@mui/icons-material/OutdoorGrill')),
    OutdoorGrillOutlined: React.lazy(() => import('@mui/icons-material/OutdoorGrillOutlined')),
    OutdoorGrillRounded: React.lazy(() => import('@mui/icons-material/OutdoorGrillRounded')),
    OutdoorGrillSharp: React.lazy(() => import('@mui/icons-material/OutdoorGrillSharp')),
    OutdoorGrillTwoTone: React.lazy(() => import('@mui/icons-material/OutdoorGrillTwoTone')),
    Outlet: React.lazy(() => import('@mui/icons-material/Outlet')),
    OutletOutlined: React.lazy(() => import('@mui/icons-material/OutletOutlined')),
    OutletRounded: React.lazy(() => import('@mui/icons-material/OutletRounded')),
    OutletSharp: React.lazy(() => import('@mui/icons-material/OutletSharp')),
    OutletTwoTone: React.lazy(() => import('@mui/icons-material/OutletTwoTone')),
    OutlinedFlag: React.lazy(() => import('@mui/icons-material/OutlinedFlag')),
    OutlinedFlagOutlined: React.lazy(() => import('@mui/icons-material/OutlinedFlagOutlined')),
    OutlinedFlagRounded: React.lazy(() => import('@mui/icons-material/OutlinedFlagRounded')),
    OutlinedFlagSharp: React.lazy(() => import('@mui/icons-material/OutlinedFlagSharp')),
    OutlinedFlagTwoTone: React.lazy(() => import('@mui/icons-material/OutlinedFlagTwoTone')),
    Output: React.lazy(() => import('@mui/icons-material/Output')),
    OutputOutlined: React.lazy(() => import('@mui/icons-material/OutputOutlined')),
    OutputRounded: React.lazy(() => import('@mui/icons-material/OutputRounded')),
    OutputSharp: React.lazy(() => import('@mui/icons-material/OutputSharp')),
    OutputTwoTone: React.lazy(() => import('@mui/icons-material/OutputTwoTone')),
    Padding: React.lazy(() => import('@mui/icons-material/Padding')),
    PaddingOutlined: React.lazy(() => import('@mui/icons-material/PaddingOutlined')),
    PaddingRounded: React.lazy(() => import('@mui/icons-material/PaddingRounded')),
    PaddingSharp: React.lazy(() => import('@mui/icons-material/PaddingSharp')),
    PaddingTwoTone: React.lazy(() => import('@mui/icons-material/PaddingTwoTone')),
    Pages: React.lazy(() => import('@mui/icons-material/Pages')),
    PagesOutlined: React.lazy(() => import('@mui/icons-material/PagesOutlined')),
    PagesRounded: React.lazy(() => import('@mui/icons-material/PagesRounded')),
    PagesSharp: React.lazy(() => import('@mui/icons-material/PagesSharp')),
    PagesTwoTone: React.lazy(() => import('@mui/icons-material/PagesTwoTone')),
    Pageview: React.lazy(() => import('@mui/icons-material/Pageview')),
    PageviewOutlined: React.lazy(() => import('@mui/icons-material/PageviewOutlined')),
    PageviewRounded: React.lazy(() => import('@mui/icons-material/PageviewRounded')),
    PageviewSharp: React.lazy(() => import('@mui/icons-material/PageviewSharp')),
    PageviewTwoTone: React.lazy(() => import('@mui/icons-material/PageviewTwoTone')),
    Paid: React.lazy(() => import('@mui/icons-material/Paid')),
    PaidOutlined: React.lazy(() => import('@mui/icons-material/PaidOutlined')),
    PaidRounded: React.lazy(() => import('@mui/icons-material/PaidRounded')),
    PaidSharp: React.lazy(() => import('@mui/icons-material/PaidSharp')),
    PaidTwoTone: React.lazy(() => import('@mui/icons-material/PaidTwoTone')),
    Palette: React.lazy(() => import('@mui/icons-material/Palette')),
    PaletteOutlined: React.lazy(() => import('@mui/icons-material/PaletteOutlined')),
    PaletteRounded: React.lazy(() => import('@mui/icons-material/PaletteRounded')),
    PaletteSharp: React.lazy(() => import('@mui/icons-material/PaletteSharp')),
    PaletteTwoTone: React.lazy(() => import('@mui/icons-material/PaletteTwoTone')),
    Panorama: React.lazy(() => import('@mui/icons-material/Panorama')),
    PanoramaFishEye: React.lazy(() => import('@mui/icons-material/PanoramaFishEye')),
    PanoramaFishEyeOutlined: React.lazy(() => import('@mui/icons-material/PanoramaFishEyeOutlined')),
    PanoramaFishEyeRounded: React.lazy(() => import('@mui/icons-material/PanoramaFishEyeRounded')),
    PanoramaFishEyeSharp: React.lazy(() => import('@mui/icons-material/PanoramaFishEyeSharp')),
    PanoramaFishEyeTwoTone: React.lazy(() => import('@mui/icons-material/PanoramaFishEyeTwoTone')),
    PanoramaHorizontal: React.lazy(() => import('@mui/icons-material/PanoramaHorizontal')),
    PanoramaHorizontalOutlined: React.lazy(() => import('@mui/icons-material/PanoramaHorizontalOutlined')),
    PanoramaHorizontalRounded: React.lazy(() => import('@mui/icons-material/PanoramaHorizontalRounded')),
    PanoramaHorizontalSelect: React.lazy(() => import('@mui/icons-material/PanoramaHorizontalSelect')),
    PanoramaHorizontalSelectOutlined: React.lazy(() => import('@mui/icons-material/PanoramaHorizontalSelectOutlined')),
    PanoramaHorizontalSelectRounded: React.lazy(() => import('@mui/icons-material/PanoramaHorizontalSelectRounded')),
    PanoramaHorizontalSelectSharp: React.lazy(() => import('@mui/icons-material/PanoramaHorizontalSelectSharp')),
    PanoramaHorizontalSelectTwoTone: React.lazy(() => import('@mui/icons-material/PanoramaHorizontalSelectTwoTone')),
    PanoramaHorizontalSharp: React.lazy(() => import('@mui/icons-material/PanoramaHorizontalSharp')),
    PanoramaHorizontalTwoTone: React.lazy(() => import('@mui/icons-material/PanoramaHorizontalTwoTone')),
    PanoramaOutlined: React.lazy(() => import('@mui/icons-material/PanoramaOutlined')),
    PanoramaPhotosphere: React.lazy(() => import('@mui/icons-material/PanoramaPhotosphere')),
    PanoramaPhotosphereOutlined: React.lazy(() => import('@mui/icons-material/PanoramaPhotosphereOutlined')),
    PanoramaPhotosphereRounded: React.lazy(() => import('@mui/icons-material/PanoramaPhotosphereRounded')),
    PanoramaPhotosphereSelect: React.lazy(() => import('@mui/icons-material/PanoramaPhotosphereSelect')),
    PanoramaPhotosphereSelectOutlined: React.lazy(() => import('@mui/icons-material/PanoramaPhotosphereSelectOutlined')),
    PanoramaPhotosphereSelectRounded: React.lazy(() => import('@mui/icons-material/PanoramaPhotosphereSelectRounded')),
    PanoramaPhotosphereSelectSharp: React.lazy(() => import('@mui/icons-material/PanoramaPhotosphereSelectSharp')),
    PanoramaPhotosphereSelectTwoTone: React.lazy(() => import('@mui/icons-material/PanoramaPhotosphereSelectTwoTone')),
    PanoramaPhotosphereSharp: React.lazy(() => import('@mui/icons-material/PanoramaPhotosphereSharp')),
    PanoramaPhotosphereTwoTone: React.lazy(() => import('@mui/icons-material/PanoramaPhotosphereTwoTone')),
    PanoramaRounded: React.lazy(() => import('@mui/icons-material/PanoramaRounded')),
    PanoramaSharp: React.lazy(() => import('@mui/icons-material/PanoramaSharp')),
    PanoramaTwoTone: React.lazy(() => import('@mui/icons-material/PanoramaTwoTone')),
    PanoramaVertical: React.lazy(() => import('@mui/icons-material/PanoramaVertical')),
    PanoramaVerticalOutlined: React.lazy(() => import('@mui/icons-material/PanoramaVerticalOutlined')),
    PanoramaVerticalRounded: React.lazy(() => import('@mui/icons-material/PanoramaVerticalRounded')),
    PanoramaVerticalSelect: React.lazy(() => import('@mui/icons-material/PanoramaVerticalSelect')),
    PanoramaVerticalSelectOutlined: React.lazy(() => import('@mui/icons-material/PanoramaVerticalSelectOutlined')),
    PanoramaVerticalSelectRounded: React.lazy(() => import('@mui/icons-material/PanoramaVerticalSelectRounded')),
    PanoramaVerticalSelectSharp: React.lazy(() => import('@mui/icons-material/PanoramaVerticalSelectSharp')),
    PanoramaVerticalSelectTwoTone: React.lazy(() => import('@mui/icons-material/PanoramaVerticalSelectTwoTone')),
    PanoramaVerticalSharp: React.lazy(() => import('@mui/icons-material/PanoramaVerticalSharp')),
    PanoramaVerticalTwoTone: React.lazy(() => import('@mui/icons-material/PanoramaVerticalTwoTone')),
    PanoramaWideAngle: React.lazy(() => import('@mui/icons-material/PanoramaWideAngle')),
    PanoramaWideAngleOutlined: React.lazy(() => import('@mui/icons-material/PanoramaWideAngleOutlined')),
    PanoramaWideAngleRounded: React.lazy(() => import('@mui/icons-material/PanoramaWideAngleRounded')),
    PanoramaWideAngleSelect: React.lazy(() => import('@mui/icons-material/PanoramaWideAngleSelect')),
    PanoramaWideAngleSelectOutlined: React.lazy(() => import('@mui/icons-material/PanoramaWideAngleSelectOutlined')),
    PanoramaWideAngleSelectRounded: React.lazy(() => import('@mui/icons-material/PanoramaWideAngleSelectRounded')),
    PanoramaWideAngleSelectSharp: React.lazy(() => import('@mui/icons-material/PanoramaWideAngleSelectSharp')),
    PanoramaWideAngleSelectTwoTone: React.lazy(() => import('@mui/icons-material/PanoramaWideAngleSelectTwoTone')),
    PanoramaWideAngleSharp: React.lazy(() => import('@mui/icons-material/PanoramaWideAngleSharp')),
    PanoramaWideAngleTwoTone: React.lazy(() => import('@mui/icons-material/PanoramaWideAngleTwoTone')),
    PanToolAlt: React.lazy(() => import('@mui/icons-material/PanToolAlt')),
    PanToolAltOutlined: React.lazy(() => import('@mui/icons-material/PanToolAltOutlined')),
    PanToolAltRounded: React.lazy(() => import('@mui/icons-material/PanToolAltRounded')),
    PanToolAltSharp: React.lazy(() => import('@mui/icons-material/PanToolAltSharp')),
    PanToolAltTwoTone: React.lazy(() => import('@mui/icons-material/PanToolAltTwoTone')),
    PanTool: React.lazy(() => import('@mui/icons-material/PanTool')),
    PanToolOutlined: React.lazy(() => import('@mui/icons-material/PanToolOutlined')),
    PanToolRounded: React.lazy(() => import('@mui/icons-material/PanToolRounded')),
    PanToolSharp: React.lazy(() => import('@mui/icons-material/PanToolSharp')),
    PanToolTwoTone: React.lazy(() => import('@mui/icons-material/PanToolTwoTone')),
    Paragliding: React.lazy(() => import('@mui/icons-material/Paragliding')),
    ParaglidingOutlined: React.lazy(() => import('@mui/icons-material/ParaglidingOutlined')),
    ParaglidingRounded: React.lazy(() => import('@mui/icons-material/ParaglidingRounded')),
    ParaglidingSharp: React.lazy(() => import('@mui/icons-material/ParaglidingSharp')),
    ParaglidingTwoTone: React.lazy(() => import('@mui/icons-material/ParaglidingTwoTone')),
    Park: React.lazy(() => import('@mui/icons-material/Park')),
    ParkOutlined: React.lazy(() => import('@mui/icons-material/ParkOutlined')),
    ParkRounded: React.lazy(() => import('@mui/icons-material/ParkRounded')),
    ParkSharp: React.lazy(() => import('@mui/icons-material/ParkSharp')),
    ParkTwoTone: React.lazy(() => import('@mui/icons-material/ParkTwoTone')),
    PartyMode: React.lazy(() => import('@mui/icons-material/PartyMode')),
    PartyModeOutlined: React.lazy(() => import('@mui/icons-material/PartyModeOutlined')),
    PartyModeRounded: React.lazy(() => import('@mui/icons-material/PartyModeRounded')),
    PartyModeSharp: React.lazy(() => import('@mui/icons-material/PartyModeSharp')),
    PartyModeTwoTone: React.lazy(() => import('@mui/icons-material/PartyModeTwoTone')),
    Password: React.lazy(() => import('@mui/icons-material/Password')),
    PasswordOutlined: React.lazy(() => import('@mui/icons-material/PasswordOutlined')),
    PasswordRounded: React.lazy(() => import('@mui/icons-material/PasswordRounded')),
    PasswordSharp: React.lazy(() => import('@mui/icons-material/PasswordSharp')),
    PasswordTwoTone: React.lazy(() => import('@mui/icons-material/PasswordTwoTone')),
    Pattern: React.lazy(() => import('@mui/icons-material/Pattern')),
    PatternOutlined: React.lazy(() => import('@mui/icons-material/PatternOutlined')),
    PatternRounded: React.lazy(() => import('@mui/icons-material/PatternRounded')),
    PatternSharp: React.lazy(() => import('@mui/icons-material/PatternSharp')),
    PatternTwoTone: React.lazy(() => import('@mui/icons-material/PatternTwoTone')),
    PauseCircle: React.lazy(() => import('@mui/icons-material/PauseCircle')),
    PauseCircleFilled: React.lazy(() => import('@mui/icons-material/PauseCircleFilled')),
    PauseCircleFilledOutlined: React.lazy(() => import('@mui/icons-material/PauseCircleFilledOutlined')),
    PauseCircleFilledRounded: React.lazy(() => import('@mui/icons-material/PauseCircleFilledRounded')),
    PauseCircleFilledSharp: React.lazy(() => import('@mui/icons-material/PauseCircleFilledSharp')),
    PauseCircleFilledTwoTone: React.lazy(() => import('@mui/icons-material/PauseCircleFilledTwoTone')),
    PauseCircleOutlined: React.lazy(() => import('@mui/icons-material/PauseCircleOutlined')),
    PauseCircleOutline: React.lazy(() => import('@mui/icons-material/PauseCircleOutline')),
    PauseCircleOutlineOutlined: React.lazy(() => import('@mui/icons-material/PauseCircleOutlineOutlined')),
    PauseCircleOutlineRounded: React.lazy(() => import('@mui/icons-material/PauseCircleOutlineRounded')),
    PauseCircleOutlineSharp: React.lazy(() => import('@mui/icons-material/PauseCircleOutlineSharp')),
    PauseCircleOutlineTwoTone: React.lazy(() => import('@mui/icons-material/PauseCircleOutlineTwoTone')),
    PauseCircleRounded: React.lazy(() => import('@mui/icons-material/PauseCircleRounded')),
    PauseCircleSharp: React.lazy(() => import('@mui/icons-material/PauseCircleSharp')),
    PauseCircleTwoTone: React.lazy(() => import('@mui/icons-material/PauseCircleTwoTone')),
    Pause: React.lazy(() => import('@mui/icons-material/Pause')),
    PauseOutlined: React.lazy(() => import('@mui/icons-material/PauseOutlined')),
    PausePresentation: React.lazy(() => import('@mui/icons-material/PausePresentation')),
    PausePresentationOutlined: React.lazy(() => import('@mui/icons-material/PausePresentationOutlined')),
    PausePresentationRounded: React.lazy(() => import('@mui/icons-material/PausePresentationRounded')),
    PausePresentationSharp: React.lazy(() => import('@mui/icons-material/PausePresentationSharp')),
    PausePresentationTwoTone: React.lazy(() => import('@mui/icons-material/PausePresentationTwoTone')),
    PauseRounded: React.lazy(() => import('@mui/icons-material/PauseRounded')),
    PauseSharp: React.lazy(() => import('@mui/icons-material/PauseSharp')),
    PauseTwoTone: React.lazy(() => import('@mui/icons-material/PauseTwoTone')),
    Payment: React.lazy(() => import('@mui/icons-material/Payment')),
    PaymentOutlined: React.lazy(() => import('@mui/icons-material/PaymentOutlined')),
    PaymentRounded: React.lazy(() => import('@mui/icons-material/PaymentRounded')),
    Payments: React.lazy(() => import('@mui/icons-material/Payments')),
    PaymentSharp: React.lazy(() => import('@mui/icons-material/PaymentSharp')),
    PaymentsOutlined: React.lazy(() => import('@mui/icons-material/PaymentsOutlined')),
    PaymentsRounded: React.lazy(() => import('@mui/icons-material/PaymentsRounded')),
    PaymentsSharp: React.lazy(() => import('@mui/icons-material/PaymentsSharp')),
    PaymentsTwoTone: React.lazy(() => import('@mui/icons-material/PaymentsTwoTone')),
    PaymentTwoTone: React.lazy(() => import('@mui/icons-material/PaymentTwoTone')),
    PedalBike: React.lazy(() => import('@mui/icons-material/PedalBike')),
    PedalBikeOutlined: React.lazy(() => import('@mui/icons-material/PedalBikeOutlined')),
    PedalBikeRounded: React.lazy(() => import('@mui/icons-material/PedalBikeRounded')),
    PedalBikeSharp: React.lazy(() => import('@mui/icons-material/PedalBikeSharp')),
    PedalBikeTwoTone: React.lazy(() => import('@mui/icons-material/PedalBikeTwoTone')),
    PendingActions: React.lazy(() => import('@mui/icons-material/PendingActions')),
    PendingActionsOutlined: React.lazy(() => import('@mui/icons-material/PendingActionsOutlined')),
    PendingActionsRounded: React.lazy(() => import('@mui/icons-material/PendingActionsRounded')),
    PendingActionsSharp: React.lazy(() => import('@mui/icons-material/PendingActionsSharp')),
    PendingActionsTwoTone: React.lazy(() => import('@mui/icons-material/PendingActionsTwoTone')),
    Pending: React.lazy(() => import('@mui/icons-material/Pending')),
    PendingOutlined: React.lazy(() => import('@mui/icons-material/PendingOutlined')),
    PendingRounded: React.lazy(() => import('@mui/icons-material/PendingRounded')),
    PendingSharp: React.lazy(() => import('@mui/icons-material/PendingSharp')),
    PendingTwoTone: React.lazy(() => import('@mui/icons-material/PendingTwoTone')),
    Pentagon: React.lazy(() => import('@mui/icons-material/Pentagon')),
    PentagonOutlined: React.lazy(() => import('@mui/icons-material/PentagonOutlined')),
    PentagonRounded: React.lazy(() => import('@mui/icons-material/PentagonRounded')),
    PentagonSharp: React.lazy(() => import('@mui/icons-material/PentagonSharp')),
    PentagonTwoTone: React.lazy(() => import('@mui/icons-material/PentagonTwoTone')),
    PeopleAlt: React.lazy(() => import('@mui/icons-material/PeopleAlt')),
    PeopleAltOutlined: React.lazy(() => import('@mui/icons-material/PeopleAltOutlined')),
    PeopleAltRounded: React.lazy(() => import('@mui/icons-material/PeopleAltRounded')),
    PeopleAltSharp: React.lazy(() => import('@mui/icons-material/PeopleAltSharp')),
    PeopleAltTwoTone: React.lazy(() => import('@mui/icons-material/PeopleAltTwoTone')),
    People: React.lazy(() => import('@mui/icons-material/People')),
    PeopleOutlined: React.lazy(() => import('@mui/icons-material/PeopleOutlined')),
    PeopleOutline: React.lazy(() => import('@mui/icons-material/PeopleOutline')),
    PeopleOutlineOutlined: React.lazy(() => import('@mui/icons-material/PeopleOutlineOutlined')),
    PeopleOutlineRounded: React.lazy(() => import('@mui/icons-material/PeopleOutlineRounded')),
    PeopleOutlineSharp: React.lazy(() => import('@mui/icons-material/PeopleOutlineSharp')),
    PeopleOutlineTwoTone: React.lazy(() => import('@mui/icons-material/PeopleOutlineTwoTone')),
    PeopleRounded: React.lazy(() => import('@mui/icons-material/PeopleRounded')),
    PeopleSharp: React.lazy(() => import('@mui/icons-material/PeopleSharp')),
    PeopleTwoTone: React.lazy(() => import('@mui/icons-material/PeopleTwoTone')),
    Percent: React.lazy(() => import('@mui/icons-material/Percent')),
    PercentOutlined: React.lazy(() => import('@mui/icons-material/PercentOutlined')),
    PercentRounded: React.lazy(() => import('@mui/icons-material/PercentRounded')),
    PercentSharp: React.lazy(() => import('@mui/icons-material/PercentSharp')),
    PercentTwoTone: React.lazy(() => import('@mui/icons-material/PercentTwoTone')),
    PermCameraMic: React.lazy(() => import('@mui/icons-material/PermCameraMic')),
    PermCameraMicOutlined: React.lazy(() => import('@mui/icons-material/PermCameraMicOutlined')),
    PermCameraMicRounded: React.lazy(() => import('@mui/icons-material/PermCameraMicRounded')),
    PermCameraMicSharp: React.lazy(() => import('@mui/icons-material/PermCameraMicSharp')),
    PermCameraMicTwoTone: React.lazy(() => import('@mui/icons-material/PermCameraMicTwoTone')),
    PermContactCalendar: React.lazy(() => import('@mui/icons-material/PermContactCalendar')),
    PermContactCalendarOutlined: React.lazy(() => import('@mui/icons-material/PermContactCalendarOutlined')),
    PermContactCalendarRounded: React.lazy(() => import('@mui/icons-material/PermContactCalendarRounded')),
    PermContactCalendarSharp: React.lazy(() => import('@mui/icons-material/PermContactCalendarSharp')),
    PermContactCalendarTwoTone: React.lazy(() => import('@mui/icons-material/PermContactCalendarTwoTone')),
    PermDataSetting: React.lazy(() => import('@mui/icons-material/PermDataSetting')),
    PermDataSettingOutlined: React.lazy(() => import('@mui/icons-material/PermDataSettingOutlined')),
    PermDataSettingRounded: React.lazy(() => import('@mui/icons-material/PermDataSettingRounded')),
    PermDataSettingSharp: React.lazy(() => import('@mui/icons-material/PermDataSettingSharp')),
    PermDataSettingTwoTone: React.lazy(() => import('@mui/icons-material/PermDataSettingTwoTone')),
    PermDeviceInformation: React.lazy(() => import('@mui/icons-material/PermDeviceInformation')),
    PermDeviceInformationOutlined: React.lazy(() => import('@mui/icons-material/PermDeviceInformationOutlined')),
    PermDeviceInformationRounded: React.lazy(() => import('@mui/icons-material/PermDeviceInformationRounded')),
    PermDeviceInformationSharp: React.lazy(() => import('@mui/icons-material/PermDeviceInformationSharp')),
    PermDeviceInformationTwoTone: React.lazy(() => import('@mui/icons-material/PermDeviceInformationTwoTone')),
    PermIdentity: React.lazy(() => import('@mui/icons-material/PermIdentity')),
    PermIdentityOutlined: React.lazy(() => import('@mui/icons-material/PermIdentityOutlined')),
    PermIdentityRounded: React.lazy(() => import('@mui/icons-material/PermIdentityRounded')),
    PermIdentitySharp: React.lazy(() => import('@mui/icons-material/PermIdentitySharp')),
    PermIdentityTwoTone: React.lazy(() => import('@mui/icons-material/PermIdentityTwoTone')),
    PermMedia: React.lazy(() => import('@mui/icons-material/PermMedia')),
    PermMediaOutlined: React.lazy(() => import('@mui/icons-material/PermMediaOutlined')),
    PermMediaRounded: React.lazy(() => import('@mui/icons-material/PermMediaRounded')),
    PermMediaSharp: React.lazy(() => import('@mui/icons-material/PermMediaSharp')),
    PermMediaTwoTone: React.lazy(() => import('@mui/icons-material/PermMediaTwoTone')),
    PermPhoneMsg: React.lazy(() => import('@mui/icons-material/PermPhoneMsg')),
    PermPhoneMsgOutlined: React.lazy(() => import('@mui/icons-material/PermPhoneMsgOutlined')),
    PermPhoneMsgRounded: React.lazy(() => import('@mui/icons-material/PermPhoneMsgRounded')),
    PermPhoneMsgSharp: React.lazy(() => import('@mui/icons-material/PermPhoneMsgSharp')),
    PermPhoneMsgTwoTone: React.lazy(() => import('@mui/icons-material/PermPhoneMsgTwoTone')),
    PermScanWifi: React.lazy(() => import('@mui/icons-material/PermScanWifi')),
    PermScanWifiOutlined: React.lazy(() => import('@mui/icons-material/PermScanWifiOutlined')),
    PermScanWifiRounded: React.lazy(() => import('@mui/icons-material/PermScanWifiRounded')),
    PermScanWifiSharp: React.lazy(() => import('@mui/icons-material/PermScanWifiSharp')),
    PermScanWifiTwoTone: React.lazy(() => import('@mui/icons-material/PermScanWifiTwoTone')),
    Person2: React.lazy(() => import('@mui/icons-material/Person2')),
    Person2Outlined: React.lazy(() => import('@mui/icons-material/Person2Outlined')),
    Person2Rounded: React.lazy(() => import('@mui/icons-material/Person2Rounded')),
    Person2Sharp: React.lazy(() => import('@mui/icons-material/Person2Sharp')),
    Person2TwoTone: React.lazy(() => import('@mui/icons-material/Person2TwoTone')),
    Person3: React.lazy(() => import('@mui/icons-material/Person3')),
    Person3Outlined: React.lazy(() => import('@mui/icons-material/Person3Outlined')),
    Person3Rounded: React.lazy(() => import('@mui/icons-material/Person3Rounded')),
    Person3Sharp: React.lazy(() => import('@mui/icons-material/Person3Sharp')),
    Person3TwoTone: React.lazy(() => import('@mui/icons-material/Person3TwoTone')),
    Person4: React.lazy(() => import('@mui/icons-material/Person4')),
    Person4Outlined: React.lazy(() => import('@mui/icons-material/Person4Outlined')),
    Person4Rounded: React.lazy(() => import('@mui/icons-material/Person4Rounded')),
    Person4Sharp: React.lazy(() => import('@mui/icons-material/Person4Sharp')),
    Person4TwoTone: React.lazy(() => import('@mui/icons-material/Person4TwoTone')),
    PersonAddAlt1: React.lazy(() => import('@mui/icons-material/PersonAddAlt1')),
    PersonAddAlt1Outlined: React.lazy(() => import('@mui/icons-material/PersonAddAlt1Outlined')),
    PersonAddAlt1Rounded: React.lazy(() => import('@mui/icons-material/PersonAddAlt1Rounded')),
    PersonAddAlt1Sharp: React.lazy(() => import('@mui/icons-material/PersonAddAlt1Sharp')),
    PersonAddAlt1TwoTone: React.lazy(() => import('@mui/icons-material/PersonAddAlt1TwoTone')),
    PersonAddAlt: React.lazy(() => import('@mui/icons-material/PersonAddAlt')),
    PersonAddAltOutlined: React.lazy(() => import('@mui/icons-material/PersonAddAltOutlined')),
    PersonAddAltRounded: React.lazy(() => import('@mui/icons-material/PersonAddAltRounded')),
    PersonAddAltSharp: React.lazy(() => import('@mui/icons-material/PersonAddAltSharp')),
    PersonAddAltTwoTone: React.lazy(() => import('@mui/icons-material/PersonAddAltTwoTone')),
    PersonAddDisabled: React.lazy(() => import('@mui/icons-material/PersonAddDisabled')),
    PersonAddDisabledOutlined: React.lazy(() => import('@mui/icons-material/PersonAddDisabledOutlined')),
    PersonAddDisabledRounded: React.lazy(() => import('@mui/icons-material/PersonAddDisabledRounded')),
    PersonAddDisabledSharp: React.lazy(() => import('@mui/icons-material/PersonAddDisabledSharp')),
    PersonAddDisabledTwoTone: React.lazy(() => import('@mui/icons-material/PersonAddDisabledTwoTone')),
    PersonAdd: React.lazy(() => import('@mui/icons-material/PersonAdd')),
    PersonAddOutlined: React.lazy(() => import('@mui/icons-material/PersonAddOutlined')),
    PersonAddRounded: React.lazy(() => import('@mui/icons-material/PersonAddRounded')),
    PersonAddSharp: React.lazy(() => import('@mui/icons-material/PersonAddSharp')),
    PersonAddTwoTone: React.lazy(() => import('@mui/icons-material/PersonAddTwoTone')),
    PersonalVideo: React.lazy(() => import('@mui/icons-material/PersonalVideo')),
    PersonalVideoOutlined: React.lazy(() => import('@mui/icons-material/PersonalVideoOutlined')),
    PersonalVideoRounded: React.lazy(() => import('@mui/icons-material/PersonalVideoRounded')),
    PersonalVideoSharp: React.lazy(() => import('@mui/icons-material/PersonalVideoSharp')),
    PersonalVideoTwoTone: React.lazy(() => import('@mui/icons-material/PersonalVideoTwoTone')),
    Person: React.lazy(() => import('@mui/icons-material/Person')),
    PersonOff: React.lazy(() => import('@mui/icons-material/PersonOff')),
    PersonOffOutlined: React.lazy(() => import('@mui/icons-material/PersonOffOutlined')),
    PersonOffRounded: React.lazy(() => import('@mui/icons-material/PersonOffRounded')),
    PersonOffSharp: React.lazy(() => import('@mui/icons-material/PersonOffSharp')),
    PersonOffTwoTone: React.lazy(() => import('@mui/icons-material/PersonOffTwoTone')),
    PersonOutlined: React.lazy(() => import('@mui/icons-material/PersonOutlined')),
    PersonOutline: React.lazy(() => import('@mui/icons-material/PersonOutline')),
    PersonOutlineOutlined: React.lazy(() => import('@mui/icons-material/PersonOutlineOutlined')),
    PersonOutlineRounded: React.lazy(() => import('@mui/icons-material/PersonOutlineRounded')),
    PersonOutlineSharp: React.lazy(() => import('@mui/icons-material/PersonOutlineSharp')),
    PersonOutlineTwoTone: React.lazy(() => import('@mui/icons-material/PersonOutlineTwoTone')),
    PersonPinCircle: React.lazy(() => import('@mui/icons-material/PersonPinCircle')),
    PersonPinCircleOutlined: React.lazy(() => import('@mui/icons-material/PersonPinCircleOutlined')),
    PersonPinCircleRounded: React.lazy(() => import('@mui/icons-material/PersonPinCircleRounded')),
    PersonPinCircleSharp: React.lazy(() => import('@mui/icons-material/PersonPinCircleSharp')),
    PersonPinCircleTwoTone: React.lazy(() => import('@mui/icons-material/PersonPinCircleTwoTone')),
    PersonPin: React.lazy(() => import('@mui/icons-material/PersonPin')),
    PersonPinOutlined: React.lazy(() => import('@mui/icons-material/PersonPinOutlined')),
    PersonPinRounded: React.lazy(() => import('@mui/icons-material/PersonPinRounded')),
    PersonPinSharp: React.lazy(() => import('@mui/icons-material/PersonPinSharp')),
    PersonPinTwoTone: React.lazy(() => import('@mui/icons-material/PersonPinTwoTone')),
    PersonRemoveAlt1: React.lazy(() => import('@mui/icons-material/PersonRemoveAlt1')),
    PersonRemoveAlt1Outlined: React.lazy(() => import('@mui/icons-material/PersonRemoveAlt1Outlined')),
    PersonRemoveAlt1Rounded: React.lazy(() => import('@mui/icons-material/PersonRemoveAlt1Rounded')),
    PersonRemoveAlt1Sharp: React.lazy(() => import('@mui/icons-material/PersonRemoveAlt1Sharp')),
    PersonRemoveAlt1TwoTone: React.lazy(() => import('@mui/icons-material/PersonRemoveAlt1TwoTone')),
    PersonRemove: React.lazy(() => import('@mui/icons-material/PersonRemove')),
    PersonRemoveOutlined: React.lazy(() => import('@mui/icons-material/PersonRemoveOutlined')),
    PersonRemoveRounded: React.lazy(() => import('@mui/icons-material/PersonRemoveRounded')),
    PersonRemoveSharp: React.lazy(() => import('@mui/icons-material/PersonRemoveSharp')),
    PersonRemoveTwoTone: React.lazy(() => import('@mui/icons-material/PersonRemoveTwoTone')),
    PersonRounded: React.lazy(() => import('@mui/icons-material/PersonRounded')),
    PersonSearch: React.lazy(() => import('@mui/icons-material/PersonSearch')),
    PersonSearchOutlined: React.lazy(() => import('@mui/icons-material/PersonSearchOutlined')),
    PersonSearchRounded: React.lazy(() => import('@mui/icons-material/PersonSearchRounded')),
    PersonSearchSharp: React.lazy(() => import('@mui/icons-material/PersonSearchSharp')),
    PersonSearchTwoTone: React.lazy(() => import('@mui/icons-material/PersonSearchTwoTone')),
    PersonSharp: React.lazy(() => import('@mui/icons-material/PersonSharp')),
    PersonTwoTone: React.lazy(() => import('@mui/icons-material/PersonTwoTone')),
    PestControl: React.lazy(() => import('@mui/icons-material/PestControl')),
    PestControlOutlined: React.lazy(() => import('@mui/icons-material/PestControlOutlined')),
    PestControlRodent: React.lazy(() => import('@mui/icons-material/PestControlRodent')),
    PestControlRodentOutlined: React.lazy(() => import('@mui/icons-material/PestControlRodentOutlined')),
    PestControlRodentRounded: React.lazy(() => import('@mui/icons-material/PestControlRodentRounded')),
    PestControlRodentSharp: React.lazy(() => import('@mui/icons-material/PestControlRodentSharp')),
    PestControlRodentTwoTone: React.lazy(() => import('@mui/icons-material/PestControlRodentTwoTone')),
    PestControlRounded: React.lazy(() => import('@mui/icons-material/PestControlRounded')),
    PestControlSharp: React.lazy(() => import('@mui/icons-material/PestControlSharp')),
    PestControlTwoTone: React.lazy(() => import('@mui/icons-material/PestControlTwoTone')),
    Pets: React.lazy(() => import('@mui/icons-material/Pets')),
    PetsOutlined: React.lazy(() => import('@mui/icons-material/PetsOutlined')),
    PetsRounded: React.lazy(() => import('@mui/icons-material/PetsRounded')),
    PetsSharp: React.lazy(() => import('@mui/icons-material/PetsSharp')),
    PetsTwoTone: React.lazy(() => import('@mui/icons-material/PetsTwoTone')),
    Phishing: React.lazy(() => import('@mui/icons-material/Phishing')),
    PhishingOutlined: React.lazy(() => import('@mui/icons-material/PhishingOutlined')),
    PhishingRounded: React.lazy(() => import('@mui/icons-material/PhishingRounded')),
    PhishingSharp: React.lazy(() => import('@mui/icons-material/PhishingSharp')),
    PhishingTwoTone: React.lazy(() => import('@mui/icons-material/PhishingTwoTone')),
    PhoneAndroid: React.lazy(() => import('@mui/icons-material/PhoneAndroid')),
    PhoneAndroidOutlined: React.lazy(() => import('@mui/icons-material/PhoneAndroidOutlined')),
    PhoneAndroidRounded: React.lazy(() => import('@mui/icons-material/PhoneAndroidRounded')),
    PhoneAndroidSharp: React.lazy(() => import('@mui/icons-material/PhoneAndroidSharp')),
    PhoneAndroidTwoTone: React.lazy(() => import('@mui/icons-material/PhoneAndroidTwoTone')),
    PhoneBluetoothSpeaker: React.lazy(() => import('@mui/icons-material/PhoneBluetoothSpeaker')),
    PhoneBluetoothSpeakerOutlined: React.lazy(() => import('@mui/icons-material/PhoneBluetoothSpeakerOutlined')),
    PhoneBluetoothSpeakerRounded: React.lazy(() => import('@mui/icons-material/PhoneBluetoothSpeakerRounded')),
    PhoneBluetoothSpeakerSharp: React.lazy(() => import('@mui/icons-material/PhoneBluetoothSpeakerSharp')),
    PhoneBluetoothSpeakerTwoTone: React.lazy(() => import('@mui/icons-material/PhoneBluetoothSpeakerTwoTone')),
    PhoneCallback: React.lazy(() => import('@mui/icons-material/PhoneCallback')),
    PhoneCallbackOutlined: React.lazy(() => import('@mui/icons-material/PhoneCallbackOutlined')),
    PhoneCallbackRounded: React.lazy(() => import('@mui/icons-material/PhoneCallbackRounded')),
    PhoneCallbackSharp: React.lazy(() => import('@mui/icons-material/PhoneCallbackSharp')),
    PhoneCallbackTwoTone: React.lazy(() => import('@mui/icons-material/PhoneCallbackTwoTone')),
    PhoneDisabled: React.lazy(() => import('@mui/icons-material/PhoneDisabled')),
    PhoneDisabledOutlined: React.lazy(() => import('@mui/icons-material/PhoneDisabledOutlined')),
    PhoneDisabledRounded: React.lazy(() => import('@mui/icons-material/PhoneDisabledRounded')),
    PhoneDisabledSharp: React.lazy(() => import('@mui/icons-material/PhoneDisabledSharp')),
    PhoneDisabledTwoTone: React.lazy(() => import('@mui/icons-material/PhoneDisabledTwoTone')),
    Phone: React.lazy(() => import('@mui/icons-material/Phone')),
    PhoneEnabled: React.lazy(() => import('@mui/icons-material/PhoneEnabled')),
    PhoneEnabledOutlined: React.lazy(() => import('@mui/icons-material/PhoneEnabledOutlined')),
    PhoneEnabledRounded: React.lazy(() => import('@mui/icons-material/PhoneEnabledRounded')),
    PhoneEnabledSharp: React.lazy(() => import('@mui/icons-material/PhoneEnabledSharp')),
    PhoneEnabledTwoTone: React.lazy(() => import('@mui/icons-material/PhoneEnabledTwoTone')),
    PhoneForwarded: React.lazy(() => import('@mui/icons-material/PhoneForwarded')),
    PhoneForwardedOutlined: React.lazy(() => import('@mui/icons-material/PhoneForwardedOutlined')),
    PhoneForwardedRounded: React.lazy(() => import('@mui/icons-material/PhoneForwardedRounded')),
    PhoneForwardedSharp: React.lazy(() => import('@mui/icons-material/PhoneForwardedSharp')),
    PhoneForwardedTwoTone: React.lazy(() => import('@mui/icons-material/PhoneForwardedTwoTone')),
    PhoneInTalk: React.lazy(() => import('@mui/icons-material/PhoneInTalk')),
    PhoneInTalkOutlined: React.lazy(() => import('@mui/icons-material/PhoneInTalkOutlined')),
    PhoneInTalkRounded: React.lazy(() => import('@mui/icons-material/PhoneInTalkRounded')),
    PhoneInTalkSharp: React.lazy(() => import('@mui/icons-material/PhoneInTalkSharp')),
    PhoneInTalkTwoTone: React.lazy(() => import('@mui/icons-material/PhoneInTalkTwoTone')),
    PhoneIphone: React.lazy(() => import('@mui/icons-material/PhoneIphone')),
    PhoneIphoneOutlined: React.lazy(() => import('@mui/icons-material/PhoneIphoneOutlined')),
    PhoneIphoneRounded: React.lazy(() => import('@mui/icons-material/PhoneIphoneRounded')),
    PhoneIphoneSharp: React.lazy(() => import('@mui/icons-material/PhoneIphoneSharp')),
    PhoneIphoneTwoTone: React.lazy(() => import('@mui/icons-material/PhoneIphoneTwoTone')),
    Phonelink: React.lazy(() => import('@mui/icons-material/Phonelink')),
    PhonelinkErase: React.lazy(() => import('@mui/icons-material/PhonelinkErase')),
    PhonelinkEraseOutlined: React.lazy(() => import('@mui/icons-material/PhonelinkEraseOutlined')),
    PhonelinkEraseRounded: React.lazy(() => import('@mui/icons-material/PhonelinkEraseRounded')),
    PhonelinkEraseSharp: React.lazy(() => import('@mui/icons-material/PhonelinkEraseSharp')),
    PhonelinkEraseTwoTone: React.lazy(() => import('@mui/icons-material/PhonelinkEraseTwoTone')),
    PhonelinkLock: React.lazy(() => import('@mui/icons-material/PhonelinkLock')),
    PhonelinkLockOutlined: React.lazy(() => import('@mui/icons-material/PhonelinkLockOutlined')),
    PhonelinkLockRounded: React.lazy(() => import('@mui/icons-material/PhonelinkLockRounded')),
    PhonelinkLockSharp: React.lazy(() => import('@mui/icons-material/PhonelinkLockSharp')),
    PhonelinkLockTwoTone: React.lazy(() => import('@mui/icons-material/PhonelinkLockTwoTone')),
    PhonelinkOff: React.lazy(() => import('@mui/icons-material/PhonelinkOff')),
    PhonelinkOffOutlined: React.lazy(() => import('@mui/icons-material/PhonelinkOffOutlined')),
    PhonelinkOffRounded: React.lazy(() => import('@mui/icons-material/PhonelinkOffRounded')),
    PhonelinkOffSharp: React.lazy(() => import('@mui/icons-material/PhonelinkOffSharp')),
    PhonelinkOffTwoTone: React.lazy(() => import('@mui/icons-material/PhonelinkOffTwoTone')),
    PhonelinkOutlined: React.lazy(() => import('@mui/icons-material/PhonelinkOutlined')),
    PhonelinkRing: React.lazy(() => import('@mui/icons-material/PhonelinkRing')),
    PhonelinkRingOutlined: React.lazy(() => import('@mui/icons-material/PhonelinkRingOutlined')),
    PhonelinkRingRounded: React.lazy(() => import('@mui/icons-material/PhonelinkRingRounded')),
    PhonelinkRingSharp: React.lazy(() => import('@mui/icons-material/PhonelinkRingSharp')),
    PhonelinkRingTwoTone: React.lazy(() => import('@mui/icons-material/PhonelinkRingTwoTone')),
    PhonelinkRounded: React.lazy(() => import('@mui/icons-material/PhonelinkRounded')),
    PhonelinkSetup: React.lazy(() => import('@mui/icons-material/PhonelinkSetup')),
    PhonelinkSetupOutlined: React.lazy(() => import('@mui/icons-material/PhonelinkSetupOutlined')),
    PhonelinkSetupRounded: React.lazy(() => import('@mui/icons-material/PhonelinkSetupRounded')),
    PhonelinkSetupSharp: React.lazy(() => import('@mui/icons-material/PhonelinkSetupSharp')),
    PhonelinkSetupTwoTone: React.lazy(() => import('@mui/icons-material/PhonelinkSetupTwoTone')),
    PhonelinkSharp: React.lazy(() => import('@mui/icons-material/PhonelinkSharp')),
    PhonelinkTwoTone: React.lazy(() => import('@mui/icons-material/PhonelinkTwoTone')),
    PhoneLocked: React.lazy(() => import('@mui/icons-material/PhoneLocked')),
    PhoneLockedOutlined: React.lazy(() => import('@mui/icons-material/PhoneLockedOutlined')),
    PhoneLockedRounded: React.lazy(() => import('@mui/icons-material/PhoneLockedRounded')),
    PhoneLockedSharp: React.lazy(() => import('@mui/icons-material/PhoneLockedSharp')),
    PhoneLockedTwoTone: React.lazy(() => import('@mui/icons-material/PhoneLockedTwoTone')),
    PhoneMissed: React.lazy(() => import('@mui/icons-material/PhoneMissed')),
    PhoneMissedOutlined: React.lazy(() => import('@mui/icons-material/PhoneMissedOutlined')),
    PhoneMissedRounded: React.lazy(() => import('@mui/icons-material/PhoneMissedRounded')),
    PhoneMissedSharp: React.lazy(() => import('@mui/icons-material/PhoneMissedSharp')),
    PhoneMissedTwoTone: React.lazy(() => import('@mui/icons-material/PhoneMissedTwoTone')),
    PhoneOutlined: React.lazy(() => import('@mui/icons-material/PhoneOutlined')),
    PhonePaused: React.lazy(() => import('@mui/icons-material/PhonePaused')),
    PhonePausedOutlined: React.lazy(() => import('@mui/icons-material/PhonePausedOutlined')),
    PhonePausedRounded: React.lazy(() => import('@mui/icons-material/PhonePausedRounded')),
    PhonePausedSharp: React.lazy(() => import('@mui/icons-material/PhonePausedSharp')),
    PhonePausedTwoTone: React.lazy(() => import('@mui/icons-material/PhonePausedTwoTone')),
    PhoneRounded: React.lazy(() => import('@mui/icons-material/PhoneRounded')),
    PhoneSharp: React.lazy(() => import('@mui/icons-material/PhoneSharp')),
    PhoneTwoTone: React.lazy(() => import('@mui/icons-material/PhoneTwoTone')),
    PhotoAlbum: React.lazy(() => import('@mui/icons-material/PhotoAlbum')),
    PhotoAlbumOutlined: React.lazy(() => import('@mui/icons-material/PhotoAlbumOutlined')),
    PhotoAlbumRounded: React.lazy(() => import('@mui/icons-material/PhotoAlbumRounded')),
    PhotoAlbumSharp: React.lazy(() => import('@mui/icons-material/PhotoAlbumSharp')),
    PhotoAlbumTwoTone: React.lazy(() => import('@mui/icons-material/PhotoAlbumTwoTone')),
    PhotoCameraBack: React.lazy(() => import('@mui/icons-material/PhotoCameraBack')),
    PhotoCameraBackOutlined: React.lazy(() => import('@mui/icons-material/PhotoCameraBackOutlined')),
    PhotoCameraBackRounded: React.lazy(() => import('@mui/icons-material/PhotoCameraBackRounded')),
    PhotoCameraBackSharp: React.lazy(() => import('@mui/icons-material/PhotoCameraBackSharp')),
    PhotoCameraBackTwoTone: React.lazy(() => import('@mui/icons-material/PhotoCameraBackTwoTone')),
    PhotoCamera: React.lazy(() => import('@mui/icons-material/PhotoCamera')),
    PhotoCameraFront: React.lazy(() => import('@mui/icons-material/PhotoCameraFront')),
    PhotoCameraFrontOutlined: React.lazy(() => import('@mui/icons-material/PhotoCameraFrontOutlined')),
    PhotoCameraFrontRounded: React.lazy(() => import('@mui/icons-material/PhotoCameraFrontRounded')),
    PhotoCameraFrontSharp: React.lazy(() => import('@mui/icons-material/PhotoCameraFrontSharp')),
    PhotoCameraFrontTwoTone: React.lazy(() => import('@mui/icons-material/PhotoCameraFrontTwoTone')),
    PhotoCameraOutlined: React.lazy(() => import('@mui/icons-material/PhotoCameraOutlined')),
    PhotoCameraRounded: React.lazy(() => import('@mui/icons-material/PhotoCameraRounded')),
    PhotoCameraSharp: React.lazy(() => import('@mui/icons-material/PhotoCameraSharp')),
    PhotoCameraTwoTone: React.lazy(() => import('@mui/icons-material/PhotoCameraTwoTone')),
    Photo: React.lazy(() => import('@mui/icons-material/Photo')),
    PhotoFilter: React.lazy(() => import('@mui/icons-material/PhotoFilter')),
    PhotoFilterOutlined: React.lazy(() => import('@mui/icons-material/PhotoFilterOutlined')),
    PhotoFilterRounded: React.lazy(() => import('@mui/icons-material/PhotoFilterRounded')),
    PhotoFilterSharp: React.lazy(() => import('@mui/icons-material/PhotoFilterSharp')),
    PhotoFilterTwoTone: React.lazy(() => import('@mui/icons-material/PhotoFilterTwoTone')),
    PhotoLibrary: React.lazy(() => import('@mui/icons-material/PhotoLibrary')),
    PhotoLibraryOutlined: React.lazy(() => import('@mui/icons-material/PhotoLibraryOutlined')),
    PhotoLibraryRounded: React.lazy(() => import('@mui/icons-material/PhotoLibraryRounded')),
    PhotoLibrarySharp: React.lazy(() => import('@mui/icons-material/PhotoLibrarySharp')),
    PhotoLibraryTwoTone: React.lazy(() => import('@mui/icons-material/PhotoLibraryTwoTone')),
    PhotoOutlined: React.lazy(() => import('@mui/icons-material/PhotoOutlined')),
    PhotoRounded: React.lazy(() => import('@mui/icons-material/PhotoRounded')),
    PhotoSharp: React.lazy(() => import('@mui/icons-material/PhotoSharp')),
    PhotoSizeSelectActual: React.lazy(() => import('@mui/icons-material/PhotoSizeSelectActual')),
    PhotoSizeSelectActualOutlined: React.lazy(() => import('@mui/icons-material/PhotoSizeSelectActualOutlined')),
    PhotoSizeSelectActualRounded: React.lazy(() => import('@mui/icons-material/PhotoSizeSelectActualRounded')),
    PhotoSizeSelectActualSharp: React.lazy(() => import('@mui/icons-material/PhotoSizeSelectActualSharp')),
    PhotoSizeSelectActualTwoTone: React.lazy(() => import('@mui/icons-material/PhotoSizeSelectActualTwoTone')),
    PhotoSizeSelectLarge: React.lazy(() => import('@mui/icons-material/PhotoSizeSelectLarge')),
    PhotoSizeSelectLargeOutlined: React.lazy(() => import('@mui/icons-material/PhotoSizeSelectLargeOutlined')),
    PhotoSizeSelectLargeRounded: React.lazy(() => import('@mui/icons-material/PhotoSizeSelectLargeRounded')),
    PhotoSizeSelectLargeSharp: React.lazy(() => import('@mui/icons-material/PhotoSizeSelectLargeSharp')),
    PhotoSizeSelectLargeTwoTone: React.lazy(() => import('@mui/icons-material/PhotoSizeSelectLargeTwoTone')),
    PhotoSizeSelectSmall: React.lazy(() => import('@mui/icons-material/PhotoSizeSelectSmall')),
    PhotoSizeSelectSmallOutlined: React.lazy(() => import('@mui/icons-material/PhotoSizeSelectSmallOutlined')),
    PhotoSizeSelectSmallRounded: React.lazy(() => import('@mui/icons-material/PhotoSizeSelectSmallRounded')),
    PhotoSizeSelectSmallSharp: React.lazy(() => import('@mui/icons-material/PhotoSizeSelectSmallSharp')),
    PhotoSizeSelectSmallTwoTone: React.lazy(() => import('@mui/icons-material/PhotoSizeSelectSmallTwoTone')),
    PhotoTwoTone: React.lazy(() => import('@mui/icons-material/PhotoTwoTone')),
    Php: React.lazy(() => import('@mui/icons-material/Php')),
    PhpOutlined: React.lazy(() => import('@mui/icons-material/PhpOutlined')),
    PhpRounded: React.lazy(() => import('@mui/icons-material/PhpRounded')),
    PhpSharp: React.lazy(() => import('@mui/icons-material/PhpSharp')),
    PhpTwoTone: React.lazy(() => import('@mui/icons-material/PhpTwoTone')),
    Piano: React.lazy(() => import('@mui/icons-material/Piano')),
    PianoOff: React.lazy(() => import('@mui/icons-material/PianoOff')),
    PianoOffOutlined: React.lazy(() => import('@mui/icons-material/PianoOffOutlined')),
    PianoOffRounded: React.lazy(() => import('@mui/icons-material/PianoOffRounded')),
    PianoOffSharp: React.lazy(() => import('@mui/icons-material/PianoOffSharp')),
    PianoOffTwoTone: React.lazy(() => import('@mui/icons-material/PianoOffTwoTone')),
    PianoOutlined: React.lazy(() => import('@mui/icons-material/PianoOutlined')),
    PianoRounded: React.lazy(() => import('@mui/icons-material/PianoRounded')),
    PianoSharp: React.lazy(() => import('@mui/icons-material/PianoSharp')),
    PianoTwoTone: React.lazy(() => import('@mui/icons-material/PianoTwoTone')),
    PictureAsPdf: React.lazy(() => import('@mui/icons-material/PictureAsPdf')),
    PictureAsPdfOutlined: React.lazy(() => import('@mui/icons-material/PictureAsPdfOutlined')),
    PictureAsPdfRounded: React.lazy(() => import('@mui/icons-material/PictureAsPdfRounded')),
    PictureAsPdfSharp: React.lazy(() => import('@mui/icons-material/PictureAsPdfSharp')),
    PictureAsPdfTwoTone: React.lazy(() => import('@mui/icons-material/PictureAsPdfTwoTone')),
    PictureInPictureAlt: React.lazy(() => import('@mui/icons-material/PictureInPictureAlt')),
    PictureInPictureAltOutlined: React.lazy(() => import('@mui/icons-material/PictureInPictureAltOutlined')),
    PictureInPictureAltRounded: React.lazy(() => import('@mui/icons-material/PictureInPictureAltRounded')),
    PictureInPictureAltSharp: React.lazy(() => import('@mui/icons-material/PictureInPictureAltSharp')),
    PictureInPictureAltTwoTone: React.lazy(() => import('@mui/icons-material/PictureInPictureAltTwoTone')),
    PictureInPicture: React.lazy(() => import('@mui/icons-material/PictureInPicture')),
    PictureInPictureOutlined: React.lazy(() => import('@mui/icons-material/PictureInPictureOutlined')),
    PictureInPictureRounded: React.lazy(() => import('@mui/icons-material/PictureInPictureRounded')),
    PictureInPictureSharp: React.lazy(() => import('@mui/icons-material/PictureInPictureSharp')),
    PictureInPictureTwoTone: React.lazy(() => import('@mui/icons-material/PictureInPictureTwoTone')),
    PieChart: React.lazy(() => import('@mui/icons-material/PieChart')),
    PieChartOutlined: React.lazy(() => import('@mui/icons-material/PieChartOutlined')),
    PieChartOutline: React.lazy(() => import('@mui/icons-material/PieChartOutline')),
    PieChartOutlineOutlined: React.lazy(() => import('@mui/icons-material/PieChartOutlineOutlined')),
    PieChartOutlineRounded: React.lazy(() => import('@mui/icons-material/PieChartOutlineRounded')),
    PieChartOutlineSharp: React.lazy(() => import('@mui/icons-material/PieChartOutlineSharp')),
    PieChartOutlineTwoTone: React.lazy(() => import('@mui/icons-material/PieChartOutlineTwoTone')),
    PieChartRounded: React.lazy(() => import('@mui/icons-material/PieChartRounded')),
    PieChartSharp: React.lazy(() => import('@mui/icons-material/PieChartSharp')),
    PieChartTwoTone: React.lazy(() => import('@mui/icons-material/PieChartTwoTone')),
    Pinch: React.lazy(() => import('@mui/icons-material/Pinch')),
    PinchOutlined: React.lazy(() => import('@mui/icons-material/PinchOutlined')),
    PinchRounded: React.lazy(() => import('@mui/icons-material/PinchRounded')),
    PinchSharp: React.lazy(() => import('@mui/icons-material/PinchSharp')),
    PinchTwoTone: React.lazy(() => import('@mui/icons-material/PinchTwoTone')),
    PinDrop: React.lazy(() => import('@mui/icons-material/PinDrop')),
    PinDropOutlined: React.lazy(() => import('@mui/icons-material/PinDropOutlined')),
    PinDropRounded: React.lazy(() => import('@mui/icons-material/PinDropRounded')),
    PinDropSharp: React.lazy(() => import('@mui/icons-material/PinDropSharp')),
    PinDropTwoTone: React.lazy(() => import('@mui/icons-material/PinDropTwoTone')),
    Pin: React.lazy(() => import('@mui/icons-material/Pin')),
    PinOutlined: React.lazy(() => import('@mui/icons-material/PinOutlined')),
    PinRounded: React.lazy(() => import('@mui/icons-material/PinRounded')),
    PinSharp: React.lazy(() => import('@mui/icons-material/PinSharp')),
    Pinterest: React.lazy(() => import('@mui/icons-material/Pinterest')),
    PinTwoTone: React.lazy(() => import('@mui/icons-material/PinTwoTone')),
    PivotTableChart: React.lazy(() => import('@mui/icons-material/PivotTableChart')),
    PivotTableChartOutlined: React.lazy(() => import('@mui/icons-material/PivotTableChartOutlined')),
    PivotTableChartRounded: React.lazy(() => import('@mui/icons-material/PivotTableChartRounded')),
    PivotTableChartSharp: React.lazy(() => import('@mui/icons-material/PivotTableChartSharp')),
    PivotTableChartTwoTone: React.lazy(() => import('@mui/icons-material/PivotTableChartTwoTone')),
    Pix: React.lazy(() => import('@mui/icons-material/Pix')),
    PixOutlined: React.lazy(() => import('@mui/icons-material/PixOutlined')),
    PixRounded: React.lazy(() => import('@mui/icons-material/PixRounded')),
    PixSharp: React.lazy(() => import('@mui/icons-material/PixSharp')),
    PixTwoTone: React.lazy(() => import('@mui/icons-material/PixTwoTone')),
    Place: React.lazy(() => import('@mui/icons-material/Place')),
    PlaceOutlined: React.lazy(() => import('@mui/icons-material/PlaceOutlined')),
    PlaceRounded: React.lazy(() => import('@mui/icons-material/PlaceRounded')),
    PlaceSharp: React.lazy(() => import('@mui/icons-material/PlaceSharp')),
    PlaceTwoTone: React.lazy(() => import('@mui/icons-material/PlaceTwoTone')),
    Plagiarism: React.lazy(() => import('@mui/icons-material/Plagiarism')),
    PlagiarismOutlined: React.lazy(() => import('@mui/icons-material/PlagiarismOutlined')),
    PlagiarismRounded: React.lazy(() => import('@mui/icons-material/PlagiarismRounded')),
    PlagiarismSharp: React.lazy(() => import('@mui/icons-material/PlagiarismSharp')),
    PlagiarismTwoTone: React.lazy(() => import('@mui/icons-material/PlagiarismTwoTone')),
    PlayArrow: React.lazy(() => import('@mui/icons-material/PlayArrow')),
    PlayArrowOutlined: React.lazy(() => import('@mui/icons-material/PlayArrowOutlined')),
    PlayArrowRounded: React.lazy(() => import('@mui/icons-material/PlayArrowRounded')),
    PlayArrowSharp: React.lazy(() => import('@mui/icons-material/PlayArrowSharp')),
    PlayArrowTwoTone: React.lazy(() => import('@mui/icons-material/PlayArrowTwoTone')),
    PlayCircle: React.lazy(() => import('@mui/icons-material/PlayCircle')),
    PlayCircleFilled: React.lazy(() => import('@mui/icons-material/PlayCircleFilled')),
    PlayCircleFilledOutlined: React.lazy(() => import('@mui/icons-material/PlayCircleFilledOutlined')),
    PlayCircleFilledRounded: React.lazy(() => import('@mui/icons-material/PlayCircleFilledRounded')),
    PlayCircleFilledSharp: React.lazy(() => import('@mui/icons-material/PlayCircleFilledSharp')),
    PlayCircleFilledTwoTone: React.lazy(() => import('@mui/icons-material/PlayCircleFilledTwoTone')),
    PlayCircleFilledWhite: React.lazy(() => import('@mui/icons-material/PlayCircleFilledWhite')),
    PlayCircleFilledWhiteOutlined: React.lazy(() => import('@mui/icons-material/PlayCircleFilledWhiteOutlined')),
    PlayCircleFilledWhiteRounded: React.lazy(() => import('@mui/icons-material/PlayCircleFilledWhiteRounded')),
    PlayCircleFilledWhiteSharp: React.lazy(() => import('@mui/icons-material/PlayCircleFilledWhiteSharp')),
    PlayCircleFilledWhiteTwoTone: React.lazy(() => import('@mui/icons-material/PlayCircleFilledWhiteTwoTone')),
    PlayCircleOutlined: React.lazy(() => import('@mui/icons-material/PlayCircleOutlined')),
    PlayCircleOutline: React.lazy(() => import('@mui/icons-material/PlayCircleOutline')),
    PlayCircleOutlineOutlined: React.lazy(() => import('@mui/icons-material/PlayCircleOutlineOutlined')),
    PlayCircleOutlineRounded: React.lazy(() => import('@mui/icons-material/PlayCircleOutlineRounded')),
    PlayCircleOutlineSharp: React.lazy(() => import('@mui/icons-material/PlayCircleOutlineSharp')),
    PlayCircleOutlineTwoTone: React.lazy(() => import('@mui/icons-material/PlayCircleOutlineTwoTone')),
    PlayCircleRounded: React.lazy(() => import('@mui/icons-material/PlayCircleRounded')),
    PlayCircleSharp: React.lazy(() => import('@mui/icons-material/PlayCircleSharp')),
    PlayCircleTwoTone: React.lazy(() => import('@mui/icons-material/PlayCircleTwoTone')),
    PlayDisabled: React.lazy(() => import('@mui/icons-material/PlayDisabled')),
    PlayDisabledOutlined: React.lazy(() => import('@mui/icons-material/PlayDisabledOutlined')),
    PlayDisabledRounded: React.lazy(() => import('@mui/icons-material/PlayDisabledRounded')),
    PlayDisabledSharp: React.lazy(() => import('@mui/icons-material/PlayDisabledSharp')),
    PlayDisabledTwoTone: React.lazy(() => import('@mui/icons-material/PlayDisabledTwoTone')),
    PlayForWork: React.lazy(() => import('@mui/icons-material/PlayForWork')),
    PlayForWorkOutlined: React.lazy(() => import('@mui/icons-material/PlayForWorkOutlined')),
    PlayForWorkRounded: React.lazy(() => import('@mui/icons-material/PlayForWorkRounded')),
    PlayForWorkSharp: React.lazy(() => import('@mui/icons-material/PlayForWorkSharp')),
    PlayForWorkTwoTone: React.lazy(() => import('@mui/icons-material/PlayForWorkTwoTone')),
    PlayLesson: React.lazy(() => import('@mui/icons-material/PlayLesson')),
    PlayLessonOutlined: React.lazy(() => import('@mui/icons-material/PlayLessonOutlined')),
    PlayLessonRounded: React.lazy(() => import('@mui/icons-material/PlayLessonRounded')),
    PlayLessonSharp: React.lazy(() => import('@mui/icons-material/PlayLessonSharp')),
    PlayLessonTwoTone: React.lazy(() => import('@mui/icons-material/PlayLessonTwoTone')),
    PlaylistAddCheckCircle: React.lazy(() => import('@mui/icons-material/PlaylistAddCheckCircle')),
    PlaylistAddCheckCircleOutlined: React.lazy(() => import('@mui/icons-material/PlaylistAddCheckCircleOutlined')),
    PlaylistAddCheckCircleRounded: React.lazy(() => import('@mui/icons-material/PlaylistAddCheckCircleRounded')),
    PlaylistAddCheckCircleSharp: React.lazy(() => import('@mui/icons-material/PlaylistAddCheckCircleSharp')),
    PlaylistAddCheckCircleTwoTone: React.lazy(() => import('@mui/icons-material/PlaylistAddCheckCircleTwoTone')),
    PlaylistAddCheck: React.lazy(() => import('@mui/icons-material/PlaylistAddCheck')),
    PlaylistAddCheckOutlined: React.lazy(() => import('@mui/icons-material/PlaylistAddCheckOutlined')),
    PlaylistAddCheckRounded: React.lazy(() => import('@mui/icons-material/PlaylistAddCheckRounded')),
    PlaylistAddCheckSharp: React.lazy(() => import('@mui/icons-material/PlaylistAddCheckSharp')),
    PlaylistAddCheckTwoTone: React.lazy(() => import('@mui/icons-material/PlaylistAddCheckTwoTone')),
    PlaylistAddCircle: React.lazy(() => import('@mui/icons-material/PlaylistAddCircle')),
    PlaylistAddCircleOutlined: React.lazy(() => import('@mui/icons-material/PlaylistAddCircleOutlined')),
    PlaylistAddCircleRounded: React.lazy(() => import('@mui/icons-material/PlaylistAddCircleRounded')),
    PlaylistAddCircleSharp: React.lazy(() => import('@mui/icons-material/PlaylistAddCircleSharp')),
    PlaylistAddCircleTwoTone: React.lazy(() => import('@mui/icons-material/PlaylistAddCircleTwoTone')),
    PlaylistAdd: React.lazy(() => import('@mui/icons-material/PlaylistAdd')),
    PlaylistAddOutlined: React.lazy(() => import('@mui/icons-material/PlaylistAddOutlined')),
    PlaylistAddRounded: React.lazy(() => import('@mui/icons-material/PlaylistAddRounded')),
    PlaylistAddSharp: React.lazy(() => import('@mui/icons-material/PlaylistAddSharp')),
    PlaylistAddTwoTone: React.lazy(() => import('@mui/icons-material/PlaylistAddTwoTone')),
    PlaylistPlay: React.lazy(() => import('@mui/icons-material/PlaylistPlay')),
    PlaylistPlayOutlined: React.lazy(() => import('@mui/icons-material/PlaylistPlayOutlined')),
    PlaylistPlayRounded: React.lazy(() => import('@mui/icons-material/PlaylistPlayRounded')),
    PlaylistPlaySharp: React.lazy(() => import('@mui/icons-material/PlaylistPlaySharp')),
    PlaylistPlayTwoTone: React.lazy(() => import('@mui/icons-material/PlaylistPlayTwoTone')),
    PlaylistRemove: React.lazy(() => import('@mui/icons-material/PlaylistRemove')),
    PlaylistRemoveOutlined: React.lazy(() => import('@mui/icons-material/PlaylistRemoveOutlined')),
    PlaylistRemoveRounded: React.lazy(() => import('@mui/icons-material/PlaylistRemoveRounded')),
    PlaylistRemoveSharp: React.lazy(() => import('@mui/icons-material/PlaylistRemoveSharp')),
    PlaylistRemoveTwoTone: React.lazy(() => import('@mui/icons-material/PlaylistRemoveTwoTone')),
    Plumbing: React.lazy(() => import('@mui/icons-material/Plumbing')),
    PlumbingOutlined: React.lazy(() => import('@mui/icons-material/PlumbingOutlined')),
    PlumbingRounded: React.lazy(() => import('@mui/icons-material/PlumbingRounded')),
    PlumbingSharp: React.lazy(() => import('@mui/icons-material/PlumbingSharp')),
    PlumbingTwoTone: React.lazy(() => import('@mui/icons-material/PlumbingTwoTone')),
    PlusOne: React.lazy(() => import('@mui/icons-material/PlusOne')),
    PlusOneOutlined: React.lazy(() => import('@mui/icons-material/PlusOneOutlined')),
    PlusOneRounded: React.lazy(() => import('@mui/icons-material/PlusOneRounded')),
    PlusOneSharp: React.lazy(() => import('@mui/icons-material/PlusOneSharp')),
    PlusOneTwoTone: React.lazy(() => import('@mui/icons-material/PlusOneTwoTone')),
    Podcasts: React.lazy(() => import('@mui/icons-material/Podcasts')),
    PodcastsOutlined: React.lazy(() => import('@mui/icons-material/PodcastsOutlined')),
    PodcastsRounded: React.lazy(() => import('@mui/icons-material/PodcastsRounded')),
    PodcastsSharp: React.lazy(() => import('@mui/icons-material/PodcastsSharp')),
    PodcastsTwoTone: React.lazy(() => import('@mui/icons-material/PodcastsTwoTone')),
    PointOfSale: React.lazy(() => import('@mui/icons-material/PointOfSale')),
    PointOfSaleOutlined: React.lazy(() => import('@mui/icons-material/PointOfSaleOutlined')),
    PointOfSaleRounded: React.lazy(() => import('@mui/icons-material/PointOfSaleRounded')),
    PointOfSaleSharp: React.lazy(() => import('@mui/icons-material/PointOfSaleSharp')),
    PointOfSaleTwoTone: React.lazy(() => import('@mui/icons-material/PointOfSaleTwoTone')),
    Policy: React.lazy(() => import('@mui/icons-material/Policy')),
    PolicyOutlined: React.lazy(() => import('@mui/icons-material/PolicyOutlined')),
    PolicyRounded: React.lazy(() => import('@mui/icons-material/PolicyRounded')),
    PolicySharp: React.lazy(() => import('@mui/icons-material/PolicySharp')),
    PolicyTwoTone: React.lazy(() => import('@mui/icons-material/PolicyTwoTone')),
    Poll: React.lazy(() => import('@mui/icons-material/Poll')),
    PollOutlined: React.lazy(() => import('@mui/icons-material/PollOutlined')),
    PollRounded: React.lazy(() => import('@mui/icons-material/PollRounded')),
    PollSharp: React.lazy(() => import('@mui/icons-material/PollSharp')),
    PollTwoTone: React.lazy(() => import('@mui/icons-material/PollTwoTone')),
    Polyline: React.lazy(() => import('@mui/icons-material/Polyline')),
    PolylineOutlined: React.lazy(() => import('@mui/icons-material/PolylineOutlined')),
    PolylineRounded: React.lazy(() => import('@mui/icons-material/PolylineRounded')),
    PolylineSharp: React.lazy(() => import('@mui/icons-material/PolylineSharp')),
    PolylineTwoTone: React.lazy(() => import('@mui/icons-material/PolylineTwoTone')),
    Pool: React.lazy(() => import('@mui/icons-material/Pool')),
    PoolOutlined: React.lazy(() => import('@mui/icons-material/PoolOutlined')),
    PoolRounded: React.lazy(() => import('@mui/icons-material/PoolRounded')),
    PoolSharp: React.lazy(() => import('@mui/icons-material/PoolSharp')),
    PoolTwoTone: React.lazy(() => import('@mui/icons-material/PoolTwoTone')),
    PortableWifiOff: React.lazy(() => import('@mui/icons-material/PortableWifiOff')),
    PortableWifiOffOutlined: React.lazy(() => import('@mui/icons-material/PortableWifiOffOutlined')),
    PortableWifiOffRounded: React.lazy(() => import('@mui/icons-material/PortableWifiOffRounded')),
    PortableWifiOffSharp: React.lazy(() => import('@mui/icons-material/PortableWifiOffSharp')),
    PortableWifiOffTwoTone: React.lazy(() => import('@mui/icons-material/PortableWifiOffTwoTone')),
    Portrait: React.lazy(() => import('@mui/icons-material/Portrait')),
    PortraitOutlined: React.lazy(() => import('@mui/icons-material/PortraitOutlined')),
    PortraitRounded: React.lazy(() => import('@mui/icons-material/PortraitRounded')),
    PortraitSharp: React.lazy(() => import('@mui/icons-material/PortraitSharp')),
    PortraitTwoTone: React.lazy(() => import('@mui/icons-material/PortraitTwoTone')),
    PostAdd: React.lazy(() => import('@mui/icons-material/PostAdd')),
    PostAddOutlined: React.lazy(() => import('@mui/icons-material/PostAddOutlined')),
    PostAddRounded: React.lazy(() => import('@mui/icons-material/PostAddRounded')),
    PostAddSharp: React.lazy(() => import('@mui/icons-material/PostAddSharp')),
    PostAddTwoTone: React.lazy(() => import('@mui/icons-material/PostAddTwoTone')),
    Power: React.lazy(() => import('@mui/icons-material/Power')),
    PowerInput: React.lazy(() => import('@mui/icons-material/PowerInput')),
    PowerInputOutlined: React.lazy(() => import('@mui/icons-material/PowerInputOutlined')),
    PowerInputRounded: React.lazy(() => import('@mui/icons-material/PowerInputRounded')),
    PowerInputSharp: React.lazy(() => import('@mui/icons-material/PowerInputSharp')),
    PowerInputTwoTone: React.lazy(() => import('@mui/icons-material/PowerInputTwoTone')),
    PowerOff: React.lazy(() => import('@mui/icons-material/PowerOff')),
    PowerOffOutlined: React.lazy(() => import('@mui/icons-material/PowerOffOutlined')),
    PowerOffRounded: React.lazy(() => import('@mui/icons-material/PowerOffRounded')),
    PowerOffSharp: React.lazy(() => import('@mui/icons-material/PowerOffSharp')),
    PowerOffTwoTone: React.lazy(() => import('@mui/icons-material/PowerOffTwoTone')),
    PowerOutlined: React.lazy(() => import('@mui/icons-material/PowerOutlined')),
    PowerRounded: React.lazy(() => import('@mui/icons-material/PowerRounded')),
    PowerSettingsNew: React.lazy(() => import('@mui/icons-material/PowerSettingsNew')),
    PowerSettingsNewOutlined: React.lazy(() => import('@mui/icons-material/PowerSettingsNewOutlined')),
    PowerSettingsNewRounded: React.lazy(() => import('@mui/icons-material/PowerSettingsNewRounded')),
    PowerSettingsNewSharp: React.lazy(() => import('@mui/icons-material/PowerSettingsNewSharp')),
    PowerSettingsNewTwoTone: React.lazy(() => import('@mui/icons-material/PowerSettingsNewTwoTone')),
    PowerSharp: React.lazy(() => import('@mui/icons-material/PowerSharp')),
    PowerTwoTone: React.lazy(() => import('@mui/icons-material/PowerTwoTone')),
    PrecisionManufacturing: React.lazy(() => import('@mui/icons-material/PrecisionManufacturing')),
    PrecisionManufacturingOutlined: React.lazy(() => import('@mui/icons-material/PrecisionManufacturingOutlined')),
    PrecisionManufacturingRounded: React.lazy(() => import('@mui/icons-material/PrecisionManufacturingRounded')),
    PrecisionManufacturingSharp: React.lazy(() => import('@mui/icons-material/PrecisionManufacturingSharp')),
    PrecisionManufacturingTwoTone: React.lazy(() => import('@mui/icons-material/PrecisionManufacturingTwoTone')),
    PregnantWoman: React.lazy(() => import('@mui/icons-material/PregnantWoman')),
    PregnantWomanOutlined: React.lazy(() => import('@mui/icons-material/PregnantWomanOutlined')),
    PregnantWomanRounded: React.lazy(() => import('@mui/icons-material/PregnantWomanRounded')),
    PregnantWomanSharp: React.lazy(() => import('@mui/icons-material/PregnantWomanSharp')),
    PregnantWomanTwoTone: React.lazy(() => import('@mui/icons-material/PregnantWomanTwoTone')),
    PresentToAll: React.lazy(() => import('@mui/icons-material/PresentToAll')),
    PresentToAllOutlined: React.lazy(() => import('@mui/icons-material/PresentToAllOutlined')),
    PresentToAllRounded: React.lazy(() => import('@mui/icons-material/PresentToAllRounded')),
    PresentToAllSharp: React.lazy(() => import('@mui/icons-material/PresentToAllSharp')),
    PresentToAllTwoTone: React.lazy(() => import('@mui/icons-material/PresentToAllTwoTone')),
    Preview: React.lazy(() => import('@mui/icons-material/Preview')),
    PreviewOutlined: React.lazy(() => import('@mui/icons-material/PreviewOutlined')),
    PreviewRounded: React.lazy(() => import('@mui/icons-material/PreviewRounded')),
    PreviewSharp: React.lazy(() => import('@mui/icons-material/PreviewSharp')),
    PreviewTwoTone: React.lazy(() => import('@mui/icons-material/PreviewTwoTone')),
    PriceChange: React.lazy(() => import('@mui/icons-material/PriceChange')),
    PriceChangeOutlined: React.lazy(() => import('@mui/icons-material/PriceChangeOutlined')),
    PriceChangeRounded: React.lazy(() => import('@mui/icons-material/PriceChangeRounded')),
    PriceChangeSharp: React.lazy(() => import('@mui/icons-material/PriceChangeSharp')),
    PriceChangeTwoTone: React.lazy(() => import('@mui/icons-material/PriceChangeTwoTone')),
    PriceCheck: React.lazy(() => import('@mui/icons-material/PriceCheck')),
    PriceCheckOutlined: React.lazy(() => import('@mui/icons-material/PriceCheckOutlined')),
    PriceCheckRounded: React.lazy(() => import('@mui/icons-material/PriceCheckRounded')),
    PriceCheckSharp: React.lazy(() => import('@mui/icons-material/PriceCheckSharp')),
    PriceCheckTwoTone: React.lazy(() => import('@mui/icons-material/PriceCheckTwoTone')),
    PrintDisabled: React.lazy(() => import('@mui/icons-material/PrintDisabled')),
    PrintDisabledOutlined: React.lazy(() => import('@mui/icons-material/PrintDisabledOutlined')),
    PrintDisabledRounded: React.lazy(() => import('@mui/icons-material/PrintDisabledRounded')),
    PrintDisabledSharp: React.lazy(() => import('@mui/icons-material/PrintDisabledSharp')),
    PrintDisabledTwoTone: React.lazy(() => import('@mui/icons-material/PrintDisabledTwoTone')),
    Print: React.lazy(() => import('@mui/icons-material/Print')),
    PrintOutlined: React.lazy(() => import('@mui/icons-material/PrintOutlined')),
    PrintRounded: React.lazy(() => import('@mui/icons-material/PrintRounded')),
    PrintSharp: React.lazy(() => import('@mui/icons-material/PrintSharp')),
    PrintTwoTone: React.lazy(() => import('@mui/icons-material/PrintTwoTone')),
    PriorityHigh: React.lazy(() => import('@mui/icons-material/PriorityHigh')),
    PriorityHighOutlined: React.lazy(() => import('@mui/icons-material/PriorityHighOutlined')),
    PriorityHighRounded: React.lazy(() => import('@mui/icons-material/PriorityHighRounded')),
    PriorityHighSharp: React.lazy(() => import('@mui/icons-material/PriorityHighSharp')),
    PriorityHighTwoTone: React.lazy(() => import('@mui/icons-material/PriorityHighTwoTone')),
    PrivacyTip: React.lazy(() => import('@mui/icons-material/PrivacyTip')),
    PrivacyTipOutlined: React.lazy(() => import('@mui/icons-material/PrivacyTipOutlined')),
    PrivacyTipRounded: React.lazy(() => import('@mui/icons-material/PrivacyTipRounded')),
    PrivacyTipSharp: React.lazy(() => import('@mui/icons-material/PrivacyTipSharp')),
    PrivacyTipTwoTone: React.lazy(() => import('@mui/icons-material/PrivacyTipTwoTone')),
    ProductionQuantityLimits: React.lazy(() => import('@mui/icons-material/ProductionQuantityLimits')),
    ProductionQuantityLimitsOutlined: React.lazy(() => import('@mui/icons-material/ProductionQuantityLimitsOutlined')),
    ProductionQuantityLimitsRounded: React.lazy(() => import('@mui/icons-material/ProductionQuantityLimitsRounded')),
    ProductionQuantityLimitsSharp: React.lazy(() => import('@mui/icons-material/ProductionQuantityLimitsSharp')),
    ProductionQuantityLimitsTwoTone: React.lazy(() => import('@mui/icons-material/ProductionQuantityLimitsTwoTone')),
    Propane: React.lazy(() => import('@mui/icons-material/Propane')),
    PropaneOutlined: React.lazy(() => import('@mui/icons-material/PropaneOutlined')),
    PropaneRounded: React.lazy(() => import('@mui/icons-material/PropaneRounded')),
    PropaneSharp: React.lazy(() => import('@mui/icons-material/PropaneSharp')),
    PropaneTank: React.lazy(() => import('@mui/icons-material/PropaneTank')),
    PropaneTankOutlined: React.lazy(() => import('@mui/icons-material/PropaneTankOutlined')),
    PropaneTankRounded: React.lazy(() => import('@mui/icons-material/PropaneTankRounded')),
    PropaneTankSharp: React.lazy(() => import('@mui/icons-material/PropaneTankSharp')),
    PropaneTankTwoTone: React.lazy(() => import('@mui/icons-material/PropaneTankTwoTone')),
    PropaneTwoTone: React.lazy(() => import('@mui/icons-material/PropaneTwoTone')),
    PsychologyAlt: React.lazy(() => import('@mui/icons-material/PsychologyAlt')),
    PsychologyAltOutlined: React.lazy(() => import('@mui/icons-material/PsychologyAltOutlined')),
    PsychologyAltRounded: React.lazy(() => import('@mui/icons-material/PsychologyAltRounded')),
    PsychologyAltSharp: React.lazy(() => import('@mui/icons-material/PsychologyAltSharp')),
    PsychologyAltTwoTone: React.lazy(() => import('@mui/icons-material/PsychologyAltTwoTone')),
    Psychology: React.lazy(() => import('@mui/icons-material/Psychology')),
    PsychologyOutlined: React.lazy(() => import('@mui/icons-material/PsychologyOutlined')),
    PsychologyRounded: React.lazy(() => import('@mui/icons-material/PsychologyRounded')),
    PsychologySharp: React.lazy(() => import('@mui/icons-material/PsychologySharp')),
    PsychologyTwoTone: React.lazy(() => import('@mui/icons-material/PsychologyTwoTone')),
    Public: React.lazy(() => import('@mui/icons-material/Public')),
    PublicOff: React.lazy(() => import('@mui/icons-material/PublicOff')),
    PublicOffOutlined: React.lazy(() => import('@mui/icons-material/PublicOffOutlined')),
    PublicOffRounded: React.lazy(() => import('@mui/icons-material/PublicOffRounded')),
    PublicOffSharp: React.lazy(() => import('@mui/icons-material/PublicOffSharp')),
    PublicOffTwoTone: React.lazy(() => import('@mui/icons-material/PublicOffTwoTone')),
    PublicOutlined: React.lazy(() => import('@mui/icons-material/PublicOutlined')),
    PublicRounded: React.lazy(() => import('@mui/icons-material/PublicRounded')),
    PublicSharp: React.lazy(() => import('@mui/icons-material/PublicSharp')),
    PublicTwoTone: React.lazy(() => import('@mui/icons-material/PublicTwoTone')),
    Publish: React.lazy(() => import('@mui/icons-material/Publish')),
    PublishedWithChanges: React.lazy(() => import('@mui/icons-material/PublishedWithChanges')),
    PublishedWithChangesOutlined: React.lazy(() => import('@mui/icons-material/PublishedWithChangesOutlined')),
    PublishedWithChangesRounded: React.lazy(() => import('@mui/icons-material/PublishedWithChangesRounded')),
    PublishedWithChangesSharp: React.lazy(() => import('@mui/icons-material/PublishedWithChangesSharp')),
    PublishedWithChangesTwoTone: React.lazy(() => import('@mui/icons-material/PublishedWithChangesTwoTone')),
    PublishOutlined: React.lazy(() => import('@mui/icons-material/PublishOutlined')),
    PublishRounded: React.lazy(() => import('@mui/icons-material/PublishRounded')),
    PublishSharp: React.lazy(() => import('@mui/icons-material/PublishSharp')),
    PublishTwoTone: React.lazy(() => import('@mui/icons-material/PublishTwoTone')),
    PunchClock: React.lazy(() => import('@mui/icons-material/PunchClock')),
    PunchClockOutlined: React.lazy(() => import('@mui/icons-material/PunchClockOutlined')),
    PunchClockRounded: React.lazy(() => import('@mui/icons-material/PunchClockRounded')),
    PunchClockSharp: React.lazy(() => import('@mui/icons-material/PunchClockSharp')),
    PunchClockTwoTone: React.lazy(() => import('@mui/icons-material/PunchClockTwoTone')),
    PushPin: React.lazy(() => import('@mui/icons-material/PushPin')),
    PushPinOutlined: React.lazy(() => import('@mui/icons-material/PushPinOutlined')),
    PushPinRounded: React.lazy(() => import('@mui/icons-material/PushPinRounded')),
    PushPinSharp: React.lazy(() => import('@mui/icons-material/PushPinSharp')),
    PushPinTwoTone: React.lazy(() => import('@mui/icons-material/PushPinTwoTone')),
    QrCode2: React.lazy(() => import('@mui/icons-material/QrCode2')),
    QrCode2Outlined: React.lazy(() => import('@mui/icons-material/QrCode2Outlined')),
    QrCode2Rounded: React.lazy(() => import('@mui/icons-material/QrCode2Rounded')),
    QrCode2Sharp: React.lazy(() => import('@mui/icons-material/QrCode2Sharp')),
    QrCode2TwoTone: React.lazy(() => import('@mui/icons-material/QrCode2TwoTone')),
    QrCode: React.lazy(() => import('@mui/icons-material/QrCode')),
    QrCodeOutlined: React.lazy(() => import('@mui/icons-material/QrCodeOutlined')),
    QrCodeRounded: React.lazy(() => import('@mui/icons-material/QrCodeRounded')),
    QrCodeScanner: React.lazy(() => import('@mui/icons-material/QrCodeScanner')),
    QrCodeScannerOutlined: React.lazy(() => import('@mui/icons-material/QrCodeScannerOutlined')),
    QrCodeScannerRounded: React.lazy(() => import('@mui/icons-material/QrCodeScannerRounded')),
    QrCodeScannerSharp: React.lazy(() => import('@mui/icons-material/QrCodeScannerSharp')),
    QrCodeScannerTwoTone: React.lazy(() => import('@mui/icons-material/QrCodeScannerTwoTone')),
    QrCodeSharp: React.lazy(() => import('@mui/icons-material/QrCodeSharp')),
    QrCodeTwoTone: React.lazy(() => import('@mui/icons-material/QrCodeTwoTone')),
    QueryBuilder: React.lazy(() => import('@mui/icons-material/QueryBuilder')),
    QueryBuilderOutlined: React.lazy(() => import('@mui/icons-material/QueryBuilderOutlined')),
    QueryBuilderRounded: React.lazy(() => import('@mui/icons-material/QueryBuilderRounded')),
    QueryBuilderSharp: React.lazy(() => import('@mui/icons-material/QueryBuilderSharp')),
    QueryBuilderTwoTone: React.lazy(() => import('@mui/icons-material/QueryBuilderTwoTone')),
    QueryStats: React.lazy(() => import('@mui/icons-material/QueryStats')),
    QueryStatsOutlined: React.lazy(() => import('@mui/icons-material/QueryStatsOutlined')),
    QueryStatsRounded: React.lazy(() => import('@mui/icons-material/QueryStatsRounded')),
    QueryStatsSharp: React.lazy(() => import('@mui/icons-material/QueryStatsSharp')),
    QueryStatsTwoTone: React.lazy(() => import('@mui/icons-material/QueryStatsTwoTone')),
    QuestionAnswer: React.lazy(() => import('@mui/icons-material/QuestionAnswer')),
    QuestionAnswerOutlined: React.lazy(() => import('@mui/icons-material/QuestionAnswerOutlined')),
    QuestionAnswerRounded: React.lazy(() => import('@mui/icons-material/QuestionAnswerRounded')),
    QuestionAnswerSharp: React.lazy(() => import('@mui/icons-material/QuestionAnswerSharp')),
    QuestionAnswerTwoTone: React.lazy(() => import('@mui/icons-material/QuestionAnswerTwoTone')),
    QuestionMark: React.lazy(() => import('@mui/icons-material/QuestionMark')),
    QuestionMarkOutlined: React.lazy(() => import('@mui/icons-material/QuestionMarkOutlined')),
    QuestionMarkRounded: React.lazy(() => import('@mui/icons-material/QuestionMarkRounded')),
    QuestionMarkSharp: React.lazy(() => import('@mui/icons-material/QuestionMarkSharp')),
    QuestionMarkTwoTone: React.lazy(() => import('@mui/icons-material/QuestionMarkTwoTone')),
    Queue: React.lazy(() => import('@mui/icons-material/Queue')),
    QueueMusic: React.lazy(() => import('@mui/icons-material/QueueMusic')),
    QueueMusicOutlined: React.lazy(() => import('@mui/icons-material/QueueMusicOutlined')),
    QueueMusicRounded: React.lazy(() => import('@mui/icons-material/QueueMusicRounded')),
    QueueMusicSharp: React.lazy(() => import('@mui/icons-material/QueueMusicSharp')),
    QueueMusicTwoTone: React.lazy(() => import('@mui/icons-material/QueueMusicTwoTone')),
    QueueOutlined: React.lazy(() => import('@mui/icons-material/QueueOutlined')),
    QueuePlayNext: React.lazy(() => import('@mui/icons-material/QueuePlayNext')),
    QueuePlayNextOutlined: React.lazy(() => import('@mui/icons-material/QueuePlayNextOutlined')),
    QueuePlayNextRounded: React.lazy(() => import('@mui/icons-material/QueuePlayNextRounded')),
    QueuePlayNextSharp: React.lazy(() => import('@mui/icons-material/QueuePlayNextSharp')),
    QueuePlayNextTwoTone: React.lazy(() => import('@mui/icons-material/QueuePlayNextTwoTone')),
    QueueRounded: React.lazy(() => import('@mui/icons-material/QueueRounded')),
    QueueSharp: React.lazy(() => import('@mui/icons-material/QueueSharp')),
    QueueTwoTone: React.lazy(() => import('@mui/icons-material/QueueTwoTone')),
    Quickreply: React.lazy(() => import('@mui/icons-material/Quickreply')),
    QuickreplyOutlined: React.lazy(() => import('@mui/icons-material/QuickreplyOutlined')),
    QuickreplyRounded: React.lazy(() => import('@mui/icons-material/QuickreplyRounded')),
    QuickreplySharp: React.lazy(() => import('@mui/icons-material/QuickreplySharp')),
    QuickreplyTwoTone: React.lazy(() => import('@mui/icons-material/QuickreplyTwoTone')),
    Quiz: React.lazy(() => import('@mui/icons-material/Quiz')),
    QuizOutlined: React.lazy(() => import('@mui/icons-material/QuizOutlined')),
    QuizRounded: React.lazy(() => import('@mui/icons-material/QuizRounded')),
    QuizSharp: React.lazy(() => import('@mui/icons-material/QuizSharp')),
    QuizTwoTone: React.lazy(() => import('@mui/icons-material/QuizTwoTone')),
    Radar: React.lazy(() => import('@mui/icons-material/Radar')),
    RadarOutlined: React.lazy(() => import('@mui/icons-material/RadarOutlined')),
    RadarRounded: React.lazy(() => import('@mui/icons-material/RadarRounded')),
    RadarSharp: React.lazy(() => import('@mui/icons-material/RadarSharp')),
    RadarTwoTone: React.lazy(() => import('@mui/icons-material/RadarTwoTone')),
    RadioButtonChecked: React.lazy(() => import('@mui/icons-material/RadioButtonChecked')),
    RadioButtonCheckedOutlined: React.lazy(() => import('@mui/icons-material/RadioButtonCheckedOutlined')),
    RadioButtonCheckedRounded: React.lazy(() => import('@mui/icons-material/RadioButtonCheckedRounded')),
    RadioButtonCheckedSharp: React.lazy(() => import('@mui/icons-material/RadioButtonCheckedSharp')),
    RadioButtonCheckedTwoTone: React.lazy(() => import('@mui/icons-material/RadioButtonCheckedTwoTone')),
    RadioButtonUnchecked: React.lazy(() => import('@mui/icons-material/RadioButtonUnchecked')),
    RadioButtonUncheckedOutlined: React.lazy(() => import('@mui/icons-material/RadioButtonUncheckedOutlined')),
    RadioButtonUncheckedRounded: React.lazy(() => import('@mui/icons-material/RadioButtonUncheckedRounded')),
    RadioButtonUncheckedSharp: React.lazy(() => import('@mui/icons-material/RadioButtonUncheckedSharp')),
    RadioButtonUncheckedTwoTone: React.lazy(() => import('@mui/icons-material/RadioButtonUncheckedTwoTone')),
    Radio: React.lazy(() => import('@mui/icons-material/Radio')),
    RadioOutlined: React.lazy(() => import('@mui/icons-material/RadioOutlined')),
    RadioRounded: React.lazy(() => import('@mui/icons-material/RadioRounded')),
    RadioSharp: React.lazy(() => import('@mui/icons-material/RadioSharp')),
    RadioTwoTone: React.lazy(() => import('@mui/icons-material/RadioTwoTone')),
    RailwayAlert: React.lazy(() => import('@mui/icons-material/RailwayAlert')),
    RailwayAlertOutlined: React.lazy(() => import('@mui/icons-material/RailwayAlertOutlined')),
    RailwayAlertRounded: React.lazy(() => import('@mui/icons-material/RailwayAlertRounded')),
    RailwayAlertSharp: React.lazy(() => import('@mui/icons-material/RailwayAlertSharp')),
    RailwayAlertTwoTone: React.lazy(() => import('@mui/icons-material/RailwayAlertTwoTone')),
    RamenDining: React.lazy(() => import('@mui/icons-material/RamenDining')),
    RamenDiningOutlined: React.lazy(() => import('@mui/icons-material/RamenDiningOutlined')),
    RamenDiningRounded: React.lazy(() => import('@mui/icons-material/RamenDiningRounded')),
    RamenDiningSharp: React.lazy(() => import('@mui/icons-material/RamenDiningSharp')),
    RamenDiningTwoTone: React.lazy(() => import('@mui/icons-material/RamenDiningTwoTone')),
    RampLeft: React.lazy(() => import('@mui/icons-material/RampLeft')),
    RampLeftOutlined: React.lazy(() => import('@mui/icons-material/RampLeftOutlined')),
    RampLeftRounded: React.lazy(() => import('@mui/icons-material/RampLeftRounded')),
    RampLeftSharp: React.lazy(() => import('@mui/icons-material/RampLeftSharp')),
    RampLeftTwoTone: React.lazy(() => import('@mui/icons-material/RampLeftTwoTone')),
    RampRight: React.lazy(() => import('@mui/icons-material/RampRight')),
    RampRightOutlined: React.lazy(() => import('@mui/icons-material/RampRightOutlined')),
    RampRightRounded: React.lazy(() => import('@mui/icons-material/RampRightRounded')),
    RampRightSharp: React.lazy(() => import('@mui/icons-material/RampRightSharp')),
    RampRightTwoTone: React.lazy(() => import('@mui/icons-material/RampRightTwoTone')),
    RateReview: React.lazy(() => import('@mui/icons-material/RateReview')),
    RateReviewOutlined: React.lazy(() => import('@mui/icons-material/RateReviewOutlined')),
    RateReviewRounded: React.lazy(() => import('@mui/icons-material/RateReviewRounded')),
    RateReviewSharp: React.lazy(() => import('@mui/icons-material/RateReviewSharp')),
    RateReviewTwoTone: React.lazy(() => import('@mui/icons-material/RateReviewTwoTone')),
    RawOff: React.lazy(() => import('@mui/icons-material/RawOff')),
    RawOffOutlined: React.lazy(() => import('@mui/icons-material/RawOffOutlined')),
    RawOffRounded: React.lazy(() => import('@mui/icons-material/RawOffRounded')),
    RawOffSharp: React.lazy(() => import('@mui/icons-material/RawOffSharp')),
    RawOffTwoTone: React.lazy(() => import('@mui/icons-material/RawOffTwoTone')),
    RawOn: React.lazy(() => import('@mui/icons-material/RawOn')),
    RawOnOutlined: React.lazy(() => import('@mui/icons-material/RawOnOutlined')),
    RawOnRounded: React.lazy(() => import('@mui/icons-material/RawOnRounded')),
    RawOnSharp: React.lazy(() => import('@mui/icons-material/RawOnSharp')),
    RawOnTwoTone: React.lazy(() => import('@mui/icons-material/RawOnTwoTone')),
    ReadMore: React.lazy(() => import('@mui/icons-material/ReadMore')),
    ReadMoreOutlined: React.lazy(() => import('@mui/icons-material/ReadMoreOutlined')),
    ReadMoreRounded: React.lazy(() => import('@mui/icons-material/ReadMoreRounded')),
    ReadMoreSharp: React.lazy(() => import('@mui/icons-material/ReadMoreSharp')),
    ReadMoreTwoTone: React.lazy(() => import('@mui/icons-material/ReadMoreTwoTone')),
    Receipt: React.lazy(() => import('@mui/icons-material/Receipt')),
    ReceiptLong: React.lazy(() => import('@mui/icons-material/ReceiptLong')),
    ReceiptLongOutlined: React.lazy(() => import('@mui/icons-material/ReceiptLongOutlined')),
    ReceiptLongRounded: React.lazy(() => import('@mui/icons-material/ReceiptLongRounded')),
    ReceiptLongSharp: React.lazy(() => import('@mui/icons-material/ReceiptLongSharp')),
    ReceiptLongTwoTone: React.lazy(() => import('@mui/icons-material/ReceiptLongTwoTone')),
    ReceiptOutlined: React.lazy(() => import('@mui/icons-material/ReceiptOutlined')),
    ReceiptRounded: React.lazy(() => import('@mui/icons-material/ReceiptRounded')),
    ReceiptSharp: React.lazy(() => import('@mui/icons-material/ReceiptSharp')),
    ReceiptTwoTone: React.lazy(() => import('@mui/icons-material/ReceiptTwoTone')),
    RecentActors: React.lazy(() => import('@mui/icons-material/RecentActors')),
    RecentActorsOutlined: React.lazy(() => import('@mui/icons-material/RecentActorsOutlined')),
    RecentActorsRounded: React.lazy(() => import('@mui/icons-material/RecentActorsRounded')),
    RecentActorsSharp: React.lazy(() => import('@mui/icons-material/RecentActorsSharp')),
    RecentActorsTwoTone: React.lazy(() => import('@mui/icons-material/RecentActorsTwoTone')),
    Recommend: React.lazy(() => import('@mui/icons-material/Recommend')),
    RecommendOutlined: React.lazy(() => import('@mui/icons-material/RecommendOutlined')),
    RecommendRounded: React.lazy(() => import('@mui/icons-material/RecommendRounded')),
    RecommendSharp: React.lazy(() => import('@mui/icons-material/RecommendSharp')),
    RecommendTwoTone: React.lazy(() => import('@mui/icons-material/RecommendTwoTone')),
    RecordVoiceOver: React.lazy(() => import('@mui/icons-material/RecordVoiceOver')),
    RecordVoiceOverOutlined: React.lazy(() => import('@mui/icons-material/RecordVoiceOverOutlined')),
    RecordVoiceOverRounded: React.lazy(() => import('@mui/icons-material/RecordVoiceOverRounded')),
    RecordVoiceOverSharp: React.lazy(() => import('@mui/icons-material/RecordVoiceOverSharp')),
    RecordVoiceOverTwoTone: React.lazy(() => import('@mui/icons-material/RecordVoiceOverTwoTone')),
    Rectangle: React.lazy(() => import('@mui/icons-material/Rectangle')),
    RectangleOutlined: React.lazy(() => import('@mui/icons-material/RectangleOutlined')),
    RectangleRounded: React.lazy(() => import('@mui/icons-material/RectangleRounded')),
    RectangleSharp: React.lazy(() => import('@mui/icons-material/RectangleSharp')),
    RectangleTwoTone: React.lazy(() => import('@mui/icons-material/RectangleTwoTone')),
    Reddit: React.lazy(() => import('@mui/icons-material/Reddit')),
    Redeem: React.lazy(() => import('@mui/icons-material/Redeem')),
    RedeemOutlined: React.lazy(() => import('@mui/icons-material/RedeemOutlined')),
    RedeemRounded: React.lazy(() => import('@mui/icons-material/RedeemRounded')),
    RedeemSharp: React.lazy(() => import('@mui/icons-material/RedeemSharp')),
    RedeemTwoTone: React.lazy(() => import('@mui/icons-material/RedeemTwoTone')),
    Redo: React.lazy(() => import('@mui/icons-material/Redo')),
    RedoOutlined: React.lazy(() => import('@mui/icons-material/RedoOutlined')),
    RedoRounded: React.lazy(() => import('@mui/icons-material/RedoRounded')),
    RedoSharp: React.lazy(() => import('@mui/icons-material/RedoSharp')),
    RedoTwoTone: React.lazy(() => import('@mui/icons-material/RedoTwoTone')),
    ReduceCapacity: React.lazy(() => import('@mui/icons-material/ReduceCapacity')),
    ReduceCapacityOutlined: React.lazy(() => import('@mui/icons-material/ReduceCapacityOutlined')),
    ReduceCapacityRounded: React.lazy(() => import('@mui/icons-material/ReduceCapacityRounded')),
    ReduceCapacitySharp: React.lazy(() => import('@mui/icons-material/ReduceCapacitySharp')),
    ReduceCapacityTwoTone: React.lazy(() => import('@mui/icons-material/ReduceCapacityTwoTone')),
    Refresh: React.lazy(() => import('@mui/icons-material/Refresh')),
    RefreshOutlined: React.lazy(() => import('@mui/icons-material/RefreshOutlined')),
    RefreshRounded: React.lazy(() => import('@mui/icons-material/RefreshRounded')),
    RefreshSharp: React.lazy(() => import('@mui/icons-material/RefreshSharp')),
    RefreshTwoTone: React.lazy(() => import('@mui/icons-material/RefreshTwoTone')),
    RememberMe: React.lazy(() => import('@mui/icons-material/RememberMe')),
    RememberMeOutlined: React.lazy(() => import('@mui/icons-material/RememberMeOutlined')),
    RememberMeRounded: React.lazy(() => import('@mui/icons-material/RememberMeRounded')),
    RememberMeSharp: React.lazy(() => import('@mui/icons-material/RememberMeSharp')),
    RememberMeTwoTone: React.lazy(() => import('@mui/icons-material/RememberMeTwoTone')),
    RemoveCircle: React.lazy(() => import('@mui/icons-material/RemoveCircle')),
    RemoveCircleOutlined: React.lazy(() => import('@mui/icons-material/RemoveCircleOutlined')),
    RemoveCircleOutline: React.lazy(() => import('@mui/icons-material/RemoveCircleOutline')),
    RemoveCircleOutlineOutlined: React.lazy(() => import('@mui/icons-material/RemoveCircleOutlineOutlined')),
    RemoveCircleOutlineRounded: React.lazy(() => import('@mui/icons-material/RemoveCircleOutlineRounded')),
    RemoveCircleOutlineSharp: React.lazy(() => import('@mui/icons-material/RemoveCircleOutlineSharp')),
    RemoveCircleOutlineTwoTone: React.lazy(() => import('@mui/icons-material/RemoveCircleOutlineTwoTone')),
    RemoveCircleRounded: React.lazy(() => import('@mui/icons-material/RemoveCircleRounded')),
    RemoveCircleSharp: React.lazy(() => import('@mui/icons-material/RemoveCircleSharp')),
    RemoveCircleTwoTone: React.lazy(() => import('@mui/icons-material/RemoveCircleTwoTone')),
    RemoveDone: React.lazy(() => import('@mui/icons-material/RemoveDone')),
    RemoveDoneOutlined: React.lazy(() => import('@mui/icons-material/RemoveDoneOutlined')),
    RemoveDoneRounded: React.lazy(() => import('@mui/icons-material/RemoveDoneRounded')),
    RemoveDoneSharp: React.lazy(() => import('@mui/icons-material/RemoveDoneSharp')),
    RemoveDoneTwoTone: React.lazy(() => import('@mui/icons-material/RemoveDoneTwoTone')),
    Remove: React.lazy(() => import('@mui/icons-material/Remove')),
    RemoveFromQueue: React.lazy(() => import('@mui/icons-material/RemoveFromQueue')),
    RemoveFromQueueOutlined: React.lazy(() => import('@mui/icons-material/RemoveFromQueueOutlined')),
    RemoveFromQueueRounded: React.lazy(() => import('@mui/icons-material/RemoveFromQueueRounded')),
    RemoveFromQueueSharp: React.lazy(() => import('@mui/icons-material/RemoveFromQueueSharp')),
    RemoveFromQueueTwoTone: React.lazy(() => import('@mui/icons-material/RemoveFromQueueTwoTone')),
    RemoveModerator: React.lazy(() => import('@mui/icons-material/RemoveModerator')),
    RemoveModeratorOutlined: React.lazy(() => import('@mui/icons-material/RemoveModeratorOutlined')),
    RemoveModeratorRounded: React.lazy(() => import('@mui/icons-material/RemoveModeratorRounded')),
    RemoveModeratorSharp: React.lazy(() => import('@mui/icons-material/RemoveModeratorSharp')),
    RemoveModeratorTwoTone: React.lazy(() => import('@mui/icons-material/RemoveModeratorTwoTone')),
    RemoveOutlined: React.lazy(() => import('@mui/icons-material/RemoveOutlined')),
    RemoveRedEye: React.lazy(() => import('@mui/icons-material/RemoveRedEye')),
    RemoveRedEyeOutlined: React.lazy(() => import('@mui/icons-material/RemoveRedEyeOutlined')),
    RemoveRedEyeRounded: React.lazy(() => import('@mui/icons-material/RemoveRedEyeRounded')),
    RemoveRedEyeSharp: React.lazy(() => import('@mui/icons-material/RemoveRedEyeSharp')),
    RemoveRedEyeTwoTone: React.lazy(() => import('@mui/icons-material/RemoveRedEyeTwoTone')),
    RemoveRoad: React.lazy(() => import('@mui/icons-material/RemoveRoad')),
    RemoveRoadOutlined: React.lazy(() => import('@mui/icons-material/RemoveRoadOutlined')),
    RemoveRoadRounded: React.lazy(() => import('@mui/icons-material/RemoveRoadRounded')),
    RemoveRoadSharp: React.lazy(() => import('@mui/icons-material/RemoveRoadSharp')),
    RemoveRoadTwoTone: React.lazy(() => import('@mui/icons-material/RemoveRoadTwoTone')),
    RemoveRounded: React.lazy(() => import('@mui/icons-material/RemoveRounded')),
    RemoveSharp: React.lazy(() => import('@mui/icons-material/RemoveSharp')),
    RemoveShoppingCart: React.lazy(() => import('@mui/icons-material/RemoveShoppingCart')),
    RemoveShoppingCartOutlined: React.lazy(() => import('@mui/icons-material/RemoveShoppingCartOutlined')),
    RemoveShoppingCartRounded: React.lazy(() => import('@mui/icons-material/RemoveShoppingCartRounded')),
    RemoveShoppingCartSharp: React.lazy(() => import('@mui/icons-material/RemoveShoppingCartSharp')),
    RemoveShoppingCartTwoTone: React.lazy(() => import('@mui/icons-material/RemoveShoppingCartTwoTone')),
    RemoveTwoTone: React.lazy(() => import('@mui/icons-material/RemoveTwoTone')),
    Reorder: React.lazy(() => import('@mui/icons-material/Reorder')),
    ReorderOutlined: React.lazy(() => import('@mui/icons-material/ReorderOutlined')),
    ReorderRounded: React.lazy(() => import('@mui/icons-material/ReorderRounded')),
    ReorderSharp: React.lazy(() => import('@mui/icons-material/ReorderSharp')),
    ReorderTwoTone: React.lazy(() => import('@mui/icons-material/ReorderTwoTone')),
    Repartition: React.lazy(() => import('@mui/icons-material/Repartition')),
    RepartitionOutlined: React.lazy(() => import('@mui/icons-material/RepartitionOutlined')),
    RepartitionRounded: React.lazy(() => import('@mui/icons-material/RepartitionRounded')),
    RepartitionSharp: React.lazy(() => import('@mui/icons-material/RepartitionSharp')),
    RepartitionTwoTone: React.lazy(() => import('@mui/icons-material/RepartitionTwoTone')),
    Repeat: React.lazy(() => import('@mui/icons-material/Repeat')),
    RepeatOn: React.lazy(() => import('@mui/icons-material/RepeatOn')),
    RepeatOne: React.lazy(() => import('@mui/icons-material/RepeatOne')),
    RepeatOneOn: React.lazy(() => import('@mui/icons-material/RepeatOneOn')),
    RepeatOneOnOutlined: React.lazy(() => import('@mui/icons-material/RepeatOneOnOutlined')),
    RepeatOneOnRounded: React.lazy(() => import('@mui/icons-material/RepeatOneOnRounded')),
    RepeatOneOnSharp: React.lazy(() => import('@mui/icons-material/RepeatOneOnSharp')),
    RepeatOneOnTwoTone: React.lazy(() => import('@mui/icons-material/RepeatOneOnTwoTone')),
    RepeatOneOutlined: React.lazy(() => import('@mui/icons-material/RepeatOneOutlined')),
    RepeatOneRounded: React.lazy(() => import('@mui/icons-material/RepeatOneRounded')),
    RepeatOneSharp: React.lazy(() => import('@mui/icons-material/RepeatOneSharp')),
    RepeatOneTwoTone: React.lazy(() => import('@mui/icons-material/RepeatOneTwoTone')),
    RepeatOnOutlined: React.lazy(() => import('@mui/icons-material/RepeatOnOutlined')),
    RepeatOnRounded: React.lazy(() => import('@mui/icons-material/RepeatOnRounded')),
    RepeatOnSharp: React.lazy(() => import('@mui/icons-material/RepeatOnSharp')),
    RepeatOnTwoTone: React.lazy(() => import('@mui/icons-material/RepeatOnTwoTone')),
    RepeatOutlined: React.lazy(() => import('@mui/icons-material/RepeatOutlined')),
    RepeatRounded: React.lazy(() => import('@mui/icons-material/RepeatRounded')),
    RepeatSharp: React.lazy(() => import('@mui/icons-material/RepeatSharp')),
    RepeatTwoTone: React.lazy(() => import('@mui/icons-material/RepeatTwoTone')),
    Replay10: React.lazy(() => import('@mui/icons-material/Replay10')),
    Replay10Outlined: React.lazy(() => import('@mui/icons-material/Replay10Outlined')),
    Replay10Rounded: React.lazy(() => import('@mui/icons-material/Replay10Rounded')),
    Replay10Sharp: React.lazy(() => import('@mui/icons-material/Replay10Sharp')),
    Replay10TwoTone: React.lazy(() => import('@mui/icons-material/Replay10TwoTone')),
    Replay30: React.lazy(() => import('@mui/icons-material/Replay30')),
    Replay30Outlined: React.lazy(() => import('@mui/icons-material/Replay30Outlined')),
    Replay30Rounded: React.lazy(() => import('@mui/icons-material/Replay30Rounded')),
    Replay30Sharp: React.lazy(() => import('@mui/icons-material/Replay30Sharp')),
    Replay30TwoTone: React.lazy(() => import('@mui/icons-material/Replay30TwoTone')),
    Replay5: React.lazy(() => import('@mui/icons-material/Replay5')),
    Replay5Outlined: React.lazy(() => import('@mui/icons-material/Replay5Outlined')),
    Replay5Rounded: React.lazy(() => import('@mui/icons-material/Replay5Rounded')),
    Replay5Sharp: React.lazy(() => import('@mui/icons-material/Replay5Sharp')),
    Replay5TwoTone: React.lazy(() => import('@mui/icons-material/Replay5TwoTone')),
    ReplayCircleFilled: React.lazy(() => import('@mui/icons-material/ReplayCircleFilled')),
    ReplayCircleFilledOutlined: React.lazy(() => import('@mui/icons-material/ReplayCircleFilledOutlined')),
    ReplayCircleFilledRounded: React.lazy(() => import('@mui/icons-material/ReplayCircleFilledRounded')),
    ReplayCircleFilledSharp: React.lazy(() => import('@mui/icons-material/ReplayCircleFilledSharp')),
    ReplayCircleFilledTwoTone: React.lazy(() => import('@mui/icons-material/ReplayCircleFilledTwoTone')),
    Replay: React.lazy(() => import('@mui/icons-material/Replay')),
    ReplayOutlined: React.lazy(() => import('@mui/icons-material/ReplayOutlined')),
    ReplayRounded: React.lazy(() => import('@mui/icons-material/ReplayRounded')),
    ReplaySharp: React.lazy(() => import('@mui/icons-material/ReplaySharp')),
    ReplayTwoTone: React.lazy(() => import('@mui/icons-material/ReplayTwoTone')),
    ReplyAll: React.lazy(() => import('@mui/icons-material/ReplyAll')),
    ReplyAllOutlined: React.lazy(() => import('@mui/icons-material/ReplyAllOutlined')),
    ReplyAllRounded: React.lazy(() => import('@mui/icons-material/ReplyAllRounded')),
    ReplyAllSharp: React.lazy(() => import('@mui/icons-material/ReplyAllSharp')),
    ReplyAllTwoTone: React.lazy(() => import('@mui/icons-material/ReplyAllTwoTone')),
    Reply: React.lazy(() => import('@mui/icons-material/Reply')),
    ReplyOutlined: React.lazy(() => import('@mui/icons-material/ReplyOutlined')),
    ReplyRounded: React.lazy(() => import('@mui/icons-material/ReplyRounded')),
    ReplySharp: React.lazy(() => import('@mui/icons-material/ReplySharp')),
    ReplyTwoTone: React.lazy(() => import('@mui/icons-material/ReplyTwoTone')),
    Report: React.lazy(() => import('@mui/icons-material/Report')),
    ReportGmailerrorred: React.lazy(() => import('@mui/icons-material/ReportGmailerrorred')),
    ReportGmailerrorredOutlined: React.lazy(() => import('@mui/icons-material/ReportGmailerrorredOutlined')),
    ReportGmailerrorredRounded: React.lazy(() => import('@mui/icons-material/ReportGmailerrorredRounded')),
    ReportGmailerrorredSharp: React.lazy(() => import('@mui/icons-material/ReportGmailerrorredSharp')),
    ReportGmailerrorredTwoTone: React.lazy(() => import('@mui/icons-material/ReportGmailerrorredTwoTone')),
    ReportOff: React.lazy(() => import('@mui/icons-material/ReportOff')),
    ReportOffOutlined: React.lazy(() => import('@mui/icons-material/ReportOffOutlined')),
    ReportOffRounded: React.lazy(() => import('@mui/icons-material/ReportOffRounded')),
    ReportOffSharp: React.lazy(() => import('@mui/icons-material/ReportOffSharp')),
    ReportOffTwoTone: React.lazy(() => import('@mui/icons-material/ReportOffTwoTone')),
    ReportOutlined: React.lazy(() => import('@mui/icons-material/ReportOutlined')),
    ReportProblem: React.lazy(() => import('@mui/icons-material/ReportProblem')),
    ReportProblemOutlined: React.lazy(() => import('@mui/icons-material/ReportProblemOutlined')),
    ReportProblemRounded: React.lazy(() => import('@mui/icons-material/ReportProblemRounded')),
    ReportProblemSharp: React.lazy(() => import('@mui/icons-material/ReportProblemSharp')),
    ReportProblemTwoTone: React.lazy(() => import('@mui/icons-material/ReportProblemTwoTone')),
    ReportRounded: React.lazy(() => import('@mui/icons-material/ReportRounded')),
    ReportSharp: React.lazy(() => import('@mui/icons-material/ReportSharp')),
    ReportTwoTone: React.lazy(() => import('@mui/icons-material/ReportTwoTone')),
    RequestPage: React.lazy(() => import('@mui/icons-material/RequestPage')),
    RequestPageOutlined: React.lazy(() => import('@mui/icons-material/RequestPageOutlined')),
    RequestPageRounded: React.lazy(() => import('@mui/icons-material/RequestPageRounded')),
    RequestPageSharp: React.lazy(() => import('@mui/icons-material/RequestPageSharp')),
    RequestPageTwoTone: React.lazy(() => import('@mui/icons-material/RequestPageTwoTone')),
    RequestQuote: React.lazy(() => import('@mui/icons-material/RequestQuote')),
    RequestQuoteOutlined: React.lazy(() => import('@mui/icons-material/RequestQuoteOutlined')),
    RequestQuoteRounded: React.lazy(() => import('@mui/icons-material/RequestQuoteRounded')),
    RequestQuoteSharp: React.lazy(() => import('@mui/icons-material/RequestQuoteSharp')),
    RequestQuoteTwoTone: React.lazy(() => import('@mui/icons-material/RequestQuoteTwoTone')),
    ResetTv: React.lazy(() => import('@mui/icons-material/ResetTv')),
    ResetTvOutlined: React.lazy(() => import('@mui/icons-material/ResetTvOutlined')),
    ResetTvRounded: React.lazy(() => import('@mui/icons-material/ResetTvRounded')),
    ResetTvSharp: React.lazy(() => import('@mui/icons-material/ResetTvSharp')),
    ResetTvTwoTone: React.lazy(() => import('@mui/icons-material/ResetTvTwoTone')),
    RestartAlt: React.lazy(() => import('@mui/icons-material/RestartAlt')),
    RestartAltOutlined: React.lazy(() => import('@mui/icons-material/RestartAltOutlined')),
    RestartAltRounded: React.lazy(() => import('@mui/icons-material/RestartAltRounded')),
    RestartAltSharp: React.lazy(() => import('@mui/icons-material/RestartAltSharp')),
    RestartAltTwoTone: React.lazy(() => import('@mui/icons-material/RestartAltTwoTone')),
    Restaurant: React.lazy(() => import('@mui/icons-material/Restaurant')),
    RestaurantMenu: React.lazy(() => import('@mui/icons-material/RestaurantMenu')),
    RestaurantMenuOutlined: React.lazy(() => import('@mui/icons-material/RestaurantMenuOutlined')),
    RestaurantMenuRounded: React.lazy(() => import('@mui/icons-material/RestaurantMenuRounded')),
    RestaurantMenuSharp: React.lazy(() => import('@mui/icons-material/RestaurantMenuSharp')),
    RestaurantMenuTwoTone: React.lazy(() => import('@mui/icons-material/RestaurantMenuTwoTone')),
    RestaurantOutlined: React.lazy(() => import('@mui/icons-material/RestaurantOutlined')),
    RestaurantRounded: React.lazy(() => import('@mui/icons-material/RestaurantRounded')),
    RestaurantSharp: React.lazy(() => import('@mui/icons-material/RestaurantSharp')),
    RestaurantTwoTone: React.lazy(() => import('@mui/icons-material/RestaurantTwoTone')),
    Restore: React.lazy(() => import('@mui/icons-material/Restore')),
    RestoreFromTrash: React.lazy(() => import('@mui/icons-material/RestoreFromTrash')),
    RestoreFromTrashOutlined: React.lazy(() => import('@mui/icons-material/RestoreFromTrashOutlined')),
    RestoreFromTrashRounded: React.lazy(() => import('@mui/icons-material/RestoreFromTrashRounded')),
    RestoreFromTrashSharp: React.lazy(() => import('@mui/icons-material/RestoreFromTrashSharp')),
    RestoreFromTrashTwoTone: React.lazy(() => import('@mui/icons-material/RestoreFromTrashTwoTone')),
    RestoreOutlined: React.lazy(() => import('@mui/icons-material/RestoreOutlined')),
    RestorePage: React.lazy(() => import('@mui/icons-material/RestorePage')),
    RestorePageOutlined: React.lazy(() => import('@mui/icons-material/RestorePageOutlined')),
    RestorePageRounded: React.lazy(() => import('@mui/icons-material/RestorePageRounded')),
    RestorePageSharp: React.lazy(() => import('@mui/icons-material/RestorePageSharp')),
    RestorePageTwoTone: React.lazy(() => import('@mui/icons-material/RestorePageTwoTone')),
    RestoreRounded: React.lazy(() => import('@mui/icons-material/RestoreRounded')),
    RestoreSharp: React.lazy(() => import('@mui/icons-material/RestoreSharp')),
    RestoreTwoTone: React.lazy(() => import('@mui/icons-material/RestoreTwoTone')),
    Reviews: React.lazy(() => import('@mui/icons-material/Reviews')),
    ReviewsOutlined: React.lazy(() => import('@mui/icons-material/ReviewsOutlined')),
    ReviewsRounded: React.lazy(() => import('@mui/icons-material/ReviewsRounded')),
    ReviewsSharp: React.lazy(() => import('@mui/icons-material/ReviewsSharp')),
    ReviewsTwoTone: React.lazy(() => import('@mui/icons-material/ReviewsTwoTone')),
    RiceBowl: React.lazy(() => import('@mui/icons-material/RiceBowl')),
    RiceBowlOutlined: React.lazy(() => import('@mui/icons-material/RiceBowlOutlined')),
    RiceBowlRounded: React.lazy(() => import('@mui/icons-material/RiceBowlRounded')),
    RiceBowlSharp: React.lazy(() => import('@mui/icons-material/RiceBowlSharp')),
    RiceBowlTwoTone: React.lazy(() => import('@mui/icons-material/RiceBowlTwoTone')),
    RingVolume: React.lazy(() => import('@mui/icons-material/RingVolume')),
    RingVolumeOutlined: React.lazy(() => import('@mui/icons-material/RingVolumeOutlined')),
    RingVolumeRounded: React.lazy(() => import('@mui/icons-material/RingVolumeRounded')),
    RingVolumeSharp: React.lazy(() => import('@mui/icons-material/RingVolumeSharp')),
    RingVolumeTwoTone: React.lazy(() => import('@mui/icons-material/RingVolumeTwoTone')),
    RMobiledata: React.lazy(() => import('@mui/icons-material/RMobiledata')),
    RMobiledataOutlined: React.lazy(() => import('@mui/icons-material/RMobiledataOutlined')),
    RMobiledataRounded: React.lazy(() => import('@mui/icons-material/RMobiledataRounded')),
    RMobiledataSharp: React.lazy(() => import('@mui/icons-material/RMobiledataSharp')),
    RMobiledataTwoTone: React.lazy(() => import('@mui/icons-material/RMobiledataTwoTone')),
    Rocket: React.lazy(() => import('@mui/icons-material/Rocket')),
    RocketLaunch: React.lazy(() => import('@mui/icons-material/RocketLaunch')),
    RocketLaunchOutlined: React.lazy(() => import('@mui/icons-material/RocketLaunchOutlined')),
    RocketLaunchRounded: React.lazy(() => import('@mui/icons-material/RocketLaunchRounded')),
    RocketLaunchSharp: React.lazy(() => import('@mui/icons-material/RocketLaunchSharp')),
    RocketLaunchTwoTone: React.lazy(() => import('@mui/icons-material/RocketLaunchTwoTone')),
    RocketOutlined: React.lazy(() => import('@mui/icons-material/RocketOutlined')),
    RocketRounded: React.lazy(() => import('@mui/icons-material/RocketRounded')),
    RocketSharp: React.lazy(() => import('@mui/icons-material/RocketSharp')),
    RocketTwoTone: React.lazy(() => import('@mui/icons-material/RocketTwoTone')),
    RollerShadesClosed: React.lazy(() => import('@mui/icons-material/RollerShadesClosed')),
    RollerShadesClosedOutlined: React.lazy(() => import('@mui/icons-material/RollerShadesClosedOutlined')),
    RollerShadesClosedRounded: React.lazy(() => import('@mui/icons-material/RollerShadesClosedRounded')),
    RollerShadesClosedSharp: React.lazy(() => import('@mui/icons-material/RollerShadesClosedSharp')),
    RollerShadesClosedTwoTone: React.lazy(() => import('@mui/icons-material/RollerShadesClosedTwoTone')),
    RollerShades: React.lazy(() => import('@mui/icons-material/RollerShades')),
    RollerShadesOutlined: React.lazy(() => import('@mui/icons-material/RollerShadesOutlined')),
    RollerShadesRounded: React.lazy(() => import('@mui/icons-material/RollerShadesRounded')),
    RollerShadesSharp: React.lazy(() => import('@mui/icons-material/RollerShadesSharp')),
    RollerShadesTwoTone: React.lazy(() => import('@mui/icons-material/RollerShadesTwoTone')),
    RollerSkating: React.lazy(() => import('@mui/icons-material/RollerSkating')),
    RollerSkatingOutlined: React.lazy(() => import('@mui/icons-material/RollerSkatingOutlined')),
    RollerSkatingRounded: React.lazy(() => import('@mui/icons-material/RollerSkatingRounded')),
    RollerSkatingSharp: React.lazy(() => import('@mui/icons-material/RollerSkatingSharp')),
    RollerSkatingTwoTone: React.lazy(() => import('@mui/icons-material/RollerSkatingTwoTone')),
    Roofing: React.lazy(() => import('@mui/icons-material/Roofing')),
    RoofingOutlined: React.lazy(() => import('@mui/icons-material/RoofingOutlined')),
    RoofingRounded: React.lazy(() => import('@mui/icons-material/RoofingRounded')),
    RoofingSharp: React.lazy(() => import('@mui/icons-material/RoofingSharp')),
    RoofingTwoTone: React.lazy(() => import('@mui/icons-material/RoofingTwoTone')),
    Room: React.lazy(() => import('@mui/icons-material/Room')),
    RoomOutlined: React.lazy(() => import('@mui/icons-material/RoomOutlined')),
    RoomPreferences: React.lazy(() => import('@mui/icons-material/RoomPreferences')),
    RoomPreferencesOutlined: React.lazy(() => import('@mui/icons-material/RoomPreferencesOutlined')),
    RoomPreferencesRounded: React.lazy(() => import('@mui/icons-material/RoomPreferencesRounded')),
    RoomPreferencesSharp: React.lazy(() => import('@mui/icons-material/RoomPreferencesSharp')),
    RoomPreferencesTwoTone: React.lazy(() => import('@mui/icons-material/RoomPreferencesTwoTone')),
    RoomRounded: React.lazy(() => import('@mui/icons-material/RoomRounded')),
    RoomService: React.lazy(() => import('@mui/icons-material/RoomService')),
    RoomServiceOutlined: React.lazy(() => import('@mui/icons-material/RoomServiceOutlined')),
    RoomServiceRounded: React.lazy(() => import('@mui/icons-material/RoomServiceRounded')),
    RoomServiceSharp: React.lazy(() => import('@mui/icons-material/RoomServiceSharp')),
    RoomServiceTwoTone: React.lazy(() => import('@mui/icons-material/RoomServiceTwoTone')),
    RoomSharp: React.lazy(() => import('@mui/icons-material/RoomSharp')),
    RoomTwoTone: React.lazy(() => import('@mui/icons-material/RoomTwoTone')),
    Rotate90DegreesCcw: React.lazy(() => import('@mui/icons-material/Rotate90DegreesCcw')),
    Rotate90DegreesCcwOutlined: React.lazy(() => import('@mui/icons-material/Rotate90DegreesCcwOutlined')),
    Rotate90DegreesCcwRounded: React.lazy(() => import('@mui/icons-material/Rotate90DegreesCcwRounded')),
    Rotate90DegreesCcwSharp: React.lazy(() => import('@mui/icons-material/Rotate90DegreesCcwSharp')),
    Rotate90DegreesCcwTwoTone: React.lazy(() => import('@mui/icons-material/Rotate90DegreesCcwTwoTone')),
    Rotate90DegreesCw: React.lazy(() => import('@mui/icons-material/Rotate90DegreesCw')),
    Rotate90DegreesCwOutlined: React.lazy(() => import('@mui/icons-material/Rotate90DegreesCwOutlined')),
    Rotate90DegreesCwRounded: React.lazy(() => import('@mui/icons-material/Rotate90DegreesCwRounded')),
    Rotate90DegreesCwSharp: React.lazy(() => import('@mui/icons-material/Rotate90DegreesCwSharp')),
    Rotate90DegreesCwTwoTone: React.lazy(() => import('@mui/icons-material/Rotate90DegreesCwTwoTone')),
    RotateLeft: React.lazy(() => import('@mui/icons-material/RotateLeft')),
    RotateLeftOutlined: React.lazy(() => import('@mui/icons-material/RotateLeftOutlined')),
    RotateLeftRounded: React.lazy(() => import('@mui/icons-material/RotateLeftRounded')),
    RotateLeftSharp: React.lazy(() => import('@mui/icons-material/RotateLeftSharp')),
    RotateLeftTwoTone: React.lazy(() => import('@mui/icons-material/RotateLeftTwoTone')),
    RotateRight: React.lazy(() => import('@mui/icons-material/RotateRight')),
    RotateRightOutlined: React.lazy(() => import('@mui/icons-material/RotateRightOutlined')),
    RotateRightRounded: React.lazy(() => import('@mui/icons-material/RotateRightRounded')),
    RotateRightSharp: React.lazy(() => import('@mui/icons-material/RotateRightSharp')),
    RotateRightTwoTone: React.lazy(() => import('@mui/icons-material/RotateRightTwoTone')),
    RoundaboutLeft: React.lazy(() => import('@mui/icons-material/RoundaboutLeft')),
    RoundaboutLeftOutlined: React.lazy(() => import('@mui/icons-material/RoundaboutLeftOutlined')),
    RoundaboutLeftRounded: React.lazy(() => import('@mui/icons-material/RoundaboutLeftRounded')),
    RoundaboutLeftSharp: React.lazy(() => import('@mui/icons-material/RoundaboutLeftSharp')),
    RoundaboutLeftTwoTone: React.lazy(() => import('@mui/icons-material/RoundaboutLeftTwoTone')),
    RoundaboutRight: React.lazy(() => import('@mui/icons-material/RoundaboutRight')),
    RoundaboutRightOutlined: React.lazy(() => import('@mui/icons-material/RoundaboutRightOutlined')),
    RoundaboutRightRounded: React.lazy(() => import('@mui/icons-material/RoundaboutRightRounded')),
    RoundaboutRightSharp: React.lazy(() => import('@mui/icons-material/RoundaboutRightSharp')),
    RoundaboutRightTwoTone: React.lazy(() => import('@mui/icons-material/RoundaboutRightTwoTone')),
    RoundedCorner: React.lazy(() => import('@mui/icons-material/RoundedCorner')),
    RoundedCornerOutlined: React.lazy(() => import('@mui/icons-material/RoundedCornerOutlined')),
    RoundedCornerRounded: React.lazy(() => import('@mui/icons-material/RoundedCornerRounded')),
    RoundedCornerSharp: React.lazy(() => import('@mui/icons-material/RoundedCornerSharp')),
    RoundedCornerTwoTone: React.lazy(() => import('@mui/icons-material/RoundedCornerTwoTone')),
    Route: React.lazy(() => import('@mui/icons-material/Route')),
    RouteOutlined: React.lazy(() => import('@mui/icons-material/RouteOutlined')),
    Router: React.lazy(() => import('@mui/icons-material/Router')),
    RouteRounded: React.lazy(() => import('@mui/icons-material/RouteRounded')),
    RouterOutlined: React.lazy(() => import('@mui/icons-material/RouterOutlined')),
    RouterRounded: React.lazy(() => import('@mui/icons-material/RouterRounded')),
    RouterSharp: React.lazy(() => import('@mui/icons-material/RouterSharp')),
    RouterTwoTone: React.lazy(() => import('@mui/icons-material/RouterTwoTone')),
    RouteSharp: React.lazy(() => import('@mui/icons-material/RouteSharp')),
    RouteTwoTone: React.lazy(() => import('@mui/icons-material/RouteTwoTone')),
    Rowing: React.lazy(() => import('@mui/icons-material/Rowing')),
    RowingOutlined: React.lazy(() => import('@mui/icons-material/RowingOutlined')),
    RowingRounded: React.lazy(() => import('@mui/icons-material/RowingRounded')),
    RowingSharp: React.lazy(() => import('@mui/icons-material/RowingSharp')),
    RowingTwoTone: React.lazy(() => import('@mui/icons-material/RowingTwoTone')),
    RssFeed: React.lazy(() => import('@mui/icons-material/RssFeed')),
    RssFeedOutlined: React.lazy(() => import('@mui/icons-material/RssFeedOutlined')),
    RssFeedRounded: React.lazy(() => import('@mui/icons-material/RssFeedRounded')),
    RssFeedSharp: React.lazy(() => import('@mui/icons-material/RssFeedSharp')),
    RssFeedTwoTone: React.lazy(() => import('@mui/icons-material/RssFeedTwoTone')),
    Rsvp: React.lazy(() => import('@mui/icons-material/Rsvp')),
    RsvpOutlined: React.lazy(() => import('@mui/icons-material/RsvpOutlined')),
    RsvpRounded: React.lazy(() => import('@mui/icons-material/RsvpRounded')),
    RsvpSharp: React.lazy(() => import('@mui/icons-material/RsvpSharp')),
    RsvpTwoTone: React.lazy(() => import('@mui/icons-material/RsvpTwoTone')),
    Rtt: React.lazy(() => import('@mui/icons-material/Rtt')),
    RttOutlined: React.lazy(() => import('@mui/icons-material/RttOutlined')),
    RttRounded: React.lazy(() => import('@mui/icons-material/RttRounded')),
    RttSharp: React.lazy(() => import('@mui/icons-material/RttSharp')),
    RttTwoTone: React.lazy(() => import('@mui/icons-material/RttTwoTone')),
    Rule: React.lazy(() => import('@mui/icons-material/Rule')),
    RuleFolder: React.lazy(() => import('@mui/icons-material/RuleFolder')),
    RuleFolderOutlined: React.lazy(() => import('@mui/icons-material/RuleFolderOutlined')),
    RuleFolderRounded: React.lazy(() => import('@mui/icons-material/RuleFolderRounded')),
    RuleFolderSharp: React.lazy(() => import('@mui/icons-material/RuleFolderSharp')),
    RuleFolderTwoTone: React.lazy(() => import('@mui/icons-material/RuleFolderTwoTone')),
    RuleOutlined: React.lazy(() => import('@mui/icons-material/RuleOutlined')),
    RuleRounded: React.lazy(() => import('@mui/icons-material/RuleRounded')),
    RuleSharp: React.lazy(() => import('@mui/icons-material/RuleSharp')),
    RuleTwoTone: React.lazy(() => import('@mui/icons-material/RuleTwoTone')),
    RunCircle: React.lazy(() => import('@mui/icons-material/RunCircle')),
    RunCircleOutlined: React.lazy(() => import('@mui/icons-material/RunCircleOutlined')),
    RunCircleRounded: React.lazy(() => import('@mui/icons-material/RunCircleRounded')),
    RunCircleSharp: React.lazy(() => import('@mui/icons-material/RunCircleSharp')),
    RunCircleTwoTone: React.lazy(() => import('@mui/icons-material/RunCircleTwoTone')),
    RunningWithErrors: React.lazy(() => import('@mui/icons-material/RunningWithErrors')),
    RunningWithErrorsOutlined: React.lazy(() => import('@mui/icons-material/RunningWithErrorsOutlined')),
    RunningWithErrorsRounded: React.lazy(() => import('@mui/icons-material/RunningWithErrorsRounded')),
    RunningWithErrorsSharp: React.lazy(() => import('@mui/icons-material/RunningWithErrorsSharp')),
    RunningWithErrorsTwoTone: React.lazy(() => import('@mui/icons-material/RunningWithErrorsTwoTone')),
    RvHookup: React.lazy(() => import('@mui/icons-material/RvHookup')),
    RvHookupOutlined: React.lazy(() => import('@mui/icons-material/RvHookupOutlined')),
    RvHookupRounded: React.lazy(() => import('@mui/icons-material/RvHookupRounded')),
    RvHookupSharp: React.lazy(() => import('@mui/icons-material/RvHookupSharp')),
    RvHookupTwoTone: React.lazy(() => import('@mui/icons-material/RvHookupTwoTone')),
    SafetyCheck: React.lazy(() => import('@mui/icons-material/SafetyCheck')),
    SafetyCheckOutlined: React.lazy(() => import('@mui/icons-material/SafetyCheckOutlined')),
    SafetyCheckRounded: React.lazy(() => import('@mui/icons-material/SafetyCheckRounded')),
    SafetyCheckSharp: React.lazy(() => import('@mui/icons-material/SafetyCheckSharp')),
    SafetyCheckTwoTone: React.lazy(() => import('@mui/icons-material/SafetyCheckTwoTone')),
    SafetyDivider: React.lazy(() => import('@mui/icons-material/SafetyDivider')),
    SafetyDividerOutlined: React.lazy(() => import('@mui/icons-material/SafetyDividerOutlined')),
    SafetyDividerRounded: React.lazy(() => import('@mui/icons-material/SafetyDividerRounded')),
    SafetyDividerSharp: React.lazy(() => import('@mui/icons-material/SafetyDividerSharp')),
    SafetyDividerTwoTone: React.lazy(() => import('@mui/icons-material/SafetyDividerTwoTone')),
    Sailing: React.lazy(() => import('@mui/icons-material/Sailing')),
    SailingOutlined: React.lazy(() => import('@mui/icons-material/SailingOutlined')),
    SailingRounded: React.lazy(() => import('@mui/icons-material/SailingRounded')),
    SailingSharp: React.lazy(() => import('@mui/icons-material/SailingSharp')),
    SailingTwoTone: React.lazy(() => import('@mui/icons-material/SailingTwoTone')),
    Sanitizer: React.lazy(() => import('@mui/icons-material/Sanitizer')),
    SanitizerOutlined: React.lazy(() => import('@mui/icons-material/SanitizerOutlined')),
    SanitizerRounded: React.lazy(() => import('@mui/icons-material/SanitizerRounded')),
    SanitizerSharp: React.lazy(() => import('@mui/icons-material/SanitizerSharp')),
    SanitizerTwoTone: React.lazy(() => import('@mui/icons-material/SanitizerTwoTone')),
    SatelliteAlt: React.lazy(() => import('@mui/icons-material/SatelliteAlt')),
    SatelliteAltOutlined: React.lazy(() => import('@mui/icons-material/SatelliteAltOutlined')),
    SatelliteAltRounded: React.lazy(() => import('@mui/icons-material/SatelliteAltRounded')),
    SatelliteAltSharp: React.lazy(() => import('@mui/icons-material/SatelliteAltSharp')),
    SatelliteAltTwoTone: React.lazy(() => import('@mui/icons-material/SatelliteAltTwoTone')),
    Satellite: React.lazy(() => import('@mui/icons-material/Satellite')),
    SatelliteOutlined: React.lazy(() => import('@mui/icons-material/SatelliteOutlined')),
    SatelliteRounded: React.lazy(() => import('@mui/icons-material/SatelliteRounded')),
    SatelliteSharp: React.lazy(() => import('@mui/icons-material/SatelliteSharp')),
    SatelliteTwoTone: React.lazy(() => import('@mui/icons-material/SatelliteTwoTone')),
    SaveAlt: React.lazy(() => import('@mui/icons-material/SaveAlt')),
    SaveAltOutlined: React.lazy(() => import('@mui/icons-material/SaveAltOutlined')),
    SaveAltRounded: React.lazy(() => import('@mui/icons-material/SaveAltRounded')),
    SaveAltSharp: React.lazy(() => import('@mui/icons-material/SaveAltSharp')),
    SaveAltTwoTone: React.lazy(() => import('@mui/icons-material/SaveAltTwoTone')),
    SaveAs: React.lazy(() => import('@mui/icons-material/SaveAs')),
    SaveAsOutlined: React.lazy(() => import('@mui/icons-material/SaveAsOutlined')),
    SaveAsRounded: React.lazy(() => import('@mui/icons-material/SaveAsRounded')),
    SaveAsSharp: React.lazy(() => import('@mui/icons-material/SaveAsSharp')),
    SaveAsTwoTone: React.lazy(() => import('@mui/icons-material/SaveAsTwoTone')),
    SavedSearch: React.lazy(() => import('@mui/icons-material/SavedSearch')),
    SavedSearchOutlined: React.lazy(() => import('@mui/icons-material/SavedSearchOutlined')),
    SavedSearchRounded: React.lazy(() => import('@mui/icons-material/SavedSearchRounded')),
    SavedSearchSharp: React.lazy(() => import('@mui/icons-material/SavedSearchSharp')),
    SavedSearchTwoTone: React.lazy(() => import('@mui/icons-material/SavedSearchTwoTone')),
    Save: React.lazy(() => import('@mui/icons-material/Save')),
    SaveOutlined: React.lazy(() => import('@mui/icons-material/SaveOutlined')),
    SaveRounded: React.lazy(() => import('@mui/icons-material/SaveRounded')),
    SaveSharp: React.lazy(() => import('@mui/icons-material/SaveSharp')),
    SaveTwoTone: React.lazy(() => import('@mui/icons-material/SaveTwoTone')),
    Savings: React.lazy(() => import('@mui/icons-material/Savings')),
    SavingsOutlined: React.lazy(() => import('@mui/icons-material/SavingsOutlined')),
    SavingsRounded: React.lazy(() => import('@mui/icons-material/SavingsRounded')),
    SavingsSharp: React.lazy(() => import('@mui/icons-material/SavingsSharp')),
    SavingsTwoTone: React.lazy(() => import('@mui/icons-material/SavingsTwoTone')),
    Scale: React.lazy(() => import('@mui/icons-material/Scale')),
    ScaleOutlined: React.lazy(() => import('@mui/icons-material/ScaleOutlined')),
    ScaleRounded: React.lazy(() => import('@mui/icons-material/ScaleRounded')),
    ScaleSharp: React.lazy(() => import('@mui/icons-material/ScaleSharp')),
    ScaleTwoTone: React.lazy(() => import('@mui/icons-material/ScaleTwoTone')),
    Scanner: React.lazy(() => import('@mui/icons-material/Scanner')),
    ScannerOutlined: React.lazy(() => import('@mui/icons-material/ScannerOutlined')),
    ScannerRounded: React.lazy(() => import('@mui/icons-material/ScannerRounded')),
    ScannerSharp: React.lazy(() => import('@mui/icons-material/ScannerSharp')),
    ScannerTwoTone: React.lazy(() => import('@mui/icons-material/ScannerTwoTone')),
    ScatterPlot: React.lazy(() => import('@mui/icons-material/ScatterPlot')),
    ScatterPlotOutlined: React.lazy(() => import('@mui/icons-material/ScatterPlotOutlined')),
    ScatterPlotRounded: React.lazy(() => import('@mui/icons-material/ScatterPlotRounded')),
    ScatterPlotSharp: React.lazy(() => import('@mui/icons-material/ScatterPlotSharp')),
    ScatterPlotTwoTone: React.lazy(() => import('@mui/icons-material/ScatterPlotTwoTone')),
    Schedule: React.lazy(() => import('@mui/icons-material/Schedule')),
    ScheduleOutlined: React.lazy(() => import('@mui/icons-material/ScheduleOutlined')),
    ScheduleRounded: React.lazy(() => import('@mui/icons-material/ScheduleRounded')),
    ScheduleSend: React.lazy(() => import('@mui/icons-material/ScheduleSend')),
    ScheduleSendOutlined: React.lazy(() => import('@mui/icons-material/ScheduleSendOutlined')),
    ScheduleSendRounded: React.lazy(() => import('@mui/icons-material/ScheduleSendRounded')),
    ScheduleSendSharp: React.lazy(() => import('@mui/icons-material/ScheduleSendSharp')),
    ScheduleSendTwoTone: React.lazy(() => import('@mui/icons-material/ScheduleSendTwoTone')),
    ScheduleSharp: React.lazy(() => import('@mui/icons-material/ScheduleSharp')),
    ScheduleTwoTone: React.lazy(() => import('@mui/icons-material/ScheduleTwoTone')),
    Schema: React.lazy(() => import('@mui/icons-material/Schema')),
    SchemaOutlined: React.lazy(() => import('@mui/icons-material/SchemaOutlined')),
    SchemaRounded: React.lazy(() => import('@mui/icons-material/SchemaRounded')),
    SchemaSharp: React.lazy(() => import('@mui/icons-material/SchemaSharp')),
    SchemaTwoTone: React.lazy(() => import('@mui/icons-material/SchemaTwoTone')),
    School: React.lazy(() => import('@mui/icons-material/School')),
    SchoolOutlined: React.lazy(() => import('@mui/icons-material/SchoolOutlined')),
    SchoolRounded: React.lazy(() => import('@mui/icons-material/SchoolRounded')),
    SchoolSharp: React.lazy(() => import('@mui/icons-material/SchoolSharp')),
    SchoolTwoTone: React.lazy(() => import('@mui/icons-material/SchoolTwoTone')),
    Science: React.lazy(() => import('@mui/icons-material/Science')),
    ScienceOutlined: React.lazy(() => import('@mui/icons-material/ScienceOutlined')),
    ScienceRounded: React.lazy(() => import('@mui/icons-material/ScienceRounded')),
    ScienceSharp: React.lazy(() => import('@mui/icons-material/ScienceSharp')),
    ScienceTwoTone: React.lazy(() => import('@mui/icons-material/ScienceTwoTone')),
    Scoreboard: React.lazy(() => import('@mui/icons-material/Scoreboard')),
    ScoreboardOutlined: React.lazy(() => import('@mui/icons-material/ScoreboardOutlined')),
    ScoreboardRounded: React.lazy(() => import('@mui/icons-material/ScoreboardRounded')),
    ScoreboardSharp: React.lazy(() => import('@mui/icons-material/ScoreboardSharp')),
    ScoreboardTwoTone: React.lazy(() => import('@mui/icons-material/ScoreboardTwoTone')),
    Score: React.lazy(() => import('@mui/icons-material/Score')),
    ScoreOutlined: React.lazy(() => import('@mui/icons-material/ScoreOutlined')),
    ScoreRounded: React.lazy(() => import('@mui/icons-material/ScoreRounded')),
    ScoreSharp: React.lazy(() => import('@mui/icons-material/ScoreSharp')),
    ScoreTwoTone: React.lazy(() => import('@mui/icons-material/ScoreTwoTone')),
    ScreenLockLandscape: React.lazy(() => import('@mui/icons-material/ScreenLockLandscape')),
    ScreenLockLandscapeOutlined: React.lazy(() => import('@mui/icons-material/ScreenLockLandscapeOutlined')),
    ScreenLockLandscapeRounded: React.lazy(() => import('@mui/icons-material/ScreenLockLandscapeRounded')),
    ScreenLockLandscapeSharp: React.lazy(() => import('@mui/icons-material/ScreenLockLandscapeSharp')),
    ScreenLockLandscapeTwoTone: React.lazy(() => import('@mui/icons-material/ScreenLockLandscapeTwoTone')),
    ScreenLockPortrait: React.lazy(() => import('@mui/icons-material/ScreenLockPortrait')),
    ScreenLockPortraitOutlined: React.lazy(() => import('@mui/icons-material/ScreenLockPortraitOutlined')),
    ScreenLockPortraitRounded: React.lazy(() => import('@mui/icons-material/ScreenLockPortraitRounded')),
    ScreenLockPortraitSharp: React.lazy(() => import('@mui/icons-material/ScreenLockPortraitSharp')),
    ScreenLockPortraitTwoTone: React.lazy(() => import('@mui/icons-material/ScreenLockPortraitTwoTone')),
    ScreenLockRotation: React.lazy(() => import('@mui/icons-material/ScreenLockRotation')),
    ScreenLockRotationOutlined: React.lazy(() => import('@mui/icons-material/ScreenLockRotationOutlined')),
    ScreenLockRotationRounded: React.lazy(() => import('@mui/icons-material/ScreenLockRotationRounded')),
    ScreenLockRotationSharp: React.lazy(() => import('@mui/icons-material/ScreenLockRotationSharp')),
    ScreenLockRotationTwoTone: React.lazy(() => import('@mui/icons-material/ScreenLockRotationTwoTone')),
    ScreenRotationAlt: React.lazy(() => import('@mui/icons-material/ScreenRotationAlt')),
    ScreenRotationAltOutlined: React.lazy(() => import('@mui/icons-material/ScreenRotationAltOutlined')),
    ScreenRotationAltRounded: React.lazy(() => import('@mui/icons-material/ScreenRotationAltRounded')),
    ScreenRotationAltSharp: React.lazy(() => import('@mui/icons-material/ScreenRotationAltSharp')),
    ScreenRotationAltTwoTone: React.lazy(() => import('@mui/icons-material/ScreenRotationAltTwoTone')),
    ScreenRotation: React.lazy(() => import('@mui/icons-material/ScreenRotation')),
    ScreenRotationOutlined: React.lazy(() => import('@mui/icons-material/ScreenRotationOutlined')),
    ScreenRotationRounded: React.lazy(() => import('@mui/icons-material/ScreenRotationRounded')),
    ScreenRotationSharp: React.lazy(() => import('@mui/icons-material/ScreenRotationSharp')),
    ScreenRotationTwoTone: React.lazy(() => import('@mui/icons-material/ScreenRotationTwoTone')),
    ScreenSearchDesktop: React.lazy(() => import('@mui/icons-material/ScreenSearchDesktop')),
    ScreenSearchDesktopOutlined: React.lazy(() => import('@mui/icons-material/ScreenSearchDesktopOutlined')),
    ScreenSearchDesktopRounded: React.lazy(() => import('@mui/icons-material/ScreenSearchDesktopRounded')),
    ScreenSearchDesktopSharp: React.lazy(() => import('@mui/icons-material/ScreenSearchDesktopSharp')),
    ScreenSearchDesktopTwoTone: React.lazy(() => import('@mui/icons-material/ScreenSearchDesktopTwoTone')),
    ScreenShare: React.lazy(() => import('@mui/icons-material/ScreenShare')),
    ScreenShareOutlined: React.lazy(() => import('@mui/icons-material/ScreenShareOutlined')),
    ScreenShareRounded: React.lazy(() => import('@mui/icons-material/ScreenShareRounded')),
    ScreenShareSharp: React.lazy(() => import('@mui/icons-material/ScreenShareSharp')),
    ScreenShareTwoTone: React.lazy(() => import('@mui/icons-material/ScreenShareTwoTone')),
    Screenshot: React.lazy(() => import('@mui/icons-material/Screenshot')),
    ScreenshotMonitor: React.lazy(() => import('@mui/icons-material/ScreenshotMonitor')),
    ScreenshotMonitorOutlined: React.lazy(() => import('@mui/icons-material/ScreenshotMonitorOutlined')),
    ScreenshotMonitorRounded: React.lazy(() => import('@mui/icons-material/ScreenshotMonitorRounded')),
    ScreenshotMonitorSharp: React.lazy(() => import('@mui/icons-material/ScreenshotMonitorSharp')),
    ScreenshotMonitorTwoTone: React.lazy(() => import('@mui/icons-material/ScreenshotMonitorTwoTone')),
    ScreenshotOutlined: React.lazy(() => import('@mui/icons-material/ScreenshotOutlined')),
    ScreenshotRounded: React.lazy(() => import('@mui/icons-material/ScreenshotRounded')),
    ScreenshotSharp: React.lazy(() => import('@mui/icons-material/ScreenshotSharp')),
    ScreenshotTwoTone: React.lazy(() => import('@mui/icons-material/ScreenshotTwoTone')),
    ScubaDiving: React.lazy(() => import('@mui/icons-material/ScubaDiving')),
    ScubaDivingOutlined: React.lazy(() => import('@mui/icons-material/ScubaDivingOutlined')),
    ScubaDivingRounded: React.lazy(() => import('@mui/icons-material/ScubaDivingRounded')),
    ScubaDivingSharp: React.lazy(() => import('@mui/icons-material/ScubaDivingSharp')),
    ScubaDivingTwoTone: React.lazy(() => import('@mui/icons-material/ScubaDivingTwoTone')),
    SdCardAlert: React.lazy(() => import('@mui/icons-material/SdCardAlert')),
    SdCardAlertOutlined: React.lazy(() => import('@mui/icons-material/SdCardAlertOutlined')),
    SdCardAlertRounded: React.lazy(() => import('@mui/icons-material/SdCardAlertRounded')),
    SdCardAlertSharp: React.lazy(() => import('@mui/icons-material/SdCardAlertSharp')),
    SdCardAlertTwoTone: React.lazy(() => import('@mui/icons-material/SdCardAlertTwoTone')),
    SdCard: React.lazy(() => import('@mui/icons-material/SdCard')),
    SdCardOutlined: React.lazy(() => import('@mui/icons-material/SdCardOutlined')),
    SdCardRounded: React.lazy(() => import('@mui/icons-material/SdCardRounded')),
    SdCardSharp: React.lazy(() => import('@mui/icons-material/SdCardSharp')),
    SdCardTwoTone: React.lazy(() => import('@mui/icons-material/SdCardTwoTone')),
    Sd: React.lazy(() => import('@mui/icons-material/Sd')),
    SdOutlined: React.lazy(() => import('@mui/icons-material/SdOutlined')),
    SdRounded: React.lazy(() => import('@mui/icons-material/SdRounded')),
    SdSharp: React.lazy(() => import('@mui/icons-material/SdSharp')),
    SdStorage: React.lazy(() => import('@mui/icons-material/SdStorage')),
    SdStorageOutlined: React.lazy(() => import('@mui/icons-material/SdStorageOutlined')),
    SdStorageRounded: React.lazy(() => import('@mui/icons-material/SdStorageRounded')),
    SdStorageSharp: React.lazy(() => import('@mui/icons-material/SdStorageSharp')),
    SdStorageTwoTone: React.lazy(() => import('@mui/icons-material/SdStorageTwoTone')),
    SdTwoTone: React.lazy(() => import('@mui/icons-material/SdTwoTone')),
    Search: React.lazy(() => import('@mui/icons-material/Search')),
    SearchOff: React.lazy(() => import('@mui/icons-material/SearchOff')),
    SearchOffOutlined: React.lazy(() => import('@mui/icons-material/SearchOffOutlined')),
    SearchOffRounded: React.lazy(() => import('@mui/icons-material/SearchOffRounded')),
    SearchOffSharp: React.lazy(() => import('@mui/icons-material/SearchOffSharp')),
    SearchOffTwoTone: React.lazy(() => import('@mui/icons-material/SearchOffTwoTone')),
    SearchOutlined: React.lazy(() => import('@mui/icons-material/SearchOutlined')),
    SearchRounded: React.lazy(() => import('@mui/icons-material/SearchRounded')),
    SearchSharp: React.lazy(() => import('@mui/icons-material/SearchSharp')),
    SearchTwoTone: React.lazy(() => import('@mui/icons-material/SearchTwoTone')),
    Security: React.lazy(() => import('@mui/icons-material/Security')),
    SecurityOutlined: React.lazy(() => import('@mui/icons-material/SecurityOutlined')),
    SecurityRounded: React.lazy(() => import('@mui/icons-material/SecurityRounded')),
    SecuritySharp: React.lazy(() => import('@mui/icons-material/SecuritySharp')),
    SecurityTwoTone: React.lazy(() => import('@mui/icons-material/SecurityTwoTone')),
    SecurityUpdate: React.lazy(() => import('@mui/icons-material/SecurityUpdate')),
    SecurityUpdateGood: React.lazy(() => import('@mui/icons-material/SecurityUpdateGood')),
    SecurityUpdateGoodOutlined: React.lazy(() => import('@mui/icons-material/SecurityUpdateGoodOutlined')),
    SecurityUpdateGoodRounded: React.lazy(() => import('@mui/icons-material/SecurityUpdateGoodRounded')),
    SecurityUpdateGoodSharp: React.lazy(() => import('@mui/icons-material/SecurityUpdateGoodSharp')),
    SecurityUpdateGoodTwoTone: React.lazy(() => import('@mui/icons-material/SecurityUpdateGoodTwoTone')),
    SecurityUpdateOutlined: React.lazy(() => import('@mui/icons-material/SecurityUpdateOutlined')),
    SecurityUpdateRounded: React.lazy(() => import('@mui/icons-material/SecurityUpdateRounded')),
    SecurityUpdateSharp: React.lazy(() => import('@mui/icons-material/SecurityUpdateSharp')),
    SecurityUpdateTwoTone: React.lazy(() => import('@mui/icons-material/SecurityUpdateTwoTone')),
    SecurityUpdateWarning: React.lazy(() => import('@mui/icons-material/SecurityUpdateWarning')),
    SecurityUpdateWarningOutlined: React.lazy(() => import('@mui/icons-material/SecurityUpdateWarningOutlined')),
    SecurityUpdateWarningRounded: React.lazy(() => import('@mui/icons-material/SecurityUpdateWarningRounded')),
    SecurityUpdateWarningSharp: React.lazy(() => import('@mui/icons-material/SecurityUpdateWarningSharp')),
    SecurityUpdateWarningTwoTone: React.lazy(() => import('@mui/icons-material/SecurityUpdateWarningTwoTone')),
    Segment: React.lazy(() => import('@mui/icons-material/Segment')),
    SegmentOutlined: React.lazy(() => import('@mui/icons-material/SegmentOutlined')),
    SegmentRounded: React.lazy(() => import('@mui/icons-material/SegmentRounded')),
    SegmentSharp: React.lazy(() => import('@mui/icons-material/SegmentSharp')),
    SegmentTwoTone: React.lazy(() => import('@mui/icons-material/SegmentTwoTone')),
    SelectAll: React.lazy(() => import('@mui/icons-material/SelectAll')),
    SelectAllOutlined: React.lazy(() => import('@mui/icons-material/SelectAllOutlined')),
    SelectAllRounded: React.lazy(() => import('@mui/icons-material/SelectAllRounded')),
    SelectAllSharp: React.lazy(() => import('@mui/icons-material/SelectAllSharp')),
    SelectAllTwoTone: React.lazy(() => import('@mui/icons-material/SelectAllTwoTone')),
    SelfImprovement: React.lazy(() => import('@mui/icons-material/SelfImprovement')),
    SelfImprovementOutlined: React.lazy(() => import('@mui/icons-material/SelfImprovementOutlined')),
    SelfImprovementRounded: React.lazy(() => import('@mui/icons-material/SelfImprovementRounded')),
    SelfImprovementSharp: React.lazy(() => import('@mui/icons-material/SelfImprovementSharp')),
    SelfImprovementTwoTone: React.lazy(() => import('@mui/icons-material/SelfImprovementTwoTone')),
    Sell: React.lazy(() => import('@mui/icons-material/Sell')),
    SellOutlined: React.lazy(() => import('@mui/icons-material/SellOutlined')),
    SellRounded: React.lazy(() => import('@mui/icons-material/SellRounded')),
    SellSharp: React.lazy(() => import('@mui/icons-material/SellSharp')),
    SellTwoTone: React.lazy(() => import('@mui/icons-material/SellTwoTone')),
    SendAndArchive: React.lazy(() => import('@mui/icons-material/SendAndArchive')),
    SendAndArchiveOutlined: React.lazy(() => import('@mui/icons-material/SendAndArchiveOutlined')),
    SendAndArchiveRounded: React.lazy(() => import('@mui/icons-material/SendAndArchiveRounded')),
    SendAndArchiveSharp: React.lazy(() => import('@mui/icons-material/SendAndArchiveSharp')),
    SendAndArchiveTwoTone: React.lazy(() => import('@mui/icons-material/SendAndArchiveTwoTone')),
    Send: React.lazy(() => import('@mui/icons-material/Send')),
    SendOutlined: React.lazy(() => import('@mui/icons-material/SendOutlined')),
    SendRounded: React.lazy(() => import('@mui/icons-material/SendRounded')),
    SendSharp: React.lazy(() => import('@mui/icons-material/SendSharp')),
    SendTimeExtension: React.lazy(() => import('@mui/icons-material/SendTimeExtension')),
    SendTimeExtensionOutlined: React.lazy(() => import('@mui/icons-material/SendTimeExtensionOutlined')),
    SendTimeExtensionRounded: React.lazy(() => import('@mui/icons-material/SendTimeExtensionRounded')),
    SendTimeExtensionSharp: React.lazy(() => import('@mui/icons-material/SendTimeExtensionSharp')),
    SendTimeExtensionTwoTone: React.lazy(() => import('@mui/icons-material/SendTimeExtensionTwoTone')),
    SendToMobile: React.lazy(() => import('@mui/icons-material/SendToMobile')),
    SendToMobileOutlined: React.lazy(() => import('@mui/icons-material/SendToMobileOutlined')),
    SendToMobileRounded: React.lazy(() => import('@mui/icons-material/SendToMobileRounded')),
    SendToMobileSharp: React.lazy(() => import('@mui/icons-material/SendToMobileSharp')),
    SendToMobileTwoTone: React.lazy(() => import('@mui/icons-material/SendToMobileTwoTone')),
    SendTwoTone: React.lazy(() => import('@mui/icons-material/SendTwoTone')),
    SensorDoor: React.lazy(() => import('@mui/icons-material/SensorDoor')),
    SensorDoorOutlined: React.lazy(() => import('@mui/icons-material/SensorDoorOutlined')),
    SensorDoorRounded: React.lazy(() => import('@mui/icons-material/SensorDoorRounded')),
    SensorDoorSharp: React.lazy(() => import('@mui/icons-material/SensorDoorSharp')),
    SensorDoorTwoTone: React.lazy(() => import('@mui/icons-material/SensorDoorTwoTone')),
    SensorOccupied: React.lazy(() => import('@mui/icons-material/SensorOccupied')),
    SensorOccupiedOutlined: React.lazy(() => import('@mui/icons-material/SensorOccupiedOutlined')),
    SensorOccupiedRounded: React.lazy(() => import('@mui/icons-material/SensorOccupiedRounded')),
    SensorOccupiedSharp: React.lazy(() => import('@mui/icons-material/SensorOccupiedSharp')),
    SensorOccupiedTwoTone: React.lazy(() => import('@mui/icons-material/SensorOccupiedTwoTone')),
    Sensors: React.lazy(() => import('@mui/icons-material/Sensors')),
    SensorsOff: React.lazy(() => import('@mui/icons-material/SensorsOff')),
    SensorsOffOutlined: React.lazy(() => import('@mui/icons-material/SensorsOffOutlined')),
    SensorsOffRounded: React.lazy(() => import('@mui/icons-material/SensorsOffRounded')),
    SensorsOffSharp: React.lazy(() => import('@mui/icons-material/SensorsOffSharp')),
    SensorsOffTwoTone: React.lazy(() => import('@mui/icons-material/SensorsOffTwoTone')),
    SensorsOutlined: React.lazy(() => import('@mui/icons-material/SensorsOutlined')),
    SensorsRounded: React.lazy(() => import('@mui/icons-material/SensorsRounded')),
    SensorsSharp: React.lazy(() => import('@mui/icons-material/SensorsSharp')),
    SensorsTwoTone: React.lazy(() => import('@mui/icons-material/SensorsTwoTone')),
    SensorWindow: React.lazy(() => import('@mui/icons-material/SensorWindow')),
    SensorWindowOutlined: React.lazy(() => import('@mui/icons-material/SensorWindowOutlined')),
    SensorWindowRounded: React.lazy(() => import('@mui/icons-material/SensorWindowRounded')),
    SensorWindowSharp: React.lazy(() => import('@mui/icons-material/SensorWindowSharp')),
    SensorWindowTwoTone: React.lazy(() => import('@mui/icons-material/SensorWindowTwoTone')),
    SentimentDissatisfied: React.lazy(() => import('@mui/icons-material/SentimentDissatisfied')),
    SentimentDissatisfiedOutlined: React.lazy(() => import('@mui/icons-material/SentimentDissatisfiedOutlined')),
    SentimentDissatisfiedRounded: React.lazy(() => import('@mui/icons-material/SentimentDissatisfiedRounded')),
    SentimentDissatisfiedSharp: React.lazy(() => import('@mui/icons-material/SentimentDissatisfiedSharp')),
    SentimentDissatisfiedTwoTone: React.lazy(() => import('@mui/icons-material/SentimentDissatisfiedTwoTone')),
    SentimentNeutral: React.lazy(() => import('@mui/icons-material/SentimentNeutral')),
    SentimentNeutralOutlined: React.lazy(() => import('@mui/icons-material/SentimentNeutralOutlined')),
    SentimentNeutralRounded: React.lazy(() => import('@mui/icons-material/SentimentNeutralRounded')),
    SentimentNeutralSharp: React.lazy(() => import('@mui/icons-material/SentimentNeutralSharp')),
    SentimentNeutralTwoTone: React.lazy(() => import('@mui/icons-material/SentimentNeutralTwoTone')),
    SentimentSatisfiedAlt: React.lazy(() => import('@mui/icons-material/SentimentSatisfiedAlt')),
    SentimentSatisfiedAltOutlined: React.lazy(() => import('@mui/icons-material/SentimentSatisfiedAltOutlined')),
    SentimentSatisfiedAltRounded: React.lazy(() => import('@mui/icons-material/SentimentSatisfiedAltRounded')),
    SentimentSatisfiedAltSharp: React.lazy(() => import('@mui/icons-material/SentimentSatisfiedAltSharp')),
    SentimentSatisfiedAltTwoTone: React.lazy(() => import('@mui/icons-material/SentimentSatisfiedAltTwoTone')),
    SentimentSatisfied: React.lazy(() => import('@mui/icons-material/SentimentSatisfied')),
    SentimentSatisfiedOutlined: React.lazy(() => import('@mui/icons-material/SentimentSatisfiedOutlined')),
    SentimentSatisfiedRounded: React.lazy(() => import('@mui/icons-material/SentimentSatisfiedRounded')),
    SentimentSatisfiedSharp: React.lazy(() => import('@mui/icons-material/SentimentSatisfiedSharp')),
    SentimentSatisfiedTwoTone: React.lazy(() => import('@mui/icons-material/SentimentSatisfiedTwoTone')),
    SentimentVeryDissatisfied: React.lazy(() => import('@mui/icons-material/SentimentVeryDissatisfied')),
    SentimentVeryDissatisfiedOutlined: React.lazy(() => import('@mui/icons-material/SentimentVeryDissatisfiedOutlined')),
    SentimentVeryDissatisfiedRounded: React.lazy(() => import('@mui/icons-material/SentimentVeryDissatisfiedRounded')),
    SentimentVeryDissatisfiedSharp: React.lazy(() => import('@mui/icons-material/SentimentVeryDissatisfiedSharp')),
    SentimentVeryDissatisfiedTwoTone: React.lazy(() => import('@mui/icons-material/SentimentVeryDissatisfiedTwoTone')),
    SentimentVerySatisfied: React.lazy(() => import('@mui/icons-material/SentimentVerySatisfied')),
    SentimentVerySatisfiedOutlined: React.lazy(() => import('@mui/icons-material/SentimentVerySatisfiedOutlined')),
    SentimentVerySatisfiedRounded: React.lazy(() => import('@mui/icons-material/SentimentVerySatisfiedRounded')),
    SentimentVerySatisfiedSharp: React.lazy(() => import('@mui/icons-material/SentimentVerySatisfiedSharp')),
    SentimentVerySatisfiedTwoTone: React.lazy(() => import('@mui/icons-material/SentimentVerySatisfiedTwoTone')),
    SetMeal: React.lazy(() => import('@mui/icons-material/SetMeal')),
    SetMealOutlined: React.lazy(() => import('@mui/icons-material/SetMealOutlined')),
    SetMealRounded: React.lazy(() => import('@mui/icons-material/SetMealRounded')),
    SetMealSharp: React.lazy(() => import('@mui/icons-material/SetMealSharp')),
    SetMealTwoTone: React.lazy(() => import('@mui/icons-material/SetMealTwoTone')),
    SettingsAccessibility: React.lazy(() => import('@mui/icons-material/SettingsAccessibility')),
    SettingsAccessibilityOutlined: React.lazy(() => import('@mui/icons-material/SettingsAccessibilityOutlined')),
    SettingsAccessibilityRounded: React.lazy(() => import('@mui/icons-material/SettingsAccessibilityRounded')),
    SettingsAccessibilitySharp: React.lazy(() => import('@mui/icons-material/SettingsAccessibilitySharp')),
    SettingsAccessibilityTwoTone: React.lazy(() => import('@mui/icons-material/SettingsAccessibilityTwoTone')),
    SettingsApplications: React.lazy(() => import('@mui/icons-material/SettingsApplications')),
    SettingsApplicationsOutlined: React.lazy(() => import('@mui/icons-material/SettingsApplicationsOutlined')),
    SettingsApplicationsRounded: React.lazy(() => import('@mui/icons-material/SettingsApplicationsRounded')),
    SettingsApplicationsSharp: React.lazy(() => import('@mui/icons-material/SettingsApplicationsSharp')),
    SettingsApplicationsTwoTone: React.lazy(() => import('@mui/icons-material/SettingsApplicationsTwoTone')),
    SettingsBackupRestore: React.lazy(() => import('@mui/icons-material/SettingsBackupRestore')),
    SettingsBackupRestoreOutlined: React.lazy(() => import('@mui/icons-material/SettingsBackupRestoreOutlined')),
    SettingsBackupRestoreRounded: React.lazy(() => import('@mui/icons-material/SettingsBackupRestoreRounded')),
    SettingsBackupRestoreSharp: React.lazy(() => import('@mui/icons-material/SettingsBackupRestoreSharp')),
    SettingsBackupRestoreTwoTone: React.lazy(() => import('@mui/icons-material/SettingsBackupRestoreTwoTone')),
    SettingsBluetooth: React.lazy(() => import('@mui/icons-material/SettingsBluetooth')),
    SettingsBluetoothOutlined: React.lazy(() => import('@mui/icons-material/SettingsBluetoothOutlined')),
    SettingsBluetoothRounded: React.lazy(() => import('@mui/icons-material/SettingsBluetoothRounded')),
    SettingsBluetoothSharp: React.lazy(() => import('@mui/icons-material/SettingsBluetoothSharp')),
    SettingsBluetoothTwoTone: React.lazy(() => import('@mui/icons-material/SettingsBluetoothTwoTone')),
    SettingsBrightness: React.lazy(() => import('@mui/icons-material/SettingsBrightness')),
    SettingsBrightnessOutlined: React.lazy(() => import('@mui/icons-material/SettingsBrightnessOutlined')),
    SettingsBrightnessRounded: React.lazy(() => import('@mui/icons-material/SettingsBrightnessRounded')),
    SettingsBrightnessSharp: React.lazy(() => import('@mui/icons-material/SettingsBrightnessSharp')),
    SettingsBrightnessTwoTone: React.lazy(() => import('@mui/icons-material/SettingsBrightnessTwoTone')),
    SettingsCell: React.lazy(() => import('@mui/icons-material/SettingsCell')),
    SettingsCellOutlined: React.lazy(() => import('@mui/icons-material/SettingsCellOutlined')),
    SettingsCellRounded: React.lazy(() => import('@mui/icons-material/SettingsCellRounded')),
    SettingsCellSharp: React.lazy(() => import('@mui/icons-material/SettingsCellSharp')),
    SettingsCellTwoTone: React.lazy(() => import('@mui/icons-material/SettingsCellTwoTone')),
    Settings: React.lazy(() => import('@mui/icons-material/Settings')),
    SettingsEthernet: React.lazy(() => import('@mui/icons-material/SettingsEthernet')),
    SettingsEthernetOutlined: React.lazy(() => import('@mui/icons-material/SettingsEthernetOutlined')),
    SettingsEthernetRounded: React.lazy(() => import('@mui/icons-material/SettingsEthernetRounded')),
    SettingsEthernetSharp: React.lazy(() => import('@mui/icons-material/SettingsEthernetSharp')),
    SettingsEthernetTwoTone: React.lazy(() => import('@mui/icons-material/SettingsEthernetTwoTone')),
    SettingsInputAntenna: React.lazy(() => import('@mui/icons-material/SettingsInputAntenna')),
    SettingsInputAntennaOutlined: React.lazy(() => import('@mui/icons-material/SettingsInputAntennaOutlined')),
    SettingsInputAntennaRounded: React.lazy(() => import('@mui/icons-material/SettingsInputAntennaRounded')),
    SettingsInputAntennaSharp: React.lazy(() => import('@mui/icons-material/SettingsInputAntennaSharp')),
    SettingsInputAntennaTwoTone: React.lazy(() => import('@mui/icons-material/SettingsInputAntennaTwoTone')),
    SettingsInputComponent: React.lazy(() => import('@mui/icons-material/SettingsInputComponent')),
    SettingsInputComponentOutlined: React.lazy(() => import('@mui/icons-material/SettingsInputComponentOutlined')),
    SettingsInputComponentRounded: React.lazy(() => import('@mui/icons-material/SettingsInputComponentRounded')),
    SettingsInputComponentSharp: React.lazy(() => import('@mui/icons-material/SettingsInputComponentSharp')),
    SettingsInputComponentTwoTone: React.lazy(() => import('@mui/icons-material/SettingsInputComponentTwoTone')),
    SettingsInputComposite: React.lazy(() => import('@mui/icons-material/SettingsInputComposite')),
    SettingsInputCompositeOutlined: React.lazy(() => import('@mui/icons-material/SettingsInputCompositeOutlined')),
    SettingsInputCompositeRounded: React.lazy(() => import('@mui/icons-material/SettingsInputCompositeRounded')),
    SettingsInputCompositeSharp: React.lazy(() => import('@mui/icons-material/SettingsInputCompositeSharp')),
    SettingsInputCompositeTwoTone: React.lazy(() => import('@mui/icons-material/SettingsInputCompositeTwoTone')),
    SettingsInputHdmi: React.lazy(() => import('@mui/icons-material/SettingsInputHdmi')),
    SettingsInputHdmiOutlined: React.lazy(() => import('@mui/icons-material/SettingsInputHdmiOutlined')),
    SettingsInputHdmiRounded: React.lazy(() => import('@mui/icons-material/SettingsInputHdmiRounded')),
    SettingsInputHdmiSharp: React.lazy(() => import('@mui/icons-material/SettingsInputHdmiSharp')),
    SettingsInputHdmiTwoTone: React.lazy(() => import('@mui/icons-material/SettingsInputHdmiTwoTone')),
    SettingsInputSvideo: React.lazy(() => import('@mui/icons-material/SettingsInputSvideo')),
    SettingsInputSvideoOutlined: React.lazy(() => import('@mui/icons-material/SettingsInputSvideoOutlined')),
    SettingsInputSvideoRounded: React.lazy(() => import('@mui/icons-material/SettingsInputSvideoRounded')),
    SettingsInputSvideoSharp: React.lazy(() => import('@mui/icons-material/SettingsInputSvideoSharp')),
    SettingsInputSvideoTwoTone: React.lazy(() => import('@mui/icons-material/SettingsInputSvideoTwoTone')),
    SettingsOutlined: React.lazy(() => import('@mui/icons-material/SettingsOutlined')),
    SettingsOverscan: React.lazy(() => import('@mui/icons-material/SettingsOverscan')),
    SettingsOverscanOutlined: React.lazy(() => import('@mui/icons-material/SettingsOverscanOutlined')),
    SettingsOverscanRounded: React.lazy(() => import('@mui/icons-material/SettingsOverscanRounded')),
    SettingsOverscanSharp: React.lazy(() => import('@mui/icons-material/SettingsOverscanSharp')),
    SettingsOverscanTwoTone: React.lazy(() => import('@mui/icons-material/SettingsOverscanTwoTone')),
    SettingsPhone: React.lazy(() => import('@mui/icons-material/SettingsPhone')),
    SettingsPhoneOutlined: React.lazy(() => import('@mui/icons-material/SettingsPhoneOutlined')),
    SettingsPhoneRounded: React.lazy(() => import('@mui/icons-material/SettingsPhoneRounded')),
    SettingsPhoneSharp: React.lazy(() => import('@mui/icons-material/SettingsPhoneSharp')),
    SettingsPhoneTwoTone: React.lazy(() => import('@mui/icons-material/SettingsPhoneTwoTone')),
    SettingsPower: React.lazy(() => import('@mui/icons-material/SettingsPower')),
    SettingsPowerOutlined: React.lazy(() => import('@mui/icons-material/SettingsPowerOutlined')),
    SettingsPowerRounded: React.lazy(() => import('@mui/icons-material/SettingsPowerRounded')),
    SettingsPowerSharp: React.lazy(() => import('@mui/icons-material/SettingsPowerSharp')),
    SettingsPowerTwoTone: React.lazy(() => import('@mui/icons-material/SettingsPowerTwoTone')),
    SettingsRemote: React.lazy(() => import('@mui/icons-material/SettingsRemote')),
    SettingsRemoteOutlined: React.lazy(() => import('@mui/icons-material/SettingsRemoteOutlined')),
    SettingsRemoteRounded: React.lazy(() => import('@mui/icons-material/SettingsRemoteRounded')),
    SettingsRemoteSharp: React.lazy(() => import('@mui/icons-material/SettingsRemoteSharp')),
    SettingsRemoteTwoTone: React.lazy(() => import('@mui/icons-material/SettingsRemoteTwoTone')),
    SettingsRounded: React.lazy(() => import('@mui/icons-material/SettingsRounded')),
    SettingsSharp: React.lazy(() => import('@mui/icons-material/SettingsSharp')),
    SettingsSuggest: React.lazy(() => import('@mui/icons-material/SettingsSuggest')),
    SettingsSuggestOutlined: React.lazy(() => import('@mui/icons-material/SettingsSuggestOutlined')),
    SettingsSuggestRounded: React.lazy(() => import('@mui/icons-material/SettingsSuggestRounded')),
    SettingsSuggestSharp: React.lazy(() => import('@mui/icons-material/SettingsSuggestSharp')),
    SettingsSuggestTwoTone: React.lazy(() => import('@mui/icons-material/SettingsSuggestTwoTone')),
    SettingsSystemDaydream: React.lazy(() => import('@mui/icons-material/SettingsSystemDaydream')),
    SettingsSystemDaydreamOutlined: React.lazy(() => import('@mui/icons-material/SettingsSystemDaydreamOutlined')),
    SettingsSystemDaydreamRounded: React.lazy(() => import('@mui/icons-material/SettingsSystemDaydreamRounded')),
    SettingsSystemDaydreamSharp: React.lazy(() => import('@mui/icons-material/SettingsSystemDaydreamSharp')),
    SettingsSystemDaydreamTwoTone: React.lazy(() => import('@mui/icons-material/SettingsSystemDaydreamTwoTone')),
    SettingsTwoTone: React.lazy(() => import('@mui/icons-material/SettingsTwoTone')),
    SettingsVoice: React.lazy(() => import('@mui/icons-material/SettingsVoice')),
    SettingsVoiceOutlined: React.lazy(() => import('@mui/icons-material/SettingsVoiceOutlined')),
    SettingsVoiceRounded: React.lazy(() => import('@mui/icons-material/SettingsVoiceRounded')),
    SettingsVoiceSharp: React.lazy(() => import('@mui/icons-material/SettingsVoiceSharp')),
    SettingsVoiceTwoTone: React.lazy(() => import('@mui/icons-material/SettingsVoiceTwoTone')),
    SevenK: React.lazy(() => import('@mui/icons-material/SevenK')),
    SevenKOutlined: React.lazy(() => import('@mui/icons-material/SevenKOutlined')),
    SevenKPlus: React.lazy(() => import('@mui/icons-material/SevenKPlus')),
    SevenKPlusOutlined: React.lazy(() => import('@mui/icons-material/SevenKPlusOutlined')),
    SevenKPlusRounded: React.lazy(() => import('@mui/icons-material/SevenKPlusRounded')),
    SevenKPlusSharp: React.lazy(() => import('@mui/icons-material/SevenKPlusSharp')),
    SevenKPlusTwoTone: React.lazy(() => import('@mui/icons-material/SevenKPlusTwoTone')),
    SevenKRounded: React.lazy(() => import('@mui/icons-material/SevenKRounded')),
    SevenKSharp: React.lazy(() => import('@mui/icons-material/SevenKSharp')),
    SevenKTwoTone: React.lazy(() => import('@mui/icons-material/SevenKTwoTone')),
    SevenMp: React.lazy(() => import('@mui/icons-material/SevenMp')),
    SevenMpOutlined: React.lazy(() => import('@mui/icons-material/SevenMpOutlined')),
    SevenMpRounded: React.lazy(() => import('@mui/icons-material/SevenMpRounded')),
    SevenMpSharp: React.lazy(() => import('@mui/icons-material/SevenMpSharp')),
    SevenMpTwoTone: React.lazy(() => import('@mui/icons-material/SevenMpTwoTone')),
    SeventeenMp: React.lazy(() => import('@mui/icons-material/SeventeenMp')),
    SeventeenMpOutlined: React.lazy(() => import('@mui/icons-material/SeventeenMpOutlined')),
    SeventeenMpRounded: React.lazy(() => import('@mui/icons-material/SeventeenMpRounded')),
    SeventeenMpSharp: React.lazy(() => import('@mui/icons-material/SeventeenMpSharp')),
    SeventeenMpTwoTone: React.lazy(() => import('@mui/icons-material/SeventeenMpTwoTone')),
    SevereCold: React.lazy(() => import('@mui/icons-material/SevereCold')),
    SevereColdOutlined: React.lazy(() => import('@mui/icons-material/SevereColdOutlined')),
    SevereColdRounded: React.lazy(() => import('@mui/icons-material/SevereColdRounded')),
    SevereColdSharp: React.lazy(() => import('@mui/icons-material/SevereColdSharp')),
    SevereColdTwoTone: React.lazy(() => import('@mui/icons-material/SevereColdTwoTone')),
    ShapeLine: React.lazy(() => import('@mui/icons-material/ShapeLine')),
    ShapeLineOutlined: React.lazy(() => import('@mui/icons-material/ShapeLineOutlined')),
    ShapeLineRounded: React.lazy(() => import('@mui/icons-material/ShapeLineRounded')),
    ShapeLineSharp: React.lazy(() => import('@mui/icons-material/ShapeLineSharp')),
    ShapeLineTwoTone: React.lazy(() => import('@mui/icons-material/ShapeLineTwoTone')),
    Share: React.lazy(() => import('@mui/icons-material/Share')),
    ShareLocation: React.lazy(() => import('@mui/icons-material/ShareLocation')),
    ShareLocationOutlined: React.lazy(() => import('@mui/icons-material/ShareLocationOutlined')),
    ShareLocationRounded: React.lazy(() => import('@mui/icons-material/ShareLocationRounded')),
    ShareLocationSharp: React.lazy(() => import('@mui/icons-material/ShareLocationSharp')),
    ShareLocationTwoTone: React.lazy(() => import('@mui/icons-material/ShareLocationTwoTone')),
    ShareOutlined: React.lazy(() => import('@mui/icons-material/ShareOutlined')),
    ShareRounded: React.lazy(() => import('@mui/icons-material/ShareRounded')),
    ShareSharp: React.lazy(() => import('@mui/icons-material/ShareSharp')),
    ShareTwoTone: React.lazy(() => import('@mui/icons-material/ShareTwoTone')),
    Shield: React.lazy(() => import('@mui/icons-material/Shield')),
    ShieldMoon: React.lazy(() => import('@mui/icons-material/ShieldMoon')),
    ShieldMoonOutlined: React.lazy(() => import('@mui/icons-material/ShieldMoonOutlined')),
    ShieldMoonRounded: React.lazy(() => import('@mui/icons-material/ShieldMoonRounded')),
    ShieldMoonSharp: React.lazy(() => import('@mui/icons-material/ShieldMoonSharp')),
    ShieldMoonTwoTone: React.lazy(() => import('@mui/icons-material/ShieldMoonTwoTone')),
    ShieldOutlined: React.lazy(() => import('@mui/icons-material/ShieldOutlined')),
    ShieldRounded: React.lazy(() => import('@mui/icons-material/ShieldRounded')),
    ShieldSharp: React.lazy(() => import('@mui/icons-material/ShieldSharp')),
    ShieldTwoTone: React.lazy(() => import('@mui/icons-material/ShieldTwoTone')),
    Shop2: React.lazy(() => import('@mui/icons-material/Shop2')),
    Shop2Outlined: React.lazy(() => import('@mui/icons-material/Shop2Outlined')),
    Shop2Rounded: React.lazy(() => import('@mui/icons-material/Shop2Rounded')),
    Shop2Sharp: React.lazy(() => import('@mui/icons-material/Shop2Sharp')),
    Shop2TwoTone: React.lazy(() => import('@mui/icons-material/Shop2TwoTone')),
    Shop: React.lazy(() => import('@mui/icons-material/Shop')),
    ShopOutlined: React.lazy(() => import('@mui/icons-material/ShopOutlined')),
    ShoppingBag: React.lazy(() => import('@mui/icons-material/ShoppingBag')),
    ShoppingBagOutlined: React.lazy(() => import('@mui/icons-material/ShoppingBagOutlined')),
    ShoppingBagRounded: React.lazy(() => import('@mui/icons-material/ShoppingBagRounded')),
    ShoppingBagSharp: React.lazy(() => import('@mui/icons-material/ShoppingBagSharp')),
    ShoppingBagTwoTone: React.lazy(() => import('@mui/icons-material/ShoppingBagTwoTone')),
    ShoppingBasket: React.lazy(() => import('@mui/icons-material/ShoppingBasket')),
    ShoppingBasketOutlined: React.lazy(() => import('@mui/icons-material/ShoppingBasketOutlined')),
    ShoppingBasketRounded: React.lazy(() => import('@mui/icons-material/ShoppingBasketRounded')),
    ShoppingBasketSharp: React.lazy(() => import('@mui/icons-material/ShoppingBasketSharp')),
    ShoppingBasketTwoTone: React.lazy(() => import('@mui/icons-material/ShoppingBasketTwoTone')),
    ShoppingCartCheckout: React.lazy(() => import('@mui/icons-material/ShoppingCartCheckout')),
    ShoppingCartCheckoutOutlined: React.lazy(() => import('@mui/icons-material/ShoppingCartCheckoutOutlined')),
    ShoppingCartCheckoutRounded: React.lazy(() => import('@mui/icons-material/ShoppingCartCheckoutRounded')),
    ShoppingCartCheckoutSharp: React.lazy(() => import('@mui/icons-material/ShoppingCartCheckoutSharp')),
    ShoppingCartCheckoutTwoTone: React.lazy(() => import('@mui/icons-material/ShoppingCartCheckoutTwoTone')),
    ShoppingCart: React.lazy(() => import('@mui/icons-material/ShoppingCart')),
    ShoppingCartOutlined: React.lazy(() => import('@mui/icons-material/ShoppingCartOutlined')),
    ShoppingCartRounded: React.lazy(() => import('@mui/icons-material/ShoppingCartRounded')),
    ShoppingCartSharp: React.lazy(() => import('@mui/icons-material/ShoppingCartSharp')),
    ShoppingCartTwoTone: React.lazy(() => import('@mui/icons-material/ShoppingCartTwoTone')),
    ShopRounded: React.lazy(() => import('@mui/icons-material/ShopRounded')),
    ShopSharp: React.lazy(() => import('@mui/icons-material/ShopSharp')),
    ShopTwo: React.lazy(() => import('@mui/icons-material/ShopTwo')),
    ShopTwoOutlined: React.lazy(() => import('@mui/icons-material/ShopTwoOutlined')),
    ShopTwoRounded: React.lazy(() => import('@mui/icons-material/ShopTwoRounded')),
    ShopTwoSharp: React.lazy(() => import('@mui/icons-material/ShopTwoSharp')),
    ShopTwoTone: React.lazy(() => import('@mui/icons-material/ShopTwoTone')),
    ShopTwoTwoTone: React.lazy(() => import('@mui/icons-material/ShopTwoTwoTone')),
    Shortcut: React.lazy(() => import('@mui/icons-material/Shortcut')),
    ShortcutOutlined: React.lazy(() => import('@mui/icons-material/ShortcutOutlined')),
    ShortcutRounded: React.lazy(() => import('@mui/icons-material/ShortcutRounded')),
    ShortcutSharp: React.lazy(() => import('@mui/icons-material/ShortcutSharp')),
    ShortcutTwoTone: React.lazy(() => import('@mui/icons-material/ShortcutTwoTone')),
    ShortText: React.lazy(() => import('@mui/icons-material/ShortText')),
    ShortTextOutlined: React.lazy(() => import('@mui/icons-material/ShortTextOutlined')),
    ShortTextRounded: React.lazy(() => import('@mui/icons-material/ShortTextRounded')),
    ShortTextSharp: React.lazy(() => import('@mui/icons-material/ShortTextSharp')),
    ShortTextTwoTone: React.lazy(() => import('@mui/icons-material/ShortTextTwoTone')),
    ShowChart: React.lazy(() => import('@mui/icons-material/ShowChart')),
    ShowChartOutlined: React.lazy(() => import('@mui/icons-material/ShowChartOutlined')),
    ShowChartRounded: React.lazy(() => import('@mui/icons-material/ShowChartRounded')),
    ShowChartSharp: React.lazy(() => import('@mui/icons-material/ShowChartSharp')),
    ShowChartTwoTone: React.lazy(() => import('@mui/icons-material/ShowChartTwoTone')),
    Shower: React.lazy(() => import('@mui/icons-material/Shower')),
    ShowerOutlined: React.lazy(() => import('@mui/icons-material/ShowerOutlined')),
    ShowerRounded: React.lazy(() => import('@mui/icons-material/ShowerRounded')),
    ShowerSharp: React.lazy(() => import('@mui/icons-material/ShowerSharp')),
    ShowerTwoTone: React.lazy(() => import('@mui/icons-material/ShowerTwoTone')),
    Shuffle: React.lazy(() => import('@mui/icons-material/Shuffle')),
    ShuffleOn: React.lazy(() => import('@mui/icons-material/ShuffleOn')),
    ShuffleOnOutlined: React.lazy(() => import('@mui/icons-material/ShuffleOnOutlined')),
    ShuffleOnRounded: React.lazy(() => import('@mui/icons-material/ShuffleOnRounded')),
    ShuffleOnSharp: React.lazy(() => import('@mui/icons-material/ShuffleOnSharp')),
    ShuffleOnTwoTone: React.lazy(() => import('@mui/icons-material/ShuffleOnTwoTone')),
    ShuffleOutlined: React.lazy(() => import('@mui/icons-material/ShuffleOutlined')),
    ShuffleRounded: React.lazy(() => import('@mui/icons-material/ShuffleRounded')),
    ShuffleSharp: React.lazy(() => import('@mui/icons-material/ShuffleSharp')),
    ShuffleTwoTone: React.lazy(() => import('@mui/icons-material/ShuffleTwoTone')),
    ShutterSpeed: React.lazy(() => import('@mui/icons-material/ShutterSpeed')),
    ShutterSpeedOutlined: React.lazy(() => import('@mui/icons-material/ShutterSpeedOutlined')),
    ShutterSpeedRounded: React.lazy(() => import('@mui/icons-material/ShutterSpeedRounded')),
    ShutterSpeedSharp: React.lazy(() => import('@mui/icons-material/ShutterSpeedSharp')),
    ShutterSpeedTwoTone: React.lazy(() => import('@mui/icons-material/ShutterSpeedTwoTone')),
    Sick: React.lazy(() => import('@mui/icons-material/Sick')),
    SickOutlined: React.lazy(() => import('@mui/icons-material/SickOutlined')),
    SickRounded: React.lazy(() => import('@mui/icons-material/SickRounded')),
    SickSharp: React.lazy(() => import('@mui/icons-material/SickSharp')),
    SickTwoTone: React.lazy(() => import('@mui/icons-material/SickTwoTone')),
    SignalCellular0Bar: React.lazy(() => import('@mui/icons-material/SignalCellular0Bar')),
    SignalCellular0BarOutlined: React.lazy(() => import('@mui/icons-material/SignalCellular0BarOutlined')),
    SignalCellular0BarRounded: React.lazy(() => import('@mui/icons-material/SignalCellular0BarRounded')),
    SignalCellular0BarSharp: React.lazy(() => import('@mui/icons-material/SignalCellular0BarSharp')),
    SignalCellular0BarTwoTone: React.lazy(() => import('@mui/icons-material/SignalCellular0BarTwoTone')),
    SignalCellular1Bar: React.lazy(() => import('@mui/icons-material/SignalCellular1Bar')),
    SignalCellular1BarOutlined: React.lazy(() => import('@mui/icons-material/SignalCellular1BarOutlined')),
    SignalCellular1BarRounded: React.lazy(() => import('@mui/icons-material/SignalCellular1BarRounded')),
    SignalCellular1BarSharp: React.lazy(() => import('@mui/icons-material/SignalCellular1BarSharp')),
    SignalCellular1BarTwoTone: React.lazy(() => import('@mui/icons-material/SignalCellular1BarTwoTone')),
    SignalCellular2Bar: React.lazy(() => import('@mui/icons-material/SignalCellular2Bar')),
    SignalCellular2BarOutlined: React.lazy(() => import('@mui/icons-material/SignalCellular2BarOutlined')),
    SignalCellular2BarRounded: React.lazy(() => import('@mui/icons-material/SignalCellular2BarRounded')),
    SignalCellular2BarSharp: React.lazy(() => import('@mui/icons-material/SignalCellular2BarSharp')),
    SignalCellular2BarTwoTone: React.lazy(() => import('@mui/icons-material/SignalCellular2BarTwoTone')),
    SignalCellular3Bar: React.lazy(() => import('@mui/icons-material/SignalCellular3Bar')),
    SignalCellular3BarOutlined: React.lazy(() => import('@mui/icons-material/SignalCellular3BarOutlined')),
    SignalCellular3BarRounded: React.lazy(() => import('@mui/icons-material/SignalCellular3BarRounded')),
    SignalCellular3BarSharp: React.lazy(() => import('@mui/icons-material/SignalCellular3BarSharp')),
    SignalCellular3BarTwoTone: React.lazy(() => import('@mui/icons-material/SignalCellular3BarTwoTone')),
    SignalCellular4Bar: React.lazy(() => import('@mui/icons-material/SignalCellular4Bar')),
    SignalCellular4BarOutlined: React.lazy(() => import('@mui/icons-material/SignalCellular4BarOutlined')),
    SignalCellular4BarRounded: React.lazy(() => import('@mui/icons-material/SignalCellular4BarRounded')),
    SignalCellular4BarSharp: React.lazy(() => import('@mui/icons-material/SignalCellular4BarSharp')),
    SignalCellular4BarTwoTone: React.lazy(() => import('@mui/icons-material/SignalCellular4BarTwoTone')),
    SignalCellularAlt1Bar: React.lazy(() => import('@mui/icons-material/SignalCellularAlt1Bar')),
    SignalCellularAlt1BarOutlined: React.lazy(() => import('@mui/icons-material/SignalCellularAlt1BarOutlined')),
    SignalCellularAlt1BarRounded: React.lazy(() => import('@mui/icons-material/SignalCellularAlt1BarRounded')),
    SignalCellularAlt1BarSharp: React.lazy(() => import('@mui/icons-material/SignalCellularAlt1BarSharp')),
    SignalCellularAlt1BarTwoTone: React.lazy(() => import('@mui/icons-material/SignalCellularAlt1BarTwoTone')),
    SignalCellularAlt2Bar: React.lazy(() => import('@mui/icons-material/SignalCellularAlt2Bar')),
    SignalCellularAlt2BarOutlined: React.lazy(() => import('@mui/icons-material/SignalCellularAlt2BarOutlined')),
    SignalCellularAlt2BarRounded: React.lazy(() => import('@mui/icons-material/SignalCellularAlt2BarRounded')),
    SignalCellularAlt2BarSharp: React.lazy(() => import('@mui/icons-material/SignalCellularAlt2BarSharp')),
    SignalCellularAlt2BarTwoTone: React.lazy(() => import('@mui/icons-material/SignalCellularAlt2BarTwoTone')),
    SignalCellularAlt: React.lazy(() => import('@mui/icons-material/SignalCellularAlt')),
    SignalCellularAltOutlined: React.lazy(() => import('@mui/icons-material/SignalCellularAltOutlined')),
    SignalCellularAltRounded: React.lazy(() => import('@mui/icons-material/SignalCellularAltRounded')),
    SignalCellularAltSharp: React.lazy(() => import('@mui/icons-material/SignalCellularAltSharp')),
    SignalCellularAltTwoTone: React.lazy(() => import('@mui/icons-material/SignalCellularAltTwoTone')),
    SignalCellularConnectedNoInternet0Bar: React.lazy(() => import('@mui/icons-material/SignalCellularConnectedNoInternet0Bar')),
    SignalCellularConnectedNoInternet0BarOutlined: React.lazy(() => import('@mui/icons-material/SignalCellularConnectedNoInternet0BarOutlined')),
    SignalCellularConnectedNoInternet0BarRounded: React.lazy(() => import('@mui/icons-material/SignalCellularConnectedNoInternet0BarRounded')),
    SignalCellularConnectedNoInternet0BarSharp: React.lazy(() => import('@mui/icons-material/SignalCellularConnectedNoInternet0BarSharp')),
    SignalCellularConnectedNoInternet0BarTwoTone: React.lazy(() => import('@mui/icons-material/SignalCellularConnectedNoInternet0BarTwoTone')),
    SignalCellularConnectedNoInternet1Bar: React.lazy(() => import('@mui/icons-material/SignalCellularConnectedNoInternet1Bar')),
    SignalCellularConnectedNoInternet1BarOutlined: React.lazy(() => import('@mui/icons-material/SignalCellularConnectedNoInternet1BarOutlined')),
    SignalCellularConnectedNoInternet1BarRounded: React.lazy(() => import('@mui/icons-material/SignalCellularConnectedNoInternet1BarRounded')),
    SignalCellularConnectedNoInternet1BarSharp: React.lazy(() => import('@mui/icons-material/SignalCellularConnectedNoInternet1BarSharp')),
    SignalCellularConnectedNoInternet1BarTwoTone: React.lazy(() => import('@mui/icons-material/SignalCellularConnectedNoInternet1BarTwoTone')),
    SignalCellularConnectedNoInternet2Bar: React.lazy(() => import('@mui/icons-material/SignalCellularConnectedNoInternet2Bar')),
    SignalCellularConnectedNoInternet2BarOutlined: React.lazy(() => import('@mui/icons-material/SignalCellularConnectedNoInternet2BarOutlined')),
    SignalCellularConnectedNoInternet2BarRounded: React.lazy(() => import('@mui/icons-material/SignalCellularConnectedNoInternet2BarRounded')),
    SignalCellularConnectedNoInternet2BarSharp: React.lazy(() => import('@mui/icons-material/SignalCellularConnectedNoInternet2BarSharp')),
    SignalCellularConnectedNoInternet2BarTwoTone: React.lazy(() => import('@mui/icons-material/SignalCellularConnectedNoInternet2BarTwoTone')),
    SignalCellularConnectedNoInternet3Bar: React.lazy(() => import('@mui/icons-material/SignalCellularConnectedNoInternet3Bar')),
    SignalCellularConnectedNoInternet3BarOutlined: React.lazy(() => import('@mui/icons-material/SignalCellularConnectedNoInternet3BarOutlined')),
    SignalCellularConnectedNoInternet3BarRounded: React.lazy(() => import('@mui/icons-material/SignalCellularConnectedNoInternet3BarRounded')),
    SignalCellularConnectedNoInternet3BarSharp: React.lazy(() => import('@mui/icons-material/SignalCellularConnectedNoInternet3BarSharp')),
    SignalCellularConnectedNoInternet3BarTwoTone: React.lazy(() => import('@mui/icons-material/SignalCellularConnectedNoInternet3BarTwoTone')),
    SignalCellularConnectedNoInternet4Bar: React.lazy(() => import('@mui/icons-material/SignalCellularConnectedNoInternet4Bar')),
    SignalCellularConnectedNoInternet4BarOutlined: React.lazy(() => import('@mui/icons-material/SignalCellularConnectedNoInternet4BarOutlined')),
    SignalCellularConnectedNoInternet4BarRounded: React.lazy(() => import('@mui/icons-material/SignalCellularConnectedNoInternet4BarRounded')),
    SignalCellularConnectedNoInternet4BarSharp: React.lazy(() => import('@mui/icons-material/SignalCellularConnectedNoInternet4BarSharp')),
    SignalCellularConnectedNoInternet4BarTwoTone: React.lazy(() => import('@mui/icons-material/SignalCellularConnectedNoInternet4BarTwoTone')),
    SignalCellularNodata: React.lazy(() => import('@mui/icons-material/SignalCellularNodata')),
    SignalCellularNodataOutlined: React.lazy(() => import('@mui/icons-material/SignalCellularNodataOutlined')),
    SignalCellularNodataRounded: React.lazy(() => import('@mui/icons-material/SignalCellularNodataRounded')),
    SignalCellularNodataSharp: React.lazy(() => import('@mui/icons-material/SignalCellularNodataSharp')),
    SignalCellularNodataTwoTone: React.lazy(() => import('@mui/icons-material/SignalCellularNodataTwoTone')),
    SignalCellularNoSim: React.lazy(() => import('@mui/icons-material/SignalCellularNoSim')),
    SignalCellularNoSimOutlined: React.lazy(() => import('@mui/icons-material/SignalCellularNoSimOutlined')),
    SignalCellularNoSimRounded: React.lazy(() => import('@mui/icons-material/SignalCellularNoSimRounded')),
    SignalCellularNoSimSharp: React.lazy(() => import('@mui/icons-material/SignalCellularNoSimSharp')),
    SignalCellularNoSimTwoTone: React.lazy(() => import('@mui/icons-material/SignalCellularNoSimTwoTone')),
    SignalCellularNull: React.lazy(() => import('@mui/icons-material/SignalCellularNull')),
    SignalCellularNullOutlined: React.lazy(() => import('@mui/icons-material/SignalCellularNullOutlined')),
    SignalCellularNullRounded: React.lazy(() => import('@mui/icons-material/SignalCellularNullRounded')),
    SignalCellularNullSharp: React.lazy(() => import('@mui/icons-material/SignalCellularNullSharp')),
    SignalCellularNullTwoTone: React.lazy(() => import('@mui/icons-material/SignalCellularNullTwoTone')),
    SignalCellularOff: React.lazy(() => import('@mui/icons-material/SignalCellularOff')),
    SignalCellularOffOutlined: React.lazy(() => import('@mui/icons-material/SignalCellularOffOutlined')),
    SignalCellularOffRounded: React.lazy(() => import('@mui/icons-material/SignalCellularOffRounded')),
    SignalCellularOffSharp: React.lazy(() => import('@mui/icons-material/SignalCellularOffSharp')),
    SignalCellularOffTwoTone: React.lazy(() => import('@mui/icons-material/SignalCellularOffTwoTone')),
    SignalWifi0Bar: React.lazy(() => import('@mui/icons-material/SignalWifi0Bar')),
    SignalWifi0BarOutlined: React.lazy(() => import('@mui/icons-material/SignalWifi0BarOutlined')),
    SignalWifi0BarRounded: React.lazy(() => import('@mui/icons-material/SignalWifi0BarRounded')),
    SignalWifi0BarSharp: React.lazy(() => import('@mui/icons-material/SignalWifi0BarSharp')),
    SignalWifi0BarTwoTone: React.lazy(() => import('@mui/icons-material/SignalWifi0BarTwoTone')),
    SignalWifi1Bar: React.lazy(() => import('@mui/icons-material/SignalWifi1Bar')),
    SignalWifi1BarLock: React.lazy(() => import('@mui/icons-material/SignalWifi1BarLock')),
    SignalWifi1BarLockOutlined: React.lazy(() => import('@mui/icons-material/SignalWifi1BarLockOutlined')),
    SignalWifi1BarLockRounded: React.lazy(() => import('@mui/icons-material/SignalWifi1BarLockRounded')),
    SignalWifi1BarLockSharp: React.lazy(() => import('@mui/icons-material/SignalWifi1BarLockSharp')),
    SignalWifi1BarLockTwoTone: React.lazy(() => import('@mui/icons-material/SignalWifi1BarLockTwoTone')),
    SignalWifi1BarOutlined: React.lazy(() => import('@mui/icons-material/SignalWifi1BarOutlined')),
    SignalWifi1BarRounded: React.lazy(() => import('@mui/icons-material/SignalWifi1BarRounded')),
    SignalWifi1BarSharp: React.lazy(() => import('@mui/icons-material/SignalWifi1BarSharp')),
    SignalWifi1BarTwoTone: React.lazy(() => import('@mui/icons-material/SignalWifi1BarTwoTone')),
    SignalWifi2Bar: React.lazy(() => import('@mui/icons-material/SignalWifi2Bar')),
    SignalWifi2BarLock: React.lazy(() => import('@mui/icons-material/SignalWifi2BarLock')),
    SignalWifi2BarLockOutlined: React.lazy(() => import('@mui/icons-material/SignalWifi2BarLockOutlined')),
    SignalWifi2BarLockRounded: React.lazy(() => import('@mui/icons-material/SignalWifi2BarLockRounded')),
    SignalWifi2BarLockSharp: React.lazy(() => import('@mui/icons-material/SignalWifi2BarLockSharp')),
    SignalWifi2BarLockTwoTone: React.lazy(() => import('@mui/icons-material/SignalWifi2BarLockTwoTone')),
    SignalWifi2BarOutlined: React.lazy(() => import('@mui/icons-material/SignalWifi2BarOutlined')),
    SignalWifi2BarRounded: React.lazy(() => import('@mui/icons-material/SignalWifi2BarRounded')),
    SignalWifi2BarSharp: React.lazy(() => import('@mui/icons-material/SignalWifi2BarSharp')),
    SignalWifi2BarTwoTone: React.lazy(() => import('@mui/icons-material/SignalWifi2BarTwoTone')),
    SignalWifi3Bar: React.lazy(() => import('@mui/icons-material/SignalWifi3Bar')),
    SignalWifi3BarLock: React.lazy(() => import('@mui/icons-material/SignalWifi3BarLock')),
    SignalWifi3BarLockOutlined: React.lazy(() => import('@mui/icons-material/SignalWifi3BarLockOutlined')),
    SignalWifi3BarLockRounded: React.lazy(() => import('@mui/icons-material/SignalWifi3BarLockRounded')),
    SignalWifi3BarLockSharp: React.lazy(() => import('@mui/icons-material/SignalWifi3BarLockSharp')),
    SignalWifi3BarLockTwoTone: React.lazy(() => import('@mui/icons-material/SignalWifi3BarLockTwoTone')),
    SignalWifi3BarOutlined: React.lazy(() => import('@mui/icons-material/SignalWifi3BarOutlined')),
    SignalWifi3BarRounded: React.lazy(() => import('@mui/icons-material/SignalWifi3BarRounded')),
    SignalWifi3BarSharp: React.lazy(() => import('@mui/icons-material/SignalWifi3BarSharp')),
    SignalWifi3BarTwoTone: React.lazy(() => import('@mui/icons-material/SignalWifi3BarTwoTone')),
    SignalWifi4Bar: React.lazy(() => import('@mui/icons-material/SignalWifi4Bar')),
    SignalWifi4BarLock: React.lazy(() => import('@mui/icons-material/SignalWifi4BarLock')),
    SignalWifi4BarLockOutlined: React.lazy(() => import('@mui/icons-material/SignalWifi4BarLockOutlined')),
    SignalWifi4BarLockRounded: React.lazy(() => import('@mui/icons-material/SignalWifi4BarLockRounded')),
    SignalWifi4BarLockSharp: React.lazy(() => import('@mui/icons-material/SignalWifi4BarLockSharp')),
    SignalWifi4BarLockTwoTone: React.lazy(() => import('@mui/icons-material/SignalWifi4BarLockTwoTone')),
    SignalWifi4BarOutlined: React.lazy(() => import('@mui/icons-material/SignalWifi4BarOutlined')),
    SignalWifi4BarRounded: React.lazy(() => import('@mui/icons-material/SignalWifi4BarRounded')),
    SignalWifi4BarSharp: React.lazy(() => import('@mui/icons-material/SignalWifi4BarSharp')),
    SignalWifi4BarTwoTone: React.lazy(() => import('@mui/icons-material/SignalWifi4BarTwoTone')),
    SignalWifiBad: React.lazy(() => import('@mui/icons-material/SignalWifiBad')),
    SignalWifiBadOutlined: React.lazy(() => import('@mui/icons-material/SignalWifiBadOutlined')),
    SignalWifiBadRounded: React.lazy(() => import('@mui/icons-material/SignalWifiBadRounded')),
    SignalWifiBadSharp: React.lazy(() => import('@mui/icons-material/SignalWifiBadSharp')),
    SignalWifiBadTwoTone: React.lazy(() => import('@mui/icons-material/SignalWifiBadTwoTone')),
    SignalWifiConnectedNoInternet4: React.lazy(() => import('@mui/icons-material/SignalWifiConnectedNoInternet4')),
    SignalWifiConnectedNoInternet4Outlined: React.lazy(() => import('@mui/icons-material/SignalWifiConnectedNoInternet4Outlined')),
    SignalWifiConnectedNoInternet4Rounded: React.lazy(() => import('@mui/icons-material/SignalWifiConnectedNoInternet4Rounded')),
    SignalWifiConnectedNoInternet4Sharp: React.lazy(() => import('@mui/icons-material/SignalWifiConnectedNoInternet4Sharp')),
    SignalWifiConnectedNoInternet4TwoTone: React.lazy(() => import('@mui/icons-material/SignalWifiConnectedNoInternet4TwoTone')),
    SignalWifiOff: React.lazy(() => import('@mui/icons-material/SignalWifiOff')),
    SignalWifiOffOutlined: React.lazy(() => import('@mui/icons-material/SignalWifiOffOutlined')),
    SignalWifiOffRounded: React.lazy(() => import('@mui/icons-material/SignalWifiOffRounded')),
    SignalWifiOffSharp: React.lazy(() => import('@mui/icons-material/SignalWifiOffSharp')),
    SignalWifiOffTwoTone: React.lazy(() => import('@mui/icons-material/SignalWifiOffTwoTone')),
    SignalWifiStatusbar4Bar: React.lazy(() => import('@mui/icons-material/SignalWifiStatusbar4Bar')),
    SignalWifiStatusbar4BarOutlined: React.lazy(() => import('@mui/icons-material/SignalWifiStatusbar4BarOutlined')),
    SignalWifiStatusbar4BarRounded: React.lazy(() => import('@mui/icons-material/SignalWifiStatusbar4BarRounded')),
    SignalWifiStatusbar4BarSharp: React.lazy(() => import('@mui/icons-material/SignalWifiStatusbar4BarSharp')),
    SignalWifiStatusbar4BarTwoTone: React.lazy(() => import('@mui/icons-material/SignalWifiStatusbar4BarTwoTone')),
    SignalWifiStatusbarConnectedNoInternet4: React.lazy(() => import('@mui/icons-material/SignalWifiStatusbarConnectedNoInternet4')),
    SignalWifiStatusbarConnectedNoInternet4Outlined: React.lazy(() => import('@mui/icons-material/SignalWifiStatusbarConnectedNoInternet4Outlined')),
    SignalWifiStatusbarConnectedNoInternet4Rounded: React.lazy(() => import('@mui/icons-material/SignalWifiStatusbarConnectedNoInternet4Rounded')),
    SignalWifiStatusbarConnectedNoInternet4Sharp: React.lazy(() => import('@mui/icons-material/SignalWifiStatusbarConnectedNoInternet4Sharp')),
    SignalWifiStatusbarConnectedNoInternet4TwoTone: React.lazy(() => import('@mui/icons-material/SignalWifiStatusbarConnectedNoInternet4TwoTone')),
    SignalWifiStatusbarNull: React.lazy(() => import('@mui/icons-material/SignalWifiStatusbarNull')),
    SignalWifiStatusbarNullOutlined: React.lazy(() => import('@mui/icons-material/SignalWifiStatusbarNullOutlined')),
    SignalWifiStatusbarNullRounded: React.lazy(() => import('@mui/icons-material/SignalWifiStatusbarNullRounded')),
    SignalWifiStatusbarNullSharp: React.lazy(() => import('@mui/icons-material/SignalWifiStatusbarNullSharp')),
    SignalWifiStatusbarNullTwoTone: React.lazy(() => import('@mui/icons-material/SignalWifiStatusbarNullTwoTone')),
    SignLanguage: React.lazy(() => import('@mui/icons-material/SignLanguage')),
    SignLanguageOutlined: React.lazy(() => import('@mui/icons-material/SignLanguageOutlined')),
    SignLanguageRounded: React.lazy(() => import('@mui/icons-material/SignLanguageRounded')),
    SignLanguageSharp: React.lazy(() => import('@mui/icons-material/SignLanguageSharp')),
    SignLanguageTwoTone: React.lazy(() => import('@mui/icons-material/SignLanguageTwoTone')),
    Signpost: React.lazy(() => import('@mui/icons-material/Signpost')),
    SignpostOutlined: React.lazy(() => import('@mui/icons-material/SignpostOutlined')),
    SignpostRounded: React.lazy(() => import('@mui/icons-material/SignpostRounded')),
    SignpostSharp: React.lazy(() => import('@mui/icons-material/SignpostSharp')),
    SignpostTwoTone: React.lazy(() => import('@mui/icons-material/SignpostTwoTone')),
    SimCardAlert: React.lazy(() => import('@mui/icons-material/SimCardAlert')),
    SimCardAlertOutlined: React.lazy(() => import('@mui/icons-material/SimCardAlertOutlined')),
    SimCardAlertRounded: React.lazy(() => import('@mui/icons-material/SimCardAlertRounded')),
    SimCardAlertSharp: React.lazy(() => import('@mui/icons-material/SimCardAlertSharp')),
    SimCardAlertTwoTone: React.lazy(() => import('@mui/icons-material/SimCardAlertTwoTone')),
    SimCardDownload: React.lazy(() => import('@mui/icons-material/SimCardDownload')),
    SimCardDownloadOutlined: React.lazy(() => import('@mui/icons-material/SimCardDownloadOutlined')),
    SimCardDownloadRounded: React.lazy(() => import('@mui/icons-material/SimCardDownloadRounded')),
    SimCardDownloadSharp: React.lazy(() => import('@mui/icons-material/SimCardDownloadSharp')),
    SimCardDownloadTwoTone: React.lazy(() => import('@mui/icons-material/SimCardDownloadTwoTone')),
    SimCard: React.lazy(() => import('@mui/icons-material/SimCard')),
    SimCardOutlined: React.lazy(() => import('@mui/icons-material/SimCardOutlined')),
    SimCardRounded: React.lazy(() => import('@mui/icons-material/SimCardRounded')),
    SimCardSharp: React.lazy(() => import('@mui/icons-material/SimCardSharp')),
    SimCardTwoTone: React.lazy(() => import('@mui/icons-material/SimCardTwoTone')),
    SingleBed: React.lazy(() => import('@mui/icons-material/SingleBed')),
    SingleBedOutlined: React.lazy(() => import('@mui/icons-material/SingleBedOutlined')),
    SingleBedRounded: React.lazy(() => import('@mui/icons-material/SingleBedRounded')),
    SingleBedSharp: React.lazy(() => import('@mui/icons-material/SingleBedSharp')),
    SingleBedTwoTone: React.lazy(() => import('@mui/icons-material/SingleBedTwoTone')),
    Sip: React.lazy(() => import('@mui/icons-material/Sip')),
    SipOutlined: React.lazy(() => import('@mui/icons-material/SipOutlined')),
    SipRounded: React.lazy(() => import('@mui/icons-material/SipRounded')),
    SipSharp: React.lazy(() => import('@mui/icons-material/SipSharp')),
    SipTwoTone: React.lazy(() => import('@mui/icons-material/SipTwoTone')),
    SixK: React.lazy(() => import('@mui/icons-material/SixK')),
    SixKOutlined: React.lazy(() => import('@mui/icons-material/SixKOutlined')),
    SixKPlus: React.lazy(() => import('@mui/icons-material/SixKPlus')),
    SixKPlusOutlined: React.lazy(() => import('@mui/icons-material/SixKPlusOutlined')),
    SixKPlusRounded: React.lazy(() => import('@mui/icons-material/SixKPlusRounded')),
    SixKPlusSharp: React.lazy(() => import('@mui/icons-material/SixKPlusSharp')),
    SixKPlusTwoTone: React.lazy(() => import('@mui/icons-material/SixKPlusTwoTone')),
    SixKRounded: React.lazy(() => import('@mui/icons-material/SixKRounded')),
    SixKSharp: React.lazy(() => import('@mui/icons-material/SixKSharp')),
    SixKTwoTone: React.lazy(() => import('@mui/icons-material/SixKTwoTone')),
    SixMp: React.lazy(() => import('@mui/icons-material/SixMp')),
    SixMpOutlined: React.lazy(() => import('@mui/icons-material/SixMpOutlined')),
    SixMpRounded: React.lazy(() => import('@mui/icons-material/SixMpRounded')),
    SixMpSharp: React.lazy(() => import('@mui/icons-material/SixMpSharp')),
    SixMpTwoTone: React.lazy(() => import('@mui/icons-material/SixMpTwoTone')),
    SixteenMp: React.lazy(() => import('@mui/icons-material/SixteenMp')),
    SixteenMpOutlined: React.lazy(() => import('@mui/icons-material/SixteenMpOutlined')),
    SixteenMpRounded: React.lazy(() => import('@mui/icons-material/SixteenMpRounded')),
    SixteenMpSharp: React.lazy(() => import('@mui/icons-material/SixteenMpSharp')),
    SixteenMpTwoTone: React.lazy(() => import('@mui/icons-material/SixteenMpTwoTone')),
    SixtyFps: React.lazy(() => import('@mui/icons-material/SixtyFps')),
    SixtyFpsOutlined: React.lazy(() => import('@mui/icons-material/SixtyFpsOutlined')),
    SixtyFpsRounded: React.lazy(() => import('@mui/icons-material/SixtyFpsRounded')),
    SixtyFpsSelect: React.lazy(() => import('@mui/icons-material/SixtyFpsSelect')),
    SixtyFpsSelectOutlined: React.lazy(() => import('@mui/icons-material/SixtyFpsSelectOutlined')),
    SixtyFpsSelectRounded: React.lazy(() => import('@mui/icons-material/SixtyFpsSelectRounded')),
    SixtyFpsSelectSharp: React.lazy(() => import('@mui/icons-material/SixtyFpsSelectSharp')),
    SixtyFpsSelectTwoTone: React.lazy(() => import('@mui/icons-material/SixtyFpsSelectTwoTone')),
    SixtyFpsSharp: React.lazy(() => import('@mui/icons-material/SixtyFpsSharp')),
    SixtyFpsTwoTone: React.lazy(() => import('@mui/icons-material/SixtyFpsTwoTone')),
    Skateboarding: React.lazy(() => import('@mui/icons-material/Skateboarding')),
    SkateboardingOutlined: React.lazy(() => import('@mui/icons-material/SkateboardingOutlined')),
    SkateboardingRounded: React.lazy(() => import('@mui/icons-material/SkateboardingRounded')),
    SkateboardingSharp: React.lazy(() => import('@mui/icons-material/SkateboardingSharp')),
    SkateboardingTwoTone: React.lazy(() => import('@mui/icons-material/SkateboardingTwoTone')),
    SkipNext: React.lazy(() => import('@mui/icons-material/SkipNext')),
    SkipNextOutlined: React.lazy(() => import('@mui/icons-material/SkipNextOutlined')),
    SkipNextRounded: React.lazy(() => import('@mui/icons-material/SkipNextRounded')),
    SkipNextSharp: React.lazy(() => import('@mui/icons-material/SkipNextSharp')),
    SkipNextTwoTone: React.lazy(() => import('@mui/icons-material/SkipNextTwoTone')),
    SkipPrevious: React.lazy(() => import('@mui/icons-material/SkipPrevious')),
    SkipPreviousOutlined: React.lazy(() => import('@mui/icons-material/SkipPreviousOutlined')),
    SkipPreviousRounded: React.lazy(() => import('@mui/icons-material/SkipPreviousRounded')),
    SkipPreviousSharp: React.lazy(() => import('@mui/icons-material/SkipPreviousSharp')),
    SkipPreviousTwoTone: React.lazy(() => import('@mui/icons-material/SkipPreviousTwoTone')),
    Sledding: React.lazy(() => import('@mui/icons-material/Sledding')),
    SleddingOutlined: React.lazy(() => import('@mui/icons-material/SleddingOutlined')),
    SleddingRounded: React.lazy(() => import('@mui/icons-material/SleddingRounded')),
    SleddingSharp: React.lazy(() => import('@mui/icons-material/SleddingSharp')),
    SleddingTwoTone: React.lazy(() => import('@mui/icons-material/SleddingTwoTone')),
    Slideshow: React.lazy(() => import('@mui/icons-material/Slideshow')),
    SlideshowOutlined: React.lazy(() => import('@mui/icons-material/SlideshowOutlined')),
    SlideshowRounded: React.lazy(() => import('@mui/icons-material/SlideshowRounded')),
    SlideshowSharp: React.lazy(() => import('@mui/icons-material/SlideshowSharp')),
    SlideshowTwoTone: React.lazy(() => import('@mui/icons-material/SlideshowTwoTone')),
    SlowMotionVideo: React.lazy(() => import('@mui/icons-material/SlowMotionVideo')),
    SlowMotionVideoOutlined: React.lazy(() => import('@mui/icons-material/SlowMotionVideoOutlined')),
    SlowMotionVideoRounded: React.lazy(() => import('@mui/icons-material/SlowMotionVideoRounded')),
    SlowMotionVideoSharp: React.lazy(() => import('@mui/icons-material/SlowMotionVideoSharp')),
    SlowMotionVideoTwoTone: React.lazy(() => import('@mui/icons-material/SlowMotionVideoTwoTone')),
    SmartButton: React.lazy(() => import('@mui/icons-material/SmartButton')),
    SmartButtonOutlined: React.lazy(() => import('@mui/icons-material/SmartButtonOutlined')),
    SmartButtonRounded: React.lazy(() => import('@mui/icons-material/SmartButtonRounded')),
    SmartButtonSharp: React.lazy(() => import('@mui/icons-material/SmartButtonSharp')),
    SmartButtonTwoTone: React.lazy(() => import('@mui/icons-material/SmartButtonTwoTone')),
    SmartDisplay: React.lazy(() => import('@mui/icons-material/SmartDisplay')),
    SmartDisplayOutlined: React.lazy(() => import('@mui/icons-material/SmartDisplayOutlined')),
    SmartDisplayRounded: React.lazy(() => import('@mui/icons-material/SmartDisplayRounded')),
    SmartDisplaySharp: React.lazy(() => import('@mui/icons-material/SmartDisplaySharp')),
    SmartDisplayTwoTone: React.lazy(() => import('@mui/icons-material/SmartDisplayTwoTone')),
    Smartphone: React.lazy(() => import('@mui/icons-material/Smartphone')),
    SmartphoneOutlined: React.lazy(() => import('@mui/icons-material/SmartphoneOutlined')),
    SmartphoneRounded: React.lazy(() => import('@mui/icons-material/SmartphoneRounded')),
    SmartphoneSharp: React.lazy(() => import('@mui/icons-material/SmartphoneSharp')),
    SmartphoneTwoTone: React.lazy(() => import('@mui/icons-material/SmartphoneTwoTone')),
    SmartScreen: React.lazy(() => import('@mui/icons-material/SmartScreen')),
    SmartScreenOutlined: React.lazy(() => import('@mui/icons-material/SmartScreenOutlined')),
    SmartScreenRounded: React.lazy(() => import('@mui/icons-material/SmartScreenRounded')),
    SmartScreenSharp: React.lazy(() => import('@mui/icons-material/SmartScreenSharp')),
    SmartScreenTwoTone: React.lazy(() => import('@mui/icons-material/SmartScreenTwoTone')),
    SmartToy: React.lazy(() => import('@mui/icons-material/SmartToy')),
    SmartToyOutlined: React.lazy(() => import('@mui/icons-material/SmartToyOutlined')),
    SmartToyRounded: React.lazy(() => import('@mui/icons-material/SmartToyRounded')),
    SmartToySharp: React.lazy(() => import('@mui/icons-material/SmartToySharp')),
    SmartToyTwoTone: React.lazy(() => import('@mui/icons-material/SmartToyTwoTone')),
    SmokeFree: React.lazy(() => import('@mui/icons-material/SmokeFree')),
    SmokeFreeOutlined: React.lazy(() => import('@mui/icons-material/SmokeFreeOutlined')),
    SmokeFreeRounded: React.lazy(() => import('@mui/icons-material/SmokeFreeRounded')),
    SmokeFreeSharp: React.lazy(() => import('@mui/icons-material/SmokeFreeSharp')),
    SmokeFreeTwoTone: React.lazy(() => import('@mui/icons-material/SmokeFreeTwoTone')),
    SmokingRooms: React.lazy(() => import('@mui/icons-material/SmokingRooms')),
    SmokingRoomsOutlined: React.lazy(() => import('@mui/icons-material/SmokingRoomsOutlined')),
    SmokingRoomsRounded: React.lazy(() => import('@mui/icons-material/SmokingRoomsRounded')),
    SmokingRoomsSharp: React.lazy(() => import('@mui/icons-material/SmokingRoomsSharp')),
    SmokingRoomsTwoTone: React.lazy(() => import('@mui/icons-material/SmokingRoomsTwoTone')),
    Sms: React.lazy(() => import('@mui/icons-material/Sms')),
    SmsFailed: React.lazy(() => import('@mui/icons-material/SmsFailed')),
    SmsFailedOutlined: React.lazy(() => import('@mui/icons-material/SmsFailedOutlined')),
    SmsFailedRounded: React.lazy(() => import('@mui/icons-material/SmsFailedRounded')),
    SmsFailedSharp: React.lazy(() => import('@mui/icons-material/SmsFailedSharp')),
    SmsFailedTwoTone: React.lazy(() => import('@mui/icons-material/SmsFailedTwoTone')),
    SmsOutlined: React.lazy(() => import('@mui/icons-material/SmsOutlined')),
    SmsRounded: React.lazy(() => import('@mui/icons-material/SmsRounded')),
    SmsSharp: React.lazy(() => import('@mui/icons-material/SmsSharp')),
    SmsTwoTone: React.lazy(() => import('@mui/icons-material/SmsTwoTone')),
    SnippetFolder: React.lazy(() => import('@mui/icons-material/SnippetFolder')),
    SnippetFolderOutlined: React.lazy(() => import('@mui/icons-material/SnippetFolderOutlined')),
    SnippetFolderRounded: React.lazy(() => import('@mui/icons-material/SnippetFolderRounded')),
    SnippetFolderSharp: React.lazy(() => import('@mui/icons-material/SnippetFolderSharp')),
    SnippetFolderTwoTone: React.lazy(() => import('@mui/icons-material/SnippetFolderTwoTone')),
    Snooze: React.lazy(() => import('@mui/icons-material/Snooze')),
    SnoozeOutlined: React.lazy(() => import('@mui/icons-material/SnoozeOutlined')),
    SnoozeRounded: React.lazy(() => import('@mui/icons-material/SnoozeRounded')),
    SnoozeSharp: React.lazy(() => import('@mui/icons-material/SnoozeSharp')),
    SnoozeTwoTone: React.lazy(() => import('@mui/icons-material/SnoozeTwoTone')),
    Snowboarding: React.lazy(() => import('@mui/icons-material/Snowboarding')),
    SnowboardingOutlined: React.lazy(() => import('@mui/icons-material/SnowboardingOutlined')),
    SnowboardingRounded: React.lazy(() => import('@mui/icons-material/SnowboardingRounded')),
    SnowboardingSharp: React.lazy(() => import('@mui/icons-material/SnowboardingSharp')),
    SnowboardingTwoTone: React.lazy(() => import('@mui/icons-material/SnowboardingTwoTone')),
    Snowmobile: React.lazy(() => import('@mui/icons-material/Snowmobile')),
    SnowmobileOutlined: React.lazy(() => import('@mui/icons-material/SnowmobileOutlined')),
    SnowmobileRounded: React.lazy(() => import('@mui/icons-material/SnowmobileRounded')),
    SnowmobileSharp: React.lazy(() => import('@mui/icons-material/SnowmobileSharp')),
    SnowmobileTwoTone: React.lazy(() => import('@mui/icons-material/SnowmobileTwoTone')),
    Snowshoeing: React.lazy(() => import('@mui/icons-material/Snowshoeing')),
    SnowshoeingOutlined: React.lazy(() => import('@mui/icons-material/SnowshoeingOutlined')),
    SnowshoeingRounded: React.lazy(() => import('@mui/icons-material/SnowshoeingRounded')),
    SnowshoeingSharp: React.lazy(() => import('@mui/icons-material/SnowshoeingSharp')),
    SnowshoeingTwoTone: React.lazy(() => import('@mui/icons-material/SnowshoeingTwoTone')),
    Soap: React.lazy(() => import('@mui/icons-material/Soap')),
    SoapOutlined: React.lazy(() => import('@mui/icons-material/SoapOutlined')),
    SoapRounded: React.lazy(() => import('@mui/icons-material/SoapRounded')),
    SoapSharp: React.lazy(() => import('@mui/icons-material/SoapSharp')),
    SoapTwoTone: React.lazy(() => import('@mui/icons-material/SoapTwoTone')),
    SocialDistance: React.lazy(() => import('@mui/icons-material/SocialDistance')),
    SocialDistanceOutlined: React.lazy(() => import('@mui/icons-material/SocialDistanceOutlined')),
    SocialDistanceRounded: React.lazy(() => import('@mui/icons-material/SocialDistanceRounded')),
    SocialDistanceSharp: React.lazy(() => import('@mui/icons-material/SocialDistanceSharp')),
    SocialDistanceTwoTone: React.lazy(() => import('@mui/icons-material/SocialDistanceTwoTone')),
    SolarPower: React.lazy(() => import('@mui/icons-material/SolarPower')),
    SolarPowerOutlined: React.lazy(() => import('@mui/icons-material/SolarPowerOutlined')),
    SolarPowerRounded: React.lazy(() => import('@mui/icons-material/SolarPowerRounded')),
    SolarPowerSharp: React.lazy(() => import('@mui/icons-material/SolarPowerSharp')),
    SolarPowerTwoTone: React.lazy(() => import('@mui/icons-material/SolarPowerTwoTone')),
    SortByAlpha: React.lazy(() => import('@mui/icons-material/SortByAlpha')),
    SortByAlphaOutlined: React.lazy(() => import('@mui/icons-material/SortByAlphaOutlined')),
    SortByAlphaRounded: React.lazy(() => import('@mui/icons-material/SortByAlphaRounded')),
    SortByAlphaSharp: React.lazy(() => import('@mui/icons-material/SortByAlphaSharp')),
    SortByAlphaTwoTone: React.lazy(() => import('@mui/icons-material/SortByAlphaTwoTone')),
    Sort: React.lazy(() => import('@mui/icons-material/Sort')),
    SortOutlined: React.lazy(() => import('@mui/icons-material/SortOutlined')),
    SortRounded: React.lazy(() => import('@mui/icons-material/SortRounded')),
    SortSharp: React.lazy(() => import('@mui/icons-material/SortSharp')),
    SortTwoTone: React.lazy(() => import('@mui/icons-material/SortTwoTone')),
    Sos: React.lazy(() => import('@mui/icons-material/Sos')),
    SosOutlined: React.lazy(() => import('@mui/icons-material/SosOutlined')),
    SosRounded: React.lazy(() => import('@mui/icons-material/SosRounded')),
    SosSharp: React.lazy(() => import('@mui/icons-material/SosSharp')),
    SosTwoTone: React.lazy(() => import('@mui/icons-material/SosTwoTone')),
    SoupKitchen: React.lazy(() => import('@mui/icons-material/SoupKitchen')),
    SoupKitchenOutlined: React.lazy(() => import('@mui/icons-material/SoupKitchenOutlined')),
    SoupKitchenRounded: React.lazy(() => import('@mui/icons-material/SoupKitchenRounded')),
    SoupKitchenSharp: React.lazy(() => import('@mui/icons-material/SoupKitchenSharp')),
    SoupKitchenTwoTone: React.lazy(() => import('@mui/icons-material/SoupKitchenTwoTone')),
    Source: React.lazy(() => import('@mui/icons-material/Source')),
    SourceOutlined: React.lazy(() => import('@mui/icons-material/SourceOutlined')),
    SourceRounded: React.lazy(() => import('@mui/icons-material/SourceRounded')),
    SourceSharp: React.lazy(() => import('@mui/icons-material/SourceSharp')),
    SourceTwoTone: React.lazy(() => import('@mui/icons-material/SourceTwoTone')),
    SouthAmerica: React.lazy(() => import('@mui/icons-material/SouthAmerica')),
    SouthAmericaOutlined: React.lazy(() => import('@mui/icons-material/SouthAmericaOutlined')),
    SouthAmericaRounded: React.lazy(() => import('@mui/icons-material/SouthAmericaRounded')),
    SouthAmericaSharp: React.lazy(() => import('@mui/icons-material/SouthAmericaSharp')),
    SouthAmericaTwoTone: React.lazy(() => import('@mui/icons-material/SouthAmericaTwoTone')),
    South: React.lazy(() => import('@mui/icons-material/South')),
    SouthEast: React.lazy(() => import('@mui/icons-material/SouthEast')),
    SouthEastOutlined: React.lazy(() => import('@mui/icons-material/SouthEastOutlined')),
    SouthEastRounded: React.lazy(() => import('@mui/icons-material/SouthEastRounded')),
    SouthEastSharp: React.lazy(() => import('@mui/icons-material/SouthEastSharp')),
    SouthEastTwoTone: React.lazy(() => import('@mui/icons-material/SouthEastTwoTone')),
    SouthOutlined: React.lazy(() => import('@mui/icons-material/SouthOutlined')),
    SouthRounded: React.lazy(() => import('@mui/icons-material/SouthRounded')),
    SouthSharp: React.lazy(() => import('@mui/icons-material/SouthSharp')),
    SouthTwoTone: React.lazy(() => import('@mui/icons-material/SouthTwoTone')),
    SouthWest: React.lazy(() => import('@mui/icons-material/SouthWest')),
    SouthWestOutlined: React.lazy(() => import('@mui/icons-material/SouthWestOutlined')),
    SouthWestRounded: React.lazy(() => import('@mui/icons-material/SouthWestRounded')),
    SouthWestSharp: React.lazy(() => import('@mui/icons-material/SouthWestSharp')),
    SouthWestTwoTone: React.lazy(() => import('@mui/icons-material/SouthWestTwoTone')),
    SpaceBar: React.lazy(() => import('@mui/icons-material/SpaceBar')),
    SpaceBarOutlined: React.lazy(() => import('@mui/icons-material/SpaceBarOutlined')),
    SpaceBarRounded: React.lazy(() => import('@mui/icons-material/SpaceBarRounded')),
    SpaceBarSharp: React.lazy(() => import('@mui/icons-material/SpaceBarSharp')),
    SpaceBarTwoTone: React.lazy(() => import('@mui/icons-material/SpaceBarTwoTone')),
    Spa: React.lazy(() => import('@mui/icons-material/Spa')),
    SpaOutlined: React.lazy(() => import('@mui/icons-material/SpaOutlined')),
    SpaRounded: React.lazy(() => import('@mui/icons-material/SpaRounded')),
    SpaSharp: React.lazy(() => import('@mui/icons-material/SpaSharp')),
    SpatialAudio: React.lazy(() => import('@mui/icons-material/SpatialAudio')),
    SpatialAudioOff: React.lazy(() => import('@mui/icons-material/SpatialAudioOff')),
    SpatialAudioOffOutlined: React.lazy(() => import('@mui/icons-material/SpatialAudioOffOutlined')),
    SpatialAudioOffRounded: React.lazy(() => import('@mui/icons-material/SpatialAudioOffRounded')),
    SpatialAudioOffSharp: React.lazy(() => import('@mui/icons-material/SpatialAudioOffSharp')),
    SpatialAudioOffTwoTone: React.lazy(() => import('@mui/icons-material/SpatialAudioOffTwoTone')),
    SpatialAudioOutlined: React.lazy(() => import('@mui/icons-material/SpatialAudioOutlined')),
    SpatialAudioRounded: React.lazy(() => import('@mui/icons-material/SpatialAudioRounded')),
    SpatialAudioSharp: React.lazy(() => import('@mui/icons-material/SpatialAudioSharp')),
    SpatialAudioTwoTone: React.lazy(() => import('@mui/icons-material/SpatialAudioTwoTone')),
    SpatialTracking: React.lazy(() => import('@mui/icons-material/SpatialTracking')),
    SpatialTrackingOutlined: React.lazy(() => import('@mui/icons-material/SpatialTrackingOutlined')),
    SpatialTrackingRounded: React.lazy(() => import('@mui/icons-material/SpatialTrackingRounded')),
    SpatialTrackingSharp: React.lazy(() => import('@mui/icons-material/SpatialTrackingSharp')),
    SpatialTrackingTwoTone: React.lazy(() => import('@mui/icons-material/SpatialTrackingTwoTone')),
    SpaTwoTone: React.lazy(() => import('@mui/icons-material/SpaTwoTone')),
    Speaker: React.lazy(() => import('@mui/icons-material/Speaker')),
    SpeakerGroup: React.lazy(() => import('@mui/icons-material/SpeakerGroup')),
    SpeakerGroupOutlined: React.lazy(() => import('@mui/icons-material/SpeakerGroupOutlined')),
    SpeakerGroupRounded: React.lazy(() => import('@mui/icons-material/SpeakerGroupRounded')),
    SpeakerGroupSharp: React.lazy(() => import('@mui/icons-material/SpeakerGroupSharp')),
    SpeakerGroupTwoTone: React.lazy(() => import('@mui/icons-material/SpeakerGroupTwoTone')),
    SpeakerNotes: React.lazy(() => import('@mui/icons-material/SpeakerNotes')),
    SpeakerNotesOff: React.lazy(() => import('@mui/icons-material/SpeakerNotesOff')),
    SpeakerNotesOffOutlined: React.lazy(() => import('@mui/icons-material/SpeakerNotesOffOutlined')),
    SpeakerNotesOffRounded: React.lazy(() => import('@mui/icons-material/SpeakerNotesOffRounded')),
    SpeakerNotesOffSharp: React.lazy(() => import('@mui/icons-material/SpeakerNotesOffSharp')),
    SpeakerNotesOffTwoTone: React.lazy(() => import('@mui/icons-material/SpeakerNotesOffTwoTone')),
    SpeakerNotesOutlined: React.lazy(() => import('@mui/icons-material/SpeakerNotesOutlined')),
    SpeakerNotesRounded: React.lazy(() => import('@mui/icons-material/SpeakerNotesRounded')),
    SpeakerNotesSharp: React.lazy(() => import('@mui/icons-material/SpeakerNotesSharp')),
    SpeakerNotesTwoTone: React.lazy(() => import('@mui/icons-material/SpeakerNotesTwoTone')),
    SpeakerOutlined: React.lazy(() => import('@mui/icons-material/SpeakerOutlined')),
    SpeakerPhone: React.lazy(() => import('@mui/icons-material/SpeakerPhone')),
    SpeakerPhoneOutlined: React.lazy(() => import('@mui/icons-material/SpeakerPhoneOutlined')),
    SpeakerPhoneRounded: React.lazy(() => import('@mui/icons-material/SpeakerPhoneRounded')),
    SpeakerPhoneSharp: React.lazy(() => import('@mui/icons-material/SpeakerPhoneSharp')),
    SpeakerPhoneTwoTone: React.lazy(() => import('@mui/icons-material/SpeakerPhoneTwoTone')),
    SpeakerRounded: React.lazy(() => import('@mui/icons-material/SpeakerRounded')),
    SpeakerSharp: React.lazy(() => import('@mui/icons-material/SpeakerSharp')),
    SpeakerTwoTone: React.lazy(() => import('@mui/icons-material/SpeakerTwoTone')),
    Speed: React.lazy(() => import('@mui/icons-material/Speed')),
    SpeedOutlined: React.lazy(() => import('@mui/icons-material/SpeedOutlined')),
    SpeedRounded: React.lazy(() => import('@mui/icons-material/SpeedRounded')),
    SpeedSharp: React.lazy(() => import('@mui/icons-material/SpeedSharp')),
    SpeedTwoTone: React.lazy(() => import('@mui/icons-material/SpeedTwoTone')),
    Spellcheck: React.lazy(() => import('@mui/icons-material/Spellcheck')),
    SpellcheckOutlined: React.lazy(() => import('@mui/icons-material/SpellcheckOutlined')),
    SpellcheckRounded: React.lazy(() => import('@mui/icons-material/SpellcheckRounded')),
    SpellcheckSharp: React.lazy(() => import('@mui/icons-material/SpellcheckSharp')),
    SpellcheckTwoTone: React.lazy(() => import('@mui/icons-material/SpellcheckTwoTone')),
    Splitscreen: React.lazy(() => import('@mui/icons-material/Splitscreen')),
    SplitscreenOutlined: React.lazy(() => import('@mui/icons-material/SplitscreenOutlined')),
    SplitscreenRounded: React.lazy(() => import('@mui/icons-material/SplitscreenRounded')),
    SplitscreenSharp: React.lazy(() => import('@mui/icons-material/SplitscreenSharp')),
    SplitscreenTwoTone: React.lazy(() => import('@mui/icons-material/SplitscreenTwoTone')),
    Spoke: React.lazy(() => import('@mui/icons-material/Spoke')),
    SpokeOutlined: React.lazy(() => import('@mui/icons-material/SpokeOutlined')),
    SpokeRounded: React.lazy(() => import('@mui/icons-material/SpokeRounded')),
    SpokeSharp: React.lazy(() => import('@mui/icons-material/SpokeSharp')),
    SpokeTwoTone: React.lazy(() => import('@mui/icons-material/SpokeTwoTone')),
    SportsBar: React.lazy(() => import('@mui/icons-material/SportsBar')),
    SportsBarOutlined: React.lazy(() => import('@mui/icons-material/SportsBarOutlined')),
    SportsBarRounded: React.lazy(() => import('@mui/icons-material/SportsBarRounded')),
    SportsBarSharp: React.lazy(() => import('@mui/icons-material/SportsBarSharp')),
    SportsBarTwoTone: React.lazy(() => import('@mui/icons-material/SportsBarTwoTone')),
    SportsBaseball: React.lazy(() => import('@mui/icons-material/SportsBaseball')),
    SportsBaseballOutlined: React.lazy(() => import('@mui/icons-material/SportsBaseballOutlined')),
    SportsBaseballRounded: React.lazy(() => import('@mui/icons-material/SportsBaseballRounded')),
    SportsBaseballSharp: React.lazy(() => import('@mui/icons-material/SportsBaseballSharp')),
    SportsBaseballTwoTone: React.lazy(() => import('@mui/icons-material/SportsBaseballTwoTone')),
    SportsBasketball: React.lazy(() => import('@mui/icons-material/SportsBasketball')),
    SportsBasketballOutlined: React.lazy(() => import('@mui/icons-material/SportsBasketballOutlined')),
    SportsBasketballRounded: React.lazy(() => import('@mui/icons-material/SportsBasketballRounded')),
    SportsBasketballSharp: React.lazy(() => import('@mui/icons-material/SportsBasketballSharp')),
    SportsBasketballTwoTone: React.lazy(() => import('@mui/icons-material/SportsBasketballTwoTone')),
    SportsCricket: React.lazy(() => import('@mui/icons-material/SportsCricket')),
    SportsCricketOutlined: React.lazy(() => import('@mui/icons-material/SportsCricketOutlined')),
    SportsCricketRounded: React.lazy(() => import('@mui/icons-material/SportsCricketRounded')),
    SportsCricketSharp: React.lazy(() => import('@mui/icons-material/SportsCricketSharp')),
    SportsCricketTwoTone: React.lazy(() => import('@mui/icons-material/SportsCricketTwoTone')),
    Sports: React.lazy(() => import('@mui/icons-material/Sports')),
    SportsEsports: React.lazy(() => import('@mui/icons-material/SportsEsports')),
    SportsEsportsOutlined: React.lazy(() => import('@mui/icons-material/SportsEsportsOutlined')),
    SportsEsportsRounded: React.lazy(() => import('@mui/icons-material/SportsEsportsRounded')),
    SportsEsportsSharp: React.lazy(() => import('@mui/icons-material/SportsEsportsSharp')),
    SportsEsportsTwoTone: React.lazy(() => import('@mui/icons-material/SportsEsportsTwoTone')),
    SportsFootball: React.lazy(() => import('@mui/icons-material/SportsFootball')),
    SportsFootballOutlined: React.lazy(() => import('@mui/icons-material/SportsFootballOutlined')),
    SportsFootballRounded: React.lazy(() => import('@mui/icons-material/SportsFootballRounded')),
    SportsFootballSharp: React.lazy(() => import('@mui/icons-material/SportsFootballSharp')),
    SportsFootballTwoTone: React.lazy(() => import('@mui/icons-material/SportsFootballTwoTone')),
    SportsGolf: React.lazy(() => import('@mui/icons-material/SportsGolf')),
    SportsGolfOutlined: React.lazy(() => import('@mui/icons-material/SportsGolfOutlined')),
    SportsGolfRounded: React.lazy(() => import('@mui/icons-material/SportsGolfRounded')),
    SportsGolfSharp: React.lazy(() => import('@mui/icons-material/SportsGolfSharp')),
    SportsGolfTwoTone: React.lazy(() => import('@mui/icons-material/SportsGolfTwoTone')),
    SportsGymnastics: React.lazy(() => import('@mui/icons-material/SportsGymnastics')),
    SportsGymnasticsOutlined: React.lazy(() => import('@mui/icons-material/SportsGymnasticsOutlined')),
    SportsGymnasticsRounded: React.lazy(() => import('@mui/icons-material/SportsGymnasticsRounded')),
    SportsGymnasticsSharp: React.lazy(() => import('@mui/icons-material/SportsGymnasticsSharp')),
    SportsGymnasticsTwoTone: React.lazy(() => import('@mui/icons-material/SportsGymnasticsTwoTone')),
    SportsHandball: React.lazy(() => import('@mui/icons-material/SportsHandball')),
    SportsHandballOutlined: React.lazy(() => import('@mui/icons-material/SportsHandballOutlined')),
    SportsHandballRounded: React.lazy(() => import('@mui/icons-material/SportsHandballRounded')),
    SportsHandballSharp: React.lazy(() => import('@mui/icons-material/SportsHandballSharp')),
    SportsHandballTwoTone: React.lazy(() => import('@mui/icons-material/SportsHandballTwoTone')),
    SportsHockey: React.lazy(() => import('@mui/icons-material/SportsHockey')),
    SportsHockeyOutlined: React.lazy(() => import('@mui/icons-material/SportsHockeyOutlined')),
    SportsHockeyRounded: React.lazy(() => import('@mui/icons-material/SportsHockeyRounded')),
    SportsHockeySharp: React.lazy(() => import('@mui/icons-material/SportsHockeySharp')),
    SportsHockeyTwoTone: React.lazy(() => import('@mui/icons-material/SportsHockeyTwoTone')),
    SportsKabaddi: React.lazy(() => import('@mui/icons-material/SportsKabaddi')),
    SportsKabaddiOutlined: React.lazy(() => import('@mui/icons-material/SportsKabaddiOutlined')),
    SportsKabaddiRounded: React.lazy(() => import('@mui/icons-material/SportsKabaddiRounded')),
    SportsKabaddiSharp: React.lazy(() => import('@mui/icons-material/SportsKabaddiSharp')),
    SportsKabaddiTwoTone: React.lazy(() => import('@mui/icons-material/SportsKabaddiTwoTone')),
    SportsMartialArts: React.lazy(() => import('@mui/icons-material/SportsMartialArts')),
    SportsMartialArtsOutlined: React.lazy(() => import('@mui/icons-material/SportsMartialArtsOutlined')),
    SportsMartialArtsRounded: React.lazy(() => import('@mui/icons-material/SportsMartialArtsRounded')),
    SportsMartialArtsSharp: React.lazy(() => import('@mui/icons-material/SportsMartialArtsSharp')),
    SportsMartialArtsTwoTone: React.lazy(() => import('@mui/icons-material/SportsMartialArtsTwoTone')),
    SportsMma: React.lazy(() => import('@mui/icons-material/SportsMma')),
    SportsMmaOutlined: React.lazy(() => import('@mui/icons-material/SportsMmaOutlined')),
    SportsMmaRounded: React.lazy(() => import('@mui/icons-material/SportsMmaRounded')),
    SportsMmaSharp: React.lazy(() => import('@mui/icons-material/SportsMmaSharp')),
    SportsMmaTwoTone: React.lazy(() => import('@mui/icons-material/SportsMmaTwoTone')),
    SportsMotorsports: React.lazy(() => import('@mui/icons-material/SportsMotorsports')),
    SportsMotorsportsOutlined: React.lazy(() => import('@mui/icons-material/SportsMotorsportsOutlined')),
    SportsMotorsportsRounded: React.lazy(() => import('@mui/icons-material/SportsMotorsportsRounded')),
    SportsMotorsportsSharp: React.lazy(() => import('@mui/icons-material/SportsMotorsportsSharp')),
    SportsMotorsportsTwoTone: React.lazy(() => import('@mui/icons-material/SportsMotorsportsTwoTone')),
    SportsOutlined: React.lazy(() => import('@mui/icons-material/SportsOutlined')),
    SportsRounded: React.lazy(() => import('@mui/icons-material/SportsRounded')),
    SportsRugby: React.lazy(() => import('@mui/icons-material/SportsRugby')),
    SportsRugbyOutlined: React.lazy(() => import('@mui/icons-material/SportsRugbyOutlined')),
    SportsRugbyRounded: React.lazy(() => import('@mui/icons-material/SportsRugbyRounded')),
    SportsRugbySharp: React.lazy(() => import('@mui/icons-material/SportsRugbySharp')),
    SportsRugbyTwoTone: React.lazy(() => import('@mui/icons-material/SportsRugbyTwoTone')),
    SportsScore: React.lazy(() => import('@mui/icons-material/SportsScore')),
    SportsScoreOutlined: React.lazy(() => import('@mui/icons-material/SportsScoreOutlined')),
    SportsScoreRounded: React.lazy(() => import('@mui/icons-material/SportsScoreRounded')),
    SportsScoreSharp: React.lazy(() => import('@mui/icons-material/SportsScoreSharp')),
    SportsScoreTwoTone: React.lazy(() => import('@mui/icons-material/SportsScoreTwoTone')),
    SportsSharp: React.lazy(() => import('@mui/icons-material/SportsSharp')),
    SportsSoccer: React.lazy(() => import('@mui/icons-material/SportsSoccer')),
    SportsSoccerOutlined: React.lazy(() => import('@mui/icons-material/SportsSoccerOutlined')),
    SportsSoccerRounded: React.lazy(() => import('@mui/icons-material/SportsSoccerRounded')),
    SportsSoccerSharp: React.lazy(() => import('@mui/icons-material/SportsSoccerSharp')),
    SportsSoccerTwoTone: React.lazy(() => import('@mui/icons-material/SportsSoccerTwoTone')),
    SportsTennis: React.lazy(() => import('@mui/icons-material/SportsTennis')),
    SportsTennisOutlined: React.lazy(() => import('@mui/icons-material/SportsTennisOutlined')),
    SportsTennisRounded: React.lazy(() => import('@mui/icons-material/SportsTennisRounded')),
    SportsTennisSharp: React.lazy(() => import('@mui/icons-material/SportsTennisSharp')),
    SportsTennisTwoTone: React.lazy(() => import('@mui/icons-material/SportsTennisTwoTone')),
    SportsTwoTone: React.lazy(() => import('@mui/icons-material/SportsTwoTone')),
    SportsVolleyball: React.lazy(() => import('@mui/icons-material/SportsVolleyball')),
    SportsVolleyballOutlined: React.lazy(() => import('@mui/icons-material/SportsVolleyballOutlined')),
    SportsVolleyballRounded: React.lazy(() => import('@mui/icons-material/SportsVolleyballRounded')),
    SportsVolleyballSharp: React.lazy(() => import('@mui/icons-material/SportsVolleyballSharp')),
    SportsVolleyballTwoTone: React.lazy(() => import('@mui/icons-material/SportsVolleyballTwoTone')),
    Square: React.lazy(() => import('@mui/icons-material/Square')),
    SquareFoot: React.lazy(() => import('@mui/icons-material/SquareFoot')),
    SquareFootOutlined: React.lazy(() => import('@mui/icons-material/SquareFootOutlined')),
    SquareFootRounded: React.lazy(() => import('@mui/icons-material/SquareFootRounded')),
    SquareFootSharp: React.lazy(() => import('@mui/icons-material/SquareFootSharp')),
    SquareFootTwoTone: React.lazy(() => import('@mui/icons-material/SquareFootTwoTone')),
    SquareOutlined: React.lazy(() => import('@mui/icons-material/SquareOutlined')),
    SquareRounded: React.lazy(() => import('@mui/icons-material/SquareRounded')),
    SquareSharp: React.lazy(() => import('@mui/icons-material/SquareSharp')),
    SquareTwoTone: React.lazy(() => import('@mui/icons-material/SquareTwoTone')),
    SsidChart: React.lazy(() => import('@mui/icons-material/SsidChart')),
    SsidChartOutlined: React.lazy(() => import('@mui/icons-material/SsidChartOutlined')),
    SsidChartRounded: React.lazy(() => import('@mui/icons-material/SsidChartRounded')),
    SsidChartSharp: React.lazy(() => import('@mui/icons-material/SsidChartSharp')),
    SsidChartTwoTone: React.lazy(() => import('@mui/icons-material/SsidChartTwoTone')),
    StackedBarChart: React.lazy(() => import('@mui/icons-material/StackedBarChart')),
    StackedBarChartOutlined: React.lazy(() => import('@mui/icons-material/StackedBarChartOutlined')),
    StackedBarChartRounded: React.lazy(() => import('@mui/icons-material/StackedBarChartRounded')),
    StackedBarChartSharp: React.lazy(() => import('@mui/icons-material/StackedBarChartSharp')),
    StackedBarChartTwoTone: React.lazy(() => import('@mui/icons-material/StackedBarChartTwoTone')),
    StackedLineChart: React.lazy(() => import('@mui/icons-material/StackedLineChart')),
    StackedLineChartOutlined: React.lazy(() => import('@mui/icons-material/StackedLineChartOutlined')),
    StackedLineChartRounded: React.lazy(() => import('@mui/icons-material/StackedLineChartRounded')),
    StackedLineChartSharp: React.lazy(() => import('@mui/icons-material/StackedLineChartSharp')),
    StackedLineChartTwoTone: React.lazy(() => import('@mui/icons-material/StackedLineChartTwoTone')),
    Stadium: React.lazy(() => import('@mui/icons-material/Stadium')),
    StadiumOutlined: React.lazy(() => import('@mui/icons-material/StadiumOutlined')),
    StadiumRounded: React.lazy(() => import('@mui/icons-material/StadiumRounded')),
    StadiumSharp: React.lazy(() => import('@mui/icons-material/StadiumSharp')),
    StadiumTwoTone: React.lazy(() => import('@mui/icons-material/StadiumTwoTone')),
    Stairs: React.lazy(() => import('@mui/icons-material/Stairs')),
    StairsOutlined: React.lazy(() => import('@mui/icons-material/StairsOutlined')),
    StairsRounded: React.lazy(() => import('@mui/icons-material/StairsRounded')),
    StairsSharp: React.lazy(() => import('@mui/icons-material/StairsSharp')),
    StairsTwoTone: React.lazy(() => import('@mui/icons-material/StairsTwoTone')),
    StarBorder: React.lazy(() => import('@mui/icons-material/StarBorder')),
    StarBorderOutlined: React.lazy(() => import('@mui/icons-material/StarBorderOutlined')),
    StarBorderPurple500: React.lazy(() => import('@mui/icons-material/StarBorderPurple500')),
    StarBorderPurple500Outlined: React.lazy(() => import('@mui/icons-material/StarBorderPurple500Outlined')),
    StarBorderPurple500Rounded: React.lazy(() => import('@mui/icons-material/StarBorderPurple500Rounded')),
    StarBorderPurple500Sharp: React.lazy(() => import('@mui/icons-material/StarBorderPurple500Sharp')),
    StarBorderPurple500TwoTone: React.lazy(() => import('@mui/icons-material/StarBorderPurple500TwoTone')),
    StarBorderRounded: React.lazy(() => import('@mui/icons-material/StarBorderRounded')),
    StarBorderSharp: React.lazy(() => import('@mui/icons-material/StarBorderSharp')),
    StarBorderTwoTone: React.lazy(() => import('@mui/icons-material/StarBorderTwoTone')),
    Star: React.lazy(() => import('@mui/icons-material/Star')),
    StarHalf: React.lazy(() => import('@mui/icons-material/StarHalf')),
    StarHalfOutlined: React.lazy(() => import('@mui/icons-material/StarHalfOutlined')),
    StarHalfRounded: React.lazy(() => import('@mui/icons-material/StarHalfRounded')),
    StarHalfSharp: React.lazy(() => import('@mui/icons-material/StarHalfSharp')),
    StarHalfTwoTone: React.lazy(() => import('@mui/icons-material/StarHalfTwoTone')),
    StarOutlined: React.lazy(() => import('@mui/icons-material/StarOutlined')),
    StarOutline: React.lazy(() => import('@mui/icons-material/StarOutline')),
    StarOutlineOutlined: React.lazy(() => import('@mui/icons-material/StarOutlineOutlined')),
    StarOutlineRounded: React.lazy(() => import('@mui/icons-material/StarOutlineRounded')),
    StarOutlineSharp: React.lazy(() => import('@mui/icons-material/StarOutlineSharp')),
    StarOutlineTwoTone: React.lazy(() => import('@mui/icons-material/StarOutlineTwoTone')),
    StarPurple500: React.lazy(() => import('@mui/icons-material/StarPurple500')),
    StarPurple500Outlined: React.lazy(() => import('@mui/icons-material/StarPurple500Outlined')),
    StarPurple500Rounded: React.lazy(() => import('@mui/icons-material/StarPurple500Rounded')),
    StarPurple500Sharp: React.lazy(() => import('@mui/icons-material/StarPurple500Sharp')),
    StarPurple500TwoTone: React.lazy(() => import('@mui/icons-material/StarPurple500TwoTone')),
    StarRate: React.lazy(() => import('@mui/icons-material/StarRate')),
    StarRateOutlined: React.lazy(() => import('@mui/icons-material/StarRateOutlined')),
    StarRateRounded: React.lazy(() => import('@mui/icons-material/StarRateRounded')),
    StarRateSharp: React.lazy(() => import('@mui/icons-material/StarRateSharp')),
    StarRateTwoTone: React.lazy(() => import('@mui/icons-material/StarRateTwoTone')),
    StarRounded: React.lazy(() => import('@mui/icons-material/StarRounded')),
    Stars: React.lazy(() => import('@mui/icons-material/Stars')),
    StarSharp: React.lazy(() => import('@mui/icons-material/StarSharp')),
    StarsOutlined: React.lazy(() => import('@mui/icons-material/StarsOutlined')),
    StarsRounded: React.lazy(() => import('@mui/icons-material/StarsRounded')),
    StarsSharp: React.lazy(() => import('@mui/icons-material/StarsSharp')),
    StarsTwoTone: React.lazy(() => import('@mui/icons-material/StarsTwoTone')),
    Start: React.lazy(() => import('@mui/icons-material/Start')),
    StartOutlined: React.lazy(() => import('@mui/icons-material/StartOutlined')),
    StartRounded: React.lazy(() => import('@mui/icons-material/StartRounded')),
    StartSharp: React.lazy(() => import('@mui/icons-material/StartSharp')),
    StartTwoTone: React.lazy(() => import('@mui/icons-material/StartTwoTone')),
    StarTwoTone: React.lazy(() => import('@mui/icons-material/StarTwoTone')),
    StayCurrentLandscape: React.lazy(() => import('@mui/icons-material/StayCurrentLandscape')),
    StayCurrentLandscapeOutlined: React.lazy(() => import('@mui/icons-material/StayCurrentLandscapeOutlined')),
    StayCurrentLandscapeRounded: React.lazy(() => import('@mui/icons-material/StayCurrentLandscapeRounded')),
    StayCurrentLandscapeSharp: React.lazy(() => import('@mui/icons-material/StayCurrentLandscapeSharp')),
    StayCurrentLandscapeTwoTone: React.lazy(() => import('@mui/icons-material/StayCurrentLandscapeTwoTone')),
    StayCurrentPortrait: React.lazy(() => import('@mui/icons-material/StayCurrentPortrait')),
    StayCurrentPortraitOutlined: React.lazy(() => import('@mui/icons-material/StayCurrentPortraitOutlined')),
    StayCurrentPortraitRounded: React.lazy(() => import('@mui/icons-material/StayCurrentPortraitRounded')),
    StayCurrentPortraitSharp: React.lazy(() => import('@mui/icons-material/StayCurrentPortraitSharp')),
    StayCurrentPortraitTwoTone: React.lazy(() => import('@mui/icons-material/StayCurrentPortraitTwoTone')),
    StayPrimaryLandscape: React.lazy(() => import('@mui/icons-material/StayPrimaryLandscape')),
    StayPrimaryLandscapeOutlined: React.lazy(() => import('@mui/icons-material/StayPrimaryLandscapeOutlined')),
    StayPrimaryLandscapeRounded: React.lazy(() => import('@mui/icons-material/StayPrimaryLandscapeRounded')),
    StayPrimaryLandscapeSharp: React.lazy(() => import('@mui/icons-material/StayPrimaryLandscapeSharp')),
    StayPrimaryLandscapeTwoTone: React.lazy(() => import('@mui/icons-material/StayPrimaryLandscapeTwoTone')),
    StayPrimaryPortrait: React.lazy(() => import('@mui/icons-material/StayPrimaryPortrait')),
    StayPrimaryPortraitOutlined: React.lazy(() => import('@mui/icons-material/StayPrimaryPortraitOutlined')),
    StayPrimaryPortraitRounded: React.lazy(() => import('@mui/icons-material/StayPrimaryPortraitRounded')),
    StayPrimaryPortraitSharp: React.lazy(() => import('@mui/icons-material/StayPrimaryPortraitSharp')),
    StayPrimaryPortraitTwoTone: React.lazy(() => import('@mui/icons-material/StayPrimaryPortraitTwoTone')),
    StickyNote2: React.lazy(() => import('@mui/icons-material/StickyNote2')),
    StickyNote2Outlined: React.lazy(() => import('@mui/icons-material/StickyNote2Outlined')),
    StickyNote2Rounded: React.lazy(() => import('@mui/icons-material/StickyNote2Rounded')),
    StickyNote2Sharp: React.lazy(() => import('@mui/icons-material/StickyNote2Sharp')),
    StickyNote2TwoTone: React.lazy(() => import('@mui/icons-material/StickyNote2TwoTone')),
    StopCircle: React.lazy(() => import('@mui/icons-material/StopCircle')),
    StopCircleOutlined: React.lazy(() => import('@mui/icons-material/StopCircleOutlined')),
    StopCircleRounded: React.lazy(() => import('@mui/icons-material/StopCircleRounded')),
    StopCircleSharp: React.lazy(() => import('@mui/icons-material/StopCircleSharp')),
    StopCircleTwoTone: React.lazy(() => import('@mui/icons-material/StopCircleTwoTone')),
    Stop: React.lazy(() => import('@mui/icons-material/Stop')),
    StopOutlined: React.lazy(() => import('@mui/icons-material/StopOutlined')),
    StopRounded: React.lazy(() => import('@mui/icons-material/StopRounded')),
    StopScreenShare: React.lazy(() => import('@mui/icons-material/StopScreenShare')),
    StopScreenShareOutlined: React.lazy(() => import('@mui/icons-material/StopScreenShareOutlined')),
    StopScreenShareRounded: React.lazy(() => import('@mui/icons-material/StopScreenShareRounded')),
    StopScreenShareSharp: React.lazy(() => import('@mui/icons-material/StopScreenShareSharp')),
    StopScreenShareTwoTone: React.lazy(() => import('@mui/icons-material/StopScreenShareTwoTone')),
    StopSharp: React.lazy(() => import('@mui/icons-material/StopSharp')),
    StopTwoTone: React.lazy(() => import('@mui/icons-material/StopTwoTone')),
    Storage: React.lazy(() => import('@mui/icons-material/Storage')),
    StorageOutlined: React.lazy(() => import('@mui/icons-material/StorageOutlined')),
    StorageRounded: React.lazy(() => import('@mui/icons-material/StorageRounded')),
    StorageSharp: React.lazy(() => import('@mui/icons-material/StorageSharp')),
    StorageTwoTone: React.lazy(() => import('@mui/icons-material/StorageTwoTone')),
    Store: React.lazy(() => import('@mui/icons-material/Store')),
    Storefront: React.lazy(() => import('@mui/icons-material/Storefront')),
    StorefrontOutlined: React.lazy(() => import('@mui/icons-material/StorefrontOutlined')),
    StorefrontRounded: React.lazy(() => import('@mui/icons-material/StorefrontRounded')),
    StorefrontSharp: React.lazy(() => import('@mui/icons-material/StorefrontSharp')),
    StorefrontTwoTone: React.lazy(() => import('@mui/icons-material/StorefrontTwoTone')),
    StoreMallDirectory: React.lazy(() => import('@mui/icons-material/StoreMallDirectory')),
    StoreMallDirectoryOutlined: React.lazy(() => import('@mui/icons-material/StoreMallDirectoryOutlined')),
    StoreMallDirectoryRounded: React.lazy(() => import('@mui/icons-material/StoreMallDirectoryRounded')),
    StoreMallDirectorySharp: React.lazy(() => import('@mui/icons-material/StoreMallDirectorySharp')),
    StoreMallDirectoryTwoTone: React.lazy(() => import('@mui/icons-material/StoreMallDirectoryTwoTone')),
    StoreOutlined: React.lazy(() => import('@mui/icons-material/StoreOutlined')),
    StoreRounded: React.lazy(() => import('@mui/icons-material/StoreRounded')),
    StoreSharp: React.lazy(() => import('@mui/icons-material/StoreSharp')),
    StoreTwoTone: React.lazy(() => import('@mui/icons-material/StoreTwoTone')),
    Storm: React.lazy(() => import('@mui/icons-material/Storm')),
    StormOutlined: React.lazy(() => import('@mui/icons-material/StormOutlined')),
    StormRounded: React.lazy(() => import('@mui/icons-material/StormRounded')),
    StormSharp: React.lazy(() => import('@mui/icons-material/StormSharp')),
    StormTwoTone: React.lazy(() => import('@mui/icons-material/StormTwoTone')),
    Straight: React.lazy(() => import('@mui/icons-material/Straight')),
    Straighten: React.lazy(() => import('@mui/icons-material/Straighten')),
    StraightenOutlined: React.lazy(() => import('@mui/icons-material/StraightenOutlined')),
    StraightenRounded: React.lazy(() => import('@mui/icons-material/StraightenRounded')),
    StraightenSharp: React.lazy(() => import('@mui/icons-material/StraightenSharp')),
    StraightenTwoTone: React.lazy(() => import('@mui/icons-material/StraightenTwoTone')),
    StraightOutlined: React.lazy(() => import('@mui/icons-material/StraightOutlined')),
    StraightRounded: React.lazy(() => import('@mui/icons-material/StraightRounded')),
    StraightSharp: React.lazy(() => import('@mui/icons-material/StraightSharp')),
    StraightTwoTone: React.lazy(() => import('@mui/icons-material/StraightTwoTone')),
    Stream: React.lazy(() => import('@mui/icons-material/Stream')),
    StreamOutlined: React.lazy(() => import('@mui/icons-material/StreamOutlined')),
    StreamRounded: React.lazy(() => import('@mui/icons-material/StreamRounded')),
    StreamSharp: React.lazy(() => import('@mui/icons-material/StreamSharp')),
    StreamTwoTone: React.lazy(() => import('@mui/icons-material/StreamTwoTone')),
    Streetview: React.lazy(() => import('@mui/icons-material/Streetview')),
    StreetviewOutlined: React.lazy(() => import('@mui/icons-material/StreetviewOutlined')),
    StreetviewRounded: React.lazy(() => import('@mui/icons-material/StreetviewRounded')),
    StreetviewSharp: React.lazy(() => import('@mui/icons-material/StreetviewSharp')),
    StreetviewTwoTone: React.lazy(() => import('@mui/icons-material/StreetviewTwoTone')),
    StrikethroughS: React.lazy(() => import('@mui/icons-material/StrikethroughS')),
    StrikethroughSOutlined: React.lazy(() => import('@mui/icons-material/StrikethroughSOutlined')),
    StrikethroughSRounded: React.lazy(() => import('@mui/icons-material/StrikethroughSRounded')),
    StrikethroughSSharp: React.lazy(() => import('@mui/icons-material/StrikethroughSSharp')),
    StrikethroughSTwoTone: React.lazy(() => import('@mui/icons-material/StrikethroughSTwoTone')),
    Stroller: React.lazy(() => import('@mui/icons-material/Stroller')),
    StrollerOutlined: React.lazy(() => import('@mui/icons-material/StrollerOutlined')),
    StrollerRounded: React.lazy(() => import('@mui/icons-material/StrollerRounded')),
    StrollerSharp: React.lazy(() => import('@mui/icons-material/StrollerSharp')),
    StrollerTwoTone: React.lazy(() => import('@mui/icons-material/StrollerTwoTone')),
    Style: React.lazy(() => import('@mui/icons-material/Style')),
    StyleOutlined: React.lazy(() => import('@mui/icons-material/StyleOutlined')),
    StyleRounded: React.lazy(() => import('@mui/icons-material/StyleRounded')),
    StyleSharp: React.lazy(() => import('@mui/icons-material/StyleSharp')),
    StyleTwoTone: React.lazy(() => import('@mui/icons-material/StyleTwoTone')),
    SubdirectoryArrowLeft: React.lazy(() => import('@mui/icons-material/SubdirectoryArrowLeft')),
    SubdirectoryArrowLeftOutlined: React.lazy(() => import('@mui/icons-material/SubdirectoryArrowLeftOutlined')),
    SubdirectoryArrowLeftRounded: React.lazy(() => import('@mui/icons-material/SubdirectoryArrowLeftRounded')),
    SubdirectoryArrowLeftSharp: React.lazy(() => import('@mui/icons-material/SubdirectoryArrowLeftSharp')),
    SubdirectoryArrowLeftTwoTone: React.lazy(() => import('@mui/icons-material/SubdirectoryArrowLeftTwoTone')),
    SubdirectoryArrowRight: React.lazy(() => import('@mui/icons-material/SubdirectoryArrowRight')),
    SubdirectoryArrowRightOutlined: React.lazy(() => import('@mui/icons-material/SubdirectoryArrowRightOutlined')),
    SubdirectoryArrowRightRounded: React.lazy(() => import('@mui/icons-material/SubdirectoryArrowRightRounded')),
    SubdirectoryArrowRightSharp: React.lazy(() => import('@mui/icons-material/SubdirectoryArrowRightSharp')),
    SubdirectoryArrowRightTwoTone: React.lazy(() => import('@mui/icons-material/SubdirectoryArrowRightTwoTone')),
    Subject: React.lazy(() => import('@mui/icons-material/Subject')),
    SubjectOutlined: React.lazy(() => import('@mui/icons-material/SubjectOutlined')),
    SubjectRounded: React.lazy(() => import('@mui/icons-material/SubjectRounded')),
    SubjectSharp: React.lazy(() => import('@mui/icons-material/SubjectSharp')),
    SubjectTwoTone: React.lazy(() => import('@mui/icons-material/SubjectTwoTone')),
    Subscript: React.lazy(() => import('@mui/icons-material/Subscript')),
    Subscriptions: React.lazy(() => import('@mui/icons-material/Subscriptions')),
    SubscriptionsOutlined: React.lazy(() => import('@mui/icons-material/SubscriptionsOutlined')),
    SubscriptionsRounded: React.lazy(() => import('@mui/icons-material/SubscriptionsRounded')),
    SubscriptionsSharp: React.lazy(() => import('@mui/icons-material/SubscriptionsSharp')),
    SubscriptionsTwoTone: React.lazy(() => import('@mui/icons-material/SubscriptionsTwoTone')),
    SubscriptOutlined: React.lazy(() => import('@mui/icons-material/SubscriptOutlined')),
    SubscriptRounded: React.lazy(() => import('@mui/icons-material/SubscriptRounded')),
    SubscriptSharp: React.lazy(() => import('@mui/icons-material/SubscriptSharp')),
    SubscriptTwoTone: React.lazy(() => import('@mui/icons-material/SubscriptTwoTone')),
    Subtitles: React.lazy(() => import('@mui/icons-material/Subtitles')),
    SubtitlesOff: React.lazy(() => import('@mui/icons-material/SubtitlesOff')),
    SubtitlesOffOutlined: React.lazy(() => import('@mui/icons-material/SubtitlesOffOutlined')),
    SubtitlesOffRounded: React.lazy(() => import('@mui/icons-material/SubtitlesOffRounded')),
    SubtitlesOffSharp: React.lazy(() => import('@mui/icons-material/SubtitlesOffSharp')),
    SubtitlesOffTwoTone: React.lazy(() => import('@mui/icons-material/SubtitlesOffTwoTone')),
    SubtitlesOutlined: React.lazy(() => import('@mui/icons-material/SubtitlesOutlined')),
    SubtitlesRounded: React.lazy(() => import('@mui/icons-material/SubtitlesRounded')),
    SubtitlesSharp: React.lazy(() => import('@mui/icons-material/SubtitlesSharp')),
    SubtitlesTwoTone: React.lazy(() => import('@mui/icons-material/SubtitlesTwoTone')),
    Subway: React.lazy(() => import('@mui/icons-material/Subway')),
    SubwayOutlined: React.lazy(() => import('@mui/icons-material/SubwayOutlined')),
    SubwayRounded: React.lazy(() => import('@mui/icons-material/SubwayRounded')),
    SubwaySharp: React.lazy(() => import('@mui/icons-material/SubwaySharp')),
    SubwayTwoTone: React.lazy(() => import('@mui/icons-material/SubwayTwoTone')),
    Summarize: React.lazy(() => import('@mui/icons-material/Summarize')),
    SummarizeOutlined: React.lazy(() => import('@mui/icons-material/SummarizeOutlined')),
    SummarizeRounded: React.lazy(() => import('@mui/icons-material/SummarizeRounded')),
    SummarizeSharp: React.lazy(() => import('@mui/icons-material/SummarizeSharp')),
    SummarizeTwoTone: React.lazy(() => import('@mui/icons-material/SummarizeTwoTone')),
    Superscript: React.lazy(() => import('@mui/icons-material/Superscript')),
    SuperscriptOutlined: React.lazy(() => import('@mui/icons-material/SuperscriptOutlined')),
    SuperscriptRounded: React.lazy(() => import('@mui/icons-material/SuperscriptRounded')),
    SuperscriptSharp: React.lazy(() => import('@mui/icons-material/SuperscriptSharp')),
    SuperscriptTwoTone: React.lazy(() => import('@mui/icons-material/SuperscriptTwoTone')),
    SupervisedUserCircle: React.lazy(() => import('@mui/icons-material/SupervisedUserCircle')),
    SupervisedUserCircleOutlined: React.lazy(() => import('@mui/icons-material/SupervisedUserCircleOutlined')),
    SupervisedUserCircleRounded: React.lazy(() => import('@mui/icons-material/SupervisedUserCircleRounded')),
    SupervisedUserCircleSharp: React.lazy(() => import('@mui/icons-material/SupervisedUserCircleSharp')),
    SupervisedUserCircleTwoTone: React.lazy(() => import('@mui/icons-material/SupervisedUserCircleTwoTone')),
    SupervisorAccount: React.lazy(() => import('@mui/icons-material/SupervisorAccount')),
    SupervisorAccountOutlined: React.lazy(() => import('@mui/icons-material/SupervisorAccountOutlined')),
    SupervisorAccountRounded: React.lazy(() => import('@mui/icons-material/SupervisorAccountRounded')),
    SupervisorAccountSharp: React.lazy(() => import('@mui/icons-material/SupervisorAccountSharp')),
    SupervisorAccountTwoTone: React.lazy(() => import('@mui/icons-material/SupervisorAccountTwoTone')),
    SupportAgent: React.lazy(() => import('@mui/icons-material/SupportAgent')),
    SupportAgentOutlined: React.lazy(() => import('@mui/icons-material/SupportAgentOutlined')),
    SupportAgentRounded: React.lazy(() => import('@mui/icons-material/SupportAgentRounded')),
    SupportAgentSharp: React.lazy(() => import('@mui/icons-material/SupportAgentSharp')),
    SupportAgentTwoTone: React.lazy(() => import('@mui/icons-material/SupportAgentTwoTone')),
    Support: React.lazy(() => import('@mui/icons-material/Support')),
    SupportOutlined: React.lazy(() => import('@mui/icons-material/SupportOutlined')),
    SupportRounded: React.lazy(() => import('@mui/icons-material/SupportRounded')),
    SupportSharp: React.lazy(() => import('@mui/icons-material/SupportSharp')),
    SupportTwoTone: React.lazy(() => import('@mui/icons-material/SupportTwoTone')),
    Surfing: React.lazy(() => import('@mui/icons-material/Surfing')),
    SurfingOutlined: React.lazy(() => import('@mui/icons-material/SurfingOutlined')),
    SurfingRounded: React.lazy(() => import('@mui/icons-material/SurfingRounded')),
    SurfingSharp: React.lazy(() => import('@mui/icons-material/SurfingSharp')),
    SurfingTwoTone: React.lazy(() => import('@mui/icons-material/SurfingTwoTone')),
    SurroundSound: React.lazy(() => import('@mui/icons-material/SurroundSound')),
    SurroundSoundOutlined: React.lazy(() => import('@mui/icons-material/SurroundSoundOutlined')),
    SurroundSoundRounded: React.lazy(() => import('@mui/icons-material/SurroundSoundRounded')),
    SurroundSoundSharp: React.lazy(() => import('@mui/icons-material/SurroundSoundSharp')),
    SurroundSoundTwoTone: React.lazy(() => import('@mui/icons-material/SurroundSoundTwoTone')),
    SwapCalls: React.lazy(() => import('@mui/icons-material/SwapCalls')),
    SwapCallsOutlined: React.lazy(() => import('@mui/icons-material/SwapCallsOutlined')),
    SwapCallsRounded: React.lazy(() => import('@mui/icons-material/SwapCallsRounded')),
    SwapCallsSharp: React.lazy(() => import('@mui/icons-material/SwapCallsSharp')),
    SwapCallsTwoTone: React.lazy(() => import('@mui/icons-material/SwapCallsTwoTone')),
    SwapHoriz: React.lazy(() => import('@mui/icons-material/SwapHoriz')),
    SwapHorizontalCircle: React.lazy(() => import('@mui/icons-material/SwapHorizontalCircle')),
    SwapHorizontalCircleOutlined: React.lazy(() => import('@mui/icons-material/SwapHorizontalCircleOutlined')),
    SwapHorizontalCircleRounded: React.lazy(() => import('@mui/icons-material/SwapHorizontalCircleRounded')),
    SwapHorizontalCircleSharp: React.lazy(() => import('@mui/icons-material/SwapHorizontalCircleSharp')),
    SwapHorizontalCircleTwoTone: React.lazy(() => import('@mui/icons-material/SwapHorizontalCircleTwoTone')),
    SwapHorizOutlined: React.lazy(() => import('@mui/icons-material/SwapHorizOutlined')),
    SwapHorizRounded: React.lazy(() => import('@mui/icons-material/SwapHorizRounded')),
    SwapHorizSharp: React.lazy(() => import('@mui/icons-material/SwapHorizSharp')),
    SwapHorizTwoTone: React.lazy(() => import('@mui/icons-material/SwapHorizTwoTone')),
    SwapVert: React.lazy(() => import('@mui/icons-material/SwapVert')),
    SwapVerticalCircle: React.lazy(() => import('@mui/icons-material/SwapVerticalCircle')),
    SwapVerticalCircleOutlined: React.lazy(() => import('@mui/icons-material/SwapVerticalCircleOutlined')),
    SwapVerticalCircleRounded: React.lazy(() => import('@mui/icons-material/SwapVerticalCircleRounded')),
    SwapVerticalCircleSharp: React.lazy(() => import('@mui/icons-material/SwapVerticalCircleSharp')),
    SwapVerticalCircleTwoTone: React.lazy(() => import('@mui/icons-material/SwapVerticalCircleTwoTone')),
    SwapVertOutlined: React.lazy(() => import('@mui/icons-material/SwapVertOutlined')),
    SwapVertRounded: React.lazy(() => import('@mui/icons-material/SwapVertRounded')),
    SwapVertSharp: React.lazy(() => import('@mui/icons-material/SwapVertSharp')),
    SwapVertTwoTone: React.lazy(() => import('@mui/icons-material/SwapVertTwoTone')),
    SwipeDownAlt: React.lazy(() => import('@mui/icons-material/SwipeDownAlt')),
    SwipeDownAltOutlined: React.lazy(() => import('@mui/icons-material/SwipeDownAltOutlined')),
    SwipeDownAltRounded: React.lazy(() => import('@mui/icons-material/SwipeDownAltRounded')),
    SwipeDownAltSharp: React.lazy(() => import('@mui/icons-material/SwipeDownAltSharp')),
    SwipeDownAltTwoTone: React.lazy(() => import('@mui/icons-material/SwipeDownAltTwoTone')),
    SwipeDown: React.lazy(() => import('@mui/icons-material/SwipeDown')),
    SwipeDownOutlined: React.lazy(() => import('@mui/icons-material/SwipeDownOutlined')),
    SwipeDownRounded: React.lazy(() => import('@mui/icons-material/SwipeDownRounded')),
    SwipeDownSharp: React.lazy(() => import('@mui/icons-material/SwipeDownSharp')),
    SwipeDownTwoTone: React.lazy(() => import('@mui/icons-material/SwipeDownTwoTone')),
    Swipe: React.lazy(() => import('@mui/icons-material/Swipe')),
    SwipeLeftAlt: React.lazy(() => import('@mui/icons-material/SwipeLeftAlt')),
    SwipeLeftAltOutlined: React.lazy(() => import('@mui/icons-material/SwipeLeftAltOutlined')),
    SwipeLeftAltRounded: React.lazy(() => import('@mui/icons-material/SwipeLeftAltRounded')),
    SwipeLeftAltSharp: React.lazy(() => import('@mui/icons-material/SwipeLeftAltSharp')),
    SwipeLeftAltTwoTone: React.lazy(() => import('@mui/icons-material/SwipeLeftAltTwoTone')),
    SwipeLeft: React.lazy(() => import('@mui/icons-material/SwipeLeft')),
    SwipeLeftOutlined: React.lazy(() => import('@mui/icons-material/SwipeLeftOutlined')),
    SwipeLeftRounded: React.lazy(() => import('@mui/icons-material/SwipeLeftRounded')),
    SwipeLeftSharp: React.lazy(() => import('@mui/icons-material/SwipeLeftSharp')),
    SwipeLeftTwoTone: React.lazy(() => import('@mui/icons-material/SwipeLeftTwoTone')),
    SwipeOutlined: React.lazy(() => import('@mui/icons-material/SwipeOutlined')),
    SwipeRightAlt: React.lazy(() => import('@mui/icons-material/SwipeRightAlt')),
    SwipeRightAltOutlined: React.lazy(() => import('@mui/icons-material/SwipeRightAltOutlined')),
    SwipeRightAltRounded: React.lazy(() => import('@mui/icons-material/SwipeRightAltRounded')),
    SwipeRightAltSharp: React.lazy(() => import('@mui/icons-material/SwipeRightAltSharp')),
    SwipeRightAltTwoTone: React.lazy(() => import('@mui/icons-material/SwipeRightAltTwoTone')),
    SwipeRight: React.lazy(() => import('@mui/icons-material/SwipeRight')),
    SwipeRightOutlined: React.lazy(() => import('@mui/icons-material/SwipeRightOutlined')),
    SwipeRightRounded: React.lazy(() => import('@mui/icons-material/SwipeRightRounded')),
    SwipeRightSharp: React.lazy(() => import('@mui/icons-material/SwipeRightSharp')),
    SwipeRightTwoTone: React.lazy(() => import('@mui/icons-material/SwipeRightTwoTone')),
    SwipeRounded: React.lazy(() => import('@mui/icons-material/SwipeRounded')),
    SwipeSharp: React.lazy(() => import('@mui/icons-material/SwipeSharp')),
    SwipeTwoTone: React.lazy(() => import('@mui/icons-material/SwipeTwoTone')),
    SwipeUpAlt: React.lazy(() => import('@mui/icons-material/SwipeUpAlt')),
    SwipeUpAltOutlined: React.lazy(() => import('@mui/icons-material/SwipeUpAltOutlined')),
    SwipeUpAltRounded: React.lazy(() => import('@mui/icons-material/SwipeUpAltRounded')),
    SwipeUpAltSharp: React.lazy(() => import('@mui/icons-material/SwipeUpAltSharp')),
    SwipeUpAltTwoTone: React.lazy(() => import('@mui/icons-material/SwipeUpAltTwoTone')),
    SwipeUp: React.lazy(() => import('@mui/icons-material/SwipeUp')),
    SwipeUpOutlined: React.lazy(() => import('@mui/icons-material/SwipeUpOutlined')),
    SwipeUpRounded: React.lazy(() => import('@mui/icons-material/SwipeUpRounded')),
    SwipeUpSharp: React.lazy(() => import('@mui/icons-material/SwipeUpSharp')),
    SwipeUpTwoTone: React.lazy(() => import('@mui/icons-material/SwipeUpTwoTone')),
    SwipeVertical: React.lazy(() => import('@mui/icons-material/SwipeVertical')),
    SwipeVerticalOutlined: React.lazy(() => import('@mui/icons-material/SwipeVerticalOutlined')),
    SwipeVerticalRounded: React.lazy(() => import('@mui/icons-material/SwipeVerticalRounded')),
    SwipeVerticalSharp: React.lazy(() => import('@mui/icons-material/SwipeVerticalSharp')),
    SwipeVerticalTwoTone: React.lazy(() => import('@mui/icons-material/SwipeVerticalTwoTone')),
    SwitchAccessShortcutAdd: React.lazy(() => import('@mui/icons-material/SwitchAccessShortcutAdd')),
    SwitchAccessShortcutAddOutlined: React.lazy(() => import('@mui/icons-material/SwitchAccessShortcutAddOutlined')),
    SwitchAccessShortcutAddRounded: React.lazy(() => import('@mui/icons-material/SwitchAccessShortcutAddRounded')),
    SwitchAccessShortcutAddSharp: React.lazy(() => import('@mui/icons-material/SwitchAccessShortcutAddSharp')),
    SwitchAccessShortcutAddTwoTone: React.lazy(() => import('@mui/icons-material/SwitchAccessShortcutAddTwoTone')),
    SwitchAccessShortcut: React.lazy(() => import('@mui/icons-material/SwitchAccessShortcut')),
    SwitchAccessShortcutOutlined: React.lazy(() => import('@mui/icons-material/SwitchAccessShortcutOutlined')),
    SwitchAccessShortcutRounded: React.lazy(() => import('@mui/icons-material/SwitchAccessShortcutRounded')),
    SwitchAccessShortcutSharp: React.lazy(() => import('@mui/icons-material/SwitchAccessShortcutSharp')),
    SwitchAccessShortcutTwoTone: React.lazy(() => import('@mui/icons-material/SwitchAccessShortcutTwoTone')),
    SwitchAccount: React.lazy(() => import('@mui/icons-material/SwitchAccount')),
    SwitchAccountOutlined: React.lazy(() => import('@mui/icons-material/SwitchAccountOutlined')),
    SwitchAccountRounded: React.lazy(() => import('@mui/icons-material/SwitchAccountRounded')),
    SwitchAccountSharp: React.lazy(() => import('@mui/icons-material/SwitchAccountSharp')),
    SwitchAccountTwoTone: React.lazy(() => import('@mui/icons-material/SwitchAccountTwoTone')),
    SwitchCamera: React.lazy(() => import('@mui/icons-material/SwitchCamera')),
    SwitchCameraOutlined: React.lazy(() => import('@mui/icons-material/SwitchCameraOutlined')),
    SwitchCameraRounded: React.lazy(() => import('@mui/icons-material/SwitchCameraRounded')),
    SwitchCameraSharp: React.lazy(() => import('@mui/icons-material/SwitchCameraSharp')),
    SwitchCameraTwoTone: React.lazy(() => import('@mui/icons-material/SwitchCameraTwoTone')),
    SwitchLeft: React.lazy(() => import('@mui/icons-material/SwitchLeft')),
    SwitchLeftOutlined: React.lazy(() => import('@mui/icons-material/SwitchLeftOutlined')),
    SwitchLeftRounded: React.lazy(() => import('@mui/icons-material/SwitchLeftRounded')),
    SwitchLeftSharp: React.lazy(() => import('@mui/icons-material/SwitchLeftSharp')),
    SwitchLeftTwoTone: React.lazy(() => import('@mui/icons-material/SwitchLeftTwoTone')),
    SwitchRight: React.lazy(() => import('@mui/icons-material/SwitchRight')),
    SwitchRightOutlined: React.lazy(() => import('@mui/icons-material/SwitchRightOutlined')),
    SwitchRightRounded: React.lazy(() => import('@mui/icons-material/SwitchRightRounded')),
    SwitchRightSharp: React.lazy(() => import('@mui/icons-material/SwitchRightSharp')),
    SwitchRightTwoTone: React.lazy(() => import('@mui/icons-material/SwitchRightTwoTone')),
    SwitchVideo: React.lazy(() => import('@mui/icons-material/SwitchVideo')),
    SwitchVideoOutlined: React.lazy(() => import('@mui/icons-material/SwitchVideoOutlined')),
    SwitchVideoRounded: React.lazy(() => import('@mui/icons-material/SwitchVideoRounded')),
    SwitchVideoSharp: React.lazy(() => import('@mui/icons-material/SwitchVideoSharp')),
    SwitchVideoTwoTone: React.lazy(() => import('@mui/icons-material/SwitchVideoTwoTone')),
    Synagogue: React.lazy(() => import('@mui/icons-material/Synagogue')),
    SynagogueOutlined: React.lazy(() => import('@mui/icons-material/SynagogueOutlined')),
    SynagogueRounded: React.lazy(() => import('@mui/icons-material/SynagogueRounded')),
    SynagogueSharp: React.lazy(() => import('@mui/icons-material/SynagogueSharp')),
    SynagogueTwoTone: React.lazy(() => import('@mui/icons-material/SynagogueTwoTone')),
    SyncAlt: React.lazy(() => import('@mui/icons-material/SyncAlt')),
    SyncAltOutlined: React.lazy(() => import('@mui/icons-material/SyncAltOutlined')),
    SyncAltRounded: React.lazy(() => import('@mui/icons-material/SyncAltRounded')),
    SyncAltSharp: React.lazy(() => import('@mui/icons-material/SyncAltSharp')),
    SyncAltTwoTone: React.lazy(() => import('@mui/icons-material/SyncAltTwoTone')),
    SyncDisabled: React.lazy(() => import('@mui/icons-material/SyncDisabled')),
    SyncDisabledOutlined: React.lazy(() => import('@mui/icons-material/SyncDisabledOutlined')),
    SyncDisabledRounded: React.lazy(() => import('@mui/icons-material/SyncDisabledRounded')),
    SyncDisabledSharp: React.lazy(() => import('@mui/icons-material/SyncDisabledSharp')),
    SyncDisabledTwoTone: React.lazy(() => import('@mui/icons-material/SyncDisabledTwoTone')),
    Sync: React.lazy(() => import('@mui/icons-material/Sync')),
    SyncLock: React.lazy(() => import('@mui/icons-material/SyncLock')),
    SyncLockOutlined: React.lazy(() => import('@mui/icons-material/SyncLockOutlined')),
    SyncLockRounded: React.lazy(() => import('@mui/icons-material/SyncLockRounded')),
    SyncLockSharp: React.lazy(() => import('@mui/icons-material/SyncLockSharp')),
    SyncLockTwoTone: React.lazy(() => import('@mui/icons-material/SyncLockTwoTone')),
    SyncOutlined: React.lazy(() => import('@mui/icons-material/SyncOutlined')),
    SyncProblem: React.lazy(() => import('@mui/icons-material/SyncProblem')),
    SyncProblemOutlined: React.lazy(() => import('@mui/icons-material/SyncProblemOutlined')),
    SyncProblemRounded: React.lazy(() => import('@mui/icons-material/SyncProblemRounded')),
    SyncProblemSharp: React.lazy(() => import('@mui/icons-material/SyncProblemSharp')),
    SyncProblemTwoTone: React.lazy(() => import('@mui/icons-material/SyncProblemTwoTone')),
    SyncRounded: React.lazy(() => import('@mui/icons-material/SyncRounded')),
    SyncSharp: React.lazy(() => import('@mui/icons-material/SyncSharp')),
    SyncTwoTone: React.lazy(() => import('@mui/icons-material/SyncTwoTone')),
    SystemSecurityUpdate: React.lazy(() => import('@mui/icons-material/SystemSecurityUpdate')),
    SystemSecurityUpdateGood: React.lazy(() => import('@mui/icons-material/SystemSecurityUpdateGood')),
    SystemSecurityUpdateGoodOutlined: React.lazy(() => import('@mui/icons-material/SystemSecurityUpdateGoodOutlined')),
    SystemSecurityUpdateGoodRounded: React.lazy(() => import('@mui/icons-material/SystemSecurityUpdateGoodRounded')),
    SystemSecurityUpdateGoodSharp: React.lazy(() => import('@mui/icons-material/SystemSecurityUpdateGoodSharp')),
    SystemSecurityUpdateGoodTwoTone: React.lazy(() => import('@mui/icons-material/SystemSecurityUpdateGoodTwoTone')),
    SystemSecurityUpdateOutlined: React.lazy(() => import('@mui/icons-material/SystemSecurityUpdateOutlined')),
    SystemSecurityUpdateRounded: React.lazy(() => import('@mui/icons-material/SystemSecurityUpdateRounded')),
    SystemSecurityUpdateSharp: React.lazy(() => import('@mui/icons-material/SystemSecurityUpdateSharp')),
    SystemSecurityUpdateTwoTone: React.lazy(() => import('@mui/icons-material/SystemSecurityUpdateTwoTone')),
    SystemSecurityUpdateWarning: React.lazy(() => import('@mui/icons-material/SystemSecurityUpdateWarning')),
    SystemSecurityUpdateWarningOutlined: React.lazy(() => import('@mui/icons-material/SystemSecurityUpdateWarningOutlined')),
    SystemSecurityUpdateWarningRounded: React.lazy(() => import('@mui/icons-material/SystemSecurityUpdateWarningRounded')),
    SystemSecurityUpdateWarningSharp: React.lazy(() => import('@mui/icons-material/SystemSecurityUpdateWarningSharp')),
    SystemSecurityUpdateWarningTwoTone: React.lazy(() => import('@mui/icons-material/SystemSecurityUpdateWarningTwoTone')),
    SystemUpdateAlt: React.lazy(() => import('@mui/icons-material/SystemUpdateAlt')),
    SystemUpdateAltOutlined: React.lazy(() => import('@mui/icons-material/SystemUpdateAltOutlined')),
    SystemUpdateAltRounded: React.lazy(() => import('@mui/icons-material/SystemUpdateAltRounded')),
    SystemUpdateAltSharp: React.lazy(() => import('@mui/icons-material/SystemUpdateAltSharp')),
    SystemUpdateAltTwoTone: React.lazy(() => import('@mui/icons-material/SystemUpdateAltTwoTone')),
    SystemUpdate: React.lazy(() => import('@mui/icons-material/SystemUpdate')),
    SystemUpdateOutlined: React.lazy(() => import('@mui/icons-material/SystemUpdateOutlined')),
    SystemUpdateRounded: React.lazy(() => import('@mui/icons-material/SystemUpdateRounded')),
    SystemUpdateSharp: React.lazy(() => import('@mui/icons-material/SystemUpdateSharp')),
    SystemUpdateTwoTone: React.lazy(() => import('@mui/icons-material/SystemUpdateTwoTone')),
    Tab: React.lazy(() => import('@mui/icons-material/Tab')),
    TableBar: React.lazy(() => import('@mui/icons-material/TableBar')),
    TableBarOutlined: React.lazy(() => import('@mui/icons-material/TableBarOutlined')),
    TableBarRounded: React.lazy(() => import('@mui/icons-material/TableBarRounded')),
    TableBarSharp: React.lazy(() => import('@mui/icons-material/TableBarSharp')),
    TableBarTwoTone: React.lazy(() => import('@mui/icons-material/TableBarTwoTone')),
    TableChart: React.lazy(() => import('@mui/icons-material/TableChart')),
    TableChartOutlined: React.lazy(() => import('@mui/icons-material/TableChartOutlined')),
    TableChartRounded: React.lazy(() => import('@mui/icons-material/TableChartRounded')),
    TableChartSharp: React.lazy(() => import('@mui/icons-material/TableChartSharp')),
    TableChartTwoTone: React.lazy(() => import('@mui/icons-material/TableChartTwoTone')),
    TableRestaurant: React.lazy(() => import('@mui/icons-material/TableRestaurant')),
    TableRestaurantOutlined: React.lazy(() => import('@mui/icons-material/TableRestaurantOutlined')),
    TableRestaurantRounded: React.lazy(() => import('@mui/icons-material/TableRestaurantRounded')),
    TableRestaurantSharp: React.lazy(() => import('@mui/icons-material/TableRestaurantSharp')),
    TableRestaurantTwoTone: React.lazy(() => import('@mui/icons-material/TableRestaurantTwoTone')),
    TableRows: React.lazy(() => import('@mui/icons-material/TableRows')),
    TableRowsOutlined: React.lazy(() => import('@mui/icons-material/TableRowsOutlined')),
    TableRowsRounded: React.lazy(() => import('@mui/icons-material/TableRowsRounded')),
    TableRowsSharp: React.lazy(() => import('@mui/icons-material/TableRowsSharp')),
    TableRowsTwoTone: React.lazy(() => import('@mui/icons-material/TableRowsTwoTone')),
    TabletAndroid: React.lazy(() => import('@mui/icons-material/TabletAndroid')),
    TabletAndroidOutlined: React.lazy(() => import('@mui/icons-material/TabletAndroidOutlined')),
    TabletAndroidRounded: React.lazy(() => import('@mui/icons-material/TabletAndroidRounded')),
    TabletAndroidSharp: React.lazy(() => import('@mui/icons-material/TabletAndroidSharp')),
    TabletAndroidTwoTone: React.lazy(() => import('@mui/icons-material/TabletAndroidTwoTone')),
    Tablet: React.lazy(() => import('@mui/icons-material/Tablet')),
    TabletMac: React.lazy(() => import('@mui/icons-material/TabletMac')),
    TabletMacOutlined: React.lazy(() => import('@mui/icons-material/TabletMacOutlined')),
    TabletMacRounded: React.lazy(() => import('@mui/icons-material/TabletMacRounded')),
    TabletMacSharp: React.lazy(() => import('@mui/icons-material/TabletMacSharp')),
    TabletMacTwoTone: React.lazy(() => import('@mui/icons-material/TabletMacTwoTone')),
    TabletOutlined: React.lazy(() => import('@mui/icons-material/TabletOutlined')),
    TabletRounded: React.lazy(() => import('@mui/icons-material/TabletRounded')),
    TabletSharp: React.lazy(() => import('@mui/icons-material/TabletSharp')),
    TabletTwoTone: React.lazy(() => import('@mui/icons-material/TabletTwoTone')),
    TableView: React.lazy(() => import('@mui/icons-material/TableView')),
    TableViewOutlined: React.lazy(() => import('@mui/icons-material/TableViewOutlined')),
    TableViewRounded: React.lazy(() => import('@mui/icons-material/TableViewRounded')),
    TableViewSharp: React.lazy(() => import('@mui/icons-material/TableViewSharp')),
    TableViewTwoTone: React.lazy(() => import('@mui/icons-material/TableViewTwoTone')),
    TabOutlined: React.lazy(() => import('@mui/icons-material/TabOutlined')),
    TabRounded: React.lazy(() => import('@mui/icons-material/TabRounded')),
    TabSharp: React.lazy(() => import('@mui/icons-material/TabSharp')),
    TabTwoTone: React.lazy(() => import('@mui/icons-material/TabTwoTone')),
    TabUnselected: React.lazy(() => import('@mui/icons-material/TabUnselected')),
    TabUnselectedOutlined: React.lazy(() => import('@mui/icons-material/TabUnselectedOutlined')),
    TabUnselectedRounded: React.lazy(() => import('@mui/icons-material/TabUnselectedRounded')),
    TabUnselectedSharp: React.lazy(() => import('@mui/icons-material/TabUnselectedSharp')),
    TabUnselectedTwoTone: React.lazy(() => import('@mui/icons-material/TabUnselectedTwoTone')),
    Tag: React.lazy(() => import('@mui/icons-material/Tag')),
    TagFaces: React.lazy(() => import('@mui/icons-material/TagFaces')),
    TagFacesOutlined: React.lazy(() => import('@mui/icons-material/TagFacesOutlined')),
    TagFacesRounded: React.lazy(() => import('@mui/icons-material/TagFacesRounded')),
    TagFacesSharp: React.lazy(() => import('@mui/icons-material/TagFacesSharp')),
    TagFacesTwoTone: React.lazy(() => import('@mui/icons-material/TagFacesTwoTone')),
    TagOutlined: React.lazy(() => import('@mui/icons-material/TagOutlined')),
    TagRounded: React.lazy(() => import('@mui/icons-material/TagRounded')),
    TagSharp: React.lazy(() => import('@mui/icons-material/TagSharp')),
    TagTwoTone: React.lazy(() => import('@mui/icons-material/TagTwoTone')),
    TakeoutDining: React.lazy(() => import('@mui/icons-material/TakeoutDining')),
    TakeoutDiningOutlined: React.lazy(() => import('@mui/icons-material/TakeoutDiningOutlined')),
    TakeoutDiningRounded: React.lazy(() => import('@mui/icons-material/TakeoutDiningRounded')),
    TakeoutDiningSharp: React.lazy(() => import('@mui/icons-material/TakeoutDiningSharp')),
    TakeoutDiningTwoTone: React.lazy(() => import('@mui/icons-material/TakeoutDiningTwoTone')),
    TapAndPlay: React.lazy(() => import('@mui/icons-material/TapAndPlay')),
    TapAndPlayOutlined: React.lazy(() => import('@mui/icons-material/TapAndPlayOutlined')),
    TapAndPlayRounded: React.lazy(() => import('@mui/icons-material/TapAndPlayRounded')),
    TapAndPlaySharp: React.lazy(() => import('@mui/icons-material/TapAndPlaySharp')),
    TapAndPlayTwoTone: React.lazy(() => import('@mui/icons-material/TapAndPlayTwoTone')),
    Tapas: React.lazy(() => import('@mui/icons-material/Tapas')),
    TapasOutlined: React.lazy(() => import('@mui/icons-material/TapasOutlined')),
    TapasRounded: React.lazy(() => import('@mui/icons-material/TapasRounded')),
    TapasSharp: React.lazy(() => import('@mui/icons-material/TapasSharp')),
    TapasTwoTone: React.lazy(() => import('@mui/icons-material/TapasTwoTone')),
    TaskAlt: React.lazy(() => import('@mui/icons-material/TaskAlt')),
    TaskAltOutlined: React.lazy(() => import('@mui/icons-material/TaskAltOutlined')),
    TaskAltRounded: React.lazy(() => import('@mui/icons-material/TaskAltRounded')),
    TaskAltSharp: React.lazy(() => import('@mui/icons-material/TaskAltSharp')),
    TaskAltTwoTone: React.lazy(() => import('@mui/icons-material/TaskAltTwoTone')),
    Task: React.lazy(() => import('@mui/icons-material/Task')),
    TaskOutlined: React.lazy(() => import('@mui/icons-material/TaskOutlined')),
    TaskRounded: React.lazy(() => import('@mui/icons-material/TaskRounded')),
    TaskSharp: React.lazy(() => import('@mui/icons-material/TaskSharp')),
    TaskTwoTone: React.lazy(() => import('@mui/icons-material/TaskTwoTone')),
    TaxiAlert: React.lazy(() => import('@mui/icons-material/TaxiAlert')),
    TaxiAlertOutlined: React.lazy(() => import('@mui/icons-material/TaxiAlertOutlined')),
    TaxiAlertRounded: React.lazy(() => import('@mui/icons-material/TaxiAlertRounded')),
    TaxiAlertSharp: React.lazy(() => import('@mui/icons-material/TaxiAlertSharp')),
    TaxiAlertTwoTone: React.lazy(() => import('@mui/icons-material/TaxiAlertTwoTone')),
    Telegram: React.lazy(() => import('@mui/icons-material/Telegram')),
    TempleBuddhist: React.lazy(() => import('@mui/icons-material/TempleBuddhist')),
    TempleBuddhistOutlined: React.lazy(() => import('@mui/icons-material/TempleBuddhistOutlined')),
    TempleBuddhistRounded: React.lazy(() => import('@mui/icons-material/TempleBuddhistRounded')),
    TempleBuddhistSharp: React.lazy(() => import('@mui/icons-material/TempleBuddhistSharp')),
    TempleBuddhistTwoTone: React.lazy(() => import('@mui/icons-material/TempleBuddhistTwoTone')),
    TempleHindu: React.lazy(() => import('@mui/icons-material/TempleHindu')),
    TempleHinduOutlined: React.lazy(() => import('@mui/icons-material/TempleHinduOutlined')),
    TempleHinduRounded: React.lazy(() => import('@mui/icons-material/TempleHinduRounded')),
    TempleHinduSharp: React.lazy(() => import('@mui/icons-material/TempleHinduSharp')),
    TempleHinduTwoTone: React.lazy(() => import('@mui/icons-material/TempleHinduTwoTone')),
    TenMp: React.lazy(() => import('@mui/icons-material/TenMp')),
    TenMpOutlined: React.lazy(() => import('@mui/icons-material/TenMpOutlined')),
    TenMpRounded: React.lazy(() => import('@mui/icons-material/TenMpRounded')),
    TenMpSharp: React.lazy(() => import('@mui/icons-material/TenMpSharp')),
    TenMpTwoTone: React.lazy(() => import('@mui/icons-material/TenMpTwoTone')),
    Terminal: React.lazy(() => import('@mui/icons-material/Terminal')),
    TerminalOutlined: React.lazy(() => import('@mui/icons-material/TerminalOutlined')),
    TerminalRounded: React.lazy(() => import('@mui/icons-material/TerminalRounded')),
    TerminalSharp: React.lazy(() => import('@mui/icons-material/TerminalSharp')),
    TerminalTwoTone: React.lazy(() => import('@mui/icons-material/TerminalTwoTone')),
    Terrain: React.lazy(() => import('@mui/icons-material/Terrain')),
    TerrainOutlined: React.lazy(() => import('@mui/icons-material/TerrainOutlined')),
    TerrainRounded: React.lazy(() => import('@mui/icons-material/TerrainRounded')),
    TerrainSharp: React.lazy(() => import('@mui/icons-material/TerrainSharp')),
    TerrainTwoTone: React.lazy(() => import('@mui/icons-material/TerrainTwoTone')),
    TextDecrease: React.lazy(() => import('@mui/icons-material/TextDecrease')),
    TextDecreaseOutlined: React.lazy(() => import('@mui/icons-material/TextDecreaseOutlined')),
    TextDecreaseRounded: React.lazy(() => import('@mui/icons-material/TextDecreaseRounded')),
    TextDecreaseSharp: React.lazy(() => import('@mui/icons-material/TextDecreaseSharp')),
    TextDecreaseTwoTone: React.lazy(() => import('@mui/icons-material/TextDecreaseTwoTone')),
    TextFields: React.lazy(() => import('@mui/icons-material/TextFields')),
    TextFieldsOutlined: React.lazy(() => import('@mui/icons-material/TextFieldsOutlined')),
    TextFieldsRounded: React.lazy(() => import('@mui/icons-material/TextFieldsRounded')),
    TextFieldsSharp: React.lazy(() => import('@mui/icons-material/TextFieldsSharp')),
    TextFieldsTwoTone: React.lazy(() => import('@mui/icons-material/TextFieldsTwoTone')),
    TextFormat: React.lazy(() => import('@mui/icons-material/TextFormat')),
    TextFormatOutlined: React.lazy(() => import('@mui/icons-material/TextFormatOutlined')),
    TextFormatRounded: React.lazy(() => import('@mui/icons-material/TextFormatRounded')),
    TextFormatSharp: React.lazy(() => import('@mui/icons-material/TextFormatSharp')),
    TextFormatTwoTone: React.lazy(() => import('@mui/icons-material/TextFormatTwoTone')),
    TextIncrease: React.lazy(() => import('@mui/icons-material/TextIncrease')),
    TextIncreaseOutlined: React.lazy(() => import('@mui/icons-material/TextIncreaseOutlined')),
    TextIncreaseRounded: React.lazy(() => import('@mui/icons-material/TextIncreaseRounded')),
    TextIncreaseSharp: React.lazy(() => import('@mui/icons-material/TextIncreaseSharp')),
    TextIncreaseTwoTone: React.lazy(() => import('@mui/icons-material/TextIncreaseTwoTone')),
    TextRotateUp: React.lazy(() => import('@mui/icons-material/TextRotateUp')),
    TextRotateUpOutlined: React.lazy(() => import('@mui/icons-material/TextRotateUpOutlined')),
    TextRotateUpRounded: React.lazy(() => import('@mui/icons-material/TextRotateUpRounded')),
    TextRotateUpSharp: React.lazy(() => import('@mui/icons-material/TextRotateUpSharp')),
    TextRotateUpTwoTone: React.lazy(() => import('@mui/icons-material/TextRotateUpTwoTone')),
    TextRotateVertical: React.lazy(() => import('@mui/icons-material/TextRotateVertical')),
    TextRotateVerticalOutlined: React.lazy(() => import('@mui/icons-material/TextRotateVerticalOutlined')),
    TextRotateVerticalRounded: React.lazy(() => import('@mui/icons-material/TextRotateVerticalRounded')),
    TextRotateVerticalSharp: React.lazy(() => import('@mui/icons-material/TextRotateVerticalSharp')),
    TextRotateVerticalTwoTone: React.lazy(() => import('@mui/icons-material/TextRotateVerticalTwoTone')),
    TextRotationAngledown: React.lazy(() => import('@mui/icons-material/TextRotationAngledown')),
    TextRotationAngledownOutlined: React.lazy(() => import('@mui/icons-material/TextRotationAngledownOutlined')),
    TextRotationAngledownRounded: React.lazy(() => import('@mui/icons-material/TextRotationAngledownRounded')),
    TextRotationAngledownSharp: React.lazy(() => import('@mui/icons-material/TextRotationAngledownSharp')),
    TextRotationAngledownTwoTone: React.lazy(() => import('@mui/icons-material/TextRotationAngledownTwoTone')),
    TextRotationAngleup: React.lazy(() => import('@mui/icons-material/TextRotationAngleup')),
    TextRotationAngleupOutlined: React.lazy(() => import('@mui/icons-material/TextRotationAngleupOutlined')),
    TextRotationAngleupRounded: React.lazy(() => import('@mui/icons-material/TextRotationAngleupRounded')),
    TextRotationAngleupSharp: React.lazy(() => import('@mui/icons-material/TextRotationAngleupSharp')),
    TextRotationAngleupTwoTone: React.lazy(() => import('@mui/icons-material/TextRotationAngleupTwoTone')),
    TextRotationDown: React.lazy(() => import('@mui/icons-material/TextRotationDown')),
    TextRotationDownOutlined: React.lazy(() => import('@mui/icons-material/TextRotationDownOutlined')),
    TextRotationDownRounded: React.lazy(() => import('@mui/icons-material/TextRotationDownRounded')),
    TextRotationDownSharp: React.lazy(() => import('@mui/icons-material/TextRotationDownSharp')),
    TextRotationDownTwoTone: React.lazy(() => import('@mui/icons-material/TextRotationDownTwoTone')),
    TextRotationNone: React.lazy(() => import('@mui/icons-material/TextRotationNone')),
    TextRotationNoneOutlined: React.lazy(() => import('@mui/icons-material/TextRotationNoneOutlined')),
    TextRotationNoneRounded: React.lazy(() => import('@mui/icons-material/TextRotationNoneRounded')),
    TextRotationNoneSharp: React.lazy(() => import('@mui/icons-material/TextRotationNoneSharp')),
    TextRotationNoneTwoTone: React.lazy(() => import('@mui/icons-material/TextRotationNoneTwoTone')),
    Textsms: React.lazy(() => import('@mui/icons-material/Textsms')),
    TextsmsOutlined: React.lazy(() => import('@mui/icons-material/TextsmsOutlined')),
    TextsmsRounded: React.lazy(() => import('@mui/icons-material/TextsmsRounded')),
    TextsmsSharp: React.lazy(() => import('@mui/icons-material/TextsmsSharp')),
    TextsmsTwoTone: React.lazy(() => import('@mui/icons-material/TextsmsTwoTone')),
    TextSnippet: React.lazy(() => import('@mui/icons-material/TextSnippet')),
    TextSnippetOutlined: React.lazy(() => import('@mui/icons-material/TextSnippetOutlined')),
    TextSnippetRounded: React.lazy(() => import('@mui/icons-material/TextSnippetRounded')),
    TextSnippetSharp: React.lazy(() => import('@mui/icons-material/TextSnippetSharp')),
    TextSnippetTwoTone: React.lazy(() => import('@mui/icons-material/TextSnippetTwoTone')),
    Texture: React.lazy(() => import('@mui/icons-material/Texture')),
    TextureOutlined: React.lazy(() => import('@mui/icons-material/TextureOutlined')),
    TextureRounded: React.lazy(() => import('@mui/icons-material/TextureRounded')),
    TextureSharp: React.lazy(() => import('@mui/icons-material/TextureSharp')),
    TextureTwoTone: React.lazy(() => import('@mui/icons-material/TextureTwoTone')),
    TheaterComedy: React.lazy(() => import('@mui/icons-material/TheaterComedy')),
    TheaterComedyOutlined: React.lazy(() => import('@mui/icons-material/TheaterComedyOutlined')),
    TheaterComedyRounded: React.lazy(() => import('@mui/icons-material/TheaterComedyRounded')),
    TheaterComedySharp: React.lazy(() => import('@mui/icons-material/TheaterComedySharp')),
    TheaterComedyTwoTone: React.lazy(() => import('@mui/icons-material/TheaterComedyTwoTone')),
    Theaters: React.lazy(() => import('@mui/icons-material/Theaters')),
    TheatersOutlined: React.lazy(() => import('@mui/icons-material/TheatersOutlined')),
    TheatersRounded: React.lazy(() => import('@mui/icons-material/TheatersRounded')),
    TheatersSharp: React.lazy(() => import('@mui/icons-material/TheatersSharp')),
    TheatersTwoTone: React.lazy(() => import('@mui/icons-material/TheatersTwoTone')),
    ThermostatAuto: React.lazy(() => import('@mui/icons-material/ThermostatAuto')),
    ThermostatAutoOutlined: React.lazy(() => import('@mui/icons-material/ThermostatAutoOutlined')),
    ThermostatAutoRounded: React.lazy(() => import('@mui/icons-material/ThermostatAutoRounded')),
    ThermostatAutoSharp: React.lazy(() => import('@mui/icons-material/ThermostatAutoSharp')),
    ThermostatAutoTwoTone: React.lazy(() => import('@mui/icons-material/ThermostatAutoTwoTone')),
    Thermostat: React.lazy(() => import('@mui/icons-material/Thermostat')),
    ThermostatOutlined: React.lazy(() => import('@mui/icons-material/ThermostatOutlined')),
    ThermostatRounded: React.lazy(() => import('@mui/icons-material/ThermostatRounded')),
    ThermostatSharp: React.lazy(() => import('@mui/icons-material/ThermostatSharp')),
    ThermostatTwoTone: React.lazy(() => import('@mui/icons-material/ThermostatTwoTone')),
    ThirteenMp: React.lazy(() => import('@mui/icons-material/ThirteenMp')),
    ThirteenMpOutlined: React.lazy(() => import('@mui/icons-material/ThirteenMpOutlined')),
    ThirteenMpRounded: React.lazy(() => import('@mui/icons-material/ThirteenMpRounded')),
    ThirteenMpSharp: React.lazy(() => import('@mui/icons-material/ThirteenMpSharp')),
    ThirteenMpTwoTone: React.lazy(() => import('@mui/icons-material/ThirteenMpTwoTone')),
    ThirtyFps: React.lazy(() => import('@mui/icons-material/ThirtyFps')),
    ThirtyFpsOutlined: React.lazy(() => import('@mui/icons-material/ThirtyFpsOutlined')),
    ThirtyFpsRounded: React.lazy(() => import('@mui/icons-material/ThirtyFpsRounded')),
    ThirtyFpsSelect: React.lazy(() => import('@mui/icons-material/ThirtyFpsSelect')),
    ThirtyFpsSelectOutlined: React.lazy(() => import('@mui/icons-material/ThirtyFpsSelectOutlined')),
    ThirtyFpsSelectRounded: React.lazy(() => import('@mui/icons-material/ThirtyFpsSelectRounded')),
    ThirtyFpsSelectSharp: React.lazy(() => import('@mui/icons-material/ThirtyFpsSelectSharp')),
    ThirtyFpsSelectTwoTone: React.lazy(() => import('@mui/icons-material/ThirtyFpsSelectTwoTone')),
    ThirtyFpsSharp: React.lazy(() => import('@mui/icons-material/ThirtyFpsSharp')),
    ThirtyFpsTwoTone: React.lazy(() => import('@mui/icons-material/ThirtyFpsTwoTone')),
    ThreeDRotation: React.lazy(() => import('@mui/icons-material/ThreeDRotation')),
    ThreeDRotationOutlined: React.lazy(() => import('@mui/icons-material/ThreeDRotationOutlined')),
    ThreeDRotationRounded: React.lazy(() => import('@mui/icons-material/ThreeDRotationRounded')),
    ThreeDRotationSharp: React.lazy(() => import('@mui/icons-material/ThreeDRotationSharp')),
    ThreeDRotationTwoTone: React.lazy(() => import('@mui/icons-material/ThreeDRotationTwoTone')),
    ThreeGMobiledata: React.lazy(() => import('@mui/icons-material/ThreeGMobiledata')),
    ThreeGMobiledataOutlined: React.lazy(() => import('@mui/icons-material/ThreeGMobiledataOutlined')),
    ThreeGMobiledataRounded: React.lazy(() => import('@mui/icons-material/ThreeGMobiledataRounded')),
    ThreeGMobiledataSharp: React.lazy(() => import('@mui/icons-material/ThreeGMobiledataSharp')),
    ThreeGMobiledataTwoTone: React.lazy(() => import('@mui/icons-material/ThreeGMobiledataTwoTone')),
    ThreeK: React.lazy(() => import('@mui/icons-material/ThreeK')),
    ThreeKOutlined: React.lazy(() => import('@mui/icons-material/ThreeKOutlined')),
    ThreeKPlus: React.lazy(() => import('@mui/icons-material/ThreeKPlus')),
    ThreeKPlusOutlined: React.lazy(() => import('@mui/icons-material/ThreeKPlusOutlined')),
    ThreeKPlusRounded: React.lazy(() => import('@mui/icons-material/ThreeKPlusRounded')),
    ThreeKPlusSharp: React.lazy(() => import('@mui/icons-material/ThreeKPlusSharp')),
    ThreeKPlusTwoTone: React.lazy(() => import('@mui/icons-material/ThreeKPlusTwoTone')),
    ThreeKRounded: React.lazy(() => import('@mui/icons-material/ThreeKRounded')),
    ThreeKSharp: React.lazy(() => import('@mui/icons-material/ThreeKSharp')),
    ThreeKTwoTone: React.lazy(() => import('@mui/icons-material/ThreeKTwoTone')),
    ThreeMp: React.lazy(() => import('@mui/icons-material/ThreeMp')),
    ThreeMpOutlined: React.lazy(() => import('@mui/icons-material/ThreeMpOutlined')),
    ThreeMpRounded: React.lazy(() => import('@mui/icons-material/ThreeMpRounded')),
    ThreeMpSharp: React.lazy(() => import('@mui/icons-material/ThreeMpSharp')),
    ThreeMpTwoTone: React.lazy(() => import('@mui/icons-material/ThreeMpTwoTone')),
    ThreeP: React.lazy(() => import('@mui/icons-material/ThreeP')),
    ThreePOutlined: React.lazy(() => import('@mui/icons-material/ThreePOutlined')),
    ThreePRounded: React.lazy(() => import('@mui/icons-material/ThreePRounded')),
    ThreePSharp: React.lazy(() => import('@mui/icons-material/ThreePSharp')),
    ThreePTwoTone: React.lazy(() => import('@mui/icons-material/ThreePTwoTone')),
    ThreeSixty: React.lazy(() => import('@mui/icons-material/ThreeSixty')),
    ThreeSixtyOutlined: React.lazy(() => import('@mui/icons-material/ThreeSixtyOutlined')),
    ThreeSixtyRounded: React.lazy(() => import('@mui/icons-material/ThreeSixtyRounded')),
    ThreeSixtySharp: React.lazy(() => import('@mui/icons-material/ThreeSixtySharp')),
    ThreeSixtyTwoTone: React.lazy(() => import('@mui/icons-material/ThreeSixtyTwoTone')),
    ThumbDownAlt: React.lazy(() => import('@mui/icons-material/ThumbDownAlt')),
    ThumbDownAltOutlined: React.lazy(() => import('@mui/icons-material/ThumbDownAltOutlined')),
    ThumbDownAltRounded: React.lazy(() => import('@mui/icons-material/ThumbDownAltRounded')),
    ThumbDownAltSharp: React.lazy(() => import('@mui/icons-material/ThumbDownAltSharp')),
    ThumbDownAltTwoTone: React.lazy(() => import('@mui/icons-material/ThumbDownAltTwoTone')),
    ThumbDown: React.lazy(() => import('@mui/icons-material/ThumbDown')),
    ThumbDownOffAlt: React.lazy(() => import('@mui/icons-material/ThumbDownOffAlt')),
    ThumbDownOffAltOutlined: React.lazy(() => import('@mui/icons-material/ThumbDownOffAltOutlined')),
    ThumbDownOffAltRounded: React.lazy(() => import('@mui/icons-material/ThumbDownOffAltRounded')),
    ThumbDownOffAltSharp: React.lazy(() => import('@mui/icons-material/ThumbDownOffAltSharp')),
    ThumbDownOffAltTwoTone: React.lazy(() => import('@mui/icons-material/ThumbDownOffAltTwoTone')),
    ThumbDownOutlined: React.lazy(() => import('@mui/icons-material/ThumbDownOutlined')),
    ThumbDownRounded: React.lazy(() => import('@mui/icons-material/ThumbDownRounded')),
    ThumbDownSharp: React.lazy(() => import('@mui/icons-material/ThumbDownSharp')),
    ThumbDownTwoTone: React.lazy(() => import('@mui/icons-material/ThumbDownTwoTone')),
    ThumbsUpDown: React.lazy(() => import('@mui/icons-material/ThumbsUpDown')),
    ThumbsUpDownOutlined: React.lazy(() => import('@mui/icons-material/ThumbsUpDownOutlined')),
    ThumbsUpDownRounded: React.lazy(() => import('@mui/icons-material/ThumbsUpDownRounded')),
    ThumbsUpDownSharp: React.lazy(() => import('@mui/icons-material/ThumbsUpDownSharp')),
    ThumbsUpDownTwoTone: React.lazy(() => import('@mui/icons-material/ThumbsUpDownTwoTone')),
    ThumbUpAlt: React.lazy(() => import('@mui/icons-material/ThumbUpAlt')),
    ThumbUpAltOutlined: React.lazy(() => import('@mui/icons-material/ThumbUpAltOutlined')),
    ThumbUpAltRounded: React.lazy(() => import('@mui/icons-material/ThumbUpAltRounded')),
    ThumbUpAltSharp: React.lazy(() => import('@mui/icons-material/ThumbUpAltSharp')),
    ThumbUpAltTwoTone: React.lazy(() => import('@mui/icons-material/ThumbUpAltTwoTone')),
    ThumbUp: React.lazy(() => import('@mui/icons-material/ThumbUp')),
    ThumbUpOffAlt: React.lazy(() => import('@mui/icons-material/ThumbUpOffAlt')),
    ThumbUpOffAltOutlined: React.lazy(() => import('@mui/icons-material/ThumbUpOffAltOutlined')),
    ThumbUpOffAltRounded: React.lazy(() => import('@mui/icons-material/ThumbUpOffAltRounded')),
    ThumbUpOffAltSharp: React.lazy(() => import('@mui/icons-material/ThumbUpOffAltSharp')),
    ThumbUpOffAltTwoTone: React.lazy(() => import('@mui/icons-material/ThumbUpOffAltTwoTone')),
    ThumbUpOutlined: React.lazy(() => import('@mui/icons-material/ThumbUpOutlined')),
    ThumbUpRounded: React.lazy(() => import('@mui/icons-material/ThumbUpRounded')),
    ThumbUpSharp: React.lazy(() => import('@mui/icons-material/ThumbUpSharp')),
    ThumbUpTwoTone: React.lazy(() => import('@mui/icons-material/ThumbUpTwoTone')),
    Thunderstorm: React.lazy(() => import('@mui/icons-material/Thunderstorm')),
    ThunderstormOutlined: React.lazy(() => import('@mui/icons-material/ThunderstormOutlined')),
    ThunderstormRounded: React.lazy(() => import('@mui/icons-material/ThunderstormRounded')),
    ThunderstormSharp: React.lazy(() => import('@mui/icons-material/ThunderstormSharp')),
    ThunderstormTwoTone: React.lazy(() => import('@mui/icons-material/ThunderstormTwoTone')),
    Timelapse: React.lazy(() => import('@mui/icons-material/Timelapse')),
    TimelapseOutlined: React.lazy(() => import('@mui/icons-material/TimelapseOutlined')),
    TimelapseRounded: React.lazy(() => import('@mui/icons-material/TimelapseRounded')),
    TimelapseSharp: React.lazy(() => import('@mui/icons-material/TimelapseSharp')),
    TimelapseTwoTone: React.lazy(() => import('@mui/icons-material/TimelapseTwoTone')),
    Timeline: React.lazy(() => import('@mui/icons-material/Timeline')),
    TimelineOutlined: React.lazy(() => import('@mui/icons-material/TimelineOutlined')),
    TimelineRounded: React.lazy(() => import('@mui/icons-material/TimelineRounded')),
    TimelineSharp: React.lazy(() => import('@mui/icons-material/TimelineSharp')),
    TimelineTwoTone: React.lazy(() => import('@mui/icons-material/TimelineTwoTone')),
    Timer10: React.lazy(() => import('@mui/icons-material/Timer10')),
    Timer10Outlined: React.lazy(() => import('@mui/icons-material/Timer10Outlined')),
    Timer10Rounded: React.lazy(() => import('@mui/icons-material/Timer10Rounded')),
    Timer10Select: React.lazy(() => import('@mui/icons-material/Timer10Select')),
    Timer10SelectOutlined: React.lazy(() => import('@mui/icons-material/Timer10SelectOutlined')),
    Timer10SelectRounded: React.lazy(() => import('@mui/icons-material/Timer10SelectRounded')),
    Timer10SelectSharp: React.lazy(() => import('@mui/icons-material/Timer10SelectSharp')),
    Timer10SelectTwoTone: React.lazy(() => import('@mui/icons-material/Timer10SelectTwoTone')),
    Timer10Sharp: React.lazy(() => import('@mui/icons-material/Timer10Sharp')),
    Timer10TwoTone: React.lazy(() => import('@mui/icons-material/Timer10TwoTone')),
    Timer3: React.lazy(() => import('@mui/icons-material/Timer3')),
    Timer3Outlined: React.lazy(() => import('@mui/icons-material/Timer3Outlined')),
    Timer3Rounded: React.lazy(() => import('@mui/icons-material/Timer3Rounded')),
    Timer3Select: React.lazy(() => import('@mui/icons-material/Timer3Select')),
    Timer3SelectOutlined: React.lazy(() => import('@mui/icons-material/Timer3SelectOutlined')),
    Timer3SelectRounded: React.lazy(() => import('@mui/icons-material/Timer3SelectRounded')),
    Timer3SelectSharp: React.lazy(() => import('@mui/icons-material/Timer3SelectSharp')),
    Timer3SelectTwoTone: React.lazy(() => import('@mui/icons-material/Timer3SelectTwoTone')),
    Timer3Sharp: React.lazy(() => import('@mui/icons-material/Timer3Sharp')),
    Timer3TwoTone: React.lazy(() => import('@mui/icons-material/Timer3TwoTone')),
    Timer: React.lazy(() => import('@mui/icons-material/Timer')),
    TimerOff: React.lazy(() => import('@mui/icons-material/TimerOff')),
    TimerOffOutlined: React.lazy(() => import('@mui/icons-material/TimerOffOutlined')),
    TimerOffRounded: React.lazy(() => import('@mui/icons-material/TimerOffRounded')),
    TimerOffSharp: React.lazy(() => import('@mui/icons-material/TimerOffSharp')),
    TimerOffTwoTone: React.lazy(() => import('@mui/icons-material/TimerOffTwoTone')),
    TimerOutlined: React.lazy(() => import('@mui/icons-material/TimerOutlined')),
    TimerRounded: React.lazy(() => import('@mui/icons-material/TimerRounded')),
    TimerSharp: React.lazy(() => import('@mui/icons-material/TimerSharp')),
    TimerTwoTone: React.lazy(() => import('@mui/icons-material/TimerTwoTone')),
    TimesOneMobiledata: React.lazy(() => import('@mui/icons-material/TimesOneMobiledata')),
    TimesOneMobiledataOutlined: React.lazy(() => import('@mui/icons-material/TimesOneMobiledataOutlined')),
    TimesOneMobiledataRounded: React.lazy(() => import('@mui/icons-material/TimesOneMobiledataRounded')),
    TimesOneMobiledataSharp: React.lazy(() => import('@mui/icons-material/TimesOneMobiledataSharp')),
    TimesOneMobiledataTwoTone: React.lazy(() => import('@mui/icons-material/TimesOneMobiledataTwoTone')),
    TimeToLeave: React.lazy(() => import('@mui/icons-material/TimeToLeave')),
    TimeToLeaveOutlined: React.lazy(() => import('@mui/icons-material/TimeToLeaveOutlined')),
    TimeToLeaveRounded: React.lazy(() => import('@mui/icons-material/TimeToLeaveRounded')),
    TimeToLeaveSharp: React.lazy(() => import('@mui/icons-material/TimeToLeaveSharp')),
    TimeToLeaveTwoTone: React.lazy(() => import('@mui/icons-material/TimeToLeaveTwoTone')),
    TipsAndUpdates: React.lazy(() => import('@mui/icons-material/TipsAndUpdates')),
    TipsAndUpdatesOutlined: React.lazy(() => import('@mui/icons-material/TipsAndUpdatesOutlined')),
    TipsAndUpdatesRounded: React.lazy(() => import('@mui/icons-material/TipsAndUpdatesRounded')),
    TipsAndUpdatesSharp: React.lazy(() => import('@mui/icons-material/TipsAndUpdatesSharp')),
    TipsAndUpdatesTwoTone: React.lazy(() => import('@mui/icons-material/TipsAndUpdatesTwoTone')),
    TireRepair: React.lazy(() => import('@mui/icons-material/TireRepair')),
    TireRepairOutlined: React.lazy(() => import('@mui/icons-material/TireRepairOutlined')),
    TireRepairRounded: React.lazy(() => import('@mui/icons-material/TireRepairRounded')),
    TireRepairSharp: React.lazy(() => import('@mui/icons-material/TireRepairSharp')),
    TireRepairTwoTone: React.lazy(() => import('@mui/icons-material/TireRepairTwoTone')),
    Title: React.lazy(() => import('@mui/icons-material/Title')),
    TitleOutlined: React.lazy(() => import('@mui/icons-material/TitleOutlined')),
    TitleRounded: React.lazy(() => import('@mui/icons-material/TitleRounded')),
    TitleSharp: React.lazy(() => import('@mui/icons-material/TitleSharp')),
    TitleTwoTone: React.lazy(() => import('@mui/icons-material/TitleTwoTone')),
    Toc: React.lazy(() => import('@mui/icons-material/Toc')),
    TocOutlined: React.lazy(() => import('@mui/icons-material/TocOutlined')),
    TocRounded: React.lazy(() => import('@mui/icons-material/TocRounded')),
    TocSharp: React.lazy(() => import('@mui/icons-material/TocSharp')),
    TocTwoTone: React.lazy(() => import('@mui/icons-material/TocTwoTone')),
    Today: React.lazy(() => import('@mui/icons-material/Today')),
    TodayOutlined: React.lazy(() => import('@mui/icons-material/TodayOutlined')),
    TodayRounded: React.lazy(() => import('@mui/icons-material/TodayRounded')),
    TodaySharp: React.lazy(() => import('@mui/icons-material/TodaySharp')),
    TodayTwoTone: React.lazy(() => import('@mui/icons-material/TodayTwoTone')),
    ToggleOff: React.lazy(() => import('@mui/icons-material/ToggleOff')),
    ToggleOffOutlined: React.lazy(() => import('@mui/icons-material/ToggleOffOutlined')),
    ToggleOffRounded: React.lazy(() => import('@mui/icons-material/ToggleOffRounded')),
    ToggleOffSharp: React.lazy(() => import('@mui/icons-material/ToggleOffSharp')),
    ToggleOffTwoTone: React.lazy(() => import('@mui/icons-material/ToggleOffTwoTone')),
    ToggleOn: React.lazy(() => import('@mui/icons-material/ToggleOn')),
    ToggleOnOutlined: React.lazy(() => import('@mui/icons-material/ToggleOnOutlined')),
    ToggleOnRounded: React.lazy(() => import('@mui/icons-material/ToggleOnRounded')),
    ToggleOnSharp: React.lazy(() => import('@mui/icons-material/ToggleOnSharp')),
    ToggleOnTwoTone: React.lazy(() => import('@mui/icons-material/ToggleOnTwoTone')),
    Token: React.lazy(() => import('@mui/icons-material/Token')),
    TokenOutlined: React.lazy(() => import('@mui/icons-material/TokenOutlined')),
    TokenRounded: React.lazy(() => import('@mui/icons-material/TokenRounded')),
    TokenSharp: React.lazy(() => import('@mui/icons-material/TokenSharp')),
    TokenTwoTone: React.lazy(() => import('@mui/icons-material/TokenTwoTone')),
    Toll: React.lazy(() => import('@mui/icons-material/Toll')),
    TollOutlined: React.lazy(() => import('@mui/icons-material/TollOutlined')),
    TollRounded: React.lazy(() => import('@mui/icons-material/TollRounded')),
    TollSharp: React.lazy(() => import('@mui/icons-material/TollSharp')),
    TollTwoTone: React.lazy(() => import('@mui/icons-material/TollTwoTone')),
    Tonality: React.lazy(() => import('@mui/icons-material/Tonality')),
    TonalityOutlined: React.lazy(() => import('@mui/icons-material/TonalityOutlined')),
    TonalityRounded: React.lazy(() => import('@mui/icons-material/TonalityRounded')),
    TonalitySharp: React.lazy(() => import('@mui/icons-material/TonalitySharp')),
    TonalityTwoTone: React.lazy(() => import('@mui/icons-material/TonalityTwoTone')),
    Topic: React.lazy(() => import('@mui/icons-material/Topic')),
    TopicOutlined: React.lazy(() => import('@mui/icons-material/TopicOutlined')),
    TopicRounded: React.lazy(() => import('@mui/icons-material/TopicRounded')),
    TopicSharp: React.lazy(() => import('@mui/icons-material/TopicSharp')),
    TopicTwoTone: React.lazy(() => import('@mui/icons-material/TopicTwoTone')),
    Tornado: React.lazy(() => import('@mui/icons-material/Tornado')),
    TornadoOutlined: React.lazy(() => import('@mui/icons-material/TornadoOutlined')),
    TornadoRounded: React.lazy(() => import('@mui/icons-material/TornadoRounded')),
    TornadoSharp: React.lazy(() => import('@mui/icons-material/TornadoSharp')),
    TornadoTwoTone: React.lazy(() => import('@mui/icons-material/TornadoTwoTone')),
    TouchApp: React.lazy(() => import('@mui/icons-material/TouchApp')),
    TouchAppOutlined: React.lazy(() => import('@mui/icons-material/TouchAppOutlined')),
    TouchAppRounded: React.lazy(() => import('@mui/icons-material/TouchAppRounded')),
    TouchAppSharp: React.lazy(() => import('@mui/icons-material/TouchAppSharp')),
    TouchAppTwoTone: React.lazy(() => import('@mui/icons-material/TouchAppTwoTone')),
    Tour: React.lazy(() => import('@mui/icons-material/Tour')),
    TourOutlined: React.lazy(() => import('@mui/icons-material/TourOutlined')),
    TourRounded: React.lazy(() => import('@mui/icons-material/TourRounded')),
    TourSharp: React.lazy(() => import('@mui/icons-material/TourSharp')),
    TourTwoTone: React.lazy(() => import('@mui/icons-material/TourTwoTone')),
    Toys: React.lazy(() => import('@mui/icons-material/Toys')),
    ToysOutlined: React.lazy(() => import('@mui/icons-material/ToysOutlined')),
    ToysRounded: React.lazy(() => import('@mui/icons-material/ToysRounded')),
    ToysSharp: React.lazy(() => import('@mui/icons-material/ToysSharp')),
    ToysTwoTone: React.lazy(() => import('@mui/icons-material/ToysTwoTone')),
    TrackChanges: React.lazy(() => import('@mui/icons-material/TrackChanges')),
    TrackChangesOutlined: React.lazy(() => import('@mui/icons-material/TrackChangesOutlined')),
    TrackChangesRounded: React.lazy(() => import('@mui/icons-material/TrackChangesRounded')),
    TrackChangesSharp: React.lazy(() => import('@mui/icons-material/TrackChangesSharp')),
    TrackChangesTwoTone: React.lazy(() => import('@mui/icons-material/TrackChangesTwoTone')),
    Traffic: React.lazy(() => import('@mui/icons-material/Traffic')),
    TrafficOutlined: React.lazy(() => import('@mui/icons-material/TrafficOutlined')),
    TrafficRounded: React.lazy(() => import('@mui/icons-material/TrafficRounded')),
    TrafficSharp: React.lazy(() => import('@mui/icons-material/TrafficSharp')),
    TrafficTwoTone: React.lazy(() => import('@mui/icons-material/TrafficTwoTone')),
    Train: React.lazy(() => import('@mui/icons-material/Train')),
    TrainOutlined: React.lazy(() => import('@mui/icons-material/TrainOutlined')),
    TrainRounded: React.lazy(() => import('@mui/icons-material/TrainRounded')),
    TrainSharp: React.lazy(() => import('@mui/icons-material/TrainSharp')),
    TrainTwoTone: React.lazy(() => import('@mui/icons-material/TrainTwoTone')),
    Tram: React.lazy(() => import('@mui/icons-material/Tram')),
    TramOutlined: React.lazy(() => import('@mui/icons-material/TramOutlined')),
    TramRounded: React.lazy(() => import('@mui/icons-material/TramRounded')),
    TramSharp: React.lazy(() => import('@mui/icons-material/TramSharp')),
    TramTwoTone: React.lazy(() => import('@mui/icons-material/TramTwoTone')),
    Transcribe: React.lazy(() => import('@mui/icons-material/Transcribe')),
    TranscribeOutlined: React.lazy(() => import('@mui/icons-material/TranscribeOutlined')),
    TranscribeRounded: React.lazy(() => import('@mui/icons-material/TranscribeRounded')),
    TranscribeSharp: React.lazy(() => import('@mui/icons-material/TranscribeSharp')),
    TranscribeTwoTone: React.lazy(() => import('@mui/icons-material/TranscribeTwoTone')),
    TransferWithinAStation: React.lazy(() => import('@mui/icons-material/TransferWithinAStation')),
    TransferWithinAStationOutlined: React.lazy(() => import('@mui/icons-material/TransferWithinAStationOutlined')),
    TransferWithinAStationRounded: React.lazy(() => import('@mui/icons-material/TransferWithinAStationRounded')),
    TransferWithinAStationSharp: React.lazy(() => import('@mui/icons-material/TransferWithinAStationSharp')),
    TransferWithinAStationTwoTone: React.lazy(() => import('@mui/icons-material/TransferWithinAStationTwoTone')),
    Transform: React.lazy(() => import('@mui/icons-material/Transform')),
    TransformOutlined: React.lazy(() => import('@mui/icons-material/TransformOutlined')),
    TransformRounded: React.lazy(() => import('@mui/icons-material/TransformRounded')),
    TransformSharp: React.lazy(() => import('@mui/icons-material/TransformSharp')),
    TransformTwoTone: React.lazy(() => import('@mui/icons-material/TransformTwoTone')),
    Transgender: React.lazy(() => import('@mui/icons-material/Transgender')),
    TransgenderOutlined: React.lazy(() => import('@mui/icons-material/TransgenderOutlined')),
    TransgenderRounded: React.lazy(() => import('@mui/icons-material/TransgenderRounded')),
    TransgenderSharp: React.lazy(() => import('@mui/icons-material/TransgenderSharp')),
    TransgenderTwoTone: React.lazy(() => import('@mui/icons-material/TransgenderTwoTone')),
    TransitEnterexit: React.lazy(() => import('@mui/icons-material/TransitEnterexit')),
    TransitEnterexitOutlined: React.lazy(() => import('@mui/icons-material/TransitEnterexitOutlined')),
    TransitEnterexitRounded: React.lazy(() => import('@mui/icons-material/TransitEnterexitRounded')),
    TransitEnterexitSharp: React.lazy(() => import('@mui/icons-material/TransitEnterexitSharp')),
    TransitEnterexitTwoTone: React.lazy(() => import('@mui/icons-material/TransitEnterexitTwoTone')),
    Translate: React.lazy(() => import('@mui/icons-material/Translate')),
    TranslateOutlined: React.lazy(() => import('@mui/icons-material/TranslateOutlined')),
    TranslateRounded: React.lazy(() => import('@mui/icons-material/TranslateRounded')),
    TranslateSharp: React.lazy(() => import('@mui/icons-material/TranslateSharp')),
    TranslateTwoTone: React.lazy(() => import('@mui/icons-material/TranslateTwoTone')),
    TravelExplore: React.lazy(() => import('@mui/icons-material/TravelExplore')),
    TravelExploreOutlined: React.lazy(() => import('@mui/icons-material/TravelExploreOutlined')),
    TravelExploreRounded: React.lazy(() => import('@mui/icons-material/TravelExploreRounded')),
    TravelExploreSharp: React.lazy(() => import('@mui/icons-material/TravelExploreSharp')),
    TravelExploreTwoTone: React.lazy(() => import('@mui/icons-material/TravelExploreTwoTone')),
    TrendingDown: React.lazy(() => import('@mui/icons-material/TrendingDown')),
    TrendingDownOutlined: React.lazy(() => import('@mui/icons-material/TrendingDownOutlined')),
    TrendingDownRounded: React.lazy(() => import('@mui/icons-material/TrendingDownRounded')),
    TrendingDownSharp: React.lazy(() => import('@mui/icons-material/TrendingDownSharp')),
    TrendingDownTwoTone: React.lazy(() => import('@mui/icons-material/TrendingDownTwoTone')),
    TrendingFlat: React.lazy(() => import('@mui/icons-material/TrendingFlat')),
    TrendingFlatOutlined: React.lazy(() => import('@mui/icons-material/TrendingFlatOutlined')),
    TrendingFlatRounded: React.lazy(() => import('@mui/icons-material/TrendingFlatRounded')),
    TrendingFlatSharp: React.lazy(() => import('@mui/icons-material/TrendingFlatSharp')),
    TrendingFlatTwoTone: React.lazy(() => import('@mui/icons-material/TrendingFlatTwoTone')),
    TrendingUp: React.lazy(() => import('@mui/icons-material/TrendingUp')),
    TrendingUpOutlined: React.lazy(() => import('@mui/icons-material/TrendingUpOutlined')),
    TrendingUpRounded: React.lazy(() => import('@mui/icons-material/TrendingUpRounded')),
    TrendingUpSharp: React.lazy(() => import('@mui/icons-material/TrendingUpSharp')),
    TrendingUpTwoTone: React.lazy(() => import('@mui/icons-material/TrendingUpTwoTone')),
    TripOrigin: React.lazy(() => import('@mui/icons-material/TripOrigin')),
    TripOriginOutlined: React.lazy(() => import('@mui/icons-material/TripOriginOutlined')),
    TripOriginRounded: React.lazy(() => import('@mui/icons-material/TripOriginRounded')),
    TripOriginSharp: React.lazy(() => import('@mui/icons-material/TripOriginSharp')),
    TripOriginTwoTone: React.lazy(() => import('@mui/icons-material/TripOriginTwoTone')),
    Troubleshoot: React.lazy(() => import('@mui/icons-material/Troubleshoot')),
    TroubleshootOutlined: React.lazy(() => import('@mui/icons-material/TroubleshootOutlined')),
    TroubleshootRounded: React.lazy(() => import('@mui/icons-material/TroubleshootRounded')),
    TroubleshootSharp: React.lazy(() => import('@mui/icons-material/TroubleshootSharp')),
    TroubleshootTwoTone: React.lazy(() => import('@mui/icons-material/TroubleshootTwoTone')),
    Try: React.lazy(() => import('@mui/icons-material/Try')),
    TryOutlined: React.lazy(() => import('@mui/icons-material/TryOutlined')),
    TryRounded: React.lazy(() => import('@mui/icons-material/TryRounded')),
    TrySharp: React.lazy(() => import('@mui/icons-material/TrySharp')),
    TryTwoTone: React.lazy(() => import('@mui/icons-material/TryTwoTone')),
    Tsunami: React.lazy(() => import('@mui/icons-material/Tsunami')),
    TsunamiOutlined: React.lazy(() => import('@mui/icons-material/TsunamiOutlined')),
    TsunamiRounded: React.lazy(() => import('@mui/icons-material/TsunamiRounded')),
    TsunamiSharp: React.lazy(() => import('@mui/icons-material/TsunamiSharp')),
    TsunamiTwoTone: React.lazy(() => import('@mui/icons-material/TsunamiTwoTone')),
    Tty: React.lazy(() => import('@mui/icons-material/Tty')),
    TtyOutlined: React.lazy(() => import('@mui/icons-material/TtyOutlined')),
    TtyRounded: React.lazy(() => import('@mui/icons-material/TtyRounded')),
    TtySharp: React.lazy(() => import('@mui/icons-material/TtySharp')),
    TtyTwoTone: React.lazy(() => import('@mui/icons-material/TtyTwoTone')),
    Tune: React.lazy(() => import('@mui/icons-material/Tune')),
    TuneOutlined: React.lazy(() => import('@mui/icons-material/TuneOutlined')),
    TuneRounded: React.lazy(() => import('@mui/icons-material/TuneRounded')),
    TuneSharp: React.lazy(() => import('@mui/icons-material/TuneSharp')),
    TuneTwoTone: React.lazy(() => import('@mui/icons-material/TuneTwoTone')),
    Tungsten: React.lazy(() => import('@mui/icons-material/Tungsten')),
    TungstenOutlined: React.lazy(() => import('@mui/icons-material/TungstenOutlined')),
    TungstenRounded: React.lazy(() => import('@mui/icons-material/TungstenRounded')),
    TungstenSharp: React.lazy(() => import('@mui/icons-material/TungstenSharp')),
    TungstenTwoTone: React.lazy(() => import('@mui/icons-material/TungstenTwoTone')),
    TurnedIn: React.lazy(() => import('@mui/icons-material/TurnedIn')),
    TurnedInNot: React.lazy(() => import('@mui/icons-material/TurnedInNot')),
    TurnedInNotOutlined: React.lazy(() => import('@mui/icons-material/TurnedInNotOutlined')),
    TurnedInNotRounded: React.lazy(() => import('@mui/icons-material/TurnedInNotRounded')),
    TurnedInNotSharp: React.lazy(() => import('@mui/icons-material/TurnedInNotSharp')),
    TurnedInNotTwoTone: React.lazy(() => import('@mui/icons-material/TurnedInNotTwoTone')),
    TurnedInOutlined: React.lazy(() => import('@mui/icons-material/TurnedInOutlined')),
    TurnedInRounded: React.lazy(() => import('@mui/icons-material/TurnedInRounded')),
    TurnedInSharp: React.lazy(() => import('@mui/icons-material/TurnedInSharp')),
    TurnedInTwoTone: React.lazy(() => import('@mui/icons-material/TurnedInTwoTone')),
    TurnLeft: React.lazy(() => import('@mui/icons-material/TurnLeft')),
    TurnLeftOutlined: React.lazy(() => import('@mui/icons-material/TurnLeftOutlined')),
    TurnLeftRounded: React.lazy(() => import('@mui/icons-material/TurnLeftRounded')),
    TurnLeftSharp: React.lazy(() => import('@mui/icons-material/TurnLeftSharp')),
    TurnLeftTwoTone: React.lazy(() => import('@mui/icons-material/TurnLeftTwoTone')),
    TurnRight: React.lazy(() => import('@mui/icons-material/TurnRight')),
    TurnRightOutlined: React.lazy(() => import('@mui/icons-material/TurnRightOutlined')),
    TurnRightRounded: React.lazy(() => import('@mui/icons-material/TurnRightRounded')),
    TurnRightSharp: React.lazy(() => import('@mui/icons-material/TurnRightSharp')),
    TurnRightTwoTone: React.lazy(() => import('@mui/icons-material/TurnRightTwoTone')),
    TurnSharpLeft: React.lazy(() => import('@mui/icons-material/TurnSharpLeft')),
    TurnSharpLeftOutlined: React.lazy(() => import('@mui/icons-material/TurnSharpLeftOutlined')),
    TurnSharpLeftRounded: React.lazy(() => import('@mui/icons-material/TurnSharpLeftRounded')),
    TurnSharpLeftSharp: React.lazy(() => import('@mui/icons-material/TurnSharpLeftSharp')),
    TurnSharpLeftTwoTone: React.lazy(() => import('@mui/icons-material/TurnSharpLeftTwoTone')),
    TurnSharpRight: React.lazy(() => import('@mui/icons-material/TurnSharpRight')),
    TurnSharpRightOutlined: React.lazy(() => import('@mui/icons-material/TurnSharpRightOutlined')),
    TurnSharpRightRounded: React.lazy(() => import('@mui/icons-material/TurnSharpRightRounded')),
    TurnSharpRightSharp: React.lazy(() => import('@mui/icons-material/TurnSharpRightSharp')),
    TurnSharpRightTwoTone: React.lazy(() => import('@mui/icons-material/TurnSharpRightTwoTone')),
    TurnSlightLeft: React.lazy(() => import('@mui/icons-material/TurnSlightLeft')),
    TurnSlightLeftOutlined: React.lazy(() => import('@mui/icons-material/TurnSlightLeftOutlined')),
    TurnSlightLeftRounded: React.lazy(() => import('@mui/icons-material/TurnSlightLeftRounded')),
    TurnSlightLeftSharp: React.lazy(() => import('@mui/icons-material/TurnSlightLeftSharp')),
    TurnSlightLeftTwoTone: React.lazy(() => import('@mui/icons-material/TurnSlightLeftTwoTone')),
    TurnSlightRight: React.lazy(() => import('@mui/icons-material/TurnSlightRight')),
    TurnSlightRightOutlined: React.lazy(() => import('@mui/icons-material/TurnSlightRightOutlined')),
    TurnSlightRightRounded: React.lazy(() => import('@mui/icons-material/TurnSlightRightRounded')),
    TurnSlightRightSharp: React.lazy(() => import('@mui/icons-material/TurnSlightRightSharp')),
    TurnSlightRightTwoTone: React.lazy(() => import('@mui/icons-material/TurnSlightRightTwoTone')),
    Tv: React.lazy(() => import('@mui/icons-material/Tv')),
    TvOff: React.lazy(() => import('@mui/icons-material/TvOff')),
    TvOffOutlined: React.lazy(() => import('@mui/icons-material/TvOffOutlined')),
    TvOffRounded: React.lazy(() => import('@mui/icons-material/TvOffRounded')),
    TvOffSharp: React.lazy(() => import('@mui/icons-material/TvOffSharp')),
    TvOffTwoTone: React.lazy(() => import('@mui/icons-material/TvOffTwoTone')),
    TvOutlined: React.lazy(() => import('@mui/icons-material/TvOutlined')),
    TvRounded: React.lazy(() => import('@mui/icons-material/TvRounded')),
    TvSharp: React.lazy(() => import('@mui/icons-material/TvSharp')),
    TvTwoTone: React.lazy(() => import('@mui/icons-material/TvTwoTone')),
    TwelveMp: React.lazy(() => import('@mui/icons-material/TwelveMp')),
    TwelveMpOutlined: React.lazy(() => import('@mui/icons-material/TwelveMpOutlined')),
    TwelveMpRounded: React.lazy(() => import('@mui/icons-material/TwelveMpRounded')),
    TwelveMpSharp: React.lazy(() => import('@mui/icons-material/TwelveMpSharp')),
    TwelveMpTwoTone: React.lazy(() => import('@mui/icons-material/TwelveMpTwoTone')),
    TwentyFourMp: React.lazy(() => import('@mui/icons-material/TwentyFourMp')),
    TwentyFourMpOutlined: React.lazy(() => import('@mui/icons-material/TwentyFourMpOutlined')),
    TwentyFourMpRounded: React.lazy(() => import('@mui/icons-material/TwentyFourMpRounded')),
    TwentyFourMpSharp: React.lazy(() => import('@mui/icons-material/TwentyFourMpSharp')),
    TwentyFourMpTwoTone: React.lazy(() => import('@mui/icons-material/TwentyFourMpTwoTone')),
    TwentyOneMp: React.lazy(() => import('@mui/icons-material/TwentyOneMp')),
    TwentyOneMpOutlined: React.lazy(() => import('@mui/icons-material/TwentyOneMpOutlined')),
    TwentyOneMpRounded: React.lazy(() => import('@mui/icons-material/TwentyOneMpRounded')),
    TwentyOneMpSharp: React.lazy(() => import('@mui/icons-material/TwentyOneMpSharp')),
    TwentyOneMpTwoTone: React.lazy(() => import('@mui/icons-material/TwentyOneMpTwoTone')),
    TwentyThreeMp: React.lazy(() => import('@mui/icons-material/TwentyThreeMp')),
    TwentyThreeMpOutlined: React.lazy(() => import('@mui/icons-material/TwentyThreeMpOutlined')),
    TwentyThreeMpRounded: React.lazy(() => import('@mui/icons-material/TwentyThreeMpRounded')),
    TwentyThreeMpSharp: React.lazy(() => import('@mui/icons-material/TwentyThreeMpSharp')),
    TwentyThreeMpTwoTone: React.lazy(() => import('@mui/icons-material/TwentyThreeMpTwoTone')),
    TwentyTwoMp: React.lazy(() => import('@mui/icons-material/TwentyTwoMp')),
    TwentyTwoMpOutlined: React.lazy(() => import('@mui/icons-material/TwentyTwoMpOutlined')),
    TwentyTwoMpRounded: React.lazy(() => import('@mui/icons-material/TwentyTwoMpRounded')),
    TwentyTwoMpSharp: React.lazy(() => import('@mui/icons-material/TwentyTwoMpSharp')),
    TwentyTwoMpTwoTone: React.lazy(() => import('@mui/icons-material/TwentyTwoMpTwoTone')),
    TwentyZeroMp: React.lazy(() => import('@mui/icons-material/TwentyZeroMp')),
    TwentyZeroMpOutlined: React.lazy(() => import('@mui/icons-material/TwentyZeroMpOutlined')),
    TwentyZeroMpRounded: React.lazy(() => import('@mui/icons-material/TwentyZeroMpRounded')),
    TwentyZeroMpSharp: React.lazy(() => import('@mui/icons-material/TwentyZeroMpSharp')),
    TwentyZeroMpTwoTone: React.lazy(() => import('@mui/icons-material/TwentyZeroMpTwoTone')),
    Twitter: React.lazy(() => import('@mui/icons-material/Twitter')),
    TwoK: React.lazy(() => import('@mui/icons-material/TwoK')),
    TwoKOutlined: React.lazy(() => import('@mui/icons-material/TwoKOutlined')),
    TwoKPlus: React.lazy(() => import('@mui/icons-material/TwoKPlus')),
    TwoKPlusOutlined: React.lazy(() => import('@mui/icons-material/TwoKPlusOutlined')),
    TwoKPlusRounded: React.lazy(() => import('@mui/icons-material/TwoKPlusRounded')),
    TwoKPlusSharp: React.lazy(() => import('@mui/icons-material/TwoKPlusSharp')),
    TwoKPlusTwoTone: React.lazy(() => import('@mui/icons-material/TwoKPlusTwoTone')),
    TwoKRounded: React.lazy(() => import('@mui/icons-material/TwoKRounded')),
    TwoKSharp: React.lazy(() => import('@mui/icons-material/TwoKSharp')),
    TwoKTwoTone: React.lazy(() => import('@mui/icons-material/TwoKTwoTone')),
    TwoMp: React.lazy(() => import('@mui/icons-material/TwoMp')),
    TwoMpOutlined: React.lazy(() => import('@mui/icons-material/TwoMpOutlined')),
    TwoMpRounded: React.lazy(() => import('@mui/icons-material/TwoMpRounded')),
    TwoMpSharp: React.lazy(() => import('@mui/icons-material/TwoMpSharp')),
    TwoMpTwoTone: React.lazy(() => import('@mui/icons-material/TwoMpTwoTone')),
    TwoWheeler: React.lazy(() => import('@mui/icons-material/TwoWheeler')),
    TwoWheelerOutlined: React.lazy(() => import('@mui/icons-material/TwoWheelerOutlined')),
    TwoWheelerRounded: React.lazy(() => import('@mui/icons-material/TwoWheelerRounded')),
    TwoWheelerSharp: React.lazy(() => import('@mui/icons-material/TwoWheelerSharp')),
    TwoWheelerTwoTone: React.lazy(() => import('@mui/icons-material/TwoWheelerTwoTone')),
    TypeSpecimen: React.lazy(() => import('@mui/icons-material/TypeSpecimen')),
    TypeSpecimenOutlined: React.lazy(() => import('@mui/icons-material/TypeSpecimenOutlined')),
    TypeSpecimenRounded: React.lazy(() => import('@mui/icons-material/TypeSpecimenRounded')),
    TypeSpecimenSharp: React.lazy(() => import('@mui/icons-material/TypeSpecimenSharp')),
    TypeSpecimenTwoTone: React.lazy(() => import('@mui/icons-material/TypeSpecimenTwoTone')),
    Umbrella: React.lazy(() => import('@mui/icons-material/Umbrella')),
    UmbrellaOutlined: React.lazy(() => import('@mui/icons-material/UmbrellaOutlined')),
    UmbrellaRounded: React.lazy(() => import('@mui/icons-material/UmbrellaRounded')),
    UmbrellaSharp: React.lazy(() => import('@mui/icons-material/UmbrellaSharp')),
    UmbrellaTwoTone: React.lazy(() => import('@mui/icons-material/UmbrellaTwoTone')),
    Unarchive: React.lazy(() => import('@mui/icons-material/Unarchive')),
    UnarchiveOutlined: React.lazy(() => import('@mui/icons-material/UnarchiveOutlined')),
    UnarchiveRounded: React.lazy(() => import('@mui/icons-material/UnarchiveRounded')),
    UnarchiveSharp: React.lazy(() => import('@mui/icons-material/UnarchiveSharp')),
    UnarchiveTwoTone: React.lazy(() => import('@mui/icons-material/UnarchiveTwoTone')),
    Undo: React.lazy(() => import('@mui/icons-material/Undo')),
    UndoOutlined: React.lazy(() => import('@mui/icons-material/UndoOutlined')),
    UndoRounded: React.lazy(() => import('@mui/icons-material/UndoRounded')),
    UndoSharp: React.lazy(() => import('@mui/icons-material/UndoSharp')),
    UndoTwoTone: React.lazy(() => import('@mui/icons-material/UndoTwoTone')),
    UnfoldLessDouble: React.lazy(() => import('@mui/icons-material/UnfoldLessDouble')),
    UnfoldLessDoubleOutlined: React.lazy(() => import('@mui/icons-material/UnfoldLessDoubleOutlined')),
    UnfoldLessDoubleRounded: React.lazy(() => import('@mui/icons-material/UnfoldLessDoubleRounded')),
    UnfoldLessDoubleSharp: React.lazy(() => import('@mui/icons-material/UnfoldLessDoubleSharp')),
    UnfoldLessDoubleTwoTone: React.lazy(() => import('@mui/icons-material/UnfoldLessDoubleTwoTone')),
    UnfoldLess: React.lazy(() => import('@mui/icons-material/UnfoldLess')),
    UnfoldLessOutlined: React.lazy(() => import('@mui/icons-material/UnfoldLessOutlined')),
    UnfoldLessRounded: React.lazy(() => import('@mui/icons-material/UnfoldLessRounded')),
    UnfoldLessSharp: React.lazy(() => import('@mui/icons-material/UnfoldLessSharp')),
    UnfoldLessTwoTone: React.lazy(() => import('@mui/icons-material/UnfoldLessTwoTone')),
    UnfoldMoreDouble: React.lazy(() => import('@mui/icons-material/UnfoldMoreDouble')),
    UnfoldMoreDoubleOutlined: React.lazy(() => import('@mui/icons-material/UnfoldMoreDoubleOutlined')),
    UnfoldMoreDoubleRounded: React.lazy(() => import('@mui/icons-material/UnfoldMoreDoubleRounded')),
    UnfoldMoreDoubleSharp: React.lazy(() => import('@mui/icons-material/UnfoldMoreDoubleSharp')),
    UnfoldMoreDoubleTwoTone: React.lazy(() => import('@mui/icons-material/UnfoldMoreDoubleTwoTone')),
    UnfoldMore: React.lazy(() => import('@mui/icons-material/UnfoldMore')),
    UnfoldMoreOutlined: React.lazy(() => import('@mui/icons-material/UnfoldMoreOutlined')),
    UnfoldMoreRounded: React.lazy(() => import('@mui/icons-material/UnfoldMoreRounded')),
    UnfoldMoreSharp: React.lazy(() => import('@mui/icons-material/UnfoldMoreSharp')),
    UnfoldMoreTwoTone: React.lazy(() => import('@mui/icons-material/UnfoldMoreTwoTone')),
    Unpublished: React.lazy(() => import('@mui/icons-material/Unpublished')),
    UnpublishedOutlined: React.lazy(() => import('@mui/icons-material/UnpublishedOutlined')),
    UnpublishedRounded: React.lazy(() => import('@mui/icons-material/UnpublishedRounded')),
    UnpublishedSharp: React.lazy(() => import('@mui/icons-material/UnpublishedSharp')),
    UnpublishedTwoTone: React.lazy(() => import('@mui/icons-material/UnpublishedTwoTone')),
    Unsubscribe: React.lazy(() => import('@mui/icons-material/Unsubscribe')),
    UnsubscribeOutlined: React.lazy(() => import('@mui/icons-material/UnsubscribeOutlined')),
    UnsubscribeRounded: React.lazy(() => import('@mui/icons-material/UnsubscribeRounded')),
    UnsubscribeSharp: React.lazy(() => import('@mui/icons-material/UnsubscribeSharp')),
    UnsubscribeTwoTone: React.lazy(() => import('@mui/icons-material/UnsubscribeTwoTone')),
    Upcoming: React.lazy(() => import('@mui/icons-material/Upcoming')),
    UpcomingOutlined: React.lazy(() => import('@mui/icons-material/UpcomingOutlined')),
    UpcomingRounded: React.lazy(() => import('@mui/icons-material/UpcomingRounded')),
    UpcomingSharp: React.lazy(() => import('@mui/icons-material/UpcomingSharp')),
    UpcomingTwoTone: React.lazy(() => import('@mui/icons-material/UpcomingTwoTone')),
    UpdateDisabled: React.lazy(() => import('@mui/icons-material/UpdateDisabled')),
    UpdateDisabledOutlined: React.lazy(() => import('@mui/icons-material/UpdateDisabledOutlined')),
    UpdateDisabledRounded: React.lazy(() => import('@mui/icons-material/UpdateDisabledRounded')),
    UpdateDisabledSharp: React.lazy(() => import('@mui/icons-material/UpdateDisabledSharp')),
    UpdateDisabledTwoTone: React.lazy(() => import('@mui/icons-material/UpdateDisabledTwoTone')),
    Update: React.lazy(() => import('@mui/icons-material/Update')),
    UpdateOutlined: React.lazy(() => import('@mui/icons-material/UpdateOutlined')),
    UpdateRounded: React.lazy(() => import('@mui/icons-material/UpdateRounded')),
    UpdateSharp: React.lazy(() => import('@mui/icons-material/UpdateSharp')),
    UpdateTwoTone: React.lazy(() => import('@mui/icons-material/UpdateTwoTone')),
    Upgrade: React.lazy(() => import('@mui/icons-material/Upgrade')),
    UpgradeOutlined: React.lazy(() => import('@mui/icons-material/UpgradeOutlined')),
    UpgradeRounded: React.lazy(() => import('@mui/icons-material/UpgradeRounded')),
    UpgradeSharp: React.lazy(() => import('@mui/icons-material/UpgradeSharp')),
    UpgradeTwoTone: React.lazy(() => import('@mui/icons-material/UpgradeTwoTone')),
    Upload: React.lazy(() => import('@mui/icons-material/Upload')),
    UploadFile: React.lazy(() => import('@mui/icons-material/UploadFile')),
    UploadFileOutlined: React.lazy(() => import('@mui/icons-material/UploadFileOutlined')),
    UploadFileRounded: React.lazy(() => import('@mui/icons-material/UploadFileRounded')),
    UploadFileSharp: React.lazy(() => import('@mui/icons-material/UploadFileSharp')),
    UploadFileTwoTone: React.lazy(() => import('@mui/icons-material/UploadFileTwoTone')),
    UploadOutlined: React.lazy(() => import('@mui/icons-material/UploadOutlined')),
    UploadRounded: React.lazy(() => import('@mui/icons-material/UploadRounded')),
    UploadSharp: React.lazy(() => import('@mui/icons-material/UploadSharp')),
    UploadTwoTone: React.lazy(() => import('@mui/icons-material/UploadTwoTone')),
    Usb: React.lazy(() => import('@mui/icons-material/Usb')),
    UsbOff: React.lazy(() => import('@mui/icons-material/UsbOff')),
    UsbOffOutlined: React.lazy(() => import('@mui/icons-material/UsbOffOutlined')),
    UsbOffRounded: React.lazy(() => import('@mui/icons-material/UsbOffRounded')),
    UsbOffSharp: React.lazy(() => import('@mui/icons-material/UsbOffSharp')),
    UsbOffTwoTone: React.lazy(() => import('@mui/icons-material/UsbOffTwoTone')),
    UsbOutlined: React.lazy(() => import('@mui/icons-material/UsbOutlined')),
    UsbRounded: React.lazy(() => import('@mui/icons-material/UsbRounded')),
    UsbSharp: React.lazy(() => import('@mui/icons-material/UsbSharp')),
    UsbTwoTone: React.lazy(() => import('@mui/icons-material/UsbTwoTone')),
    UTurnLeft: React.lazy(() => import('@mui/icons-material/UTurnLeft')),
    UTurnLeftOutlined: React.lazy(() => import('@mui/icons-material/UTurnLeftOutlined')),
    UTurnLeftRounded: React.lazy(() => import('@mui/icons-material/UTurnLeftRounded')),
    UTurnLeftSharp: React.lazy(() => import('@mui/icons-material/UTurnLeftSharp')),
    UTurnLeftTwoTone: React.lazy(() => import('@mui/icons-material/UTurnLeftTwoTone')),
    UTurnRight: React.lazy(() => import('@mui/icons-material/UTurnRight')),
    UTurnRightOutlined: React.lazy(() => import('@mui/icons-material/UTurnRightOutlined')),
    UTurnRightRounded: React.lazy(() => import('@mui/icons-material/UTurnRightRounded')),
    UTurnRightSharp: React.lazy(() => import('@mui/icons-material/UTurnRightSharp')),
    UTurnRightTwoTone: React.lazy(() => import('@mui/icons-material/UTurnRightTwoTone')),
    Vaccines: React.lazy(() => import('@mui/icons-material/Vaccines')),
    VaccinesOutlined: React.lazy(() => import('@mui/icons-material/VaccinesOutlined')),
    VaccinesRounded: React.lazy(() => import('@mui/icons-material/VaccinesRounded')),
    VaccinesSharp: React.lazy(() => import('@mui/icons-material/VaccinesSharp')),
    VaccinesTwoTone: React.lazy(() => import('@mui/icons-material/VaccinesTwoTone')),
    VapeFree: React.lazy(() => import('@mui/icons-material/VapeFree')),
    VapeFreeOutlined: React.lazy(() => import('@mui/icons-material/VapeFreeOutlined')),
    VapeFreeRounded: React.lazy(() => import('@mui/icons-material/VapeFreeRounded')),
    VapeFreeSharp: React.lazy(() => import('@mui/icons-material/VapeFreeSharp')),
    VapeFreeTwoTone: React.lazy(() => import('@mui/icons-material/VapeFreeTwoTone')),
    VapingRooms: React.lazy(() => import('@mui/icons-material/VapingRooms')),
    VapingRoomsOutlined: React.lazy(() => import('@mui/icons-material/VapingRoomsOutlined')),
    VapingRoomsRounded: React.lazy(() => import('@mui/icons-material/VapingRoomsRounded')),
    VapingRoomsSharp: React.lazy(() => import('@mui/icons-material/VapingRoomsSharp')),
    VapingRoomsTwoTone: React.lazy(() => import('@mui/icons-material/VapingRoomsTwoTone')),
    Verified: React.lazy(() => import('@mui/icons-material/Verified')),
    VerifiedOutlined: React.lazy(() => import('@mui/icons-material/VerifiedOutlined')),
    VerifiedRounded: React.lazy(() => import('@mui/icons-material/VerifiedRounded')),
    VerifiedSharp: React.lazy(() => import('@mui/icons-material/VerifiedSharp')),
    VerifiedTwoTone: React.lazy(() => import('@mui/icons-material/VerifiedTwoTone')),
    VerifiedUser: React.lazy(() => import('@mui/icons-material/VerifiedUser')),
    VerifiedUserOutlined: React.lazy(() => import('@mui/icons-material/VerifiedUserOutlined')),
    VerifiedUserRounded: React.lazy(() => import('@mui/icons-material/VerifiedUserRounded')),
    VerifiedUserSharp: React.lazy(() => import('@mui/icons-material/VerifiedUserSharp')),
    VerifiedUserTwoTone: React.lazy(() => import('@mui/icons-material/VerifiedUserTwoTone')),
    VerticalAlignBottom: React.lazy(() => import('@mui/icons-material/VerticalAlignBottom')),
    VerticalAlignBottomOutlined: React.lazy(() => import('@mui/icons-material/VerticalAlignBottomOutlined')),
    VerticalAlignBottomRounded: React.lazy(() => import('@mui/icons-material/VerticalAlignBottomRounded')),
    VerticalAlignBottomSharp: React.lazy(() => import('@mui/icons-material/VerticalAlignBottomSharp')),
    VerticalAlignBottomTwoTone: React.lazy(() => import('@mui/icons-material/VerticalAlignBottomTwoTone')),
    VerticalAlignCenter: React.lazy(() => import('@mui/icons-material/VerticalAlignCenter')),
    VerticalAlignCenterOutlined: React.lazy(() => import('@mui/icons-material/VerticalAlignCenterOutlined')),
    VerticalAlignCenterRounded: React.lazy(() => import('@mui/icons-material/VerticalAlignCenterRounded')),
    VerticalAlignCenterSharp: React.lazy(() => import('@mui/icons-material/VerticalAlignCenterSharp')),
    VerticalAlignCenterTwoTone: React.lazy(() => import('@mui/icons-material/VerticalAlignCenterTwoTone')),
    VerticalAlignTop: React.lazy(() => import('@mui/icons-material/VerticalAlignTop')),
    VerticalAlignTopOutlined: React.lazy(() => import('@mui/icons-material/VerticalAlignTopOutlined')),
    VerticalAlignTopRounded: React.lazy(() => import('@mui/icons-material/VerticalAlignTopRounded')),
    VerticalAlignTopSharp: React.lazy(() => import('@mui/icons-material/VerticalAlignTopSharp')),
    VerticalAlignTopTwoTone: React.lazy(() => import('@mui/icons-material/VerticalAlignTopTwoTone')),
    VerticalShadesClosed: React.lazy(() => import('@mui/icons-material/VerticalShadesClosed')),
    VerticalShadesClosedOutlined: React.lazy(() => import('@mui/icons-material/VerticalShadesClosedOutlined')),
    VerticalShadesClosedRounded: React.lazy(() => import('@mui/icons-material/VerticalShadesClosedRounded')),
    VerticalShadesClosedSharp: React.lazy(() => import('@mui/icons-material/VerticalShadesClosedSharp')),
    VerticalShadesClosedTwoTone: React.lazy(() => import('@mui/icons-material/VerticalShadesClosedTwoTone')),
    VerticalShades: React.lazy(() => import('@mui/icons-material/VerticalShades')),
    VerticalShadesOutlined: React.lazy(() => import('@mui/icons-material/VerticalShadesOutlined')),
    VerticalShadesRounded: React.lazy(() => import('@mui/icons-material/VerticalShadesRounded')),
    VerticalShadesSharp: React.lazy(() => import('@mui/icons-material/VerticalShadesSharp')),
    VerticalShadesTwoTone: React.lazy(() => import('@mui/icons-material/VerticalShadesTwoTone')),
    VerticalSplit: React.lazy(() => import('@mui/icons-material/VerticalSplit')),
    VerticalSplitOutlined: React.lazy(() => import('@mui/icons-material/VerticalSplitOutlined')),
    VerticalSplitRounded: React.lazy(() => import('@mui/icons-material/VerticalSplitRounded')),
    VerticalSplitSharp: React.lazy(() => import('@mui/icons-material/VerticalSplitSharp')),
    VerticalSplitTwoTone: React.lazy(() => import('@mui/icons-material/VerticalSplitTwoTone')),
    Vibration: React.lazy(() => import('@mui/icons-material/Vibration')),
    VibrationOutlined: React.lazy(() => import('@mui/icons-material/VibrationOutlined')),
    VibrationRounded: React.lazy(() => import('@mui/icons-material/VibrationRounded')),
    VibrationSharp: React.lazy(() => import('@mui/icons-material/VibrationSharp')),
    VibrationTwoTone: React.lazy(() => import('@mui/icons-material/VibrationTwoTone')),
    VideoCall: React.lazy(() => import('@mui/icons-material/VideoCall')),
    VideoCallOutlined: React.lazy(() => import('@mui/icons-material/VideoCallOutlined')),
    VideoCallRounded: React.lazy(() => import('@mui/icons-material/VideoCallRounded')),
    VideoCallSharp: React.lazy(() => import('@mui/icons-material/VideoCallSharp')),
    VideoCallTwoTone: React.lazy(() => import('@mui/icons-material/VideoCallTwoTone')),
    Videocam: React.lazy(() => import('@mui/icons-material/Videocam')),
    VideoCameraBack: React.lazy(() => import('@mui/icons-material/VideoCameraBack')),
    VideoCameraBackOutlined: React.lazy(() => import('@mui/icons-material/VideoCameraBackOutlined')),
    VideoCameraBackRounded: React.lazy(() => import('@mui/icons-material/VideoCameraBackRounded')),
    VideoCameraBackSharp: React.lazy(() => import('@mui/icons-material/VideoCameraBackSharp')),
    VideoCameraBackTwoTone: React.lazy(() => import('@mui/icons-material/VideoCameraBackTwoTone')),
    VideoCameraFront: React.lazy(() => import('@mui/icons-material/VideoCameraFront')),
    VideoCameraFrontOutlined: React.lazy(() => import('@mui/icons-material/VideoCameraFrontOutlined')),
    VideoCameraFrontRounded: React.lazy(() => import('@mui/icons-material/VideoCameraFrontRounded')),
    VideoCameraFrontSharp: React.lazy(() => import('@mui/icons-material/VideoCameraFrontSharp')),
    VideoCameraFrontTwoTone: React.lazy(() => import('@mui/icons-material/VideoCameraFrontTwoTone')),
    VideocamOff: React.lazy(() => import('@mui/icons-material/VideocamOff')),
    VideocamOffOutlined: React.lazy(() => import('@mui/icons-material/VideocamOffOutlined')),
    VideocamOffRounded: React.lazy(() => import('@mui/icons-material/VideocamOffRounded')),
    VideocamOffSharp: React.lazy(() => import('@mui/icons-material/VideocamOffSharp')),
    VideocamOffTwoTone: React.lazy(() => import('@mui/icons-material/VideocamOffTwoTone')),
    VideocamOutlined: React.lazy(() => import('@mui/icons-material/VideocamOutlined')),
    VideocamRounded: React.lazy(() => import('@mui/icons-material/VideocamRounded')),
    VideocamSharp: React.lazy(() => import('@mui/icons-material/VideocamSharp')),
    VideocamTwoTone: React.lazy(() => import('@mui/icons-material/VideocamTwoTone')),
    VideoChat: React.lazy(() => import('@mui/icons-material/VideoChat')),
    VideoChatOutlined: React.lazy(() => import('@mui/icons-material/VideoChatOutlined')),
    VideoChatRounded: React.lazy(() => import('@mui/icons-material/VideoChatRounded')),
    VideoChatSharp: React.lazy(() => import('@mui/icons-material/VideoChatSharp')),
    VideoChatTwoTone: React.lazy(() => import('@mui/icons-material/VideoChatTwoTone')),
    VideoFile: React.lazy(() => import('@mui/icons-material/VideoFile')),
    VideoFileOutlined: React.lazy(() => import('@mui/icons-material/VideoFileOutlined')),
    VideoFileRounded: React.lazy(() => import('@mui/icons-material/VideoFileRounded')),
    VideoFileSharp: React.lazy(() => import('@mui/icons-material/VideoFileSharp')),
    VideoFileTwoTone: React.lazy(() => import('@mui/icons-material/VideoFileTwoTone')),
    VideogameAsset: React.lazy(() => import('@mui/icons-material/VideogameAsset')),
    VideogameAssetOff: React.lazy(() => import('@mui/icons-material/VideogameAssetOff')),
    VideogameAssetOffOutlined: React.lazy(() => import('@mui/icons-material/VideogameAssetOffOutlined')),
    VideogameAssetOffRounded: React.lazy(() => import('@mui/icons-material/VideogameAssetOffRounded')),
    VideogameAssetOffSharp: React.lazy(() => import('@mui/icons-material/VideogameAssetOffSharp')),
    VideogameAssetOffTwoTone: React.lazy(() => import('@mui/icons-material/VideogameAssetOffTwoTone')),
    VideogameAssetOutlined: React.lazy(() => import('@mui/icons-material/VideogameAssetOutlined')),
    VideogameAssetRounded: React.lazy(() => import('@mui/icons-material/VideogameAssetRounded')),
    VideogameAssetSharp: React.lazy(() => import('@mui/icons-material/VideogameAssetSharp')),
    VideogameAssetTwoTone: React.lazy(() => import('@mui/icons-material/VideogameAssetTwoTone')),
    VideoLabel: React.lazy(() => import('@mui/icons-material/VideoLabel')),
    VideoLabelOutlined: React.lazy(() => import('@mui/icons-material/VideoLabelOutlined')),
    VideoLabelRounded: React.lazy(() => import('@mui/icons-material/VideoLabelRounded')),
    VideoLabelSharp: React.lazy(() => import('@mui/icons-material/VideoLabelSharp')),
    VideoLabelTwoTone: React.lazy(() => import('@mui/icons-material/VideoLabelTwoTone')),
    VideoLibrary: React.lazy(() => import('@mui/icons-material/VideoLibrary')),
    VideoLibraryOutlined: React.lazy(() => import('@mui/icons-material/VideoLibraryOutlined')),
    VideoLibraryRounded: React.lazy(() => import('@mui/icons-material/VideoLibraryRounded')),
    VideoLibrarySharp: React.lazy(() => import('@mui/icons-material/VideoLibrarySharp')),
    VideoLibraryTwoTone: React.lazy(() => import('@mui/icons-material/VideoLibraryTwoTone')),
    VideoSettings: React.lazy(() => import('@mui/icons-material/VideoSettings')),
    VideoSettingsOutlined: React.lazy(() => import('@mui/icons-material/VideoSettingsOutlined')),
    VideoSettingsRounded: React.lazy(() => import('@mui/icons-material/VideoSettingsRounded')),
    VideoSettingsSharp: React.lazy(() => import('@mui/icons-material/VideoSettingsSharp')),
    VideoSettingsTwoTone: React.lazy(() => import('@mui/icons-material/VideoSettingsTwoTone')),
    VideoStable: React.lazy(() => import('@mui/icons-material/VideoStable')),
    VideoStableOutlined: React.lazy(() => import('@mui/icons-material/VideoStableOutlined')),
    VideoStableRounded: React.lazy(() => import('@mui/icons-material/VideoStableRounded')),
    VideoStableSharp: React.lazy(() => import('@mui/icons-material/VideoStableSharp')),
    VideoStableTwoTone: React.lazy(() => import('@mui/icons-material/VideoStableTwoTone')),
    ViewAgenda: React.lazy(() => import('@mui/icons-material/ViewAgenda')),
    ViewAgendaOutlined: React.lazy(() => import('@mui/icons-material/ViewAgendaOutlined')),
    ViewAgendaRounded: React.lazy(() => import('@mui/icons-material/ViewAgendaRounded')),
    ViewAgendaSharp: React.lazy(() => import('@mui/icons-material/ViewAgendaSharp')),
    ViewAgendaTwoTone: React.lazy(() => import('@mui/icons-material/ViewAgendaTwoTone')),
    ViewArray: React.lazy(() => import('@mui/icons-material/ViewArray')),
    ViewArrayOutlined: React.lazy(() => import('@mui/icons-material/ViewArrayOutlined')),
    ViewArrayRounded: React.lazy(() => import('@mui/icons-material/ViewArrayRounded')),
    ViewArraySharp: React.lazy(() => import('@mui/icons-material/ViewArraySharp')),
    ViewArrayTwoTone: React.lazy(() => import('@mui/icons-material/ViewArrayTwoTone')),
    ViewCarousel: React.lazy(() => import('@mui/icons-material/ViewCarousel')),
    ViewCarouselOutlined: React.lazy(() => import('@mui/icons-material/ViewCarouselOutlined')),
    ViewCarouselRounded: React.lazy(() => import('@mui/icons-material/ViewCarouselRounded')),
    ViewCarouselSharp: React.lazy(() => import('@mui/icons-material/ViewCarouselSharp')),
    ViewCarouselTwoTone: React.lazy(() => import('@mui/icons-material/ViewCarouselTwoTone')),
    ViewColumn: React.lazy(() => import('@mui/icons-material/ViewColumn')),
    ViewColumnOutlined: React.lazy(() => import('@mui/icons-material/ViewColumnOutlined')),
    ViewColumnRounded: React.lazy(() => import('@mui/icons-material/ViewColumnRounded')),
    ViewColumnSharp: React.lazy(() => import('@mui/icons-material/ViewColumnSharp')),
    ViewColumnTwoTone: React.lazy(() => import('@mui/icons-material/ViewColumnTwoTone')),
    ViewComfyAlt: React.lazy(() => import('@mui/icons-material/ViewComfyAlt')),
    ViewComfyAltOutlined: React.lazy(() => import('@mui/icons-material/ViewComfyAltOutlined')),
    ViewComfyAltRounded: React.lazy(() => import('@mui/icons-material/ViewComfyAltRounded')),
    ViewComfyAltSharp: React.lazy(() => import('@mui/icons-material/ViewComfyAltSharp')),
    ViewComfyAltTwoTone: React.lazy(() => import('@mui/icons-material/ViewComfyAltTwoTone')),
    ViewComfy: React.lazy(() => import('@mui/icons-material/ViewComfy')),
    ViewComfyOutlined: React.lazy(() => import('@mui/icons-material/ViewComfyOutlined')),
    ViewComfyRounded: React.lazy(() => import('@mui/icons-material/ViewComfyRounded')),
    ViewComfySharp: React.lazy(() => import('@mui/icons-material/ViewComfySharp')),
    ViewComfyTwoTone: React.lazy(() => import('@mui/icons-material/ViewComfyTwoTone')),
    ViewCompactAlt: React.lazy(() => import('@mui/icons-material/ViewCompactAlt')),
    ViewCompactAltOutlined: React.lazy(() => import('@mui/icons-material/ViewCompactAltOutlined')),
    ViewCompactAltRounded: React.lazy(() => import('@mui/icons-material/ViewCompactAltRounded')),
    ViewCompactAltSharp: React.lazy(() => import('@mui/icons-material/ViewCompactAltSharp')),
    ViewCompactAltTwoTone: React.lazy(() => import('@mui/icons-material/ViewCompactAltTwoTone')),
    ViewCompact: React.lazy(() => import('@mui/icons-material/ViewCompact')),
    ViewCompactOutlined: React.lazy(() => import('@mui/icons-material/ViewCompactOutlined')),
    ViewCompactRounded: React.lazy(() => import('@mui/icons-material/ViewCompactRounded')),
    ViewCompactSharp: React.lazy(() => import('@mui/icons-material/ViewCompactSharp')),
    ViewCompactTwoTone: React.lazy(() => import('@mui/icons-material/ViewCompactTwoTone')),
    ViewCozy: React.lazy(() => import('@mui/icons-material/ViewCozy')),
    ViewCozyOutlined: React.lazy(() => import('@mui/icons-material/ViewCozyOutlined')),
    ViewCozyRounded: React.lazy(() => import('@mui/icons-material/ViewCozyRounded')),
    ViewCozySharp: React.lazy(() => import('@mui/icons-material/ViewCozySharp')),
    ViewCozyTwoTone: React.lazy(() => import('@mui/icons-material/ViewCozyTwoTone')),
    ViewDay: React.lazy(() => import('@mui/icons-material/ViewDay')),
    ViewDayOutlined: React.lazy(() => import('@mui/icons-material/ViewDayOutlined')),
    ViewDayRounded: React.lazy(() => import('@mui/icons-material/ViewDayRounded')),
    ViewDaySharp: React.lazy(() => import('@mui/icons-material/ViewDaySharp')),
    ViewDayTwoTone: React.lazy(() => import('@mui/icons-material/ViewDayTwoTone')),
    ViewHeadline: React.lazy(() => import('@mui/icons-material/ViewHeadline')),
    ViewHeadlineOutlined: React.lazy(() => import('@mui/icons-material/ViewHeadlineOutlined')),
    ViewHeadlineRounded: React.lazy(() => import('@mui/icons-material/ViewHeadlineRounded')),
    ViewHeadlineSharp: React.lazy(() => import('@mui/icons-material/ViewHeadlineSharp')),
    ViewHeadlineTwoTone: React.lazy(() => import('@mui/icons-material/ViewHeadlineTwoTone')),
    ViewInAr: React.lazy(() => import('@mui/icons-material/ViewInAr')),
    ViewInArOutlined: React.lazy(() => import('@mui/icons-material/ViewInArOutlined')),
    ViewInArRounded: React.lazy(() => import('@mui/icons-material/ViewInArRounded')),
    ViewInArSharp: React.lazy(() => import('@mui/icons-material/ViewInArSharp')),
    ViewInArTwoTone: React.lazy(() => import('@mui/icons-material/ViewInArTwoTone')),
    ViewKanban: React.lazy(() => import('@mui/icons-material/ViewKanban')),
    ViewKanbanOutlined: React.lazy(() => import('@mui/icons-material/ViewKanbanOutlined')),
    ViewKanbanRounded: React.lazy(() => import('@mui/icons-material/ViewKanbanRounded')),
    ViewKanbanSharp: React.lazy(() => import('@mui/icons-material/ViewKanbanSharp')),
    ViewKanbanTwoTone: React.lazy(() => import('@mui/icons-material/ViewKanbanTwoTone')),
    ViewList: React.lazy(() => import('@mui/icons-material/ViewList')),
    ViewListOutlined: React.lazy(() => import('@mui/icons-material/ViewListOutlined')),
    ViewListRounded: React.lazy(() => import('@mui/icons-material/ViewListRounded')),
    ViewListSharp: React.lazy(() => import('@mui/icons-material/ViewListSharp')),
    ViewListTwoTone: React.lazy(() => import('@mui/icons-material/ViewListTwoTone')),
    ViewModule: React.lazy(() => import('@mui/icons-material/ViewModule')),
    ViewModuleOutlined: React.lazy(() => import('@mui/icons-material/ViewModuleOutlined')),
    ViewModuleRounded: React.lazy(() => import('@mui/icons-material/ViewModuleRounded')),
    ViewModuleSharp: React.lazy(() => import('@mui/icons-material/ViewModuleSharp')),
    ViewModuleTwoTone: React.lazy(() => import('@mui/icons-material/ViewModuleTwoTone')),
    ViewQuilt: React.lazy(() => import('@mui/icons-material/ViewQuilt')),
    ViewQuiltOutlined: React.lazy(() => import('@mui/icons-material/ViewQuiltOutlined')),
    ViewQuiltRounded: React.lazy(() => import('@mui/icons-material/ViewQuiltRounded')),
    ViewQuiltSharp: React.lazy(() => import('@mui/icons-material/ViewQuiltSharp')),
    ViewQuiltTwoTone: React.lazy(() => import('@mui/icons-material/ViewQuiltTwoTone')),
    ViewSidebar: React.lazy(() => import('@mui/icons-material/ViewSidebar')),
    ViewSidebarOutlined: React.lazy(() => import('@mui/icons-material/ViewSidebarOutlined')),
    ViewSidebarRounded: React.lazy(() => import('@mui/icons-material/ViewSidebarRounded')),
    ViewSidebarSharp: React.lazy(() => import('@mui/icons-material/ViewSidebarSharp')),
    ViewSidebarTwoTone: React.lazy(() => import('@mui/icons-material/ViewSidebarTwoTone')),
    ViewStream: React.lazy(() => import('@mui/icons-material/ViewStream')),
    ViewStreamOutlined: React.lazy(() => import('@mui/icons-material/ViewStreamOutlined')),
    ViewStreamRounded: React.lazy(() => import('@mui/icons-material/ViewStreamRounded')),
    ViewStreamSharp: React.lazy(() => import('@mui/icons-material/ViewStreamSharp')),
    ViewStreamTwoTone: React.lazy(() => import('@mui/icons-material/ViewStreamTwoTone')),
    ViewTimeline: React.lazy(() => import('@mui/icons-material/ViewTimeline')),
    ViewTimelineOutlined: React.lazy(() => import('@mui/icons-material/ViewTimelineOutlined')),
    ViewTimelineRounded: React.lazy(() => import('@mui/icons-material/ViewTimelineRounded')),
    ViewTimelineSharp: React.lazy(() => import('@mui/icons-material/ViewTimelineSharp')),
    ViewTimelineTwoTone: React.lazy(() => import('@mui/icons-material/ViewTimelineTwoTone')),
    ViewWeek: React.lazy(() => import('@mui/icons-material/ViewWeek')),
    ViewWeekOutlined: React.lazy(() => import('@mui/icons-material/ViewWeekOutlined')),
    ViewWeekRounded: React.lazy(() => import('@mui/icons-material/ViewWeekRounded')),
    ViewWeekSharp: React.lazy(() => import('@mui/icons-material/ViewWeekSharp')),
    ViewWeekTwoTone: React.lazy(() => import('@mui/icons-material/ViewWeekTwoTone')),
    Vignette: React.lazy(() => import('@mui/icons-material/Vignette')),
    VignetteOutlined: React.lazy(() => import('@mui/icons-material/VignetteOutlined')),
    VignetteRounded: React.lazy(() => import('@mui/icons-material/VignetteRounded')),
    VignetteSharp: React.lazy(() => import('@mui/icons-material/VignetteSharp')),
    VignetteTwoTone: React.lazy(() => import('@mui/icons-material/VignetteTwoTone')),
    Villa: React.lazy(() => import('@mui/icons-material/Villa')),
    VillaOutlined: React.lazy(() => import('@mui/icons-material/VillaOutlined')),
    VillaRounded: React.lazy(() => import('@mui/icons-material/VillaRounded')),
    VillaSharp: React.lazy(() => import('@mui/icons-material/VillaSharp')),
    VillaTwoTone: React.lazy(() => import('@mui/icons-material/VillaTwoTone')),
    Visibility: React.lazy(() => import('@mui/icons-material/Visibility')),
    VisibilityOff: React.lazy(() => import('@mui/icons-material/VisibilityOff')),
    VisibilityOffOutlined: React.lazy(() => import('@mui/icons-material/VisibilityOffOutlined')),
    VisibilityOffRounded: React.lazy(() => import('@mui/icons-material/VisibilityOffRounded')),
    VisibilityOffSharp: React.lazy(() => import('@mui/icons-material/VisibilityOffSharp')),
    VisibilityOffTwoTone: React.lazy(() => import('@mui/icons-material/VisibilityOffTwoTone')),
    VisibilityOutlined: React.lazy(() => import('@mui/icons-material/VisibilityOutlined')),
    VisibilityRounded: React.lazy(() => import('@mui/icons-material/VisibilityRounded')),
    VisibilitySharp: React.lazy(() => import('@mui/icons-material/VisibilitySharp')),
    VisibilityTwoTone: React.lazy(() => import('@mui/icons-material/VisibilityTwoTone')),
    VoiceChat: React.lazy(() => import('@mui/icons-material/VoiceChat')),
    VoiceChatOutlined: React.lazy(() => import('@mui/icons-material/VoiceChatOutlined')),
    VoiceChatRounded: React.lazy(() => import('@mui/icons-material/VoiceChatRounded')),
    VoiceChatSharp: React.lazy(() => import('@mui/icons-material/VoiceChatSharp')),
    VoiceChatTwoTone: React.lazy(() => import('@mui/icons-material/VoiceChatTwoTone')),
    Voicemail: React.lazy(() => import('@mui/icons-material/Voicemail')),
    VoicemailOutlined: React.lazy(() => import('@mui/icons-material/VoicemailOutlined')),
    VoicemailRounded: React.lazy(() => import('@mui/icons-material/VoicemailRounded')),
    VoicemailSharp: React.lazy(() => import('@mui/icons-material/VoicemailSharp')),
    VoicemailTwoTone: React.lazy(() => import('@mui/icons-material/VoicemailTwoTone')),
    VoiceOverOff: React.lazy(() => import('@mui/icons-material/VoiceOverOff')),
    VoiceOverOffOutlined: React.lazy(() => import('@mui/icons-material/VoiceOverOffOutlined')),
    VoiceOverOffRounded: React.lazy(() => import('@mui/icons-material/VoiceOverOffRounded')),
    VoiceOverOffSharp: React.lazy(() => import('@mui/icons-material/VoiceOverOffSharp')),
    VoiceOverOffTwoTone: React.lazy(() => import('@mui/icons-material/VoiceOverOffTwoTone')),
    Volcano: React.lazy(() => import('@mui/icons-material/Volcano')),
    VolcanoOutlined: React.lazy(() => import('@mui/icons-material/VolcanoOutlined')),
    VolcanoRounded: React.lazy(() => import('@mui/icons-material/VolcanoRounded')),
    VolcanoSharp: React.lazy(() => import('@mui/icons-material/VolcanoSharp')),
    VolcanoTwoTone: React.lazy(() => import('@mui/icons-material/VolcanoTwoTone')),
    VolumeDown: React.lazy(() => import('@mui/icons-material/VolumeDown')),
    VolumeDownOutlined: React.lazy(() => import('@mui/icons-material/VolumeDownOutlined')),
    VolumeDownRounded: React.lazy(() => import('@mui/icons-material/VolumeDownRounded')),
    VolumeDownSharp: React.lazy(() => import('@mui/icons-material/VolumeDownSharp')),
    VolumeDownTwoTone: React.lazy(() => import('@mui/icons-material/VolumeDownTwoTone')),
    VolumeMute: React.lazy(() => import('@mui/icons-material/VolumeMute')),
    VolumeMuteOutlined: React.lazy(() => import('@mui/icons-material/VolumeMuteOutlined')),
    VolumeMuteRounded: React.lazy(() => import('@mui/icons-material/VolumeMuteRounded')),
    VolumeMuteSharp: React.lazy(() => import('@mui/icons-material/VolumeMuteSharp')),
    VolumeMuteTwoTone: React.lazy(() => import('@mui/icons-material/VolumeMuteTwoTone')),
    VolumeOff: React.lazy(() => import('@mui/icons-material/VolumeOff')),
    VolumeOffOutlined: React.lazy(() => import('@mui/icons-material/VolumeOffOutlined')),
    VolumeOffRounded: React.lazy(() => import('@mui/icons-material/VolumeOffRounded')),
    VolumeOffSharp: React.lazy(() => import('@mui/icons-material/VolumeOffSharp')),
    VolumeOffTwoTone: React.lazy(() => import('@mui/icons-material/VolumeOffTwoTone')),
    VolumeUp: React.lazy(() => import('@mui/icons-material/VolumeUp')),
    VolumeUpOutlined: React.lazy(() => import('@mui/icons-material/VolumeUpOutlined')),
    VolumeUpRounded: React.lazy(() => import('@mui/icons-material/VolumeUpRounded')),
    VolumeUpSharp: React.lazy(() => import('@mui/icons-material/VolumeUpSharp')),
    VolumeUpTwoTone: React.lazy(() => import('@mui/icons-material/VolumeUpTwoTone')),
    VolunteerActivism: React.lazy(() => import('@mui/icons-material/VolunteerActivism')),
    VolunteerActivismOutlined: React.lazy(() => import('@mui/icons-material/VolunteerActivismOutlined')),
    VolunteerActivismRounded: React.lazy(() => import('@mui/icons-material/VolunteerActivismRounded')),
    VolunteerActivismSharp: React.lazy(() => import('@mui/icons-material/VolunteerActivismSharp')),
    VolunteerActivismTwoTone: React.lazy(() => import('@mui/icons-material/VolunteerActivismTwoTone')),
    VpnKey: React.lazy(() => import('@mui/icons-material/VpnKey')),
    VpnKeyOff: React.lazy(() => import('@mui/icons-material/VpnKeyOff')),
    VpnKeyOffOutlined: React.lazy(() => import('@mui/icons-material/VpnKeyOffOutlined')),
    VpnKeyOffRounded: React.lazy(() => import('@mui/icons-material/VpnKeyOffRounded')),
    VpnKeyOffSharp: React.lazy(() => import('@mui/icons-material/VpnKeyOffSharp')),
    VpnKeyOffTwoTone: React.lazy(() => import('@mui/icons-material/VpnKeyOffTwoTone')),
    VpnKeyOutlined: React.lazy(() => import('@mui/icons-material/VpnKeyOutlined')),
    VpnKeyRounded: React.lazy(() => import('@mui/icons-material/VpnKeyRounded')),
    VpnKeySharp: React.lazy(() => import('@mui/icons-material/VpnKeySharp')),
    VpnKeyTwoTone: React.lazy(() => import('@mui/icons-material/VpnKeyTwoTone')),
    VpnLock: React.lazy(() => import('@mui/icons-material/VpnLock')),
    VpnLockOutlined: React.lazy(() => import('@mui/icons-material/VpnLockOutlined')),
    VpnLockRounded: React.lazy(() => import('@mui/icons-material/VpnLockRounded')),
    VpnLockSharp: React.lazy(() => import('@mui/icons-material/VpnLockSharp')),
    VpnLockTwoTone: React.lazy(() => import('@mui/icons-material/VpnLockTwoTone')),
    Vrpano: React.lazy(() => import('@mui/icons-material/Vrpano')),
    VrpanoOutlined: React.lazy(() => import('@mui/icons-material/VrpanoOutlined')),
    VrpanoRounded: React.lazy(() => import('@mui/icons-material/VrpanoRounded')),
    VrpanoSharp: React.lazy(() => import('@mui/icons-material/VrpanoSharp')),
    VrpanoTwoTone: React.lazy(() => import('@mui/icons-material/VrpanoTwoTone')),
    Wallet: React.lazy(() => import('@mui/icons-material/Wallet')),
    WalletOutlined: React.lazy(() => import('@mui/icons-material/WalletOutlined')),
    WalletRounded: React.lazy(() => import('@mui/icons-material/WalletRounded')),
    WalletSharp: React.lazy(() => import('@mui/icons-material/WalletSharp')),
    WalletTwoTone: React.lazy(() => import('@mui/icons-material/WalletTwoTone')),
    Wallpaper: React.lazy(() => import('@mui/icons-material/Wallpaper')),
    WallpaperOutlined: React.lazy(() => import('@mui/icons-material/WallpaperOutlined')),
    WallpaperRounded: React.lazy(() => import('@mui/icons-material/WallpaperRounded')),
    WallpaperSharp: React.lazy(() => import('@mui/icons-material/WallpaperSharp')),
    WallpaperTwoTone: React.lazy(() => import('@mui/icons-material/WallpaperTwoTone')),
    Warehouse: React.lazy(() => import('@mui/icons-material/Warehouse')),
    WarehouseOutlined: React.lazy(() => import('@mui/icons-material/WarehouseOutlined')),
    WarehouseRounded: React.lazy(() => import('@mui/icons-material/WarehouseRounded')),
    WarehouseSharp: React.lazy(() => import('@mui/icons-material/WarehouseSharp')),
    WarehouseTwoTone: React.lazy(() => import('@mui/icons-material/WarehouseTwoTone')),
    WarningAmber: React.lazy(() => import('@mui/icons-material/WarningAmber')),
    WarningAmberOutlined: React.lazy(() => import('@mui/icons-material/WarningAmberOutlined')),
    WarningAmberRounded: React.lazy(() => import('@mui/icons-material/WarningAmberRounded')),
    WarningAmberSharp: React.lazy(() => import('@mui/icons-material/WarningAmberSharp')),
    WarningAmberTwoTone: React.lazy(() => import('@mui/icons-material/WarningAmberTwoTone')),
    Warning: React.lazy(() => import('@mui/icons-material/Warning')),
    WarningOutlined: React.lazy(() => import('@mui/icons-material/WarningOutlined')),
    WarningRounded: React.lazy(() => import('@mui/icons-material/WarningRounded')),
    WarningSharp: React.lazy(() => import('@mui/icons-material/WarningSharp')),
    WarningTwoTone: React.lazy(() => import('@mui/icons-material/WarningTwoTone')),
    Wash: React.lazy(() => import('@mui/icons-material/Wash')),
    WashOutlined: React.lazy(() => import('@mui/icons-material/WashOutlined')),
    WashRounded: React.lazy(() => import('@mui/icons-material/WashRounded')),
    WashSharp: React.lazy(() => import('@mui/icons-material/WashSharp')),
    WashTwoTone: React.lazy(() => import('@mui/icons-material/WashTwoTone')),
    Watch: React.lazy(() => import('@mui/icons-material/Watch')),
    WatchLater: React.lazy(() => import('@mui/icons-material/WatchLater')),
    WatchLaterOutlined: React.lazy(() => import('@mui/icons-material/WatchLaterOutlined')),
    WatchLaterRounded: React.lazy(() => import('@mui/icons-material/WatchLaterRounded')),
    WatchLaterSharp: React.lazy(() => import('@mui/icons-material/WatchLaterSharp')),
    WatchLaterTwoTone: React.lazy(() => import('@mui/icons-material/WatchLaterTwoTone')),
    WatchOff: React.lazy(() => import('@mui/icons-material/WatchOff')),
    WatchOffOutlined: React.lazy(() => import('@mui/icons-material/WatchOffOutlined')),
    WatchOffRounded: React.lazy(() => import('@mui/icons-material/WatchOffRounded')),
    WatchOffSharp: React.lazy(() => import('@mui/icons-material/WatchOffSharp')),
    WatchOffTwoTone: React.lazy(() => import('@mui/icons-material/WatchOffTwoTone')),
    WatchOutlined: React.lazy(() => import('@mui/icons-material/WatchOutlined')),
    WatchRounded: React.lazy(() => import('@mui/icons-material/WatchRounded')),
    WatchSharp: React.lazy(() => import('@mui/icons-material/WatchSharp')),
    WatchTwoTone: React.lazy(() => import('@mui/icons-material/WatchTwoTone')),
    WaterDamage: React.lazy(() => import('@mui/icons-material/WaterDamage')),
    WaterDamageOutlined: React.lazy(() => import('@mui/icons-material/WaterDamageOutlined')),
    WaterDamageRounded: React.lazy(() => import('@mui/icons-material/WaterDamageRounded')),
    WaterDamageSharp: React.lazy(() => import('@mui/icons-material/WaterDamageSharp')),
    WaterDamageTwoTone: React.lazy(() => import('@mui/icons-material/WaterDamageTwoTone')),
    Water: React.lazy(() => import('@mui/icons-material/Water')),
    WaterfallChart: React.lazy(() => import('@mui/icons-material/WaterfallChart')),
    WaterfallChartOutlined: React.lazy(() => import('@mui/icons-material/WaterfallChartOutlined')),
    WaterfallChartRounded: React.lazy(() => import('@mui/icons-material/WaterfallChartRounded')),
    WaterfallChartSharp: React.lazy(() => import('@mui/icons-material/WaterfallChartSharp')),
    WaterfallChartTwoTone: React.lazy(() => import('@mui/icons-material/WaterfallChartTwoTone')),
    WaterOutlined: React.lazy(() => import('@mui/icons-material/WaterOutlined')),
    WaterRounded: React.lazy(() => import('@mui/icons-material/WaterRounded')),
    WaterSharp: React.lazy(() => import('@mui/icons-material/WaterSharp')),
    WaterTwoTone: React.lazy(() => import('@mui/icons-material/WaterTwoTone')),
    Waves: React.lazy(() => import('@mui/icons-material/Waves')),
    WavesOutlined: React.lazy(() => import('@mui/icons-material/WavesOutlined')),
    WavesRounded: React.lazy(() => import('@mui/icons-material/WavesRounded')),
    WavesSharp: React.lazy(() => import('@mui/icons-material/WavesSharp')),
    WavesTwoTone: React.lazy(() => import('@mui/icons-material/WavesTwoTone')),
    WbAuto: React.lazy(() => import('@mui/icons-material/WbAuto')),
    WbAutoOutlined: React.lazy(() => import('@mui/icons-material/WbAutoOutlined')),
    WbAutoRounded: React.lazy(() => import('@mui/icons-material/WbAutoRounded')),
    WbAutoSharp: React.lazy(() => import('@mui/icons-material/WbAutoSharp')),
    WbAutoTwoTone: React.lazy(() => import('@mui/icons-material/WbAutoTwoTone')),
    WbCloudy: React.lazy(() => import('@mui/icons-material/WbCloudy')),
    WbCloudyOutlined: React.lazy(() => import('@mui/icons-material/WbCloudyOutlined')),
    WbCloudyRounded: React.lazy(() => import('@mui/icons-material/WbCloudyRounded')),
    WbCloudySharp: React.lazy(() => import('@mui/icons-material/WbCloudySharp')),
    WbCloudyTwoTone: React.lazy(() => import('@mui/icons-material/WbCloudyTwoTone')),
    WbIncandescent: React.lazy(() => import('@mui/icons-material/WbIncandescent')),
    WbIncandescentOutlined: React.lazy(() => import('@mui/icons-material/WbIncandescentOutlined')),
    WbIncandescentRounded: React.lazy(() => import('@mui/icons-material/WbIncandescentRounded')),
    WbIncandescentSharp: React.lazy(() => import('@mui/icons-material/WbIncandescentSharp')),
    WbIncandescentTwoTone: React.lazy(() => import('@mui/icons-material/WbIncandescentTwoTone')),
    WbIridescent: React.lazy(() => import('@mui/icons-material/WbIridescent')),
    WbIridescentOutlined: React.lazy(() => import('@mui/icons-material/WbIridescentOutlined')),
    WbIridescentRounded: React.lazy(() => import('@mui/icons-material/WbIridescentRounded')),
    WbIridescentSharp: React.lazy(() => import('@mui/icons-material/WbIridescentSharp')),
    WbIridescentTwoTone: React.lazy(() => import('@mui/icons-material/WbIridescentTwoTone')),
    WbShade: React.lazy(() => import('@mui/icons-material/WbShade')),
    WbShadeOutlined: React.lazy(() => import('@mui/icons-material/WbShadeOutlined')),
    WbShadeRounded: React.lazy(() => import('@mui/icons-material/WbShadeRounded')),
    WbShadeSharp: React.lazy(() => import('@mui/icons-material/WbShadeSharp')),
    WbShadeTwoTone: React.lazy(() => import('@mui/icons-material/WbShadeTwoTone')),
    WbSunny: React.lazy(() => import('@mui/icons-material/WbSunny')),
    WbSunnyOutlined: React.lazy(() => import('@mui/icons-material/WbSunnyOutlined')),
    WbSunnyRounded: React.lazy(() => import('@mui/icons-material/WbSunnyRounded')),
    WbSunnySharp: React.lazy(() => import('@mui/icons-material/WbSunnySharp')),
    WbSunnyTwoTone: React.lazy(() => import('@mui/icons-material/WbSunnyTwoTone')),
    WbTwilight: React.lazy(() => import('@mui/icons-material/WbTwilight')),
    WbTwilightOutlined: React.lazy(() => import('@mui/icons-material/WbTwilightOutlined')),
    WbTwilightRounded: React.lazy(() => import('@mui/icons-material/WbTwilightRounded')),
    WbTwilightSharp: React.lazy(() => import('@mui/icons-material/WbTwilightSharp')),
    WbTwilightTwoTone: React.lazy(() => import('@mui/icons-material/WbTwilightTwoTone')),
    Wc: React.lazy(() => import('@mui/icons-material/Wc')),
    WcOutlined: React.lazy(() => import('@mui/icons-material/WcOutlined')),
    WcRounded: React.lazy(() => import('@mui/icons-material/WcRounded')),
    WcSharp: React.lazy(() => import('@mui/icons-material/WcSharp')),
    WcTwoTone: React.lazy(() => import('@mui/icons-material/WcTwoTone')),
    WebAsset: React.lazy(() => import('@mui/icons-material/WebAsset')),
    WebAssetOff: React.lazy(() => import('@mui/icons-material/WebAssetOff')),
    WebAssetOffOutlined: React.lazy(() => import('@mui/icons-material/WebAssetOffOutlined')),
    WebAssetOffRounded: React.lazy(() => import('@mui/icons-material/WebAssetOffRounded')),
    WebAssetOffSharp: React.lazy(() => import('@mui/icons-material/WebAssetOffSharp')),
    WebAssetOffTwoTone: React.lazy(() => import('@mui/icons-material/WebAssetOffTwoTone')),
    WebAssetOutlined: React.lazy(() => import('@mui/icons-material/WebAssetOutlined')),
    WebAssetRounded: React.lazy(() => import('@mui/icons-material/WebAssetRounded')),
    WebAssetSharp: React.lazy(() => import('@mui/icons-material/WebAssetSharp')),
    WebAssetTwoTone: React.lazy(() => import('@mui/icons-material/WebAssetTwoTone')),
    Web: React.lazy(() => import('@mui/icons-material/Web')),
    Webhook: React.lazy(() => import('@mui/icons-material/Webhook')),
    WebhookOutlined: React.lazy(() => import('@mui/icons-material/WebhookOutlined')),
    WebhookRounded: React.lazy(() => import('@mui/icons-material/WebhookRounded')),
    WebhookSharp: React.lazy(() => import('@mui/icons-material/WebhookSharp')),
    WebhookTwoTone: React.lazy(() => import('@mui/icons-material/WebhookTwoTone')),
    WebOutlined: React.lazy(() => import('@mui/icons-material/WebOutlined')),
    WebRounded: React.lazy(() => import('@mui/icons-material/WebRounded')),
    WebSharp: React.lazy(() => import('@mui/icons-material/WebSharp')),
    WebStories: React.lazy(() => import('@mui/icons-material/WebStories')),
    WebStoriesOutlined: React.lazy(() => import('@mui/icons-material/WebStoriesOutlined')),
    WebStoriesRounded: React.lazy(() => import('@mui/icons-material/WebStoriesRounded')),
    WebStoriesSharp: React.lazy(() => import('@mui/icons-material/WebStoriesSharp')),
    WebStoriesTwoTone: React.lazy(() => import('@mui/icons-material/WebStoriesTwoTone')),
    WebTwoTone: React.lazy(() => import('@mui/icons-material/WebTwoTone')),
    Weekend: React.lazy(() => import('@mui/icons-material/Weekend')),
    WeekendOutlined: React.lazy(() => import('@mui/icons-material/WeekendOutlined')),
    WeekendRounded: React.lazy(() => import('@mui/icons-material/WeekendRounded')),
    WeekendSharp: React.lazy(() => import('@mui/icons-material/WeekendSharp')),
    WeekendTwoTone: React.lazy(() => import('@mui/icons-material/WeekendTwoTone')),
    West: React.lazy(() => import('@mui/icons-material/West')),
    WestOutlined: React.lazy(() => import('@mui/icons-material/WestOutlined')),
    WestRounded: React.lazy(() => import('@mui/icons-material/WestRounded')),
    WestSharp: React.lazy(() => import('@mui/icons-material/WestSharp')),
    WestTwoTone: React.lazy(() => import('@mui/icons-material/WestTwoTone')),
    WhatsApp: React.lazy(() => import('@mui/icons-material/WhatsApp')),
    Whatshot: React.lazy(() => import('@mui/icons-material/Whatshot')),
    WhatshotOutlined: React.lazy(() => import('@mui/icons-material/WhatshotOutlined')),
    WhatshotRounded: React.lazy(() => import('@mui/icons-material/WhatshotRounded')),
    WhatshotSharp: React.lazy(() => import('@mui/icons-material/WhatshotSharp')),
    WhatshotTwoTone: React.lazy(() => import('@mui/icons-material/WhatshotTwoTone')),
    WheelchairPickup: React.lazy(() => import('@mui/icons-material/WheelchairPickup')),
    WheelchairPickupOutlined: React.lazy(() => import('@mui/icons-material/WheelchairPickupOutlined')),
    WheelchairPickupRounded: React.lazy(() => import('@mui/icons-material/WheelchairPickupRounded')),
    WheelchairPickupSharp: React.lazy(() => import('@mui/icons-material/WheelchairPickupSharp')),
    WheelchairPickupTwoTone: React.lazy(() => import('@mui/icons-material/WheelchairPickupTwoTone')),
    WhereToVote: React.lazy(() => import('@mui/icons-material/WhereToVote')),
    WhereToVoteOutlined: React.lazy(() => import('@mui/icons-material/WhereToVoteOutlined')),
    WhereToVoteRounded: React.lazy(() => import('@mui/icons-material/WhereToVoteRounded')),
    WhereToVoteSharp: React.lazy(() => import('@mui/icons-material/WhereToVoteSharp')),
    WhereToVoteTwoTone: React.lazy(() => import('@mui/icons-material/WhereToVoteTwoTone')),
    Widgets: React.lazy(() => import('@mui/icons-material/Widgets')),
    WidgetsOutlined: React.lazy(() => import('@mui/icons-material/WidgetsOutlined')),
    WidgetsRounded: React.lazy(() => import('@mui/icons-material/WidgetsRounded')),
    WidgetsSharp: React.lazy(() => import('@mui/icons-material/WidgetsSharp')),
    WidgetsTwoTone: React.lazy(() => import('@mui/icons-material/WidgetsTwoTone')),
    WidthFull: React.lazy(() => import('@mui/icons-material/WidthFull')),
    WidthFullOutlined: React.lazy(() => import('@mui/icons-material/WidthFullOutlined')),
    WidthFullRounded: React.lazy(() => import('@mui/icons-material/WidthFullRounded')),
    WidthFullSharp: React.lazy(() => import('@mui/icons-material/WidthFullSharp')),
    WidthFullTwoTone: React.lazy(() => import('@mui/icons-material/WidthFullTwoTone')),
    WidthNormal: React.lazy(() => import('@mui/icons-material/WidthNormal')),
    WidthNormalOutlined: React.lazy(() => import('@mui/icons-material/WidthNormalOutlined')),
    WidthNormalRounded: React.lazy(() => import('@mui/icons-material/WidthNormalRounded')),
    WidthNormalSharp: React.lazy(() => import('@mui/icons-material/WidthNormalSharp')),
    WidthNormalTwoTone: React.lazy(() => import('@mui/icons-material/WidthNormalTwoTone')),
    WidthWide: React.lazy(() => import('@mui/icons-material/WidthWide')),
    WidthWideOutlined: React.lazy(() => import('@mui/icons-material/WidthWideOutlined')),
    WidthWideRounded: React.lazy(() => import('@mui/icons-material/WidthWideRounded')),
    WidthWideSharp: React.lazy(() => import('@mui/icons-material/WidthWideSharp')),
    WidthWideTwoTone: React.lazy(() => import('@mui/icons-material/WidthWideTwoTone')),
    Wifi1Bar: React.lazy(() => import('@mui/icons-material/Wifi1Bar')),
    Wifi1BarOutlined: React.lazy(() => import('@mui/icons-material/Wifi1BarOutlined')),
    Wifi1BarRounded: React.lazy(() => import('@mui/icons-material/Wifi1BarRounded')),
    Wifi1BarSharp: React.lazy(() => import('@mui/icons-material/Wifi1BarSharp')),
    Wifi1BarTwoTone: React.lazy(() => import('@mui/icons-material/Wifi1BarTwoTone')),
    Wifi2Bar: React.lazy(() => import('@mui/icons-material/Wifi2Bar')),
    Wifi2BarOutlined: React.lazy(() => import('@mui/icons-material/Wifi2BarOutlined')),
    Wifi2BarRounded: React.lazy(() => import('@mui/icons-material/Wifi2BarRounded')),
    Wifi2BarSharp: React.lazy(() => import('@mui/icons-material/Wifi2BarSharp')),
    Wifi2BarTwoTone: React.lazy(() => import('@mui/icons-material/Wifi2BarTwoTone')),
    WifiCalling3: React.lazy(() => import('@mui/icons-material/WifiCalling3')),
    WifiCalling3Outlined: React.lazy(() => import('@mui/icons-material/WifiCalling3Outlined')),
    WifiCalling3Rounded: React.lazy(() => import('@mui/icons-material/WifiCalling3Rounded')),
    WifiCalling3Sharp: React.lazy(() => import('@mui/icons-material/WifiCalling3Sharp')),
    WifiCalling3TwoTone: React.lazy(() => import('@mui/icons-material/WifiCalling3TwoTone')),
    WifiCalling: React.lazy(() => import('@mui/icons-material/WifiCalling')),
    WifiCallingOutlined: React.lazy(() => import('@mui/icons-material/WifiCallingOutlined')),
    WifiCallingRounded: React.lazy(() => import('@mui/icons-material/WifiCallingRounded')),
    WifiCallingSharp: React.lazy(() => import('@mui/icons-material/WifiCallingSharp')),
    WifiCallingTwoTone: React.lazy(() => import('@mui/icons-material/WifiCallingTwoTone')),
    WifiChannel: React.lazy(() => import('@mui/icons-material/WifiChannel')),
    WifiChannelOutlined: React.lazy(() => import('@mui/icons-material/WifiChannelOutlined')),
    WifiChannelRounded: React.lazy(() => import('@mui/icons-material/WifiChannelRounded')),
    WifiChannelSharp: React.lazy(() => import('@mui/icons-material/WifiChannelSharp')),
    WifiChannelTwoTone: React.lazy(() => import('@mui/icons-material/WifiChannelTwoTone')),
    Wifi: React.lazy(() => import('@mui/icons-material/Wifi')),
    WifiFind: React.lazy(() => import('@mui/icons-material/WifiFind')),
    WifiFindOutlined: React.lazy(() => import('@mui/icons-material/WifiFindOutlined')),
    WifiFindRounded: React.lazy(() => import('@mui/icons-material/WifiFindRounded')),
    WifiFindSharp: React.lazy(() => import('@mui/icons-material/WifiFindSharp')),
    WifiFindTwoTone: React.lazy(() => import('@mui/icons-material/WifiFindTwoTone')),
    WifiLock: React.lazy(() => import('@mui/icons-material/WifiLock')),
    WifiLockOutlined: React.lazy(() => import('@mui/icons-material/WifiLockOutlined')),
    WifiLockRounded: React.lazy(() => import('@mui/icons-material/WifiLockRounded')),
    WifiLockSharp: React.lazy(() => import('@mui/icons-material/WifiLockSharp')),
    WifiLockTwoTone: React.lazy(() => import('@mui/icons-material/WifiLockTwoTone')),
    WifiOff: React.lazy(() => import('@mui/icons-material/WifiOff')),
    WifiOffOutlined: React.lazy(() => import('@mui/icons-material/WifiOffOutlined')),
    WifiOffRounded: React.lazy(() => import('@mui/icons-material/WifiOffRounded')),
    WifiOffSharp: React.lazy(() => import('@mui/icons-material/WifiOffSharp')),
    WifiOffTwoTone: React.lazy(() => import('@mui/icons-material/WifiOffTwoTone')),
    WifiOutlined: React.lazy(() => import('@mui/icons-material/WifiOutlined')),
    WifiPassword: React.lazy(() => import('@mui/icons-material/WifiPassword')),
    WifiPasswordOutlined: React.lazy(() => import('@mui/icons-material/WifiPasswordOutlined')),
    WifiPasswordRounded: React.lazy(() => import('@mui/icons-material/WifiPasswordRounded')),
    WifiPasswordSharp: React.lazy(() => import('@mui/icons-material/WifiPasswordSharp')),
    WifiPasswordTwoTone: React.lazy(() => import('@mui/icons-material/WifiPasswordTwoTone')),
    WifiProtectedSetup: React.lazy(() => import('@mui/icons-material/WifiProtectedSetup')),
    WifiProtectedSetupOutlined: React.lazy(() => import('@mui/icons-material/WifiProtectedSetupOutlined')),
    WifiProtectedSetupRounded: React.lazy(() => import('@mui/icons-material/WifiProtectedSetupRounded')),
    WifiProtectedSetupSharp: React.lazy(() => import('@mui/icons-material/WifiProtectedSetupSharp')),
    WifiProtectedSetupTwoTone: React.lazy(() => import('@mui/icons-material/WifiProtectedSetupTwoTone')),
    WifiRounded: React.lazy(() => import('@mui/icons-material/WifiRounded')),
    WifiSharp: React.lazy(() => import('@mui/icons-material/WifiSharp')),
    WifiTethering: React.lazy(() => import('@mui/icons-material/WifiTethering')),
    WifiTetheringError: React.lazy(() => import('@mui/icons-material/WifiTetheringError')),
    WifiTetheringErrorOutlined: React.lazy(() => import('@mui/icons-material/WifiTetheringErrorOutlined')),
    WifiTetheringErrorRounded: React.lazy(() => import('@mui/icons-material/WifiTetheringErrorRounded')),
    WifiTetheringErrorRoundedOutlined: React.lazy(() => import('@mui/icons-material/WifiTetheringErrorRoundedOutlined')),
    WifiTetheringErrorRoundedRounded: React.lazy(() => import('@mui/icons-material/WifiTetheringErrorRoundedRounded')),
    WifiTetheringErrorRoundedSharp: React.lazy(() => import('@mui/icons-material/WifiTetheringErrorRoundedSharp')),
    WifiTetheringErrorRoundedTwoTone: React.lazy(() => import('@mui/icons-material/WifiTetheringErrorRoundedTwoTone')),
    WifiTetheringErrorSharp: React.lazy(() => import('@mui/icons-material/WifiTetheringErrorSharp')),
    WifiTetheringErrorTwoTone: React.lazy(() => import('@mui/icons-material/WifiTetheringErrorTwoTone')),
    WifiTetheringOff: React.lazy(() => import('@mui/icons-material/WifiTetheringOff')),
    WifiTetheringOffOutlined: React.lazy(() => import('@mui/icons-material/WifiTetheringOffOutlined')),
    WifiTetheringOffRounded: React.lazy(() => import('@mui/icons-material/WifiTetheringOffRounded')),
    WifiTetheringOffSharp: React.lazy(() => import('@mui/icons-material/WifiTetheringOffSharp')),
    WifiTetheringOffTwoTone: React.lazy(() => import('@mui/icons-material/WifiTetheringOffTwoTone')),
    WifiTetheringOutlined: React.lazy(() => import('@mui/icons-material/WifiTetheringOutlined')),
    WifiTetheringRounded: React.lazy(() => import('@mui/icons-material/WifiTetheringRounded')),
    WifiTetheringSharp: React.lazy(() => import('@mui/icons-material/WifiTetheringSharp')),
    WifiTetheringTwoTone: React.lazy(() => import('@mui/icons-material/WifiTetheringTwoTone')),
    WifiTwoTone: React.lazy(() => import('@mui/icons-material/WifiTwoTone')),
    Window: React.lazy(() => import('@mui/icons-material/Window')),
    WindowOutlined: React.lazy(() => import('@mui/icons-material/WindowOutlined')),
    WindowRounded: React.lazy(() => import('@mui/icons-material/WindowRounded')),
    WindowSharp: React.lazy(() => import('@mui/icons-material/WindowSharp')),
    WindowTwoTone: React.lazy(() => import('@mui/icons-material/WindowTwoTone')),
    WindPower: React.lazy(() => import('@mui/icons-material/WindPower')),
    WindPowerOutlined: React.lazy(() => import('@mui/icons-material/WindPowerOutlined')),
    WindPowerRounded: React.lazy(() => import('@mui/icons-material/WindPowerRounded')),
    WindPowerSharp: React.lazy(() => import('@mui/icons-material/WindPowerSharp')),
    WindPowerTwoTone: React.lazy(() => import('@mui/icons-material/WindPowerTwoTone')),
    WineBar: React.lazy(() => import('@mui/icons-material/WineBar')),
    WineBarOutlined: React.lazy(() => import('@mui/icons-material/WineBarOutlined')),
    WineBarRounded: React.lazy(() => import('@mui/icons-material/WineBarRounded')),
    WineBarSharp: React.lazy(() => import('@mui/icons-material/WineBarSharp')),
    WineBarTwoTone: React.lazy(() => import('@mui/icons-material/WineBarTwoTone')),
    Woman2: React.lazy(() => import('@mui/icons-material/Woman2')),
    Woman2Outlined: React.lazy(() => import('@mui/icons-material/Woman2Outlined')),
    Woman2Rounded: React.lazy(() => import('@mui/icons-material/Woman2Rounded')),
    Woman2Sharp: React.lazy(() => import('@mui/icons-material/Woman2Sharp')),
    Woman2TwoTone: React.lazy(() => import('@mui/icons-material/Woman2TwoTone')),
    Woman: React.lazy(() => import('@mui/icons-material/Woman')),
    WomanOutlined: React.lazy(() => import('@mui/icons-material/WomanOutlined')),
    WomanRounded: React.lazy(() => import('@mui/icons-material/WomanRounded')),
    WomanSharp: React.lazy(() => import('@mui/icons-material/WomanSharp')),
    WomanTwoTone: React.lazy(() => import('@mui/icons-material/WomanTwoTone')),
    Work: React.lazy(() => import('@mui/icons-material/Work')),
    WorkHistory: React.lazy(() => import('@mui/icons-material/WorkHistory')),
    WorkHistoryOutlined: React.lazy(() => import('@mui/icons-material/WorkHistoryOutlined')),
    WorkHistoryRounded: React.lazy(() => import('@mui/icons-material/WorkHistoryRounded')),
    WorkHistorySharp: React.lazy(() => import('@mui/icons-material/WorkHistorySharp')),
    WorkHistoryTwoTone: React.lazy(() => import('@mui/icons-material/WorkHistoryTwoTone')),
    WorkOff: React.lazy(() => import('@mui/icons-material/WorkOff')),
    WorkOffOutlined: React.lazy(() => import('@mui/icons-material/WorkOffOutlined')),
    WorkOffRounded: React.lazy(() => import('@mui/icons-material/WorkOffRounded')),
    WorkOffSharp: React.lazy(() => import('@mui/icons-material/WorkOffSharp')),
    WorkOffTwoTone: React.lazy(() => import('@mui/icons-material/WorkOffTwoTone')),
    WorkOutlined: React.lazy(() => import('@mui/icons-material/WorkOutlined')),
    WorkOutline: React.lazy(() => import('@mui/icons-material/WorkOutline')),
    WorkOutlineOutlined: React.lazy(() => import('@mui/icons-material/WorkOutlineOutlined')),
    WorkOutlineRounded: React.lazy(() => import('@mui/icons-material/WorkOutlineRounded')),
    WorkOutlineSharp: React.lazy(() => import('@mui/icons-material/WorkOutlineSharp')),
    WorkOutlineTwoTone: React.lazy(() => import('@mui/icons-material/WorkOutlineTwoTone')),
    WorkRounded: React.lazy(() => import('@mui/icons-material/WorkRounded')),
    WorkSharp: React.lazy(() => import('@mui/icons-material/WorkSharp')),
    WorkspacePremium: React.lazy(() => import('@mui/icons-material/WorkspacePremium')),
    WorkspacePremiumOutlined: React.lazy(() => import('@mui/icons-material/WorkspacePremiumOutlined')),
    WorkspacePremiumRounded: React.lazy(() => import('@mui/icons-material/WorkspacePremiumRounded')),
    WorkspacePremiumSharp: React.lazy(() => import('@mui/icons-material/WorkspacePremiumSharp')),
    WorkspacePremiumTwoTone: React.lazy(() => import('@mui/icons-material/WorkspacePremiumTwoTone')),
    Workspaces: React.lazy(() => import('@mui/icons-material/Workspaces')),
    WorkspacesOutlined: React.lazy(() => import('@mui/icons-material/WorkspacesOutlined')),
    WorkspacesRounded: React.lazy(() => import('@mui/icons-material/WorkspacesRounded')),
    WorkspacesSharp: React.lazy(() => import('@mui/icons-material/WorkspacesSharp')),
    WorkspacesTwoTone: React.lazy(() => import('@mui/icons-material/WorkspacesTwoTone')),
    WorkTwoTone: React.lazy(() => import('@mui/icons-material/WorkTwoTone')),
    WrapText: React.lazy(() => import('@mui/icons-material/WrapText')),
    WrapTextOutlined: React.lazy(() => import('@mui/icons-material/WrapTextOutlined')),
    WrapTextRounded: React.lazy(() => import('@mui/icons-material/WrapTextRounded')),
    WrapTextSharp: React.lazy(() => import('@mui/icons-material/WrapTextSharp')),
    WrapTextTwoTone: React.lazy(() => import('@mui/icons-material/WrapTextTwoTone')),
    WrongLocation: React.lazy(() => import('@mui/icons-material/WrongLocation')),
    WrongLocationOutlined: React.lazy(() => import('@mui/icons-material/WrongLocationOutlined')),
    WrongLocationRounded: React.lazy(() => import('@mui/icons-material/WrongLocationRounded')),
    WrongLocationSharp: React.lazy(() => import('@mui/icons-material/WrongLocationSharp')),
    WrongLocationTwoTone: React.lazy(() => import('@mui/icons-material/WrongLocationTwoTone')),
    Wysiwyg: React.lazy(() => import('@mui/icons-material/Wysiwyg')),
    WysiwygOutlined: React.lazy(() => import('@mui/icons-material/WysiwygOutlined')),
    WysiwygRounded: React.lazy(() => import('@mui/icons-material/WysiwygRounded')),
    WysiwygSharp: React.lazy(() => import('@mui/icons-material/WysiwygSharp')),
    WysiwygTwoTone: React.lazy(() => import('@mui/icons-material/WysiwygTwoTone')),
    Yard: React.lazy(() => import('@mui/icons-material/Yard')),
    YardOutlined: React.lazy(() => import('@mui/icons-material/YardOutlined')),
    YardRounded: React.lazy(() => import('@mui/icons-material/YardRounded')),
    YardSharp: React.lazy(() => import('@mui/icons-material/YardSharp')),
    YardTwoTone: React.lazy(() => import('@mui/icons-material/YardTwoTone')),
    YouTube: React.lazy(() => import('@mui/icons-material/YouTube')),
    YoutubeSearchedFor: React.lazy(() => import('@mui/icons-material/YoutubeSearchedFor')),
    YoutubeSearchedForOutlined: React.lazy(() => import('@mui/icons-material/YoutubeSearchedForOutlined')),
    YoutubeSearchedForRounded: React.lazy(() => import('@mui/icons-material/YoutubeSearchedForRounded')),
    YoutubeSearchedForSharp: React.lazy(() => import('@mui/icons-material/YoutubeSearchedForSharp')),
    YoutubeSearchedForTwoTone: React.lazy(() => import('@mui/icons-material/YoutubeSearchedForTwoTone')),
    ZoomIn: React.lazy(() => import('@mui/icons-material/ZoomIn')),
    ZoomInMap: React.lazy(() => import('@mui/icons-material/ZoomInMap')),
    ZoomInMapOutlined: React.lazy(() => import('@mui/icons-material/ZoomInMapOutlined')),
    ZoomInMapRounded: React.lazy(() => import('@mui/icons-material/ZoomInMapRounded')),
    ZoomInMapSharp: React.lazy(() => import('@mui/icons-material/ZoomInMapSharp')),
    ZoomInMapTwoTone: React.lazy(() => import('@mui/icons-material/ZoomInMapTwoTone')),
    ZoomInOutlined: React.lazy(() => import('@mui/icons-material/ZoomInOutlined')),
    ZoomInRounded: React.lazy(() => import('@mui/icons-material/ZoomInRounded')),
    ZoomInSharp: React.lazy(() => import('@mui/icons-material/ZoomInSharp')),
    ZoomInTwoTone: React.lazy(() => import('@mui/icons-material/ZoomInTwoTone')),
    ZoomOut: React.lazy(() => import('@mui/icons-material/ZoomOut')),
    ZoomOutMap: React.lazy(() => import('@mui/icons-material/ZoomOutMap')),
    ZoomOutMapOutlined: React.lazy(() => import('@mui/icons-material/ZoomOutMapOutlined')),
    ZoomOutMapRounded: React.lazy(() => import('@mui/icons-material/ZoomOutMapRounded')),
    ZoomOutMapSharp: React.lazy(() => import('@mui/icons-material/ZoomOutMapSharp')),
    ZoomOutMapTwoTone: React.lazy(() => import('@mui/icons-material/ZoomOutMapTwoTone')),
    ZoomOutOutlined: React.lazy(() => import('@mui/icons-material/ZoomOutOutlined')),
    ZoomOutRounded: React.lazy(() => import('@mui/icons-material/ZoomOutRounded')),
    ZoomOutSharp: React.lazy(() => import('@mui/icons-material/ZoomOutSharp')),
    ZoomOutTwoTone: React.lazy(() => import('@mui/icons-material/ZoomOutTwoTone'))
};