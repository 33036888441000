import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
    ClickAwayListener,
    IconButton,
    Stack,
    StackProps,
    SvgIconProps,
    TextField,
    Tooltip
} from "@mui/material";
import { Edit, PanToolOutlined } from "@mui/icons-material";
import { isArray } from "lodash";
import { LoadingBackDrop } from "../Common/LoadingBackdrop";
import { CartProductIconText } from "./CartProductIconText";
import { useShowError } from "../Utils/showError";
import { useCartProductUpdate } from "./network/cartProductUpdate";
import GetCookie from "../Common/Functions/GetCookie";
import { CartConstructionProductsTableItemProps } from "./CartConstructionProductsTableItem";
import { AppState } from "../../Reducers/Reducers";
import { ProviderContext } from "./utils/providerContext";
import { green } from "@mui/material/colors";

type Props = {
    type: CartConstructionProductsTableItemProps['type'],
    id: number | number[],
    isCustom: boolean,
    content: React.ReactNode,
    modifiedName: string,
    providerName: string,
    localization: {
        locale: number,
        name: string,
        description: string,
        cancel_text_condition: string
    }[],
    customLocalization?: {
        locale: number,
        name: string,
        description: string,
        cancel_text_condition: string | null
    }[],
    startIcon: React.FunctionComponent<SvgIconProps>,
    isProposition?: boolean,
    onClick?: StackProps['onClick']
}

export function CartProductCardName(props: Props): JSX.Element {
    const versionString = GetCookie("trip_id_version");
    const version = versionString ? parseInt(versionString) : -1;
    const { t, i18n } = useTranslation();
    const locale = useSelector((state: AppState) => {
        return state.user.locales.find((item) => {
            return item.language_code === i18n.language;
        })?.id ?? 1;
    });
    const user = useSelector((state: AppState) => state.user?.user);
    const [editName, setEditName] = useState(false);
    const [loading, setLoading] = useState(false);
    const [nameInput, setNameInput] = useState('');
    const showError = useShowError();
    const update = useCartProductUpdate({
        onTrigger() {
            setLoading(true);
        },
        onError(error) {
            console.error(error);
            showError(error);
        },
        onFinally() {
            setLoading(false);
        }
    });
    const providerContext = useContext(ProviderContext);
    const quotationCode = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;

    const onSave = async () => {
        const localization = props.localization.find((item) => {
            return item.locale === locale;
        });
        const name = localization?.name ?? props.providerName;
        if (name !== nameInput) {
            let description = props.localization.find((item) => {
                return item.locale === locale;
            })?.description ?? '';

            if (description.trim().length === 0) {
                description = props.customLocalization?.find((item) => {
                    return item.locale === locale;
                })?.description ?? '';
            }

            let cancelCondition = props.localization.find((item) => {
                return item.locale === locale;
            })?.cancel_text_condition ?? '';

            if (cancelCondition.trim().length === 0) {
                cancelCondition = props.customLocalization?.find((item) => {
                    return item.locale === locale;
                })?.cancel_text_condition ?? '';
            }

            if (isArray(props.id)) {
                await Promise.all(
                    props.id.map((id) => {
                        return update(
                            version,
                            {
                                id,
                                type: props.type,
                                isCustom: props.isCustom
                            },
                            {
                                localization: props.localization.filter((item) => {
                                    return item.locale !== locale;
                                }).concat([
                                    {
                                        locale,
                                        name: nameInput,
                                        description: description,
                                        cancel_text_condition: cancelCondition
                                    }
                                ])
                            }
                        );
                    })
                );
            } else {
                await update(
                    version,
                    {
                        id: props.id,
                        type: props.type,
                        isCustom: props.isCustom
                    },
                    {
                        localization: props.localization.filter((item) => {
                            return item.locale !== locale;
                        }).concat([
                            {
                                locale,
                                name: nameInput,
                                description: description,
                                cancel_text_condition: cancelCondition
                            }
                        ])
                    }
                );
            }
            setEditName(false);
        } else {
            setEditName(false);
        }
    };

    useEffect(() => {
        if (editName) {
            setNameInput(
                props.localization.find((item) => {
                    return item.locale === locale;
                })?.name ?? props.providerName
            );
        }
    }, [locale, editName, props.localization, props.providerName]);

    return (
        <>
            <CartProductIconText
                size="medium"
                fontWeight="bold"
                text={
                    !editName ?
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <div>
                                {props.content}
                            </div>
                            {
                                props.modifiedName !== props.providerName &&
                                <Tooltip
                                    title={
                                        t(
                                            'cart-material.cart-construction-product-provider-name',
                                            { name: props.providerName }
                                        )
                                    }
                                >
                                    <PanToolOutlined fontSize="inherit" />
                                </Tooltip>
                            }
                        </Stack> :
                        <ClickAwayListener mouseEvent="onMouseDown" onClickAway={onSave}>
                            <TextField
                                size="small"
                                value={nameInput}
                                sx={{
                                    '& .MuiInputBase-input': {
                                        padding: 0,
                                        fontWeight: 'bold'
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none'
                                    },
                                    "minWidth": nameInput.length * 10 + 'px'
                                }}
                                onChange={(event) => setNameInput(event.target.value)}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                        onSave();
                                    }
                                }}
                                autoFocus
                                fullWidth
                            />
                        </ClickAwayListener>
                }
                startIcon={props.startIcon}
                endContent={
                    !providerContext.module &&
                        !editName &&
                        (
                            quotationCode !== 'visiteurs' ||
                            user?.client_full?.type !== 2
                        ) ?
                        <IconButton
                            size="small"
                            onClick={(event) => {
                                event.stopPropagation();
                                event.nativeEvent.stopImmediatePropagation();
                                setEditName(true);
                            }}
                        >
                            <Edit fontSize="inherit" />
                        </IconButton> :
                        undefined
                }
                onClick={!editName ? props.onClick : undefined}
            />
            <LoadingBackDrop open={loading} />
        </>
    );
}
