import { Currency } from "../../../Reducers/objects/currency";

type Options = {
    amount: number,
    from: Currency,
    to: Currency
}

export function convertToCurrency(options: Options): number {
    const factor = options.to.usd_factor / options.from.usd_factor;
    return options.amount * factor;
}