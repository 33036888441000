import { useEffect, useState } from "react";
import memoizee from "memoizee";
import axios from "axios";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";

type DynamicTag = {
    id: number,
    icon: string | null,
    display_on_quotation: boolean,
    display_on_minisite: boolean,
    name: string,
    localization: {
        id: number,
        locale: number,
        name: string,
    }[]
}

export function useDynamicTags(): DynamicTag[] {
    const [results, setResults] = useState<DynamicTag[]>([]);

    useEffect(() => {
        (async () => {
            try {
                const tags = await makeRequest();
                setResults(tags);
            } catch (error) {
                console.error(error);
            }
        })()
    }, []);

    return results;
}

const makeRequest = memoizee(
    async () => {
        const { headers } = CheckBeforeRequest();

        const response = await axios.get<{
            results: DynamicTag[]
        }>(
            `${API_HREF}client/${window.id_owner}/accommodation_tag/`,
            {
                headers,
                params: { limit: 50 }
            }
        );

        return response.data.results;
    },
    {
        promise: true
    }
);
