import React, { PropsWithChildren, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Button,
    ClickAwayListener,
    IconButton,
    Stack,
    styled
} from "@mui/material";
import { Edit, Save } from "@mui/icons-material";
import { ProviderContext } from "../CartMaterial/utils/providerContext";

type Props = {
    productId?: number,
    reference: string,
    open: boolean,
    onOpen: () => void,
    onClose: () => void,
    onSave: (reference: string) => void
}

export function ProviderBookingReferenceEdit(props: PropsWithChildren<Props>): React.ReactNode {
    const { t } = useTranslation();
    const [reference, setReference] = useState('');
    const providerContext = useContext(ProviderContext);

    const onSave = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | MouseEvent | TouchEvent) => {
        event.stopPropagation();
        props.onSave(reference);
        props.onClose();
    };

    const onEdit = () => {
        props.onOpen();
        setReference(props.reference);
    };

    if (providerContext.module !== 'booking') {
        return props.children;
    }

    return (
        <Stack direction="row" alignItems="center" spacing={1}>
            {
                !props.open &&
                <>
                    {props.children}
                    <Button
                        size="small"
                        variant="outlined"
                        endIcon={
                            <Edit sx={{ fontSize: '0.75em' }} />
                        }
                        onClick={onEdit}
                        sx={{
                            fontSize: '0.75em',
                            textTransform: 'none'
                        }}
                    >
                        {t('cart-material.booking-number')}
                    </Button>
                </>
            }
            {
                props.open &&
                <>
                    <ClickAwayListener
                        onClickAway={onSave}
                        mouseEvent="onMouseDown"
                    >
                        <BookingReferenceInput
                            value={reference}
                            onChange={(event) => setReference(event.target.value)}
                        />
                    </ClickAwayListener>
                    <IconButton
                        size="small"
                        onMouseDown={onSave}
                        sx={{ fontSize: 16 }}
                    >
                        <Save fontSize="inherit" />
                    </IconButton>
                </>
            }
        </Stack>
    );
}

const BookingReferenceInput = styled('input')((props) => ({
    padding: 0,
    paddingLeft: props.theme.spacing(1),
    paddingRight: props.theme.spacing(1),
    width: '25%',
    border: `1px solid ${props.theme.palette.primary.main}`,
    fontSize: '0.75rem',
    borderRadius: 2,
    minWidth: 145
}));