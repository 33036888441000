import { useMemo } from "react";
import { useSelector } from "react-redux";
import { extractPackages } from "./extractPackages";
import { useCartProducts } from "../../Itinerary/network/cartProducts";
import { useFilteredCartProducts } from "../../Itinerary/network/filteredCartProducts";
import { ManualProduct } from "../../../Reducers/objects/manualProduct";
import { AppState } from "../../../Reducers/Reducers";

type Options = {
    returnAll?: boolean
}

type Cart = Omit<
    ReturnType<typeof useCartProducts> & { manualProducts: ManualProduct[] },
    'trains' | 'ferries' | 'cruises' | 'insurances'
>

export function usePackagedProducts(options?: Options): {
    [key: string]: Cart
} {
    const trip = useSelector((state: AppState) => state.trip.data_trip);
    const filteredCart = useFilteredCartProducts();
    const cart = useCartProducts();
    return useMemo((): { [version: number]: typeof filteredCart } => {
        return extractPackages({
            trip,
            cart: options?.returnAll ?
                {
                    ...cart,
                    manualProducts: cart.cruises.map((item) => {
                        return item.cruise;
                    }).concat(
                        cart.ferries.map((item) => {
                            return item.ferry;
                        })
                    ).concat(
                        cart.trains.map((item) => {
                            return item.train;
                        })
                    )
                } :
                filteredCart
        });
    }, [
        options?.returnAll,
        options?.returnAll ? cart : filteredCart
    ]);
}
