import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { isNumber } from "lodash";
import { ManualProduct } from "../../../Reducers/objects/manualProduct";
import { Flight } from "../../Itinerary/network/flight";
import { AccommodationCart } from "../../Itinerary/objects/accommodationCart";
import { CarCart } from "../../Itinerary/objects/carCart";
import { PoiCart } from "../../Itinerary/objects/poiCart";
import { TransferCart } from "../../Itinerary/objects/transferCart";
import { AssistanceCart } from "../../Itinerary/objects/assistanceCart";
import { AppState } from "../../../Reducers/Reducers";

type Options = {
    type: 'car',
    product: CarCart
} | {
    type: 'accommodation',
    product: AccommodationCart
} | {
    type: 'transfer',
    product: TransferCart
} | {
    type: 'poi',
    product: PoiCart
} | {
    type: 'flight',
    product: Flight
} | {
    type: 'assistance',
    product: AssistanceCart
} | {
    type: 'manual-flight',
    product: ManualProduct
} | {
    type: 'manual',
    product: ManualProduct
}

type Callback = (options: Options) => string

export function useGetProductName(): Callback {
    const { t, i18n } = useTranslation();
    const locale = useSelector((state: AppState) => state.user.locales?.find((item) => {
        return item.language_code === i18n.language;
    })?.id ?? 1);
    const quotation_code = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;
    return useCallback((options) => {
        switch (options.type) {
            case 'accommodation': {
                const name = options.product.localization.find((item) => {
                    return item.locale === locale;
                })?.name ??
                    options.product.hotel[0]?.name ??
                    '';
                const variantName = options.product.variant &&
                    !isNumber(options.product.variant) ?
                    options.product.variant.localization?.find((item) => {
                        return item.locale === locale;
                    })?.title ?? options.product.variant.name :
                    null;

                return [
                    name,
                    variantName && `(${variantName})`
                ].filter((item) => !!item).join(' ');
            }
            case 'car': {
                return [
                    options.product.localization.find((item) => {
                        return item.locale === locale;
                    })?.name ?? options.product.name,
                    quotation_code === 'marcovasco' &&
                    `(${options.product.vehicle_code})`,
                    options.product.variant &&
                    ` (${options.product.variant.localization?.find((item) => item.locale === locale)?.title ?? options.product.variant.name})`
                ].filter((item) => item).join(' ');
            }
            case 'flight': {
                return '';
            }
            case 'poi': {
                const name = (() => {
                    const poiLocalization = options.product.localization.find((item) => {
                        return item.locale === locale;
                    });
                    if (poiLocalization) {
                        return poiLocalization.name;
                    }
                    const localization = options.product.custom_product?.localization?.find((item) => {
                        return item.locale === locale;
                    });
                    if (localization) {
                        return (localization?.title.length ?? 0) > 0 ?
                            localization?.title :
                            options.product.custom_product?.title;
                    }
                    return options.product.custom_product?.title;
                })();
                const variantName = (
                    // eslint-disable-next-line no-nested-ternary
                    options.product.cust_variant &&
                        !isNumber(options.product.cust_variant) ?
                        options.product.cust_variant.localization?.find((item) => {
                            return item.locale === locale;
                        })?.title ??
                        options.product.cust_variant.name :
                        (
                            options.product.variant && !isNumber(options.product.variant) ?
                                options.product.variant.localization?.find((item) => {
                                    return item.locale === locale;
                                })?.title ??
                                options.product.variant.name :
                                options.product.rate_name ??
                                null
                        )
                );
                return [
                    name,
                    variantName && `(${variantName})`
                ].filter((item) => !!item).join(' ');
            }
            case 'transfer': {
                const name = (() => {
                    const localization = options.product.localization.find((item) => {
                        return item.locale === locale;
                    });
                    if (localization) {
                        return localization.name;
                    } else if (
                        options.product.is_custom
                    ) {
                        return options.product.custom_product?.title;
                    }
                    return options.product.name;
                })();
                const variantName = options.product.variant &&
                    `(${options.product.variant.localization?.find((item) => item.locale === locale)?.title ?? options.product.variant.name})`;
                return [
                    name,
                    variantName
                ].filter((item) => !!item).join(' ');
            }
            case 'assistance': {
                return options.product.product_type === 8 &&
                    options.product.contract_name ?
                    options.product.contract_name :
                    t('cart-material.assistance')
            }
            case 'manual': {
                return options.product.localization?.find((item) => {
                    return item.locale === locale;
                })?.name ??
                    options.product.name ??
                    '';
            }
        }
        return '';
    }, [t, locale]);
}
