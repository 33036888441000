import React, { PropsWithChildren, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    ClickAwayListener,
    IconButton,
    Stack,
    styled,
    Tooltip
} from "@mui/material";
import { Edit, Lock, Pending, Save } from "@mui/icons-material";
import { ProviderContext } from "../CartMaterial/utils/providerContext";

type Props = {
    productId: number,
    price: string,
    open: boolean,
    bothEntitiesAccepted: boolean,
    providerWantsModification: boolean,
    onOpen: () => void,
    onClose: () => void
}

export function ProviderQuotationPriceEdit(props: PropsWithChildren<Props>): React.ReactNode {
    const { t } = useTranslation();
    const [priceInput, setPriceInput] = useState('');
    const providerContext = useContext(ProviderContext);

    const onSavePrice = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | MouseEvent | TouchEvent) => {
        event.stopPropagation();
        if (providerContext.module === 'quotation') {
            providerContext.onChangePrice(props.productId, parseFloat(priceInput));
        }
        props.onClose();
    };

    const onEditPrice = () => {
        props.onOpen();
        setPriceInput(props.price);
    };

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPriceInput(event.target.value.replace(/[^0-9.]+/g, ''));
    }

    const onUnlockPriceChange = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | MouseEvent | TouchEvent) => {
        event.stopPropagation();
        if (providerContext.module === 'quotation') {
            providerContext.onUnlockPriceChange(props.productId);
        }
    };

    if (providerContext.module !== 'quotation') {
        return props.children;
    }

    return (
        <Stack direction="row" alignItems="center" spacing={1}>
            {
                !props.open &&
                <>
                    {props.children}
                    {
                        !props.bothEntitiesAccepted &&
                        <IconButton
                            size="small"
                            onClick={onEditPrice}
                            sx={{ fontSize: 16 }}
                        >
                            <Edit fontSize="inherit" />
                        </IconButton>
                    }
                    {
                        props.bothEntitiesAccepted &&
                        !props.providerWantsModification &&
                        <Tooltip title={t('cart-material.provider-ask-for-modification')}>
                            <IconButton
                                size="small"
                                onClick={onUnlockPriceChange}
                                sx={{ fontSize: 16 }}
                            >
                                <Lock fontSize="inherit" />
                            </IconButton>
                        </Tooltip>
                    }
                    {
                        props.bothEntitiesAccepted &&
                        props.providerWantsModification &&
                        <Tooltip title={t('cart-material.provider-modification-request-sent')}>
                            <span>
                                <IconButton
                                    size="small"
                                    sx={{ fontSize: 16 }}
                                    disabled
                                >
                                    <Pending fontSize="inherit" />
                                </IconButton>
                            </span>
                        </Tooltip>
                    }
                </>
            }
            {
                props.open &&
                <>
                    <ClickAwayListener
                        onClickAway={onSavePrice}
                        mouseEvent="onMouseDown"
                    >
                        <PriceInput
                            value={priceInput}
                            onChange={onChange}
                        />
                    </ClickAwayListener>
                    <IconButton
                        size="small"
                        onMouseDown={onSavePrice}
                        sx={{ fontSize: 16 }}
                    >
                        <Save fontSize="inherit" />
                    </IconButton>
                </>
            }
        </Stack>
    );
}

const PriceInput = styled('input')((props) => ({
    fontSize: '0.75rem',
    paddingLeft: props.theme.spacing(1),
    paddingRight: props.theme.spacing(1),
    border: '1px solid rgba(0, 0, 0, 0.5)',
    borderRadius: props.theme.shape.borderRadius
}));
