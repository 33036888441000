// Dependencies
import { useTranslation } from 'react-i18next';
import React, { FC, ReactElement, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Core & Lab
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Grid from '@mui/material/Grid';
// Components
import PictureModalList from './pictureModalList';
import PictureModalUpload from './pictureModalUpload';
// Actions
import { updateCreateTab } from './redux/actions';
// Utils
import { PictureContext } from './utils/pictureContext';
import { ProviderContext } from '../../../CartMaterial/utils/providerContext';
// Types
import { AppState } from '../../../../Reducers/Reducers';

const PictureModalCreateTabs: FC = (): ReactElement => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const create_tab = useSelector((state: AppState) => state.picture.create_tab);
    const providerContext = useContext(ProviderContext);

    const onChangeTab = (event: React.SyntheticEvent, value: 0 | 1) => {
        dispatch(updateCreateTab(value));
    };

    return (
        <PictureContext.Consumer>
            {({ singleFile, tabs }) => (
                <Grid container spacing={ 2 }>
                    <Grid item xs={ 12 }>
                        <Tabs value={ create_tab } onChange={ onChangeTab }>
                            {
                                (!tabs || tabs.includes('uploader')) &&
                                <Tab
                                    label={
                                        t<string>(
                                            singleFile ? 'shared.upload-picture' : 'shared.upload-picture(s)'
                                        )
                                    }
                                    value={0}
                                />
                            }
                            {
                                !providerContext.module &&
                                (!tabs || tabs.includes('picker')) &&
                                <Tab value={1} label={ t<string>('shared.search-picture') }/>
                            }
                        </Tabs>
                    </Grid>
                    {
                        create_tab === 0 ? (
                            <Grid item xs={ 12 }>
                                <PictureModalUpload />
                            </Grid>
                        ) : (
                            <Grid item xs={ 12 }>
                                <PictureModalList/>
                            </Grid>
                        )
                    }
                </Grid>
            )}
        </PictureContext.Consumer>
    );
};

export default PictureModalCreateTabs;
