import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Stack } from "@mui/material";
import { ArrowRightAlt } from "@mui/icons-material";
import { flatten, groupBy, intersection } from "lodash";
import { getDestinationName } from "../Itinerary/utils/getDestinationName";
import { sortItinerary } from "../Itinerary/utils/sortItinerary";
import { useProductDays } from "../CartMaterial/utils/productDays";
import { Itinerary } from "../Itinerary/objects/itinerary";
import { AppState } from "../../Reducers/Reducers";

type Props = {
    productStepIds: (number | undefined)[],
    productsWithDays: {
        days: number[];
        content: React.ReactNode;
    }[],
    productsWithSteps: {
        step: Itinerary | undefined;
        content: React.ReactNode;
    }[]
}

export function ProviderProductsContent(props: Props): JSX.Element {
    const { t, i18n } = useTranslation();
    const trip = useSelector((state: AppState) => state.trip.all_data);
    const locale = useSelector((state: AppState) => state.user.locales?.find((item) => {
        return item.language_code === i18n.language;
    })?.id ?? 1);
    const itinerary = useSelector((state: AppState) => state.itinerary.itinerary_list);
    const steps = useMemo(() => {
        return itinerary.filter((step) => {
            return step.step_type === 'STEP';
        }).sort(sortItinerary);
    }, [itinerary]);
    const getDays = useProductDays();
    const days = Object.values(
        groupBy(
            flatten(
                steps.map((item) => {
                    const days = getDays(item.start_date, item.end_date);

                    return days.map((day, index) => {
                        return {
                            day,
                            date: window.moment.utc(item.start_date).add(index, 'day'),
                            destination: item.destination ?
                                getDestinationName(locale, item.destination).split(',')[0] :
                                null
                        };
                    });
                })
            ),
            (item) => item.day
        )
    ).map((item) => {
        return {
            ...item[0]!,
            destination: item.map((item) => item.destination).filter((item): item is NonNullable<typeof item> => {
                return !!item;
            })
        };
    });
    const rawDays = days.map((item) => item.day);
    return (
        <>
            {
                trip?.is_by_day_view &&
                days.filter((item) => {
                    const productDays = flatten(
                        props.productsWithDays.map((item) => {
                            return item.days;
                        })
                    );
                    return productDays.includes(item.day);
                }).map((item) => {
                    return (
                        <Stack key={item.date.toISOString()} spacing={1}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }} component="span">
                                {t('cart-material.cart-construction-day-colon', { day: item.day })}{' '}
                                {
                                    item.destination.reduce((prev, current, index, array) => {
                                        const result = [
                                            ...prev,
                                            current,
                                            <ArrowRightAlt fontSize="small" sx={{ marginLeft: 0.5, marginRight: 0.5 }} />
                                        ];

                                        if (index === array.length - 1) {
                                            result.pop();
                                        }

                                        return result;
                                    }, [] as React.ReactNode[])
                                }{' - '}
                                {
                                    item.date.format('LL')
                                }
                            </Box>
                            <Stack spacing={1}>
                                {
                                    props.productsWithDays.filter((product) => {
                                        return product.days.includes(item.day)
                                    }).map((item) => {
                                        return item.content;
                                    })
                                }
                            </Stack>
                        </Stack>
                    );
                })
            }
            {
                trip?.is_by_day_view &&
                props.productsWithDays.some((product) => {
                    return intersection(product.days, rawDays).length === 0;
                }) &&
                <Stack spacing={1}>
                    <span>{t('cart-material.cart-construction-other-products')}</span>
                    {
                        props.productsWithDays.filter((product) => {
                            return intersection(product.days, rawDays).length === 0;
                        }).map((item) => {
                            return item.content;
                        })
                    }
                </Stack>
            }
            {
                !trip?.is_by_day_view &&
                steps.filter((step) => {
                    return props.productStepIds.includes(step.id);
                }).map((step, index) => {
                    return (
                        <Stack key={step.id} spacing={1}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }} component="span">
                                {t('itinerary.step-no', { no: index + 1 })} :{' '}
                                {
                                    step.destination &&
                                    getDestinationName(locale, step.destination)
                                }{' - '}
                                {
                                    t(
                                        'cart-material.cart-construction-products-table-date',
                                        {
                                            from: window.moment.utc(step.start_date).format('LLL'),
                                            to: window.moment.utc(step.end_date).format('LLL')
                                        }
                                    )
                                }
                            </Box>
                            <Stack spacing={1}>
                                {
                                    props.productsWithSteps.filter((product) => {
                                        return product.step?.id === step.id;
                                    }).map((item) => {
                                        return item.content;
                                    })
                                }
                            </Stack>
                        </Stack>
                    );
                })
            }
            {
                !trip?.is_by_day_view &&
                props.productsWithSteps.some((product) => {
                    return !product.step;
                }) &&
                <Stack spacing={1}>
                    <span>{t('cart-material.cart-construction-other-products')}</span>
                    {
                        props.productsWithSteps.filter((product) => {
                            return !product.step;
                        }).map((item) => {
                            return item.content;
                        })
                    }
                </Stack>
            }
        </>
    )
}