//Dependencies
import clsx from 'clsx';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState, Fragment, useRef, useContext } from 'react';
//Actions
//Core
import { Stack, Typography } from '@mui/material';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { makeStyles } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';

//Icons
import Close from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import ReportProblem from '@material-ui/icons/ReportProblemOutlined';

//Common
import GetCookie from '../Common/Functions/GetCookie';
import CheckBeforeRequest from '../Common/CheckBeforeRequest';
//Components
import CartCarNote from './CartCarNote';
import CartCarManualBooking from './CartCarManualBooking';
import CartCarManualCancellationRules from './CartCarManualCancellationRules';
import GetPricesId from '../Cart/Functions/Margin/GetPricesId';
import {
    CartConstructionProductsTableItemProviderQuotationStatus
} from './CartConstructionProductsTableItemProviderQuotationStatus';
import {
    CartPackageItemChangeProviderQuotationStatusButtons
} from './CartPackageItemChangeProviderQuotationStatusButtons';
import { CartConstructionProviderQuotationStatusLayout } from './CartConstructionProviderQuotationStatusLayout';

//Actions
import { CarCart } from '../Itinerary/objects/carCart';
import { StatusBooking } from '../Itinerary/objects/statusBooking';
import { AppState } from '../../Reducers/Reducers';
import { ProductPrice } from '../Itinerary/objects/productPrice';
import { Currency } from '../../Reducers/objects/currency';
import { Itinerary } from '../Itinerary/objects/itinerary';
import { Provider } from '../Common/objects/provider';
import { isProductPackaged } from './utils/isProductPackaged';
import { ProviderContext } from './utils/providerContext';

type Props = {
    car: CarCart,
    car_index: number
}

export const CartCarCardDetails = withRouter<Props>(
    function CartCarCardDetails({ car }): JSX.Element {
        const classes = useStyles();
        const { t } = useTranslation();
        const dispatch = useDispatch();
        const { enqueueSnackbar } = useSnackbar();

        const quotation_code = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;

        const user = useSelector((state: AppState) => state.user?.user);
        const currency = useSelector((state: AppState) => state.trip.currency);
        const language = useSelector((state: AppState) => state.header.tmp_language);
        const currency_list = useSelector((state: AppState) => state.base.currency_list);
        const itinerary_list = useSelector((state: AppState) => state.itinerary.itinerary_list);
        const providers = useSelector((state: AppState) => state.trip.providers);
        const manual_providers = useSelector((state: AppState) => state.trip.manual_providers);
        const see_new_price = useSelector((state: AppState) => state.cart.see_new_price);
        const trip = useSelector((state: AppState) => state.trip.data_trip);

        const [showNote, setShowNote] = useState(false);
        const [showManualCancellationRules, setShowManualCancellationRules] = useState(false);
        const [showManualBooking, setShowManualBooking] = useState(false);
        const [onRequest, setOnRequest] = useState(false);
        const [catalogPrice, setCatalogPrice] = useState(false);
        const [margin, setMargin] = useState<{
            id?: number,
            ids?: {
                room_id: number,
                price_id: number
            }[],
            rate_type: 'PER' | 'FIX',
            purchase: number,
            purchase_converted: number,
            purchase_currency?: Currency,
            factor_used: string,
            value: number,
            static_value?: number,
            markup: boolean,
            percent: number,
            static_percent?: number,
            selling: number,
            selling_currency?: Currency,
            custom_rate?: boolean,
            custom_rate_type?: string
        } | null>(null);
        const [surcom, setSurcom] = useState<{
            value?: number,
            static?: number,
            purchase: number,
            selling: number,
            percent: number,
            currency: Currency | null
        } | null>(null);
        const [provider, setProvider] = useState<Provider | null>(null);
        const [oneWay, setOneWay] = useState<{
            cost: number;
            cost_currency: Currency | undefined;
            converted_cost: number;
            converted_currency: Currency;
        } | null>(null);
        const [marginValue, setMarginValue] = useState<number | null>(null);
        const [marginPercent, setMarginPercent] = useState<number | null>(null);
        const providerContext = useContext(ProviderContext);

        const manualBookingRef = useRef<HTMLDivElement>(null);

        const scrollIntoManuaBookingView = () => {
            manualBookingRef.current?.scrollIntoView({ block: 'center', inline: 'center', behavior: 'smooth' });
        };

        const showContractDetail = () => {
            dispatch({ type: 'CART_TOGGLE_CONTRACT_DETAIL', payload: car });
        };

        const onManualBooking = () => {
            setShowManualBooking(!showManualBooking);
        };

        const onAddNote = () => {
            setShowNote(!showNote);
        };

        const onDeleteNote = () => {
            const { headers } = CheckBeforeRequest();
            axios({
                method: 'PATCH',
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/car/${car.id}/`,
                data: {
                    custom_information: null
                }
            }).then(response => {
                dispatch({ type: 'CAR_EDIT_CART_BY_ID', payload: response.data });
                enqueueSnackbar(t('cart-material.note-deleted'), { variant: 'success' });
            }).catch(error => {
                console.log(error);
                enqueueSnackbar(t('cart-material.note-not-deleted'), { variant: 'error' });
            });
        };

        const onManualCancellationRules = () => {
            setShowManualCancellationRules(!showManualCancellationRules);
        };

        const onDeleteManualCancellationRules = () => {
            const { headers } = CheckBeforeRequest();
            axios({
                method: 'PATCH',
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/car/${car.id}/`,
                data: {
                    no_refund: true,
                    cancel_condition_text: null
                }
            }).then(response => {
                dispatch({ type: 'CAR_EDIT_CART_BY_ID', payload: response.data });
                setShowManualCancellationRules(!showManualCancellationRules);
            }).catch(error => {
                console.log(error);
                setShowManualCancellationRules(!showManualCancellationRules);
            });
        };

        const priceToDisplay = (price: ProductPrice) => {
            return {
                cost: parseFloat(price.selling_price),
                currency: currency_list.find(currency => currency.id === price.selling_currency)
            };
        };

        const getPrice = (prices: ProductPrice[]) => {
            if (user?.client_full?.type === 1 || user?.client_full?.type === 3 || quotation_code === 'verdie') {
                for (let i = 0; i < prices.length; i++) {
                    if (prices[i]!.is_tva) {
                        return priceToDisplay(prices[i]!);
                    }
                }
                for (let i = 0; i < prices.length; i++) {
                    if (prices[i]!.is_surcom) {
                        return priceToDisplay(prices[i]!);
                    }
                }
                for (let i = 0; i < prices.length; i++) {
                    if (prices[i]!.master_price) {
                        return priceToDisplay(prices[i]!);
                    }
                }
                for (let i = 0; i < prices.length; i++) {
                    if (prices[i]!.owner === user?.client) {
                        return priceToDisplay(prices[i]!);
                    }
                }
            } else {
                for (let i = 0; i < prices.length; i++) {
                    if (prices[i]!.is_tva) {
                        return priceToDisplay(prices[i]!);
                    }
                }
                for (let i = 0; i < prices.length; i++) {
                    if (prices[i]!.owner === user?.client) {
                        return priceToDisplay(prices[i]!);
                    }
                }
            }
            return {
                cost: 0,
                currency: currency
            };
        };

        const getMargin = (
            tmp_margin: NonNullable<typeof margin>,
            tmp_surcom: NonNullable<typeof surcom>,
            prices: ProductPrice[]
        ) => {
            if (user?.client_full?.type === 1 || user?.client_full?.type === 3 || quotation_code === 'verdie') {
                for (let i = 0; i < prices.length; i++) {
                    if (prices[i]!.is_surcom) {
                        tmp_surcom.purchase += parseFloat(prices[i]!.purchase_price) * parseFloat(prices[i]!.factor_used);
                        tmp_surcom.selling += parseFloat(prices[i]!.selling_price);
                        tmp_surcom.currency = currency_list.find(currency => currency.id === prices[i]!.selling_currency) ?? null;
                        tmp_surcom.percent += parseFloat(prices[i]!.percentage_between);
                        break;
                    }
                }
                for (let i = 0; i < prices.length; i++) {
                    if (prices[i]!.master_price) {
                        tmp_margin.purchase += parseFloat(prices[i]!.purchase_price);
                        tmp_margin.purchase_converted += parseFloat(prices[i]!.purchase_price) * parseFloat(prices[i]!.factor_used);
                        tmp_margin.purchase_currency = currency_list.find(currency => currency.id === prices[i]!.purchase_currency);
                        tmp_margin.selling += parseFloat(prices[i]!.selling_price);
                        tmp_margin.selling_currency = currency_list.find(currency => currency.id === prices[i]!.selling_currency);
                        tmp_margin.percent += parseFloat(prices[i]!.percentage_between);
                        tmp_margin.custom_rate = prices[i]!.custom_rate;
                        tmp_margin.custom_rate_type = prices[i]!.custom_rate_type ?? undefined;
                        tmp_margin.id = prices[i]!.id;
                        break;
                    }
                }
                if (tmp_margin.id === null) {
                    for (let i = 0; i < prices.length; i++) {
                        if (prices[i]!.owner === user?.client) {
                            tmp_margin.purchase += parseFloat(prices[i]!.purchase_price);
                            tmp_margin.purchase_converted += parseFloat(prices[i]!.purchase_price) * parseFloat(prices[i]!.factor_used);
                            tmp_margin.purchase_currency = currency_list.find(currency => currency.id === prices[i]!.purchase_currency);
                            tmp_margin.selling += parseFloat(prices[i]!.selling_price);
                            tmp_margin.selling_currency = currency_list.find(currency => currency.id === prices[i]!.selling_currency);
                            tmp_margin.percent += parseFloat(prices[i]!.percentage_between);
                            tmp_margin.custom_rate = prices[i]!.custom_rate;
                            tmp_margin.custom_rate_type = prices[i]!.custom_rate_type ?? undefined;
                            tmp_margin.id = prices[i]!.id;
                            break;
                        }
                    }
                }
            } else {
                for (let i = 0; i < prices.length; i++) {
                    if (prices[i]!.owner === user?.client) {
                        tmp_margin.purchase += parseFloat(prices[i]!.purchase_price);
                        tmp_margin.purchase_converted += parseFloat(prices[i]!.purchase_price) * parseFloat(prices[i]!.factor_used);
                        tmp_margin.purchase_currency = currency_list.find(currency => currency.id === prices[i]!.purchase_currency);
                        tmp_margin.selling += parseFloat(prices[i]!.selling_price);
                        tmp_margin.selling_currency = currency_list.find(currency => currency.id === prices[i]!.selling_currency);
                        tmp_margin.percent += parseFloat(prices[i]!.percentage_between);
                        tmp_margin.custom_rate = prices[i]!.custom_rate;
                        tmp_margin.custom_rate_type = prices[i]!.custom_rate_type ?? undefined;
                        tmp_margin.id = prices[i]!.id;
                        break;
                    }
                }
            }
        };

        const priceCurrencyConverter = (
            first_price: number,
            first_currency: Currency | undefined,
            second_currency: Currency
        ) => {
            if (first_currency?.iso_code === second_currency.iso_code) {
                return first_price;
            }
            return (first_currency?.iso_code !== 'USD' ? first_price / (first_currency?.usd_factor ?? 1) : first_price) * second_currency.usd_factor;
        };

        const confirmCustomInformationRecalc = () => {
            const { headers } = CheckBeforeRequest();
            axios({
                method: 'PATCH',
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/car/${car.id}/`,
                data: {
                    moved_with_recalc_custom_information: false
                }
            }).then(response => {
                dispatch({ type: 'CAR_EDIT_CART_BY_ID', payload: response.data });
            }).catch(error => {
                console.log(error);
            });
        };

        const confirmCancelConditionRecalc = () => {
            const { headers } = CheckBeforeRequest();
            axios({
                method: 'PATCH',
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/car/${car.id}/`,
                data: {
                    moved_with_recalc_cancel_condition_text: false
                }
            }).then(response => {
                dispatch({ type: 'CAR_EDIT_CART_BY_ID', payload: response.data });
            }).catch(error => {
                console.log(error);
            });
        };

        const handleSeeNewPrice = () => {
            dispatch({
                type: 'CART_SEE_NEW_PRICE_MODAL',
                payload: {
                    see_new_price: !see_new_price,
                    data: see_new_price ? null : {
                        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/car/${car.id}/`,
                        id: car.id,
                        old_price: car.prices[GetPricesId(car.prices, user?.client, user, true)],
                        new_price: car.provider_wanted_price,
                        product: car,
                        custom_dispatch: 'CAR_EDIT_CART_BY_ID'
                    }
                }
            });
        };

        useEffect(() => {
            if (showManualBooking) {
                scrollIntoManuaBookingView();
            }
        });

        useEffect(() => {
            if (marginValue && marginValue !== margin?.value) {
                let tmp_margin = margin ?
                    { ...margin } :
                    null;
                if (tmp_margin) {
                    tmp_margin.value = marginValue;
                    tmp_margin.rate_type = 'FIX';
                    if (tmp_margin.markup) {
                        tmp_margin.percent = parseFloat(((tmp_margin.value / tmp_margin.purchase_converted) * 100).toFixed(2));
                    } else {
                        tmp_margin.percent = parseFloat(((tmp_margin.value / tmp_margin.selling) * 100).toFixed(2));
                    }
                    setMarginPercent(tmp_margin.percent);
                    setMargin(tmp_margin);
                }
            }
        }, [marginValue]);

        useEffect(() => {
            if (marginPercent && marginPercent !== margin?.percent) {
                let tmp_margin = margin ?
                    { ...margin } :
                    null;
                if (tmp_margin) {
                    tmp_margin.percent = marginPercent;
                    tmp_margin.rate_type = 'PER';
                    if (tmp_margin.markup) {
                        tmp_margin.value = parseFloat((tmp_margin.purchase_converted * (tmp_margin.percent / 100)).toFixed(2));
                    } else {
                        tmp_margin.value = parseFloat((tmp_margin.selling * (tmp_margin.percent / 100)).toFixed(2));
                    }
                    setMarginValue(tmp_margin.value);
                    setMargin(tmp_margin);
                }
            }
        }, [marginPercent]);

        useEffect(() => {
            if (showNote) {
                const element = document.getElementById(`car-${car.id}-note`);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
                }
            }
        });

        useEffect(() => {
            if (showManualCancellationRules) {
                const element = document.getElementById(`car-${car.id}-manual-cancellation-rules`);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
                }
            }
        });

        useEffect(() => {
            let total_cost: {
                cost: number,
                currency?: Currency
            }[] = [];
            let on_request = false;
            let catalog_price = false;
            if (car.on_request) {
                on_request = true;
            }
            if (car.catalog_price) {
                catalog_price = true;
            }
            let tmp_margin: NonNullable<typeof margin> = {
                purchase: 0,
                purchase_converted: 0,
                selling: 0,
                value: 0,
                percent: 0,
                rate_type: 'PER',
                factor_used: '0',
                markup: false
            };
            let tmp_surcom: NonNullable<typeof surcom> = {
                purchase: 0,
                selling: 0,
                value: 0,
                percent: 0,
                currency: null
            };
            let price = getPrice(car.prices);
            getMargin(tmp_margin, tmp_surcom, car.prices);
            let matched = false;
            total_cost.map(currency_cost => {
                if (currency_cost.currency?.id === price.currency?.id) {
                    matched = true;
                    currency_cost.cost += price.cost;
                }
            });
            if (!matched) {
                total_cost.push({
                    cost: price.cost,
                    currency: price.currency
                });
            }
            let found = {
                start: false,
                end: false
            };
            let iti_error: {
                start: Itinerary | null,
                end: Itinerary | null
            } = {
                start: null,
                end: null
            };
            itinerary_list.map(step => {
                if (step.destination && car.start_destination && step.destination.id === car.start_destination.id && (found.start === false || (found.start && iti_error.start))) {
                    found.start = true;
                    if (!(window.moment.utc(car.start_date).isSameOrAfter(window.moment.utc(step.start_date), 'd') && window.moment.utc(car.start_date).isSameOrBefore(window.moment.utc(step.end_date), 'd'))) {
                        iti_error.start = step;
                    } else {
                        iti_error.start = null;
                    }
                }
                if (step.destination && car.end_destination && step.destination.id === car.end_destination.id && (found.end === false || (found.end && iti_error.end))) {
                    found.end = true;
                    if (
                        !(
                            (
                                window.moment.utc(car.end_date).isValid() ?
                                    window.moment.utc(car.end_date) :
                                    window.moment.utc(car.start_date)
                            ).isSameOrAfter(window.moment.utc(step.start_date), 'd') &&
                            (
                                window.moment.utc(car.end_date).isValid() ?
                                    window.moment.utc(car.end_date) :
                                    window.moment.utc(car.start_date)
                            ).isSameOrBefore(window.moment.utc(step.end_date), 'd')
                        )
                    ) {
                        iti_error.end = step;
                    } else {
                        iti_error.end = null;
                    }
                }
            });
            let tmp_provider = null;
            for (let i = 0; i < providers.length; i++) {
                if (providers[i]!.provider.id === car.provider) {
                    tmp_provider = providers[i];
                }
            }
            for (let i = 0; i < manual_providers.length; i++) {
                if (manual_providers[i]!.provider.id === car.provider) {
                    tmp_provider = manual_providers[i];
                }
            }
            tmp_margin.value = parseFloat((tmp_margin.selling - tmp_margin.purchase_converted).toFixed(2));
            tmp_margin.static_value = tmp_margin.value;
            tmp_margin.markup = Math.abs(tmp_margin.percent - (100 - (tmp_margin.purchase_converted / tmp_margin.selling) * 100)) > Math.abs(tmp_margin.percent - ((tmp_margin.value / tmp_margin.purchase_converted) * 100));
            tmp_margin.static_percent = tmp_margin.percent;
            tmp_margin.custom_rate = tmp_margin.custom_rate;
            tmp_surcom.static = tmp_surcom.value;
            tmp_surcom.value = tmp_surcom.selling - tmp_surcom.purchase;
            car.onsite_fare.map((onsite_fare) => {
                if (onsite_fare.description === "One way fares" && onsite_fare.price !== 0) {
                    const currency_one_way = currency_list.find((el) => {
                        return el.id === onsite_fare.currency;
                    });
                    let one_way = {
                        cost: onsite_fare.price,
                        cost_currency: currency_one_way,
                        converted_cost: currency_one_way?.iso_code !== currency.iso_code ?
                            priceCurrencyConverter(onsite_fare.price, currency_one_way, currency) :
                            onsite_fare.price,
                        converted_currency: currency
                    };
                    setOneWay(one_way);
                }
            });
            setMargin(tmp_margin);
            setSurcom(tmp_surcom);
            setOnRequest(on_request);
            setCatalogPrice(catalog_price);
            setProvider(tmp_provider ?? null);
        }, [car]);

        return (
            <>
                <Grid
                    id={`product-${car.id}`}
                    className={classes.scrollMargin}
                    container
                    item
                    xs
                    direction={"column"}
                    spacing={2}
                >
                    <Grid item>
                        <Grid container direction="row" justify={'space-between'} alignItems={'center'}>
                            {
                                user?.client_full?.type !== 2 &&
                                <Grid item>
                                    <CartConstructionProviderQuotationStatusLayout
                                        status={
                                            <CartConstructionProductsTableItemProviderQuotationStatus
                                                item={car}
                                            />
                                        }
                                        buttons={
                                            <CartPackageItemChangeProviderQuotationStatusButtons
                                                item={{
                                                    ...car,
                                                    stackNumber: car.stack_number,
                                                    providerId: car.provider,
                                                    type: 'cars',
                                                    isCustom: car.is_custom
                                                }}
                                            />
                                        }
                                    />
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                    <Grid item container spacing={2}>
                        <Grid item xs>
                            <Grid container>
                                <Grid className={classes.gridContent} item xs>
                                    <div className={classes.containerMargin}>
                                        {
                                            car.included_service.map((service, index_service) => {
                                                if (service.included) {
                                                    return <div key={`car-service-${index_service}`}><CheckCircleOutline className={classes.service} /><Typography>{service.title}</Typography></div>;
                                                }
                                            })
                                        }
                                    </div>
                                    <br />
                                    <Grid container spacing={2}>
                                        <Grid item>
                                            <Typography className={classes.contractPack} onClick={showContractDetail}>
                                                {t('cart-material.contract-detail')} : {car.contract_name}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <br />
                                    {
                                        provider && provider.provider.name === 'Avis' && car.location_code_pickup !== car.location_code_return &&
                                        <Typography>{t('cart-material.one-way')}</Typography>
                                    }
                                </Grid>
                            </Grid>
                            <Grid className={classes.bookingContainer} item xs>
                                {
                                    !isProductPackaged({
                                        product: car,
                                        stackInfos: trip?.stack_info ?? null
                                    }) &&
                                    <Fragment>
                                        {
                                            !car.is_available && (provider === null || !provider.provider.custom) &&
                                            <Typography className={clsx(classes.price, classes.unavailable)}>{t('cart-material.product-unavailable')}</Typography>
                                        }
                                        {
                                            catalogPrice &&
                                            <Typography
                                                className={
                                                    clsx(
                                                        classes.price,
                                                        {
                                                            [classes.priceUnavailable]: !car.is_available && (provider === null || !provider.provider.custom)
                                                        }
                                                    )
                                                }
                                            >
                                                {t('cart-material.catalog-price')}
                                            </Typography>
                                        }
                                        {
                                            car.booking_status?.status_booking !== StatusBooking.CONFIRMED &&
                                            onRequest &&
                                            <Typography>{t('cart-material.on-request')}</Typography>
                                        }
                                        {
                                            car.price_change &&
                                            (car.price_change.price_variation !== 0 || !car.price_change.is_available) &&
                                            <Grid container justify={'flex-end'} alignItems={'center'}>
                                                {
                                                    car.price_change.is_available &&
                                                    <Grid item>
                                                        {car.price_change.price_variation > 0 ? <ArrowUpward className={classes.red} /> : <ArrowDownward className={classes.green} />}
                                                    </Grid>
                                                }
                                                <Grid item>
                                                    <Typography className={car.price_change.price_variation > 0 || !car.price_change.is_available ? classes.red : classes.green}>
                                                        {
                                                            !car.price_change.is_available ?
                                                                t('cart-material.product-unavailable') :
                                                                car.price_change.price_variation.toLocaleString(
                                                                    language,
                                                                    { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                                                                )
                                                        }
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        }
                                    </Fragment>
                                }
                            </Grid>
                        </Grid>
                        {
                            oneWay &&
                            <Grid item xs={12}>
                                <Alert className={classes.resortFees} classes={{ icon: classes.alertIcon }} variant={'filled'} severity={'warning'}>
                                    {
                                        //eslint-disable-next-line max-len
                                        `${t('cars.one_way')} : ${t('cart-material.resort-fees-1')} ${oneWay.cost} ${oneWay.cost_currency ? oneWay.cost_currency?.symbol : ''} ${oneWay.cost !== oneWay.converted_cost ? `(${t('cart-material.resort-fees-2')} ${oneWay.converted_cost}${oneWay.converted_currency ? oneWay.converted_currency.symbol : ''})` : ''} ${t('cart-material.one-way-fees-3')}`
                                    }
                                </Alert>
                            </Grid>
                        }
                        {
                            car.booking_status && car.booking_status.status_booking === 'UNAVAILABLE' &&
                            <Grid item xs={12}>
                                <Alert className={classes.errorAlert} classes={{ icon: classes.alertIcon }} variant={'filled'} severity={'error'}>
                                    {t('cart-material.booking-unavailable')} : {car.booking_status.item_reference}
                                </Alert>
                            </Grid>
                        }
                        {
                            car.booking_status &&
                            (car.booking_status.status_booking === 'ERROR' || car.booking_status.status_booking === 'UNAVAILABLE') &&
                            car.booking_errors &&
                            car.booking_errors.length > 0 &&
                            <Grid item xs={12}>
                                <Alert className={classes.errorAlert} classes={{ icon: classes.alertIcon }} variant={'filled'} severity={'error'}>
                                    <AlertTitle>{t('cart-material.error')} :</AlertTitle>
                                    {
                                        car.booking_errors.map(error => (
                                            <Typography key={`booking-error-${error.created_date}`}>
                                                {window.moment.utc(error.created_date).format('DD/MM/YYYY')} : {error.detail}
                                            </Typography>
                                        ))
                                    }
                                </Alert>
                            </Grid>
                        }
                        {
                            showManualBooking &&
                            <Grid item xs={12} style={{ marginTop: 15 }} ref={manualBookingRef}>
                                <CartCarManualBooking car={car} onManualBooking={onManualBooking} />
                            </Grid>
                        }
                        {
                            !providerContext.module &&
                            <>
                                {
                                    showNote ? (
                                        <Grid id={`car-${car.id}-note`} item xs={12}>
                                            <CartCarNote car={car} onAddNote={onAddNote} />
                                        </Grid>
                                    ) : car.custom_information && (
                                        <Grid item xs={12}>
                                            <Alert className={classes.cancelConditionAlert} variant={'filled'} severity={'info'} action={
                                                <Grid container direction={'column'} spacing={2} alignItems={'flex-end'}>
                                                    <Grid item>
                                                        <Grid container spacing={2} alignItems={'center'}>
                                                            <Grid item>
                                                                <Button className={classes.editCustomInformation} color={'inherit'} size={'small'} onClick={onAddNote}>
                                                                    {t('global.edit')}
                                                                </Button>
                                                            </Grid>
                                                            <Grid item>
                                                                <Close className={classes.deleteCustomInformation} onClick={onDeleteNote} />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item style={{ width: '100%' }}>
                                                        {
                                                            car.moved_with_recalc_custom_information &&
                                                            <Tooltip title={t('cart-material.recalc-product-note')}>
                                                                <div
                                                                    className={clsx(classes.traverlerModification, classes.delete)}
                                                                    onClick={confirmCustomInformationRecalc}
                                                                    style={{ fontSize: 13, color: 'white', background: '#ff9800', border: '2px solid #ff9800' }}
                                                                >
                                                                    <Grid container alignItems={'center'} justify={'space-between'}>
                                                                        <Grid item><ReportProblem style={{ verticalAlign: 'sub', fontSize: 18 }} /></Grid>
                                                                        <Grid item>{t('cart-material.recalc')}</Grid>
                                                                        <Grid item><Close style={{ verticalAlign: 'middle', fontSize: 18 }} /></Grid>
                                                                    </Grid>
                                                                </div>
                                                            </Tooltip>
                                                        }
                                                    </Grid>
                                                </Grid>
                                            }>
                                                <AlertTitle>{t('cart-material.note')} :</AlertTitle>
                                                {car.custom_information}
                                            </Alert>
                                        </Grid>
                                    )
                                }
                            </>
                        }
                        {
                            user?.client_full?.type !== 2 && car.provider_wanted_price &&
                            <Grid item xs={12}>
                                <Alert variant={'filled'} severity={'error'} action={
                                    <Button color={"inherit"} size={'small'} onClick={handleSeeNewPrice}>{t('global.see')}</Button>
                                }>
                                    <AlertTitle>{t('cart-material.provider-wanted-price')}</AlertTitle>
                                </Alert>
                            </Grid>
                        }
                        {
                            showManualCancellationRules ?
                                <Grid id={`car-${car.id}-manual-cancellation-rules`} item xs={12}>
                                    <CartCarManualCancellationRules car={car} onManualCancellationRules={onManualCancellationRules} />
                                </Grid> :
                                car.cancel_condition_text &&
                                <Grid item xs={12}>
                                    <Alert className={classes.cancelConditionAlert} variant={'filled'} severity={'info'} action={
                                        <Grid container direction={'column'} spacing={2} alignItems={'flex-end'}>
                                            <Grid item>
                                                <Grid container spacing={2} alignItems={'center'}>
                                                    {
                                                        //!['verdie'].includes(quotation_code) &&
                                                        <Fragment>
                                                            <Grid item>
                                                                <Button className={classes.editCustomInformation} color={'inherit'} size={'small'} onClick={onManualCancellationRules}>
                                                                    {t('global.edit')}
                                                                </Button>
                                                            </Grid>
                                                            <Grid item>
                                                                <Close className={classes.deleteCustomInformation} onClick={onDeleteManualCancellationRules} />
                                                            </Grid>
                                                        </Fragment>
                                                    }
                                                </Grid>
                                            </Grid>
                                            <Grid item style={{ width: '100%' }}>
                                                {
                                                    car.moved_with_recalc_cancel_condition_text &&
                                                    <Tooltip title={t('cart-material.recalc-product-cancel-condition')}>
                                                        <div
                                                            className={clsx(classes.traverlerModification, classes.delete)}
                                                            onClick={confirmCancelConditionRecalc}
                                                            style={{ fontSize: 13, color: 'white', background: '#ff9800', border: '2px solid #ff9800' }}
                                                        >
                                                            <Grid container alignItems={'center'} justify={'space-between'}>
                                                                <Grid item><ReportProblem style={{ verticalAlign: 'sub', fontSize: 18 }} /></Grid>
                                                                <Grid item>{t('cart-material.recalc')}</Grid>
                                                                <Grid item><Close style={{ verticalAlign: 'middle', fontSize: 18 }} /></Grid>
                                                            </Grid>
                                                        </div>
                                                    </Tooltip>
                                                }
                                            </Grid>
                                        </Grid>
                                    }>
                                        <AlertTitle>
                                            {
                                                !['verdie'].includes(quotation_code) ?
                                                    t('cart-material.manual-cancellation-rules') :
                                                    t('cart-material.provider-cancellation-rules')
                                            } :{' '}
                                            {
                                                car.no_refund ?
                                                    t('cart-material.not-cancellable') :
                                                    t('cart-material.cancellable-with-conditions')
                                            }
                                        </AlertTitle>
                                        {car.cancel_condition_text === t('cart-material.not-cancellable') ? '' : car.cancel_condition_text}
                                    </Alert>
                                </Grid>
                        }
                    </Grid>
                </Grid>
            </>
        );
    }
);

const useStyles = makeStyles(() => ({
    scrollMargin: {
        scrollMarginTop: '75px'
    },
    disableBorder: {
        border: 'none !important'
    },
    gridImage: {
        width: 250,
        position: 'relative'
    },
    gridContent: {
        padding: '0 15px'
    },
    gridPrice: {
        width: 100
    },
    image: {
        borderRadius: 16
    },
    subProviderLogo: {
        position: 'absolute',
        left: 0
    },
    selector: {
        margin: '16px 0 8px 0'
    },
    variant: {
        margin: '16px 0 8px 0'
    },
    updateQuotationStatus: {
        textDecoration: 'underline',
        marginBottom: 4,
        cursor: 'pointer'
    },
    addNote: {
        textDecoration: 'underline',
        marginBottom: 4,
        cursor: 'pointer'
    },
    manualCancellationRules: {
        textDecoration: 'underline',
        marginBottom: 4,
        cursor: 'pointer'
    },
    containerMargin: {
        marginBottom: 4
    },
    carName: {
        fontSize: 20,
        fontWeight: 'bold',
        cursor: 'pointer'
    },
    hotelInPropertyCollapsed: {
        float: 'left',
        cursor: 'pointer',
        marginRight: 4
    },
    hotelInPropertyIconCollapsed: {
        verticalAlign: 'text-top'
    },
    pin: {
        float: 'left',
        marginRight: 4
    },
    calendar: {
        float: 'left',
        marginRight: 4
    },
    service: {
        float: 'left',
        marginRight: 4
    },
    contractPack: {
        textDecoration: 'underline',
        pointer: 'cursor',
        textAlign: 'right',
        cursor: 'pointer',
        fontSize: 12,
        display: 'inline',
        marginLeft: 5
    },
    contractLoading: {
        color: '#E6592F',
        marginLeft: 16
    },
    bookingContainer: {
        paddingLeft: 15
    },
    manualBookingHand: {
        float: 'left',
        marginRight: 8
    },
    price: {
        textAlign: 'right',
        fontSize: 32,
        fontWeight: 'bold'
    },
    unavailable: {
        color: 'red'
    },
    priceUnavailable: {
        fontSize: 20
    },
    red: {
        textAlign: 'right',
        color: 'red'
    },
    green: {
        textAlign: 'right',
        color: 'green'
    },
    priceDetail: {
        textAlign: 'right',
        textDecoration: 'underline'
    },
    traverlerModification: {
        borderRadius: 16,
        padding: '1px 6px'
    },
    booking: {
        borderRadius: 16,
        fontWeight: 700,
        padding: '4px 12px',
        margin: '4px 0',
        textTransform: 'uppercase'
    },
    bookingCollapsed: {
        borderRadius: 16,
        fontWeight: 700,
        padding: '4px 12px',
        margin: '8px 0 0 0',
        textTransform: 'uppercase'
    },
    bookingDetail: {
        textDecoration: 'underline',
        fontSize: 12,
        textAlign: 'right',
        marginBottom: 4,
        cursor: 'pointer'
    },
    needToBook: {
        color: '#4caf50',
        textAlign: 'right'
    },
    booked: {
        border: '2px solid #4caf50',
        color: '#4caf50'
    },
    pending: {
        border: '2px solid #ff9800',
        color: '#ff9800'
    },
    cached: {
        float: 'left',
        marginRight: 8,
        cursor: 'pointer'
    },
    cancelled: {
        border: '2px solid black',
        color: 'black'
    },
    error: {
        border: '2px solid #f44336',
        color: '#f44336'
    },
    deleteContainer: {
        textAlign: 'right'
    },
    delete: {
        cursor: 'pointer'
    },
    prices: {
        textAlign: 'right'
    },
    errorAlert: {
        marginTop: 8
    },
    alertIcon: {
        alignItems: 'center'
    },
    infoCustomInformation: {
        border: '1px solid #FF9800',
        color: 'white',
        background: '#FF9800',
        marginRight: 5
    },
    editCustomInformation: {
        border: '1px solid'
    },
    deleteCustomInformation: {
        cursor: 'pointer',
        marginLeft: 8
    },
    marginEdit: {
        textDecoration: 'underline',
        color: '#E6592F',
        cursor: 'pointer'
    },
    validateButton: {
        backgroundColor: '#E6592F',
        color: 'white'
    },
    lowerCase: {
        textTransform: "capitalize"
    },
    hideMargin: {
        textDecoration: 'underline',
        cursor: 'pointer'
    },
    hideProduct: {
        backgroundColor: '#eeee',
        border: "solid 2px #E6592F",
        padding: 15,
        borderRadius: 10,
        marginBottom: 20
    },
    inQuotation: {
        border: "solid 2px #43D67D",
        padding: 15,
        borderRadius: 10,
        marginBottom: 20
    },
    isOption: {
        border: "solid 2px #eeee",
        padding: 15,
        borderRadius: 10,
        marginBottom: 20
    },
    progress: {
        color: green[500],
        position: 'absolute',
        top: "16%",
        left: "43%",
        marginLeft: -12
    },
    checkboxContainer: {
        position: 'relative',
        marginTop: '-5px'
    },
    cancelButton: {
        border: '1px solid',
        backgroundColor: 'white',
        color: '#E6592F'
    },
    cancelConditionAlert: {
        "whiteSpace": 'pre-wrap',
        "&& .MuiAlert-action": {
            minWidth: 140
        }
    },
    resortFees: {
        margin: '15px 0 0 15px'
    },
    editTime: {
        "cursor": 'pointer',
        '&:hover': {
            textDecoration: 'underline'
        }
    }
}));
