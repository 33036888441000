import { useDispatch } from "react-redux";
import axios from "axios";
import { usePackagedProducts } from "../../CartMaterial/utils/packagedProducts";
import { useCartProductUpdate } from "../../CartMaterial/network/cartProductUpdate";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import { TripVersion } from "../../Menu/MaterialTripList/objects/tripVersion";
import { StatusBooking } from "../../Itinerary/objects/statusBooking";

type CallbackOptions = RequestOptions & {
    products: ReturnType<typeof usePackagedProducts>[string]
}

type Callback = (request: CallbackOptions) => Promise<void>

type Options = Partial<{
    onTrigger: () => void,
    onSuccess: () => void,
    onError: (error: Error) => void,
    onFinally: () => void
}>

export function useChangePackageReferenceBooking(options: Options): Callback {
    const dispatch = useDispatch();
    const updateProduct = useCartProductUpdate({});
    return async (requestOptions) => {
        try {
            if (options.onTrigger) {
                options.onTrigger();
            }
            const { trip, stackInfoId } = await makeRequest(requestOptions);
            dispatch({ type: 'TRIP_SET_DATA_TRIP', payload: { data_trip: trip } });

            await Promise.all(
                requestOptions.products.accommodations.filter((item) => {
                    return !item.accommodation.stack_info_id;
                }).map((item) => {
                    if (item.type === 'normal') {
                        return updateProduct(
                            requestOptions.tripVersion,
                            {
                                type: 'accommodation',
                                id: item.accommodation.id,
                                roomIds: item.accommodation.rooms.map((room) => {
                                    return room.id;
                                }),
                                isCustom: item.accommodation.is_custom,
                            },
                            {
                                stack_info_id: stackInfoId
                            }
                        );
                    }
                    return updateProduct(
                        requestOptions.tripVersion,
                        {
                            type: 'manual-accommodation',
                            id: item.accommodation.id,
                            isCustom: false,
                        },
                        {
                            stack_info_id: stackInfoId
                        }
                    );
                }).concat(
                    requestOptions.products.assistances.filter((item) => {
                        return !item.assistance.stack_info_id;
                    }).map((item) => {
                        if (item.type === 'normal') {
                            return updateProduct(
                                requestOptions.tripVersion,
                                {
                                    type: 'assistance',
                                    id: item.assistance.id,
                                    isCustom: item.assistance.is_custom,
                                },
                                {
                                    stack_info_id: stackInfoId
                                }
                            );
                        }
                        return updateProduct(
                            requestOptions.tripVersion,
                            {
                                type: 'manual',
                                id: item.assistance.id,
                                isCustom: false,
                            },
                            {
                                stack_info_id: stackInfoId
                            }
                        );
                    })
                ).concat(
                    requestOptions.products.cars.filter((item) => {
                        return !item.car.stack_info_id;
                    }).map((item) => {
                        if (item.type === 'normal') {
                            return updateProduct(
                                requestOptions.tripVersion,
                                {
                                    type: 'car',
                                    id: item.car.id,
                                    isCustom: item.car.is_custom,
                                },
                                {
                                    stack_info_id: stackInfoId
                                }
                            );
                        }
                        return updateProduct(
                            requestOptions.tripVersion,
                            {
                                type: 'manual',
                                id: item.car.id,
                                isCustom: false,
                            },
                            {
                                stack_info_id: stackInfoId
                            }
                        );
                    })
                ).concat(
                    requestOptions.products.flights.filter((item) => {
                        return !item.flight.stack_info_id;
                    }).map((item) => {
                        if (item.type === 'normal') {
                            return updateProduct(
                                requestOptions.tripVersion,
                                {
                                    type: 'flight',
                                    id: item.flight.id,
                                    isCustom: item.flight.is_custom,
                                },
                                {
                                    stack_info_id: stackInfoId
                                }
                            );
                        }
                        return updateProduct(
                            requestOptions.tripVersion,
                            {
                                type: 'manual',
                                id: item.flight.id,
                                isCustom: false,
                            },
                            {
                                stack_info_id: stackInfoId
                            }
                        );
                    })
                ).concat(
                    requestOptions.products.pois.filter((item) => {
                        return !item.poi.stack_info_id;
                    }).map((item) => {
                        if (item.type === 'normal') {
                            return updateProduct(
                                requestOptions.tripVersion,
                                {
                                    type: 'poi',
                                    id: item.poi.id,
                                    isCustom: item.poi.is_custom,
                                },
                                {
                                    stack_info_id: stackInfoId
                                }
                            );
                        }
                        return updateProduct(
                            requestOptions.tripVersion,
                            {
                                type: 'manual',
                                id: item.poi.id,
                                isCustom: false,
                            },
                            {
                                stack_info_id: stackInfoId
                            }
                        );
                    })
                ).concat(
                    requestOptions.products.transfers.filter((item) => {
                        return !item.transfer.stack_info_id;
                    }).map((item) => {
                        if (item.type === 'normal') {
                            return updateProduct(
                                requestOptions.tripVersion,
                                {
                                    type: 'transfer',
                                    id: item.transfer.id,
                                    isCustom: item.transfer.is_custom,
                                },
                                {
                                    stack_info_id: stackInfoId
                                }
                            );
                        }
                        return updateProduct(
                            requestOptions.tripVersion,
                            {
                                type: 'manual',
                                id: item.transfer.id,
                                isCustom: false,
                            },
                            {
                                stack_info_id: stackInfoId
                            }
                        );
                    })
                ).concat(
                    requestOptions.products.manualProducts.filter((item) => {
                        return !item.stack_info_id;
                    }).map((item) => {
                        return updateProduct(
                            requestOptions.tripVersion,
                            {
                                type: 'manual',
                                id: item.id,
                                isCustom: false,
                            },
                            {
                                stack_info_id: stackInfoId
                            }
                        );
                    })
                )
            );

            if (options.onSuccess) {
                options.onSuccess();
            }
        } catch (error: any) {
            if (options.onError) {
                options.onError(error);
            }
        } finally {
            if (options.onFinally) {
                options.onFinally();
            }
        }
    };
}

type RequestOptions = {
    tripId: number,
    tripVersion: number,
    stackNumber: number,
    stackInfos: NonNullable<TripVersion['stack_info']>,
    stackInfo: NonNullable<TripVersion['stack_info']>[number] | null,
    reference: string | null
}
async function makeRequest(options: RequestOptions): Promise<{
    trip: TripVersion,
    stackInfoId: number | null
}> {
    const { headers } = CheckBeforeRequest();
    const stackInfoIds = options.stackInfos.map((item) => {
        return item.id;
    });
    const response = await axios<TripVersion>({
        method: 'PATCH',
        headers: headers,
        url: `${API_HREF}client/${window.id_owner}/trip/${options.tripId}/versions/${options.tripVersion}/`,
        data: {
            stack_info: (
                options.stackInfos as {
                    id?: number,
                    stack_number: number,
                    booking_status: object | null
                }[]
            ).filter((item) => {
                return item.id !== options.stackInfo?.id;
            }).concat([
                {
                    id: options.stackInfo?.id,
                    stack_number: options.stackNumber,
                    booking_status: options.reference ?
                        {
                            status_booking: StatusBooking.CONFIRMED,
                            item_reference: options.reference
                        } :
                        null
                }
            ])
        }
    });
    return {
        trip: response.data,
        stackInfoId: response.data.stack_info?.find((item) => {
            return !stackInfoIds.includes(item.id);
        })?.id ??
            options.stackInfo?.id ??
            null
    };
}