import { groupBy, mergeWith } from "lodash";
import { isProductPackaged } from "./isProductPackaged";
import { useCartProducts } from "../../Itinerary/network/cartProducts";
import { ManualProduct } from "../../../Reducers/objects/manualProduct";
import { TripVersion } from "../../Menu/MaterialTripList/objects/tripVersion";

type Cart = Omit<
    ReturnType<typeof useCartProducts> & {manualProducts: ManualProduct[]},
    'trains' | 'ferries' | 'cruises' | 'insurances'
>

type Options = {
    trip: TripVersion | null,
    cart: Cart
}

export function extractPackages(options: Options) {
            const grouppedProducts = {
                flights: groupBy(
                    options.cart.flights.filter((item) => {
                        return isProductPackaged({
                            product: item.flight,
                            stackInfos: options.trip?.stack_info ?? null
                        });
                    }),
                    (item) => `${item.flight.stack_number ?? ''}-${item.flight.stack_info_id ?? ''}`
                ),
                accommodations: groupBy(
                    options.cart.accommodations.filter((item) => {
                        return isProductPackaged({
                            product: item.accommodation,
                            stackInfos: options.trip?.stack_info ?? null
                        });
                    }),
                    (item) => `${item.accommodation.stack_number ?? ''}-${item.accommodation.stack_info_id ?? ''}`
                ),
                cars: groupBy(
                    options.cart.cars.filter((item) => {
                        return isProductPackaged({
                            product: item.car,
                            stackInfos: options.trip?.stack_info ?? null
                        });
                    }),
                    (item) => `${item.car.stack_number ?? ''}-${item.car.stack_info_id ?? ''}`
                ),
                pois: groupBy(
                    options.cart.pois.filter((item) => {
                        return isProductPackaged({
                            product: item.poi,
                            stackInfos: options.trip?.stack_info ?? null
                        });
                    }),
                    (item) => `${item.poi.stack_number ?? ''}-${item.poi.stack_info_id ?? ''}`
                ),
                transfers: groupBy(
                    options.cart.transfers.filter((item) => {
                        return isProductPackaged({
                            product: item.transfer,
                            stackInfos: options.trip?.stack_info ?? null
                        });
                    }),
                    (item) => `${item.transfer.stack_number ?? ''}-${item.transfer.stack_info_id ?? ''}`
                ),
                assistances: groupBy(
                    options.cart.assistances.filter((item) => {
                        return isProductPackaged({
                            product: item.assistance,
                            stackInfos: options.trip?.stack_info ?? null
                        });
                    }),
                    (item) => `${item.assistance.stack_number ?? ''}-${item.assistance.stack_info_id ?? ''}`
                ),
                manualProducts: groupBy(
                    options.cart.manualProducts.filter((item) => {
                        return isProductPackaged({
                            product: item,
                            stackInfos: options.trip?.stack_info ?? null
                        });
                    }),
                    (item) => `${item.stack_number ?? ''}-${item.stack_info_id ?? ''}`
                )
            };
            let result: {
                [key: string]: Cart
            } = {};
            result = mergeWith(
                result,
                grouppedProducts.flights,
                (result, flights) => {
                    return {
                        accommodations: [],
                        assistances: [],
                        cars: [],
                        pois: [],
                        transfers: [],
                        manualProducts: [],
                        ...result,
                        flights
                    };
                }
            );
            result = mergeWith(
                result,
                grouppedProducts.accommodations,
                (result, accommodations) => {
                    return {
                        flights: [],
                        assistances: [],
                        cars: [],
                        pois: [],
                        transfers: [],
                        manualProducts: [],
                        ...result,
                        accommodations
                    };
                }
            );
            result = mergeWith(
                result,
                grouppedProducts.cars,
                (result, cars) => {
                    return {
                        flights: [],
                        assistances: [],
                        accommodations: [],
                        pois: [],
                        transfers: [],
                        manualProducts: [],
                        ...result,
                        cars
                    };
                }
            );
            result = mergeWith(
                result,
                grouppedProducts.pois,
                (result, pois) => {
                    return {
                        flights: [],
                        cars: [],
                        assistances: [],
                        accommodations: [],
                        transfers: [],
                        manualProducts: [],
                        ...result,
                        pois
                    };
                }
            );
            result = mergeWith(
                result,
                grouppedProducts.transfers,
                (result, transfers) => {
                    return {
                        flights: [],
                        cars: [],
                        assistances: [],
                        accommodations: [],
                        pois: [],
                        manualProducts: [],
                        ...result,
                        transfers
                    };
                }
            );
            result = mergeWith(
                result,
                grouppedProducts.assistances,
                (result, assistances) => {
                    return {
                        flights: [],
                        cars: [],
                        accommodations: [],
                        pois: [],
                        transfers: [],
                        manualProducts: [],
                        ...result,
                        assistances
                    };
                }
            );
            result = mergeWith(
                result,
                grouppedProducts.manualProducts,
                (result, manualProducts) => {
                    return {
                        flights: [],
                        cars: [],
                        assistances: [],
                        accommodations: [],
                        pois: [],
                        transfers: [],
                        ...result,
                        manualProducts
                    };
                }
            );
            return result;
}