import React, { useContext, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Box,
    Checkbox,
    TableCell,
    TableRow,
    tableCellClasses
} from "@mui/material";
import { CheckBox, CheckBoxOutlineBlankOutlined } from "@mui/icons-material";
import { CartConstructionProductEditModal } from "./CartConstructionProductEditModal";
import { CartConstructionProductsContext } from "./utils/cartConstructionProductsContext";
import { useCartProducts } from "../Itinerary/network/cartProducts";
import { isProductBookable } from "./utils/isProductBookable";
import { getTableItemBackgroundColor } from "./utils/getTableItemBackgroundColor";
import { ManualProduct } from "../../Reducers/objects/manualProduct";
import { ManualProductAccommodationCart } from "../Itinerary/objects/manualProductAccommodationCart";
import { Flight } from "../Itinerary/network/flight";
import { ManualProductFlightCart } from "../Itinerary/objects/manualProductFlightCart";
import { CarCart } from "../Itinerary/objects/carCart";
import { AssistanceCart } from "../Itinerary/objects/assistanceCart";
import { AccommodationCart } from "../Itinerary/objects/accommodationCart";
import { PoiCart } from "../Itinerary/objects/poiCart";
import { TransferCart } from "../Itinerary/objects/transferCart";
import { AppState } from "../../Reducers/Reducers";

export type CartConstructionProductsTableItemProps = ({
    type: 'car',
    index: number,
    item: CarCart
} | {
    type: 'assistance',
    item: AssistanceCart
} | {
    type: 'accommodation',
    item: AccommodationCart,
    accommodations: ReturnType<typeof useCartProducts>['accommodations'][number][],
} | {
    type: 'manual-accommodation',
    item: ManualProductAccommodationCart,
} | {
    type: 'poi',
    item: PoiCart
} | {
    type: 'transfer',
    item: TransferCart
} | {
    type: 'flight',
    item: Flight
} | {
    type: 'manual-flight',
    item: ManualProductFlightCart
} | {
    type: 'manual',
    item: ManualProduct
})

type Props = {
    children: React.ReactNode
} & CartConstructionProductsTableItemProps

export function CartConstructionProductsTableItem(props: Props): JSX.Element {
    const dispatch = useDispatch();
    const user = useSelector((state: AppState) => state.user.user);
    const trip = useSelector((state: AppState) => state.trip.data_trip);
    const itinerary_type_details_data = useSelector((state: AppState) => state.itinerary_type.itinerary_type_details_data);
    const toBeBooked = useSelector((state: AppState) => state.cart.to_book) ?? [];
    const [open, setOpen] = useState(false);
    const context = useContext(CartConstructionProductsContext);
    const cart = useCartProducts();
    const hasCounterOffer = useMemo(() => {
        return cart.accommodations.some((item) => {
            return item.accommodation.counter_offer_of === props.item.id;
        }) ||
            cart.assistances.some((item) => {
                return item.assistance.counter_offer_of === props.item.id;
            }) ||
            cart.cars.some((item) => {
                return item.car.counter_offer_of === props.item.id;
            }) ||
            cart.cruises.some((item) => {
                return item.cruise.counter_offer_of === props.item.id;
            }) ||
            cart.ferries.some((item) => {
                return item.ferry.counter_offer_of === props.item.id;
            }) ||
            cart.flights.some((item) => {
                return item.flight.counter_offer_of === props.item.id;
            }) ||
            cart.insurances.some((item) => {
                return item.insurance.counter_offer_of === props.item.id;
            }) ||
            cart.pois.some((item) => {
                return item.poi.counter_offer_of === props.item.id;
            }) ||
            cart.trains.some((item) => {
                return item.train.counter_offer_of === props.item.id;
            }) ||
            cart.transfers.some((item) => {
                return item.transfer.counter_offer_of === props.item.id;
            });
    }, [cart]);
    const checked = props.type === 'manual-accommodation' ?
        props.item.rooms?.every((item) => {
            return toBeBooked.findIndex((product) => {
                return product.id === item.id;
            }) >= 0;
        }) :
        toBeBooked?.findIndex((item) => {
            return item.id === props.item.id;
        }) >= 0;

    const color = getTableItemBackgroundColor(
        props.item,
        itinerary_type_details_data ?? { variant_name: [] }
    );
    const quotationCode = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;

    const onToggleForBooking = () => {
        dispatch({
            type: 'CART_TOGGLE_FOR_BOOKING',
            payload: {
                ...props.item,
                is_manual: props.type === 'manual' ||
                    props.type === 'manual-flight' ||
                    props.type === 'manual-accommodation'
            }
        });
    };

    const onClose = () => {
        setOpen(false);
    };

    return (
        <>
            <TableRow
                id={`product-${props.item.id}`}
                sx={(theme) => ({
                    "position": 'relative',
                    "verticalAlign": 'top',
                    "backgroundColor": hasCounterOffer ?
                        theme.palette.grey[400] :
                        color,
                    "cursor": 'pointer',
                    "height": 75,
                    "scrollMarginTop": 75,
                    [`.${tableCellClasses.body}`]: {
                        color: theme.palette.getContrastText(color)
                    },
                    [`&:hover .${tableCellClasses.body}`]: {
                        color: theme.palette.getContrastText('#eee')
                    },
                    '& > *': { borderBottom: 'unset !important' },
                    '& + .cart-construction-table-item-margin-row': {
                        backgroundColor: color
                    },
                    '&:hover + .cart-construction-table-item-margin-row': {
                        backgroundColor: theme.palette.action.hover
                    }
                })}
                onClick={() => setOpen(true)}
                hover
            >
                {
                    context.enableBooking &&
                    <TableCell sx={{ padding: 0, verticalAlign: 'middle' }}>
                        <Checkbox
                            checked={checked}
                            onClick={(event) => event.stopPropagation()}
                            onChange={onToggleForBooking}
                            icon={
                                <Box
                                    sx={{
                                        width: 20,
                                        height: 20,
                                        borderRadius: '3px',
                                        backgroundColor: '#fff'
                                    }}
                                >
                                    <CheckBoxOutlineBlankOutlined
                                        viewBox="3 3 18 18"
                                        sx={{
                                            width: '100%',
                                            height: '100%'
                                        }}
                                    />
                                </Box>
                            }
                            checkedIcon={
                                <Box
                                    sx={{
                                        width: 20,
                                        height: 20,
                                        borderRadius: '3px',
                                        backgroundColor: '#fff'
                                    }}
                                >
                                    <CheckBox
                                        viewBox="3 3 18 18"
                                        sx={{
                                            width: '100%',
                                            height: '100%',
                                            color: '#E6592F'
                                        }}
                                    />
                                </Box>
                            }
                            disabled={
                                !isProductBookable(
                                    props,
                                    user,
                                    trip?.stack_info ?? null,
                                    quotationCode
                                )
                            }
                        />
                    </TableCell>
                }
                {props.children}
            </TableRow>
            <CartConstructionProductEditModal
                open={open}
                onClose={onClose}
                {...props}
            />
        </>
    );
}
