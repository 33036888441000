//Dependencies
import clsx from 'clsx';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState, useRef, useContext } from 'react';
//Core
import { Chip } from '@mui/material';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';

//Icons
import { ErrorOutline } from '@mui/icons-material';
import Close from '@material-ui/icons/Close';
import ReportProblem from '@material-ui/icons/ReportProblemOutlined';
import Tooltip from '@material-ui/core/Tooltip';
//Components
import CartManualProductsNote from './CartManualProductsNote';
import CartManualProductsManualBooking from './CartManualProductsManualBooking';
import CartManualProductsManualCancellationRules from './CartManualProductsManualCancellationRules';
import CartDeleteModal from './CartDeleteModal';
//Common
import { ProviderContext } from './utils/providerContext';
import GetCookie from '../Common/Functions/GetCookie';
import CheckBeforeRequest from '../Common/CheckBeforeRequest';
import GetPricesId from '../Cart/Functions/Margin/GetPricesId';
//Actions
import { ManualProductFlightCart } from '../Itinerary/objects/manualProductFlightCart';
import { Itinerary } from '../Itinerary/objects/itinerary';
import { AppState } from '../../Reducers/Reducers';

type Props = {
    manual_product: ManualProductFlightCart
}

export function CartManualFlightCardDetails({ manual_product }: Props): JSX.Element {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const quotation_code = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;

    const user = useSelector((state: AppState) => state.user?.user);
    const language = useSelector((state: AppState) => state.header.tmp_language);
    const currency_list = useSelector((state: AppState) => state.base.currency_list);
    const itinerary_list = useSelector((state: AppState) => state.itinerary.itinerary_list);
    const step = useSelector((state: AppState) => state.cart.step);
    const [showNote, setShowNote] = useState(false);
    const [showManualCancellationRules, setShowManualCancellationRules] = useState(false);
    const [showManualBooking, setShowManualBooking] = useState(false);
    const [errors, setErrors] = useState<{
        found: {
            start: boolean,
            end: boolean
        },
        error: {
            start: Itinerary | null,
            end: Itinerary | null
        } | null
    } | null>(null);
    const [deleteModal, setDeleteModal] = useState(false);

    const manualBookingRef = useRef<HTMLDivElement>(null);
    const providerContext = useContext(ProviderContext);

    const scrollIntoManuaBookingView = () => {
        manualBookingRef.current?.scrollIntoView({ block: 'center', inline: 'center', behavior: 'smooth' });
    };

    const onManualBooking = () => {
        setShowManualBooking(!showManualBooking);
    };

    const onManualCancellationRules = () => {
        setShowManualCancellationRules(!showManualCancellationRules);
    };

    const onDeleteManualCancellationRules = () => {
        const { headers } = CheckBeforeRequest();
        axios({
            method: 'PATCH',
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/manual-products/${manual_product.id}/`,
            data: {
                no_refund: true,
                cancel_condition_text: null
            }
        }).then(response => {
            dispatch({ type: 'CART_EDIT_MANUAL_ITEM', payload: response.data });
        }).catch(error => {
            console.log(error);
            setShowManualCancellationRules(!showManualCancellationRules);
        });
    };

    const onAddNote = () => {
        setShowNote(!showNote);
    };

    const confirmTravelerModification = () => {
        const { headers } = CheckBeforeRequest();
        axios({
            method: 'PATCH',
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/manual-products/${manual_product.id}/`,
            data: {
                traveler_modification: null
            }
        }).then(response => {
            dispatch({ type: 'CART_EDIT_MANUAL_ITEM', payload: response.data });
        }).catch(error => {
            console.log(error);
        });
    };

    const onDeleteNote = () => {
        const { headers } = CheckBeforeRequest();
        axios({
            method: 'PATCH',
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/manual-products/${manual_product.id}/`,
            data: {
                custom_information: null
            }
        }).then(response => {
            dispatch({ type: 'CART_EDIT_MANUAL_ITEM', payload: response.data });
            enqueueSnackbar(t<string>('cart-material.note-deleted'), { variant: 'success' });
        }).catch(error => {
            console.log(error);
            enqueueSnackbar(t<string>('cart-material.note-not-deleted'), { variant: 'error' });
        });
    };

    const onDelete = () => {
        const { headers } = CheckBeforeRequest();
        axios({
            method: 'DELETE',
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/manual-products/${manual_product.id}/`
        }).then(() => {
            dispatch({ type: 'CART_MANUAL_PRODUCT_REMOVE_FROM_CART_BY_ID', payload: manual_product.id });
            enqueueSnackbar(t<string>('cart-material.manual-product-deleted'), { variant: 'success' });
        }).catch(error => {
            console.log(error);
            enqueueSnackbar(t<string>('cart-material.manual-product-not-deleted'), { variant: 'error' });
        });
    };

    const confirmRecalc = () => {
        const { headers } = CheckBeforeRequest();
        axios({
            method: 'PATCH',
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/manual-products/${manual_product.id}/`,
            data: {
                moved_with_recalc: false
            }
        }).then(response => {
            dispatch({ type: 'CART_EDIT_MANUAL_ITEM', payload: response.data });
        }).catch(error => {
            console.log(error);
        });
    };
    const confirmCustomInformationRecalc = () => {
        const { headers } = CheckBeforeRequest();
        axios({
            method: 'PATCH',
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/manual-products/${manual_product.id}/`,
            data: {
                moved_with_recalc_custom_information: false
            }
        }).then(response => {
            dispatch({ type: 'CART_EDIT_MANUAL_ITEM', payload: response.data });
        }).catch(error => {
            console.log(error);
        });
    };
    const confirmCancelConditionRecalc = () => {
        const { headers } = CheckBeforeRequest();
        axios({
            method: 'PATCH',
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/manual-products/${manual_product.id}/`,
            data: {
                moved_with_recalc_cancel_condition_text: false
            }
        }).then(response => {
            dispatch({ type: 'CART_EDIT_MANUAL_ITEM', payload: response.data });
        }).catch(error => {
            console.log(error);
        });
    };

    useEffect(() => {
        if (showManualBooking) {
            scrollIntoManuaBookingView();
        }
    });

    useEffect(() => {
        if (showNote) {
            const element = document.getElementById(`flight-${manual_product.id}-note`);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
            }
        }
    });

    useEffect(() => {
        if (showManualCancellationRules) {
            const element = document.getElementById(`flight-${manual_product.id}-manual-cancellation-rules`);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
            }
        }
    });

    useEffect(() => {
        let found = {
            start: false,
            end: true
        };
        let iti_error: {
            start: Itinerary | null,
            end: Itinerary | null,
        } = {
            start: null,
            end: null
        };
        itinerary_list.map(step => {
            if (step.destination && manual_product.start_destination && step.destination.id === manual_product.start_destination.id && (found.start === false || (found.start && iti_error.start))) {
                found.start = true;
                if (
                    !(
                        window.moment.utc(manual_product.flight_segment[0]?.start_date).isSameOrAfter(window.moment.utc(step.start_date), 'd') &&
                        window.moment.utc(manual_product.flight_segment[0]?.start_date).isSameOrBefore(window.moment.utc(step.end_date), 'd')
                    )
                ) {
                    iti_error.start = step;
                } else {
                    iti_error.start = null;
                }
            }
            /*
            if (step.destination && manual_product.end_destination && step.destination.id === manual_product.end_destination.id && (found.end === false || (found.end && iti_error.end))) {
                found.end = true;
                if (!(window.moment.utc(manual_product.end_date).isSameOrAfter(window.moment.utc(step.start_date), 'd') && window.moment.utc(manual_product.end_date).isSameOrBefore(window.moment.utc(step.end_date), 'd'))) {
                    iti_error.end = step;
                } else {
                    iti_error.end = null;
                }
            }
            */
        });
        setErrors({
            found: found,
            error: iti_error
        });
    }, [manual_product]);

    return (
        <Grid container item xs direction={"column"} spacing={2}>
            <Grid item>
                <Grid
                    container
                    direction={
                        (
                            step === 4 &&
                            user?.client_full?.type !== 2 &&
                            manual_product.is_bookable &&
                            manual_product.is_available &&
                            (manual_product.booking_status === null || manual_product.booking_status.status_booking === "ERROR") &&
                            ![20].includes(manual_product.product_type)
                        ) ?
                            'row' :
                            'row-reverse'
                    }
                    justify={'space-between'}
                    alignItems={'center'}
                >
                    <Grid item>
                        <Grid container direction={"row-reverse"} spacing={4}>
                            {
                                user?.client_full?.type !== 2 &&
                                <Grid item style={{ fontWeight: 'bold' }}>
                                    <Grid container direction={'column'} justify={'center'} alignItems={'center'}>
                                        {
                                            manual_product.traveler_modification &&
                                            <Grid item>
                                                <Chip
                                                    color="warning"
                                                    label={
                                                        manual_product.traveler_modification === 'SET_IN_TRIP' ?
                                                            t<string>('cart-material.add-to-cart-traveler') :
                                                            t<string>('cart-material.add-to-option-traveler')
                                                    }
                                                    size="small"
                                                    icon={<ErrorOutline color="inherit" />}
                                                    onDelete={confirmTravelerModification}
                                                />
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container item xs spacing={2}>
                <Grid container item spacing={2}>
                    <Grid item xs={6}>
                        {
                            manual_product.booking_status && manual_product.booking_status.status_booking === 'CANCELLED' && manual_product.cancellation_charged_amount &&
                            <Typography className={clsx(classes.booking, classes.error)}>
                                {t<string>('flight_search.cancel_fee')} :{' '}
                                {
                                    new Intl.NumberFormat(
                                        language,
                                        {
                                            style: 'currency',
                                            currency: currency_list.find((currency) => currency.id === manual_product.prices[GetPricesId(manual_product.prices, user?.client, user, true)]?.purchase_currency)?.iso_code,
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                        }).format(manual_product.cancellation_charged_amount)}
                            </Typography>
                        }
                    </Grid>
                </Grid>
                {
                    manual_product.booking_status && manual_product.booking_status.status_booking === 'UNAVAILABLE' &&
                    <Grid item xs={12}>
                        <Alert variant={'filled'} severity={'error'}>
                            {t<string>('cart-material.booking-unavailable')} : {manual_product.booking_status.item_reference}
                        </Alert>
                    </Grid>
                }
                {
                    manual_product.booking_status && manual_product.booking_status.status_booking === 'ERROR' && manual_product.booking_errors && manual_product.booking_errors.length > 0 &&
                    <Grid item xs={12}>
                        <Alert variant={'filled'} severity={'error'}>
                            <AlertTitle>{t<string>('cart-material.error')} :</AlertTitle>
                            {manual_product.booking_errors.map(error => <Typography key={`booking-error-${error.created_date}`}>{window.moment.utc(error.created_date).format('DD-MM-YYYY')} : {error.detail}</Typography>)}
                        </Alert>
                    </Grid>
                }
                {
                    errors && errors.found.start && errors.error?.start &&
                    <Grid item xs={12}>
                        <Alert variant={'filled'} severity={'error'}>
                            {t<string>('cart-material.itinerary-error-1')}{' '}
                            {errors.error.start.destination?.data?.name}{' '}
                            {t<string>('global.from')}{' '}
                            {window.moment.utc(errors.error?.start.start_date).format('DD/MM/YYYY')}{' '}
                            {t<string>('global.to')}{' '}
                            {window.moment.utc(errors.error?.start.end_date).format('DD/MM/YYYY')}.{' '}
                            {t<string>('cart-material.itinerary-error-2')}.
                        </Alert>
                    </Grid>
                }
                {
                    showManualBooking &&
                    <Grid item xs={12} style={{ marginTop: 15 }} ref={manualBookingRef}>
                        <hr />
                        <CartManualProductsManualBooking manual_product={manual_product} onManualBooking={onManualBooking} />
                    </Grid>
                }
                {
                    !providerContext.module &&
                    manual_product.moved_with_recalc &&
                    <Grid item xs={12} onClick={confirmRecalc} style={{ cursor: 'pointer' }}>
                        <Alert variant={'filled'} severity={'warning'} action={<Close className={classes.deleteCustomInformation} />}>
                            {t<string>('cart-material.recalc-move')}
                        </Alert>
                    </Grid>
                }
                {
                    !providerContext.module &&
                    <>
                        {
                            showNote ? (
                                <Grid id={`flight-${manual_product.id}-note`} item xs={12}>
                                    <hr />
                                    <CartManualProductsNote manual_product={manual_product} onAddNote={onAddNote} />
                                </Grid>
                            ) : manual_product.custom_information && (
                                <Grid item xs={12}>
                                    <hr />
                                    <Alert className={classes.cancelConditionAlert} variant={'filled'} severity={'info'} action={
                                        <Grid container direction={'column'} spacing={2} alignItems={'flex-end'}>
                                            <Grid item>
                                                <Grid container spacing={2} alignItems={'center'}>
                                                    <Grid item>
                                                        <Button className={classes.editCustomInformation} color={'inherit'} size={'small'} onClick={onAddNote}>
                                                            {t<string>('global.edit')}
                                                        </Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Close className={classes.deleteCustomInformation} onClick={onDeleteNote} />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item style={{ width: '100%' }}>
                                                {
                                                    manual_product.moved_with_recalc_custom_information &&
                                                    <Tooltip title={t<string>('cart-material.recalc-product-note')}>
                                                        <div className={clsx(classes.traverlerModification, classes.delete)} onClick={confirmCustomInformationRecalc} style={{ fontSize: 13, color: 'white', background: '#ff9800', border: '2px solid #ff9800' }}>
                                                            <Grid container alignItems={'center'} justify={'space-between'}>
                                                                <Grid item><ReportProblem style={{ verticalAlign: 'sub', fontSize: 18 }} /></Grid>
                                                                <Grid item>{t<string>('cart-material.recalc')}</Grid>
                                                                <Grid item><Close style={{ verticalAlign: 'middle', fontSize: 18 }} /></Grid>
                                                            </Grid>
                                                        </div>
                                                    </Tooltip>
                                                }
                                            </Grid>
                                        </Grid>
                                    }>
                                        <AlertTitle>{t<string>('cart-material.note')} :</AlertTitle>
                                        {manual_product.custom_information}
                                    </Alert>
                                </Grid>
                            )
                        }
                    </>
                }
                {
                    showManualCancellationRules ?
                        <Grid id={`flight-${manual_product.id}-manual-cancellation-rules`} item xs={12}>
                            <hr />
                            <CartManualProductsManualCancellationRules manual_product={manual_product} onManualCancellationRules={onManualCancellationRules} />
                        </Grid> : manual_product.cancel_condition_text &&
                        <Grid item xs={12}>
                            <hr />
                            <Alert className={classes.cancelConditionAlert} variant={'filled'} severity={'info'} action={
                                <Grid container direction={'column'} spacing={2} alignItems={'flex-end'}>
                                    <Grid item>
                                        <Grid container spacing={2} alignItems={'center'}>
                                            {
                                                !['verdie'].includes(quotation_code) &&
                                                <Grid item>
                                                    <Button className={classes.editCustomInformation} color={'inherit'} size={'small'} onClick={onManualCancellationRules}>
                                                        {t<string>('global.edit')}
                                                    </Button>
                                                </Grid>
                                            }
                                            <Grid item>
                                                <Close className={classes.deleteCustomInformation} onClick={onDeleteManualCancellationRules} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item style={{ width: '100%' }}>
                                        {
                                            manual_product.moved_with_recalc_cancel_condition_text &&
                                            <Tooltip title={t<string>('cart-material.recalc-product-cancel-condition')}>
                                                <div className={clsx(classes.traverlerModification, classes.delete)} onClick={confirmCancelConditionRecalc} style={{ fontSize: 13, color: 'white', background: '#ff9800', border: '2px solid #ff9800' }}>
                                                    <Grid container alignItems={'center'} justify={'space-between'}>
                                                        <Grid item><ReportProblem style={{ verticalAlign: 'sub', fontSize: 18 }} /></Grid>
                                                        <Grid item>{t<string>('cart-material.recalc')}</Grid>
                                                        <Grid item><Close style={{ verticalAlign: 'middle', fontSize: 18 }} /></Grid>
                                                    </Grid>
                                                </div>
                                            </Tooltip>
                                        }
                                    </Grid>
                                </Grid>
                            }>
                                <AlertTitle>
                                    {
                                        !['verdie'].includes(quotation_code) ?
                                            t<string>('cart-material.manual-cancellation-rules') :
                                            t<string>('cart-material.provider-cancellation-rules')
                                    } :{' '}
                                    {
                                        manual_product.no_refund ?
                                            t<string>('cart-material.not-cancellable') :
                                            t<string>('cart-material.cancellable-with-conditions')
                                    }
                                </AlertTitle>
                                {manual_product.cancel_condition_text === t<string>('cart-material.not-cancellable') ? '' : manual_product.cancel_condition_text}
                            </Alert>
                        </Grid>
                }
            </Grid>
            <CartDeleteModal deleteModal={deleteModal} setDeleteModal={setDeleteModal} callback={onDelete} />
        </Grid>
    );
}

const useStyles = makeStyles(() => ({
    smallText: {
        fontSize: 10
    },
    gridContainer: {
        border: '1px solid rgba(0,0,0,.1)',
        marginBottom: 8,
        borderRadius: 8
    },
    mainRow: {
        margin: 0,
        position: 'relative'
    },
    composition: {
        float: 'left',
        marginRight: 4
    },
    priceDetail: {
        textDecoration: 'underline',
        cursor: 'pointer'
    },
    red: {
        color: 'red'
    },
    green: {
        color: 'green'
    },
    firstRow: {
        backgroundColor: '#daf6ff',
        margin: 0
    },
    secondRow: {
        margin: 0
    },
    relative: {
        position: 'relative',
        textAlign: 'center'
    },
    noBaggage: {
        color: 'gray',
        fontSize: 48
    },
    baggage: {
        color: '#E6592F',
        fontSize: 48
    },
    baggageNumber: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        color: 'white'
    },
    moreDetailContainer: {
        paddingBottom: 8
    },
    moreDetail: {
        textDecoration: 'underline',
        cursor: 'pointer'
    },
    selector: {
        margin: '4px 0 8px 0'
    },
    variant: {
        margin: '16px 0 8px 0'
    },
    updateQuotationStatus: {
        textDecoration: 'underline',
        marginBottom: 4,
        cursor: 'pointer'
    },
    addNote: {
        textDecoration: 'underline',
        marginBottom: 4,
        cursor: 'pointer'
    },
    manualCancellationRules: {
        textDecoration: 'underline',
        marginBottom: 4,
        cursor: 'pointer'
    },
    editManualProduct: {
        textDecoration: 'underline',
        marginBottom: 4,
        cursor: 'pointer'
    },
    booking: {
        borderRadius: 16,
        fontWeight: 700,
        padding: '4px 12px',
        margin: '4px 0',
        textTransform: 'uppercase'
    },
    booked: {
        border: '2px solid #4caf50',
        color: '#4caf50'
    },
    pending: {
        border: '2px solid #ff9800',
        color: '#ff9800',
        cursor: 'pointer'
    },
    cached: {
        float: 'left',
        marginRight: 8,
        cursor: 'pointer'
    },
    cancelled: {
        border: '2px solid black',
        color: 'black'
    },
    error: {
        border: '2px solid #f44336',
        color: '#f44336'
    },
    editCustomInformation: {
        border: '1px solid'
    },
    deleteCustomInformation: {
        cursor: 'pointer',
        marginLeft: 8
    },
    notAvailable: {
        color: 'red'
    },
    delete: {
        position: 'absolute',
        right: 4,
        top: 4,
        cursor: 'pointer'
    },
    validateButton: {
        backgroundColor: '#E6592F',
        color: 'white'
    },
    price: {
        textAlign: 'right',
        fontSize: 32,
        fontWeight: 'bold'
    },
    catalogedPrice: {
        textAlign: 'right',
        cursor: 'pointer',
        textDecoration: 'underline',
        fontSize: 12
    },
    hideMargin: {
        textDecoration: 'underline',
        cursor: 'pointer'
    },
    hideProduct: {
        background: "#eeee",
        border: "solid 2px #E6592F",
        padding: 15,
        borderRadius: 10,
        marginBottom: 10
    },
    inQuotation: {
        border: "solid 2px #43D67D",
        padding: 15,
        borderRadius: 10,
        marginBottom: 10
    },
    isOption: {
        border: "solid 2px #eeee",
        padding: 15,
        borderRadius: 10,
        marginBottom: 10
    },
    progress: {
        color: green[500],
        position: 'absolute',
        top: "16%",
        left: "43%",
        marginLeft: -12
    },
    checkboxContainer: {
        position: 'relative',
        marginTop: '-5px'
    },
    cancelButton: {
        border: '1px solid',
        backgroundColor: 'white',
        color: '#E6592F'
    },
    infoCustomInformation: {
        border: '1px solid #FF9800',
        color: 'white',
        background: '#FF9800',
        marginRight: 5
    },
    traverlerModification: {
        borderRadius: 16,
        padding: '1px 6px'
    },
    cancelConditionAlert: {
        "whiteSpace": 'pre-wrap',
        "&& .MuiAlert-action": {
            minWidth: 140
        }
    }
}));
