import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { withRouter } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Alert,
    Button,
    FormControl,
    IconButton,
    InputAdornment,
    MenuItem,
    Paper,
    Select,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import { ArrowRightAlt, Close } from "@mui/icons-material";
import { findLast } from "lodash";
import { ProviderQuotationCurrencyPicker } from "./ProviderQuotationCurrencyPicker";
import { ProviderQuotationConfirmationModal } from "./ProviderQuotationConfirmationModal";
import { CartConstructionProductsTableItemProps } from "../CartMaterial/CartConstructionProductsTableItem";
import { LoadingBackDrop } from "../Common/LoadingBackdrop";
import { useShowError } from "../Utils/showError";
import { useGetPrice } from "../CartMaterial/utils/getPrice";
import { useProductCurrencyChange } from "./network/productCurrencyChange";
import { usePackagedProducts } from "../CartMaterial/utils/packagedProducts";
import { useCartProducts } from "../Itinerary/network/cartProducts";
import { useProviderQuotationProducts } from "./utils/providerQuotationProducts";
import { useCartProductUpdate } from "../CartMaterial/network/cartProductUpdate";
import { convertToCurrency } from "./utils/convertToCurrency";
import CheckResponse from "../Flight/FlightSelected/Functions/CheckResponse";
import { ProviderContext } from "../CartMaterial/utils/providerContext";
import { ProviderQuotationProductStatus } from "./objects/providerQuotationProductStatus";
import { AppState } from "../../Reducers/Reducers";

export enum QuotationTab {
    PRODUCTS,
    TEXTS
}

type Props = {
    priceType: 'manual' | 'products-total',
    statuses: {
        [key: number]: {
            id: number,
            isCustom: boolean,
            type: ReturnType<typeof useProviderQuotationProducts>[number]['type'],
            status: ProviderQuotationProductStatus | null,
            providerComment: string | null
        }
    },
    agentHasMadeModification: boolean
    onChangeTab: React.Dispatch<React.SetStateAction<QuotationTab>>,
    onChangePriceType: React.Dispatch<React.SetStateAction<'manual' | 'products-total'>>,
}

export const ProviderQuotationSideContent = withRouter<Props>(
    function ProviderQuotationSideContent(props): JSX.Element {
        const providerId = props.params.providerId;
        const tripId = props.params.tripId;
        const tripVersion = props.params.tripVersion;
        const stackNumber = parseInt(props.params.stackNumber ?? '-1');
        const dispatch = useDispatch();
        const { t } = useTranslation();
        const currencies = useSelector((state: AppState) => state.trip.currencies);
        const trip = useSelector((state: AppState) => state.trip.all_data);
        const tripData = useSelector((state: AppState) => state.trip.data_trip);
        const [terrestrialPrice, setTerrestrialPrice] = useState<
            {
                amount: number,
                currency: number | null
            } |
            null
        >(null);
        const [flightPrice, setFlightPrice] = useState<
            {
                amount: number,
                currency: number | null
            } |
            null
        >(null);
        const [totalPrice, setTotalPrice] = useState<
            {
                amount: number,
                currency: number | null
            } |
            null
        >(null);
        const [loading, setLoading] = useState(false);
        const terrestrialPriceCurrency = useMemo(() => {
            return currencies?.find((item) => {
                return item.id === terrestrialPrice?.currency;
            });
        }, [currencies, terrestrialPrice]);
        const flightPriceCurrency = useMemo(() => {
            return currencies?.find((item) => {
                return item.id === flightPrice?.currency;
            });
        }, [currencies, flightPrice]);
        const totalPriceCurrency = useMemo(() => {
            return currencies?.find((item) => {
                return item.id === totalPrice?.currency;
            });
        }, [currencies, totalPrice]);
        const showError = useShowError();
        const updateProductCurrency = useProductCurrencyChange({
            onSuccess(product, data) {
                if (tripData) {
                    switch (product.type) {
                        case 'flights': {
                            dispatch({
                                type: 'FLIGHT_EDIT_CART_BY_ID',
                                payload: CheckResponse([data], tripData.end_date)[0]!
                            });
                            break;
                        }
                        case 'cars': {
                            dispatch({
                                type: 'CAR_EDIT_CART_BY_ID',
                                payload: data
                            });
                            break;
                        }
                        case 'accommodations': {
                            dispatch({
                                type: 'ACCOMMODATION_EDIT_CART_BY_ID',
                                payload: data
                            });
                            break;
                        }
                        case 'transfers': {
                            dispatch({
                                type: 'TRANSFER_EDIT_CART_BY_ID',
                                payload: data
                            });
                            break;
                        }
                        case 'pois': {
                            dispatch({
                                type: 'POI_EDIT_CART_BY_ID',
                                payload: data
                            });
                            break;
                        }
                        default: {
                            dispatch({
                                type: 'CART_EDIT_MANUAL_ITEM',
                                payload: data
                            });
                            break;
                        }
                    }
                }
            },
            onError(error) {
                console.error(error);
                showError(error);
            }
        });
        const updateProduct = useCartProductUpdate({
            onError(error) {
                showError(error);
            }
        });
        const packagedProducts = usePackagedProducts();
        const products = useMemo(() => {
            const key = Object.keys(packagedProducts).find((key) => {
                return key.startsWith(stackNumber + '-');
            }) ?? '';
            return packagedProducts[key] ?? {
                accommodations: [],
                assistances: [],
                cars: [],
                flights: [],
                manualProducts: [],
                pois: [],
                transfers: []
            };
        }, [packagedProducts]);
        const rawProducts = useMemo(() => {
            return [
                ...products.accommodations.map((item): typeof item.accommodation & { type: keyof ReturnType<typeof useCartProducts> | 'manual' } => {
                    return {
                        type: item.type === 'normal' ?
                            'accommodations' :
                            'manual',
                        ...item.accommodation
                    }
                }),
                ...products.assistances.map((item): typeof item.assistance & { type: keyof ReturnType<typeof useCartProducts> | 'manual' } => {
                    return {
                        type: item.type === 'normal' ?
                            'assistances' :
                            'manual',
                        ...item.assistance
                    }
                }),
                ...products.cars.map((item): typeof item.car & { type: keyof ReturnType<typeof useCartProducts> | 'manual' } => {
                    return {
                        type: item.type === 'normal' ?
                            'cars' :
                            'manual',
                        ...item.car
                    }
                }),
                ...products.flights.map((item): typeof item.flight & { type: keyof ReturnType<typeof useCartProducts> | 'manual' } => {
                    return {
                        type: item.type === 'normal' ?
                            'flights' :
                            'manual',
                        ...item.flight
                    }
                }),
                ...products.manualProducts.map((item): typeof item & { type: keyof ReturnType<typeof useCartProducts> | 'manual' } => {
                    return {
                        type: 'manual',
                        ...item
                    }
                }),
                ...products.pois.map((item): typeof item.poi & { type: keyof ReturnType<typeof useCartProducts> | 'manual' } => {
                    return {
                        type: item.type === 'normal' ?
                            'pois' :
                            'manual',
                        ...item.poi
                    }
                }),
                ...products.transfers.map((item): typeof item.transfer & { type: keyof ReturnType<typeof useCartProducts> | 'manual' } => {
                    return {
                        type: item.type === 'normal' ?
                            'transfers' :
                            'manual',
                        ...item.transfer
                    }
                })
            ];
        }, [products]);
        const isInAdditionMode = useMemo(() => {
            return rawProducts.every((product) => {
                return !product.is_stack_price;
            });
        }, [rawProducts]);
        const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
        const getPrice = useGetPrice();
        const context = useContext(ProviderContext);
        const hasFlight = products.flights.length > 0;
        const counterOffersProductIds = rawProducts.map((item) => {
            return item.counter_offer_of;
        }).filter((item): item is NonNullable<typeof item> => !!item);

        const onAutocomputePrice = useCallback(() => {
            setTotalPrice((state) => {
                const totalAmountCurrency = currencies?.find((item) => {
                    return item.id === state?.currency;
                });
                const total = rawProducts.filter((product) => {
                    const itemData = props.statuses[product.id];
                    return itemData?.status?.provider !== 'refused' &&
                        !product.is_optional &&
                        !counterOffersProductIds.includes(product.id);
                }).reduce((prev, current) => {
                    const price = getPrice(current.prices);
                    const amount = totalAmountCurrency && price.purchaseCurrency ?
                        convertToCurrency({
                            amount: price.purchaseCost,
                            from: price.purchaseCurrency,
                            to: totalAmountCurrency
                        }) :
                        0;
                    return prev + amount;
                }, 0);
                return {
                    amount: Math.round(total * 100) / 100,
                    currency: getPrice(rawProducts[0]?.prices ?? []).purchaseCurrency?.id ?? null
                };
            });
        }, [rawProducts]);

        const onChangeCurrency = (type: 'terrestrial' | 'flight' | 'total', currency: number | null) => {
            switch (type) {
                case 'terrestrial': {
                    setTerrestrialPrice((state) => {
                        if (state) {
                            return {
                                ...state,
                                currency
                            }
                        }
                        return {
                            amount: 0,
                            currency
                        };
                    });
                    break;
                }
                case 'flight': {
                    setFlightPrice((state) => {
                        if (state) {
                            return {
                                ...state,
                                currency
                            }
                        }
                        return {
                            amount: 0,
                            currency
                        };
                    });
                    break;
                }
                case 'total': {
                    setTotalPrice((state) => {
                        if (state) {
                            return {
                                ...state,
                                currency
                            }
                        }
                        return {
                            amount: 0,
                            currency
                        };
                    });
                    break;
                }
            }
        };

        const onChangeAmount = (type: 'terrestrial' | 'flight' | 'total', value: string) => {
            const amount = parseFloat(value.replace(/[^0-9.]+/g, ''));
            switch (type) {
                case 'terrestrial': {
                    setTerrestrialPrice((state) => {
                        if (state) {
                            return {
                                ...state,
                                amount: isNaN(amount) ? 0 : amount
                            }
                        }
                        return {
                            amount: isNaN(amount) ? 0 : amount,
                            currency: 47
                        };
                    });
                    break;
                }
                case 'flight': {
                    setFlightPrice((state) => {
                        if (state) {
                            return {
                                ...state,
                                amount: isNaN(amount) ? 0 : amount
                            }
                        }
                        return {
                            amount: isNaN(amount) ? 0 : amount,
                            currency: 47
                        };
                    });
                    break;
                }
                case 'total': {
                    setTotalPrice((state) => {
                        if (state) {
                            return {
                                ...state,
                                amount: isNaN(amount) ? 0 : amount
                            }
                        }
                        return {
                            amount: isNaN(amount) ? 0 : amount,
                            currency: 47
                        };
                    });
                    break;
                }
            }
        };

        const onResetPrices = () => {
            if (tripData) {
                const terrestrialPrice = props.priceType === 'manual' ?
                    findLast(
                        tripData.prices_terrestrial?.filter((item) => {
                            return item.stack_number === stackNumber;
                        }) ?? [],
                        (item) => item.master_price
                    ) :
                    undefined;
                const terrestrialPriceAmount = parseFloat(terrestrialPrice?.purchase_price ?? '0');
                setTerrestrialPrice(
                    terrestrialPrice ?
                        {
                            amount: isNaN(terrestrialPriceAmount) ? 0 : terrestrialPriceAmount,
                            currency: terrestrialPrice?.purchase_currency ?? 47
                        } :
                        null
                );
                const flightPrice = props.priceType === 'manual' ?
                    findLast(
                        tripData.prices_flight?.filter((item) => {
                            return item.stack_number === stackNumber;
                        }) ?? [],
                        (item) => item.master_price
                    ) :
                    undefined;
                const flightPriceAmount = parseFloat(flightPrice?.purchase_price ?? '0');
                setFlightPrice(
                    flightPrice ?
                        {
                            amount: isNaN(flightPriceAmount) ? 0 : flightPriceAmount,
                            currency: flightPrice?.purchase_currency ?? 47
                        } :
                        null
                );
                if (props.priceType === 'products-total') {
                    onAutocomputePrice();
                }
            }
        }

        const onChangeProductsCurrency = async (currency: number | null) => {
            const currencyData = currencies?.find((item) => {
                return item.id === currency;
            });
            if (currency && context.module === 'quotation' && context.tripId && context.version && currency) {
                setLoading(true);
                const promises: Promise<void>[] = [];
                for (const product of rawProducts) {
                    const masterPrice = product.prices.find((item) => {
                        return item.master_price;
                    });
                    const price = getPrice(product.prices);
                    const amount = currencyData && price.purchaseCurrency ?
                        convertToCurrency({
                            amount: price.purchaseCost,
                            from: price.purchaseCurrency,
                            to: currencyData
                        }) :
                        0;
                    if (masterPrice?.id) {
                        promises.push(
                            updateProductCurrency(
                                {
                                    tripId: context.tripId,
                                    version: context.version,
                                    tripToken: context.tripToken ?? ''
                                },
                                {
                                    id: product.id,
                                    type: product.type,
                                    isCustom: product.is_custom,
                                    price: parseFloat(amount.toFixed(2)),
                                    currency,
                                    masterPriceId: masterPrice.id,
                                    customRate: masterPrice.custom_rate,
                                    customRateType: (masterPrice.custom_rate_type as 'PER' | 'FIX') ?? 'PER',
                                    customValue: masterPrice.custom_value ?
                                        parseFloat(masterPrice.custom_value) :
                                        0
                                }
                            )
                        );
                    }
                }
                await Promise.all(promises);
                setLoading(false);
            }
        };

        const onDiscardAgentModificationAlert = async () => {
            if (context.module === 'quotation') {
                setLoading(true);
                try {
                    await Promise.all(
                        rawProducts.filter((product) => {
                            return product.agent_remodified;
                        }).map((product) => {
                            const productData = props.statuses[product.id];
                            let type: CartConstructionProductsTableItemProps['type'] | null = null;
        
                            switch (product?.type) {
                                case 'accommodations': {
                                    type = 'accommodation';
                                    break;
                                }
                                case 'assistances':
                                case 'insurances': {
                                    type = 'assistance';
                                    break;
                                }
                                case 'cars': {
                                    type = 'car';
                                    break;
                                }
                                case 'flights': {
                                    type = 'flight';
                                    break;
                                }
                                case 'pois': {
                                    type = 'poi';
                                    break;
                                }
                                case 'transfers': {
                                    type = 'transfer';
                                    break;
                                }
                                case 'cruises':
                                case 'ferries':
                                case 'trains':
                                case 'manual': {
                                    type = 'manual';
                                    break;
                                }
                            }
        
                            if (productData) {
                                return updateProduct(
                                    context.version,
                                    {
                                        id: product.id,
                                        type,
                                        isCustom: productData.isCustom,
                                        roomIds: productData.type === 'accommodations' ?
                                            products.accommodations.find((item) => {
                                                return item.type === 'normal' && item.accommodation.id === product.id;
                                            })?.accommodation.rooms?.map((item) => item.id) :
                                            undefined
                                    },
                                    {
                                        agent_remodified: false
                                    }
                                );
                            }
                        })
                    );
                } finally {
                    setLoading(false);
                }
            }
        }

        useEffect(() => {
            onResetPrices();
        }, [tripData, stackNumber]);

        useEffect(() => {
            onResetPrices();
        }, [
            props.priceType,
            totalPrice?.currency,
            onAutocomputePrice
        ]);

        useEffect(() => {
            onAutocomputePrice();
        }, [
            rawProducts,
            props.statuses,
            rawProducts
        ]);

        useEffect(() => {
            if (!isInAdditionMode) {
                props.onChangePriceType('manual');
            }
        }, [isInAdditionMode]);

        return (
            <>
                <Paper
                    elevation={1}
                    sx={{
                        paddingTop: 2.5,
                        paddingBottom: 3.5,
                        paddingLeft: 3.5,
                        paddingRight: 3.5,
                        borderRadius: '4px'
                    }}
                >
                    <Typography
                        variant="h6"
                        fontWeight="bold"
                        component="div"
                        sx={{ marginBottom: 2.5 }}
                    >
                        {t('cart-material.provider-quotation-package-price')}
                    </Typography>
                    {
                        props.agentHasMadeModification &&
                        <Alert
                            severity="info"
                            action={
                                <IconButton size="small" onClick={onDiscardAgentModificationAlert}>
                                    <Close fontSize="inherit" />
                                </IconButton>
                            }
                            sx={{ marginBottom: 2.5 }}
                        >
                            {t('cart-material.agent-made-modification-alert')}
                        </Alert>
                    }
                    <FormControl size="small" fullWidth>
                        <Select
                            value={props.priceType}
                            onChange={(event) => props.onChangePriceType(event.target.value as typeof props.priceType)}
                        >
                            <MenuItem value="products-total">
                                {t('cart-material.price-type-products-total')}
                            </MenuItem>
                            <MenuItem value="manual">
                                {t('cart-material.price-type-package-total')}
                            </MenuItem>
                        </Select>
                    </FormControl>
                    {
                        props.priceType === 'manual' &&
                        <>
                            <Alert severity="info" sx={{ marginY: 1.5 }}>
                                {t('cart-material.provider-quotation-package-price-group-title')}
                            </Alert>
                            <Stack direction="row" spacing={1} sx={{ marginBottom: 2 }}>
                                <TextField
                                    type="number"
                                    size="small"
                                    label={t('cart-material.provider-quotation-terrestrial-selling-price')}
                                    value={terrestrialPrice?.amount ?? 0}
                                    onChange={(event) => onChangeAmount('terrestrial', event.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {terrestrialPriceCurrency?.symbol}
                                            </InputAdornment>
                                        )
                                    }}
                                    fullWidth
                                />
                                <ProviderQuotationCurrencyPicker
                                    value={terrestrialPrice?.currency ?? 47}
                                    onChange={(currency) => onChangeCurrency('terrestrial', currency)}
                                />
                            </Stack>
                            {
                                hasFlight &&
                                <Stack direction="row" spacing={1} sx={{ marginBottom: 2 }}>
                                    <TextField
                                        type="number"
                                        size="small"
                                        label={t('cart-material.provider-quotation-flight-selling-price')}
                                        value={flightPrice?.amount ?? 0}
                                        onChange={(event) => onChangeAmount('flight', event.target.value)}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    {flightPriceCurrency?.symbol}
                                                </InputAdornment>
                                            )
                                        }}
                                        fullWidth
                                    />
                                    <ProviderQuotationCurrencyPicker
                                        value={flightPrice?.currency ?? 47}
                                        onChange={(currency) => onChangeCurrency('flight', currency)}
                                    />
                                </Stack>
                            }
                        </>
                    }
                    {
                        props.priceType === 'products-total' &&
                        <>
                            <Alert severity="info" sx={{ marginY: 1.5 }}>
                                {t('cart-material.provider-quotation-package-price-total-title')}
                            </Alert>
                            <Stack direction="row" spacing={1}>
                                <TextField
                                    type="number"
                                    size="small"
                                    label={t('cart-material.provider-quotation-total-selling-price')}
                                    value={totalPrice?.amount ?? 0}
                                    onChange={(event) => onChangeAmount('total', event.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {totalPriceCurrency?.symbol}
                                            </InputAdornment>
                                        )
                                    }}
                                    disabled
                                    fullWidth
                                />
                                <ProviderQuotationCurrencyPicker
                                    value={totalPrice?.currency ?? 47}
                                    onChange={onChangeProductsCurrency}
                                />
                            </Stack>
                        </>
                    }
                </Paper>
                {
                    trip?.provider_allowed_to_edit_byday &&
                    <Button
                        variant="outlined"
                        sx={{ marginTop: 2 }}
                        onClick={() => props.onChangeTab(QuotationTab.TEXTS)}
                        fullWidth
                    >
                        {t('cart-material.provider-edit-quotation-text')}
                    </Button>
                }
                <Button
                    variant="contained"
                    endIcon={<ArrowRightAlt />}
                    sx={{ marginTop: 2 }}
                    onClick={() => setOpenConfirmationModal(true)}
                    fullWidth
                >
                    {t('cart-material.provider-quotation-continue')}
                </Button>
                <ProviderQuotationConfirmationModal
                    priceType={props.priceType}
                    tripId={tripId}
                    version={tripVersion}
                    providerId={providerId}
                    stackNumber={props.params.stackNumber}
                    prices={{
                        terrestrial: terrestrialPrice,
                        flight: hasFlight ? flightPrice : undefined,
                        total: totalPrice
                    }}
                    open={openConfirmationModal}
                    statuses={props.statuses}
                    onClose={() => setOpenConfirmationModal(false)}
                />
                <LoadingBackDrop open={loading} />
            </>
        );
    }
);