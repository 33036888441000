import { useDispatch, useSelector } from "react-redux";
import axios, { AxiosResponse } from "axios";
import { differenceBy, flatten, uniqBy } from "lodash";
import { useSendProviderQuotationDemand } from "../utils/sendProviderQuotationDemand";
import { makeProductEditRequest } from "../utils/editProductRequests";
import CheckResponse from "../../Flight/FlightSelected/Functions/CheckResponse";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import { PackageInput } from "../CartPackagePackagesList";
import { TripVersion } from "../../Menu/MaterialTripList/objects/tripVersion";
import { CartPackageDraggableItemProps } from "../CartPackageDraggableItem";
import { CartConstructionProductsTableItemProps } from "../CartConstructionProductsTableItem";
import { AppState } from "../../../Reducers/Reducers";
import { isProductPackaged } from "../utils/isProductPackaged";

type UpdatedProducts = {
    meta: CartPackageDraggableItemProps,
    data: unknown
}[]

type Callback = (
    tripVersion: number,
    packages: PackageInput,
    removedProducts: CartPackageDraggableItemProps[]
) => Promise<void>

type Options = Partial<{
    onTrigger: () => void,
    onSuccess: (products: UpdatedProducts) => void,
    onError: (error: Error) => void,
    onFinally: () => void
}>

export function useCartPackagesUpdate(options: Options): Callback {
    const dispatch = useDispatch();
    const trip = useSelector((state: AppState) => state.trip.data_trip);
    const tripId = useSelector((state: AppState) => state.trip.trip_id);
    const sendQuotationMail = useSendProviderQuotationDemand({});

    return async (version, packages, removedProducts) => {
        if (tripId) {
            try {
                if (options.onTrigger) {
                    options.onTrigger();
                }
                const newPackages = uniqBy(
                    Object.keys(packages).map((key) => {
                        const stackNumber = key.split('-')[0] ? parseInt(key.split('-')[0]!) : null;
                        const stackInfoId = key.split('-')[1] ? parseInt(key.split('-')[1]!) : null;
                        return {
                            stackNumber: stackNumber,
                            stackInfoId: stackInfoId,
                            provider: packages[key]!.provider?.provider?.id ?? -1
                        };
                    }).filter((item) => {
                        return item.provider >= 0 &&
                            !isProductPackaged({
                                product: {
                                    stack_number: item.stackNumber,
                                    stack_info_id: item.stackInfoId
                                },
                                stackInfos: trip?.stack_info ?? null,
                                connected: true
                            });
                    }),
                    (item) => `${item.stackNumber}-${item.provider}`
                );
                const oldPackages = uniqBy(
                    (
                        trip?.prices_terrestrial?.map((item) => {
                            return {
                                stackNumber: item.stack_number,
                                stackInfoId: item.stack_info_id,
                                provider: item.provider
                            };
                        }) ?? []
                    ).concat(
                        trip?.prices_flight?.map((item) => {
                            return {
                                stackNumber: item.stack_number,
                                stackInfoId: item.stack_info_id,
                                provider: item.provider
                            };
                        }) ?? []
                    ).filter((item) => {
                        return !isProductPackaged({
                            product: {
                                stack_number: item.stackNumber,
                                stack_info_id: item.stackInfoId
                            },
                            stackInfos: trip?.stack_info ?? null,
                            connected: true
                        });
                    }),
                    (item) => `${item.stackNumber}-${item.provider}`
                );
                const toBeRemovedPackages = differenceBy(
                    oldPackages,
                    newPackages,
                    (item) => `${item.stackNumber}-${item.stackInfoId}-${item.provider}`
                );
                const result = await makeRequest({
                    tripId,
                    version,
                    packages,
                    removedPackages: toBeRemovedPackages,
                    removedProducts,
                    sendQuotationMail
                });
                for (const product of result.products) {
                    switch (product.meta.type) {
                        case 'flights': {
                            dispatch({
                                type: 'FLIGHT_EDIT_CART_BY_ID',
                                payload: CheckResponse([product.data], trip?.end_date)[0]!
                            });
                            break;
                        }
                        case 'cars': {
                            dispatch({
                                type: 'CAR_EDIT_CART_BY_ID',
                                payload: product.data
                            });
                            break;
                        }
                        case 'accommodations': {
                            dispatch({
                                type: 'ACCOMMODATION_EDIT_CART_BY_ID',
                                payload: product.data
                            });
                            break;
                        }
                        case 'transfers': {
                            dispatch({
                                type: 'TRANSFER_EDIT_CART_BY_ID',
                                payload: product.data
                            });
                            break;
                        }
                        case 'pois':
                        case 'activities': {
                            dispatch({
                                type: 'POI_EDIT_CART_BY_ID',
                                payload: product.data
                            });
                            break;
                        }
                        case 'assistances':
                        case 'insurances': {
                            dispatch({
                                type: 'CART_ASSISTANCE_EDIT_BY_ID',
                                payload: product.data
                            });
                            break;
                        }
                        default: {
                            dispatch({
                                type: 'CART_EDIT_MANUAL_ITEM',
                                payload: product.data
                            });
                            break;
                        }
                    }
                }
                if (result.trip) {
                    dispatch({
                        type: 'TRIP_SET_DATA_TRIP',
                        payload: {
                            data_trip: result.trip
                        }
                    });
                }
                if (options.onSuccess) {
                    options.onSuccess(result.products);
                }
            } catch (error: any) {
                if (options.onError) {
                    options.onError(error);
                }
            } finally {
                if (options.onFinally) {
                    options.onFinally();
                }
            }
        }
    };
}

type RequestOptions = {
    tripId: number,
    version: number,
    packages: PackageInput,
    removedPackages: { stackNumber: number | null, stackInfoId: number | null, provider: number }[],
    removedProducts: CartPackageDraggableItemProps[],
    sendQuotationMail: ReturnType<typeof useSendProviderQuotationDemand>
}

async function makeRequest(options: RequestOptions): Promise<{
    trip: TripVersion | undefined,
    products: UpdatedProducts
}> {
    const tripUpdate: TripVersion[] = [];

    for (const item of options.removedPackages) {
        const response = await makeDeletePackagePriceRequest({
            tripId: options.tripId,
            version: options.version,
            package: item
        });
        if (response?.data) {
            tripUpdate.push(response.data);
        }
    }
    
    const response = await makeDeletePackageStackPriceRequest({
        tripId: options.tripId,
        version: options.version
    });
    if (response?.data) {
        tripUpdate.push(response.data);
    }

    for (const key of Object.keys(options.packages)) {
        const stackNumber = key.split('-')[0] ? parseInt(key.split('-')[0]!) : null;
        const stackInfoId = key.split('-')[1] ? parseInt(key.split('-')[1]!) : null;
        const packageContent = options.packages[key];
        const priceResponse = await makeUpdatePriceRequest({
            tripId: options.tripId,
            version: options.version,
            stackNumber,
            stackInfoId,
            package: packageContent
        });
        if (priceResponse?.data) {
            tripUpdate.push(priceResponse.data);
        }
    }

    const productsUpdate = Object.keys(options.packages).map(async (key) => {
        const stackNumber = key.split('-')[0] ? parseInt(key.split('-')[0]!) : null;
        const stackInfoId = key.split('-')[1] ? parseInt(key.split('-')[1]!) : null;
        const packageContent = options.packages[key];
        const productsResponses = flatten(
            makeUpdateProductsRequest({
                tripId: options.tripId,
                version: options.version,
                stackNumber,
                stackInfoId,
                package: packageContent,
            }).filter((item): item is NonNullable<typeof item> => {
                return !!item;
            })
        );

        if (
            stackNumber &&
            packageContent?.provider?.provider.id &&
            packageContent?.products.some((item) => {
                return item.agent_accepted && item.provider_accepted;
            })
        ) {
            options.sendQuotationMail(
                options.version,
                [
                    {
                        stackNumber,
                        providerId: packageContent.provider?.provider.id
                    }
                ],
                false
            );
        }

        const productsResponsesResult: Awaited<typeof productsResponses[number]>[] = [];

        for (const response of productsResponses) {
            productsResponsesResult.push(await response);
        }

        return productsResponsesResult;
    });
    const remove = flatten(
        makeRemoveProductsRequest({
            tripId: options.tripId,
            version: options.version,
            removedProducts: options.removedProducts
        }).filter((item): item is NonNullable<typeof item> => {
            return !!item;
        })
    );
    const result: UpdatedProducts = [];

    for (const response of productsUpdate) {
        const products = flatten(await response).filter((item): item is NonNullable<typeof item> => {
            return !!item;
        });
        for (const item of products) {
            result.push({ meta: item.product, data: item.updatedProduct });
        }
    }

    for (const response of remove) {
        const item = await response;
        if (item) {
            result.push({ meta: item.product, data: item.updatedProduct });
        }
    }

    return {
        trip: tripUpdate[tripUpdate.length - 1],
        products: result
    };
}

type UpdatePriceRequestOptions = {
    tripId: number,
    version: number,
    stackNumber: number | null,
    stackInfoId: number | null,
    package: PackageInput[number] | undefined
}

async function makeUpdatePriceRequest(options: UpdatePriceRequestOptions): Promise<AxiosResponse<TripVersion> | null> {
    const { pass_check, headers } = CheckBeforeRequest();

    if (pass_check && options.package?.provider) {
        return axios.patch<TripVersion>(
            `${API_HREF}client/${window.id_owner}/trip/${options.tripId}/versions/${options.version}/`,
            {
                flight_purchase_price: options.package.flightPrice.price.toString(),
                flight_purchase_currency: options.package.flightPrice.currency,
                flight_custom_rate_type: options.package.flightPrice.marginType,
                flight_custom_value: options.package.flightPrice.margin.toString(),
                flight_stack_number: options.stackNumber,
                flight_stack_info_id: options.stackInfoId,
                flight_provider_id: options.package.provider.provider.id,
                terrestrial_purchase_price: options.package.terrestrialPrice.price.toString(),
                terrestrial_purchase_currency: options.package.terrestrialPrice.currency,
                terrestrial_custom_rate_type: options.package.terrestrialPrice.marginType,
                terrestrial_custom_value: options.package.terrestrialPrice.margin.toString(),
                terrestrial_stack_number: options.stackNumber,
                terrestrial_stack_info_id: options.stackInfoId,
                terrestrial_provider_id: options.package.provider.provider.id
            },
            { headers }
        );
    }

    return null;
}

type DeletePriceRequestOptions = {
    tripId: number,
    version: number,
    package: {
        stackNumber: number | null,
        stackInfoId: number | null,
        provider: number
    },
}

export async function makeDeletePackagePriceRequest(options: DeletePriceRequestOptions): Promise<AxiosResponse<TripVersion> | null> {
    const { pass_check, headers } = CheckBeforeRequest();

    if (pass_check) {
        return axios.patch<TripVersion>(
            `${API_HREF}client/${window.id_owner}/trip/${options.tripId}/versions/${options.version}/`,
            {
                flight_stack_number: options.package.stackNumber,
                flight_stack_info_id: options.package.stackInfoId,
                flight_provider_id: options.package.provider,
                flight_price_delete: true,
                terrestrial_stack_number: options.package.stackNumber,
                terrestrial_stack_info_id: options.package.stackInfoId,
                terrestrial_provider_id: options.package.provider,
                terrestrial_price_delete: true
            },
            { headers }
        );
    }

    return null;
}

type DeleteStackPriceRequestOptions = {
    tripId: number,
    version: number,
}

export async function makeDeletePackageStackPriceRequest(options: DeleteStackPriceRequestOptions): Promise<AxiosResponse<TripVersion> | null> {
    const { pass_check, headers } = CheckBeforeRequest();

    if (pass_check) {
        return axios.patch<TripVersion>(
            `${API_HREF}client/${window.id_owner}/trip/${options.tripId}/versions/${options.version}/`,
            { stack_price_delete: true },
            { headers }
        );
    }

    return null;
}

type RemoveProductsRequestOptions = {
    tripId: number,
    version: number,
    removedProducts: CartPackageDraggableItemProps[]
}

function makeRemoveProductsRequest(options: RemoveProductsRequestOptions) {
    return options.removedProducts.map((product) => {
        if (product.type === 'accommodations') {
            return product.roomIds?.map((id) => {
                return makeProductUpdateRequest({
                    tripId: options.tripId,
                    version: options.version,
                    product: {
                        ...product,
                        id
                    },
                    data: {
                        stack_number: null,
                        stack_info_id: null
                    }
                });
            });
        }
        return [
            makeProductUpdateRequest({
                tripId: options.tripId,
                version: options.version,
                product,
                data: {
                    stack_number: null,
                    stack_info_id: null
                }
            })
        ];
    });
}

type UpdateProductsRequestOptions = {
    tripId: number,
    version: number,
    stackNumber: number | null,
    stackInfoId: number | null,
    package: PackageInput[number] | undefined
}

function makeUpdateProductsRequest(options: UpdateProductsRequestOptions) {
    return options.package?.products.map((product) => {
        if (product.type === 'accommodations') {
            return product.roomIds?.map((id) => {
                return makeProductUpdateRequest({
                    tripId: options.tripId,
                    version: options.version,
                    product: {
                        ...product,
                        id
                    },
                    data: {
                        stack_number: options.stackNumber,
                        stack_info_id: options.stackInfoId,
                        is_stack_price: true,
                        agent_remodified: product.agent_accepted && product.provider_accepted
                    }
                });
            });
        }
        return [
            makeProductUpdateRequest({
                tripId: options.tripId,
                version: options.version,
                product,
                data: {
                    stack_number: options.stackNumber,
                    stack_info_id: options.stackInfoId,
                    is_stack_price: true,
                    agent_remodified: product.agent_accepted && product.provider_accepted
                }
            })
        ];
    }) ?? [];
}

type ProductUpdateRequestOptions<T> = {
    tripId: number,
    version: number,
    product: CartPackageDraggableItemProps,
    data: T
}

async function makeProductUpdateRequest<T>(options: ProductUpdateRequestOptions<T>) {
    let type: CartConstructionProductsTableItemProps['type'] = 'manual';

    switch (options.product.type) {
        case 'accommodations': {
            type = 'accommodation';
            break;
        }
        case 'activities':
        case 'pois': {
            type = 'poi';
            break;
        }
        case 'cars': {
            type = 'car';
            break;
        }
        case 'assistances':
        case 'insurances': {
            type = 'assistance';
            break;
        }
        case 'transfers': {
            type = 'transfer';
            break;
        }
        case 'flights': {
            type = 'flight';
            break;
        }
    }

    return {
        product: options.product,
        updatedProduct: await makeProductEditRequest({
            tripId: options.tripId,
            version: options.version,
            product: {
                type,
                id: options.product.id,
                isCustom: options.product.isCustom
            },
            data: options.data
        })
    };
}
