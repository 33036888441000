import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme, useMediaQuery } from "@material-ui/core";

import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import LuggageByPax from "../FlightSearch/LuggageByPax";
import SelectLuggage from "./SelectLuggage";
import SelectLuggageModal from "./SelectLuggageModal";

import CountAgePaxType from "../Functions/CountAgePaxType";

import { RemovePassengerBaggage } from "../../../Actions/FlightSearch";

const main_color = JSON.parse(localStorage.getItem("config")).main_color;

const useStyles = makeStyles((theme) => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: '#0000008A',
        letterSpacing: 1.25
    },
    fontWeight900: {
        fontWeight: 900
    },
    fontSize10: {
        fontSize: 10
    },
    fontSize12: {
        fontSize: 12
    },
    fontSize16: {
        fontSize: 16
    },
    fontSize20: {
        fontSize: 20
    },
    uppercase: {
        textTransform: "uppercase"
    },
    textCenter: {
        textAlign: "center"
    },
    genericPadding: {
        padding: "8px 16px"
    },
    spacer: {
        padding: "8px 0px"
    },
    selectSpacer: {
        padding: 16
    },
    borderTableCell:{
        borderBottom: "none"
    },
    validateButton: {
        backgroundColor: `${main_color}`,
        color: "white",
        padding: "8px 60px",
        '&:hover': {
            backgroundColor: `${main_color}CA`,
        }
    },
    pointer: {
        cursor: "pointer"
    }
}))

const AddLuggage = (({ flight, setFlight, travelers, luggageOptions, flightStep, setFlightStep, seats_by_segment }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const isHandHeld = useMediaQuery(theme.breakpoints.down(960));
    const isTablet = useMediaQuery(theme.breakpoints.between(600, 960));
    const isMobile = useMediaQuery(theme.breakpoints.down(600));

    const end_date = useSelector(store => store.trip.end_date);
    const airports = useSelector(store => store.flight_search.airports);
    const stations = useSelector(store => store.flight_search.stations);
    const flight_list_render = useSelector(store => store.flight_search.flight_list_render);
    const selected_flight = useSelector(store => store.flight_search.selected_flight);

    const travelers_obj = CountAgePaxType(travelers, end_date);

    const [carryOnLuggages, setCarryOnLuggages] = useState(0);
    const [checkLuggages, setCheckLuggages] = useState(0);
    const [tempPaidOptions, setTempPaidOptions] = useState([]);
    const [paidOptionsMaxLength, setPaidOptionsMaxLength] = useState(1);
    const [optionsLevels, setOptionsLevels] = useState([]);
    const [openLuggageModal, setOpenLuggageModal] = useState([]);
    console.log("flight : ", flight);
    useEffect(() => {
        let temp_carry_on = 0;
        let temp_check = 0
        if (
            flight.options &&
            flight.options.fares &&
            flight.options.fares.length > 0 &&
            flight.options.fares[0].option_category &&
            flight.options.fares[0].option_category.length > 0 &&
            flight.options.fares[0].option_category[0].option_segment.length > 0 &&
            flight.options.fares[0].option_category[0].option_segment[0].option.length > 0
        ) {
            let key_optional_service = flight.options.fares[0].option_category[0].option_segment[0].option[0].key_optional_service;
            flight.options.fares[0].option_category[0].option_segment[0].option.map((option) => {
                if (option.key_optional_service === key_optional_service && option.included && option.available && option.option_type === "Baggage") {
                    if (option.baggage_is_carry_on) {
                        temp_carry_on += option.baggage_quantity;
                    } else {
                        temp_check += option.baggage_quantity;
                    }
                }
            })
        } else {
            if (flight.outbounds[0].legs[0].baggage !== undefined){
                flight.outbounds[0].legs[0].baggage.map((luggage) => {
                    if(luggage.is_carry_on){
                        temp_carry_on += luggage.quantity;
                    }else{
                        temp_check += luggage.quantity;
                    }
                })
            }else{
                temp_check = flight.outbounds[0].legs[0].baggage_allowance.number_of_pieces;
            }
        }

        // init tempPaidOptions
        let temp_paid_options = [];
        let temp_options_levels = [];
        let temp_open_modal = [];
        flight.outbounds.map((outbound_flight) => {
            let option_by_outbound = {};
            let level_by_outbound = {};
            let open_modal_by_outbound = {};

            outbound_flight.legs.map((leg) => {
                if (Object.keys(leg.paid_options).length !== 0){
                    // copy only option key from existing paid_options in tempPaidOptions
                    let temp_reduced_options = {};
                    let temp_reduced_options_levels = {};
                    Object.keys(leg.paid_options).forEach(key => {
                        let temp_options_keys = [];
                        let max_baggage_level = 0;
                        leg.paid_options[key].map((option) => {
                            temp_options_keys.push(option.key_optional_service);
                            if (option.baggage_level_restriction !== null && option.baggage_level_restriction > max_baggage_level){
                                max_baggage_level = option.baggage_level_restriction;
                            }
                        })
                        temp_reduced_options_levels[key] = max_baggage_level;
                        temp_reduced_options[key] = temp_options_keys;
                    })
                    option_by_outbound[leg.key_segments !== undefined ? leg.key_segments : leg.key] = temp_reduced_options;
                    level_by_outbound[leg.key_segments !== undefined ? leg.key_segments : leg.key] = temp_reduced_options_levels;
                }
            })
            outbound_flight.legs.map((leg) => {
                if (option_by_outbound[leg.key_segments !== undefined ? leg.key_segments : leg.key] === undefined){
                    option_by_outbound[leg.key_segments !== undefined ? leg.key_segments : leg.key] = {};
                }
                if (level_by_outbound[leg.key_segments !== undefined ? leg.key_segments : leg.key] === undefined){
                    level_by_outbound[leg.key_segments !== undefined ? leg.key_segments : leg.key] = {};
                }
                if (open_modal_by_outbound[leg.key_segments !== undefined ? leg.key_segments : leg.key] === undefined){
                    open_modal_by_outbound[leg.key_segments !== undefined ? leg.key_segments : leg.key] = {};
                }
            })            
            temp_paid_options.push(option_by_outbound);
            temp_options_levels.push(level_by_outbound);
            temp_open_modal.push(open_modal_by_outbound);
        })

        temp_paid_options.map((option_by_outbound) => {
            // add default value to display selector
            Object.keys(option_by_outbound).map((key_segment) => {
                travelers.map((traveler) => {
                    if (option_by_outbound[key_segment][traveler.id] === undefined){
                        option_by_outbound[key_segment][traveler.id] = [t("flight_search.no_luggage")];
                    }else if(option_by_outbound[key_segment][traveler.id].length < 3){
                        option_by_outbound[key_segment][traveler.id].push(t("flight_search.no_luggage"));
                    }
                })
            })
        })

        temp_options_levels.map((level_by_outbound) => {
            // add default level
            Object.keys(level_by_outbound).map((key_segment) => {
                travelers.map((traveler) => {
                    if (level_by_outbound[key_segment][traveler.id] === undefined){
                        level_by_outbound[key_segment][traveler.id] = 0;
                    }
                })
            })
        })

        temp_open_modal.map((open_modal_by_outbound) => {
            // close modal by default
            Object.keys(open_modal_by_outbound).map((key_segment) => {
                travelers.map((traveler) => {
                    if (open_modal_by_outbound[key_segment][traveler.id] === undefined){
                        open_modal_by_outbound[key_segment][traveler.id] = false;
                    }
                })
            })
        })
        
        setCarryOnLuggages(temp_carry_on);
        setCheckLuggages(temp_check);
        setTempPaidOptions(temp_paid_options);
        setOptionsLevels(temp_options_levels);
        setOpenLuggageModal(temp_open_modal);
    }, [])

    const checkColumns = (temp_paid_options) => {
        let temp_max_length = 1;
        temp_paid_options.map((outbound_options) => {
            Object.keys(outbound_options).forEach(key_segment => {
                Object.keys(outbound_options[key_segment]).forEach(key => {
                    if (outbound_options[key_segment][key].length > temp_max_length){
                        temp_max_length = outbound_options[key_segment][key].length;
                    }
                })
            })
        })
        setPaidOptionsMaxLength(temp_max_length);
    }

    const DeleteAllOptions = (outbound_index, key_segment, traveler_id) => {
        let temp_paid_options = tempPaidOptions.slice();
        let temp_option_levels = optionsLevels.slice();
        temp_paid_options[outbound_index][key_segment][traveler_id].map((paid_luggage_key) => {
            let luggage = luggageOptions.find(item => item.key_optional_service === paid_luggage_key);
            if (luggage !== undefined){
                if (luggage.option_on_group){
                    Object.keys(temp_paid_options[outbound_index]).map(key => {
                        if (key !== key_segment && luggage.key_segments.includes(key)){
                            temp_paid_options[outbound_index][key][traveler_id].map((outbound_luggage, outbound_luggage_index) => {
                                if (outbound_luggage === paid_luggage_key){
                                    temp_paid_options[outbound_index][key][traveler_id].splice(outbound_luggage_index, 1);
                                    temp_option_levels[outbound_index][key][traveler_id] = 0;
                                }
                            })
                        }
                    })
                }

                if (luggage.option_on_flight){
                    temp_paid_options.map((luggage_by_outbound, parsed_outbound_index) => {
                        Object.keys(luggage_by_outbound).map(key => {
                            luggage_by_outbound[key][traveler_id].map((outbound_luggage, outbound_luggage_index) => {
                                if (key !== key_segment && outbound_luggage === paid_luggage_key){
                                    luggage_by_outbound[key][traveler_id].splice(outbound_luggage_index, 1);
                                    temp_option_levels[parsed_outbound_index][key][traveler_id] = 0;
                                }
                            })
                        })
                    })
                }
            }
        })
        dispatch(RemovePassengerBaggage(selected_flight, outbound_index, key_segment, traveler_id));
        temp_paid_options[outbound_index][key_segment][traveler_id] = [t("flight_search.no_luggage")];
        temp_option_levels[outbound_index][key_segment][traveler_id] = 0;
        checkColumns(temp_paid_options);
        setTempPaidOptions(temp_paid_options);
        setOptionsLevels(temp_option_levels);
    }

    const validate = () => {
        let find_flight = flight_list_render.find(flight => flight.id === selected_flight);
        setFlight(find_flight);
        setFlightStep(seats_by_segment.length === 0 ? (flight.options.fares[0].refund_detail !== null && flight.options.fares[0].cancel_info !== null ? flightStep + 2 : flightStep + 3) : flightStep + 1);
    }

    const handleLuggageModal = (traveler, outbound_index, key_segment) => {
        let temp_open_modal = openLuggageModal.slice();
        temp_open_modal[outbound_index][key_segment][traveler.id] = true
        setOpenLuggageModal(temp_open_modal);
    }

    return(
        <Grid className={classes.spacer}>
            <Grid container alignItems="center" justifyContent="space-between" direction={isMobile ? "column" : "row"} className={classes.spacer} spacing={isMobile ? 2 : 0}>
                { !isMobile && <Grid item xs={4} /> }
                <Grid item xs={isMobile ? 12 : 4} className={`${classes.textCenter} ${classes.genericText} ${classes.fontWeight900} ${isTablet ? classes.fontSize20 : (isMobile ? classes.fontSize16 : classes.fontSize20)} ${classes.uppercase}`}>
                    { t("flight.add_luggage") }
                </Grid>
                <Grid item xs={isMobile ? 12 : 4} className={`${classes.spacer} ${classes.textCenter}`}>
                    <Button variant="contained" size="small" onClick={() => validate()} className={classes.validateButton}>
                        {t('flight-selected.skip-step')}
                    </Button>
                </Grid>
            </Grid>             
            <Divider />
            <Grid container justify={'space-between'} style={{marginTop: 5}}>
                <Grid item className={`${classes.genericText} ${classes.genericPadding} ${classes.fontWeight900} ${classes.fontSize16} ${classes.uppercase}`}>
                    { t("flight.luggage_recap") }
                </Grid>
            </Grid>
            <Grid container alignItems='center' justify="space-between" className={classes.genericPadding}>
                <Grid item xs={isMobile ? 12 : (isTablet ? 6 : 3)}>
                    <Grid className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.uppercase}`}>
                        { t("flight_search.luggage_carry_on") + " :"}
                    </Grid>
                    {
                        travelers_obj.ADT !== 0 && (
                            <Grid className={classes.spacer}>
                                <LuggageByPax luggageNb={carryOnLuggages} luggageType={"cabin"} paxNb={travelers_obj.ADT} paxLabel={t("flight_search.adults")} small={false} />
                            </Grid>
                        )
                    }
                    {
                        travelers_obj.CNN !== 0 && (
                            <Grid className={classes.spacer}>
                                <LuggageByPax luggageNb={carryOnLuggages} luggageType={"cabin"} paxNb={travelers_obj.CNN} paxLabel={t("flight_search.children")} small={false} />
                            </Grid>
                        )
                    }
                    {
                        travelers_obj.INF !== 0 && (
                            <Grid className={classes.spacer}>
                                <LuggageByPax luggageNb={carryOnLuggages} luggageType={"cabin"} paxNb={travelers_obj.INF} paxLabel={t("flight_search.babies")} small={false} />
                            </Grid>)
                    }
                </Grid>
                { !isHandHeld && <Grid item xs={3} /> }
                <Grid item xs={isMobile ? 12 : (isTablet ? 6 : 3)}>
                    <Grid className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.uppercase}`}>
                        { t("flight_search.luggage_check") + " :" }
                    </Grid>
                    {
                        travelers_obj.ADT !== 0 && (
                            <Grid className={classes.spacer}>
                                <LuggageByPax luggageNb={checkLuggages} luggageType={"checked"} paxNb={travelers_obj.ADT} paxLabel={t("flight_search.adults")} small={false} />
                            </Grid>
                        )
                    }
                    {
                        travelers_obj.CNN !== 0 && (
                            <Grid className={classes.spacer}>
                                <LuggageByPax luggageNb={checkLuggages} luggageType={"checked"} paxNb={travelers_obj.CNN} paxLabel={t("flight_search.children")} small={false} />
                            </Grid>
                        )
                    }
                    {
                        travelers_obj.INF !== 0 && (
                            <Grid className={classes.spacer}>
                                <LuggageByPax luggageNb={checkLuggages} luggageType={"checked"} paxNb={travelers_obj.INF} paxLabel={t("flight_search.babies")} small={false} />
                            </Grid>
                        )
                    }
                </Grid>
                <Grid item xs={3} />
            </Grid>
            <Divider style={{ margin: "8px 16px" }} />
            <Grid className={`${classes.genericPadding}`}>
                <Grid className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize16} ${classes.uppercase}`}>
                    { t("flight.add_luggage") }
                </Grid>
                {
                    tempPaidOptions.length !== 0 && optionsLevels.length !== 0 && flight.outbounds.map((outbound_flight, outbound_index) => {
                        return(
                            outbound_flight.legs.map((leg, leg_index) => {
                                return(
                                    <Grid key={leg_index} className={classes.spacer}>
                                        <TableContainer style={{ overflow: "hidden" }}>
                                            <Table>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell padding="none" className={`${classes.borderTableCell} ${classes.genericText} ${classes.fontWeight900} ${isHandHeld ? classes.fontSize12 : classes.fontSize16} ${classes.uppercase} ${classes.textCenter}`} style={{ width: 80 }}>
                                                            { t("global.flight") + " " + (outbound_index + 1) }
                                                        </TableCell>
                                                        <TableCell padding="none" className={`${classes.borderTableCell} ${classes.genericText} ${classes.fontWeight900} ${isHandHeld ? classes.fontSize12 : classes.fontSize16} ${classes.uppercase} ${classes.textCenter}`} style={{ width: 200 }}>
                                                            {
                                                                leg.origin_airport !== undefined && airports[leg.origin_airport].code
                                                            }
                                                            {
                                                                leg.origin_station !== undefined && stations[leg.origin_station].code
                                                            }
                                                            { " - " }
                                                            {
                                                                leg.destination_airport !== undefined && airports[leg.destination_airport].code
                                                            }
                                                            {
                                                                leg.destination_station !== undefined && stations[leg.destination_station].code
                                                            }
                                                        </TableCell>
                                                        <TableCell padding="none" className={`${classes.borderTableCell} ${classes.genericText} ${classes.fontWeight900} ${isHandHeld ? classes.fontSize12 : classes.fontSize16} ${classes.uppercase} ${classes.textCenter}`}  style={{ width: 296 }}>
                                                            { isHandHeld ? t("flight_search.add_optional_luggage_short") : t("flight_search.add_optional_luggage") + " " + 1}
                                                        </TableCell>
                                                        {
                                                            !isHandHeld && (
                                                                <TableCell padding="none" className={`${classes.borderTableCell} ${classes.genericText} ${classes.fontWeight900} ${isHandHeld ? classes.fontSize12 : classes.fontSize16} ${classes.uppercase} ${classes.textCenter}`} style={{ width: 296 }}>
                                                                    {
                                                                        paidOptionsMaxLength > 1 && t("flight_search.add_optional_luggage") + " " + 2
                                                                    }
                                                                </TableCell>
                                                            )
                                                        }
                                                        {
                                                            !isHandHeld && (
                                                                <TableCell padding="none" className={`${classes.borderTableCell} ${classes.genericText} ${classes.fontWeight900} ${isHandHeld ? classes.fontSize12 : classes.fontSize16} ${classes.uppercase} ${classes.textCenter}`} style={{ width: 296 }}>
                                                                    {
                                                                        paidOptionsMaxLength > 2 && t("flight_search.add_optional_luggage")  + " " + 3
                                                                    }
                                                                </TableCell>
                                                            )
                                                        }
                                                        { !isHandHeld && <TableCell padding="none" className={classes.borderTableCell} style={{ width: 32 }} /> }
                                                    </TableRow>
                                                    {
                                                        travelers.map((traveler, traveler_index) => {
                                                            let temp_paid_option = tempPaidOptions[outbound_index][leg.key_segments !== undefined ? leg.key_segments : leg.key][traveler.id];
                                                            let last_option = temp_paid_option[temp_paid_option.length - 1];
                                                            return(
                                                                <TableRow key={traveler_index}>
                                                                    <TableCell padding="none" className={classes.borderTableCell} style={{ width: 80 }} />
                                                                    <TableCell padding="none" className={`${classes.borderTableCell} ${classes.genericText} ${classes.fontWeight900} ${isHandHeld ? classes.fontSize10 : classes.fontSize12} ${classes.uppercase} ${classes.textCenter}`} style={{ width: 200 }}>
                                                                        { traveler.first_name + " " + traveler.last_name }
                                                                    </TableCell>
                                                                    <TableCell className={`${classes.borderTableCell} ${classes.selectSpacer}`} style={{ width: 296 }}>
                                                                        {
                                                                            isHandHeld && (
                                                                                <Grid container className={classes.pointer} alignItems="center" justify="center" onClick={() => handleLuggageModal(traveler, outbound_index, leg.key_segments !== undefined ? leg.key_segments : leg.key)}>
                                                                                    <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize10} ${classes.uppercase}`}>
                                                                                        { last_option !== t("flight_search.no_luggage") ? temp_paid_option.length : temp_paid_option.length - 1 }
                                                                                    </Grid>
                                                                                    <Grid item>
                                                                                        <IconButton size="small">
                                                                                            <EditIcon />
                                                                                        </IconButton>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            )
                                                                        }
                                                                        {
                                                                            !isHandHeld && (
                                                                                <SelectLuggage luggageOptions={luggageOptions} tempPaidOptions={tempPaidOptions} setTempPaidOptions={setTempPaidOptions} key_segment={leg.key_segments !== undefined ? leg.key_segments : leg.key} outbound_index={outbound_index} traveler={traveler} traveler_index={traveler_index} optionsLevels={optionsLevels} setOptionsLevels={setOptionsLevels} luggage_index={0} checkColumns={checkColumns} />
                                                                            )
                                                                        }
                                                                    </TableCell>
                                                                    {
                                                                        !isHandHeld && (
                                                                            <TableCell className={`${classes.borderTableCell} ${classes.selectSpacer}`} style={{ width: 296 }}>
                                                                                {
                                                                                    temp_paid_option.length > 1 && (
                                                                                        <SelectLuggage luggageOptions={luggageOptions} tempPaidOptions={tempPaidOptions} setTempPaidOptions={setTempPaidOptions} key_segment={leg.key_segments !== undefined ? leg.key_segments : leg.key} outbound_index={outbound_index} traveler={traveler} traveler_index={traveler_index} optionsLevels={optionsLevels} setOptionsLevels={setOptionsLevels} luggage_index={1} checkColumns={checkColumns} />
                                                                                    )
                                                                                }
                                                                            </TableCell>
                                                                        )
                                                                    }
                                                                    {
                                                                        !isHandHeld && (
                                                                            <TableCell className={`${classes.borderTableCell} ${classes.selectSpacer}`} style={{ width: 296 }}>
                                                                                {
                                                                                    temp_paid_option.length > 2 && (
                                                                                        <SelectLuggage luggageOptions={luggageOptions} tempPaidOptions={tempPaidOptions} setTempPaidOptions={setTempPaidOptions} key_segment={leg.key_segments !== undefined ? leg.key_segments : leg.key} outbound_index={outbound_index} traveler={traveler} traveler_index={traveler_index} optionsLevels={optionsLevels} setOptionsLevels={setOptionsLevels} luggage_index={2} checkColumns={checkColumns} />
                                                                                    )
                                                                                }
                                                                            </TableCell>
                                                                        )
                                                                    }
                                                                    {
                                                                        !isHandHeld && temp_paid_option.length > 1 && (
                                                                            <TableCell padding="none" className={classes.borderTableCell} style={{ width: 32 }}>
                                                                                <Tooltip title={t("flight_search.del_all_luggage")}>
                                                                                    <IconButton size="small" onClick={() => DeleteAllOptions(outbound_index, leg.key_segments !== undefined ? leg.key_segments : leg.key, traveler.id)}>
                                                                                        <DeleteIcon style={{ color: '#E6592F' }} />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </TableCell>
                                                                        )
                                                                    }
                                                                    <SelectLuggageModal openModal={openLuggageModal} setOpenModal={setOpenLuggageModal} luggageOptions={luggageOptions} tempPaidOptions={tempPaidOptions} setTempPaidOptions={setTempPaidOptions} key_segment={leg.key_segments !== undefined ? leg.key_segments : leg.key} outbound_index={outbound_index} traveler={traveler} traveler_index={traveler_index} optionsLevels={optionsLevels} setOptionsLevels={setOptionsLevels} DeleteAllOptions={DeleteAllOptions} checkColumns={checkColumns} />
                                                                </TableRow>
                                                            )
                                                        })
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                )
                            })
                        )
                    })
                }
            </Grid>
            <Grid className={`${classes.textCenter} ${classes.spacer}`}>
                <Button variant="contained" size="small" onClick={() => validate()} className={classes.validateButton}>
                    { t("global.validate") }
                </Button>
            </Grid>
        </Grid>
    )
})

export default React.memo(AddLuggage);