import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Alert,
    Box,
    Chip,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Typography
} from "@mui/material";
import {
    cloneDeep,
    groupBy,
    keyBy,
    mapValues,
    merge
} from "lodash";
import { Moment } from "moment";
import { getCircuit } from "../Itinerary/utils/getCircuit";
import { useGetAdalteRoomTypes } from "./Functions/getAdalteRoomTypes";
import { AdalteRoomTypes } from "./objects/AdalteRoomTypes";
import { Traveler } from "../../Reducers/objects/Traveler";
import { Circuit } from "../Itinerary/objects/circuit";
import { AppState } from "../../Reducers/Reducers";
import { differenceInCalendarYears } from 'date-fns';

export type SelectedRoom = AdalteRoomTypes['rooms'][number] & {
    index: string,
    pickup_code: string | null,
    code: string | null,
    chosen_language_code: string,
    group_passenger?: number
}

type Props = {
    circuitId: number,
    version: number,
    startDate?: Moment,
    adultTravelers: Traveler[] | null,
    childTravelers: Traveler[] | null,
    groups: {
        id?: number,
        travelers: number[],
        travelers_list: Traveler[]
    }[] | null,
    errors: {
        [index: number]: string
    },
    onChangeErrors: React.Dispatch<React.SetStateAction<{
        [index: number]: string
    }>>,
    rooms: SelectedRoom[],
    pickups: string,
    onChangePickups: React.Dispatch<React.SetStateAction<string>>,
    onChangeRooms: React.Dispatch<React.SetStateAction<SelectedRoom[]>>,
    adalteLanguageCode: string,
    onChangeAdalteLanguageCode: React.Dispatch<React.SetStateAction<string>>,
    includeAge: boolean,
    forVersionUpdate?: boolean
}

export function RoomTypePicker(props: Props): JSX.Element {
    const { t, i18n } = useTranslation();
    const locale = useSelector((state: AppState) => state.user.locales?.find((item) => {
        return item.language_code === i18n.language;
    })?.id ?? 1);
    const [circuit, setCircuit] = useState<Circuit | null>(null);
    console.log("props.groups : ", props.groups);
    const options = useMemo((): Parameters<typeof useGetAdalteRoomTypes>[0] => {
        const adultTravelerIds = props.adultTravelers?.map((traveler) => {
            return traveler.id;
        }) ?? [];
        return {
            forVersionUpdate: props.forVersionUpdate ?? false,
            version: props.version,
            groups: props.groups?.map((group) => {
                return group.travelers_list.map((traveler) => {
                    return traveler.birth_date ? differenceInCalendarYears(new Date(), new Date(traveler.birth_date)) : 30;
                });
                // return group.travelers.map((travelerId) => {
                //     if (adultTravelerIds.includes(travelerId)) {
                //         return 30;
                //     }
                //     return 2;
                // });
            }) ?? [],
            startDate: props.startDate?.format('YYYY-MM-DD') ?? '',
            skip: !props.groups || !props.startDate
        };
    }, [
        props.forVersionUpdate,
        props.version,
        props.adultTravelers,
        props.groups,
        props.startDate
    ]);
    const fetchedRoomTypes = useGetAdalteRoomTypes(options);
    // const roomTypes = useMemo(() => {
    //     const types = groupBy(
    //         props.rooms,
    //         (room) => `${room.index}-${room.code}`
    //     );
    //     const fetchedTypes = mapValues(
    //         keyBy(
    //             fetchedRoomTypes.rooms,
    //             (item) => `${item.index}`
    //         ),
    //         (item) => item.options.map((room) => ({
    //             ...room,
    //             index: item.index,
    //         }))
    //     );
    //     const result = Object.values(
    //         mapValues(
    //             merge(
    //                 cloneDeep(types),
    //                 fetchedTypes.rooms
    //             ),
    //             (rooms, key) => {
    //                 const [index, pickup_code] = key.split('-') as [string, string];
    //                 return {
    //                     index,
    //                     pickup_code,
    //                     rooms
    //                 };
    //             }
    //         )
    //     );
    //     return result;
    // }, [fetchedRoomTypes, props.rooms]);
    const pickups = useMemo(() => {
        return fetchedRoomTypes?.pickups;
    }, [fetchedRoomTypes]);


    useEffect(() => {
        (async () => {
            setCircuit(await getCircuit(props.circuitId));
        })();
    }, [props.circuitId]);

    return (
        <Box sx={{ textAlign: 'left' }}>
            <Typography
                variant="h6"
                color="GrayText"
                fontWeight={700}
                component="div"
                gutterBottom
            >
                {t('home.modal.room-type-choice')}
            </Typography>
            {
                (fetchedRoomTypes?.rooms?.length ?? 0) === 0 &&
                <Alert severity="warning">
                    {t('home.modal.room-type-choice-no-offers-hint')}
                </Alert>
            }
            {
                (fetchedRoomTypes?.rooms?.length ?? 0) > 0 &&
                <Stack alignItems="flex-start" spacing={2}>
                    {
                        props.groups?.map((group, index) => {
                            const types = fetchedRoomTypes!.rooms.find((item) => {
                                return parseInt(item.index) === index + 1;
                            });
                            const rooms = types?.options;
                            return (
                                <Stack
                                    key={props.rooms[index]?.pickup_code ?? index}
                                    direction="row"
                                    alignItems="center"
                                    spacing={2}
                                >
                                    <FormControl
                                        size="small"
                                        sx={{ maxWidth: 'unset' }}
                                    >
                                        <InputLabel shrink>
                                            {t('home.modal.room-type-choice-room-no', { no: index + 1 })}
                                        </InputLabel>
                                        <Select
                                            value={
                                                props.rooms[index]?.code ??
                                                ''
                                            }
                                            label={t('home.modal.room-type-choice-room-no', { no: index + 1 })}
                                            renderValue={(selected) => {
                                                const code = selected as string;

                                                if (!code) {
                                                    return <em>{t('home.modal.room-type-choice-hint')}</em>;
                                                }

                                                return rooms?.find((room) => {
                                                    return room.code === code;
                                                })?.name;
                                            }}
                                            MenuProps={{
                                                disablePortal: true
                                            }}
                                            onChange={(event) => {
                                                const room = rooms?.find((item) => {
                                                    return item.code === event.target.value;
                                                });

                                                if (room) {
                                                    props.onChangeErrors((state) => ({
                                                        ...state,
                                                        [index]: ''
                                                    }));
                                                }

                                                props.onChangeRooms((state) => {
                                                    return [
                                                        ...state.slice(0, index),
                                                        room ?
                                                            {
                                                                ...room,
                                                                index: types!.index,
                                                                pickup_code: props.pickups,
                                                                chosen_language_code: props.adalteLanguageCode,
                                                                group_passenger: group.id,
                                                                travelers_age: props.includeAge ?
                                                                    group.travelers_list.map((traveler) => {
                                                                        return window.moment.utc().diff(
                                                                            window.moment.utc(traveler.birth_date, 'YYYY-MM-DD'),
                                                                            'years'
                                                                        );
                                                                    }) :
                                                                    undefined
                                                            } :
                                                            null,
                                                        ...state.slice(index + 1)
                                                    ].filter((item): item is NonNullable<typeof item> => {
                                                        return !!item;
                                                    });
                                                });
                                            }}
                                            error={!!props.errors[index]}
                                            notched
                                            displayEmpty
                                        >
                                            {
                                                rooms?.map((room) => (
                                                    <MenuItem key={room.name} value={room.code}>
                                                        {room.name}
                                                    </MenuItem>
                                                ))
                                            }
                                        </Select>
                                        {
                                            props.errors[index] &&
                                            <FormHelperText error>
                                                {props.errors[index]}
                                            </FormHelperText>
                                        }
                                    </FormControl>
                                    {
                                        props.rooms[index] &&
                                        <>
                                            <Typography>
                                                {
                                                    t(
                                                        'home.modal.room-type-price',
                                                        {
                                                            price: new Intl.NumberFormat(
                                                                i18n.language,
                                                                {
                                                                    style: 'currency',
                                                                    currency: props.rooms[index]?.currency ?? 'EUR'
                                                                }
                                                            ).format(props.rooms[index]?.price ?? 0)
                                                        }
                                                    )
                                                }
                                            </Typography>
                                            {
                                                props.rooms[index]?.on_demand === 'true' &&
                                                <Chip
                                                    label={t('shared.stock-type-on-demand')}
                                                />
                                            }
                                        </>
                                    }
                                </Stack>
                            );
                        })
                    }
                </Stack>
            }
            <Typography
                variant="h6"
                color="GrayText"
                fontWeight={700}
                component="div"
                sx={{ marginTop: 1.5 }}
                gutterBottom
            >
                {t('home.modal.package-language-choice')}
            </Typography>
            {
                circuit?.guide_languages.length === 0 &&
                <Alert severity="warning">
                    {t('home.modal.package-no-language-warning')}
                </Alert>
            }
            {/*{*/}
            {/*    props.rooms.length === 0 &&*/}
            {/*    <Alert severity="warning">*/}
            {/*        {t('home.modal.package-language-hint')}*/}
            {/*    </Alert>*/}
            {/*}*/}
            {
                (circuit?.guide_languages.length ?? 0) > 0 &&
                <FormControl
                    size="small"
                    sx={{ minWidth: 175 }}
                >
                    <Select
                        value={
                            props.adalteLanguageCode ?? ''
                        }
                        MenuProps={{
                            disablePortal: true
                        }}
                        onChange={(event) => {
                            props.onChangeAdalteLanguageCode(event.target.value);
                            props.onChangeRooms((state) => {
                                return state.map((item) => ({
                                    ...item,
                                    chosen_language_code: event.target.value
                                }));
                            });
                            // props.onChangeRooms((state) => {
                            //     return state.map((item) => {
                            //         return {
                            //             ...item,
                            //             chosen_language_code: event.target.value
                            //         };
                            //     });
                            // });
                        }}
                        error={!!props.errors[-2]}
                        notched
                        displayEmpty
                    >
                        {
                            circuit?.guide_languages.map((language) => (
                                <MenuItem key={language.id} value={language.language_code}>
                                    {
                                        language.LocalTranslation?.find((item) => {
                                            return item.localeTranslate === locale;
                                        })?.translated_name ??
                                        language.full_name
                                    }
                                </MenuItem>
                            ))
                        }
                    </Select>
                    {
                        props.errors[-2] &&
                        <FormHelperText error>
                            {props.errors[-2]}
                        </FormHelperText>
                    }
                </FormControl>
            }
            <Typography
                variant="h6"
                color="GrayText"
                fontWeight={700}
                component="div"
                sx={{ marginTop: 1.5 }}
                gutterBottom
            >
                {t('home.modal.package-pickup-choice')}
            </Typography>
            {
                pickups && pickups.length > 0 &&
                <FormControl
                    size="small"
                    sx={{ minWidth: 175, maxWidth: 175 }}
                >
                    <Select
                        value={
                            props.pickups ?? ''
                        }
                        MenuProps={{
                            disablePortal: true
                        }}
                        onChange={(event) => {
                            props.onChangePickups(event.target.value);
                            props.onChangeRooms((state) => {
                                return state.map((item) => ({
                                    ...item,
                                    pickup_code: event.target.value
                                }));
                            });
                            // props.onChangeRooms((state) => {
                            //     return state.map((item) => {
                            //         return {
                            //             ...item,
                            //             pickups: event.target.value
                            //         };
                            //     });
                            // });
                        }}
                        // error={!!props.errors[-2]}
                        notched
                        displayEmpty
                    >
                        {
                            pickups.map((pickup) => (
                                <MenuItem key={pickup.code} value={pickup.code}>
                                    {
                                        [
                                            pickup.name,
                                            pickup.city
                                        ].filter((item) => !!item).join(', ')
                                    }
                                </MenuItem>
                            ))
                        }
                    </Select>
                    {/*{*/}
                    {/*    props.errors[-2] &&*/}
                    {/*    <FormHelperText error>*/}
                    {/*        {props.errors[-2]}*/}
                    {/*    </FormHelperText>*/}
                    {/*}*/}
                </FormControl>
            }
        </Box>
    );
}
