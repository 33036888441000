import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { Cancel, DashboardRounded } from "@mui/icons-material";
import { CartFlightCard } from "../CartMaterial/CartFlightCard";
import { CounterOfferLayout, useProviderProducts } from "./hooks/providerProducts";
import { ProviderProductsContent } from "./providerProductsContent";
import { LoadingBackDrop } from "../Common/LoadingBackdrop";
import { isProductSamePackage } from "../CartMaterial/utils/isProductSamePackage";
import { usePackagedProducts } from "../CartMaterial/utils/packagedProducts";
import { useChangePackageReferenceBooking } from "./utils/changePackageReferenceBooking";
import { ProviderContext } from "../CartMaterial/utils/providerContext";
import { ProviderBookingReferenceEdit } from "../ProviderBooking/providerBookingReferenceEdit";
import { AppState } from "../../Reducers/Reducers";

type Props = {
    products: ReturnType<typeof usePackagedProducts>[string],
    disableCounterOffer?: boolean
}

export function ProviderProducts(props: Props): JSX.Element {
    const {
        productStepIds,
        productsWithDays,
        productsWithSteps,
        packages
    } = useProviderProducts(props.products, !props.disableCounterOffer);

    return (
        <>
            {
                props.products.flights.map((item) => {
                    const counterOffer = props.products.flights.find((product) => {
                        return item.flight.id === product.flight.counter_offer_of;
                    });

                    if (counterOffer) {
                        return (
                            <CounterOfferLayout
                                key={item.flight.id}
                                product={
                                    item.type === 'normal' ?
                                        <CartFlightCard
                                            type="normal"
                                            flight={item.flight}
                                        /> :
                                        <CartFlightCard
                                            type="manual"
                                            flight={item.flight}
                                        />
                                }
                                counterOffer={
                                    counterOffer.type === 'normal' ?
                                        <CartFlightCard
                                            type="normal"
                                            flight={counterOffer.flight}
                                        /> :
                                        <CartFlightCard
                                            type="manual"
                                            flight={counterOffer.flight}
                                        />
                                }
                            />
                        )
                    }

                    if (!item.flight.counter_offer_of) {
                        return item.type === 'normal' ?
                            <CartFlightCard
                                key={item.flight.id}
                                type="normal"
                                flight={item.flight}
                            /> :
                            <CartFlightCard
                                key={item.flight.id}
                                type="manual"
                                flight={item.flight}
                            />
                    }
                })
            }
            <ProviderProductsContent
                productStepIds={productStepIds}
                productsWithDays={productsWithDays}
                productsWithSteps={productsWithSteps}
            />
            {
                Object.keys(packages).map((key) => {
                    const data = packages[key]!;
                    const stackNumber = key.split('-')[0] ? parseInt(key.split('-')[0]!) : null;
                    const stackInfoId = key.split('-')[1] ? parseInt(key.split('-')[1]!) : null;

                    if (stackNumber) {
                        return (
                            <Package
                                key={key}
                                stackNumber={stackNumber}
                                stackInfoId={stackInfoId}
                                package={data}
                            />
                        );
                    }
                })
            }
        </>
    );
}

type PackageProps = {
    stackNumber: number,
    stackInfoId: number | null,
    package: ReturnType<typeof useProviderProducts>['packages'][string]
}

function Package(props: PackageProps): JSX.Element {
    const { t } = useTranslation();
    const trip = useSelector((state: AppState) => state.trip.data_trip);
    const [loading, setLoading] = useState(false);
    const context = useContext(ProviderContext);
    const [editBookingReference, setEditBookingReference] = useState(false);
    const updateReference = useChangePackageReferenceBooking({
        onTrigger() {
            setLoading(true);
        },
        onFinally() {
            setLoading(false);
            setEditBookingReference(false);
        }
    });

    const onSaveReference = (reference: string | null) => {
        if (context.module === 'booking') {
            const stackInfos = trip?.stack_info ?? [];
            const correspondingInfo = stackInfos.find((item) => {
                return isProductSamePackage(
                    {
                        stack_number: item.stack_number,
                        stack_info_id: item.is_custom && !props.stackInfoId ?
                            props.stackInfoId :
                            item.id
                    },
                    {
                        stack_number: props.stackNumber,
                        stack_info_id: props.stackInfoId
                    }
                );
            });
            updateReference({
                tripId: context.tripId,
                tripVersion: context.version,
                reference,
                stackInfo: correspondingInfo ?? null,
                stackInfos,
                stackNumber: props.stackNumber,
                products: props.package.products
            });
        }
    }

    return (
        <>
            <Box
                sx={{
                    padding: '16px',
                    border: '2px solid #2F80ED',
                    borderRadius: '16px',
                    marginTop: '16px',
                    marginBottom: '16px'
                }}
            >
                <Stack direction="row" alignItems="center" spacing={1.5} sx={{ marginBottom: 2 }}>
                    <Box
                        sx={{
                            textAlign: 'center',
                            color: '#2F80ED',
                            border: '2px solid #2F80ED',
                            fontWeight: 'bold',
                            borderRadius: '16px',
                            padding: '2px 30px',
                        }}
                    >
                        <DashboardRounded style={{ verticalAlign: 'top', marginRight: 3 }} />
                        {
                            t(
                                'cart-material.cart-construction-package-name',
                                { name: props.stackNumber }
                            )
                        }
                    </Box>
                    {
                        context.module === 'booking' &&
                        <>
                            <ProviderBookingReferenceEdit
                                open={editBookingReference}
                                reference={
                                    trip?.stack_info?.find((item) => {
                                        return item.id === props.stackInfoId;
                                    })?.booking_status?.item_reference ??
                                    ''
                                }
                                onOpen={() => setEditBookingReference(true)}
                                onClose={() => setEditBookingReference(false)}
                                onSave={onSaveReference}
                            >
                                <Typography fontWeight={100} fontSize="0.75rem">
                                    {
                                        t(
                                            'cart-material.cart-construction-reference',
                                            {
                                                ref: trip?.stack_info?.find((item) => {
                                                    return item.id === props.stackInfoId;
                                                })?.booking_status?.item_reference ??
                                                    ''
                                            }
                                        )
                                    }
                                </Typography>
                            </ProviderBookingReferenceEdit>
                            <IconButton onClick={() => onSaveReference(null)}>
                                <Cancel />
                            </IconButton>
                        </>
                    }
                </Stack>
                <ProviderProductsContent
                    productStepIds={props.package.productStepIds}
                    productsWithDays={props.package.productsWithDays}
                    productsWithSteps={props.package.productsWithSteps}
                />
            </Box>
            <LoadingBackDrop open={loading} />
        </>
    );
}