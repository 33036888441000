//Dependencies
import { useTranslation } from 'react-i18next';
import React, { FC, ReactElement, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
//Core & Lab
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    AlertTitle,
    AppBar,
    Button,
    Container,
    Dialog,
    Grid,
    IconButton,
    Toolbar,
    Typography
} from '@mui/material';
//Icons
import Close from '@mui/icons-material/Close';
//Components
import PictureProgress from './pictureProgress';
import PictureModalCreate from './pictureModalCreate';
import PictureModalCreateTabs from './pictureModalCreateTabs';
//Actions
import { resetCreateForm } from './redux/actions';
//Utils
import { PictureContext, PictureContextValue } from './utils/pictureContext';
import { ProviderContext } from '../../../CartMaterial/utils/providerContext';

type Props = PictureContextValue & {
    onClose: () => void,
    extraContent?: JSX.Element,
    hidePictureHelper?: boolean,
}

const PictureModalCreateForm: FC<Props> = (props): ReactElement => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const providerContext = useContext(ProviderContext);

    useEffect(() => {
        return () => {
            dispatch(resetCreateForm());
        };
    }, []);

    return (
        <PictureContext.Provider value={props}>
            <Dialog
                fullScreen
                open
                onClose={props.onClose}
            >
                <AppBar className={'ft-user-appBar'}>
                    <Toolbar>
                        <IconButton edge={'start'} color={'inherit'} onClick={props.onClose}><Close /></IconButton>
                        <Typography variant={'h6'}>
                            {
                                t(
                                    props.singleFile ? 'shared.add-picture' : 'shared.add-picture(s)'
                                )
                            }
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Container className={'ft-container'} maxWidth={'md'}>
                    {props.extraContent}
                    <Accordion square expanded>
                        <AccordionSummary className={'ft-user-title'}>
                            <Typography>{t('shared.picture-selection')}</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={'ft-user-subContainer'}>
                            <Grid container spacing={2}>
                                {
                                    !props.hidePictureHelper &&
                                    <Grid item xs={12}>
                                        <Alert className={'ft-justify'} severity={'info'}>
                                            <AlertTitle>{t('shared.info')} :</AlertTitle>
                                            {
                                                !providerContext.module ?
                                                    t('shared.add-picture-helper') :
                                                    t('shared.add-picture-helper-provider')
                                            }
                                        </Alert>
                                    </Grid>
                                }
                                <Grid item xs={12}>
                                    <PictureModalCreateTabs />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Grid container spacing={2} justifyContent={'flex-end'}>
                        <Grid item>
                            <Button onClick={props.onClose}>{t('shared.cancel')}</Button>
                        </Grid>
                        <Grid item>
                            <PictureModalCreate onClose={props.onClose} />
                        </Grid>
                    </Grid>
                </Container>
            </Dialog>
            <PictureProgress />
        </PictureContext.Provider>
    );
};

export default PictureModalCreateForm;
