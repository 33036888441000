import React, { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Alert,
    Box,
    CardContent,
    Chip,
    Collapse,
    IconButton,
    Stack,
    Tooltip,
    Typography
} from "@mui/material";
import {
    ArrowRightAltOutlined,
    CloudCircle,
    CloudOff,
    DateRangeOutlined,
    Edit,
    ErrorOutline,
    ExploreOutlined,
    GroupAddOutlined,
    HighlightOff,
    PanToolOutlined,
    PeopleOutlineOutlined,
    PlaceOutlined,
    ReportProblemOutlined,
    TransferWithinAStationOutlined,
    WorkOutlineOutlined
} from "@mui/icons-material";
import { flatten, isNumber } from "lodash";
import axios from "axios";
import { CartProductCard, CartProductCardProps } from "./CartProductCard";
import { CartTransferCardDetails } from "./CartTransferCardDetails";
import { CartConstructionProductsTableItemStatus } from "./CartConstructionProductsTableItemStatus";
import { CartConstructionProductsTableItemMargin } from "./CartConstructionProductsTableItemMargin";
import { ProviderQuotationPriceEdit } from "../ProviderQuotation/providerQuotationPriceEdit";
import { ProviderBookingReferenceEdit } from "../ProviderBooking/providerBookingReferenceEdit";
import { LoadingBackDrop } from "../Common/LoadingBackdrop";
import { CartProductCardName } from "./CartProductCardName";
import { CartClientTimeModal } from "./CartClientTimeModal";
import { CartProductCardProviderLogo } from "./CartProductCardProviderLogo";
import { CartProductIconText } from "./CartProductIconText";
import { ProviderContext } from "./utils/providerContext";
import { getTransferPicture } from "./utils/getTransferPicture";
import { findProductItineraryError } from "./utils/findProductItineraryError";
import { findPictureUrl } from "./utils/findPictureUrl";
import { findProductAssociatedItineraryStep } from "./utils/findProductAssociatedItineraryStep";
import { transformStepInputsToGroups } from "../Itinerary/utils/transformStepInputsToGroups";
import { insertDivider } from "./utils/insertDivider";
import { sortItinerary } from "../Itinerary/utils/sortItinerary";
import { checkIfOnRoad } from "./utils/checkIfOnRoad";
import { isProductPackaged } from "./utils/isProductPackaged";
import { isProductSamePackage } from "./utils/isProductSamePackage";
import { useGetPrice } from "./utils/getPrice";
import { useShowError } from "../Utils/showError";
import { useProductBookingStatusChange } from "../ProviderBooking/network/productBookingStatusChange";
import GetCookie from "../Common/Functions/GetCookie";
import CheckBeforeRequest from "../Common/CheckBeforeRequest";
import { TransferCart } from "../Itinerary/objects/transferCart";
import { StatusBooking } from "../Itinerary/objects/statusBooking";
import { AppState } from "../../Reducers/Reducers";

type Props = {
    transfer: TransferCart
} & Pick<CartProductCardProps, 'alwaysOpen'>

export function CartTransferCard(props: Props): JSX.Element | null {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const locale = useSelector((state: AppState) => {
        return state.user.locales.find((item) => {
            return item.language_code === i18n.language;
        })?.id ?? 1;
    });
    const providers = useSelector((state: AppState) => state.trip.providers);
    const trip = useSelector((state: AppState) => state.trip.data_trip);
    const tripEndDate = useSelector((state: AppState) => state.trip.end_date);
    const itinerary_list = useSelector((state: AppState) => state.itinerary.itinerary_list);
    const manualProviders = useSelector((state: AppState) => state.trip.manual_providers);
    const user = useSelector((state: AppState) => state.user?.user);
    const [pictures, setPictures] = useState<string[] | null>(null);
    const [editTime, setEditTime] = useState(false);
    const [editPrice, setEditPrice] = useState(false);
    const [editBookingReference, setEditBookingReference] = useState(false);
    const [loading, setLoading] = useState(false);
    const provider = useMemo(() => {
        return providers.concat(manualProviders).find((item) => {
            return item.provider.id === props.transfer.provider;
        })?.provider;
    }, [props.transfer, providers, manualProviders]);
    const { found, iti_error } = useMemo(() => {
        return findProductItineraryError(
            'transfer',
            {
                product: props.transfer,
                itinerary: itinerary_list,
                stackInfos: trip?.stack_info ?? null
            }
        );
    }, [
        itinerary_list,
        props.transfer,
        trip
    ]);
    const isOnRoad = useMemo(() => {
        const groups = transformStepInputsToGroups(itinerary_list);
        const searchableItinerary = isProductPackaged({
            product: props.transfer,
            stackInfos: trip?.stack_info ?? null
        }) ?
            flatten(
                groups.filter((item) => {
                    return item[0] &&
                        (
                            isNumber(item[0].circuit) ||
                            isNumber(item[0].iti_type)
                        ) &&
                        item[0].circuit === props.transfer.from_circuit;
                })
            ) :
            itinerary_list;
        const steps = searchableItinerary.filter((item) => {
            return item.step_type === 'STEP';
        }).sort(sortItinerary);
        const associatedStep = findProductAssociatedItineraryStep({
            type: 'start',
            itinerary: steps,
            product: props.transfer,
            granularity: 'seconds'
        });

        if (associatedStep) {
            return checkIfOnRoad({
                type: 'transfer',
                itinerary: steps,
                step: associatedStep,
                product: props.transfer
            });
        }

        return false;
    }, [props.transfer, itinerary_list]);
    const getPrice = useGetPrice();
    const totalCost = getPrice(props.transfer.prices);
    const showError = useShowError();
    const changeBookingStatus = useProductBookingStatusChange({
        onTrigger() {
            setLoading(true);
        },
        onSuccess(product) {
            dispatch({
                type: 'TRANSFER_EDIT_CART_BY_ID',
                payload: product
            });
        },
        onError(error) {
            console.error(error);
            showError(error);
        },
        onFinally() {
            setLoading(false);
        }
    });
    const providerContext = useContext(ProviderContext);
    const quotation_code = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;

    const confirmTravelerModification = () => {
        const { headers } = CheckBeforeRequest();
        axios({
            method: 'PATCH',
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/${props.transfer.is_custom ? 'custom-products' : 'transfers'}/${props.transfer.id}/`,
            data: {
                traveler_modification: null
            }
        }).then((response) => {
            dispatch({ type: 'TRANSFER_EDIT_CART_BY_ID', payload: response.data });
        }).catch((error) => {
            console.log(error);
        });
    };

    const onChangeBookingReference = (reference: string) => {
        if (
            providerContext.module === 'booking' &&
            providerContext.tripId &&
            providerContext.version
        ) {
            changeBookingStatus({
                tripId: providerContext.tripId,
                version: providerContext.version,
                productId: props.transfer.id,
                ref: reference,
                status: props.transfer.booking_status?.status_booking ?? null,
                isManual: false
            });
        }
    }

    useEffect(() => {
        const pictures = getTransferPicture(props.transfer);

        setPictures(
            pictures.length > 0 ?
                pictures.map((picture) => {
                    return findPictureUrl(picture);
                }) :
                []
        );
    }, [props.transfer]);

    if (!pictures) {
        return null;
    }

    return (
        <>
            <CartProductCard
                type="transfer"
                item={props.transfer}
                mainContent={
                    <Stack>
                        <CartProductCardName
                            type="transfer"
                            content={
                                <>
                                    {
                                        (() => {
                                            const localization = props.transfer.localization.find((item) => {
                                                return item.locale === locale;
                                            });
                                            if (localization) {
                                                return localization.name;
                                            } else if (
                                                props.transfer.is_custom
                                            ) {
                                                return props.transfer.custom_product?.title;
                                            }
                                            return props.transfer.name;
                                        })()
                                    }
                                    {
                                        props.transfer.variant &&
                                        ` (${props.transfer.variant.localization?.find((item) => item.locale === locale)?.title ?? props.transfer.variant.name})`
                                    }
                                </>
                            }
                            modifiedName={
                                (() => {
                                    const localization = props.transfer.localization.find((item) => {
                                        return item.locale === locale;
                                    });
                                    if (localization) {
                                        return localization.name;
                                    } else if (
                                        props.transfer.is_custom
                                    ) {
                                        return props.transfer.custom_product?.title ?? '';
                                    }
                                    return props.transfer.name ?? '';
                                })()
                            }
                            providerName={
                                props.transfer.custom_product?.localization?.find((item) => {
                                    return item.locale === locale;
                                })?.title ??
                                props.transfer.custom_product?.title ??
                                props.transfer.name ??
                                ''
                            }
                            localization={props.transfer.localization}
                            id={props.transfer.id}
                            isCustom={props.transfer.is_custom}
                            startIcon={TransferWithinAStationOutlined}
                            isProposition={props.transfer.provider_created}
                        />
                        <CartProductIconText
                            text={
                                <Stack direction="row" alignItems="center" spacing={1.5}>
                                    <span>
                                        {
                                            props.transfer.is_custom ?
                                                props.transfer.origin_name :
                                                props.transfer.station_name_pickup
                                        }
                                    </span>
                                    <ArrowRightAltOutlined />
                                    <span>
                                        {
                                            props.transfer.is_custom ?
                                                props.transfer.destination_name :
                                                props.transfer.station_name_return
                                        }
                                    </span>
                                </Stack>
                            }
                            startIcon={PlaceOutlined}
                        />
                        {
                            isOnRoad &&
                            <CartProductIconText
                                text={
                                    t(
                                        'cart-material.cart-construction-on-road',
                                        {
                                            from: (
                                                isOnRoad[0].destination?.data?.localization.find((item) => {
                                                    return item.locale === locale;
                                                })?.name ??
                                                isOnRoad[0].destination?.data?.name ??
                                                isOnRoad[0].destination?.data?.international_name
                                            )?.split(',')[0],
                                            to: (
                                                isOnRoad[1].destination?.data?.localization.find((item) => {
                                                    return item.locale === locale;
                                                })?.name ??
                                                isOnRoad[1].destination?.data?.name ??
                                                isOnRoad[1].destination?.data?.international_name
                                            )?.split(',')[0]
                                        }
                                    )
                                }
                                startIcon={ExploreOutlined}
                                color="#FF8100"
                            />
                        }
                        <CartProductIconText
                            text={
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <div>
                                        {
                                            !window.moment.utc(props.transfer.start_date).isSame(props.transfer.end_date) &&
                                            t(
                                                'cart-material.cart-construction-products-table-date',
                                                {
                                                    from: window.moment.utc(props.transfer.start_date).format('LLL'),
                                                    to: window.moment.utc(props.transfer.end_date).format('LLL')
                                                }
                                            )
                                        }
                                        {
                                            window.moment.utc(props.transfer.start_date).isSame(props.transfer.end_date) &&
                                            window.moment.utc(props.transfer.start_date).format('LLL')
                                        }
                                    </div>
                                    {
                                        (
                                            !window.moment.utc(props.transfer.provider_start_date).isSame(window.moment.utc(props.transfer.start_date), 'minutes') ||
                                            !window.moment.utc(props.transfer.provider_end_date).isSame(window.moment.utc(props.transfer.end_date), 'minutes')
                                        ) &&
                                        <Tooltip title={t('cart-material.cart-construction-dates-changed-hint')}>
                                            <PanToolOutlined sx={{ fontSize: '1.2em' }} />
                                        </Tooltip>
                                    }
                                    {
                                        (
                                            props.transfer.is_custom ||
                                            props.transfer.booking_status
                                        ) &&
                                        (
                                            quotation_code !== 'visiteurs' ||
                                            user?.client_full?.type !== 2
                                        ) &&
                                        <IconButton size="small" onClick={() => setEditTime(true)}>
                                            <Edit fontSize="inherit" />
                                        </IconButton>
                                    }
                                </Stack>
                            }
                            startIcon={DateRangeOutlined}
                        />
                        <Stack
                            direction={{ md: 'row' }}
                            spacing={{ md: 2 }}
                        >
                            <CartProductIconText
                                text={
                                    [
                                        t(
                                            'roadbook.adults-count',
                                            {
                                                count: props.transfer.group_passenger?.travelers_list.filter((traveler) => {
                                                    const result = window.moment.utc(tripEndDate).diff(
                                                        window.moment.utc(traveler.birth_date),
                                                        'years'
                                                    );
                                                    return result >= 18;
                                                }).length ?? 0
                                            }
                                        ),
                                        (() => {
                                            const childrenCount = props.transfer.group_passenger?.travelers_list.filter((traveler) => {
                                                return window.moment.utc(tripEndDate).diff(
                                                    window.moment.utc(traveler.birth_date),
                                                    'years'
                                                ) < 18;
                                            }).length ?? 0;
                                            return childrenCount > 0 ?
                                                t(
                                                    'roadbook.children-count',
                                                    { count: childrenCount }
                                                ) :
                                                null;
                                        })()
                                    ].filter((item) => item).join(', ')
                                }
                                startIcon={PeopleOutlineOutlined}
                            />
                            {
                                !isProductPackaged({
                                    product: props.transfer,
                                    stackInfos: trip?.stack_info ?? null
                                }) &&
                                !props.transfer.is_cancellable &&
                                <CartProductIconText
                                    text={t('cart-material.not-cancellable')}
                                    startIcon={HighlightOff}
                                />
                            }
                        </Stack>
                        <Stack direction="row" spacing={1}>
                            <CartProductIconText
                                text={
                                    <>
                                        {
                                            props.transfer.is_custom ?
                                                props.transfer.custom_product?.max_passengers :
                                                props.transfer.max_capacity
                                        }
                                    </>
                                }
                                startIcon={GroupAddOutlined}
                            />
                            <CartProductIconText
                                text={
                                    <>
                                        {
                                            props.transfer.is_custom ?
                                                props.transfer.custom_product?.nb_baggage :
                                                props.transfer.baggage
                                        }
                                    </>
                                }
                                startIcon={WorkOutlineOutlined}
                            />
                        </Stack>
                    </Stack>
                }
                sideContent={
                    <Stack spacing={1} alignItems="center">
                        <Typography variant="caption" sx={{ opacity: 0.6 }}>
                            {t('cart-material.cart-construction-amount')}
                        </Typography>
                        <ProviderQuotationPriceEdit
                            productId={props.transfer.id}
                            price={
                                (
                                    providerContext.module ?
                                        totalCost.purchaseCost :
                                        totalCost.cost
                                ).toString()
                            }
                            open={editPrice}
                            bothEntitiesAccepted={
                                props.transfer.provider_accepted &&
                                props.transfer.agent_accepted
                            }
                            providerWantsModification={props.transfer.provider_want_modification}
                            onOpen={() => setEditPrice(true)}
                            onClose={() => setEditPrice(false)}
                        >
                            <Typography fontWeight="bold" textAlign="center">
                                {
                                    new Intl.NumberFormat(
                                        i18n.language,
                                        {
                                            style: 'currency',
                                            currency: providerContext.module ?
                                                totalCost.purchaseCurrency?.iso_code ?? 'EUR' :
                                                totalCost.currency?.iso_code ?? 'EUR'
                                        }
                                    ).format(
                                        providerContext.module ?
                                            totalCost.purchaseCost :
                                            totalCost.cost
                                    )
                                }
                                {
                                    !providerContext.module &&
                                    props.transfer.price_change &&
                                    props.transfer.price_change.price_variation !== 0 &&
                                    <Stack direction="row" alignItems="center" justifyContent="center">
                                        <Typography
                                            variant="caption"
                                            sx={{
                                                color: props.transfer.price_change.price_variation < 0 ? 'green' : 'red',
                                                fontSize: '10px'
                                            }}
                                        >
                                            {
                                                new Intl.NumberFormat(
                                                    i18n.language,
                                                    {
                                                        style: 'currency',
                                                        currency: totalCost.currency?.iso_code ?? 'EUR'
                                                    }
                                                ).format(props.transfer.price_change.price_variation)
                                            }
                                        </Typography>
                                        <ArrowRightAltOutlined
                                            sx={{
                                                transform: props.transfer.price_change.price_variation < 0 ?
                                                    'rotateZ(90deg)' :
                                                    'rotateZ(-90deg)',
                                                color: 'green',
                                                fontSize: '14px'
                                            }}
                                        />
                                    </Stack>
                                }
                            </Typography>
                        </ProviderQuotationPriceEdit>
                        <CartProductCardProviderLogo
                            logoUrl={
                                provider?.logo?.url ??
                                provider?.logo?.thumbnail_big ??
                                provider?.logo?.thumbnail_medium ??
                                provider?.logo?.thumbnail_little ??
                                null
                            }
                            name={provider?.name ?? null}
                        />
                    </Stack>
                }
                footer={(providerActions, providerInfo) => (
                    <>
                        <Stack direction="row" alignItems="center" spacing={2}>
                            {
                                insertDivider(
                                    [
                                        !isProductPackaged({
                                            product: props.transfer,
                                            stackInfos: trip?.stack_info ?? null,
                                            connected: true
                                        }) &&
                                        <>
                                            <CartConstructionProductsTableItemStatus
                                                type="transfer"
                                                item={props.transfer}
                                                bookingProcessState={props.transfer.booking_process_state}
                                                bookingStatus={props.transfer.booking_status}
                                                agencyNeedToBook={props.transfer.agency_need_to_book}
                                            />
                                            {
                                                !trip?.stack_info?.find((item) => {
                                                    return isProductSamePackage(
                                                        {
                                                            stack_number: item.stack_number,
                                                            stack_info_id: item.is_custom && !props.transfer.stack_info_id ?
                                                                props.transfer.stack_info_id :
                                                                item.id
                                                        },
                                                        {
                                                            stack_number: props.transfer.stack_number,
                                                            stack_info_id: props.transfer.stack_info_id
                                                        }
                                                    );
                                                })?.booking_status?.item_reference &&
                                                (
                                                    props.transfer.booking_status?.status_booking === StatusBooking.CONFIRMED ||
                                                    props.transfer.booking_status?.status_booking === StatusBooking.PENDING ||
                                                    props.transfer.booking_status?.status_booking === StatusBooking.WAITING
                                                ) &&
                                                <ProviderBookingReferenceEdit
                                                    open={editBookingReference}
                                                    productId={props.transfer.id}
                                                    reference={props.transfer.booking_status?.item_reference ?? ''}
                                                    onOpen={() => setEditBookingReference(true)}
                                                    onClose={() => setEditBookingReference(false)}
                                                    onSave={onChangeBookingReference}
                                                >
                                                    <Typography fontWeight={100} fontSize="0.75rem">
                                                        {
                                                            t(
                                                                'cart-material.cart-construction-reference',
                                                                { ref: props.transfer.booking_status?.item_reference }
                                                            )
                                                        }
                                                    </Typography>
                                                </ProviderBookingReferenceEdit>
                                            }
                                        </>,
                                        providerActions,
                                        !providerContext.module &&
                                        !isProductPackaged({
                                            product: props.transfer,
                                            stackInfos: trip?.stack_info ?? null,
                                            connected: true
                                        }) &&
                                        (
                                            user?.client_full?.type !== 2 ||
                                            props.transfer.creator?.client?.type === user.client_full.type
                                        ) &&
                                        <>
                                            {
                                                !props.transfer.is_custom ?
                                                    <Chip
                                                        label={t('cart-material.flux')}
                                                        size="small"
                                                        sx={{
                                                            backgroundColor: '#2ACAEA',
                                                            color: '#fff'
                                                        }}
                                                        icon={<CloudCircle color="inherit" />}
                                                    /> :
                                                    <Chip
                                                        size="small"
                                                        label={t('cart-material.offline')}
                                                        sx={{
                                                            backgroundColor: '#6A329F',
                                                            color: '#fff'
                                                        }}
                                                        icon={<CloudOff color="inherit" />}
                                                    />
                                            }
                                        </>,
                                        user?.client_full?.type !== 2 &&
                                        props.transfer.traveler_modification &&
                                        <Chip
                                            color="warning"
                                            label={
                                                props.transfer.traveler_modification === 'SET_IN_TRIP' ?
                                                    t('cart-material.add-to-cart-traveler') :
                                                    t('cart-material.add-to-option-traveler')
                                            }
                                            size="small"
                                            icon={<ErrorOutline color="inherit" />}
                                            onDelete={confirmTravelerModification}
                                        />,
                                        props.transfer.booking_status?.status_booking !== StatusBooking.CONFIRMED &&
                                        props.transfer.on_request &&
                                        <Typography variant="caption">
                                            {t('cart-material.on-request')}
                                        </Typography>,
                                        !isProductPackaged({
                                            product: props.transfer,
                                            stackInfos: trip?.stack_info ?? null
                                        }) &&
                                        !props.transfer.is_cancellable &&
                                        <Box sx={{ whiteSpace: 'nowrap' }}>
                                            <CartProductIconText
                                                text={t('cart-material.not-cancellable')}
                                                startIcon={ReportProblemOutlined}
                                                color="#FF8100"
                                            />
                                        </Box>
                                    ]
                                )
                            }
                        </Stack>
                        {providerInfo}
                    </>
                )}
                alerts={
                    <Collapse in={!found || !!iti_error} unmountOnExit>
                        <CardContent
                            sx={{
                                borderTop: !found || !!iti_error ?
                                    '1px solid rgba(0, 0, 0, 0.25)' :
                                    undefined
                            }}
                        >
                            <Stack spacing={1}>
                                {
                                    iti_error &&
                                    <Alert variant="filled" severity="error" sx={{ backgroundColor: '#E74432' }}>
                                        {t('cart-material.itinerary-error-1')}{' '}
                                        {
                                            iti_error.destination?.data?.localization.find((item) => {
                                                return item.locale === locale;
                                            })?.name ??
                                            iti_error.destination?.data?.name ??
                                            iti_error.destination?.data?.international_name
                                        } {t('global.from')}{' '}
                                        {window.moment.utc(iti_error.start_date).format('DD/MM/YYYY HH:mm')}{' '}
                                        {t('global.to')} {window.moment.utc(iti_error.end_date).format('DD/MM/YYYY HH:mm')}.{' '}
                                        {t('cart-material.itinerary-error-2')}.
                                    </Alert>
                                }
                                {
                                    !found &&
                                    <Alert variant="filled" severity="error" sx={{ backgroundColor: '#E74432' }}>
                                        {t('cart-material.no-destination-found')}
                                    </Alert>
                                }
                            </Stack>
                        </CardContent>
                    </Collapse>
                }
                margin={<MarginWrapper transfer={props.transfer} />}
                pictures={pictures}
                alwaysOpen={props.alwaysOpen}
                showEntirePictures
            >
                <CartTransferCardDetails transfer={props.transfer} />
            </CartProductCard>
            {
                user?.client_full?.type !== 2 &&
                editTime &&
                <CartClientTimeModal
                    type="normal"
                    product={props.transfer}
                    onClose={() => setEditTime(false)}
                />
            }
            <LoadingBackDrop open={loading} />
        </>
    );
}

type MarginWrapperProps = {
    transfer: TransferCart
}

function MarginWrapper(props: MarginWrapperProps): JSX.Element {
    const trip = useSelector((state: AppState) => state.trip.data_trip);
    const seeAllProductsMargins = useSelector((state: AppState) => state.cartConstruction.seeProductsMargins);

    return (
        <Collapse
            in={
                seeAllProductsMargins &&
                (
                    !isProductPackaged({
                        product: props.transfer,
                        stackInfos: trip?.stack_info ?? null
                    }) ||
                    !props.transfer.is_stack_price
                )
            }
        >
            <CardContent
                sx={{
                    borderTop: seeAllProductsMargins ?
                        '1px solid rgba(0, 0, 0, 0.25)' :
                        undefined
                }}
            >
                <CartConstructionProductsTableItemMargin
                    type="transfer"
                    item={props.transfer}
                />
            </CardContent>
        </Collapse>
    );
}
