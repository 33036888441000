//Dependencies
import clsx from 'clsx';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState, Fragment, useRef, useMemo, useContext } from 'react';
import { isNumber } from 'lodash';
//Core
import { Box, Stack, Typography } from '@mui/material';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { makeStyles } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';

//Icons
import Close from '@material-ui/icons/Close';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";
import HelpOutline from "@material-ui/icons/HelpOutline";
import ReportProblem from '@material-ui/icons/ReportProblemOutlined';
import { EditorState } from 'draft-js';

//Components
import CartPoiNote from './CartPoiNote';
import CartPoiManualBooking from './CartPoiManualBooking';
import CartPoiManualCancellationRules from './CartPoiManualCancellationRules';
import {
    CartConstructionProductsTableItemProviderQuotationStatus
} from './CartConstructionProductsTableItemProviderQuotationStatus';
import {
    CartPackageItemChangeProviderQuotationStatusButtons
} from './CartPackageItemChangeProviderQuotationStatusButtons';
import { CartConstructionProviderQuotationStatusLayout } from './CartConstructionProviderQuotationStatusLayout';
import RichEditor, { RichEditorStyleMap } from '../Menu/MaterialTripList/utils/editor/editor';

//Common
import { htmlHasEmptyContent } from '../Menu/MaterialTripList/utils/htmlHasEmptyContent';
import { renderRichEditorContentHTML } from '../Menu/MaterialTripList/utils/editor/renderRichEditorContentHtml';
import { restoreRichEditorStateFromHtml } from '../Menu/MaterialTripList/utils/editor/restoreRichEditorStateFromHtml';
import { isProductPackaged } from './utils/isProductPackaged';
import { createDomObjectsFromHtml } from '../Menu/MaterialTripList/MailVisualEditor/utils/dom';
import { fixNodeEmptyContent } from '../Menu/MaterialTripList/MailVisualEditor/utils/fixNodeEmptyContent';
import { customColors } from '../Menu/MaterialTripList/utils/editor/customColors';
import { ProviderContext } from './utils/providerContext';
import GetCookie from '../Common/Functions/GetCookie';
import CheckBeforeRequest from '../Common/CheckBeforeRequest';
import GetPricesId from '../Cart/Functions/Margin/GetPricesId';

//Actions
import moment from 'moment';
import { PoiCart } from '../Itinerary/objects/poiCart';
import { Currency } from '../../Reducers/objects/currency';
import { ProductPrice } from '../Itinerary/objects/productPrice';
import { StatusBooking } from '../Itinerary/objects/statusBooking';
import { AppState } from '../../Reducers/Reducers';

type Props = {
    poi: PoiCart
}

const EmptyState = EditorState.createEmpty();

export const CartPoiCarDetails = withRouter<Props>(
    function CartPoiCarDetails({ poi }): JSX.Element {
        const classes = useStyles();
        const { t, i18n } = useTranslation();
        const dispatch = useDispatch();
        const { enqueueSnackbar } = useSnackbar();
        const quotation_code = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;

        const locale = useSelector((state: AppState) => {
            return state.user.locales.find((item) => {
                return item.language_code === i18n.language;
            })?.id ?? 1;
        });
        const user = useSelector((state: AppState) => state.user?.user);
        const currency = useSelector((state: AppState) => state.trip.currency);
        const trip = useSelector((state: AppState) => state.trip.data_trip);
        const language = useSelector((state: AppState) => state.header.tmp_language);
        const currency_list = useSelector((state: AppState) => state.base.currency_list);
        const see_new_price = useSelector((state: AppState) => state.cart.see_new_price);

        const [showNote, setShowNote] = useState(false);
        const [showManualCancellationRules, setShowManualCancellationRules] = useState(false);
        const [showManualBooking, setShowManualBooking] = useState(false);
        const [onRequest, setOnRequest] = useState(false);
        const [margin, setMargin] = useState<{
            id?: number,
            ids?: {
                room_id: number,
                price_id: number
            }[],
            rate_type: 'PER' | 'FIX',
            purchase: number,
            purchase_converted: number,
            purchase_currency?: Currency,
            factor_used: string,
            value: number,
            static_value?: number,
            markup: boolean,
            percent: number,
            static_percent?: number,
            selling: number,
            selling_currency?: Currency,
            custom_rate?: boolean,
            custom_rate_type?: string
        } | null>(null);
        const [surcom, setSurcom] = useState<{
            value?: number,
            static?: number,
            purchase: number,
            selling: number,
            percent: number,
            currency: Currency | null
        } | null>(null);
        const [editDescription, setEditDescription] = useState(false);
        const [newDescription, setNewDescription] = useState(EmptyState);
        const [marginValue, setMarginValue] = useState<number | null>(null);
        const [marginPercent, setMarginPercent] = useState<number | null>(null);
        const description = useMemo(() => {
            let description = '';

            const localization = poi.localization.find((localization) => {
                return localization.locale === locale;
            });
            const providerLocalization = poi.custom_product?.localization?.find((localization) => {
                return localization.locale === locale;
            });

            if (!htmlHasEmptyContent(localization?.description ?? '')) {
                return localization?.description ?? '';
            } else if (!htmlHasEmptyContent(poi.description ?? '')) {
                return poi.description ?? '';
            }

            if (!htmlHasEmptyContent(providerLocalization?.short_description ?? '')) {
                description = providerLocalization?.short_description ?? '';
            } else if (!htmlHasEmptyContent(poi.custom_product?.short_description ?? '')) {
                description = poi.custom_product?.short_description ?? '';
            }

            const variantDescription = (
                // eslint-disable-next-line no-nested-ternary
                poi.cust_variant &&
                    !isNumber(poi.cust_variant) ?
                    poi.cust_variant.localization?.find((item) => {
                        return item.locale === locale;
                    })?.short_description ??
                    poi.cust_variant.short_description :
                    (
                        poi.variant && !isNumber(poi.variant) ?
                            poi.variant.localization?.find((item) => {
                                return item.locale === locale;
                            })?.short_description ??
                            poi.variant?.short_description :
                            null
                    )
            );

            if (!htmlHasEmptyContent(variantDescription ?? '')) {
                description += variantDescription;
            }

            const { document } = createDomObjectsFromHtml(description);
            fixNodeEmptyContent(document.body);

            return document.body.innerHTML;
        }, [poi, locale]);

        const manualBookingRef = useRef<HTMLDivElement>(null);
        const providerContext = useContext(ProviderContext);

        const showCancellationDetail = () => {
            dispatch({ type: 'CART_TOGGLE_CANCELLATION_DETAIL', payload: { room: poi, currency: getPrice(poi.prices).currency } });
        };

        const scrollIntoManuaBookingView = () => {
            manualBookingRef.current?.scrollIntoView({ block: 'center', inline: 'center', behavior: 'smooth' });
        };

        const onManualBooking = () => {
            setShowManualBooking(!showManualBooking);
        };

        const onAddNote = () => {
            setShowNote(!showNote);
        };

        const onDeleteNote = () => {
            const { headers } = CheckBeforeRequest();
            axios({
                method: 'PATCH',
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/custom-products/${poi.id}/`,
                data: {
                    custom_information: null
                }
            }).then(response => {
                dispatch({ type: 'POI_EDIT_CART_BY_ID', payload: response.data });
                enqueueSnackbar(t('cart-material.note-deleted'), { variant: 'success' });
            }).catch(error => {
                console.log(error);
                enqueueSnackbar(t('cart-material.note-not-deleted'), { variant: 'error' });
            });
        };

        const onManualCancellationRules = () => {
            setShowManualCancellationRules(!showManualCancellationRules);
        };

        const onDeleteManualCancellationRules = () => {
            const { headers } = CheckBeforeRequest();
            axios({
                method: 'PATCH',
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/custom-products/${poi.id}/`,
                data: {
                    no_refund: true,
                    cancel_condition_text: null
                }
            }).then(response => {
                dispatch({ type: 'POI_EDIT_CART_BY_ID', payload: response.data });
                setShowManualCancellationRules(!showManualCancellationRules);
            }).catch(error => {
                console.log(error);
                setShowManualCancellationRules(!showManualCancellationRules);
            });
        };

        const priceToDisplay = (price: ProductPrice) => {
            return {
                cost: parseFloat(price.selling_price),
                currency: currency_list.find(currency => currency.id === price.selling_currency)
            };
        };

        const getPrice = (prices: ProductPrice[]) => {
            if (user?.client_full?.type === 1 || user?.client_full?.type === 3 || quotation_code === 'verdie') {
                for (let i = 0; i < prices.length; i++) {
                    if (prices[i]!.is_tva) {
                        return priceToDisplay(prices[i]!);
                    }
                }
                for (let i = 0; i < prices.length; i++) {
                    if (prices[i]!.is_surcom) {
                        return priceToDisplay(prices[i]!);
                    }
                }
                for (let i = 0; i < prices.length; i++) {
                    if (prices[i]!.master_price) {
                        return priceToDisplay(prices[i]!);
                    }
                }
                for (let i = 0; i < prices.length; i++) {
                    if (prices[i]!.owner === user?.client) {
                        return priceToDisplay(prices[i]!);
                    }
                }
            } else {
                for (let i = 0; i < prices.length; i++) {
                    if (prices[i]!.is_tva) {
                        return priceToDisplay(prices[i]!);
                    }
                }
                for (let i = 0; i < prices.length; i++) {
                    if (prices[i]!.owner === user?.client) {
                        return priceToDisplay(prices[i]!);
                    }
                }
            }
            return {
                cost: 0,
                currency: currency
            };
        };

        const getMargin = (
            tmp_margin: NonNullable<typeof margin>,
            tmp_surcom: NonNullable<typeof surcom>,
            prices: ProductPrice[]
        ) => {
            if (user?.client_full?.type === 1 || user?.client_full?.type === 3 || quotation_code === 'verdie') {
                for (let i = 0; i < prices.length; i++) {
                    if (prices[i]!.is_surcom) {
                        tmp_surcom.purchase += parseFloat(prices[i]!.purchase_price) * parseFloat(prices[i]!.factor_used);
                        tmp_surcom.selling += parseFloat(prices[i]!.selling_price);
                        tmp_surcom.currency = currency_list.find(currency => currency.id === prices[i]!.selling_currency) ?? null;
                        tmp_surcom.percent += parseFloat(prices[i]!.percentage_between);
                        break;
                    }
                }
                for (let i = 0; i < prices.length; i++) {
                    if (prices[i]!.master_price) {
                        tmp_margin.purchase += parseFloat(prices[i]!.purchase_price);
                        tmp_margin.purchase_converted += parseFloat(prices[i]!.purchase_price) * parseFloat(prices[i]!.factor_used);
                        tmp_margin.factor_used = prices[i]!.factor_used;
                        tmp_margin.purchase_currency = currency_list.find(currency => currency.id === prices[i]!.purchase_currency);
                        tmp_margin.selling += parseFloat(prices[i]!.selling_price);
                        tmp_margin.selling_currency = currency_list.find(currency => currency.id === prices[i]!.selling_currency);
                        tmp_margin.percent += parseFloat(prices[i]!.percentage_between);
                        tmp_margin.custom_rate = prices[i]!.custom_rate;
                        tmp_margin.custom_rate_type = prices[i]!.custom_rate_type ?? undefined;
                        tmp_margin.id = prices[i]!.id;
                        break;
                    }
                }
                if (tmp_margin.id === null) {
                    for (let i = 0; i < prices.length; i++) {
                        if (prices[i]!.owner === user?.client) {
                            tmp_margin.purchase += parseFloat(prices[i]!.purchase_price);
                            tmp_margin.purchase_converted += parseFloat(prices[i]!.purchase_price) * parseFloat(prices[i]!.factor_used);
                            tmp_margin.factor_used = prices[i]!.factor_used;
                            tmp_margin.purchase_currency = currency_list.find(currency => currency.id === prices[i]!.purchase_currency);
                            tmp_margin.selling += parseFloat(prices[i]!.selling_price);
                            tmp_margin.selling_currency = currency_list.find(currency => currency.id === prices[i]!.selling_currency);
                            tmp_margin.percent += parseFloat(prices[i]!.percentage_between);
                            tmp_margin.custom_rate = prices[i]!.custom_rate;
                            tmp_margin.custom_rate_type = prices[i]!.custom_rate_type ?? undefined;
                            tmp_margin.id = prices[i]!.id;
                            break;
                        }
                    }
                }
            } else {
                for (let i = 0; i < prices.length; i++) {
                    if (prices[i]!.owner === user?.client) {
                        tmp_margin.purchase += parseFloat(prices[i]!.purchase_price);
                        tmp_margin.purchase_converted += parseFloat(prices[i]!.purchase_price) * parseFloat(prices[i]!.factor_used);
                        tmp_margin.factor_used = prices[i]!.factor_used;
                        tmp_margin.purchase_currency = currency_list.find(currency => currency.id === prices[i]!.purchase_currency);
                        tmp_margin.selling += parseFloat(prices[i]!.selling_price);
                        tmp_margin.selling_currency = currency_list.find(currency => currency.id === prices[i]!.selling_currency);
                        tmp_margin.percent += parseFloat(prices[i]!.percentage_between);
                        tmp_margin.id = prices[i]!.id;
                        break;
                    }
                }
            }
        };

        const onEditDescription = () => {
            setEditDescription(true);
        };

        const onDescriptionCancel = () => {
            setEditDescription(false);
        };

        const onAnswerQuestions = () => {
            dispatch({ type: 'MATERIAL_POI_OPEN_QUESTIONS', payload: { id: poi.id, questions: poi.questions } });
        };

        const onDescriptionSave = () => {
            const { headers } = CheckBeforeRequest();
            let localization = poi.localization;
            const styles = {
                ...RichEditorStyleMap,
                ...(quotation_code ? customColors[quotation_code] : null)
            };
            if (
                poi.localization.findIndex((item) => {
                    return item.locale === locale;
                }) >= 0
            ) {
                localization = poi.localization.map((item) => {
                    if (item.locale === locale) {
                        return {
                            ...item,
                            description: renderRichEditorContentHTML(
                                styles,
                                newDescription.getCurrentContent()
                            )
                        };
                    }
                    return item;
                });
            } else {
                localization = poi.localization.concat([
                    {
                        locale: locale,
                        cancel_text_condition: poi.cancel_condition_text ?? '',
                        name: poi.custom_product?.localization?.find((localization) => {
                            return localization.locale === locale;
                        })?.title ??
                            poi.custom_product?.title ??
                            '',
                        description: renderRichEditorContentHTML(
                            styles,
                            newDescription.getCurrentContent()
                        ),
                        long_description: null
                    }
                ]);
            }
            axios({
                method: 'PATCH',
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/custom-products/${poi.id}/`,
                data: { localization }
            }).then(response => {
                dispatch({ type: 'POI_EDIT_CART_BY_ID', payload: response.data });
                setEditDescription(false);
            }).catch(error => {
                console.log(error);
            });
        };

        const confirmCustomInformationRecalc = () => {
            const { headers } = CheckBeforeRequest();
            axios({
                method: 'PATCH',
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/custom-products/${poi.id}/`,
                data: {
                    moved_with_recalc_custom_information: false
                }
            }).then(response => {
                dispatch({ type: 'POI_EDIT_CART_BY_ID', payload: response.data });
            }).catch(error => {
                console.log(error);
            });
        };

        const confirmCancelConditionRecalc = () => {
            const { headers } = CheckBeforeRequest();
            axios({
                method: 'PATCH',
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/custom-products/${poi.id}/`,
                data: {
                    moved_with_recalc_cancel_condition_text: false
                }
            }).then(response => {
                dispatch({ type: 'POI_EDIT_CART_BY_ID', payload: response.data });
            }).catch(error => {
                console.log(error);
            });
        };

        const handleSeeNewPrice = () => {
            dispatch({
                type: 'CART_SEE_NEW_PRICE_MODAL',
                payload: {
                    see_new_price: !see_new_price,
                    data: see_new_price ? null : {
                        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/custom-products/${poi.id}/`,
                        id: poi.id,
                        old_price: poi.prices[GetPricesId(poi.prices, user?.client, user, true)],
                        new_price: poi.provider_wanted_price,
                        product: poi,
                        custom_dispatch: 'POI_EDIT_CART_BY_ID'
                    }
                }
            });
        };

        useEffect(() => {
            const styles = {
                ...RichEditorStyleMap,
                ...(quotation_code ? customColors[quotation_code] : null)
            };
            setNewDescription(
                restoreRichEditorStateFromHtml(
                    styles,
                    description
                )
            );
        }, [poi, description]);

        useEffect(() => {
            if (marginValue && marginValue !== margin?.value) {
                let tmp_margin = margin ?
                    { ...margin } :
                    null;

                if (tmp_margin) {
                    tmp_margin.value = marginValue;
                    tmp_margin.rate_type = 'FIX';
                    if (tmp_margin.markup) {
                        tmp_margin.percent = parseFloat(((tmp_margin.value / tmp_margin.purchase_converted) * 100).toFixed(2));
                    } else {
                        tmp_margin.percent = parseFloat(((tmp_margin.value / tmp_margin.selling) * 100).toFixed(2));
                    }
                    setMarginPercent(tmp_margin.percent);
                    setMargin(tmp_margin);
                }
            }
        }, [marginValue]);

        useEffect(() => {
            if (marginPercent && marginPercent !== margin?.percent) {
                let tmp_margin = margin ?
                    { ...margin } :
                    null;
                if (tmp_margin) {
                    tmp_margin.percent = marginPercent;
                    tmp_margin.rate_type = 'PER';
                    if (tmp_margin.markup) {
                        tmp_margin.value = parseFloat((tmp_margin.purchase_converted * (tmp_margin.percent / 100)).toFixed(2));
                    } else {
                        tmp_margin.value = parseFloat((tmp_margin.selling * (tmp_margin.percent / 100)).toFixed(2));
                    }
                    setMarginValue(tmp_margin.value);
                    setMargin(tmp_margin);
                }
            }
        }, [marginPercent]);

        useEffect(() => {
            if (showNote) {
                const element = document.getElementById(`poi-${poi.id}-note`);
                if (element) {
                    element.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
                }
            }
        });

        useEffect(() => {
            if (showManualCancellationRules) {
                const element = document.getElementById(`poi-${poi.id}-manual-cancellation-rules`);
                if (element) {
                    element.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
                }
            }
        });

        useEffect(() => {
            if (showManualBooking) {
                scrollIntoManuaBookingView();
            }
        });

        useEffect(() => {
            let total_cost: {
                cost: number,
                currency?: Currency
            }[] = [];
            let on_request = false;
            let tmp_margin: NonNullable<typeof margin> = {
                factor_used: '0',
                markup: false,
                purchase: 0,
                purchase_converted: 0,
                selling: 0,
                value: 0,
                percent: 0,
                rate_type: 'PER',
                ids: []
            };
            let tmp_surcom: NonNullable<typeof surcom> = {
                purchase: 0,
                selling: 0,
                value: 0,
                percent: 0,
                currency: null
            };
            if (poi.on_request) {
                on_request = true;
            }
            let price = getPrice(poi.prices);
            getMargin(tmp_margin, tmp_surcom, poi.prices);
            let matched = false;
            total_cost.map(currency_cost => {
                if (currency_cost.currency?.id === price.currency?.id) {
                    matched = true;
                    currency_cost.cost += price.cost;
                }
            });
            if (!matched) {
                total_cost.push({
                    cost: price.cost,
                    currency: price.currency
                });
            }
            tmp_margin.value = parseFloat((tmp_margin.selling - tmp_margin.purchase_converted).toFixed(2));
            tmp_margin.static_value = tmp_margin.value;
            tmp_margin.markup = Math.abs(tmp_margin.percent - (100 - (tmp_margin.purchase_converted / tmp_margin.selling) * 100)) > Math.abs(tmp_margin.percent - ((tmp_margin.value / tmp_margin.purchase_converted) * 100));
            tmp_margin.static_percent = tmp_margin.percent;
            tmp_margin.static_percent = tmp_margin.percent;
            tmp_surcom.static = tmp_surcom.value;
            tmp_margin.custom_rate = tmp_margin.custom_rate;
            tmp_surcom.value = tmp_surcom.selling - tmp_surcom.purchase;
            setMargin(tmp_margin);
            setSurcom(tmp_surcom);
            setOnRequest(on_request);
        }, [poi]);

        return (
            <>
                <Grid
                    id={`product-${poi.id}`}
                    className={classes.scrollMargin}
                    container
                    item
                    xs
                    direction={"column"}
                    spacing={2}
                >
                    <Grid item xs={12}>
                        <Grid container direction="row" justify={'space-between'} alignItems={'center'}>
                            {
                                user?.client_full?.type !== 2 &&
                                <Grid item xs={12}>
                                    <CartConstructionProviderQuotationStatusLayout
                                        status={
                                            <CartConstructionProductsTableItemProviderQuotationStatus
                                                item={poi}
                                            />
                                        }
                                        buttons={
                                            <CartPackageItemChangeProviderQuotationStatusButtons
                                                item={{
                                                    ...poi,
                                                    stackNumber: poi.stack_number,
                                                    providerId: poi.provider,
                                                    type: 'pois',
                                                    isCustom: poi.is_custom
                                                }}
                                            />
                                        }
                                    />
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                    <Grid container item xs spacing={2}>
                        <Grid item xs style={{ paddingLeft: 18 }}>
                            <Grid container>
                                <Grid item xs>
                                    {
                                        !isProductPackaged({
                                            product: poi,
                                            stackInfos: trip?.stack_info ?? null
                                        }) &&
                                        !poi.poi_type &&
                                        <Fragment>
                                            {
                                                poi.is_cancellable && (
                                                    <div className={classes.containerMargin}>
                                                        <CheckCircleOutline className={classes.roomIcon} />
                                                        <Typography className={classes.inlineBlock}>
                                                            {
                                                                (
                                                                    (poi.charges?.length ?? 0) > 0 ||
                                                                    parseFloat(poi.cancellation_amount ?? '0') > 0
                                                                ) ?
                                                                    t('cart-material.cancellable-with-conditions') :
                                                                    t('cart-material.cancellable')
                                                            }
                                                        </Typography>
                                                        {
                                                            (
                                                                (poi.charges?.length ?? 0) > 0 ||
                                                                parseFloat(poi.cancellation_amount ?? '0') > 0
                                                            ) &&
                                                            <HelpOutline
                                                                onClick={showCancellationDetail}
                                                                className={classes.cancellationInfo}
                                                            />
                                                        }
                                                    </div>
                                                )
                                            }
                                        </Fragment>
                                    }
                                    {
                                        !editDescription && (
                                            <Fragment>
                                                <Box
                                                    className={classes.containerMargin}
                                                    sx={{
                                                        '& p': {
                                                            marginTop: '0 !important',
                                                            marginBottom: '0 !important'
                                                        }
                                                    }}
                                                >
                                                    <Typography dangerouslySetInnerHTML={{ __html: description }} />
                                                </Box>
                                                {
                                                    !providerContext.module &&
                                                    (
                                                        quotation_code !== "visiteurs" ||
                                                        user?.client_full?.type !== 2
                                                    ) &&
                                                    <Typography className={classes.contractPack} style={{ float: "right" }} onClick={onEditDescription}>
                                                        {t("cart-material.edit-description")}
                                                    </Typography>
                                                }
                                            </Fragment>
                                        )
                                    }
                                    {
                                        editDescription && (
                                            <Fragment>
                                                <Box sx={{ marginBottom: 1 }}>
                                                    <RichEditor
                                                        values={{}}
                                                        pictures={null}
                                                        editorState={newDescription}
                                                        setEditorState={setNewDescription}
                                                        controlledState
                                                    />
                                                </Box>
                                                <Button variant={'contained'} className={classes.validateButton} style={{ marginRight: 10 }} onClick={onDescriptionCancel} >{t('global.cancel')}</Button>
                                                <Button variant={'contained'} className={classes.validateButton} onClick={onDescriptionSave}>{t('global.validate')} </Button>
                                            </Fragment>
                                        )
                                    }
                                </Grid>
                            </Grid>
                            <Grid className={classes.bookingContainer} item xs>
                                {
                                    !isProductPackaged({
                                        product: poi,
                                        stackInfos: trip?.stack_info ?? null
                                    }) &&
                                    poi.poi_type !== true &&
                                    <Fragment>
                                        {
                                            poi.booking_status?.status_booking !== StatusBooking.CONFIRMED &&
                                            onRequest &&
                                            <Typography>{t('cart-material.on-request')}</Typography>
                                        }
                                        {
                                            poi.price_change && (poi.price_change.price_variation !== 0 || !poi.price_change.is_available) &&
                                            <Grid container justify={'flex-end'} alignItems={'center'}>
                                                {
                                                    poi.price_change.is_available &&
                                                    <Grid item>
                                                        {poi.price_change.price_variation > 0 ? <ArrowUpward className={classes.red} /> : <ArrowDownward className={classes.green} />}
                                                    </Grid>
                                                }
                                                <Grid item>
                                                    <Typography className={poi.price_change.price_variation > 0 || !poi.price_change.is_available ? classes.red : classes.green}>
                                                        {
                                                            poi.price_change.is_available &&
                                                            poi.price_change.price_variation.toLocaleString(
                                                                language,
                                                                { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                                                            )
                                                        }
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        }
                                    </Fragment>
                                }
                                {
                                    !poi.poi_type && poi.booking_status && poi.booking_status.status_booking === 'CANCELLED' && poi.cancellation_charged_amount &&
                                    <Typography className={clsx(classes.booking, classes.error)}>
                                        {t('flight_search.cancel_fee')} :{' '}
                                        {
                                            new Intl.NumberFormat(
                                                language,
                                                {
                                                    style: 'currency',
                                                    currency: currency_list.find((currency) => currency.id === poi.prices[GetPricesId(poi.prices, user?.client, user, true)]?.purchase_currency)?.iso_code,
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2
                                                }
                                            ).format(poi.cancellation_charged_amount)}
                                    </Typography>
                                }
                            </Grid>
                            {
                                poi.booking_status && poi.booking_status.status_booking === 'UNAVAILABLE' &&
                                <Grid item xs={12}>
                                    <Alert className={classes.errorAlert} classes={{ icon: classes.alertIcon }} variant={'filled'} severity={'error'}>
                                        {t('cart-material.booking-unavailable')} : {poi.booking_status.item_reference}
                                    </Alert>
                                </Grid>
                            }
                            {
                                poi.booking_status && (poi.booking_status.status_booking === 'ERROR' || poi.booking_status.status_booking === 'UNAVAILABLE') && poi.booking_errors && poi.booking_errors.length > 0 &&
                                <Grid item xs={12}>
                                    <Alert className={classes.errorAlert} classes={{ icon: classes.alertIcon }} variant={'filled'} severity={'error'}>
                                        <AlertTitle>{t('cart-material.error')} :</AlertTitle>
                                        {poi.booking_errors.map(error => <Typography key={`booking-error-${error.created_date}`}>{moment.utc(error.created_date).format('DD/MM/YYYY')} : {error.detail}</Typography>)}
                                    </Alert>
                                </Grid>
                            }
                            {
                                showManualBooking &&
                                <Grid item xs={12} style={{ marginTop: 15 }} ref={manualBookingRef}>
                                    <CartPoiManualBooking poi={poi} onManualBooking={onManualBooking} />
                                </Grid>
                            }
                            {
                                !providerContext.module &&
                                <>
                                    {
                                        showNote ? (
                                            <Grid id={`poi-${poi.id}-note`} item xs={12}>
                                                <CartPoiNote poi={poi} onAddNote={onAddNote} />
                                            </Grid>
                                        ) : poi.custom_information && (
                                            <Grid item xs={12}>
                                                <Alert className={classes.cancelConditionAlert} variant={'filled'} severity={'info'} action={
                                                    <Grid container direction={'column'} spacing={2} alignItems={'flex-end'}>
                                                        <Grid item>
                                                            <Grid container spacing={2} alignItems={'center'}>
                                                                <Grid item>
                                                                    <Button className={classes.editCustomInformation} color={'inherit'} size={'small'} onClick={onAddNote}>
                                                                        {t('global.edit')}
                                                                    </Button>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Close className={classes.deleteCustomInformation} onClick={onDeleteNote} />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item style={{ width: '100%' }}>
                                                            {
                                                                poi.moved_with_recalc_custom_information &&
                                                                <Tooltip title={t('cart-material.recalc-product-note')}>
                                                                    <div
                                                                        className={clsx(classes.traverlerModification, classes.delete)}
                                                                        onClick={confirmCustomInformationRecalc}
                                                                        style={{ fontSize: 13, color: 'white', background: '#ff9800', border: '2px solid #ff9800' }}
                                                                    >
                                                                        <Grid container alignItems={'center'} justify={'space-between'}>
                                                                            <Grid item><ReportProblem style={{ verticalAlign: 'sub', fontSize: 18 }} /></Grid>
                                                                            <Grid item>{t('cart-material.recalc')}</Grid>
                                                                            <Grid item><Close style={{ verticalAlign: 'middle', fontSize: 18 }} /></Grid>
                                                                        </Grid>
                                                                    </div>
                                                                </Tooltip>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                }>
                                                    <AlertTitle>{t('cart-material.note')} :</AlertTitle>
                                                    {poi.custom_information}
                                                </Alert>
                                            </Grid>
                                        )
                                    }
                                </>
                            }
                            {
                                user?.client_full?.type !== 2 && poi.booking_status && poi.booking_status.details && poi.booking_status.details !== '' &&
                                <Grid item xs={12}>
                                    <Alert variant={'filled'} severity={'info'}>
                                        <AlertTitle>{t('cart-material.provider-note')} :</AlertTitle>
                                        {poi.booking_status.details}
                                    </Alert>
                                </Grid>
                            }
                            {
                                user?.client_full?.type !== 2 && poi.provider_wanted_price &&
                                <Grid item xs={12}>
                                    <Alert variant={'filled'} severity={'error'} action={
                                        <Button color={"inherit"} size={'small'} onClick={handleSeeNewPrice}>{t('global.see')}</Button>
                                    }>
                                        <AlertTitle>{t('cart-material.provider-wanted-price')}</AlertTitle>
                                    </Alert>
                                </Grid>
                            }
                            {
                                showManualCancellationRules ?
                                    <Grid id={`poi-${poi.id}-manual-cancellation-rules`} item xs={12}>
                                        <CartPoiManualCancellationRules poi={poi} onManualCancellationRules={onManualCancellationRules} />
                                    </Grid> : poi.cancel_condition_text &&
                                    <Grid item xs={12}>
                                        <Alert className={classes.cancelConditionAlert} variant={'filled'} severity={'info'} action={
                                            <Grid container direction={'column'} spacing={2} alignItems={'flex-end'}>
                                                <Grid item>
                                                    <Grid container spacing={2} alignItems={'center'}>
                                                        {
                                                            //!['verdie'].includes(quotation_code) &&
                                                            <Fragment>
                                                                <Grid item>
                                                                    <Button className={classes.editCustomInformation} color={'inherit'} size={'small'} onClick={onManualCancellationRules}>
                                                                        {t('global.edit')}
                                                                    </Button>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Close className={classes.deleteCustomInformation} onClick={onDeleteManualCancellationRules} />
                                                                </Grid>
                                                            </Fragment>
                                                        }
                                                    </Grid>
                                                </Grid>
                                                <Grid item style={{ width: '100%' }}>
                                                    {
                                                        poi.moved_with_recalc_cancel_condition_text &&
                                                        <Tooltip title={t('cart-material.recalc-product-cancel-condition')}>
                                                            <div
                                                                className={clsx(classes.traverlerModification, classes.delete)}
                                                                onClick={confirmCancelConditionRecalc}
                                                                style={{ fontSize: 13, color: 'white', background: '#ff9800', border: '2px solid #ff9800' }}
                                                            >
                                                                <Grid container alignItems={'center'} justify={'space-between'}>
                                                                    <Grid item><ReportProblem style={{ verticalAlign: 'sub', fontSize: 18 }} /></Grid>
                                                                    <Grid item>{t('cart-material.recalc')}</Grid>
                                                                    <Grid item><Close style={{ verticalAlign: 'middle', fontSize: 18 }} /></Grid>
                                                                </Grid>
                                                            </div>
                                                        </Tooltip>
                                                    }
                                                </Grid>
                                            </Grid>
                                        }>
                                            <AlertTitle>
                                                {
                                                    !['verdie'].includes(quotation_code) ?
                                                        t('cart-material.manual-cancellation-rules') :
                                                        t('cart-material.provider-cancellation-rules')
                                                } : {poi.no_refund ? t('cart-material.not-cancellable') : t('cart-material.cancellable-with-conditions')}
                                            </AlertTitle>
                                            {poi.cancel_condition_text === t('cart-material.not-cancellable') ? '' : poi.cancel_condition_text}
                                        </Alert>
                                    </Grid>
                            }
                            {
                                !providerContext.module &&
                                !!poi.questions &&
                                poi.questions.length > 0 &&
                                <Typography className={classes.manualCancellationRules} onClick={onAnswerQuestions}>
                                    {t('poi.answer_questions')}
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </>
        );
    }
);

const useStyles = makeStyles(() => ({
    scrollMargin: {
        scrollMarginTop: '75px'
    },
    disableBorder: {
        border: 'none !important'
    },
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    gridImage: {
        width: 250
    },
    gridContent: {
        padding: '0 15px'
    },
    gridPrice: {
        width: 100
    },
    image: {
        borderRadius: 16
    },
    selector: {
        margin: '16px 0 8px 0'
    },
    variant: {
        margin: '16px 0 8px 0'
    },
    updateQuotationStatus: {
        textDecoration: 'underline',
        marginBottom: 4,
        cursor: 'pointer'
    },
    addNote: {
        textDecoration: 'underline',
        marginBottom: 4,
        cursor: 'pointer'
    },
    manualCancellationRules: {
        textDecoration: 'underline',
        marginBottom: 4,
        cursor: 'pointer'
    },
    containerMargin: {
        marginBottom: 4
    },
    roomIcon: {
        float: 'left',
        marginRight: 4
    },
    inlineBlock: {
        display: 'inline-block'
    },
    cancellationInfo: {
        display: 'inline-block',
        verticalAlign: 'bottom',
        marginLeft: 4,
        cursor: 'pointer'
    },
    poiName: {
        fontSize: 20,
        fontWeight: 'bold',
        cursor: 'pointer'
    },
    pin: {
        float: 'left',
        marginRight: 4
    },
    calendar: {
        float: 'left',
        marginRight: 4
    },
    composition: {
        float: 'left',
        marginRight: 4
    },
    service: {
        float: 'left',
        marginRight: 4
    },
    contractPack: {
        textDecoration: 'underline',
        pointer: 'cursor',
        cursor: 'pointer',
        fontSize: 12,
        display: 'inline',
        marginLeft: 5
    },
    bookingContainer: {
        paddingLeft: 15
    },
    manualBookingHand: {
        float: 'left',
        marginRight: 8
    },
    price: {
        textAlign: 'right',
        fontSize: 32,
        fontWeight: 'bold'
    },
    red: {
        textAlign: 'right',
        color: 'red'
    },
    green: {
        textAlign: 'right',
        color: 'green'
    },
    needToBook: {
        color: '#4caf50',
        textAlign: 'right'
    },
    traverlerModification: {
        borderRadius: 16,
        padding: '1px 6px'
    },
    booking: {
        borderRadius: 16,
        fontWeight: 700,
        padding: '4px 12px',
        margin: '4px 0',
        textTransform: 'uppercase'
    },
    bookingDetail: {
        textDecoration: 'underline',
        fontSize: 12,
        textAlign: 'right',
        marginBottom: 4,
        cursor: 'pointer'
    },
    booked: {
        border: '2px solid #4caf50',
        color: '#4caf50'
    },
    pending: {
        border: '2px solid #ff9800',
        color: '#ff9800'
    },
    cached: {
        float: 'left',
        marginRight: 8,
        cursor: 'pointer'
    },
    cancelled: {
        border: '2px solid black',
        color: 'black'
    },
    error: {
        border: '2px solid #f44336',
        color: '#f44336'
    },
    deleteContainer: {
        textAlign: 'right'
    },
    delete: {
        cursor: 'pointer'
    },
    prices: {
        textAlign: 'right'
    },
    errorAlert: {
        marginTop: 8
    },
    alertIcon: {
        alignItems: 'center'
    },
    infoCustomInformation: {
        border: '1px solid #FF9800',
        color: 'white',
        background: '#FF9800',
        marginRight: 5
    },
    editCustomInformation: {
        border: '1px solid'
    },
    deleteCustomInformation: {
        cursor: 'pointer',
        marginLeft: 8
    },
    marginEdit: {
        textDecoration: 'underline',
        color: '#E6592F',
        cursor: 'pointer'
    },
    validateButton: {
        backgroundColor: '#E6592F',
        color: 'white'
    },
    poiInPropertyCollapsed: {
        float: 'left',
        cursor: 'pointer',
        marginRight: 4
    },
    poiInPropertyIconCollapsed: {
        verticalAlign: 'text-top'
    },
    pictureContainer: {
        overflow: "hidden",
        height: 200,
        //width: 300,
        position: "relative",
        cursor: "pointer"
    },
    hideMargin: {
        textDecoration: 'underline',
        cursor: 'pointer'
    },
    hideProduct: {
        backgroundColor: '#eeee',
        border: "solid 2px #E6592F",
        padding: 15,
        borderRadius: 10,
        marginBottom: 20
    },
    inQuotation: {
        border: "solid 2px #43D67D",
        padding: 15,
        borderRadius: 10,
        marginBottom: 20
    },
    isOption: {
        border: "solid 2px #eeee",
        padding: 15,
        borderRadius: 10,
        marginBottom: 20
    },
    progress: {
        color: green[500],
        position: 'absolute',
        top: "16%",
        left: "43%",
        marginLeft: -12
    },
    checkboxContainer: {
        position: 'relative',
        marginTop: '-5px'
    },
    cancelButton: {
        border: '1px solid',
        backgroundColor: 'white',
        color: '#E6592F'
    },
    cancelConditionAlert: {
        "whiteSpace": 'pre-wrap',
        "&& .MuiAlert-action": {
            minWidth: 140
        }
    },
    editTime: {
        "cursor": 'pointer',
        '&:hover': {
            textDecoration: 'underline'
        }
    },
    unavailable: {
        color: 'red'
    },
    priceUnavailable: {
        fontSize: 20
    }
}));
